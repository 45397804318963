// @react
import React from 'react'
import colors from 'constants/colors'

const ThirdParty: React.FunctionComponent = () => (
  <svg
    width="8"
    height="7"
    viewBox="0 0 8 7"
    preserveAspectRatio="xMidYMid meet"
    style={{
      width: '8px',
      height: '7px',
      fill: colors.white,
    }}
  >
    <path d="M1.6 4C.72 4 0 4.72 0 5.6c0 .88.72 1.6 1.6 1.6.88 0 1.6-.72 1.6-1.6 0-.88-.72-1.6-1.6-1.6zM4 0c-.88 0-1.6.72-1.6 1.6 0 .88.72 1.6 1.6 1.6.88 0 1.6-.72 1.6-1.6C5.6.72 4.88 0 4 0zm2.4 4c-.88 0-1.6.72-1.6 1.6 0 .88.72 1.6 1.6 1.6.88 0 1.6-.72 1.6-1.6C8 4.72 7.28 4 6.4 4z" />
  </svg>
)

export default ThirdParty
