import { Grid, Typography } from '@material-ui/core'
import { flexCenter } from 'modules/common/styledComponents/Mixins'
import styled from 'styled-components'
import colors from 'constants/colors'
import MuiStarIcon from '@material-ui/icons/Star'
import MuiStarBorderIcon from '@material-ui/icons/StarBorder'

export const GridWithPointer = styled(Grid)`
  && {
    cursor: pointer;
  }
`

export const FlexCenter = styled.div`
  ${flexCenter};
`

export const Label = styled(Typography)`
  && {
    margin-left: 15px;
    color: ${colors.dark};
  }
`

export const StarIcon = styled(MuiStarIcon)`
  && {
    ${(props) => `font-size:  ${String(props.size) || 24}px;`}
    ${(props) => props.disabled && `color: #e5e9ed`}
`

export const StarBorderIcon = styled(MuiStarBorderIcon)`
&& {
  ${(props) => `font-size:  ${String(props.size) || 24}px;`}
  ${(props) => props.disabled && `color: #e5e9ed`}
`
