import { Typography } from '@material-ui/core'
import styled from 'styled-components'

export const StepperWrap = styled.div`
  .MuiStepIcon-root {
    padding: 11px;
    font-size: 18px;
  }

  .MuiStepLabel-label.MuiStepLabel-alternativeLabel {
    font-size: 14px;
    margin-top: 10px;
  }

  .MuiStepConnector-alternativeLabel {
    top: 6px;
  }

  .MuiStepIcon-completed {
    margin: 0;
    background-color: transparent;

    &.MuiStepIcon-completed path {
      display: block;
    }
  }
`

export const Wrap = styled.div`
  flex: 1;
`

export const Subtitle = styled(Typography)`
  && {
    margin: 17px 0;
    text-transform: uppercase;
  }
`
