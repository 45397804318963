import styled from 'styled-components'

export const StyledIcon = styled.span`
  display: inline-flex;
  vertical-align: middle;
  flex-shrink: 0;

  .MuiSvgIcon-root {
    width: 0.8em;
    height: 0.8em;
  }
`
