// @react
import React from 'react'
import { ReactElement } from 'react'
import { Typography } from '@material-ui/core'

interface PropsType {
  errorText: string
}

const ErrorText = ({ errorText }: PropsType): ReactElement => {
  return (
    <Typography variant="subtitle1" gutterBottom>
      {errorText}
    </Typography>
  )
}

export default ErrorText
