// @react
import { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router'
import { useContext } from 'react'
// @design
// @components
import NotFound from 'modules/common/pages/NotFound'
import CenteredLoader from 'modules/common/components/_UI_DUMB/Loaders/CenteredLoader'
// @hook-form
import InlineForm from 'modules/common/components/_FORM_ELEMENTS/HookForm/inline/inline-form'
import FormDatepicker from 'modules/common/components/_FORM_ELEMENTS/HookForm/form-inputs/FormDatepicker/inline'
// @common
import strings from 'constants/strings'
import { DealContext } from 'modules/deal/contexts/deal'
import { pick } from 'utils/utils'
// @graphql
import { METRIC_UPDATE } from 'graphql/mutations/metric.mutation'
import { GET_METRIC } from 'graphql/queries/metric.query'
import { useMutation, useQuery } from '@apollo/client'
import {
  DealActions,
  GetMetricQuery,
  GetMetricQueryVariables,
  Metric,
  UpdateMetricMutation,
  UpdateMetricMutationVariables,
} from 'graphql/graphqlTypes'

/**
 *
 * @constructor
 */
const Dates = () => {
  /***************
   * CONSTANTS
   ***************/
  const dateFields = [
    'bidDate',
    'investmentCommittee',
    'endDate',
    'closingDate',
  ]

  /*******************************************************
   * HOOKS
   *******************************************************/
  const location = useLocation()
  const dealId = location.pathname.split('/')[3]
  const queryResult = useContext(DealContext)

  /*******************************************************
   * GRAPHQL
   *******************************************************/
  const { data, error, loading } = useQuery<
    GetMetricQuery,
    GetMetricQueryVariables
  >(GET_METRIC, {
    variables: { dealId },
    fetchPolicy: 'network-only',
  })
  const [metricsUpdate] = useMutation<
    UpdateMetricMutation,
    UpdateMetricMutationVariables
  >(METRIC_UPDATE)

  const [values, setValues] = useState<Partial<Metric> | undefined>()
  useEffect(() => {
    if (values === undefined && data?.metric) {
      setValues(pick(data?.metric, dateFields))
    }
  }, [data?.metric])

  /*******************************************************
   * FUNCTIONS
   *******************************************************/
  const submitHandler = async (formData) => {
    const newData = { ...formData }
    setValues(formData)
    await metricsUpdate({
      variables: { input: { ...newData, id: Number(data?.metric.id) } },
    })
  }

  /*******************************************************
   * RENDER ELEMENTS
   *******************************************************/
  const disabledEdit =
    (queryResult.data?.deal.actions?.indexOf(DealActions.DEAL_METRIC_EDIT) ||
      0) < 0

  const renderDates = () => (
    <InlineForm
      disabled={disabledEdit}
      editButtonText={strings.MD_EDIT_BUTTON}
      title={strings.MD_TITLE}
      onSubmit={submitHandler}
      defaultValues={values}
      testid="key-dates"
    >
      <FormDatepicker label={strings.MD_BID_DATE} name={'bidDate'} />
      <FormDatepicker
        label={strings.MD_INVESTMENT_COMMITTEE}
        name={'investmentCommittee'}
      />
      <FormDatepicker label={strings.MD_END_DATE} name={'endDate'} />
      <FormDatepicker label={strings.MD_CLOSING_DATE} name={'closingDate'} />
    </InlineForm>
  )
  const dates = useMemo(renderDates, [pick(data?.metric, dateFields)])

  /*******************************************************
   * RENDER
   *******************************************************/
  if (loading) return <CenteredLoader />

  if (!data?.metric && error) {
    return <NotFound />
  }
  return <>{dates}</>
}

export default Dates
