// @react
import {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react'
import { RouteComponentProps } from 'react-router'
// @common
import { format, parseISO } from 'date-fns'
import config from 'constants/config'
// @components
import MessagesList, {
  GroupedMessageData,
  MessageData,
} from '../../components/MessagesList'
import MessagesTextarea from 'modules/common/components/_FORM_ELEMENTS/Message/MessagesTextarea'
import { MessagesContainer } from 'modules/common/styledComponents/Message'
// @graphql
import { CREATE_MESSAGE } from 'graphql/mutations/message.mutation'
import { GET_MESSAGES_BY_USER } from 'graphql/queries/message.query'
import { MESSAGE_CREATE_SUBSCRIPTION } from 'graphql/subscriptions/message.subscription'
import { useMutation, useQuery } from '@apollo/client'
// @context
import UIfx from 'uifx'
import { UserContext } from 'modules/user/context/user'
import File from 'modules/document/models/file'
import { AudioFile } from 'modules/document/types/file'
import {
  CreateMessageMutation,
  CreateMessageMutationVariables,
  GetMessagesByUserQuery,
  GetMessagesByUserQueryVariables,
  MessagesOrderByList,
  OnMessageCreatedSubscription,
  OnMessageCreatedSubscriptionVariables,
  Order,
} from 'graphql/graphqlTypes'
import useErrorHandler from 'modules/common/hooks/useErrorHandler'

type TParams = {
  id: string | undefined
}

type PropsType = RouteComponentProps<TParams>

/**
 *
 * @param props
 * @constructor
 */
const Messages = (props: PropsType): ReactElement => {
  /*******************************************************
   * PROPS
   *******************************************************/
  // this will be the user Id
  const { id } = props.match.params

  const bell = useMemo(() => {
    return new UIfx(File.getAudioFile(AudioFile.ALERT), {
      volume: 0.4,
    })
  }, [])

  /*******************************************************
   * CONTEXT
   *******************************************************/
  const user = useContext(UserContext)

  /*******************************************************
   * GRAPHQL
   *******************************************************/
  /**
   * queries
   */
  const { subscribeToMore, data } = useQuery<
    GetMessagesByUserQuery,
    GetMessagesByUserQueryVariables
  >(GET_MESSAGES_BY_USER, {
    variables: {
      orderBy: {
        order: Order.DESC,
        value: MessagesOrderByList.CREATED_DATE,
      },
      filters: {
        user: [id || ''],
      },
    },
    skip: !id,
  })

  /**
   * subscriptions
   */
  const subscriptionWrapper = useCallback(() => {
    return subscribeToMore<
      OnMessageCreatedSubscription,
      OnMessageCreatedSubscriptionVariables
    >({
      document: MESSAGE_CREATE_SUBSCRIPTION,
      updateQuery: (prev, { subscriptionData }) => {
        const newMessageItem = subscriptionData.data.messageCreated

        const newQueryData = {
          messages: {
            ...prev.messages,
            data: [newMessageItem, ...prev.messages.data],
          },
        }
        if (user.user && newMessageItem.createdBy?.id !== user.user.id) {
          bell.play()
        }

        return newQueryData
      },
    })
  }, [subscribeToMore])

  useEffect(() => {
    subscriptionWrapper()
  }, [])

  /**
   * mutations
   */
  const [createMessage, { error }] = useMutation<
    CreateMessageMutation,
    CreateMessageMutationVariables
  >(CREATE_MESSAGE)
  useErrorHandler(error)

  /**
   * createCommentWrapper
   * @param inputData
   */
  const createChatMessageWrapper = async (inputData: { body: string }) => {
    if (!id) return

    const inputCommentData: CreateMessageMutationVariables = {
      input: {
        body: inputData.body,
        user: id,
      },
    }

    void (await createMessage({
      variables: inputCommentData,
    }))
  }

  /*******************************************************
   * RENDER
   *******************************************************/
  const formattedData: MessageData[] = data?.messages.data
    ? data.messages.data.map((el) => {
        return {
          id: Number(el?.id),
          body: el?.body,
          owner: el?.createdBy?.id === user.user?.id,
          firstName: el?.createdBy?.firstName || '',
          lastName: el?.createdBy?.lastName || '',
          avatar: el?.createdBy?.avatar?.file?.location,
          createdDate: el?.createdDate,
          createdDay: format(
            parseISO(el?.createdDate),
            config.dateFullMonthFormat,
          ),
          displayTime: format(parseISO(el?.createdDate), config.timeFormat),
        }
      })
    : []

  if (Array.isArray(formattedData)) {
    formattedData.reverse()
  }

  const groupedByDay = formattedData.reduce((acc, curr) => {
    if (!acc[curr.createdDay]) acc[curr.createdDay] = [] //If this type wasn't previously stored
    acc[curr.createdDay]?.push(curr)
    return acc
  }, {} as GroupedMessageData)

  return (
    <MessagesContainer>
      <MessagesList data={formattedData} groupedData={groupedByDay} />
      <MessagesTextarea
        editedCommentText={{ commentId: undefined, value: undefined }}
        userAvatar={user.user?.avatar?.file?.location || ''}
        submitHandler={createChatMessageWrapper}
      />
    </MessagesContainer>
  )
}

export default Messages
