// @react
import { ReactElement, useContext, useEffect } from 'react'
import { Route, RouteComponentProps, withRouter } from 'react-router'
// @design
import * as SCGLobal from 'modules/common/styledComponents/Global'
import * as TabsSC from 'modules/common/styledComponents/Tabs'
// @common
import { RouteNames } from 'constants/routeNames'
// @components
import NotFound from 'modules/common/pages/NotFound'
// @graphql
import { useQuery } from '@apollo/client'
import { GET_ITEMS } from 'graphql/queries/item.query'
import { DealContext } from 'modules/deal/contexts/deal'
import { SnackbarTypes } from 'modules/common/components/_UI_DUMB/Snackbars/Snackbar'
import { SnackbarContext } from 'modules/common/context/snackbar'
import ItemsList from 'modules/common/components/_UI_SMART/ItemsList'
import {
  GetItemsQuery,
  GetItemsQueryVariables,
  ItemsOrderByList,
  Order,
  Scalars,
} from 'graphql/graphqlTypes'
import ItemsTab from '../../components/ItemsTabs'
import strings from 'constants/strings'
import DealStageView from 'modules/deal/pages/DealStageView'
import KanbanWrapper from 'modules/kanban/pages/Kanban'
import DealMigrateActions from '../../components/DealMigrateActions'
import DealStepperContextWrapper from 'modules/deal/contexts/dealStepper'
import { TopWrap } from './styledComponents'

type TParams = {
  id: string | undefined
}

type PropsType = RouteComponentProps<TParams>

const DealItems = (props: PropsType): ReactElement => {
  /**
   * context
   */
  /*******************************************************
   * CONTEXT
   *******************************************************/
  const { dealId, data, loading } = useContext(DealContext)
  const snackbar = useContext(SnackbarContext)

  /**
   * queries
   */
  /*******************************************************
   * GRAPHQL
   *******************************************************/
  const { error } = useQuery<GetItemsQuery, GetItemsQueryVariables>(GET_ITEMS, {
    variables: {
      orderBy: {
        order: Order.DESC,
        value: ItemsOrderByList.NAME,
      },
      filters: {
        deal: [dealId || ''],
      },
    },
    skip: !dealId,
  })

  /*******************************************************
   * LIFECYCLE HOOKS
   *******************************************************/
  useEffect(() => {
    if (error && error[0]?.message) {
      snackbar.setMessage({
        type: SnackbarTypes.ERROR,
        message: error[0]?.message,
        show: true,
      })
    }
  }, [error])

  /*******************************************************
   * FUNCTIONS
   *******************************************************/

  /**
   *
   * @param id
   * @param parentId
   */
  const handleClick = (
    id: Scalars['ID'],
    parentId: Scalars['ID'] | null | undefined,
  ) => {
    props.history.push(
      `/${RouteNames.DEALS}/${RouteNames.DETAILS}/${String(parentId)}/${String(
        RouteNames.ITEMS,
      )}/${String(RouteNames.DETAILS)}/${id}`,
    )
  }
  /*******************************************************
   * RENDER COMPONENTS
   *******************************************************/
  if (dealId && !data && !loading) {
    return <NotFound />
  }
  /*******************************************************
   * RENDER COMPONENTS
   *******************************************************/
  const tabs = (
    <>
      <Route
        path={`/${RouteNames.DEALS}/${RouteNames.DETAILS}/:${RouteNames.ID}/${RouteNames.ITEMS}`}
        exact
        render={() => (
          <ItemsList dealId={dealId || undefined} handleClick={handleClick} />
        )}
      />
      <Route
        path={`/${RouteNames.DEALS}/${RouteNames.DETAILS}/:${RouteNames.ID}/${RouteNames.STAGE_VIEW}`}
        exact
        component={DealStageView}
      />
      <Route
        exact
        path={`/${RouteNames.DEALS}/${RouteNames.DETAILS}/:${RouteNames.ID}/${RouteNames.CARD_VIEW}`}
        render={() => <KanbanWrapper />}
      />
    </>
  )

  /*******************************************************
   * RENDER
   *******************************************************/
  return (
    <DealStepperContextWrapper>
      <SCGLobal.ContentContainer>
        <TabsSC.WideTabsContainer>
          <TabsSC.ViewBy>{strings.VIEW_BY}</TabsSC.ViewBy>
          <SCGLobal.PushContainer>
            <TopWrap>
              <ItemsTab dealId={dealId || ''} activeIndex={1} />
              <TopWrap>
                <DealMigrateActions />
              </TopWrap>
            </TopWrap>
          </SCGLobal.PushContainer>
          {tabs}
        </TabsSC.WideTabsContainer>
      </SCGLobal.ContentContainer>
    </DealStepperContextWrapper>
  )
}

export default withRouter(DealItems)
