import styled from 'styled-components'
import colors from 'constants/colors'
import { to } from './MediaQueries'
import { Tab, Typography } from '@material-ui/core'

export const TabsContainer = styled.div`
  max-width: 835px;
  margin: auto;

  ${to(600)} {
    padding: 0 20px;
  }
`

export const WideTabsContainer = styled.div`
  margin: auto;

  ${to(600)} {
    padding: 0 20px;
  }
`

export const ViewBy = styled(Typography)`
  margin-bottom: 1em;
  font-size: 16px;
  font-weight: 600;
  line-height: 1.36;
  color: ${colors.dark};
`

export const TabLabel = styled.span`
  margin-right: 5px;
`

export const StyledTab = styled(Tab)`
  && {
    padding: 0;
    > *:first-child > span {
      margin-bottom: 0;
    }
  }
`
