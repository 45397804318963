// @react
import { createContext, useContext, useEffect, useState } from 'react'
// @graphql
import { ApolloQueryResult } from '@apollo/client/core/types'
import { useQuery, useSubscription } from '@apollo/client'
import { GET_DEAL_INFO } from 'graphql/queries/deal.query'
import { useLocation } from 'react-router'
import {
  DEAL_DELETE_SUBSCRIPTION,
  DEAL_UPDATE_SUBSCRIPTION,
} from 'graphql/subscriptions/deal.subscription'
import DestroyDocSvgIcon from 'modules/common/components/_UI_DUMB/Icons/SvgIcons/DestroyDocSvgIcon'
import RocketShipSvgIcon from 'modules/common/components/_UI_DUMB/Icons/SvgIcons/RocketShipSvgIcon'
import {
  GetDealInfoQuery,
  GetDealInfoQueryVariables,
  OnDealUpdatedSubscription,
  OnDealUpdatedSubscriptionVariables,
  OnItemDeleteSubscription,
  OnItemDeleteSubscriptionVariables,
  Scalars,
  DealActions,
  OnDealDeleteSubscription,
  OnDealDeleteSubscriptionVariables,
} from 'graphql/graphqlTypes'
import { ITEM_DELETE_SUBSCRIPTION } from 'graphql/subscriptions/item.subscription'
import { SnackbarContext } from 'modules/common/context/snackbar'
import { SnackbarTypes } from 'modules/common/components/_UI_DUMB/Snackbars/Snackbar'
import BROWSER_HISTORY from 'utils/history'
import useErrorHandler from 'modules/common/hooks/useErrorHandler'

interface DealContextValue {
  data: GetDealInfoQuery | null | undefined
  loading: boolean
  refetch?:
    | null
    | ((
        variables?: Partial<GetDealInfoQueryVariables>,
      ) => Promise<ApolloQueryResult<GetDealInfoQuery>>)
  dealId: Scalars['ID'] | null
  error: any
}

export const DealContext = createContext<DealContextValue>({
  data: null,
  loading: false,
  refetch: null,
  dealId: null,
  error: null,
})

const CustomIcon = {
  DEAL_KILLED: <DestroyDocSvgIcon />,
  DEAL_LAUNCHED: <RocketShipSvgIcon />,
}

/**
 *
 * @param Component
 */
const ComponentWrapper = (Component) => {
  return function DealRequired(props: Record<string, unknown>) {
    const location = useLocation()
    const [, , , dealId] = location.pathname.split('/')
    /*******************************************************
     * HOOKS
     *******************************************************/
    const snackbar = useContext(SnackbarContext)
    const [contextValue, setContextValue] = useState<DealContextValue>({
      data: null,
      loading: false,
      refetch: null,
      dealId: null,
      error: null,
    })

    /*******************************************************
     * GRAPHQL
     *******************************************************/
    /**
     * define queries
     */
    const { data, loading, refetch, error, client, networkStatus } = useQuery<
      GetDealInfoQuery,
      GetDealInfoQueryVariables
    >(GET_DEAL_INFO, {
      variables: {
        id: dealId,
      },
      skip: !dealId,
    })
    useErrorHandler(error)

    /**
     * DEAL SUBS
     */
    useSubscription<
      OnDealUpdatedSubscription,
      OnDealUpdatedSubscriptionVariables
    >(DEAL_UPDATE_SUBSCRIPTION, {
      shouldResubscribe: true,
      onSubscriptionComplete: () => {
        console.log('DEAL_UPDATE_SUBSCRIPTION completed')
      },
      onSubscriptionData: ({ client, subscriptionData }) => {
        // @todo user will no longer be in the UPDATE_SUB USER GROUP BECAUSE IS REMOVED FROM DEAL
        if (
          !subscriptionData.data?.dealUpdated.deal.actions?.includes(
            DealActions.DEAL_READ,
          )
        ) {
          evictDealFromCache(subscriptionData?.data?.dealUpdated.deal.id)

          if (dealId === subscriptionData?.data?.dealUpdated.deal.id) {
            snackbar.setMessage({
              type: SnackbarTypes.ERROR,
              message: `You not longer have access to this Deal`,
              show: true,
            })
          }
        }
      },
    })

    useSubscription<
      OnDealDeleteSubscription,
      OnDealDeleteSubscriptionVariables
    >(DEAL_DELETE_SUBSCRIPTION, {
      shouldResubscribe: true,
      onSubscriptionData: ({ client, subscriptionData }) => {
        evictDealFromCache(subscriptionData?.data?.dealDeleted.deal.id)

        if (dealId === subscriptionData?.data?.dealDeleted.deal.id) {
          // no redirect needed, switches to 404, not sure yet how this is different from DEAL_DELETION
          snackbar.setMessage({
            type: SnackbarTypes.ERROR,
            message: `This deal has been deleted!`,
            show: true,
          })
        }
      },
    })

    /**
     * ITEM SUBS
     */
    useSubscription<
      OnItemDeleteSubscription,
      OnItemDeleteSubscriptionVariables
    >(ITEM_DELETE_SUBSCRIPTION, {
      shouldResubscribe: true,
      onSubscriptionData: ({ client, subscriptionData }) => {
        const normalizedId = client.cache.identify({
          id: subscriptionData?.data?.itemDeleted.item?.id,
          __typename: 'Item',
        })
        client.cache.evict({ id: normalizedId })
        client.cache.gc()
        // no redirect needed, switches to 404, not sure yet how this is different from DEAL_DELETION
      },
    })

    /*******************************************************
     * HOOKS
     *******************************************************/
    useEffect(() => {
      if (error || (!data && !loading && dealId)) {
        evictDealFromCache(dealId)
      }
      setContextValue({
        data,
        loading,
        refetch,
        dealId,
        error,
      })
    }, [data, loading])

    /*******************************************************
     * FUNCTIONS
     *******************************************************/
    const evictDealFromCache = (id) => {
      if (!client) {
        console.log('client not defined')
        return
      }

      const normalizedId = client.cache.identify({
        id,
        __typename: 'Deal',
      })
      client.cache.evict({ id: normalizedId })
      client.cache.gc()
      BROWSER_HISTORY.push('/404')
    }

    /*******************************************************
     * RENDER
     *******************************************************/
    return (
      <DealContext.Provider value={contextValue}>
        <Component {...props} />
      </DealContext.Provider>
    )
  }
}

export default ComponentWrapper
