// @react
import axios from 'axios'
import strings from 'constants/strings'
import parse from 'html-react-parser'
import { useState } from 'react'
import { GET_USER_INFO } from 'graphql/queries/user.query'
import { useQuery } from '@apollo/client'
import CenteredLoader from 'modules/common/components/_UI_DUMB/Loaders/CenteredLoader'
import * as SC from './styledComponents'
import {
  GetUserInfoQuery,
  GetUserInfoQueryVariables,
} from 'graphql/graphqlTypes'
import useErrorHandler from 'modules/common/hooks/useErrorHandler'

/**
 * @constructor
 */
const ProfilePassword = () => {
  /*******************************************************
   * STATE
   *******************************************************/
  const [msg, changeMsg] = useState(null)

  /*******************************************************
   * GRAPHQL
   *******************************************************/
  const { error, data, loading } = useQuery<
    GetUserInfoQuery,
    GetUserInfoQueryVariables
  >(GET_USER_INFO)
  useErrorHandler(error)

  /*******************************************************
   * FUNCTIONS
   *******************************************************/
  const submitHandler = async (email: string) => {
    const data = {
      client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
      email,
      connection: process.env.REACT_APP_DATABASE_CONNECTION_NAME,
    }
    void (await axios
      .post(
        `https://${String(
          process.env.REACT_APP_AUTH0_DOMAIN,
        )}/dbconnections/change_password`,
        data,
      )
      .then((res) => {
        const msg = res.data
        changeMsg(msg)
      })
      .catch((errors) => {
        console.log(errors)
      }))
  }

  /*******************************************************
   * RENDER
   *******************************************************/
  if (loading) return <CenteredLoader />

  return (
    <SC.Wrapper>
      <SC.Title variant="h3" color="secondary">
        Password
      </SC.Title>
      <SC.Title variant="h4" color="secondary">
        {parse(strings.PASSWORD_RESET_LINK)}
      </SC.Title>
      {data?.user?.email && (
        <SC.StyledButton
          onClick={() => submitHandler(data.user?.email || '')}
          variant="contained"
          color="primary"
          type="submit"
        >
          {strings.RESET}
        </SC.StyledButton>
      )}

      <div>{msg}</div>
    </SC.Wrapper>
  )
}

export default ProfilePassword
