import styled from 'styled-components'

export const HomeRightContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  overflow: hidden;
`

export const HomeContentWrap = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
`

export const ComponentWrap = styled.div`
  width: 100%;
  flex: 1;

  ${(props: { background: string }) =>
    props.background &&
    `
    background-color: ${props.background};
  `}
`
