import React, { ReactElement, ReactNode } from 'react'
import { CircularProgress } from '@material-ui/core'
import { SCLoaderWrapper } from './styledComponents/centeredLoader'

interface PropsType {
  className?: string
  condition: boolean
  children: ReactNode
  loadingComponent?: ReactNode
  size?: number
}

const Loader = () => <CircularProgress size={40} />

const ConditionalLoader = ({
  className,
  condition,
  loadingComponent,
  children,
}: PropsType): ReactElement => (
  <>
    {condition ? (
      children
    ) : (
      <SCLoaderWrapper className={className}>
        {loadingComponent ?? <Loader />}
      </SCLoaderWrapper>
    )}
  </>
)

export default ConditionalLoader
