// @react
import React from 'react'
import styled from 'styled-components'

const RocketShipSvg = (props: { className: string }) => (
  <div className={props.className}>
    <svg
      className="path"
      stroke="#2c3e50"
      strokeWidth="0.3"
      fill="freeze"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 16 16"
    >
      <path d="M5.313 11.717a.29.29 0 0 0-.308.135 1.653 1.653 0 0 1-1.79.93 1.64 1.64 0 0 1 .932-1.788.29.29 0 0 0-.11-.535 3.03 3.03 0 0 0-2.837.98 5.046 5.046 0 0 0-.866 3.98.293.293 0 0 0 .24.24 6.347 6.347 0 0 0 1.006.08 4.336 4.336 0 0 0 3.29-1.25 3.067 3.067 0 0 0 .672-2.54.29.29 0 0 0-.23-.25zM15.866.135c-.114-.113-.348-.06-.348-.06A11.5 11.5 0 0 0 14.238 0a10.385 10.385 0 0 0-7.41 3 10.064 10.064 0 0 0-.68.76 3.847 3.847 0 0 0-2.972-.11C1.65 4.306.758 6.176.02 8.1a.29.29 0 0 0 .45.332 3.807 3.807 0 0 1 3.63-.5l-.362 1.31a.29.29 0 0 0 .125.32 12.82 12.82 0 0 1 2.577 2.574.29.29 0 0 0 .322.125l1.31-.36a3.752 3.752 0 0 1-.5 3.64.29.29 0 0 0 .333.45c1.923-.74 3.793-1.63 4.447-3.15a3.845 3.845 0 0 0-.108-2.97A9.844 9.844 0 0 0 13 9.18 10.508 10.508 0 0 0 15.93.49s.05-.235-.064-.35zM14.238.58a10.966 10.966 0 0 1 1.127.058 10.746 10.746 0 0 1 .047 1.55 1.828 1.828 0 0 1-1.083-.515A1.8 1.8 0 0 1 13.81.59c.14-.007.282-.012.424-.012zm-7 2.83A9.72 9.72 0 0 1 13.23.627a2.427 2.427 0 0 0 .5 1.237l-1.8 1.8a2.35 2.35 0 0 0-3.3 3.3l-2.1 2.1a12.862 12.862 0 0 0-1.752-1.422 9.627 9.627 0 0 1 2.46-4.232zm5.02 2.107a1.774 1.774 0 1 1-1.773-1.773 1.776 1.776 0 0 1 1.774 1.773zm-11.38 2C1.506 6.013 2.272 4.67 3.4 4.185a3.2 3.2 0 0 1 2.374.06A10.258 10.258 0 0 0 4.262 7.36a4.052 4.052 0 0 0-3.385.156zm5.932 4.13a14.353 14.353 0 0 0-2.457-2.453l.263-.956a12.413 12.413 0 0 1 3.15 3.147zm5.01.95c-.485 1.133-1.827 1.9-3.33 2.528a4.057 4.057 0 0 0 .15-3.385 10.29 10.29 0 0 0 3.122-1.52 3.205 3.205 0 0 1 .056 2.38zm-3.46-1.374A12.894 12.894 0 0 0 6.943 9.47l2.1-2.1a2.35 2.35 0 0 0 3.3-3.3l1.8-1.8a2.432 2.432 0 0 0 1.234.5 9.72 9.72 0 0 1-2.78 5.99 9.634 9.634 0 0 1-4.232 2.458z" />
    </svg>
  </div>
)

export const RocketShipSvgIcon = styled(RocketShipSvg)`
  && {
    width: 35px;
    height: 35px;
    top: 10%;

    .path {
      stroke-dasharray: 1000;
      stroke-dashoffset: 1000;
      animation: draw 4s infinite;
      fill: #fff;
    }
  }

  @keyframes draw {
    0% {
      stroke-dashoffset: 1000;
    }
    60% {
      stroke-dashoffset: 0;
    }
    100% {
      stroke-dashoffset: 1000;
    }
  }
`

export default RocketShipSvgIcon
