import styled from 'styled-components'
import colors from 'constants/colors'

export const Wrap = styled.div`
  .suggest__control {
    border-width: 0 0 1px;
    border-bottom-color: ${colors.battleshipGrey};
    border-radius: 0;
  }

  .suggest__value-container {
    padding-left: 0;
  }

  .suggest__placeholder {
    font-size: 14px;
    text-transform: uppercase;
  }

  .suggest__indicator,
  .suggest__indicator-separator {
    display: none;
  }

  .suggest__multi-value {
    margin-left: 0;
    margin-right: 4px;
    padding: 0.625em 0.75em 0.625em 1.25em;
    font-size: 14px;
    background-color: ${colors.paleGrey};
    color: ${colors.dark};
  }

  .suggest__multi-value__label {
    margin-right: 1em;
    padding-left: 0;
    font-size: 1em;
    font-weight: 600;
  }

  .suggest__multi-value__remove {
    cursor: pointer;

    &:hover {
      background-color: ${colors.white};
      box-shadow: 0 0 0 1px ${colors.lightGreyBlue};
      color: currentColor;
    }

    svg {
      width: 1.2em;
      height: 1.2em;
    }
  }
`
