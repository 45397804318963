// @common
import strings from 'constants/strings'
// @UI
import { Grid } from '@material-ui/core'
import Section from 'modules/common/components/_UI_DUMB/DashboardSection'
import Skeleton from '@material-ui/lab/Skeleton'
// @components
import LoadMoreBtn from 'modules/common/components/_UI_SMART/LoadMoreBtn'
// @graphql
import { GetDealsQuery, GetDealsQueryVariables } from 'graphql/graphqlTypes'
// @models
import DealModel from 'modules/deal/models/deal'
import { ApolloError } from '@apollo/client'
import { ApolloFetchMoreType } from 'modules/common/types'
import { ApolloQueryResult } from '@apollo/client/core/types'
import React, { useState } from 'react'
import DealCard from 'modules/dashboard/components/DealCard'
import ConditionalLoader from 'modules/common/components/_UI_DUMB/Loaders/ConditionalLoader'
import NetworkError from 'modules/common/pages/NetworkError'
import SectionEmpty from 'modules/common/components/_UI_DUMB/Section/SectionEmpty'
import CreateDeal from 'modules/deal/components/CreateDealWizard'
import { LoadingBlocks } from 'modules/common/components/_UI_DUMB/Loaders/Skeletons'

/*******************************************************
 * TYPES
 *******************************************************/
interface Props {
  data: GetDealsQuery | undefined
  loading: boolean
  error?: ApolloError | undefined
  refetch: (
    variables?: Partial<GetDealsQueryVariables>,
  ) => Promise<ApolloQueryResult<GetDealsQuery>>
  fetchMore: ApolloFetchMoreType<GetDealsQuery, GetDealsQueryVariables>
  variables: GetDealsQueryVariables | undefined
  cardPrefixes: {
    subtitle: string
    byLine: string
  }
}

/**
 *
 * @param props
 * @constructor
 */
const DealsGrid = ({ data, error, loading, fetchMore }: Props) => {
  /*******************************************************
   * STATE
   *******************************************************/
  const [page, setPage] = useState(data?.deals.meta.page || 1)
  const [showDealModal, setShowDealModal] = useState<boolean>(false)
  const toggleDealModal = () => setShowDealModal(!showDealModal)

  /*******************************************************
   * PROPS
   *******************************************************/
  const dataMeta = data?.deals.meta

  const isLoadMoreNeeded = dataMeta?.totalPages
    ? (page || 0) + 1 !== dataMeta.totalPages
    : false

  /*******************************************************
   * FUNCTIONS
   *******************************************************/
  const loadMoreHandler = () => {
    if (dataMeta) {
      setPage((old) => old + 1)
      void fetchMore({
        variables: {
          page,
        },
      })
    }
  }

  /*******************************************************
   * RENDER ELEMENTS
   *******************************************************/
  const getGridItems = () => {
    if (data?.deals.data?.length) {
      return data.deals.data.map((el, key: number) => {
        // figure out what the main deal image is
        const dealImage = el.imageDocs?.length
          ? el.imageDocs[0]?.file?.location
          : undefined

        return (
          <DealCard
            key={el.id}
            title={el.name}
            image={dealImage}
            deal={el}
            href={DealModel.dealStatusRouter(el.status, el.id)}
          />
        )
      })
    }

    return (
      <Grid item xs={12}>
        <SectionEmpty
          title={strings.DASHBOARD_NO_DEALS_ACTIVE}
          cta={[
            {
              type: 'primary',
              label: strings.DASHBOARD_NO_DEALS_CREATE,
              onClick: toggleDealModal,
            },
          ]}
          modal={
            <CreateDeal open={showDealModal} handleClose={toggleDealModal} />
          }
        />
      </Grid>
    )
  }

  /**
   *
   * @type {JSX.Element}
   */
  const LoadingComponent = () => (
    <Grid container spacing={3}>
      {Array(12)
        .fill('')
        .map((item, idx) => (
          <Grid key={idx} item xs={12} sm={6} md={4}>
            <Skeleton variant="rect" width="100%" height={156} />
          </Grid>
        ))}
    </Grid>
  )

  /*******************************************************
   * RENDER
   *******************************************************/
  if (error) {
    return <NetworkError />
  }

  return (
    <Section margin="md">
      <ConditionalLoader
        condition={!loading}
        loadingComponent={<LoadingBlocks size={'short'} count={12} />}
      >
        <>
          <Grid container spacing={3}>
            {getGridItems()}
          </Grid>
          <LoadMoreBtn
            isVisible={isLoadMoreNeeded}
            isLoading={loading}
            onClick={loadMoreHandler}
          />
        </>
      </ConditionalLoader>
    </Section>
  )
}

export default DealsGrid
