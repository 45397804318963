import { Menu, MenuItem } from '@material-ui/core'
import colors from 'constants/colors'
import styled from 'styled-components'

export const Title = styled.span`
  font-size: 18px;
  margin-left: 10px;
`

export const StyledMenu = styled(Menu).attrs({
  classes: { paper: 'paper' },
})`
  .paper {
    min-width: 140px;
    overflow: initial;
    margin-top: 10px;
    box-shadow: 0 0 0.4em -0.2em ${colors.battleshipGrey};
  }
`

export const StyleMenuItem = styled(MenuItem)`
&& {
  ${(props) =>
    props.variant === 'yellow' &&
    `&:hover {
    color: ${colors.white}
    background-color: ${colors.yellowishOrange};
  }`}
`

export const StyledMenuItemSeparator = styled(MenuItem)`
  margin: 4px 0;
  padding: 0;
  border-top: 1px solid ${colors.veryLightBlue};
  pointer-events: none;
`

export const SubMenu = styled.ul`
  margin: 0;
  padding: 0;
  font-size: 14px;
  list-style: none;
  color: ${colors.dark};
`

export const SubMenuItem = styled.li`
  padding: 6px 15px;
  line-height: 1.5;

  &.subTitle {
    margin-top: 5px;
    padding-top: 10px;
    line-height: 13px;
    font-size: 14px;
    text-transform: uppercase;
    color: ${colors.lightGreyBlue};
    pointer-events: none;
    border-top: 0.5px solid ${colors.paleGrey};

    &.first {
      border: none;
      margin-top: 0;
    }

    a {
      font-size: 1em;
      color: red;
    }
  }

  &:hover {
    background-color: ${colors.darkenBlue};
    cursor: pointer;
  }
`
