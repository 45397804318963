// @react
import { RouteComponentProps, withRouter } from 'react-router'
import { useState, useContext, useEffect } from 'react'
// @design
import * as SC from './styledComponents/dealStageView'
// @common
import strings from 'constants/strings'
import { RouteNames } from 'constants/routeNames'
import { SnackbarContext } from 'modules/common/context/snackbar'
// @components
import NotFound from 'modules/common/pages/NotFound'
import ItemsList from 'modules/common/components/_UI_SMART/ItemsList'
import CenteredLoader from 'modules/common/components/_UI_DUMB/Loaders/CenteredLoader'
import { SnackbarTypes } from 'modules/common/components/_UI_DUMB/Snackbars/Snackbar'
import { DealContext } from 'modules/deal/contexts/deal'
import { ContentContainer } from 'modules/common/styledComponents/Global'
import DealStageViewSteppers from './components/DealStageViewSteppers'
// @graphql
import { useQuery, useMutation } from '@apollo/client'
import { GET_ITEMS_WITH_USER } from 'graphql/queries/item.query'
import { DEAL_MIGRATE } from 'graphql/mutations/deal.mutation'
import {
  ItemsOrderByList,
  Order,
  DealStatus,
  DealStageDirection,
  GetItemsWithUserQueryVariables,
  GetItemsWithUserQuery,
  MigrateDealMutation,
  MigrateDealMutationVariables,
  Scalars,
  ItemsFilterInput,
  DealStage,
} from 'graphql/graphqlTypes'

/*******************************************************
 * TYPES
 *******************************************************/
type TParams = { id: string }
type PropsType = RouteComponentProps<TParams>

/**
 *
 * @param props
 * @returns {any}
 * @constructor
 */
const DealStageView = ({ history }: PropsType) => {
  /*******************************************************
   * STATE
   *******************************************************/
  const [dealStage, setDealStage] = useState<DealStage>()

  /*******************************************************
   * CONTEXT
   *******************************************************/
  const queryResult = useContext(DealContext)
  const snackbar = useContext(SnackbarContext)

  /*******************************************************
   * CONST
   *******************************************************/
  const {
    data: dealData,
    loading: dealLoading,
    refetch: dealRefetch,
    dealId,
  } = queryResult

  const dealStatus = dealData?.deal.status
  /*******************************************************
   * GRAPHQL
   *******************************************************/
  /**
   * queries
   *
   */
  const itemsQueryResult = useQuery<
    GetItemsWithUserQuery,
    GetItemsWithUserQueryVariables
  >(GET_ITEMS_WITH_USER, {
    variables: {
      orderBy: {
        order: Order.DESC,
        value: ItemsOrderByList.NAME,
      },
      filters: {
        deal: [dealId || ''],
        stage: dealStage ? [dealStage] : [],
        // @todo 01-22 fix this type
      } as ItemsFilterInput,
    },
    skip: !dealId,
  })
  const { variables, error, loading } = itemsQueryResult

  /**
   * mutations
   */
  const [migrateDeal] = useMutation<
    MigrateDealMutation,
    MigrateDealMutationVariables
  >(DEAL_MIGRATE)

  /*******************************************************
   * HOOKS
   *******************************************************/

  useEffect(() => {
    if (dealData?.deal.stage) setDealStage(dealData.deal.stage)
  }, [dealData?.deal.stage])

  /*******************************************************
   * FUNCTIONS
   *******************************************************/
  /**
   * handleMigration
   * @param dealId
   * @param direction
   * @return {Promise<void>}
   */
  const handleMigration = async (
    dealId: Scalars['ID'],
    direction: DealStageDirection,
  ) => {
    const deal = await migrateDeal({
      variables: {
        id: dealId,
        direction,
      },
    })
    dealRefetch && (await dealRefetch())
  }

  /**
   *
   * @param id
   * @param parentId
   */
  const handleClick = (id: number, parentId: number | null | undefined) => {
    history.push(
      `/${RouteNames.DEALS}/${RouteNames.DETAILS}/${String(parentId)}/${
        RouteNames.ITEMS
      }/${RouteNames.DETAILS}/${id}`,
    )
  }

  /**
   *
   * @param {DealStageDirection} direction
   * @returns {Promise<void>}
   */
  const moveStage = async (direction?: DealStageDirection) => {
    if (dealStatus === DealStatus.DEAD) return
    dealId &&
      (await handleMigration(dealId, direction || DealStageDirection.FORWARD))
    snackbar.setMessage({
      message: strings.SUCCESS_UPDATE,
      type: SnackbarTypes.SUCCESS,
      show: true,
      anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
    })
  }

  /*******************************************************
   * CONST
   *******************************************************/
  const stage = variables?.filters?.stage
  const selectedDealStage = (stage?.length && stage[0]) || dealStage

  /*******************************************************
   * RENDER COMPONENTS
   *******************************************************/
  if (!loading && error?.message === 'Permission denied') {
    return <NotFound />
  }

  if (dealLoading) {
    return <CenteredLoader />
  }

  /*******************************************************
   * RENDER
   *******************************************************/
  if (dealData?.deal && dealId) {
    return (
      /* THIS WAS CHNAGEDD TO PURE DIV @todo review if that was correct*/
      <ContentContainer data-testid={'deal-single-stageview-page'}>
        <SC.Steppers>
          {dealStage && (
            <DealStageViewSteppers
              handleChangeVisibility={moveStage}
              dealStage={dealStage}
              setDealStage={setDealStage}
              queryResult={itemsQueryResult}
            />
          )}
        </SC.Steppers>
        {!loading && (
          <div className="deal-stage-view-table-container">
            <SC.TopWrap spacing={2}>
              {selectedDealStage && (
                <ItemsList
                  dealId={dealId}
                  showFilters={false}
                  handleClick={handleClick}
                  dealStage={selectedDealStage}
                />
              )}
            </SC.TopWrap>
          </div>
        )}
      </ContentContainer>
    )
  }

  return <NotFound />
}

export default withRouter(DealStageView)
