import { ReactNode, useContext, useState } from 'react'
// @design
import * as SC from '../../styledComponents/item'
// @common
import { SnackbarContext } from 'modules/common/context/snackbar'
import { DealContext } from 'modules/deal/contexts/deal'
import { isOfType } from 'modules/common/types'
import {
  ITEM_CLOSE_PERMISSIONS,
  ITEM_DELETE_PERMISSIONS,
  ITEM_REOPEN_PERMISSIONS,
} from '../../../../config'
import strings from 'constants/strings'
import useErrorHandler from 'modules/common/hooks/useErrorHandler'
import { useRouteMatch } from 'react-router'
import { SnackbarTypes } from 'modules/common/components/_UI_DUMB/Snackbars/Snackbar'
import BROWSER_HISTORY from 'utils/history'
import { RouteNames } from 'constants/routeNames'
// @components
import Dropdown, {
  Props as DropDownProps,
} from '../../../../../common/components/_UI_DUMB/Dropdown'
import StarredButton from '../../../../components/StarredButton'
import {
  StyledListItemIcon,
  StyledListItemText,
} from 'modules/common/components/_UI_SMART/Sidebar/styledComponents/sidebar'
import Spacer from 'modules/common/components/_UI_DUMB/Spacer'
import { Grid, useMediaQuery, useTheme } from '@material-ui/core'
import CenteredLoader from 'modules/common/components/_UI_DUMB/Loaders/CenteredLoader'
import DoneIcon from '@material-ui/icons/Done'
import DeleteIcon from '@material-ui/icons/Delete'
import ConditionalLoader from 'modules/common/components/_UI_DUMB/Loaders/ConditionalLoader'
//@graphql
import { GET_ITEM } from 'graphql/queries/item.query'
import { useMutation, useQuery } from '@apollo/client'
import { DELETE_ITEM, ITEM_UPDATE } from 'graphql/mutations/item.mutation'
import {
  DeleteItemMutation,
  DeleteItemMutationVariables,
  GetItemQuery,
  GetItemQueryVariables,
  Item as ItemType,
  ItemActions,
  ItemStatus,
  ItemUpdateMutation,
  ItemUpdateMutationVariables,
} from 'graphql/graphqlTypes'

type TParams = { id: string; itemId: string }

const ItemToolBar = () => {
  /**
   * match params
   */
  const match = useRouteMatch<TParams>()
  const { itemId } = match.params

  /**
   *
   * other hooks
   */
  const theme = useTheme()
  const snackbar = useContext(SnackbarContext)

  const isUpSm = useMediaQuery(theme.breakpoints.up('sm'))

  /*******************************************************
   * STATE
   *******************************************************/
  const [isGranularLoading, setIsGranularLoading] = useState<{
    starred?: boolean
    permissions?: boolean
    status?: boolean
  }>({
    starred: false,
    permissions: false,
    status: false,
  })
  const [anchorEl, setAnchorEl] = useState<ReactNode>(null)
  const [menuTarget, setMenuTarget] = useState<string>('')

  /*******************************************************
   * CONTEXT
   *******************************************************/
  const queryResult = useContext(DealContext)
  const { dealId } = queryResult

  /*******************************************************
   * GRAPHQL
   *******************************************************/

  /**
   * queries
   */
  const { data, refetch, loading, subscribeToMore, error } = useQuery<
    GetItemQuery,
    GetItemQueryVariables
  >(GET_ITEM, {
    variables: {
      id: itemId,
    },
  })
  useErrorHandler(error)

  const [deleteItemMutation, { error: deleteItemMutationErrors }] = useMutation<
    DeleteItemMutation,
    DeleteItemMutationVariables
  >(DELETE_ITEM)
  useErrorHandler(deleteItemMutationErrors)

  /**
   * mutations
   */
  const [updateItemMutation, { error: updateItemMutationErrors }] = useMutation<
    ItemUpdateMutation,
    ItemUpdateMutationVariables
  >(ITEM_UPDATE)
  useErrorHandler(updateItemMutationErrors)

  /**
   *
   */
  const onClose = () => {
    setMenuTarget('')
    setAnchorEl(null)
  }

  /*******************************************************
   * FUNCTIONS
   *******************************************************/

  /**
   *
   * @param value
   */
  const changeItemStatus = async (value: ItemStatus) => {
    setIsGranularLoading({ status: true })
    void (await updateItemMutation({
      variables: {
        input: {
          id: itemId,
          status: value,
        },
      },

      // @todo 12 - 21 review
      update(cache, { data: itemUpdateMutationData }) {
        if (isOfType<ItemType>(itemUpdateMutationData, '__typename', 'Item')) {
          cache.writeQuery<GetItemQuery, GetItemQueryVariables>({
            query: GET_ITEM,
            variables: { id: itemId },
            data: {
              item: {
                ...itemUpdateMutationData.itemUpdate,
                __typename: 'Item',
              },
            },
          })
        }
      },
    }))
    setIsGranularLoading({ status: false })
  }
  /**
   *
   * @return {Promise<void>}
   */
  const deleteItem = async () => {
    await deleteItemMutation({
      variables: {
        id: itemId,
      },
    })

    snackbar.setMessage({
      message: 'Item deleted',
      type: SnackbarTypes.WARNING,
      show: true,
      anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
    })

    BROWSER_HISTORY.push({
      pathname: `/${RouteNames.DEALS}/${RouteNames.DETAILS}/${String(dealId)}/${
        RouteNames.STAGE_VIEW
      }`,
      search: '',
    })
  }

  /**
   *
   */
  const getMenuItems = (): DropDownProps['menuItems'] => {
    if (
      !itemActions?.some((action) => {
        if (!action) return false
        return [...ITEM_DELETE_PERMISSIONS].includes(action)
      })
    ) {
      return
    }

    return [
      {
        clickAction: deleteItem,
        Component: (
          <>
            <StyledListItemIcon>
              <DeleteIcon color={'error'} />
            </StyledListItemIcon>
            <StyledListItemText primary="Delete" />
          </>
        ),
      },
    ]
  }

  const itemActions = data?.item.actions

  if (!data) return <></>

  return (
    <ConditionalLoader condition={!loading}>
      <SC.ToolBarSection
        item
        sm={5}
        container
        data-testid="item-toolbar-section"
        alignItems="center"
        justifyContent="flex-end"
      >
        {isUpSm && (
          <>
            <StarredButton
              itemId={data.item.id}
              disabled={!itemActions?.includes(ItemActions.ITEM_STAR)}
              isStarred={!!data.item.starred}
              isHiddenLabel
              iconSize={32}
            />
            <Spacer width={20} />
          </>
        )}
        <Grid item>
          {isGranularLoading.status ? (
            <CenteredLoader size={30} />
          ) : (
            <SC.NoHoverButton
              variant="contained"
              startIcon={<DoneIcon />}
              $closed={data.item.status === ItemStatus.CLOSED}
              disabled={
                !itemActions?.some((action) => {
                  if (!action) {
                    return false
                  }

                  return data.item.status === 'OPEN'
                    ? ITEM_CLOSE_PERMISSIONS.includes(action)
                    : ITEM_REOPEN_PERMISSIONS.includes(action)
                })
              }
              onClick={() => {
                const newStatus =
                  data.item.status === ItemStatus.CLOSED
                    ? ItemStatus.OPEN
                    : ItemStatus.CLOSED
                void changeItemStatus(newStatus)
              }}
            >
              {data.item.status === ItemStatus.CLOSED
                ? strings.MARK_OPEN
                : strings.MARK_CLOSED}
            </SC.NoHoverButton>
          )}
        </Grid>
        {!isUpSm && (
          <>
            <Spacer width={30} />
            <StarredButton
              itemId={data.item.id}
              disabled={!itemActions?.includes(ItemActions.ITEM_STAR)}
              isStarred={!!data.item.starred}
              isHiddenLabel
            />
            <Spacer width={10} />
          </>
        )}

        <Spacer width={15} />
        <Grid>
          {itemActions?.some((action) => {
            if (!action) return false
            return [...ITEM_DELETE_PERMISSIONS].includes(action)
          }) && (
            <SC.DropdownEditIcon
              onClick={(event) => {
                setAnchorEl(event.currentTarget)
                setMenuTarget('ITEM_EDIT')
              }}
              data-testid="button-item-edit"
            />
          )}
          {itemActions && (
            <Dropdown
              menuItems={getMenuItems()}
              menuTarget={menuTarget}
              target={'ITEM_EDIT'}
              openEventTarget={anchorEl}
              handleCloseOutside={onClose}
            />
          )}
        </Grid>
      </SC.ToolBarSection>
    </ConditionalLoader>
  )
}

export default ItemToolBar
