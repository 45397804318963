// @components
import Password from './components/Password'
import Notifications from './components/Notifications'

const AccountSettings = () => (
  <>
    <Password />
    <Notifications />
  </>
)

export default AccountSettings
