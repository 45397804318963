// @react
import { ReactElement } from 'react'
// @common
import strings from 'constants/strings'
// @components
import DealsGrid from 'modules/deal/pages/Deals/components/DealsGridFiltered'
// @graphql
import {
  InvitesOrderByList,
  InviteStatus,
  Order,
  InviteType,
  GetDealsInvitesQuery,
  GetDealsInvitesQueryVariables,
} from 'graphql/graphqlTypes'
import { GET_DEALS_INVITES } from 'graphql/queries/invite.query'
import { QueryResult, useQuery } from '@apollo/client'
import config from 'constants/config'

type PropsType = {
  staticProps: {
    filter: InviteStatus
  }
}

/**
 *
 * @param props
 * @constructor
 * @return {any}
 */
const DealsInvites = ({ staticProps }: PropsType): ReactElement => {
  /*******************************************************
   * PROPS
   *******************************************************/
  let subtitlePrefix = ''

  /**
   * QUERIES
   */
  const queryResult: QueryResult<
    GetDealsInvitesQuery,
    GetDealsInvitesQueryVariables
  > = useQuery<GetDealsInvitesQuery, GetDealsInvitesQueryVariables>(
    GET_DEALS_INVITES,
    {
      fetchPolicy: 'network-only',
      variables: {
        filters: {
          status: staticProps.filter,
          type:
            staticProps.filter === InviteStatus.RECEIVED
              ? [InviteType.DEAL, InviteType.TEAM, InviteType.THIRD_PARTY]
              : [InviteType.DEAL],
        },
        orderBy: {
          value: InvitesOrderByList.CREATED_DATE,
          order: Order.DESC,
        },
        pageSize: config.defaultPageSize,
      },
    },
  )

  /*******************************************************
   * FUNCTIONS
   *******************************************************/
  switch (staticProps.filter) {
    case InviteStatus.RECEIVED:
      subtitlePrefix = strings.FROM
      break
    case InviteStatus.SENT:
      subtitlePrefix = strings.SENT_TO
      break
  }

  /**
   *
   */
  return (
    <DealsGrid
      {...queryResult}
      cardPrefixes={{
        subtitle: subtitlePrefix,
        byLine: strings.DATE_SENT,
        user: 'user',
      }}
    />
  )
}

export default DealsInvites
