// @react
import React from 'react'
import * as SC from './styledComponents/statusTag'

interface Props {
  status: string | null
}

const StatusTag = (props: Props) => {
  const { status } = props
  return status ? (
    <SC.Wrapper>
      <SC.StyledTag status={status}>{status}</SC.StyledTag>
    </SC.Wrapper>
  ) : null
}

export default StatusTag
