import styled from 'styled-components'

export const ChartWrapper = styled.div`
  height: 648px;
  width: 100%;
  padding: 5%;
  background-color: white;
`

export const ChartTableWrapper = styled.div`
  width: 100%;
  padding: 5%;
  background-color: white;
`
