import { ReactNode } from 'react'
import { Scalars, TeamRole } from 'graphql/graphqlTypes'
import { ItemStatus } from '../../../../types'

export enum ContactItemVariant {
  MENU = 'MENU',
  REMOVE = 'REMOVE',
  ACTION = 'ACTION',
}

export type MenuItem = { text: string; handler: () => void }

export type ContactItemCTA = {
  variant: ContactItemVariant
  handler?: () => void
  tooltipText?: string
  text?: string
  items?: {
    handler: () => void
    tooltipText?: string
    text: string
  }[]
  menuTrigger?: ReactNode
}

export type ContactItemComponentProps = {
  userId?: Scalars['ID']
  name: string[] | string
  text?: string[] | string
  avatar?: string
  type?: string
  email?: string
  contactStatus?: string | null
  teamType?: string | null
  showLeadIcon?: boolean
  itemStatus?: ItemStatus
  disabled?: boolean
  buttons?: ContactItemCTA[]
  onUserClick?: () => void
  role?: TeamRole | null
  hideStatus?: boolean
  hideTeamType?: boolean
  isAdded?: boolean
  injectClasses?: {
    ctaWrapper: string
  }
}
