import React from 'react'
import strings from 'constants/strings'
import * as SC from './styledComponents'

type PropsType = {
  testid?: string
  header?: {
    title: string
    label?: string
    seeAll?: string
  }
  children: React.ReactNode
  margin?: 'sm' | 'md'
}

const DashboardSection = ({ children, header, margin, testid }: PropsType) => (
  <SC.Section data-testid={testid} margin={margin}>
    {header ? (
      <SC.SectionHeader>
        <SC.SectionHeaderLeft>
          <SC.SectionHeaderTitle>{header.title}</SC.SectionHeaderTitle>
          {header.label ? (
            <SC.SectionHeaderLabel>{header.label}</SC.SectionHeaderLabel>
          ) : null}
        </SC.SectionHeaderLeft>
        {header.seeAll ? (
          <SC.SectionHeaderSeeAll to={header.seeAll}>
            {strings.SEE_ALL}
          </SC.SectionHeaderSeeAll>
        ) : null}
      </SC.SectionHeader>
    ) : null}
    {children}
  </SC.Section>
)

export default DashboardSection
