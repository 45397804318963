// language=GraphQL
import { gql } from '@apollo/client'
import { DOCUMENT_FRAGMENT } from '../fragments/document.fragment'
import { MINI_USER } from './../fragments/user.fragment'

export const GET_INVITES = gql`
  query GetInvites(
    $filters: InvitesFilterInput
    $orderBy: InvitesOrderByInput
    $page: Int
  ) {
    invites(filters: $filters, orderBy: $orderBy, page: $page) {
      data {
        id
        createdBy {
          ...MiniUser
          company
        }
        type
        createdDate
        email
        deal {
          id
          name
          stage
        }
        user {
          firstName
          lastName
          id
        }
      }
      meta {
        page
        totalPages
        pageSize
      }
    }
  }
  ${MINI_USER}
`

export const GET_DEALS_INVITES = gql`
  query GetDealsInvites(
    $filters: InvitesFilterInput
    $orderBy: InvitesOrderByInput
    $pageSize: Int
    $page: Int
  ) {
    dealsInvites(
      filters: $filters
      orderBy: $orderBy
      pageSize: $pageSize
      page: $page
    ) {
      data {
        id
        email
        createdBy {
          ...MiniUser
          company
        }
        type
        createdDate
        deal {
          id
          name
          createdDate
          modifiedDate
          stage
          status
          propertyType
          imageDocs {
            ...DocumentNode
          }
        }
        user {
          ...MiniUser
        }
      }
      meta {
        count
        page
        totalPages
        pageSize
      }
    }
  }
  ${DOCUMENT_FRAGMENT}
  ${MINI_USER}
`

export const GET_INVITES_COUNT = gql`
  query GetInvitesCount {
    invitesCount {
      sent {
        count
        type
      }
      received {
        count
        type
      }
    }
  }
`
