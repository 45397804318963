import { Table, TableBody, TableCell, TableRow } from '@material-ui/core'
import styled from 'styled-components'
import { colors } from 'constants/colors'

export const style = () => ({
  root: {
    width: '100%',
  },
  table: {
    width: '100%',
  },
  headerRow: {
    cursor: 'auto',
    '&:hover': {
      backgroundColor: 'transparent',
    },
  },
})

export const StyledTableHeadRow = styled(TableRow).attrs({
  classes: { root: `table-head` },
})``

export const StyledTableHeadCell = styled(TableCell)`
  ${(props) =>
    props.$withHoverStyles &&
    `
    cursor: pointer;

    :hover {
      background-color: ${colors.paleGrey};
    }
  `}
`

export const StyledTableBody = styled(TableBody).attrs({
  classes: { root: `table-body` },
})``

export const InfoWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
`

export const StyledTable = styled(Table)``
