// @design
import * as SC from 'modules/common/components/_UI_DUMB/DashboardCard/styledComponents'
import PropertySingleIcon from 'modules/common/components/_UI_DUMB/Icons/SvgIcons/PropertySingle'
import PropertyPortfolioIcon from 'modules/common/components/_UI_DUMB/Icons/SvgIcons/PropertyPortfolio'
// @common
import {
  Deal,
  DealStatus,
  DealType,
  PropertyType,
  User,
} from 'graphql/graphqlTypes'
import DealModel, { DealStageLabels } from 'modules/deal/models/deal'
import { useContext } from 'react'
import { UserContext } from 'modules/user/context/user'
import strings from 'constants/strings'
import { format, parseISO } from 'date-fns'
import config from 'constants/config'
import DashboardCard, {
  GridVariantType,
} from 'modules/common/components/_UI_DUMB/DashboardCard'

/*******************************************************
 * TYPES
 *******************************************************/
type PropsType = {
  testid?: string
  href?: string | null
  image?: string | null
  title: string
  byLine?: string
  onClose?: () => void
  cta?: {
    label: string
    onClick: () => void
  }
  deal?: {
    status?: DealStatus | null
    propertyType?: PropertyType | null
    createdBy?: Partial<User>
    type?: DealType
  }
  invite?: any
}

/**
 *
 * @param {string | undefined} testid
 * @param {string | null | undefined} href
 * @param {string | null | undefined} image
 * @param {string} title
 * @param {{status?: DealStatus | null, propertyType?: PropertyType | null, createdBy?: Partial<User>, type?: DealType} | undefined} deal
 * @param {(() => void) | undefined} onClose
 * @param {{label: string, onClick: () => void} | undefined} cta
 * @param {any} invite
 * @returns {JSX.Element}
 * @constructor
 */
const DealCard = ({
  testid = 'grid-card',
  href,
  image,
  title,
  deal,
  onClose,
  cta,
  invite,
}: PropsType) => {
  /*******************************************************
   * CONTEXT
   *******************************************************/
  const user = useContext(UserContext).user

  const dealData: Deal = deal || invite?.deal
  const headerStageLabel = DealModel.syntheticStage(dealData.stage)

  /*******************************************************
   * RENDER COMPONENTS
   *******************************************************/
  let imageNode: null | JSX.Element | React.FunctionComponent = image ? (
    <img src={image} alt={title} />
  ) : null

  // for the deal nodes
  if (dealData) {
    // figure out which property type we're working with
    // to display the correct icon
    const DealIcon =
      dealData.propertyType === 'SINGLE'
        ? PropertySingleIcon
        : PropertyPortfolioIcon

    // use uploaded image (if any), but fall back to the corresponding property icon
    imageNode = imageNode || <DealIcon />
  }

  /**
   *
   * @returns {JSX.Element}
   */
  const renderDealSubtitle = () => {
    if (
      dealData?.type === DealType.SOLO ||
      dealData.status === DealStatus.DRAFT
    ) {
      return
    }
    const isOriginator = dealData?.createdBy?.id === user?.id
    const subtitle = isOriginator
      ? `${strings.SENT_TO} ${String(
          dealData?.recipientTeam?.teamLead?.firstName,
        )} ${String(dealData?.recipientTeam?.teamLead?.lastName)}`
      : `${strings.FROM} ${String(dealData?.createdBy?.firstName)} ${String(
          dealData?.createdBy?.lastName,
        )}`
    return <SC.CardSubtitle>{subtitle}</SC.CardSubtitle>
  }

  /**
   *
   * @returns {JSX.Element}
   */
  const renderInviteSubtitle = () => {
    const isAuthor = invite?.createdBy?.id === user?.id
    const subtitle = isAuthor
      ? `${strings.SENT_TO} ${String(invite?.user?.firstName)} ${String(
          invite?.user?.lastName,
        )}`
      : `${strings.FROM} ${String(invite?.createdBy?.firstName)} ${String(
          invite?.createdBy?.lastName,
        )}`

    return <SC.CardSubtitle>{subtitle}</SC.CardSubtitle>
  }

  /**
   *
   * @returns {JSX.Element | null}
   */
  const renderStage = () => {
    if ([DealStatus.OPEN, DealStatus.DEAD].includes(dealData.status)) {
      return (
        <SC.DealStage>
          {DealStageLabels[headerStageLabel] || headerStageLabel}
        </SC.DealStage>
      )
    }
    return null
  }

  /**
   *
   * @returns {JSX.Element | null}
   */
  const getByline = () => {
    const lastUpdated = format(
      parseISO(dealData.modifiedDate),
      config.dateFullMonthFormat,
    )

    return lastUpdated ? (
      <SC.CardByLine>
        <SC.ScheduleIcon />
        {`${strings.LAST_UPDATED} ${lastUpdated}`}
      </SC.CardByLine>
    ) : null
  }

  /******************************************************
   * RENDER
   *******************************************************/
  return (
    <DashboardCard
      testid={testid}
      title={title}
      imageNode={imageNode}
      status={dealData.status}
      cta={cta}
      href={href}
      subtitle={invite ? renderInviteSubtitle() : renderDealSubtitle()}
      byLine={getByline()}
      onClose={onClose}
      bottomCTA={renderStage()}
      variant={String(dealData.status) as GridVariantType}
    />
  )
}

export default DealCard
