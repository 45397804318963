// @react
import styled from 'styled-components'
import colors from 'constants/colors'
import { from, device } from 'modules/common/styledComponents/MediaQueries'
import { ContentContainer as Container } from 'modules/common/styledComponents/Global'

export const ProfileContainer = styled(Container)`
  margin: 0px auto;
  background-color: ${colors.white};
  padding: 50px;
  ${from(device.ipadL)} {
    max-width: 768px;
  }
`

export const ProfileEditPasswordContainer = styled(Container)`
  ${from(device.ipadL)} {
    max-width: 663px;
  }
`

// export const ProfileContactsWrap = styled.div`
//   display: flex;
//   align-items: stretch;
//   flex-wrap: wrap;
//   background-color: ${colors.paleGrey};
// `
//
// export const ProfileContactWrap = styled.div`
//   width: 100%;
//   & ${StyledContactRequestItem}:hover {
//     background-color: ${colors.darkenBlue};
//   }
//   ${from(device.tablet)} {
//     width: 100%;
//     display: flex;
//     flex-direction: column;
//
//     > * {
//       height: 100%;
//     }
//   }
// `
//
// export const ProfileAddContactList = styled.div`
//   height: 360px;
//   overflow: auto;
//   border-top: 1px solid ${colors.veryLightBlue};
//   border-bottom: 1px solid ${colors.veryLightBlue};
// `
//
// export const ProfileAddContactMemberWrap = styled.div`
//   margin-bottom: 50px;
// `
