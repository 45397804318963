import SwitchboardDialog from 'modules/common/components/_UI_DUMB/ModalDialogs/SwitchboardDialog'
import { DialogContent, Typography } from '@material-ui/core'
import styled from 'styled-components'
import { device, from, to } from 'modules/common/styledComponents/MediaQueries'

export const StyledSwitchboardDialog = styled(SwitchboardDialog)`
  .MuiDialogTitle-root {
    position: absolute;
    right: 1em;
    top: 1em;
    padding: 0;
  }
`

export const StyledDialogContent = styled(DialogContent)`
  && {
    display: flex;
    padding-left: 0;
  }
`

export const Image = styled.div`
  width: 70px;
  flex-shrink: 0;

  img {
    display: block;
    width: 100%;

    ${to(device.tabletS)} {
      object-fit: cover;
      object-position: 100% 0;
      height: 100%;
    }
  }

  ${to(device.tabletS)} {
    overflow: hidden;
    margin-right: 16px;
  }

  ${from(device.mobileL)} {
    width: 100px;
  }

  ${from(device.tabletS)} {
    width: 300px;
    margin-left: 5px;
    margin-right: 30px;
  }
`

export const Content = styled.div`
  padding-top: 20px;
  padding-bottom: 20px;
  align-self: center;

  ${to(device.tabletS)} {
    margin-top: 20px;
  }
`

export const Title = styled(Typography)`
  margin-bottom: 1em;
  color: currentColor;
  font-size: 20px;

  ${from(device.tabletS)} {
    font-size: 28px;
  }
`

export const Text = styled.div`
  margin-bottom: 30px;
`
