import styled from 'styled-components'
import colors from 'constants/colors'
import { Typography } from '@material-ui/core'

export const Message = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 20px;
`

export const MyMessage = styled(Message)`
  flex-direction: row;
`

export const NotMyMessage = styled(Message)`
  flex-direction: row-reverse;
`

export const MessageTextWrap = styled.div`
  display: inline-block;
  position: relative;
  margin-bottom: 15px;
  margin-top: 10px;
  box-shadow: 0 2px 4px 0 ${colors.veryLightBlue};
  border: solid 1px ${colors.veryLightBlue};
  border-radius: 3px;

  ${MyMessage} & {
    margin-left: 10px;
    color: ${colors.battleshipGrey};
  }

  ${NotMyMessage} & {
    margin-right: 30px;
    border: 0;
    background: ${colors.dusk};
    color: ${colors.white};
  }
`

export const MessageText = styled.div`
  display: inline-block;
  padding: 16px 30px;
  position: relative;
  z-index: 2;
  font-size: 14px;
`

export const TypographyMini = styled(Typography)`
  && {
    font-size: 10px;
    color: ${colors.aquaMarineDark};
  }
  display: block;
  position: absolute;
  bottom: -17px;
  font-style: italic;
`

export const MessageArrow = styled.span`
    position: absolute;
    border-top: 7px solid ${colors.white};
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    height: 0;
    width: 7px;
    z-index: 4;
    left: -7px;
    top: 0;

    &::before,
    &::after {
      content: '';
      display: block;
      position: absolute;
      height: 1px;
      width: 10px;
      background: ${colors.veryLightBlue};
    }

    &::before {
      left: -8px;
      top: -8px;
    }

    &::after {
      top: -4px;
      left: -9px;
      transform: rotate(45deg);
    }

    ${MyMessage} & {
        border-top-color: ${colors.white};
    }

    ${NotMyMessage} & {
        right: -6px;
        left: initial;
        border-top: 10px solid ${colors.dusk};
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        width: 10px;

        &::before,
        &::after {
          display: none;
        }
      }
    }
`
