// @react
import React from 'react'
// @design
import { Checkbox, FormControlLabel, makeStyles } from '@material-ui/core'
import colors from 'constants/colors'
/*******************************************************
 * CONST
 *******************************************************/
export const style = () => ({
  label: {
    fontSize: '13px',
    color: colors.battleshipGrey,
  },
})
const useStyles = makeStyles(style)
/*******************************************************
 * TYPE
 *******************************************************/
export type PropsType = {
  label: string
  className?: string
  labelClassName?: string
  onChange: (evt: boolean) => void
  disabled: boolean
  value: string | undefined
  input?: unknown
}

/**
 *
 * @param onChange
 * @param label
 * @param labelClassName
 * @param disabled
 * @param value
 * @param input
 * @constructor
 */
const FormCheckbox: React.FunctionComponent<PropsType> = ({
  onChange,
  label,
  labelClassName,
  disabled,
  // @todo where do these values come from, this makes no sense
  value,
  input = {},
}) => {
  /*******************************************************
   * HOOKS
   *******************************************************/
  const classes = useStyles()

  return (
    <FormControlLabel
      classes={{
        label: labelClassName ? labelClassName : classes.label,
      }}
      control={
        <Checkbox
          color="primary"
          value={value}
          name={value}
          checked={!!value}
          onChange={(e) => onChange(e.target.checked)}
          {...input}
        />
      }
      disabled={disabled}
      label={label}
    />
  )
}

export default FormCheckbox
