import React, { Dispatch, SetStateAction, useContext, useState } from 'react'
// @design
import * as ContactItemSC from 'modules/common/components/_UI_SMART/ContactItem/styledComponents/contactItem'
import { DialogActions, DialogContent, Grid } from '@material-ui/core'
// @common
import strings from 'constants/strings'
import useDebounce from 'modules/common/hooks/useDebounce'
import { config } from 'constants/config'
import useAsyncEffect from 'use-async-effect'
import useErrorHandler from 'modules/common/hooks/useErrorHandler'
// @components
import StyledDialog from 'modules/common/components/_UI_DUMB/ModalDialogs/styledComponents/StyledDialog'
import SwitchboardDialog from 'modules/common/components/_UI_DUMB/ModalDialogs/SwitchboardDialog'
import ProfileMyContactsSearch from '../../../user/components/ProfileMyContactsSearch'
// @graphql
import { useQuery } from '@apollo/client'
import { GET_THIRDPARTY_USERS } from 'graphql/queries/user.query'
import {
  GetThirdpartyUsersQuery,
  GetThirdpartyUsersQueryVariables,
  Item,
  Order,
  Scalars,
  SimplifiedUser,
  User,
  UsersOrderByList,
} from 'graphql/graphqlTypes'
import ConditionalLoader from '../../../common/components/_UI_DUMB/Loaders/ConditionalLoader'
import DealTeamThirdPartyContactsList from 'modules/deal/components/UserRelationsManage/DealTeamThirdPartyContactsList'
import { DealContext } from 'modules/deal/contexts/deal'

/*******************************************************
 * TYPE
 *******************************************************/
interface PropsType {
  dealId: Scalars['ID']
  isDialogOpen: boolean
  setIsDialogOpen: Dispatch<SetStateAction<boolean>>
  selectedContacts: (SimplifiedUser | User)[]
  item: Item
  isAllowedToEdit?: boolean
  btnTitle?: string
}

/**
 *
 * @param props
 * @constructor
 */
const ManageThirdParty = ({
  dealId,
  isDialogOpen,
  setIsDialogOpen,
  item,
}: PropsType) => {
  const [searchTerm, setSearchTerm] = useState<string>('')
  const debouncedSearchValue = useDebounce(searchTerm, config.debounce)
  const queryResult = useContext(DealContext)
  /*******************************************************
   * GRAPHQL
   *******************************************************/
  const { data, refetch, loading, fetchMore, error } = useQuery<
    GetThirdpartyUsersQuery,
    GetThirdpartyUsersQueryVariables
  >(GET_THIRDPARTY_USERS, {
    variables: {
      orderBy: {
        order: Order.ASC,
        value: UsersOrderByList.FIRST_NAME,
      },
      dealId: dealId,
    },
  })
  useErrorHandler(error)

  /*******************************************************
   * LIFECYCLE HOOKS
   *******************************************************/
  useAsyncEffect(async () => {
    if (debouncedSearchValue !== undefined) {
      void (await refetch({ filters: { search: debouncedSearchValue } }))
    }
  }, [debouncedSearchValue])

  /*******************************************************
   * FUNCTIONS
   *******************************************************/
  /**
   *
   */
  const loadMoreHandler = async () => {
    if (data?.thirdPartyUsers.meta && fetchMore) {
      const newPageNum = (data.thirdPartyUsers.meta.page || 0) + 1

      await fetchMore<
        GetThirdpartyUsersQuery,
        GetThirdpartyUsersQueryVariables
      >({
        variables: {
          page: newPageNum,
        },
      })
    }
  }

  /**
   *
   */
  const handleClose = () => {
    setIsDialogOpen(false)
  }

  const isLoadMoreNeeded =
    (data?.thirdPartyUsers.meta?.page || 0) <
    (data?.thirdPartyUsers.meta?.totalPages || 0) - 1

  /*******************************************************
   * RENDER
   *******************************************************/
  return (
    <SwitchboardDialog
      open={isDialogOpen}
      onClose={handleClose}
      title={strings.ADD_THIRD_PARTY}
    >
      <DialogContent>
        <ContactItemSC.DialogContactListWrap>
          <Grid container spacing={1} alignItems="center">
            <ProfileMyContactsSearch
              value={searchTerm}
              onClear={() => setSearchTerm('')}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </Grid>
        </ContactItemSC.DialogContactListWrap>
        <ContactItemSC.DialogContactList>
          <ConditionalLoader condition={!(!data && loading)}>
            <DealTeamThirdPartyContactsList
              userContactsData={data?.thirdPartyUsers.data}
              item={item}
              loadMore={{
                // @todo load mores hard to calculate since we're getting the item users right from the ITEM query
                isLoadMoreNeeded,
                loadMoreHandler,
              }}
              refetch={async () => {
                queryResult.refetch && (await queryResult.refetch())
                await refetch()
              }}
            />
          </ConditionalLoader>
        </ContactItemSC.DialogContactList>
      </DialogContent>
      <DialogActions>
        <StyledDialog.Wrap>
          <Grid container justifyContent="center" spacing={3}>
            <Grid item xs={12} sm={6}>
              <StyledDialog.SubmitBtn
                onClick={handleClose}
                variant="contained"
                color="primary"
              >
                {strings.CLOSE}
              </StyledDialog.SubmitBtn>
            </Grid>
          </Grid>
        </StyledDialog.Wrap>
      </DialogActions>
    </SwitchboardDialog>
  )
}

export default ManageThirdParty
