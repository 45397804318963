// @react
import React from 'react'

const PropertyPortfolio: React.FunctionComponent = () => (
  <svg
    className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 37 37"
    fill="none"
  >
    <rect width="37" height="37" rx="5" fill="#3BA2D9" />
    <path
      d="M23.5 17.5v-8h-10v18h4v-4h2v4h4v-10Zm-6 4h-2v-2h2v2Zm0-4h-2v-2h2v2Zm0-4h-2v-2h2v2Zm4 8h-2v-2h2v2Zm0-4h-2v-2h2v2Zm0-4h-2v-2h2v2ZM12.5 21.389V16.5h-6v11h2.4v-2.444h1.2V27.5h2.4V21.389Zm-3.6 2.444H7.7v-1.222h1.2v1.222Zm0-2.444H7.7v-1.222h1.2v1.222Zm0-2.445H7.7v-1.222h1.2v1.222Zm2.4 4.89h-1.2V22.61h1.2v1.222Zm0-2.445h-1.2v-1.222h1.2v1.222Zm0-2.445h-1.2v-1.222h1.2v1.222ZM30.5 21.389V16.5h-6v11h2.4v-2.444h1.2V27.5h2.4V21.389Zm-3.6 2.444h-1.2v-1.222h1.2v1.222Zm0-2.444h-1.2v-1.222h1.2v1.222Zm0-2.445h-1.2v-1.222h1.2v1.222Zm2.4 4.89h-1.2V22.61h1.2v1.222Zm0-2.445h-1.2v-1.222h1.2v1.222Zm0-2.445h-1.2v-1.222h1.2v1.222Z"
      fill="#fff"
    />
  </svg>
)

export default PropertyPortfolio
