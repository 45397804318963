// @react
import React, { Dispatch, ReactElement, SetStateAction } from 'react'
// @design
// @components
import ChipBtn from 'modules/common/components/_UI_DUMB/ChipBtn'
import strings from 'constants/strings'

/*******************************************************
 * TYPES
 *******************************************************/
type PropsType = {
  activeIndex: number
  tabHandler?: Dispatch<SetStateAction<number>>
  tab1Active: boolean
}

/**
 *
 * @param {number} activeIndex
 * @param tabHandler
 * @param disableTabs
 * @returns {React.ReactElement}
 * @constructor
 */
const DealTeamsTabs: React.FC<PropsType> = ({
  activeIndex,
  tabHandler,
  tab1Active,
}: PropsType): ReactElement => {
  /*******************************************************
   * RENDER
   *******************************************************/
  return (
    <>
      <ChipBtn
        labelText={strings.MY_TEAM}
        isActive={activeIndex === 0}
        onClick={() => {
          tabHandler && tabHandler(0)
        }}
      />
      <ChipBtn
        labelText={strings.THEIR_TEAM}
        isActive={activeIndex === 1}
        disabled={!tab1Active}
        onClick={() => {
          tabHandler && tabHandler(1)
        }}
      />
    </>
  )
}

export default DealTeamsTabs
