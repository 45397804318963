import { Avatar, Button, Grid, Typography } from '@material-ui/core'
import { flexCenter } from 'modules/common/styledComponents/Mixins'
import { MessagesContainer } from 'modules/common/styledComponents/Message'
import styled from 'styled-components'
import colors from 'constants/colors'
import MoreVert from '@material-ui/icons/MoreVert'
import { ContentContainer } from 'modules/common/styledComponents/Global'
import { to, device } from 'modules/common/styledComponents/MediaQueries'

export const DealName = styled(Typography)`
  && {
    margin-bottom: 10px;
    text-transform: uppercase;
    &:hover {
      cursor: pointer;
    }
  }
`

export const ItemTitle = styled(Typography)`
  && {
    font-size: 26px;
  }
`

export const StyledAvatar = styled(Avatar)`
  && {
    width: 35px;
    height: 35px;
    margin-right: 20px;
  }
`

export const TitleGrid = styled(Grid)`
  && {
    margin-bottom: 40px;
    ${to(device.materialXS)} {
      margin-bottom: 30px;
    }
  }
`

export const AssignedLabel = styled(Typography)`
  && {
    font-size: 12px;
    line-height: 1.4;
    text-transform: uppercase;
  }
`

export const AssignedTo = styled(Button)`
  && {
    padding: 0;
    font-weight: 300;
    text-transform: none;
    text-decoration: underline;
    min-height: 0;
    color: ${colors.yellowishOrangeAlt};

    &:hover {
      background: none;
      text-decoration: underline;
    }
  }
`

export const NoHoverButton = styled(Button)<{ closed?: boolean }>`
  && {
    background-color: ${(props) =>
      props.$closed ? colors.aquaMarineLight : colors.aquaMarine}
    color: ${(props) => (props.$closed ? colors.statusOnline : colors.white)}

    &:hover {
      cursor: auto !important;
      background-color: ${(props) =>
        !props.$closed ? colors.aquaMarineLight : colors.aquaMarine}
      color: ${(props) => (!props.$closed ? colors.statusOnline : colors.white)}
  }
`

export const ItemsDetailContainer = styled(ContentContainer)`
  && {
    overflow: auto;
  }
`

export const FlexCenter = styled.div`
  ${flexCenter};
`

export const StyledMessagesContainer = styled(MessagesContainer)`
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`

export const DropdownEditIcon = styled(MoreVert)`
  && {
    color: ${colors.yellowishOrange};
    font-size: 25px;
    cursor: pointer;
    margin-left: 5px;
    vertical-align: middle;
    transform: rotate(90deg);
  }
`

export const ContactsWrap = styled.div`
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  margin-bottom: 12px;
`

export const ThirdPartyTitle = styled.div`
  height: 17px;
  margin: 0 386px 15px 0;
  font-size: 12px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: var(--dark);
`

export const CardRow = styled.div`
  padding: 8px 14px 8px 20px;
  font-size: 14px;
  color: ${colors.dark};
  ${(props) =>
    props.noBorder
      ? `padding-bottom: 0px;`
      : `border-bottom: 1px solid ${colors.paleGrey};
  `}

  p {
    margin: 0px;
  }
`

export const CardValue = styled.span`
  font-weight: 600;
  font-size: 14px;
`

export const TitleSection = styled(Grid)`
  && {
    ${to(device.materialXS)} {
      margin-bottom: 20px;
    }
  }
`

export const ToolBarSection = styled(Grid)`
  && {
    ${to(device.materialXS)} {
      justify-content: flex-start;
    }
  }
`
