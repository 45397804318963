import { RouteType, RouteNames } from 'constants/routeNames'
import Notifications from './pages/Notifications'

const routes: RouteType[] = [
  {
    component: Notifications,
    path: `/${RouteNames.DEALS}/${RouteNames.DETAILS}/:${RouteNames.ID}/${RouteNames.NOTIFICATIONS}`,
    type: 'PRIVATE',
    layout: 'MAIN',
  },
  {
    component: Notifications,
    path: `/${RouteNames.NOTIFICATIONS}`,
    type: 'PRIVATE',
    exact: true,
    layout: 'MAIN',
  },
]

const Routes = { routes }

export default Routes
