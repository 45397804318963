// @react
import React from 'react'
import { Button as MuiButton, ButtonProps } from '@material-ui/core'
import * as SC from './styledComponents'
/*******************************************************
 * CONSTANTS
 *******************************************************/
const BtnColors = {
  aquaMarine: 'aquaMarine',
  aquaMarineLight: 'aquaMarineLight',
}

const mapButton = {
  [BtnColors.aquaMarine]: SC.ButtonAqua,
  [BtnColors.aquaMarineLight]: SC.ButtonAquaMarineLight,
}

/*******************************************************
 * TYPES
 *******************************************************/
type PropTypes = Omit<ButtonProps, 'color'> & {
  color: ButtonProps['color'] | keyof typeof BtnColors
}

/**
 *
 * @param props
 * @constructor
 */
const Button = (props: PropTypes) => {
  const Button = mapButton[props.color || ''] || (MuiButton as typeof MuiButton)
  return <Button {...props} />
}

export default Button
