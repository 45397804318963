import { Provinces } from 'country-and-province'
import {
  AreaUnit,
  ClearHeightUnit,
  InterestType,
  LandAreaUnit,
  RentableAreaUnit,
  SiteAreaUnit,
} from 'graphql/graphqlTypes'

/**
 *
 */
export const getRegions = (country) => {
  return country ? Provinces.byCountryCode(country) : []
}

export const getAddressLabels = (country) => {
  switch (country) {
    case 'CA':
      return {
        addressLabel: 'Street',
        postalCodeLabel: 'Postal Code',
        stateLabel: 'Province',
      }
    case 'CN':
      return {
        addressLabel: 'Bldg # & Street',
        postalCodeLabel: 'District',
        stateLabel: 'Province',
      }
    case undefined:
    case 'US':
      return {
        addressLabel: 'Address',
        postalCodeLabel: 'Zip Code',
        stateLabel: 'State',
      }
    default:
      return {
        addressLabel: 'Address',
        postalCodeLabel: 'Postal Code',
        stateLabel: 'Province/Region',
      }
  }
}

export const tenancyTypes = ['SINGLE', 'MULTI']
export const loadingTypes = ['Standard', 'Cross-dock']
export const debtTypes = ['Free and clear', 'Encumbered']
export const zoningTypes = [
  'Commercial',
  'Residential',
  'Industrial',
  'Agricultural',
  'Other',
]
export const entitlementsTypes = ['Yes', 'No']
export const industryTypes = [
  'Hospitality',
  'Industrial',
  'Land',
  'Other',
  'Office',
  'Residential',
  'Retail',
]
export const subsectorTypes = [
  'Multifamily',
  'Single family',
  'Manufactured housing',
  'Retirement',
  'Student housing',
]
export const productType = [
  'Mall',
  'Strip centre',
  'Power centre',
  'Community centre',
  'Lifestyle',
  'Factory outlet',
  'Single tenant',
  'Other',
]

export const interestTypes = [
  { value: InterestType.FEE_SIMPLE, label: 'Fee simple' },
  { value: InterestType.GROUND_LEASE, label: 'Ground lease' },
]

export const rentableAreaUnits = [
  { value: RentableAreaUnit.SQFT, label: 'sqft' },
  { value: RentableAreaUnit.SQM, label: 'sqm' },
]

export const siteAreaUnits = [
  { value: SiteAreaUnit.ACRES, label: 'ac' },
  { value: SiteAreaUnit.SQFT, label: 'sqft' },
  { value: SiteAreaUnit.SQM, label: 'sqm' },
]

export const clearHeightUnits = [
  { value: ClearHeightUnit.FEET, label: 'ft' },
  { value: ClearHeightUnit.METERS, label: 'm' },
]

export const landAreaUnits = [
  { value: LandAreaUnit.ACRES, label: 'ac' },
  { value: LandAreaUnit.SQFT, label: 'sqft' },
  { value: LandAreaUnit.SQM, label: 'sqm' },
]

export const areaUnits = [
  { value: AreaUnit.SQFT, label: 'sqft' },
  { value: AreaUnit.SQM, label: 'sqm' },
]

export const propertyTypeFields = {
  SINGLE: ['country', 'zip', 'state', 'city', 'tenancy', 'yearOfConstruction'],
  PORTFOLIO: ['numberOfProperties'],
}
