export enum TotalsSumBy {
  COUNT = 'COUNT',
  GAV = 'GAV',
  NAV = 'NAV',
}

export enum ChartType {
  CHART = 'CHART',
  TABLE = 'TABLE',
}
