// language=GraphQL
import { gql } from '@apollo/client'

/**
 * @todo
 * notifications on deal node gives 'user' field error
 */
export const DEAL_UPDATE_SUBSCRIPTION = gql`
  subscription onDealUpdated {
    dealUpdated {
      deal {
        actions
        id
        name
        stage
        status
        propertyType
        rentableAreaValue
        rentableAreaUnit
        yearOfConstruction
        tenancy
        country
        state
        zip
        description
        industry
      }
      updatedBy {
        id
        firstName
        lastName
      }
      userMessage {
        id
        type
        msg
      }
    }
  }
`

export const DEAL_DELETE_SUBSCRIPTION = gql`
  subscription onDealDelete {
    dealDeleted {
      deal {
        id
      }
    }
  }
`
