// @react
import { useEffect } from 'react'
// @design
import * as ContactItemSC from 'modules/common/components/_UI_SMART/ContactItem/styledComponents/contactItem'
// @components
import ProfileNoContacts from 'modules/user/components/ProfileNoContacts'
import UserContactsList from 'modules/deal/components/UserRelationsManage/UserContactsList'
import ConditionalLoader from 'modules/common/components/_UI_DUMB/Loaders/ConditionalLoader'
// @graphql
import {
  ContactStatus,
  GetUserContactsQuery,
  GetUserContactsQueryVariables,
  OnUserDeclinedInvitationToContactsSubscription,
  OnUserDeclinedInvitationToContactsSubscriptionVariables,
} from 'graphql/graphqlTypes'
import { useQuery } from '@apollo/client'
import { USER_DECLINED_INVITATION_TO_CONTACTS } from 'graphql/subscriptions/user.subscription'
import { GET_USER_CONTACTS } from 'graphql/queries/user.query'

/**
 *
 *     WHEN would this state need to be updated?
 *
 *     1. when this user declines invite, UI removes invite from list (local)
 *     2. when this user accepts invite, UI removes invite from list (local)
 *     3. when user who sent invite deletes invite (sub)
 *
 * @constructor
 */
const ProfileSentContacts = () => {
  /*******************************************************
   * GRAPHQL
   *******************************************************/
  const { data, loading, refetch, subscribeToMore } = useQuery<
    GetUserContactsQuery,
    GetUserContactsQueryVariables
  >(GET_USER_CONTACTS, {
    variables: {
      contactStatus: ContactStatus.INVITED,
    },
    fetchPolicy: 'network-only',
  })

  useEffect(() => {
    return subscribeToMore<
      OnUserDeclinedInvitationToContactsSubscription,
      OnUserDeclinedInvitationToContactsSubscriptionVariables
    >({
      document: USER_DECLINED_INVITATION_TO_CONTACTS,
      updateQuery: (prev, { subscriptionData }) => {
        const declinedContact =
          subscriptionData.data.userDeclinedInvitationToContacts
        return {
          ...prev,
          userContacts: {
            ...prev.userContacts,
            data: prev.userContacts.data?.filter(
              // @todo contact ID should not be nullable
              (contact) => contact?.id === declinedContact.id,
            ),
            meta: {
              ...prev.userContacts.meta,
              count: (prev.userContacts.meta?.count || 0) - 1,
            },
          },
        }
      },
    })
  }, [subscribeToMore])

  return (
    <ConditionalLoader condition={!loading}>
      <ContactItemSC.ContactListWrap data-testid="profile-sent-invites">
        <ContactItemSC.ContactWrap>
          <UserContactsList
            userContactsData={data?.userContacts.data}
            refetch={refetch}
            notFound={<ProfileNoContacts variant="empty-sent" />}
          />
        </ContactItemSC.ContactWrap>
      </ContactItemSC.ContactListWrap>
    </ConditionalLoader>
  )
}

export default ProfileSentContacts
