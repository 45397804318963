// @react
import React from 'react'
import CenteredLoader from 'modules/common/components/_UI_DUMB/Loaders/CenteredLoader'

/**
 *
 * @constructor
 */
const PageLoader = () => {
  return (
    <div className="app-loader">
      <CenteredLoader size={70} />
    </div>
  )
}

export default PageLoader
