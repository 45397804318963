import { gql } from '@apollo/client'
import { FILTERS_FRAGMENT } from './filters.fragment'
import { SIMPLIFIED_USER } from './user.fragment'
import { COMMENT } from './comment.fragment'

export const ITEM_META = gql`
  fragment ItemMeta on ItemsMeta {
    count
    page
    totalPages
    pageSize
    allFilters {
      ...FiltersNode
    }
  }
  ${FILTERS_FRAGMENT}
`

export const SIMPLE_ITEM = gql`
  fragment SimpleItem on Item {
    id
    createdDate
    modifiedDate
    name
    description
    permissions
    stage
    status
    starred
    laneOrder
    dueDate
  }
`

export const ITEM_WITH_USER = gql`
  fragment ItemWithUser on Item {
    id
    createdDate
    modifiedDate
    name
    description
    stage
    status
    starred
    laneOrder
    dueDate
    createdBy {
      id
      firstName
      lastName
    }
  }
`

export const FULL_ITEM = gql`
  fragment FullItem on Item {
    id
    createdDate
    modifiedDate
    name
    description
    stage
    status
    starred
    laneOrder
    dueDate
    deal {
      id
      name
      status
    }
    team {
      teamMembership {
        teamId
        userId
      }
    }
    actions
    createdBy {
      id
      lastName
      firstName
      avatar {
        id
        file {
          id
          location
          versionId
        }
      }
    }
    permissions
    documents {
      id
      name
      finalized
      file {
        id
        originalName
        extension
        mimetype
        location
        actions
        versionId
      }
    }
    comments {
      ...CommentNode
    }
    users {
      ...SimplifiedUserNode
    }
  }
  ${SIMPLIFIED_USER}
  ${COMMENT}
`
