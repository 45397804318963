// @design
import { GridSize } from '@material-ui/core'
import * as SC from './styledComponents'
import CancelIcon from '@material-ui/icons/Cancel'
// @common
import { DealStatus, PropertyType, User } from 'graphql/graphqlTypes'
import { DealStatusLabels } from 'modules/deal/models/deal'
import { ReactElement } from 'react'

/*******************************************************
 * TYPES
 *******************************************************/
type GridItemClassType = {
  [index: string]: GridSize
}

export type GridVariantType = 'deal' | 'contact'

type PropsType = {
  testid?: string
  title: string
  imageNode?: null | JSX.Element | React.FunctionComponent
  status?: DealStatus
  gridItemClasses?: GridItemClassType
  cta?: {
    label: string
    onClick: () => void
  }
  href?: string | null
  subtitle?: string | ReactElement
  byLine?: string | ReactElement | null
  onClose?: () => void
  bottomCTA?: ReactElement | null
  variant?: GridVariantType
}

/*******************************************************
 * CONSTANTS
 *******************************************************/
// grid item responsive classes
const defaultGridItemClasses: GridItemClassType = {
  xs: 12,
  sm: 6,
  md: 4,
}

/**
 *
 * @param {string | undefined} testid
 * @param {string | null | undefined} href
 * @param {string | null | undefined} image
 * @param {string} title
 * @param {string | undefined} subtitle
 * @param {string | undefined} byLine
 * @param {{status?: DealStatus | null, propertyType?: PropertyType | null, createdBy?: Partial<User>} | undefined} deal
 * @param {"deal" | "contact" | undefined} variant
 * @param {(() => void) | undefined} onClose
 * @param {{label: string, onClick: () => void} | undefined} cta
 * @returns {JSX.Element}
 * @constructor
 */
const DashboardCard = ({
  testid = 'grid-card-inner',
  title,
  imageNode,
  status,
  cta,
  href,
  subtitle,
  byLine,
  onClose,
  bottomCTA,
  variant = 'deal',
  gridItemClasses,
}: PropsType) => {
  /*******************************************************
   * RENDER
   *******************************************************/
  const wrapperClasses = { ...defaultGridItemClasses, ...gridItemClasses }
  return (
    <SC.CardGridItemWrap item {...wrapperClasses} data-testid={'grid-card'}>
      <SC.CardOuterWrap data-testid={testid}>
        {href ? <SC.CardLink to={href} /> : null}

        {status ? (
          <SC.CardStatus variant={variant}>
            {DealStatusLabels[status]}
          </SC.CardStatus>
        ) : null}

        <SC.CardWrap type={variant}>
          {onClose ? (
            <SC.CloseIcon aria-label="close" onClick={onClose}>
              <CancelIcon />
            </SC.CloseIcon>
          ) : null}

          <SC.CardTop>
            <SC.CardImage>{imageNode}</SC.CardImage>
            <SC.CardText>
              <SC.CardTitle>{title}</SC.CardTitle>
              {subtitle}
              {byLine}
              {bottomCTA}
            </SC.CardText>
          </SC.CardTop>

          {cta ? (
            <SC.CardButton
              variant="contained"
              color="primary"
              onClick={cta.onClick}
            >
              {cta.label}
            </SC.CardButton>
          ) : null}
        </SC.CardWrap>
      </SC.CardOuterWrap>
    </SC.CardGridItemWrap>
  )
}

export default DashboardCard
