// reviewed for unused exports 05/22
import { Avatar, Button } from '@material-ui/core'
import { colors } from 'constants/colors'
import { device, from } from 'modules/common/styledComponents/MediaQueries'
import styled from 'styled-components'
import FormInput from 'modules/common/components/_FORM_ELEMENTS/HookForm/form-inputs/FormInput'
import FormSelect from 'modules/common/components/_FORM_ELEMENTS/HookForm/form-inputs/FormSelect'

export const LinkedInImg = styled.img`
  margin: 0 6px 0 0;
  width: 16px;
  height: 16px;
`

export const LoadingButton = styled(Button)`
  && {
    width: 100%;
  }
`

export const UpdateBtn = styled(Button)`
  && {
    text-transform: none;
    padding-left: 30px;
    padding-right: 30px;
    margin-right: 20px;
  }

  &&.Mui-disabled {
    background: ${colors.veryLightBlue};
    color: ${colors.battleshipGrey};
  }
`
export const EditAvatarWrap = styled.div`
  display: flex;
  align-items: center;
  margin: 0px 10px 5px;
`
export const EditAvatarInner = styled.div`
  display: flex;
  flex-direction: column;
`

export const ImportLinkedIn = styled(Button)`
  && {
    display: flex;
    margin: 0;
    font-size: 13px;
    align-items: center;
    text-decoration: underline;
    cursor: pointer;
    text-transform: none;
    color: ${colors.dark};
    padding: 0;
  }
`

export const StyledAvatar = styled(Avatar)`
  && {
    width: 130px;
    height: 130px;
    margin-right: 1.3em;

    ${from(device.mobileL)} {
      width: 170px;
      height: 170px;
      margin-right: 40px;
    }

    ${from(device.ipad)} {
      width: 200px;
      height: 200px;
    }
  }
`

export const Actions = styled.div`
  display: flex;
  width: 100%;
  margin-top: 2em;
  padding: 10px 0px;
  flex-direction: row;
  align-items: flex-start;

  ${from(device.ipad)} {
    margin-top: 50px;
  }
`
export const DeactivateBtn = styled(Button)`
  && {
    padding-left: 30px;
    padding-right: 30px;
    text-transform: none;
    color: ${colors.battleshipGrey};
    background-color: ${colors.veryLightBlue};
  }
`

export const HalfWidthInput = styled(FormInput)`
  && {
    ${from(600)} {
      width: calc(50% - 12px);
    }
  }
`
export const HalfWidthSelect = styled(FormSelect)`
  && {
    ${from(600)} {
      width: calc(50% - 12px);
    }
  }
`

export const UpdateImgBtn = styled(Button)`
  text-transform: none;
  color: ${colors.battleshipGrey};
  background-color: ${colors.veryLightBlue};
  width: 180px;
  act && {
    margin-bottom: 20px;
  }
`

export const FieldWrapper = styled.div`
  padding: 10px;
`

export const Wrapper = styled.div``
