import { gql } from '@apollo/client'

export const COMMENT = gql`
  fragment CommentNode on Comment {
    id
    body
    createdBy {
      id
      lastName
      firstName
      avatar {
        id
        file {
          id
          location
          versionId
        }
      }
    }
    actions
    createdDate
  }
`
