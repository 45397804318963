// @react
import React, { useEffect, useState } from 'react'
import { Route, useLocation } from 'react-router'
// @design
import * as SCGLobal from 'modules/common/styledComponents/Global'
import * as TabsSC from 'modules/common/styledComponents/Tabs'
// @common
import strings from 'constants/strings'
import { RouteNames } from 'constants/routeNames'
// @components
import ProfilePendingContacts from 'modules/user/components/ProfilePendingContacts'
import ProfileSentContacts from 'modules/user/components/ProfileSentContacts'
import ProfileMyContacts from 'modules/user/components/ProfileMyContacts'
import ConditionalLoader from 'modules/common/components/_UI_DUMB/Loaders/ConditionalLoader'
import NetworkTabs from './components/NetworkTabs'
// @graphql
import { useQuery, useSubscription } from '@apollo/client'
import { GET_NETWORK_SUMMARY } from 'graphql/queries/user.query'
import {
  GetNetworkSummaryQuery,
  GetNetworkSummaryQueryVariables,
  OnUserCanceledInvitationToContactsSubscription,
  OnUserCanceledInvitationToContactsSubscriptionVariables,
  OnUserDeclinedInvitationToContactsSubscription,
  OnUserDeclinedInvitationToContactsSubscriptionVariables,
} from 'graphql/graphqlTypes'

import {
  USER_CANCELED_INVITATION_TO_CONTACTS,
  USER_DECLINED_INVITATION_TO_CONTACTS,
  USER_INVITED_TO_CONTACTS,
  USER_REMOVED_FROM_CONTACTS,
} from 'graphql/subscriptions/user.subscription'

/**
 *
 * @constructor
 */
const ProfileContacts = () => {
  /*******************************************************
   * HOOKS
   *******************************************************/
  const location = useLocation()

  /*******************************************************
   * STATE
   *******************************************************/
  const [tabIndex, changeIndex] = useState<number>(2)

  /*******************************************************
   * GRAPHQL
   *******************************************************/

  /**
   * queries
   */
  const { data, loading, refetch } = useQuery<
    GetNetworkSummaryQuery,
    GetNetworkSummaryQueryVariables
  >(GET_NETWORK_SUMMARY, {
    fetchPolicy: 'network-only',
  })

  /**
   * subscriptions
   */
  useSubscription<
    OnUserDeclinedInvitationToContactsSubscription,
    OnUserDeclinedInvitationToContactsSubscriptionVariables
  >(USER_DECLINED_INVITATION_TO_CONTACTS, {
    onSubscriptionData: async ({ subscriptionData }) => {
      await refetch()
    },
  })

  useSubscription<
    OnUserCanceledInvitationToContactsSubscription,
    OnUserCanceledInvitationToContactsSubscriptionVariables
  >(USER_CANCELED_INVITATION_TO_CONTACTS, {
    onSubscriptionData: async ({ subscriptionData }) => {
      await refetch()
    },
  })

  useSubscription(USER_REMOVED_FROM_CONTACTS, {
    onSubscriptionData: async ({ subscriptionData }) => {
      await refetch()
    },
  })

  useSubscription(USER_INVITED_TO_CONTACTS, {
    onSubscriptionData: async ({ subscriptionData }) => {
      await refetch()
    },
  })

  /*******************************************************
   * LIFECYCLE HOOKS
   *******************************************************/

  // @todo honestly we should be more elegantly leveraging react router for subroutes
  useEffect(() => {
    switch (location.pathname) {
      case `/${RouteNames.NETWORK}/${RouteNames.CONTACT_PENDING}`:
        changeIndex(0)
        break
      case `/${RouteNames.NETWORK}/${RouteNames.CONTACT_SENT}`:
        changeIndex(1)
        break
      case `/${RouteNames.NETWORK}`:
        changeIndex(2)
        break
    }
  }, [location])

  const counts = {
    contactsCount: data?.userContactsAccepted.meta?.count || 0,
    sentCount: data?.userContactsInvited.meta?.count || 0,
    pendingCount: data?.userContactsPending.meta?.count || 0,
  }

  /*******************************************************
   * RENDER COMPONENTS
   *******************************************************/
  const Tabs = () => (
    <>
      <Route
        path={`/${RouteNames.NETWORK}/${RouteNames.CONTACT_PENDING}`}
        exact
        render={() => <ProfilePendingContacts />}
      />
      <Route
        path={`/${RouteNames.NETWORK}/${RouteNames.CONTACT_SENT}`}
        exact
        render={() => <ProfileSentContacts />}
      />
      <Route
        exact
        path={`/${RouteNames.NETWORK}`}
        render={() => <ProfileMyContacts />}
      />
    </>
  )

  /*******************************************************
   * RENDER
   *******************************************************/

  return (
    <SCGLobal.ContentContainer>
      <TabsSC.TabsContainer>
        <ConditionalLoader condition={!loading}>
          <TabsSC.ViewBy>{strings.VIEW_BY}</TabsSC.ViewBy>
          <SCGLobal.PushContainer>
            <NetworkTabs
              tabHandler={changeIndex}
              activeIndex={tabIndex}
              counts={counts}
            />
          </SCGLobal.PushContainer>
        </ConditionalLoader>
        <Tabs />
      </TabsSC.TabsContainer>
    </SCGLobal.ContentContainer>
  )
}

export default ProfileContacts
