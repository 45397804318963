// language=GraphQL
import { gql } from '@apollo/client'
import { NOTIFICATION_ITEM } from '../fragments/notification.fragment'

export const GET_NOTIFICATIONS = gql`
  query GetNotifications(
    $filters: NotificationsFilterInput
    $orderBy: NotificationsOrderByInput
    $page: Int
    $pageSize: Int
  ) {
    notifications(
      filters: $filters
      orderBy: $orderBy
      page: $page
      pageSize: $pageSize
    ) {
      data {
        ...NotificationItem
      }
      meta {
        page
        orderBy {
          value
          order
        }
        totalPages
        unreadCount
        count
      }
    }
  }
  ${NOTIFICATION_ITEM}
`
