// @react
import React, { useState, useCallback, useContext, useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
// @design
import {
  Box,
  DialogActions,
  DialogContent,
  Grid,
  RadioGroup,
  Typography,
} from '@material-ui/core'
import * as SC from './styledComponents/createItem'

// @constants
import { RouteNames } from 'constants/routeNames'
// @common
import SwitchboardDialog from 'modules/common/components/_UI_DUMB/ModalDialogs/SwitchboardDialog'
import validationSchema from 'modules/item/utils/validation'
import history from 'utils/history'
import strings from 'constants/strings'
import useErrorHandler from 'modules/common/hooks/useErrorHandler'
import { UserContext } from 'modules/user/context/user'
import { DealContext } from 'modules/deal/contexts/deal'
import { yupResolver } from '@hookform/resolvers/yup'
import { ITEM_STAGE_OPTIONS } from 'modules/item/config'
// @components
import Spacer from 'modules/common/components/_UI_DUMB/Spacer'
import DragAndDropArea from 'modules/common/components/_UI_DUMB/DragAndDropArea/DragAndDropArea'
import FormQuill from 'modules/common/components/_FORM_ELEMENTS/HookForm/form-inputs/FormQuill'
import StarredButton from 'modules/item/components/Create/components/StarredButton'
import FormRadio from 'modules/common/components/_FORM_ELEMENTS/HookForm/form-inputs/FormRadio'
// @graphql
import { CREATE_ITEM } from 'graphql/mutations/item.mutation'
import { useMutation } from '@apollo/client'
import { GET_ITEMS } from 'graphql/queries/item.query'
import {
  CreateItemMutation,
  CreateItemMutationVariables,
  DealStage,
  SimplifiedUser,
  TeamRole,
  User,
  Document,
} from 'graphql/graphqlTypes'
import ConditionalLoader from 'modules/common/components/_UI_DUMB/Loaders/ConditionalLoader'

interface InjectProps {
  open: boolean
  handleClose: () => void
  initData?: { stage: DealStage }
}

type PropsType = InjectProps

const defaultValue = {
  permissions: 'MY_TEAM',
  description: '',
  name: '',
  starred: false,
}

/**
 *
 * @param props
 * @constructor
 * @return {any}
 */
const CreateItem = ({ handleClose, open, initData }: PropsType) => {
  /*******************************************************
   * STATE
   *******************************************************/
  const [itemContacts, setItemContacts] = useState<(SimplifiedUser | User)[]>(
    [],
  )
  const [documents, setDocuments] = useState<Document[]>([])

  /*******************************************************
   * CONTEXT
   *******************************************************/
  const queryResult = useContext(DealContext)
  const { dealId, data } = queryResult
  const { stage, myRole } = data?.deal
    ? data.deal
    : { stage: undefined, myRole: undefined }
  const currentUser = useContext(UserContext).user

  /*******************************************************
   * HOOKS
   *******************************************************/
  const { control, formState, handleSubmit, reset } = useForm({
    defaultValues: defaultValue,
    resolver: yupResolver(validationSchema),
  })

  useEffect(() => {
    ;(control as { formName?: string }).formName = 'item-create'
  }, [])

  useEffect(() => {
    if (currentUser?.id && myRole === TeamRole.THIRD_PARTY) {
      setItemContacts([currentUser])
    }
  }, [currentUser, myRole])

  const updateItemFiles = useCallback((documents: Document[] | undefined) => {
    if (documents) {
      setDocuments((prevState) => [...prevState, ...documents])
    }
  }, [])

  /*******************************************************
   * GRAPHQL
   *******************************************************/

  /**
   * mutation
   */
  const [createItem, { loading, error: createItemMutationError }] = useMutation<
    CreateItemMutation,
    CreateItemMutationVariables
  >(CREATE_ITEM, { refetchQueries: [GET_ITEMS] })
  useErrorHandler(createItemMutationError)

  /*******************************************************
   * FUNCTIONS
   *******************************************************/
  /**
   *
   */
  const close = () => {
    setItemContacts([])
    handleClose()
  }

  const canEditVisibility = myRole !== TeamRole.THIRD_PARTY

  /**
   *
   * @param inputData
   */
  const create = async (inputData) => {
    const documentIds = documents.map((document) => {
      return document.id
    })
    const { data } = await createItem({
      variables: {
        input: {
          deal: dealId,
          users: itemContacts.map((contact) => contact.id),
          ...inputData,
          documents: documentIds,
        },
      },
    })

    if (!createItemMutationError && data?.itemCreate.id) {
      history.push(
        `/${RouteNames.DEALS}/${RouteNames.DETAILS}/${String(dealId)}/${
          RouteNames.ITEMS
        }/${RouteNames.DETAILS}/${data.itemCreate.id}`,
      )
      setDocuments([])
      reset()
      close()
    }
  }
  /**
   *
   * @param id
   */
  const removeDocument = (id) => {
    const newDocuments = documents.filter((doc) => doc.id !== id)
    setDocuments(newDocuments)
  }

  /*******************************************************
   * RENDER COMPONENTS
   *******************************************************/

  if (!dealId) {
    return <></>
  }

  return (
    <>
      <SwitchboardDialog
        open={open}
        onClose={close}
        title={strings.CREATE_ITEM}
      >
        <form onSubmit={handleSubmit((data) => create(data))}>
          <DialogContent>
            <SC.FlexCenter>
              <SC.StyledInputField
                name="name"
                control={control}
                formState={formState}
                label={`${strings.ITEM_NAME} *`}
              />
              <Controller
                name="starred"
                control={control}
                render={(values) => (
                  <StarredButton {...values} label="Starred" />
                )}
              />
            </SC.FlexCenter>
            <Grid container spacing={4}>
              <Grid item xs={6}>
                <SC.StyledSelectField
                  name="stage"
                  control={control}
                  formState={formState}
                  defaultValue={initData?.stage || stage}
                  label={`${strings.STAGE} *`}
                  options={ITEM_STAGE_OPTIONS}
                />
              </Grid>
              <Grid item xs={6}>
                <SC.StyledDatepickerField
                  control={control}
                  name="dueDate"
                  formState={formState}
                  label={'Due date'}
                  isFullWidth
                  isRightAlign
                />
              </Grid>
            </Grid>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={12} sm={2}>
                <Typography variant="body1" className="uppercase">
                  {strings.VISIBILITY}
                </Typography>
              </Grid>
              <Box mt={4}>
                <Controller
                  as={
                    <RadioGroup>
                      <SC.FlexWrap>
                        <Grid item>
                          <FormRadio
                            disabled={!canEditVisibility}
                            label={strings.MY_TEAM}
                            value={'MY_TEAM'}
                          />
                        </Grid>
                        <Grid item>
                          <FormRadio
                            disabled={!canEditVisibility}
                            label={strings.BOTH_TEAMS}
                            value={'BOTH_TEAMS'}
                          />
                        </Grid>
                      </SC.FlexWrap>
                    </RadioGroup>
                  }
                  name="permissions"
                  control={control}
                />
              </Box>
            </Grid>
            <Spacer height={itemContacts.length ? 10 : 0} />
            <Box pb={2}>
              <Spacer height={itemContacts.length ? 10 : 0} />
            </Box>
            <FormQuill
              name="description"
              control={control}
              label={strings.ITEM_DESCRIPTION}
              formState={formState}
            />
            <Spacer height={40} />
            <DragAndDropArea
              testId="create-item-file-upload"
              documents={documents}
              removeFileCallback={(id) => {
                removeDocument(id)
              }}
              fileUpdateCallback={updateItemFiles}
              canUpload={true}
              canDeleteDocuments={true}
              isMultiple={true}
              forceDisableFinalize={true}
            />
          </DialogContent>
          <DialogActions>
            <Grid item xs={12} sm={6}>
              <SC.SubmitButton
                variant="contained"
                disabled={loading}
                type="submit"
                color="primary"
              >
                <ConditionalLoader condition={!loading}>
                  {strings.CREATE_ITEM}
                </ConditionalLoader>
              </SC.SubmitButton>
            </Grid>
          </DialogActions>
        </form>
      </SwitchboardDialog>

      {/*<ManageThirdParty*/}
      {/*  isDialogOpen={isDialogOpen}*/}
      {/*  setIsDialogOpen={setIsDialogOpen}*/}
      {/*  selectedContacts={itemContacts}*/}
      {/*  dealId={dealId}*/}
      {/*/>*/}
    </>
  )
}

export default CreateItem
