import styled from 'styled-components'
import colors from 'constants/colors'
import { device, from } from 'modules/common/styledComponents/MediaQueries'

export const Container = styled.div`
  ${from(device.ipad)} {
    padding-bottom: 58px;
  }
`

export const InputWrap = styled.div`
  display: flex;
  justify-content: space-evenly;
  padding: 1em;
  border-bottom: 1px solid ${colors.veryLightBlue};

  ${from(device.ipad)} {
    padding: 20px 40px;
  }
`
