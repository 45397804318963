// @react
import React, { ReactNode, useEffect, useState } from 'react'
// @common
import uuid from 'react-uuid'
// @design
import {
  IconButton,
  makeStyles,
  SlideProps,
  Snackbar as MaterialSnackbar,
  SnackbarContent,
  Theme,
} from '@material-ui/core'
import clsx from 'clsx'
import { Slide } from '@material-ui/core'
import colors from 'constants/colors'
// @components
import CloseIcon from '@material-ui/icons/Close'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import WarningIcon from '@material-ui/icons/Warning'
import ErrorIcon from '@material-ui/icons/Error'
import InfoIcon from '@material-ui/icons/Info'
import parse from 'html-react-parser'

/*******************************************************
 * CONST
 *******************************************************/

export const snackbarIconVariant = {
  SUCCESS: CheckCircleIcon,
  WARNING: WarningIcon,
  ERROR: ErrorIcon,
  INFO: InfoIcon,
}

export enum SnackbarTypes {
  SUCCESS = 'SUCCESS',
  INFO = 'INFO',
  ERROR = 'ERROR',
  WARNING = 'WARNING',
}
/*******************************************************
 * STYLES
 *******************************************************/
const useStyles = makeStyles((theme: Theme) => ({
  [SnackbarTypes.SUCCESS]: {
    backgroundColor: colors.aquaMarine,
  },
  [SnackbarTypes.ERROR]: {
    backgroundColor: colors.grapefruit,
  },
  [SnackbarTypes.INFO]: {
    backgroundColor: colors.white,
    color: colors.battleshipGrey,
  },
  [SnackbarTypes.WARNING]: {
    backgroundColor: theme.palette.primary.main,
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
}))

/*******************************************************
 * TYPES
 *******************************************************/
export interface SnackbarProps {
  message: string | ReactNode
  show: boolean
  onClose: () => void
  type: SnackbarTypes
  icon: ReactNode | null
  closeCallback?: () => void
  anchorOrigin: {
    vertical: 'bottom' | 'top'
    horizontal: 'left' | 'right'
  }
}
type TransitionProps = Omit<SlideProps, 'direction'>

function TransitionLeft(props) {
  return <Slide {...props} direction="left" />
}
function TransitionRight(props) {
  return <Slide {...props} direction="right" />
}
// function TransitionUp(props) {
//   return <Slide {...props} direction="up" />
// }
//
// function TransitionDown(props) {
//   return <Slide {...props} direction="down" />
// }
/**
 *
 * @param anchorOrigin
 * @param message
 * @param show
 * @param onClose
 * @param type
 * @param icon
 * @param closeCallback
 * @constructor
 */
const Snackbar = ({
  anchorOrigin,
  message,
  show,
  onClose,
  type,
  icon,
  closeCallback,
}: SnackbarProps) => {
  /*******************************************************
   * HOOKS
   *******************************************************/
  const classes = useStyles()
  const Icon = snackbarIconVariant[type]
  const [transition, setTransition] = useState<
    React.ComponentType<TransitionProps> | undefined
  >(() => TransitionRight)

  useEffect(() => {
    if (anchorOrigin.horizontal === 'right') {
      setTransition(() => TransitionLeft)
    }
    return () => {
      message && closeCallback && closeCallback()
    }
  }, [anchorOrigin])

  /*******************************************************
   * RENDER
   *******************************************************/
  const renderMsg = () => {
    return (
      <SnackbarContent
        className={clsx(classes[type])}
        message={
          <span id="client-snackbar" className={classes.message}>
            {!icon && (
              <Icon className={clsx(classes.icon, classes.iconVariant)} />
            )}
            {!!icon && (
              <div
                style={{
                  marginRight: '15px',
                }}
              >
                {icon}
              </div>
            )}
            {typeof message === 'string' ? parse(message) : message}
          </span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="close"
            color="inherit"
            onClick={onClose}
          >
            <CloseIcon className={classes.icon} />
          </IconButton>,
        ]}
      />
    )
  }

  /*******************************************************
   * RENDER
   *******************************************************/
  return (
    <MaterialSnackbar
      anchorOrigin={{
        vertical: anchorOrigin.vertical,
        horizontal: anchorOrigin.horizontal,
      }}
      open={show}
      autoHideDuration={6000}
      onClose={onClose}
      key={uuid()}
      TransitionComponent={transition}
    >
      <div>{renderMsg()}</div>
    </MaterialSnackbar>
  )
}
Snackbar.defaultProps = {
  message: '',
  show: false,
  onClose: () => {},
  icon: null,
  type: SnackbarTypes.SUCCESS,
  anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
}
export default Snackbar
