// @react
import { ReactElement, useContext, useEffect } from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
// @common
import { RouteNames } from 'constants/routeNames'
// @components
import NotFound from 'modules/common/pages/NotFound'
// @graphql
import { useQuery } from '@apollo/client'
import { GET_ITEMS } from 'graphql/queries/item.query'
import { DealContext } from 'modules/deal/contexts/deal'
import { SnackbarTypes } from 'modules/common/components/_UI_DUMB/Snackbars/Snackbar'
import { SnackbarContext } from 'modules/common/context/snackbar'
import ItemsList from 'modules/common/components/_UI_SMART/ItemsList'
import {
  GetItemsQuery,
  GetItemsQueryVariables,
  ItemsOrderByList,
  Order,
  Scalars,
} from 'graphql/graphqlTypes'

type TParams = {
  id: string | undefined
}

type PropsType = RouteComponentProps<TParams>

const Items = (props: PropsType): ReactElement => {
  /**
   * context
   */
  /*******************************************************
   * CONTEXT
   *******************************************************/
  const { dealId, data, loading } = useContext(DealContext)
  const snackbar = useContext(SnackbarContext)

  /**
   * queries
   */
  /*******************************************************
   * GRAPHQL
   *******************************************************/
  const { error } = useQuery<GetItemsQuery, GetItemsQueryVariables>(GET_ITEMS, {
    variables: {
      orderBy: {
        order: Order.DESC,
        value: ItemsOrderByList.NAME,
      },
      filters: {
        deal: [dealId || ''],
      },
    },
    skip: !dealId,
  })

  /*******************************************************
   * LIFECYCLE HOOKS
   *******************************************************/
  useEffect(() => {
    if (error && error[0]?.message) {
      snackbar.setMessage({
        type: SnackbarTypes.ERROR,
        message: error[0]?.message,
        show: true,
      })
    }
  }, [error])

  /*******************************************************
   * FUNCTIONS
   *******************************************************/

  /**
   *
   * @param id
   * @param parentId
   */
  const handleClick = (
    id: Scalars['ID'],
    parentId: Scalars['ID'] | null | undefined,
  ) => {
    props.history.push(
      `/${RouteNames.DEALS}/${RouteNames.DETAILS}/${String(parentId)}/${String(
        RouteNames.ITEMS,
      )}/${String(RouteNames.DETAILS)}/${id}`,
    )
  }
  /*******************************************************
   * RENDER COMPONENTS
   *******************************************************/
  if (dealId && !data && !loading) {
    return <NotFound />
  }

  /*******************************************************
   * RENDER
   *******************************************************/
  return <ItemsList dealId={dealId || undefined} handleClick={handleClick} />
}

export default withRouter(Items)
