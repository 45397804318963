// @react
import React, { useState } from 'react'
// @design
import SectionEmpty from 'modules/common/components/_UI_DUMB/Section/SectionEmpty'
// @common
import strings from 'constants/strings'
// @components
import illustration from './contacts.svg'
import ProfileAddContact from 'modules/user/components/ProfileAddContact'

type PropsType = {
  search?: {
    term: string
    clear: () => void
  }
  variant: 'empty-pending' | 'more-pending' | 'empty-contacts' | 'empty-sent'
}
const getTextVariant = (variant: string, searchTerm?: string): string => {
  if (searchTerm) return strings.NETWORK_EMPTY_SEARCH(searchTerm)

  switch (variant) {
    case 'more-pending':
      return strings.NETWORK_PENDING_MORE
    case 'empty-pending':
      return strings.NETWORK_EMPTY_PENDING
    case 'empty-contacts':
      return strings.NETWORK_EMPTY_CONTACTS
    case 'empty-sent':
      return strings.NETWORK_EMPTY_SENT
    default:
      return ''
  }
}
/**
 *
 * @param {"empty-pending" | "more-pending" | "empty-contacts" | "empty-sent"} variant
 * @param {{term: string, clear: () => void} | undefined} search
 * @returns {JSX.Element}
 * @constructor
 */
const ProfileNoContacts = ({ variant, search }: PropsType) => {
  /*******************************************************
   * STATE
   *******************************************************/
  const [showContactModal, setShowContactModal] = useState(false)

  const toggleContactModal = () => setShowContactModal(!showContactModal)

  // cta that appears only when the search term exists
  const searchCta = search
    ? [
        {
          label: strings.SEL_ALL_CONTACTS,
          onClick: search.clear,
        },
      ]
    : []

  return (
    <SectionEmpty
      title={getTextVariant(variant, search?.term)}
      illustration={
        !search
          ? {
              src: illustration,
              placement: 'bottom',
            }
          : undefined
      }
      cta={[
        ...searchCta,
        {
          type: 'primary',
          onClick: toggleContactModal,
          label: strings.ADD_CONTACT_CTA,
        },
      ]}
      modal={
        <ProfileAddContact
          open={showContactModal}
          handleClose={toggleContactModal}
        />
      }
    />
  )
}

export default ProfileNoContacts
