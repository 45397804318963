// @react
import { useContext } from 'react'
// @constants
import strings from 'constants/strings'
// @contexts
import { SnackbarContext } from 'modules/common/context/snackbar'
import { UserContext } from 'modules/user/context/user'
// @components
import * as SC from './styledComponents'
import { SnackbarTypes } from 'modules/common/components/_UI_DUMB/Snackbars/Snackbar'
import CenteredLoader from 'modules/common/components/_UI_DUMB/Loaders/CenteredLoader'
import { RadioGroup } from '@material-ui/core'
import { Controller, useForm } from 'react-hook-form'
import FormCheckbox from 'modules/common/components/_FORM_ELEMENTS/HookForm/form-inputs/FormCheckbox'
import FormRadio from 'modules/common/components/_FORM_ELEMENTS/HookForm/form-inputs/FormRadio'
// @graphql
import { UPDATE_USER } from 'graphql/mutations/user.mutation'
import { GET_USER_INFO } from 'graphql/queries/user.query'
import { useMutation } from '@apollo/client'
import {
  UserUpdateMutation,
  UserUpdateMutationVariables,
  NotificationPriority,
} from 'graphql/graphqlTypes'

/**
 *
 */
const ProfileNotifications = () => {
  /*******************************************************
   * CONTEXT
   *******************************************************/
  const user = useContext(UserContext)
  const snackbar = useContext(SnackbarContext)
  const priority =
    user.user?.notificationsPriorityPref || NotificationPriority.LOW

  /*******************************************************
   * HOOKS
   *******************************************************/
  const { control, handleSubmit } = useForm({
    defaultValues: {
      notificationsPriority: priority.toString(),
      receiveEmail: user.user?.notificationsEmailPref,
    },
  })

  /*******************************************************
   * GRAPHQL
   *******************************************************/
  const [updateProfileMutation, { loading: updatingUser }] = useMutation<
    UserUpdateMutation,
    UserUpdateMutationVariables
  >(UPDATE_USER, { refetchQueries: [GET_USER_INFO] })

  /*******************************************************
   * FUNCTIONS
   *******************************************************/
  /**
   *
   * @param value
   */
  const submit = async (value) => {
    if (!user.user?.id) return

    await updateProfileMutation({
      variables: {
        userInput: {
          id: user.user.id,
          notificationsPriorityPref: value.notificationsPriority,
          notificationsEmailPref: value.receiveEmail,
        },
      },
    })

    snackbar.setMessage({
      type: SnackbarTypes.SUCCESS,
      message: strings.SUCCESS_UPDATE,
      show: true,
      anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
    })
  }

  /*******************************************************
   * RENDER
   *******************************************************/
  return (
    <SC.Wrapper>
      <form onSubmit={handleSubmit((data) => submit(data))}>
        <SC.Container>
          <SC.Title variant="h3" color="secondary">
            {strings.EMAIL_NOTIFICATIONS}
          </SC.Title>
          <Controller
            name="receiveEmail"
            control={control}
            render={(values) => (
              <FormCheckbox
                {...values}
                onChange={values.onChange}
                disabled={false}
                label={strings.EMAIL_NOTIFICATIONS_LABEL}
              />
            )}
          />
        </SC.Container>
        <SC.Container>
          <SC.Title variant="h3" color="secondary">
            {strings.NOTIFICATIONS_LEVEL}
          </SC.Title>
          <SC.FieldsWrap>
            <Controller
              defaultValue={NotificationPriority.LOW}
              as={
                <RadioGroup>
                  <FormRadio
                    label={strings.EMAIL_NOTIFICATIONS_LABEL_CRITICAL}
                    value={NotificationPriority.HIGH}
                  />
                  <FormRadio
                    label={strings.EMAIL_NOTIFICATIONS_LABEL_IMPORTANT}
                    value={NotificationPriority.MEDIUM}
                  />
                  <FormRadio
                    label={strings.EMAIL_NOTIFICATIONS_LABEL_ALL}
                    value={NotificationPriority.LOW}
                  />
                </RadioGroup>
              }
              name="notificationsPriority"
              control={control}
            />
          </SC.FieldsWrap>
        </SC.Container>
        {updatingUser ? (
          <CenteredLoader />
        ) : (
          <SC.StyledBtn
            color="primary"
            size="large"
            variant="contained"
            type="submit"
          >
            {strings.UPDATE_SETTINGS}
          </SC.StyledBtn>
        )}
      </form>
    </SC.Wrapper>
  )
}

export default ProfileNotifications
