import styled from 'styled-components'
import { colors } from 'constants/colors'
import RecentNotificationsItem from 'modules/notification/components/RecentNotificationsItem'

export const StyledRecentNotificationsItem = styled(
  RecentNotificationsItem,
).attrs({
  newClasses: { wrap: 'wrap' },
})``

export const Wrap = styled.div`
  .wrap {
    border: 0;
  }

  span.mention {
    color: ${colors.yellowishOrange}
    font-weight: bold
  }
`

export const Item = styled.div`
  padding: 20px 0;
  border-top: 1px solid ${colors.lightGreyBlue};

  > * {
    padding-top: 0;
    padding-bottom: 0;
  }
`
