// @react
import React from 'react'
import { Grid, InputAdornment } from '@material-ui/core'
import strings from 'constants/strings'
import FormSelect from 'modules/common/components/_FORM_ELEMENTS/HookForm/form-inputs/FormSelect'
import FormInput from 'modules/common/components/_FORM_ELEMENTS/HookForm/form-inputs/FormInput'
import {
  debtTypes,
  tenancyTypes,
  rentableAreaUnits,
  clearHeightUnits,
} from 'modules/deal/common/common'
import {
  ClearHeightUnit,
  LoadingType,
  RentableAreaUnit,
} from 'graphql/graphqlTypes'
import GlobalHelperModel from 'modules/common/models/globalHelper'

const Industrial = ({ control, formState }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={4}>
        <FormInput
          name="yearOfConstruction"
          label={strings.YEAR_OF_CONSTRUCTION}
          control={control}
          formState={formState}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={8}>
            <FormInput
              id={'deal-summary-rentableAreaValue'}
              name="rentableAreaValue"
              label={`${strings.RENTABLE_AREA}`}
              control={control}
              formState={formState}
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <FormSelect
              name="rentableAreaUnit"
              control={control}
              formState={formState}
              defaultValue={
                rentableAreaUnits.find(
                  ({ value }) => value === RentableAreaUnit.SQFT,
                )?.value
              }
              label={' '}
              options={rentableAreaUnits}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormSelect
          name="tenancy"
          control={control}
          formState={formState}
          label={strings.TENANCY}
          options={tenancyTypes.map((tenancy) => ({
            value: tenancy,
            label: GlobalHelperModel.normalizeWords(tenancy) || '',
          }))}
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <FormInput
          id={'deal-summary-occupancy'}
          name="occupancy"
          label={strings.OCCUPANCY}
          inputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
          control={control}
          formState={formState}
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <FormInput
          name="wale"
          label={strings.WALE}
          control={control}
          formState={formState}
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={8}>
            <FormInput
              name="clearHeight"
              label={`${strings.CLEAR_HEIGHT}`}
              control={control}
              formState={formState}
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <FormSelect
              name="clearHeightUnit"
              control={control}
              formState={formState}
              defaultValue={
                clearHeightUnits.find(
                  ({ value }) => value === ClearHeightUnit.FEET,
                )?.value
              }
              label={' '}
              options={clearHeightUnits}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} sm={4}>
        <FormSelect
          name="loadingType"
          control={control}
          defaultValue={Object.values(LoadingType)[0]}
          formState={formState}
          label={strings.LOADING_TYPE}
          options={Object.values(LoadingType).map((loadingType) => ({
            value: loadingType,
            label: GlobalHelperModel.normalizeWords(loadingType) || '',
          }))}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormInput
          name="acreage"
          label={strings.ACREAGE}
          control={control}
          formState={formState}
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <FormInput
          name="noDockHighDoors"
          label={strings.DOCK_HIGH_DOORS}
          control={control}
          formState={formState}
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <FormInput
          name="parkingStalls"
          label={strings.PARKING_STALLS}
          control={control}
          formState={formState}
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <FormSelect
          name="debt"
          control={control}
          formState={formState}
          defaultValue={debtTypes[0]}
          label={strings.DEBT}
          options={debtTypes}
        />
      </Grid>
    </Grid>
  )
}

export default Industrial
