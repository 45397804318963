import { Table, TableBody, TableCell } from '@material-ui/core'
import { DealIndustry, DealStage } from 'graphql/graphqlTypes'
import IndustryTag from '../IndustryTag'
import { ChartTableWrapper } from '../PipeLineChart/styledComponents'
import GlobalHelperModel from 'modules/common/models/globalHelper'
import {
  TableCellNBStyled,
  TableCellNoBorderStyled,
  TableCellStyled,
  TableHeadStyled,
} from './styledComponents'
import { TotalsSumBy } from '../../enum'

type PropsType = {
  data: any
  sumBy: keyof typeof TotalsSumBy
}

/**
 *
 * @param data
 * @param sumBy
 * @constructor
 */
const ChartTable = ({ data, sumBy }: PropsType) => {
  /*******************************************************
   * CONST
   *******************************************************/
  const groupedByIndustry = Object.keys(DealIndustry).map((key) => {
    return { industry: DealIndustry[key] }
  })

  const rowsData = groupedByIndustry.map((industry) => {
    const byIndustry = data.filter((it) => it.industry === industry.industry)
    byIndustry.forEach((it) => {
      if (!industry.hasOwnProperty(it.stage)) industry[it.stage] = 0
      industry[it.stage] =
        String(industry[it.stage]) + String(it[sumBy.toLowerCase()])
          ? Number(it[sumBy.toLowerCase()])
          : 0
    })
    return industry
  })

  /*******************************************************
   * RENDER COMPONENTS
   *******************************************************/
  const tableBody = rowsData.map((rowData) => {
    return (
      <TableCellNoBorderStyled key={rowData.industry}>
        <TableCellNBStyled>
          <IndustryTag industry={rowData.industry} />
        </TableCellNBStyled>
        <TableCellStyled>
          {GlobalHelperModel.getCleanNumber(rowData[DealStage.REVIEW])}
        </TableCellStyled>
        <TableCellStyled iseven={'true'}>
          {GlobalHelperModel.getCleanNumber(rowData[DealStage.UNDERWRITING])}
        </TableCellStyled>
        <TableCellStyled>
          {GlobalHelperModel.getCleanNumber(rowData[DealStage.DUE_DILIGENCE])}
        </TableCellStyled>
        <TableCellStyled iseven={'true'}>
          {GlobalHelperModel.getCleanNumber(rowData[DealStage.CLOSING])}
        </TableCellStyled>
      </TableCellNoBorderStyled>
    )
  })

  /*******************************************************
   * RENDER
   *******************************************************/
  return (
    <ChartTableWrapper>
      <Table>
        <TableHeadStyled>
          <TableCell />
          <TableCell>Review</TableCell>
          <TableCell>Underwriting</TableCell>
          <TableCell>Due Diligence</TableCell>
          <TableCell>Closing</TableCell>
        </TableHeadStyled>
        <TableBody>{tableBody}</TableBody>
      </Table>
    </ChartTableWrapper>
  )
}

export default ChartTable
