import styled from 'styled-components'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import colors from 'constants/colors'

export const InviteButton = styled.button`
  background-color: ${colors.aquaMarine};
  font-family: Open Sans;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${colors.white};
  width: 180px;
  height: 20px;
  margin: 0 10px 4px 285px;
  padding: 2px 16px;
  border-radius: 2px;
  border: 0;
  cursor: pointer;
`

export const Title = styled.div`
  font-family: Open Sans;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${colors.dark};
  margin-top: 20px;
`
export const StyledFab = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  background-color: ${colors.yellowishOrange};
  width: 20px;
  height: 20px;
  border-radius: 10px;
  cursor: pointer;
`

export const StyledAddIcon = styled(AddIcon)`
  && {
    width: 20px;
    height: 20px;
  }
`

export const StyledRemoveIcon = styled(RemoveIcon)`
  && {
    width: 20px;
    height: 20px;
  }
`
export const StyledAddButton = styled.div`
  display: flex;
  align-items: end;
  justify-content: flex-end;
  padding: 15px 0px;
`
export const StyledCustomList = styled.div`
  .MuiGrid-item {
    padding-top: 8px !important;
  }
`
