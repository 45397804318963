export const convertRate = (
  rates,
  value,
  resultCurrency: string,
  currentCurrency = 'USD',
) => {
  const resultRate =
    rates.find((rate) => rate.currency === resultCurrency)?.rate || 1
  const currentRate =
    rates.find((rate) => rate.currency === currentCurrency)?.rate || 1
  const result = (value * resultRate) / currentRate
  return result
}
