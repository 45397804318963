// @react
import {
  FormControl,
  FormHelperText,
  InputLabel,
  InputLabelProps,
  InputProps,
  MenuItem,
  Select,
  WithStyles,
  makeStyles,
} from '@material-ui/core'
import * as SC from 'modules/deal/common/styledComponents'
import { FC } from 'react'
import { Control, Controller, FieldValues, FormState } from 'react-hook-form'

export const style = () => ({
  wrap: {
    width: '100%',
    marginBottom: '10px',
    '& .Mui-disabled': {
      fontStyle: 'normal',
    },
  },
})

const useStyles = makeStyles(style)

type SelectProps = {
  testid?: string
  name: string
  label: string
  formState: FormState<FieldValues>
  className?: string
  inputProps?: Partial<InputProps>
  disabled?: boolean
  withoutLabel?: boolean
  InputLabelProps?: { shrink?: boolean }
  control: Control<any> & { formName?: string }
  hidden?: boolean
  wrap?: string
  onChange?: (e) => void
  inputLabelProps?: Partial<InputLabelProps>
  options: ({ label: string; value: string } | string)[]
  defaultValue?: unknown
}

export type PropsType = SelectProps & WithStyles<typeof style>

/**
 *
 * @param classes
 * @param label
 * @param name
 * @param testid
 * @param onChange
 * @param withoutLabel
 * @constructor
 */
const FormSelect: FC<SelectProps> = ({
  control,
  name,
  label,
  hidden,
  className,
  wrap,
  testid,
  onChange,
  withoutLabel,
  options,
  inputLabelProps,
  formState,
  defaultValue,
}) => {
  const { errors, isSubmitting, isSubmitSuccessful } = formState
  const submitFailed = !isSubmitSuccessful
  const isHidden = hidden ? 'd-none' : ''
  const error = errors[name]
  const isError = (isSubmitting && !!error) || (submitFailed && !!error)
  const errorText = error?.message
  const helperText = submitFailed && typeof error === 'string' ? error : null
  const dataTestId = testid
    ? testid
    : control.formName
    ? `${control.formName}-${name}`
    : name || label.toLocaleLowerCase()
  const renderLabel = () => {
    if (withoutLabel) return
    return label ? (
      <InputLabel shrink={inputLabelProps?.shrink}>{label}</InputLabel>
    ) : null
  }

  /*******************************************************
   * HOOKS
   *******************************************************/
  /*******************************************************
   * RENDER
   *******************************************************/
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      render={(values) => (
        <FormControl
          className={[className, isHidden, wrap].join(' ')}
          error={isError}
        >
          {renderLabel()}
          <Select
            {...values}
            name={name}
            defaultValue=""
            data-testid={dataTestId}
            onChange={(e) => {
              values.onChange(e)
              if (onChange) {
                onChange(e)
              }
            }}
          >
            {options.map((item) => {
              const value = typeof item === 'string' ? item : item.value
              const label = typeof item === 'string' ? item : item.label
              return (
                <MenuItem key={value} value={value}>
                  <SC.OptionItem>{label}</SC.OptionItem>
                </MenuItem>
              )
            })}
          </Select>
          {helperText && !errorText && (
            <FormHelperText>{helperText}</FormHelperText>
          )}
          {errorText && isError && <FormHelperText>{errorText}</FormHelperText>}
        </FormControl>
      )}
    />
  )
}

export default FormSelect
