// @react
import { ReactElement, useContext, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
// @design
import {
  DialogActions,
  Grid,
  TableRow,
  TableCell,
  Typography,
} from '@material-ui/core'
import * as SCGLobal from 'modules/common/styledComponents/Global'
import StyledDialog from 'modules/common/components/_UI_DUMB/ModalDialogs/styledComponents/StyledDialog'
import FilterIcon from 'modules/common/components/_UI_DUMB/Icons/SvgIcons/Filter'
import FilterDialog from './components/FilterDialog'
import StagesMobile from './components/StagesMobile'
import * as SC from './styledComponents'
// GraphQL
import { useQuery } from '@apollo/client'
import {
  DealsFilterInput,
  DealsOrderByList,
  DealStage,
  DealStatus,
  GetDealsQuery,
  GetDealsQueryVariables,
  GetDealTotalsQuery,
  GetDealTotalsQueryVariables,
  Metric,
  Order,
  Scalars,
} from 'graphql/graphqlTypes'
import { GET_DEALS, GET_DEAL_TOTALS } from 'graphql/queries/deal.query'
import { GET_EXCHANGE_RATES } from 'graphql/queries/exchangeRates.query'
// @common
import useAsyncEffect from 'use-async-effect'
import strings from 'constants/strings'
import ErrorText from 'modules/common/components/_UI_DUMB/ErrorText/ErrorText'
import GlobalHelperModel from 'modules/common/models/globalHelper'
// @models
import { ChartType } from 'modules/pipeline/enum'
import DealModel from 'modules/deal/models/deal'
import { TotalsSumBy } from 'modules/pipeline/enum'
import { convertRate } from 'utils/ratesCalculation'
// @components
import GlobalControls from 'modules/pipeline/components/GlobalControls'
import PipeLineChart from 'modules/pipeline/components/PipeLineChart/PipeLineChart'
import { PipelineContext } from '../../contexts/pipeline'
import DealsTotalsSearch from '../../components/DealTotalsSearch'
import ChartTable from '../../components/ChartTable'
import DealsList from 'modules/deal/pages/Deals/components/DealsList'
import IndustryTag from '../../components/IndustryTag'
import DueDateTag from 'modules/item/components/common/DueDateTag'
import ContextTableWrapper from 'modules/common/components/_UI_SMART/SharedTable/ContextTableWrapper'

/*******************************************************
 * CONSTS
 *******************************************************/
const excludeStages = [DealStage.PRELIM, DealStage.CLOSED]

const columnTypes = {
  [TotalsSumBy.NAV]: 'netAsset',
  [TotalsSumBy.GAV]: 'grossAsset',
}

const displayStages = [
  DealStage.REVIEW,
  DealStage.UNDERWRITING,
  DealStage.DUE_DILIGENCE,
  DealStage.CLOSING,
]

/**
 *
 * @constructor
 */
const Pipeline = (): ReactElement => {
  /*******************************************************
   * HOOKS
   *******************************************************/
  const history = useHistory()
  const { state } = useContext(PipelineContext)
  const [modalVisible, setModalVisible] = useState<boolean>(false)

  /*******************************************************
   * GQL
   *******************************************************/
  /**
   * Deal Totals
   */
  const { data, refetch, variables } = useQuery<
    GetDealTotalsQuery,
    GetDealTotalsQueryVariables
  >(GET_DEAL_TOTALS, {
    variables: {
      filters: { excludeStages },
    },
  })

  /**
   * Deals
   */
  const dealsQueryResult = useQuery<GetDealsQuery, GetDealsQueryVariables>(
    GET_DEALS,
    {
      fetchPolicy: 'cache-first',
      variables: {
        filters: { excludeStages },
        orderBy: {
          order: Order.DESC,
          value: DealsOrderByList.CREATED_DATE,
        },
      },
    },
  )
  const {
    data: getDealsData,
    refetch: getDealsRefetch,
    error: getDealsError,
    variables: getDealsVariables,
    loading: getDealsLoading,
    fetchMore: getDealsFetchMore,
  } = dealsQueryResult

  /**
   * Exchange Rate
   */
  const { data: exchangeRateData } = useQuery(GET_EXCHANGE_RATES)
  const dealTotals = data?.dealTotals.data

  const groupedByStage = displayStages.map((key) => {
    return {
      stage: key,
    }
  })

  let result = groupedByStage.map((it) => {
    const staged = dealTotals?.filter((totals) => totals.stage === it.stage)
    staged?.forEach((totals) => {
      if (totals.industry) {
        it[totals.industry] = totals[state.sumBy.toLowerCase()]
          ? Number(totals[state.sumBy.toLowerCase()])
          : 0
      }
    })

    return it
  })

  /*******************************************************
   * HOOKS
   *******************************************************/
  useAsyncEffect(async () => {
    const newVariables = {
      filters: {
        ...variables?.filters,
        ...{ currency: state.currency },
      },
    }
    await refetch(newVariables)
    await getDealsRefetch(newVariables)
  }, [state.currency])

  useEffect(() => {
    result = groupedByStage.map((it) => {
      const staged = dealTotals?.filter((totals) => totals.stage === it.stage)
      staged?.forEach((totals) => {
        if (totals.industry) {
          it[totals.industry] = totals[state.sumBy.toLowerCase()]
            ? Number(totals[state.sumBy.toLowerCase()])
            : 0
        }
      })
      return it
    })
  }, [state.sumBy])

  /*******************************************************
   * FUNCTIONS
   *******************************************************/
  const toggleFilterDialog = () => setModalVisible(!modalVisible)

  /**
   *
   * @param metrics
   * @param key
   */
  const calculateMetricsByKey = (metrics: Metric[], key: string): number => {
    return (
      metrics
        .map((metric) =>
          convertRate(
            exchangeRateData?.getExchangeRates || [],
            Number(metric[columnTypes[key]] || 0),
            state.currency,
            metric.currency || 'USD',
          ),
        )
        .reduce((acc, metric) => metric + acc, 0) || 0
    )
  }

  /**
   *
   * @param id
   * @param status
   */
  const handleClick = (id: Scalars['ID'], status: DealStatus) => {
    history.push(DealModel.dealStatusRouter(status, id))
  }

  const headerRowData = [
    { name: 'Name', value: DealsOrderByList.NAME },
    { name: 'Sector', value: DealsOrderByList.SECTOR },
    { name: 'Stage', value: DealsOrderByList.STAGE },
    { name: state.sumBy, value: DealsOrderByList[state.sumBy] },
    { name: 'Exp. Closing Date', value: DealsOrderByList.CLOSING_DATE },
  ].filter((headerRow) => headerRow.name !== TotalsSumBy.COUNT)

  /*******************************************************
   * RENDER ELEMENTS
   *******************************************************/
  const tableData = (): ReactElement | ReactElement[] | void => {
    if (getDealsError) {
      return <ErrorText errorText={`${strings.GENERIC_ERROR}:`} />
    }

    if (!getDealsData?.deals || getDealsData.deals.data?.length === 0) {
      return <ErrorText errorText={strings.NO_DEALS} />
    }

    return getDealsData.deals.data?.map((el, key: number) => {
      const assetsValue = el.metrics
        ? // @todo 01-22 fix this type
          calculateMetricsByKey(el.metrics as Metric[], state.sumBy)
        : 0

      return (
        <TableRow
          key={key}
          component="div"
          onClick={() => {
            handleClick(el.id, el.status)
          }}
        >
          <TableCell component="div">
            <Typography variant="subtitle1" className="underline">
              {el.name}
              <SC.MobileTags>
                {el.industry && <IndustryTag industry={el.industry} />}
                {el.stage &&
                  GlobalHelperModel.normalizeWords(
                    DealModel.syntheticStage(el.stage),
                  )}
              </SC.MobileTags>
            </Typography>
          </TableCell>
          <TableCell component="div">
            {el.industry && <IndustryTag industry={el.industry} />}
          </TableCell>
          <TableCell component="div">
            {el.stage &&
              GlobalHelperModel.normalizeWords(
                DealModel.syntheticStage(el.stage),
              )}
          </TableCell>
          {state.sumBy !== TotalsSumBy.COUNT && (
            <TableCell component="div">
              {GlobalHelperModel.getCleanNumber(assetsValue)}
            </TableCell>
          )}
          <TableCell component="div">
            <DueDateTag
              date={
                el.metrics?.find((metric) => metric.closingDate)?.closingDate
              }
              closed={false}
            />
          </TableCell>
        </TableRow>
      )
    })
  }

  /*******************************************************
   * RENDER
   *******************************************************/
  return (
    <SCGLobal.ContentContainer>
      <SC.Header>
        <SC.Title variant="subtitle1">{strings.PL_TITLE}</SC.Title>
        <SC.FilterToggle size="small" onClick={toggleFilterDialog}>
          <FilterIcon />
        </SC.FilterToggle>
      </SC.Header>

      <FilterDialog
        open={modalVisible}
        onClose={toggleFilterDialog}
        title={strings.PL_FILTER_TITLE}
        action={
          <DialogActions>
            <StyledDialog.Wrap>
              <Grid container justifyContent="center" spacing={3}>
                <Grid item xs={12} sm={6}>
                  <StyledDialog.SubmitBtn
                    onClick={toggleFilterDialog}
                    variant="contained"
                    color="primary"
                  >
                    {strings.APPLY}
                  </StyledDialog.SubmitBtn>
                </Grid>
              </Grid>
            </StyledDialog.Wrap>
          </DialogActions>
        }
      >
        <SCGLobal.PushContainer>
          <SC.FilterTitle variant="subtitle1">
            {strings.PL_FILTER_TITLE}
          </SC.FilterTitle>
          <DealsTotalsSearch
            queryResult={{
              ...dealsQueryResult,
              data,
            }}
            refetchOverride={async (variables) => {
              await refetch(
                variables as {
                  filters?: DealsFilterInput
                },
              )
              await getDealsRefetch(
                variables as {
                  filters?: DealsFilterInput
                },
              )
            }}
          />
          <GlobalControls />
        </SCGLobal.PushContainer>
      </FilterDialog>
      <SC.ChartWrapper>
        {state.chartType === ChartType.CHART ? (
          <PipeLineChart data={result} />
        ) : (
          <ChartTable data={data?.dealTotals.data} sumBy={state.sumBy} />
        )}
      </SC.ChartWrapper>
      <StagesMobile data={data?.dealTotals.data} sumBy={state.sumBy} />
      <ContextTableWrapper
        queryResult={dealsQueryResult}
        headerRow={headerRowData}
      >
        <DealsList
          wrapper={SC.DealsTableWrapper}
          data={getDealsData}
          error={getDealsError}
          loading={getDealsLoading}
          refetch={getDealsRefetch}
          fetchMore={getDealsFetchMore}
          variables={getDealsVariables}
          header={headerRowData}
          tableData={tableData}
        />
      </ContextTableWrapper>
    </SCGLobal.ContentContainer>
  )
}

export default Pipeline
