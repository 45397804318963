import { makeStyles, TableHead } from '@material-ui/core'
import { Order } from 'graphql/graphqlTypes'
import { TableSortableRowModel } from 'modules/common/models/sharedTableChange/SharedTableSortableRow.model'
import { useCallback } from 'react'
import { useMemo, useState } from 'react'
import TableHeader from '../TableWrapper/components/TableHeader'
import * as SC from '../TableWrapper/styledComponents/tableWrapper'

type OrderType = {
  orderBy?: {
    order?: Order
    value?: string
  }
}

type PropsType = {
  refetch: (OwnProps) => void
  headerRow: TableSortableRowModel[]
}

/**
 * @TODO
 *  *****IMPORTANT******
 *  05/22 THERE IS AN IMPORTANT BUG HERE WHERE initial
 *  value of useState<OrderType> overrides settings from consuming component queries
 *
 * @param {(OwnProps) => void} refetch
 * @param {TableSortableRowModel[]} headerRow
 * @returns {{orderVars: OrderType, setFilters: (value: (((prevState: {}) => {}) | {})) => void, SortableHeader: () => JSX.Element}}
 */
const useSort = ({ refetch, headerRow }: PropsType) => {
  const useStyles = makeStyles(SC.style)
  const classes = useStyles()
  const [orderVars, setOrderVars] = useState<OrderType>({
    orderBy: {
      order: Order.DESC,
      value: 'CREATED_DATE',
    },
  })

  const [filters, setFilters] = useState({})
  /*******************************************************
   * FUNCTIONS
   *******************************************************/
  /**
   *
   * @param key
   */
  const handleChange = useCallback(
    (key: string) => {
      const currentOrderBy = orderVars.orderBy
      let direction = Order.ASC
      if (currentOrderBy?.value === key) {
        direction = currentOrderBy.order === Order.DESC ? Order.ASC : Order.DESC
      }
      const vars = {
        orderBy: {
          order: direction,
          value: key,
        },
      }
      setOrderVars(vars)
      refetch({ filters, ...vars })
    },
    [orderVars, filters],
  )

  const SortableHeader = useMemo(() => {
    const HeaderComponent = () => {
      return (
        <TableHead component="div">
          <SC.StyledTableHeadRow component="div" className={classes.headerRow}>
            <TableHeader
              onSort={handleChange}
              row={headerRow}
              variables={orderVars}
            />
          </SC.StyledTableHeadRow>
        </TableHead>
      )
    }
    return HeaderComponent
  }, [orderVars, filters])

  return {
    SortableHeader,
    setFilters,
    orderVars,
  }
}

export default useSort
