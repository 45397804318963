// @react
import React, { useState, useRef, FC } from 'react'
// @design
import {
  FormControl,
  FormHelperText,
  InputLabelProps,
  InputProps,
  InputAdornment,
  makeStyles,
  TextField,
} from '@material-ui/core'
import { Control, Controller, FieldValues, FormState } from 'react-hook-form'
import colors from 'constants/colors'
// @common
import DayPickerInput from 'react-day-picker/DayPickerInput'
import dateFnsFormat from 'date-fns/format'
import dateFnsParse from 'date-fns/parse'
import { isToday } from 'date-fns'
import { DateUtils } from 'react-day-picker'
import config from 'constants/config'
import calendarIcon from 'assets/img/customIcons/calendar.svg'
import FreeDatePickerArrow from 'modules/common/components/_FORM_ELEMENTS/FreeDatePicker/components/FreeDatePickerArrow'

/*******************************************************
 * CONST
 *******************************************************/
export const style = () => ({
  wrap: {
    width: '100%',
    marginBottom: '10px',
  },
  icon: {
    width: 15,
    height: 15,
  },
  rightAlign: {
    '& .DayPickerInput-Overlay': {
      right: 0,
      left: 'auto',
    },
  },
  fullWidth: {
    '& .DayPickerInput': {
      width: '100%',
    },
  },
})

const todaySelected = {
  backgroundColor: colors.yellowishOrange,
  color: colors.white,
}

const todayNotSelected = {
  color: colors.dark,
}
const FORMAT = 'MMMM do, yyyy'
const useStyles = makeStyles(style)

const getModifiersStyles = (isTodayValue: boolean) => {
  return {
    selected: {
      backgroundColor: colors.yellowishOrange,
      color: colors.white,
    },
    today: isTodayValue ? todaySelected : todayNotSelected,
  }
}

/*******************************************************
 * TYPES
 *******************************************************/
export type PropsType = {
  name: string
  label: string
  formState: FormState<FieldValues>
  className?: string
  inputProps?: Partial<InputProps>
  disabled?: boolean
  withoutLabel?: boolean
  InputLabelProps?: { shrink?: boolean }
  control: Control<any>
  hidden?: boolean
  wrap?: string
  multiline?: boolean
  rows?: number
  rowsMax?: number
  onChange?: (e) => void
  type?: React.InputHTMLAttributes<unknown>['type']
  value?: unknown
  inputLabelProps?: Partial<InputLabelProps>
  isRightAlign?: boolean
  isFullWidth?: boolean
  isSubmitting?: boolean
  isSubmitSuccessful?: boolean
  error?: string
  helperText?: string
  errorText?: string
}

/**
 *
 * @param classes
 * @param input
 * @param label
 * @param name
 * @param multiline
 * @param rows
 * @param rowsMax
 * @param onChange
 * @param inputProps
 * @param isSubmitting
 * @param isSubmitSuccessful
 * @param disabled
 * @param value
 * @param error
 * @param withoutLabel
 * @param custom
 * @constructor
 */
const FormDatepicker: FC<PropsType> = ({
  formState,
  control,
  name,
  label,
  hidden,
  className,
  withoutLabel,
  disabled,
  isRightAlign,
  isFullWidth,
}) => {
  /*******************************************************
   * STATE
   *******************************************************/
  const [showState, setShowState] = useState(false)

  /*******************************************************
   * CONST
   *******************************************************/
  const { errors, isSubmitting, isSubmitSuccessful } = formState
  const submitFailed = !isSubmitSuccessful
  const isHidden = hidden ? 'd-none' : ''
  const error = errors[name]
  const isError = (isSubmitting && !!error) || (submitFailed && !!error)
  const errorText = error?.message
  const helperText = submitFailed && typeof error === 'string' ? error : null

  /*******************************************************
   * HOOKS
   *******************************************************/
  const ref = useRef<any>(null)
  const classes = useStyles()

  /*******************************************************
   * FUNCTIONS
   *******************************************************/
  /**
   *
   * @param str
   * @param format
   */
  const parseDate = (str, format) => {
    const parsed = dateFnsParse(str, format, new Date())
    if (DateUtils.isDate(parsed)) {
      return parsed
    }
    return undefined
  }

  /**
   *
   * @param date
   * @param format
   */
  const formatDate = (date, format) => {
    return dateFnsFormat(date, format)
  }

  /*******************************************************
   * RENDER
   *******************************************************/
  return (
    <Controller
      name={name}
      control={control}
      render={({ value, onChange }) => {
        const isTodayValue = isToday(value || new Date())
        const currentValue = value
          ? parseDate(value, config.isoDateFormat)
          : new Date()

        return (
          <FormControl
            className={[
              className,
              isHidden,
              classes.wrap,
              //@ts-ignore
              isRightAlign && classes.rightAlign,
              //@ts-ignore
              isFullWidth && classes.fullWidth,
            ].join(' ')}
            error={isError}
          >
            <div
              onClick={(e) => e.stopPropagation()}
              className="DayPickerComponent"
              data-testid="datepicker"
            >
              <DayPickerInput
                dayPickerProps={{
                  modifiersStyles: getModifiersStyles(isTodayValue),
                  showOutsideDays: true,
                  navbarElement: <FreeDatePickerArrow isSingle />,
                  fixedWeeks: true,
                }}
                onDayPickerShow={() => setShowState(true)}
                onDayPickerHide={() => setShowState(false)}
                hideOnDayClick
                keepFocus={true}
                onClick={() => ref.current?.handleInputFocus()}
                ref={ref}
                formatDate={formatDate}
                parseDate={parseDate}
                placeholder={
                  currentValue && `${dateFnsFormat(currentValue, FORMAT)}`
                }
                format={FORMAT}
                value={
                  currentValue
                    ? `${dateFnsFormat(currentValue, FORMAT)}`
                    : undefined
                }
                onDayChange={(e) => {
                  onChange(e ? formatDate(e, config.isoDateFormat) : undefined)
                }}
                component={(props) => {
                  return (
                    <TextField
                      {...props}
                      name={name || label.toLowerCase()}
                      focused={showState}
                      disabled={disabled}
                      label={withoutLabel ? undefined : label}
                      onClick={() => ref.current?.hideAfterDayClick()}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <img
                              alt=""
                              className={classes.icon}
                              src={calendarIcon}
                            />
                          </InputAdornment>
                        ),
                      }}
                    />
                  )
                }}
              />
            </div>
            {helperText && !errorText && (
              <FormHelperText>{helperText}</FormHelperText>
            )}
            {errorText && isError && (
              <FormHelperText>{errorText}</FormHelperText>
            )}
          </FormControl>
        )
      }}
    />
  )
}

export default FormDatepicker
