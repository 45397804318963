import styled from 'styled-components'
import { InputAdornment, TextField } from '@material-ui/core'
import { Close, Search } from '@material-ui/icons'
import colors from 'constants/colors'

export const ClearButton = styled.button`
  display: inline-flex;
  align-items: center;
  padding: 10px;
  border: 0;
  background: transparent;
  transition: all 0.2s ease;
  opacity: 0;
  visibility: hidden;
  cursor: pointer;

  svg {
    font-size: 16px;
  }
`

export const SearchIcon = styled(Search)`
  color: ${colors.battleshipGrey};
  transition: color 0.3s ease;
`

export const Wrap = styled.div`
  width: 100%;
`

export const Input = styled(TextField)`
  .MuiInput-input {
    height: auto;
    padding: 12px;
    line-height: 1.35;

    &::placeholder {
      opacity: 1;
      color: ${colors.battleshipGrey};
    }
  }

  .MuiInput-underline {
    border: 1px solid ${colors.paleGrey};
    border-radius: 5px;
    background-color: ${colors.white};
    transition: border-color 0.3s ease;

    &.Mui-focused {
      border-color: ${colors.yellowishOrangeAlt};
    }
  }

  ${(props) =>
    props.filled &&
    `
    ${ClearButton as string} {
      opacity: 1;
      visibility: visible;
    }

    ${SearchIcon as string} {
      color: currentColor;
    }
  `}
`

export const CloseIcon = styled(Close)`
  color: currentColor;
`

export const Adornment = styled(InputAdornment)`
  && {
    margin: 0;
  }

  ${(props) =>
    props.position === 'start' &&
    `
    padding-left: 20px;
  `}

  ${(props) =>
    props.position === 'end' &&
    `
    padding-right: 15px;
  `}
`
