import Dates from './components/Dates'
import Metrics from './components/Metrics'

/**
 *
 * @constructor
 */
const DatesAndMetrics = () => {
  /*******************************************************
   * RENDER
   *******************************************************/

  return (
    <div data-testid={'deal-single-dates-and-metrics-page'}>
      <Dates />
      <Metrics />
    </div>
  )
}

export default DatesAndMetrics
