import 'wdyr'
import ReactDOM from 'react-dom'
// @design
import './assets/styles/index.scss'
// @util
import * as serviceWorker from './serviceWorker'
// @components
import App from 'App/App'
import Favicon from 'react-favicon'

ReactDOM.render(
  <App />,
  //   <Favicon url={['./images/favicon-sb.png']} />
  // </App>,
  document.getElementById('root'),
)
serviceWorker.register()
