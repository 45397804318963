import { RouteType, RouteNames } from 'constants/routeNames'
import Profile from './pages/Profile'
import ProfileAccountSettings from './pages/ProfileAccountSettings'
import ProfileContacts from './pages/Network'
import ProfileUser from './pages/ProfilePublic'

const routes: RouteType[] = [
  {
    component: Profile,
    path: `/${RouteNames.PROFILE}`,
    type: 'PRIVATE',
    exact: true,
    layout: 'MAIN',
    whiteInnerWrap: false,
  },
  {
    component: ProfileAccountSettings,
    path: `/${RouteNames.PROFILE}/${RouteNames.ACCOUNT_SETTINGS}`,
    type: 'PRIVATE',
    exact: true,
    layout: 'MAIN',
    whiteInnerWrap: true,
  },
  {
    component: ProfileContacts,
    path: `/${RouteNames.NETWORK}`,
    type: 'PRIVATE',
    exact: true,
    layout: 'MAIN',
  },
  {
    component: ProfileContacts,
    path: `/${RouteNames.NETWORK}/${RouteNames.CONTACT_PENDING}`,
    type: 'PRIVATE',
    exact: true,
    layout: 'MAIN',
  },
  {
    component: ProfileContacts,
    path: `/${RouteNames.NETWORK}/${RouteNames.CONTACT_SENT}`,
    type: 'PRIVATE',
    exact: true,
    layout: 'MAIN',
  },
  {
    component: ProfileUser,
    path: `/${RouteNames.USER}/:${RouteNames.ID}`,
    type: 'PRIVATE',
    exact: true,
    layout: 'MAIN',
  },
]

export default { routes }
