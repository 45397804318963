import {
  ApolloLink,
  FetchResult,
  Observable,
  Operation,
} from '@apollo/client/core'
import { GraphQLError, print } from 'graphql'
import { Client, ClientOptions, createClient } from 'graphql-ws'

/**
 *
 */
class WebSocketLink extends ApolloLink {
  private client: Client

  /**
   *
   * @param {ClientOptions} options
   */
  constructor(options: ClientOptions) {
    super()
    this.client = createClient(options)
  }

  /**
   *
   * @param {Operation} operation
   * @returns {Observable<FetchResult>}
   */
  public request(operation: Operation): Observable<FetchResult> {
    return new Observable((sink) => {
      return this.client.subscribe<FetchResult>(
        { ...operation, query: print(operation.query) },
        {
          next: sink.next.bind(sink),
          complete: sink.complete.bind(sink),
          error: (err) => {
            if (Array.isArray(err))
              // GraphQLError[]
              return sink.error(
                new Error(err.map(({ message }) => message).join(', ')),
              )

            if (err instanceof Error) {
              return sink.error(err)
            }

            if (err instanceof CloseEvent) {
              return sink.error(
                // reason will be available on clean closes
                new Error(
                  `Socket closed with event ${err.code} ${err.reason || ''}`,
                ),
              )
            }
            return sink.error(new GraphQLError('WS error from Webscoketlink'))
          },
        },
      )
    })
  }
}

export default WebSocketLink
