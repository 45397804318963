// language=GraphQL
import { DOCUMENT_FRAGMENT } from 'graphql/fragments/document.fragment'
import { gql } from '@apollo/client'
import {
  FULL_ITEM,
  ITEM_META,
  ITEM_WITH_USER,
  SIMPLE_ITEM,
} from '../fragments/item.fragment'

export const GET_ITEMS = gql`
  query GetItems(
    $filters: ItemsFilterInput
    $orderBy: ItemsOrderByInput
    $page: Int
    $pageSize: Int
  ) {
    items(
      orderBy: $orderBy
      filters: $filters
      page: $page
      pageSize: $pageSize
    ) {
      data {
        ...SimpleItem
        deal {
          id
          name
        }
        notifications
        documents {
          name
        }
      }
      meta {
        ...ItemMeta
      }
    }
  }
  ${ITEM_META}
  ${SIMPLE_ITEM}
`

export const GET_ITEMS_WITH_USER = gql`
  query GetItemsWithUser(
    $filters: ItemsFilterInput
    $orderBy: ItemsOrderByInput
    $page: Int
    $pageSize: Int
  ) {
    items(
      orderBy: $orderBy
      filters: $filters
      page: $page
      pageSize: $pageSize
    ) {
      data {
        ...ItemWithUser
        deal {
          id
          name
        }
        notifications
        documents {
          ...DocumentNode
        }
      }
      meta {
        ...ItemMeta
      }
    }
  }
  ${ITEM_META}
  ${ITEM_WITH_USER}
  ${DOCUMENT_FRAGMENT}
`

export const GET_ITEM = gql`
  query GetItem($id: ID!) {
    item(id: $id) {
      ...FullItem
    }
  }
  ${FULL_ITEM}
`
