import styled from 'styled-components'
import colors from 'constants/colors'

export const StyledTag = styled.div`
  ${(props: { color: string }) =>
    props.color && `background: ${String(props.color)}`};
  text-transform: capitalise;
  font-family: Open Sans;
  font-size: 10px;
  padding: 2px;
  color: ${colors.dark};
  border-radius: 4px;
  padding: 3px 6.5px;
  margin-right: 5px;
  text-align: center;
`
