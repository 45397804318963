// language=GraphQL
import { gql } from '@apollo/client'
import { MINI_USER } from 'graphql/fragments/user.fragment'
import { DEAL_FRAGMENT, TEAM_DATA } from '../fragments/deal.fragment'
import { DOCUMENT_FRAGMENT } from '../fragments/document.fragment'
import { FILTERS_FRAGMENT } from '../fragments/filters.fragment'

export const GET_DEALS = gql`
  query GetDeals(
    $filters: DealsFilterInput
    $orderBy: DealsOrderByInput
    $page: Int
    $pageSize: Int
  ) {
    deals(
      orderBy: $orderBy
      filters: $filters
      page: $page
      pageSize: $pageSize
    ) {
      data {
        id
        createdBy {
          ...MiniUser
        }
        stage
        status
        notifications
        createdDate
        modifiedDate
        name
        type
        recipientTeam {
          teamLead {
            firstName
            lastName
          }
        }
        industry
        propertyType
        imageDocs {
          ...DocumentNode
        }
        metrics {
          netAsset
          grossAsset
          closingDate
          currency
        }
      }
      meta {
        page
        totalPages
        pageSize
        orderBy {
          order
          value
        }
        allFilters {
          ...FiltersNode
        }
      }
    }
  }
  ${FILTERS_FRAGMENT}
  ${DOCUMENT_FRAGMENT}
  ${MINI_USER}
`

export const GET_DEAL_TOTALS = gql`
  query GetDealTotals($filters: DealsFilterInput) {
    dealTotals(filters: $filters) {
      data {
        industry
        stage
        count
        gav
        nav
      }
      meta {
        allFilters {
          ...FiltersNode
        }
      }
    }
  }
  ${FILTERS_FRAGMENT}
`

export const GET_DEAL_COUNT_BY_STATUS = gql`
  query GetDealCountByStatus {
    dealCountByStatus {
      counts {
        OPEN
        PENDING
        DRAFT
        DEAD
        DECLINED
      }
    }
  }
`

export const GET_DEALS_BY_STATUS = gql`
  query GetDealsByStatus(
    $filters: DealsFilterInput
    $orderBy: DealsOrderByInput
    $page: Int
  ) {
    deals(orderBy: $orderBy, filters: $filters, page: $page) {
      data {
        id
        createdBy {
          ...MiniUser
        }
        stage
        status
        notifications
        createdDate
        name
      }
      meta {
        page
        totalPages
        pageSize
      }
    }
  }
  ${MINI_USER}
`

export const GET_DEAL_INFO = gql`
  query GetDealInfo($id: ID!) {
    deal(id: $id) {
      actions
      otherDocs {
        ...DocumentNode
      }
      imageDocs {
        ...DocumentNode
      }
      pitchBook {
        ...DocumentNode
      }
      confi {
        ...DocumentNode
      }
      ...DealNode
      theirTeam
      myTeam
      originatingTeam {
        ...TeamData
      }
      recipientTeam {
        ...TeamData
      }
    }
  }
  ${TEAM_DATA}
  ${DEAL_FRAGMENT}
  ${DOCUMENT_FRAGMENT}
`

export const GET_ACTIVE_DEALS = gql`
  query GetActiveDeals(
    $filters: DealsFilterInput
    $orderBy: DealsOrderByInput
    $pageSize: Int
  ) {
    deals(orderBy: $orderBy, filters: $filters, pageSize: $pageSize) {
      data {
        id
        name
        status
        propertyType
        createdDate
        modifiedDate
        stage
        status
        recipientTeam {
          teamLead {
            firstName
            lastName
          }
        }
        createdBy {
          ...MiniUser
        }
        imageDocs {
          ...DocumentNode
        }
      }
    }
  }
  ${DOCUMENT_FRAGMENT}
  ${MINI_USER}
`

export const GET_DEAL_TEAM_MEMBERS = gql`
  query GetDealTeamMembers($id: ID!) {
    deal(id: $id) {
      actions
      id
      theirTeam
      myTeam
      status
      stage
      type
      myRole
      originatingTeam {
        ...TeamData
      }
      recipientTeam {
        ...TeamData
      }
    }
  }
  ${TEAM_DATA}
`
