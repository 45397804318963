import { gql } from '@apollo/client'

export const METRIC_FRAGMENT = gql`
  fragment MetricNode on Metric {
    id
    bidDate
    investmentCommittee
    endDate
    closingDate
    ltv
    capRate
    grossUnleveredIrr
    grossLeveredIrr
    netLeveredIrr
    multipleOnInvestedCapital
    grossAsset
    netAsset
    currency
    number
    periodType
  }
`
