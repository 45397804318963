// @react
import { useCallback, useEffect, useState, useContext } from 'react'
// @design
import * as SC from '../../styledComponents/profile'
import linkedInImage from 'assets/img/linkedin-gray.svg'
import { Grid } from '@material-ui/core'
import features from 'constants/features'
import profilePlaceholderImage from 'assets/img/profile-placeholder.png'
import PlaceholderImage from './placeholder.svg'
// @common
import strings from 'constants/strings'
import { countries, allowedCountryCodes } from 'constants/geo'
import FormInput from 'modules/common/components/_FORM_ELEMENTS/HookForm/form-inputs/FormInput'
import FormSelect from 'modules/common/components/_FORM_ELEMENTS/HookForm/form-inputs/FormSelect'
import * as Yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'
import { Auth0Context, useAuth0 } from '@auth0/auth0-react'
import UserModel from 'modules/user/models/user'
import { SnackbarContext } from 'modules/common/context/snackbar'
// @components
import CenteredLoader from 'modules/common/components/_UI_DUMB/Loaders/CenteredLoader'
import ProfileActionDialog from '../../components/ProfileActionDialog'
import ProfileWelcomeDialog from '../../components/ProfileWelcomeDialog'
import { SnackbarTypes } from 'modules/common/components/_UI_DUMB/Snackbars/Snackbar'
import { ProfileContainer } from 'modules/common/styledComponents/Profile'
import ProfileUpdateAvatar from '../../components/ProfileUpdateAvatar'
// @graphql
import { GET_USER_INFO } from 'graphql/queries/user.query'
import { UPDATE_USER } from 'graphql/mutations/user.mutation'
import {
  GetUserInfoQuery,
  GetUserInfoQueryVariables,
  UserUpdateMutation,
  UserUpdateMutationVariables,
} from 'graphql/graphqlTypes'
import { useMutation, useQuery } from '@apollo/client'
import { getRegions } from 'modules/deal/common/common'
import { useForm } from 'react-hook-form'
import { RouteNames } from 'constants/routeNames'
import { useHistory } from 'react-router'
import useErrorHandler from 'modules/common/hooks/useErrorHandler'

/*******************************************************
 * Types
 *******************************************************/
export interface UserProfileFormValues {
  firstName: string
  lastName: string
  email: string
  title: string
  company: string
  city: string
  country: string
  state: string
}

const dialogs = {
  deactivate: {
    id: 'DEACTIVATE_ACCOUNT',
    title: strings.DEACTIVATE_ACCOUNT,
    content: strings.DEACTIVATE_ACCOUNT_CONFIRM,
    acceptText: strings.PROCEED,
    declineText: strings.CANCEL,
  },
  updateLinkedinImg: {
    id: 'DEACTIVATE_ACCOUNT',
    title: strings.DEACTIVATE_ACCOUNT,
    content: strings.IMPORT_FROM_LINKEDIN_CONFIRM,
    acceptText: strings.YES,
    declineText: strings.NO,
  },
  updateImg: {
    id: 'UPDATE_IMAGE',
    title: strings.UPDATE_PROFILE_IMAGE,
    content: strings.UPDATE_IMAGE_CONFIRM,
    acceptText: strings.CHOOSE_IMAGE,
    declineText: strings.CANCEL,
  },
}

const validationSchema = Yup.object().shape({
  firstName: Yup.string().required('Name is required!').nullable(true),
  lastName: Yup.string().required('Last name is required!').nullable(true),
  email: Yup.string().email(strings.ERROR_INVALID_EMAIL),
  title: Yup.string().required('Title is required!').nullable(true),
  state: Yup.string().required('State is required!').nullable(true),
  city: Yup.string().required('City is required!').nullable(true),
  country: Yup.string().required('Country is required!').nullable(true),
  company: Yup.string().required('Company name is required!').nullable(true),
})

const defaults = {
  firstName: '',
  lastName: '',
  email: '',
  title: '',
  company: '',
  city: '',
  country: '',
  state: '',
}

/**
 *
 * @constructor
 */
const Profile = () => {
  /*******************************************************
   * GRAPHQL
   *******************************************************/
  /**
   * define queries
   */
  const { error, data, loading } = useQuery<
    GetUserInfoQuery,
    GetUserInfoQueryVariables
  >(GET_USER_INFO, {
    fetchPolicy: 'cache-only',
  })
  useErrorHandler(error)

  // @todo do we need this?
  useContext(Auth0Context)

  /**
   * define mutations
   */
  const [
    updateProfileMutation,
    { loading: updatingUser, error: updateProfileError },
  ] = useMutation<UserUpdateMutation, UserUpdateMutationVariables>(UPDATE_USER)
  useErrorHandler(updateProfileError)

  const isAllFieldsProvided = UserModel.checkIsAllMandatoryFieldsProvided<
    GetUserInfoQuery['user']
  >(data?.user)

  /*******************************************************
   * STATE
   *******************************************************/
  const [showDeactivateModal, changeDeactivateModal] = useState<boolean>(false)
  const [showUpdateLinkedinModal, changeUpdateLinkedinModal] =
    useState<boolean>(false)
  const [showUpdateImgModal, changeUpdateImgModal] = useState<boolean>(false)
  const [showWelcomeModal, setWelcomeModal] = useState<boolean>(
    !isAllFieldsProvided,
  )
  const [profileImg, changeProfileImg] = useState<string>(PlaceholderImage)
  const [modalKey, setModalKey] = useState<number>(0)

  /*******************************************************
   * CONTEXT
   *******************************************************/
  const snackbar = useContext(SnackbarContext)

  /*******************************************************
   * HOOKS
   *******************************************************/
  const history = useHistory()
  const { user } = useAuth0()
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { control, getValues, setValue, reset, watch, trigger, formState } =
    useForm<UserProfileFormValues>({
      resolver: yupResolver(validationSchema),
      mode: 'onBlur',
      reValidateMode: 'onBlur',
      defaultValues: {
        firstName: defaults.firstName,
        lastName: defaults.lastName,
        email: defaults.email,
        title: defaults.title,
        company: defaults.company,
        city: defaults.city,
        country: defaults.country,
        state: defaults.state,
      },
    })

  const watchCountry = watch('country')

  /*******************************************************
   * LIFE CYCLE HOOKS
   *******************************************************/
  useEffect(() => {
    const location = data?.user?.avatar?.file?.location
    changeProfileImg(location || '')

    if (data && data.user) {
      const formUserData = {
        firstName: data.user.firstName || defaults.firstName,
        lastName: data.user.lastName || defaults.lastName,
        email: data.user.email || defaults.email,
        title: data.user.title || defaults.title,
        company: data.user.company || defaults.company,
        city: data.user.city || defaults.city,
        country: data.user.country?.toUpperCase() || defaults.country,
        state: data.user.state || defaults.state,
      }
      reset(formUserData)
    }
  }, [data, reset])

  /**
   *
   */
  const updateProfile = useCallback(async () => {
    const values = getValues()
    const isValid = await trigger()
    if (!isValid) return

    const { avatar, __typename, provider, ...userInput } = {
      ...data?.user,
      ...values,
    }
    if (!userInput.id) return

    const variables: UserUpdateMutationVariables = {
      userInput: {
        ...userInput,
        id: userInput.id,
      },
    }

    const updatedResult = await updateProfileMutation({
      variables,
    })

    if (!updateProfileError) {
      snackbar.setMessage({
        type: SnackbarTypes.SUCCESS,
        message: strings.SUCCESS_UPDATE,
        show: true,
        anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
      })
    }
    const isAllFieldsUpdated = UserModel.checkIsAllMandatoryFieldsProvided<
      Partial<GetUserInfoQuery['user']>
    >(updatedResult.data?.userUpdate)
    if (!isAllFieldsProvided && isAllFieldsUpdated) {
      history.push(`/${RouteNames.HOME}`)
    }
  }, [updateProfileMutation, data?.user, profileImg])

  /**
   *
   */
  const updateProfileImage = useCallback(
    async (fileId?: number) => {
      if (data?.user) {
        await updateProfileMutation({
          variables: {
            userInput: {
              id: data?.user.id,
              avatar: fileId ? fileId.toString() : null,
            },
          },
        })

        snackbar.setMessage({
          type: SnackbarTypes.SUCCESS,
          message: strings.SUCCESS_UPDATE,
          show: true,
          anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
        })
      }
    },
    [updateProfileMutation, data?.user],
  )

  /*******************************************************
   * FUNCTIONS
   *******************************************************/
  /**
   * check if we can import image from linked in
   * @todo test other social providers
   */
  const checkImgFromLinkedin = () => {
    //const token: any = getIdTokenClaims();
    return !!(user && user.picture)
  }

  /**
   * get picture if present on auth0 id token
   */
  const updateImgFromLinkedin = () => {
    // @todo fix type
    //const token: any = await getIdTokenClaims();

    if (user && user.picture) {
      changeProfileImg(user.picture)
    }

    changeUpdateLinkedinModal(!showUpdateLinkedinModal)
  }

  if (loading || !data?.user) return <CenteredLoader />
  const showLinkedIn = checkImgFromLinkedin()

  /*******************************************************
   * RENDER
   *******************************************************/
  return (
    <SC.Wrapper>
      <ProfileContainer>
        <SC.EditAvatarWrap>
          <SC.StyledAvatar
            src={profileImg || profilePlaceholderImage}
            data-testid="profile-image"
            aria-label="profile-image"
          />
          <SC.EditAvatarInner>
            <SC.UpdateImgBtn
              onClick={() => changeUpdateImgModal(!showUpdateImgModal)}
              variant="contained"
            >
              {data?.user?.avatar?.file?.location
                ? strings.UPDATE_PROFILE_IMAGE
                : strings.ADD_PROFILE_IMAGE}
            </SC.UpdateImgBtn>
            {features.IMPORT_FROM_LINKEDIN && (
              <SC.ImportLinkedIn
                disabled={!showLinkedIn}
                onClick={() =>
                  changeUpdateLinkedinModal(!showUpdateLinkedinModal)
                }
              >
                <SC.LinkedInImg src={linkedInImage} />
                {strings.IMPORT_FROM_LINKEDIN}
              </SC.ImportLinkedIn>
            )}
          </SC.EditAvatarInner>
        </SC.EditAvatarWrap>
        <form>
          <SC.FieldWrapper>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6} lg={6}>
                <FormInput
                  name="firstName"
                  control={control}
                  formState={formState}
                  label={`${strings.FIRST_NAME} *`}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={6}>
                <FormInput
                  name="lastName"
                  control={control}
                  formState={formState}
                  label={`${strings.LAST_NAME} *`}
                />
              </Grid>
              <Grid item xs={12}>
                <SC.HalfWidthInput
                  name="email"
                  disabled
                  control={control}
                  label={`${strings.EMAIL} *`}
                  formState={formState}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={6}>
                <FormInput
                  name="title"
                  control={control}
                  formState={formState}
                  label={`${strings.TITLE} *`}
                />
              </Grid>
              <Grid item xs={12} sm={6} lg={6}>
                <FormInput
                  name="company"
                  control={control}
                  formState={formState}
                  label={`${strings.COMPANY} *`}
                />
              </Grid>
              <Grid item xs={12}>
                <SC.HalfWidthSelect
                  testid="profile-country"
                  name="country"
                  control={control}
                  formState={formState}
                  label={`${strings.COUNTRY} *`}
                  onChange={() => {
                    setValue('state', '')
                  }}
                  options={countries
                    .filter((el) => allowedCountryCodes.includes(el.code))
                    .map((country) => ({
                      label: country.label,
                      value: country.code,
                    }))}
                />
              </Grid>
              {watchCountry && (
                <>
                  <Grid item xs={12} sm={6} lg={6}>
                    <FormSelect
                      testid="profile-state"
                      name="state"
                      control={control}
                      formState={formState}
                      label={`${
                        watchCountry === 'CA' ? strings.PROVINCE : strings.STATE
                      } *`}
                      options={getRegions(watchCountry).map((region) => ({
                        label: region.name,
                        value: region.code,
                      }))}
                    />
                  </Grid>
                  <Grid item xs={12} sm={6} lg={6}>
                    <FormInput
                      name="city"
                      control={control}
                      formState={formState}
                      label={`${strings.CITY} *`}
                    />
                  </Grid>
                </>
              )}
            </Grid>
          </SC.FieldWrapper>
          <SC.Actions>
            {updatingUser ? (
              <SC.LoadingButton
                color="primary"
                size="large"
                variant="contained"
                disabled
              >
                Updating... <CenteredLoader size={35} />
              </SC.LoadingButton>
            ) : (
              <SC.UpdateBtn
                onClick={updateProfile}
                color="primary"
                variant="contained"
                disabled={!formState.isDirty || !formState.isValid}
              >
                {!isAllFieldsProvided
                  ? strings.GET_STARTED
                  : strings.UPDATE_PROFILE}
              </SC.UpdateBtn>
            )}
            {features.DISABLE_ACCOUNT_BUTTON && (
              <SC.DeactivateBtn
                type="button"
                disabled
                onClick={() => changeDeactivateModal(!showDeactivateModal)}
              >
                {strings.DEACTIVATE_ACCOUNT}
              </SC.DeactivateBtn>
            )}
          </SC.Actions>
        </form>
      </ProfileContainer>
      <ProfileWelcomeDialog
        open={showWelcomeModal}
        onClose={() => setWelcomeModal(!showWelcomeModal)}
      />
      <ProfileActionDialog
        open={showDeactivateModal}
        dialogs={dialogs.deactivate}
        handleAccept={() => changeDeactivateModal(!showDeactivateModal)}
        handleDecline={() => changeDeactivateModal(!showDeactivateModal)}
      />
      <ProfileActionDialog
        open={showUpdateLinkedinModal}
        dialogs={dialogs.updateLinkedinImg}
        handleAccept={updateImgFromLinkedin}
        handleDecline={() =>
          changeUpdateLinkedinModal(!showUpdateLinkedinModal)
        }
      />
      <ProfileUpdateAvatar
        open={showUpdateImgModal}
        key={modalKey}
        profileImg={profileImg}
        dialogs={{
          ...dialogs.updateImg,
        }}
        handleAccept={updateProfileImage}
        handleDecline={() => {
          setModalKey(modalKey + 1)
          changeUpdateImgModal(!showUpdateImgModal)
        }}
      />
    </SC.Wrapper>
  )
}

export default Profile
