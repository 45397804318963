import { ReactElement, useContext } from 'react'
import { ResponsiveBar } from '@nivo/bar'
import { DealIndustry } from 'graphql/graphqlTypes'
import IndustryColor from '../IndustryTag/constants'
import { ChartWrapper } from './styledComponents'
import { DealStageLabels } from 'modules/deal/models/deal'
import { TotalsSumBy } from 'modules/pipeline/enum'
import { PipelineContext } from '../../contexts/pipeline'
import { CurrencySymbols } from 'modules/pipeline/constants'

/*******************************************************
 * CONST
 *******************************************************/
export const dealIndustries = [
  DealIndustry.HOSPITALITY,
  DealIndustry.INDUSTRIAL,
  DealIndustry.LAND,
  DealIndustry.OFFICE,
  DealIndustry.OTHER,
  DealIndustry.RESIDENTIAL,
  DealIndustry.RETAIL,
]

/*******************************************************
 * FUNCTIONS
 *******************************************************/
const getYAxisLabel = (
  currency: keyof typeof CurrencySymbols,
  sumBy: TotalsSumBy,
) => {
  const currencySymbol = CurrencySymbols[currency]

  const label = `${currencySymbol}${
    currencySymbol === CurrencySymbols.CHF ? ',' : ''
  }M`

  const YAxis = {
    [TotalsSumBy.COUNT]: 'Number of deals',
    [TotalsSumBy.NAV]: `NAV (${label})`,
    [TotalsSumBy.GAV]: `GAV (${label})`,
  }
  return YAxis[sumBy]
}

/**
 *
 * @param data
 * @constructor
 */
const PipeLineChart = ({ data }): ReactElement => {
  /*******************************************************
   * CONTEXT
   *******************************************************/
  const { state } = useContext(PipelineContext)

  /*******************************************************
   * RENDER
   *******************************************************/
  return (
    <ChartWrapper>
      <ResponsiveBar
        data={data.map(({ stage, ...industryValues }) => {
          const industryData = {
            ...industryValues,
            stage: DealStageLabels[stage],
          }
          if (state.sumBy !== TotalsSumBy.COUNT) {
            return Object.entries(industryValues).reduce((acc, param) => {
              const [key, value] = param
              // @todo 01-22 fix this type
              return dealIndustries.includes(key as DealIndustry)
                ? { ...acc, [key]: value / 1000000 }
                : { ...acc }
            }, industryData)
          }

          return industryData
        })}
        keys={Object.keys(DealIndustry).map((key) => DealIndustry[key])}
        indexBy="stage"
        margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
        padding={0.3}
        enableLabel={false}
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        colors={Object.keys(IndustryColor).map((ind) => IndustryColor[ind])}
        borderColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 0,
          tickPadding: 10,
          tickRotation: 0,
          legend: 'Stages',
          legendPosition: 'middle',
          legendOffset: 40,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: getYAxisLabel(state.currency, state.sumBy),
          legendPosition: 'middle',
          legendOffset: -50,
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{ from: 'color', modifiers: [['darker', 1.6]] }}
        legends={[
          {
            dataFrom: 'keys',
            anchor: 'bottom-right',
            direction: 'column',
            justify: false,
            translateX: 120,
            translateY: 0,
            itemsSpacing: 16,
            itemWidth: 100,
            itemHeight: 20,
            itemDirection: 'left-to-right',
            itemOpacity: 0.85,
            symbolSize: 20,
            symbolShape: 'circle',
            effects: [
              {
                on: 'hover',
                style: {
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
        role="application"
        ariaLabel=""
        barAriaLabel={(e) =>
          `${e.id}: ${e.formattedValue} in country: ${e.indexValue}`
        }
      />
    </ChartWrapper>
  )
}

export default PipeLineChart
