// @common
// @types
import {
  GetDealsQueryVariables,
  GetDealsQuery,
  DealsFilterInput,
  ItemsFilterInput,
  GetItemsQuery,
  GetItemsQueryVariables,
  GetItemsWithUserQuery,
  GetItemsWithUserQueryVariables,
  GetDocumentsQuery,
  GetDocumentsQueryVariables,
  FiltersNodeFragment,
  GetDealTotalsQuery,
  GetDealTotalsQueryVariables,
} from 'graphql/graphqlTypes'
import { ApolloQueryResult, QueryResult } from '@apollo/client'
import { isOfTypeDynamic } from 'modules/common/types'
import { useState } from 'react'

/*******************************************************
 * TYPE
 *******************************************************/
export type Filter = {
  key: string
  values: any
}
export type Filters = (Filter | undefined)[]

export interface FiltersHookReturn {
  data: (FiltersNodeFragment | undefined | null)[]
  changeCriteria: (filters: Partial<Filters>) => void
  datePickerChangeHandler: (range: { from: Date; to: Date }) => void
}

export type QueryResultUnion =
  | QueryResult<GetDealsQuery, GetDealsQueryVariables>
  | QueryResult<GetItemsQuery, GetItemsQueryVariables>
  | QueryResult<GetItemsWithUserQuery, GetItemsWithUserQueryVariables>
  | QueryResult<GetDocumentsQuery, GetDocumentsQueryVariables>
  | {
      data: QueryResult<GetDealTotalsQuery, GetDealTotalsQueryVariables>['data']
      variables: QueryResult<GetDealsQuery, GetDealsQueryVariables>['variables']
      refetch: QueryResult<GetDealsQuery, GetDealsQueryVariables>['refetch']
    }

type PropsType = {
  refetchOverride?: (variables: {
    filters?: DealsFilterInput | ItemsFilterInput
  }) => Promise<ApolloQueryResult<GetItemsQuery | GetDealTotalsQuery>> | void
  queryResult: QueryResultUnion
}

/**
 *
 * @param queryData
 * this 'logic' used to be performed in the calling/parent component
 * possibly it would have been cleaner to leave it there
 *
 */
const getMetaFromQuery = (queryData) /*: FiltersNodeFragment[] */ => {
  if (!queryData) {
    return []
  }

  if (isOfTypeDynamic<GetDealsQuery>(queryData, 'deals')) {
    return queryData.deals.meta.allFilters || []
  }

  if (
    isOfTypeDynamic<GetItemsQuery>(queryData, 'items') ||
    isOfTypeDynamic<GetItemsWithUserQuery>(queryData, 'items')
  ) {
    return queryData.items.meta.allFilters
  }

  if (isOfTypeDynamic<GetDocumentsQuery>(queryData, 'documents')) {
    return queryData.documents.meta.allFilters || []
  }

  if (isOfTypeDynamic<GetDealTotalsQuery>(queryData, 'dealTotals')) {
    return queryData.dealTotals.meta.allFilters || []
  }
  return []
}

/**
 *
 * @param WrappedComponent
 */
const useFilters = ({
  queryResult,
  refetchOverride,
}: PropsType): FiltersHookReturn => {
  /*******************************************************
   * PROPS
   *******************************************************/
  const { refetch, data: queryData, variables } = queryResult
  const [filterHolder, setFilterHolder] = useState({})
  /**
   * changeCriteria()
   * @return {(evt: React.ChangeEvent<HTMLSelectElement>) => void}
   * @param filters
   */
  const changeCriteria = (filters: Filters) => {
    const newFilters = filters.reduce((acc, curr) => {
      if (!curr) return acc
      const { values, key } = curr
      const currentKey = key || ''
      if (
        values &&
        Array.isArray(values) &&
        values.length &&
        !values.find((el) => el === 'RESET')
      ) {
        acc[currentKey] = values
      } else if (values && !Array.isArray(values) && values !== 'RESET') {
        acc[currentKey] = values
      } else {
        acc[currentKey] = null
      }
      return acc
    }, filterHolder)
    setFilterHolder(newFilters)
    return refetchQuery(newFilters)
  }

  /**
   *
   * @param newFilters
   */
  const refetchQuery = (newFilters) => {
    // construct new variables based on user toggled filter options
    const newVariables = {
      filters: {
        ...variables?.filters,
        ...newFilters,
      },
      orderBy: {
        ...variables?.orderBy,
      },
    }
    // if a custom 'refetch callback' was passed into the hook, use this,
    // otherwise use the standard refetch that was passed in within the original apollo query
    if (refetchOverride) {
      return refetchOverride(newVariables)
    }
    return refetch(newVariables)
  }

  /**
   *
   * @param from
   * @param to
   */
  const datePickerChangeHandler = async ({
    from,
    to,
  }: {
    from: Date
    to: Date
  }) => {
    await changeCriteria([
      {
        key: 'createdDateStart',
        values: from,
      },
      {
        key: 'createdDateEnd',
        values: to,
      },
    ])
  }

  return {
    datePickerChangeHandler,
    changeCriteria,
    data: getMetaFromQuery(queryData),
  }
}

export default useFilters
