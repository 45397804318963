import { css } from 'styled-components'
import colors from 'constants/colors'
import { from, device } from 'modules/common/styledComponents/MediaQueries'

export const flex = css`
  display: flex;
`

export const flexCenter = css`
  ${flex};
  align-items: center;
`

export const flexEnd = css`
  ${flex};
  align-items: flex-end;
`

export const spaceBetweenCenter = css`
  ${flexCenter};
  justify-content: space-between;
`

export const dashboardRowBasic = (padding: string) => css`
  border: 1px solid ${colors.veryLightBlue};
  border-top: 0;
  padding: ${padding};
  transition: 0.2s;
  cursor: pointer;

  &:hover {
    background: ${colors.paleGrey};
  }
`

export const linkMixin = css`
  text-decoration: none;
  color: ${colors.white};
  transition: 0.05s;

  &:hover {
    color: ${colors.yellowishOrange};
  }

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
  }
`

export const avatarImage = css`
  width: 50px;
  height: 50px;
  margin-right: 0.7em;

  ${from(device.tablet)} {
    margin-right: 1em;
  }

  ${from(device.ipad)} {
    margin-right: 28px;
  }
`
