// @react
import React from 'react'
import styled from 'styled-components'
import colors from 'constants/colors'

interface InjectProps {
  count: number
  unread?: boolean
}

type PropsType = InjectProps

const Badge = styled.span`
  align-items: center;
  display: inline-flex;
  min-width: 20px;
  margin-left: 7px;
  padding: 5px 7px;
  justify-content: center;
  vertical-align: middle;
  border-radius: 4px;
  line-height: 1;
  background-color: ${colors.paleGrey};
  color: ${colors.dark};

  ${(props) =>
    props.unread &&
    `
    background-color: ${colors.grapefruit};
    color: ${colors.white};
  `}
`

const Count = styled.span`
  font-size: 10px;
`

const StyledBadge = ({ count, unread }: PropsType) => {
  if (count === 0) {
    return null
  }

  return (
    <Badge className="SB-badge" unread={unread}>
      <Count>{count}</Count>
    </Badge>
  )
}

export default StyledBadge
