// @react
import { FunctionComponent } from 'react'
// @design
import * as SC from './styledComponents/datesFilter'
import { Typography } from '@material-ui/core'
// @common
import { format } from 'date-fns'
import config from 'constants/config'
import strings from 'constants/strings'
import { Filters } from 'modules/common/components/_UI_SMART/SharedTable/hook/filters'
// @components
import FreeSelect from 'modules/common/components/_FORM_ELEMENTS/FreeSelect'
import FreeDatePicker from 'modules/common/components/_FORM_ELEMENTS/FreeDatePicker/FreeDatePicker'

/*******************************************************
 * TYPES
 *******************************************************/
type DateObject = {
  from: Date
  to: Date
}

interface PropsType {
  createdDateStart?: Date | string | null
  createdDateEnd?: Date | string | null
  changeCriteria: (fieldType: Filters) => void
  datePickerChangeHandler: (dateObject: DateObject) => void
}

const DatesFilter: FunctionComponent<PropsType> = ({
  createdDateStart: dateStart,
  createdDateEnd: dateEnd,
  changeCriteria,
  datePickerChangeHandler,
}) => {
  /*******************************************************
   * CONST
   *******************************************************/
  // @todo 'values prop' here makes no sense, this should be a true Date type?

  const fromDate = dateStart ? Number(dateStart) : dateStart
  const toDate = dateEnd ? Number(dateEnd) : dateEnd

  /*******************************************************
   * FUNCTIONS
   *******************************************************/
  /**
   *
   * @param date
   */
  const getFormat = (date: number | string): string => {
    return format(new Date(date), config.dateFormat)
  }

  /**
   *
   */
  const getRenderValue = () => {
    if (fromDate || toDate) {
      const timeNow = format(new Date(), config.dateFormat)

      const fromDateStr = fromDate ? getFormat(fromDate) : timeNow
      const toDateStr = toDate ? getFormat(toDate) : timeNow
      return `${fromDateStr} - ${toDateStr}`
    }

    return strings.ALL_DATES
  }

  /*******************************************************
   * RENDER
   *******************************************************/
  return (
    <FreeSelect
      value="DATE"
      label={strings.DATE}
      onChange={() => {
        changeCriteria([
          {
            key: 'createdDateStart',
            values: 'RESET',
          },
          {
            key: 'createdDateEnd',
            values: 'RESET',
          },
        ])
      }}
      renderValue={() => {
        return <Typography>{getRenderValue()}</Typography>
      }}
    >
      <SC.ClearDates value="RESET">
        <SC.CancelIcon />
        {strings.CLEAR_SELECTION}
      </SC.ClearDates>
      <SC.DatepickerWrap value="DATE" disableGutters>
        <div
          onClick={(e) => {
            // because we don't want MenuItem to dismiss when we click
            e.stopPropagation()
          }}
        >
          <FreeDatePicker
            onChange={datePickerChangeHandler}
            from={fromDate ? new Date(fromDate) : new Date()}
            to={toDate ? new Date(toDate) : new Date()}
          />
        </div>
      </SC.DatepickerWrap>
    </FreeSelect>
  )
}

export default DatesFilter
