// @todo why this status?
/*******************************************************
 * ENUMS
 *******************************************************/
import React from 'react'
import { FetchMoreQueryOptions } from '@apollo/client/core/watchQueryOptions'
import { FetchMoreOptions } from '@apollo/client/core/ObservableQuery'
import { ApolloQueryResult } from '@apollo/client/core/types'
import { DocumentNode } from 'apollo-link'

export enum ItemStatus {
  DISABLED = 'DISABLED',
  CONNECTED = 'CONNECTED',
}

export enum Team {
  MY_TEAM = 'MY_TEAM',
  THEIR_TEAM = 'THEIR_TEAM',
}

/*******************************************************
 * TYPES
 *******************************************************/

export type GroupedMenuItem = {
  title?: string
  data: { clickAction: () => void; Component: React.ReactNode }[]
}
/*******************************************************
 * APOLLO
 *******************************************************/
/**
 * this is a 'fake' type for fetch more whose type was hard to extract
 */
export type ApolloFetchMoreType<TData, TVariables> = (<
  K extends keyof TVariables,
>(
  fetchMoreOptions: FetchMoreQueryOptions<TVariables, K> &
    FetchMoreOptions<TData, TVariables>,
) => Promise<ApolloQueryResult<TData>>) &
  // Note I do not use TData2 or TVariables2
  (<K extends keyof TVariables>(
    fetchMoreOptions: {
      query?: DocumentNode
    } & FetchMoreQueryOptions<TVariables, K> &
      FetchMoreOptions<TData, TVariables>,
  ) => Promise<ApolloQueryResult<TData>>)

/*******************************************************
 * TYPEGUARDS
 *******************************************************/

/**
 *   @ArrayElement
 * pulls the type of a single element from a list, very useful for GQL partial queries without corresponding type fragments
 */
export type ArrayElement<A> = A extends readonly (infer T)[] ? T : never

export const isArrayType = <T>(content: T | T[]): content is T[] => {
  return (content as T[]).length !== undefined
}

/**
 *
 * @param varToBeChecked
 * @param propertyKey
 * @param propertyValue
 *
 *  example from GQL result:
 *
 *   if (
 *    isOfType<TypeObjectFromGraphqlTypes>(
 *     content,
 *     '__typename',
 *     'TypeObjectFromGraphqlTypes',
 *   )
 *  ) {
 */
export const isOfType = <T>(
  varToBeChecked: unknown | undefined,
  propertyKey: keyof T,
  propertyValue: T[keyof T],
): varToBeChecked is T => {
  if (!varToBeChecked) return false
  return (varToBeChecked as T)[propertyKey] === propertyValue
}

/**
 *
 * @param varToBeChecked
 * @param propertyKey
 *
 * type guard checks for existence of attribute
 * ex:
 *    link = constant to check
 *    LinksLink = type to verify
 *    'to' = attribute must exist on LinksLink
 *
 *   isOfTypeDynamic<LinksLink>(link, 'to')
 *
 */
export const isOfTypeDynamic = <T>(
  varToBeChecked: unknown,
  propertyKey: keyof T,
): varToBeChecked is T => (varToBeChecked as T)[propertyKey] !== undefined

/**
 *
 * @param myEnum
 * @param enumValue
 */
export const getEnumKeyByEnumValue = <T>(
  myEnum: unknown,
  enumValue: number | string,
): string => {
  const keys = Object.keys(myEnum as T).filter(
    (x) => (myEnum as T)[x] === enumValue,
  )
  return keys.length > 0 ? keys[0] : ''
}

/**
 *
 * @param myEnum1
 * @param myEnum2
 * @param enumValue
 */
export const equivocateEnum = <T, P>(
  myEnum1: unknown,
  myEnum2: unknown,
  enumValue: number | string,
): P | null => {
  const enumKey = Object.keys(myEnum1 as T)[
    Object.values(myEnum1 as T).indexOf(enumValue)
  ]
  if (!enumKey) return null
  if (!(myEnum2 as P)[enumKey]) return null
  return (myEnum2 as P)[enumKey]
}

export enum DataActionType {
  REMOVE = 'REMOVE',
  UPDATE = 'UPDATE',
  ADD = 'ADD',
}
