// @react
import { FiltersNodeFragment } from 'graphql/graphqlTypes'
import React, { useState } from 'react'
// @common
import { MenuItem, Typography } from '@material-ui/core'
import FreeSelect from 'modules/common/components/_FORM_ELEMENTS/FreeSelect'
// @components
import Checkbox from '@material-ui/core/Checkbox'
import { Filters } from 'modules/common/components/_UI_SMART/SharedTable/hook/filters'
import CenteredLoader from 'modules/common/components/_UI_DUMB/Loaders/CenteredLoader'
import { Filter } from '../hook/filterSort'

/*******************************************************
 * TYPES
 *******************************************************/
interface PropsType {
  type: string
  changeCriteria: (filters: Partial<Filters>) => void
  filters?: FiltersNodeFragment | null
}

/**
 *
 * @param props
 * @constructor
 */
const ListFilter = ({ changeCriteria, type, filters }: PropsType) => {
  /*******************************************************
   * STATE
   *******************************************************/
  const [isLoading, setIsLoading] = useState<boolean>(false)

  /**
   *
   */
  const freeSelectValues = () => {
    return (
      filters?.values.filter((el) => el.isActive).map((el) => el.value || '') ||
      []
    )
  }

  /*******************************************************
   * RENDER
   *******************************************************/
  return (
    <FreeSelect
      value={freeSelectValues()}
      label={filters?.label || ''}
      renderValue={() => (
        <Typography>
          {filters?.values
            .filter((el) => el.isActive)
            .map((el) => el.label)
            .join(', ') || filters?.allLabel}
        </Typography>
      )}
      onChange={async (evt) => {
        if (isLoading) return
        setIsLoading(true)
        await changeCriteria([{ key: type, values: evt.target.value }])
        setIsLoading(false)
      }}
      multiple
    >
      <MenuItem value={'RESET'}>
        <Checkbox
          color="primary"
          disabled={isLoading}
          checked={!filters?.values.filter((el) => el.isActive).length}
        />
        <Typography>{filters?.allLabel}</Typography>
      </MenuItem>

      {filters?.values.map((el) => {
        return (
          <MenuItem key={el.value} value={el.value || ''}>
            {isLoading ? (
              <Checkbox
                icon={<CenteredLoader size={20} />}
                color="primary"
                checked={el.isActive}
                disabled={true}
              />
            ) : (
              <Checkbox
                color="primary"
                checked={el.isActive}
                disabled={isLoading}
              />
            )}

            <Typography>{el.label}</Typography>
          </MenuItem>
        )
      })}
    </FreeSelect>
  )
}

export default ListFilter
