// language=GraphQL
import { gql } from '@apollo/client'

export const CREATE_MESSAGE = gql`
  mutation CreateMessage($input: CreateMessageInput) {
    messageCreate(input: $input) {
      id
    }
  }
`

export const SET_AS_READ = gql`
  mutation SetAsReadByUser($id: ID!) {
    messageSetAsReadByUser(id: $id) {
      message
      status
    }
  }
`
