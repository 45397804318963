// @react
import React from 'react'
import { Tooltip, withStyles, Zoom } from '@material-ui/core'
import parse from 'html-react-parser'
import { colors } from 'constants/colors'

/*******************************************************
 * CONST
 *******************************************************/
const TooltipInfo = withStyles({
  tooltip: {
    backgroundColor: colors.aquaMarine,
    color: colors.white,
    border: `1px solid ${colors.white}`,
  },
})(Tooltip)

const TooltipWarning = withStyles({
  tooltip: {
    backgroundColor: colors.grapefruit,
    color: colors.white,
    border: colors.grapefruit,
  },
})(Tooltip)

/*******************************************************
 * TYPE
 *******************************************************/
interface PropsType {
  title?: string
  className?: string
  theme?: string
  children: React.ReactElement
}

/**
 *
 * @param title
 * @param children
 * @param theme
 * @constructor
 */
const SwitchboardTooltip = ({ title, children, theme }: PropsType) => {
  /*******************************************************
   * CONST
   *******************************************************/
  const StyledToolTip = theme === 'warning' ? TooltipWarning : TooltipInfo

  /*******************************************************
   * RENDER
   *******************************************************/
  if (title)
    return (
      <StyledToolTip
        enterDelay={800}
        TransitionComponent={Zoom}
        TransitionProps={{ timeout: 100 }}
        title={parse(title)}
        leaveDelay={200}
      >
        {children}
      </StyledToolTip>
    )

  return <>{children}</>
}

export default SwitchboardTooltip
