import { Button, Checkbox, Grid } from '@material-ui/core'
import { flexCenter } from 'modules/common/styledComponents/Mixins'
import styled from 'styled-components'
import { Controller } from 'react-hook-form'
import FormInput from 'modules/common/components/_FORM_ELEMENTS/HookForm/form-inputs/FormInput'
import FormSelect from 'modules/common/components/_FORM_ELEMENTS/HookForm/form-inputs/FormSelect'
import FormDatepicker from 'modules/common/components/_FORM_ELEMENTS/HookForm/form-inputs/FormDatepicker'

export const SubmitButton = styled(Button)`
  && {
    width: 100%;
    padding: 15px 106px;
    text-transform: uppercase;
  }
`

export const StyledField = styled(Controller)`
  margin-bottom: 30px;
`

export const StyledInputField = styled(FormInput)`
  width: 100%;
  margin-bottom: 30px;
`

export const StyledSelectField = styled(FormSelect)`
  margin-bottom: 30px;
`

export const StyledDatepickerField = styled(FormDatepicker)`
  margin-bottom: 30px;
`

export const RadioField = styled(Controller)`
  margin-left: 0;
`

export const FlexWrap = styled.div`
  ${flexCenter};
  margin-bottom: 25px;
`

export const FlexCenter = styled.div`
  ${flexCenter};
`

export const GridContainer = styled(Grid)`
  && {
    margin-bottom: 26px;
  }
`

export const CheckboxWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  ${({ disabled }) => (disabled ? 'cursor: default;' : 'cursor: pointer;')}
`

export const StyledLabel = styled.label`
  ${({ disabled }) => (disabled ? 'cursor: default;' : 'cursor: pointer;')}
`

export const StyledCheckbox = styled(Checkbox)`
  && {
    margin-right: 15px;
  }
`
