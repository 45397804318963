import { Grid } from '@material-ui/core'
import Skeleton from '@material-ui/lab/Skeleton'
import React from 'react'

export const LoadingBlocks = ({
  count,
  size,
}: {
  count: number
  size: 'short' | 'tall' | 'medium'
}) => {
  const heights = {
    short: 156,
    medium: 187,
    tall: 220,
  }

  return (
    <Grid container spacing={3}>
      {Array(count)
        .fill('')
        .map((item, idx) => (
          <Grid key={idx} item xs={12} sm={6} md={4}>
            <Skeleton variant="rect" width="100%" height={heights[size]} />
          </Grid>
        ))}
    </Grid>
  )
}
