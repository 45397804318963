// @react
import React from 'react'
import { useHistory } from 'react-router-dom'
import RecentNotificationsItem from 'modules/notification/components/RecentNotificationsItem'
// @common
import { formatDistanceToNowStrict, parseISO } from 'date-fns'
// @components
import * as SC from '../../styledComponents/notifications'
// @graphql
import { MutationFunctionOptions } from '@apollo/client'
import { NotificationItemFragment as NotificationType } from 'graphql/graphqlTypes'
import NotificationModel from '../../models'

/*******************************************************
 *
 *******************************************************/
type PropsType = {
  notification: NotificationType
  updateNotification: (
    options?: MutationFunctionOptions | undefined,
  ) => Promise<unknown>
}

/**
 *
 * @param props
 * @constructor
 */
const NotificationItem = ({ notification, updateNotification }: PropsType) => {
  /*******************************************************
   * CONST
   *******************************************************/
  const link = NotificationModel.getLink(notification)

  /*******************************************************
   * HOOKS
   *******************************************************/
  const history = useHistory()

  /*******************************************************
   * FUNCTIONS
   *******************************************************/

  /**
   *
   */
  const markMessageRead = async () => {
    if (notification.read) return
    if (updateNotification)
      void (await updateNotification({
        variables: { input: { id: notification.id, read: true } },
      }))
  }

  /**
   *
   */
  const navigationHandler = async () => {
    await markMessageRead()
    link && history.push(link)
  }
  /*******************************************************
   * RENDER
   *******************************************************/
  return (
    <SC.Wrap
      key={notification}
      seen={notification.read}
      onClick={navigationHandler}
    >
      <RecentNotificationsItem
        avatar={notification.createdBy.avatar?.file?.location}
        disableHover={notification.read}
        fromTime={formatDistanceToNowStrict(
          parseISO(notification.createdDate),
          {
            addSuffix: true,
          },
        )}
        name={`${String(notification.createdBy.firstName)} ${String(
          notification.createdBy.lastName,
        )}`}
        seen={notification.read}
        text={notification.message || ''}
        active={notification.read}
        newClasses={{ text: 'notification-text' }}
      />
    </SC.Wrap>
  )
}

export default NotificationItem
