import styled from 'styled-components'
import { ContentContainer } from 'modules/common/styledComponents/Global'
import { flexCenter } from 'modules/common/styledComponents/Mixins'

export const Container = styled(ContentContainer)`
  && {
    flex: 1;
    overflow: auto;
  }
`

export const List = styled.div`
  display: flex;
  flex-direction: column;
  overflow: auto;
`

export const DateTitle = styled.div`
  && {
    ${flexCenter}
  }
  flex-direction: column;
  overflow: auto;
  width: 100%;
  font-size: 12px;
  padding: 20px 0;
`
