// @react
import { Dispatch, SetStateAction, useEffect } from 'react'
// @design
import * as SC from '../styledComponents/createDealDocuments'
import * as ButtonsSC from 'modules/common/components/_UI_DUMB/Button/styledComponents'
import { DialogActions, Grid, DialogContent } from '@material-ui/core'
// @constants
import { ImageExtensions } from 'constants/mime'
// @common
import strings from 'constants/strings'
// @components
import DragAndDropArea from 'modules/common/components/_UI_DUMB/DragAndDropArea/DragAndDropArea'
// @types
import {
  AddDealDocumentsMutation,
  AddDealDocumentsMutationVariables,
  DealDocumentInput,
  DocumentType,
  Scalars,
  Document,
} from 'graphql/graphqlTypes'
// @graphql
import { DEAL_ADD_DOCUMENTS } from 'graphql/mutations/deal.mutation'
import { useMutation } from '@apollo/client'
import { DealDocs } from '../../../common/types'

/*******************************************************
 * TYPES
 *******************************************************/
interface PropsType {
  dealId: Scalars['ID']
  mode?: 'edit' | 'create'
  onSubmit: () => void
  previousStep: (unknown) => void
  documentsState: DealDocs
  setDocumentsState: Dispatch<SetStateAction<DealDocs>>
  changeStepTitle: (title: string) => void
}

/**
 *
 * @param props
 * @constructor
 */
const CreateDealDocuments = ({
  previousStep,
  onSubmit,
  dealId,
  documentsState,
  setDocumentsState,
  changeStepTitle,
}: PropsType) => {
  /*******************************************************
   * HOOKS
   *******************************************************/
  useEffect(() => {
    changeStepTitle('Deal Documents')
  }, [])

  /*******************************************************
   * GRAPHQL
   *******************************************************/

  /**
   * mutations
   */
  const [addDealDocumentsMutation] = useMutation<
    AddDealDocumentsMutation,
    AddDealDocumentsMutationVariables
  >(DEAL_ADD_DOCUMENTS)

  /*******************************************************
   * FUNCTIONS
   *******************************************************/
  /**
   *
   * @param documents
   * @param dealDocumentType
   */
  const uploadFilesHandler = async (
    documents: Document[],
    dealDocumentType: DocumentType,
  ): Promise<void> => {
    switch (dealDocumentType) {
      case DocumentType.CONFI:
        setDocumentsState({
          ...documentsState,
          confi: documents,
        })
        break
      case DocumentType.DEAL_DOC:
        setDocumentsState({
          ...documentsState,
          otherDocs: [...(documentsState.otherDocs || []), ...documents],
        })
        break
      case DocumentType.DEAL_IMAGE:
        setDocumentsState({
          ...documentsState,
          imageDocs: [...(documentsState.imageDocs || []), ...documents],
        })
        break
      case DocumentType.PITCHBOOK:
        setDocumentsState({
          ...documentsState,
          pitchBook: documents,
        })
        break
    }
    await createDealDocumentsHandler(documents, dealDocumentType)
  }

  /**
   *
   * @param documents
   * @param dealDocumentType
   */
  const createDealDocumentsHandler = async (
    documents: any,
    dealDocumentType: DocumentType,
  ): Promise<void> => {
    const documentIds = documents.map((document) => {
      return document.id
    })

    const dealDocumentInput: DealDocumentInput[] = documentIds.map((item) => {
      return {
        id: item,
        type: dealDocumentType,
      }
    })

    await addDealDocumentsMutation({
      variables: {
        id: dealId,
        documents: dealDocumentInput,
      },
    })
  }

  /**
   * updateList
   */
  const updateList = (documentId: string | undefined): void => {
    const newDocumentsState = {}
    for (const documentsStateType in documentsState) {
      if (documentsState.hasOwnProperty(documentsStateType)) {
        newDocumentsState[documentsStateType] = documentsState[
          documentsStateType
        ].filter((item) => {
          return item.id !== documentId
        })
      }
    }
    setDocumentsState(newDocumentsState as DealDocs)
  }

  /*******************************************************
   * RENDER
   *******************************************************/
  return (
    <form onSubmit={onSubmit}>
      <DialogContent>
        <SC.Subtitle variant="subtitle1">
          {strings.UPLOAD_DEAL_IMAGES}
        </SC.Subtitle>
        <SC.DNDWrap>
          <DragAndDropArea
            testId={'create-deal-deal-images'}
            documents={documentsState.imageDocs}
            removeFileCallback={(documentId) => updateList(documentId)}
            fileUpdateCallback={(res: Document[]) => {
              void uploadFilesHandler(res, DocumentType.DEAL_IMAGE)
            }}
            canUpload={true}
            canDeleteDocuments={true}
            isMultiple={true}
            accept={ImageExtensions}
            forceDisableFinalize={true}
          />
        </SC.DNDWrap>
        <SC.Subtitle variant="subtitle1">{strings.UPLOAD_CONFI}</SC.Subtitle>
        <SC.DNDWrap>
          <DragAndDropArea
            testId={'create-deal-confi'}
            documents={documentsState.confi}
            removeFileCallback={(documentId) => updateList(documentId)}
            fileUpdateCallback={(res) => {
              void uploadFilesHandler(res, DocumentType.CONFI)
            }}
            canUpload={true}
            canDeleteDocuments={true}
            isMultiple={false}
            hideUploader={!!documentsState.confi.length}
            forceDisableFinalize={true}
          />
        </SC.DNDWrap>
        <SC.Subtitle variant="subtitle1">
          {strings.UPLOAD_PITCH_BOOK}
        </SC.Subtitle>
        <SC.DNDWrap>
          <DragAndDropArea
            testId={'create-deal-pitchbook'}
            documents={documentsState.pitchBook}
            removeFileCallback={(documentId) => updateList(documentId)}
            fileUpdateCallback={(res) => {
              void uploadFilesHandler(res, DocumentType.PITCHBOOK)
            }}
            canUpload={true}
            canDeleteDocuments={true}
            isMultiple={false}
            hideUploader={!!documentsState.pitchBook.length}
            forceDisableFinalize={true}
          />
        </SC.DNDWrap>
        <SC.Subtitle variant="subtitle1">
          {strings.UPLOAD_DEAL_DOCS}
        </SC.Subtitle>
        <SC.DNDWrap>
          <DragAndDropArea
            testId={'create-deal-deal-docs'}
            documents={documentsState.otherDocs}
            removeFileCallback={(documentId) => updateList(documentId)}
            fileUpdateCallback={(res) => {
              void uploadFilesHandler(res, DocumentType.DEAL_DOC)
            }}
            canUpload={true}
            canDeleteDocuments={true}
            isMultiple={true}
            forceDisableFinalize={true}
          />
        </SC.DNDWrap>
      </DialogContent>
      <DialogActions>
        <SC.Wrap>
          <Grid container justifyContent="center" spacing={3}>
            <Grid item xs={12} sm={6}>
              <ButtonsSC.SubmitBtn
                variant="contained"
                color="primary"
                type="submit"
              >
                NEXT STEP
              </ButtonsSC.SubmitBtn>
            </Grid>
          </Grid>
          <Grid container justifyContent="center">
            <Grid item>
              <ButtonsSC.ActionBtn
                onClick={previousStep}
                size="small"
                color="secondary"
              >
                <span className="underline">Previous Step</span>
              </ButtonsSC.ActionBtn>
            </Grid>
          </Grid>
        </SC.Wrap>
      </DialogActions>
    </form>
  )
}

export default CreateDealDocuments
