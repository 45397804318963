export const fileWhiteList = [
  'gif',
  'jpg',
  'jpeg',
  'png',
  'avux',
  'doc',
  'docx',
  'xls',
  'xlsx',
  'ppt',
  'pptx',
  'vsd',
  'vsdx',
  'pdf',
  'msg',
  'txt',
  'rtf',
  'csv',
  'zip',
  'rar',
]

export const mimeTypes = {
  'application/avux': 'avux',
  'application/zip': 'zip',
  'application/x-rar-compressed': 'rar',
  'application/x-rar-co': 'rar',
  'application/x-tar': 'tar',
  'application/x-apple-diskimage': 'dmg',
  'application/java-archive': 'jar',
  'application/vnd.businessobjects': 'obj',
  'model/vnd.collada+xml': 'dae',
  'application/vnd.koan': 'skp',
  // doc
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
    'doc',
  'application/msword': 'doc',
  'application/doc': 'doc',
  'application/ms-doc': 'doc',
  'text/richtext': 'rtf',
  'text/rtf': 'rtf',
  'application/rtf': 'rtf',
  'application/x-texinfo': 'txt',
  'text/plain': 'txt',
  'application/vnd.oasis.opendocument.text': 'odt',
  'application/x-tex': 'tex',
  // design
  'application/visio': 'vsd',
  'application/visio.drawing': 'vsd',
  'application/vnd.visio.2000': 'vsdx',
  // email
  'application/octet-stream': 'msg',
  // powerpoint
  'application/mspowerpoint': 'ppt',
  'application/powerpoint': 'ppt',
  'application/vnd.ms-powerpoint': 'ppt',
  'application/x-mspowerpoint': 'ppt',
  'application/mspowerp': 'ppt',
  'application/vnd.open': 'pptx',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation':
    'pptx',
  // excel
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
  'application/excel': 'xls',
  'application/vnd.ms-excel': 'xls',
  'application/x-excel': 'xls',
  'application/x-msexcel': 'xls',
  // ai,
  'image/svg+xml': 'svg',
  'application/pdf': 'pdf',
  'application/xml': 'xml',
  'text/csv': 'csv',
  'image/jpeg': 'jpg',
  'image/x-citrix-jpeg': 'jpg',
  'image/pjpeg': 'jpg',
  'video/jpeg': 'jpg',
  'image/gif': 'gif',
  'image/x-png': 'png',
  'image/x-citrix-png': 'png',
  'image/png': 'png',
  // jpeg,
  'image/tiff': 'tif',
  'image/vnd.adobe.photoshop': 'psd',
  'application/x-photoshop': 'psd',
  'application/photoshop': 'psd',
  'application/psd': 'psd',
  'image/psd': 'psd',
  'image/x-adobe-dng': 'raw',
  'image/x-dcraw': 'raw',
  'audio/webm': 'webm',
  'video/webm': 'webm',
  'video/x-matroska': 'mkv',
  'video/x-msvideo': 'avi',
  'video/quicktime': 'mov',
  'video/x-m4v': 'm4v',
  'video/mpeg': 'mpeg',
  'application/mp4': 'mp4',
  'video/mp4': 'mp4',
  'audio/mp4': 'mp4',
  'audio/mp3': 'mp3',
  'audio/mpeg3': 'mp3',
  'audio/x-mpeg-3': 'mp3',
  'audio/xmpeg': 'mp3',
  'audio/m4a': 'm4a',
  'audio/x-m4a': 'm4a',
  'video/ogg': 'ogg',
  'audio/ogg': 'ogg',
  'application/ogg': 'ogg',
  'application/vnd.americandynamics.acc': 'acc',
  'audio/x-flac': 'flac',
  'audio/flac': 'flac',
}

export const mimeTypesByCategory = {
  ARCHIVE: {
    'application/zip': 'zip',
    'application/x-rar-compressed': 'rar',
    'application/x-tar': 'tar',
    'application/x-apple-diskimage': 'dmg',
    'application/java-archive': 'jar',
    'application/vnd.businessobjects': 'obj',
    'model/vnd.collada+xml': 'dae',
    'application/vnd.koan': 'skp',
  },
  DOCUMENT: {
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
      'doc',
    'application/doc': 'doc',
    'application/ms-doc': 'doc',
    'application/msword': 'doc',
    'text/richtext': 'rtf',
    'text/rtf': 'rtf',
    'application/rtf': 'rtf',
    'application/x-texinfo': 'txt',
    'text/plain': 'txt',
    'application/vnd.oasis.opendocument.text': 'odt',
    'application/x-tex': 'tex',
    'application/octet-stream': 'msg',
    'application/visio': 'vsd',
    'application/visio.drawing': 'vsd',
  },
  PDF: {
    'application/pdf': 'pdf',
  },
  SPREADSHEET: {
    'application/xml': 'xml',
    'text/csv': 'csv',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xls',
    'application/excel': 'xls',
    'application/vnd.ms-excel': 'xls',
    'application/x-excel': 'xls',
    'application/x-msexcel': 'xls',
  },
  IMAGE: {
    'image/svg+xml': 'svg',
    'image/jpeg': 'jpg',
    'image/x-citrix-jpeg': 'jpg',
    'image/pjpeg': 'jpg',
    'image/gif': 'gif',
    'image/x-png': 'png',
    'image/x-citrix-png': 'png',
    'image/png': 'png',
    'image/tiff': 'tif',
    'image/vnd.adobe.photoshop': 'psd',
    'application/x-photoshop': 'psd',
    'application/photoshop': 'psd',
    'application/psd': 'psd',
    'image/psd': 'psd',
    'image/x-adobe-dng': 'raw',
    'image/x-dcraw': 'raw',
  },
  VIDEO: {
    'video/jpeg': 'jpg',
    'video/webm': 'webm',
    'video/x-matroska': 'mkv',
    'video/x-msvideo': 'avi',
    'video/quicktime': 'mov',
    'video/x-m4v': 'm4v',
    'video/mpeg': 'mpeg',
    'application/mp4': 'mp4',
    'video/mp4': 'mp4',
    'video/ogg': 'ogg',
  },
  AUDIO: {
    'audio/webm': 'webm',
    'audio/mp4': 'mp4',
    'audio/mp3': 'mp3',
    'audio/mpeg3': 'mp3',
    'audio/x-mpeg-3': 'mp3',
    'audio/xmpeg': 'mp3',
    'audio/m4a': 'm4a',
    'audio/x-m4a': 'm4a',
    'audio/ogg': 'ogg',
    'application/ogg': 'ogg',
    'application/vnd.americandynamics.acc': 'acc',
    'audio/x-flac': 'flac',
    'audio/flac': 'flac',
  },
}

export const ImageExtensions = ['.jpeg', '.jpg', '.gif', '.png']
