//@common
import useErrorHandler from 'modules/common/hooks/useErrorHandler'
import { GET_INVITES_COUNT } from 'graphql/queries/invite.query'
import { SnackbarTypes } from 'modules/common/components/_UI_DUMB/Snackbars/Snackbar'
import { ATTACH_USER, UNATTACH_USER } from 'graphql/mutations/item.mutation'
import { SnackBarProviderProps } from 'modules/common/context/SnackbarProvider'

// @graphql
import { useMutation } from '@apollo/client'
import {
  AttachUserMutation,
  AttachUserMutationVariables,
  ContactStatus,
  DeleteContactMutation,
  DeleteContactMutationVariables,
  GetDealInfoQuery,
  GetDealTeamMembersQuery,
  InviteAcceptMutation,
  InviteAcceptMutationVariables,
  InviteCancelMutation,
  InviteCancelMutationVariables,
  InviteDeclineMutation,
  InviteDeclineMutationVariables,
  InviteToContactsMutation,
  InviteToContactsMutationVariables,
  InviteToDealMutation,
  InviteToDealMutationVariables,
  InviteToTeamMutation,
  InviteToTeamMutationVariables,
  InviteType,
  InviteUserDealInput,
  Item,
  RemoveFromTeamMutation,
  RemoveFromTeamMutationVariables,
  Scalars,
  SimplifiedUser,
  TeamRole,
  TeamUpdateTeamLeadMutation,
  TeamUpdateTeamLeadMutationVariables,
  UnattachUserMutation,
  UnattachUserMutationVariables,
  User,
} from 'graphql/graphqlTypes'
import {
  REMOVE_FROM_TEAM,
  UPDATE_TEAM_LEAD,
} from 'graphql/mutations/team.mutation'
import {
  DELETE_CONTACT,
  INVITE_ACCEPT,
  INVITE_CANCEL,
  INVITE_DECLINE,
  INVITE_TO_CONTACTS,
  INVITE_TO_DEAL,
  INVITE_TO_TEAM,
} from 'graphql/mutations/invite.mutation'
import { GET_USER_CONTACTS } from 'graphql/queries/user.query'
import {
  ContactItemCTA,
  ContactItemVariant,
  MenuItem,
} from 'modules/common/components/_UI_SMART/ContactItem/type'
import strings from 'constants/strings'
import { Button } from '@material-ui/core'
import React, { useContext } from 'react'
import DealModel from 'modules/deal/models/deal'
import { SnackbarContext } from 'modules/common/context/snackbar'
import { RouteNames } from 'constants/routeNames'
// @components
// @common
import { useHistory } from 'react-router-dom'

const DEFAULT_MESSAGE: SnackBarProviderProps = {
  message: '',
  icon: null,
  type: SnackbarTypes.SUCCESS,
  show: false,
  anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
}

const useUserActions = (callback: () => void, myTeamId?: string) => {
  const snackbar = useContext(SnackbarContext)
  const history = useHistory()

  /*******************************************************
   * MUTATIONS
   *******************************************************/

  /**
   * ADD/REMOVE FROM INVITE
   */
  const [inviteAccept, { error: inviteAcceptMutationError }] = useMutation<
    InviteAcceptMutation,
    InviteAcceptMutationVariables
  >(INVITE_ACCEPT)
  useErrorHandler(inviteAcceptMutationError)

  const [inviteCancel, { error: inviteCancelMutationError }] = useMutation<
    InviteCancelMutation,
    InviteCancelMutationVariables
  >(INVITE_CANCEL)
  useErrorHandler(inviteCancelMutationError)

  const [inviteDecline, { error: inviteDeclineMutationError }] = useMutation<
    InviteDeclineMutation,
    InviteDeclineMutationVariables
  >(INVITE_DECLINE)
  useErrorHandler(inviteDeclineMutationError)

  /**
   * ADD/REMOVE FROM TEAM
   */
  const [userRemoveFromTeam, { error: removeUserMutationError }] = useMutation<
    RemoveFromTeamMutation,
    RemoveFromTeamMutationVariables
  >(REMOVE_FROM_TEAM)
  useErrorHandler(removeUserMutationError)

  const [teamInviteSend, { error: addToTeamMutationError }] = useMutation<
    InviteToTeamMutation,
    InviteToTeamMutationVariables
  >(INVITE_TO_TEAM)
  useErrorHandler(addToTeamMutationError)

  /**
   * ADD/REMOVE FROM CONTACTS
   */
  const [addToContacts, { error: addToContactsMutationError }] = useMutation<
    InviteToContactsMutation,
    InviteToContactsMutationVariables
  >(INVITE_TO_CONTACTS, {
    refetchQueries: [
      {
        query: GET_USER_CONTACTS,
        variables: { contactStatus: ContactStatus.INVITED },
      },
    ],
  })
  useErrorHandler(addToContactsMutationError)

  const [removeContact, { error: removeContactMutationError }] = useMutation<
    DeleteContactMutation,
    DeleteContactMutationVariables
  >(DELETE_CONTACT)
  useErrorHandler(removeContactMutationError)

  /**
   * DEAL ACTIONS
   */
  const [updateTeamLead, { error: updateTeamLeadMutationErrors }] = useMutation<
    TeamUpdateTeamLeadMutation,
    TeamUpdateTeamLeadMutationVariables
  >(UPDATE_TEAM_LEAD)

  const [inviteToDealMutation, { error: inviteToDealMutationMutationErrors }] =
    useMutation<InviteToDealMutation, InviteToDealMutationVariables>(
      INVITE_TO_DEAL,
      { refetchQueries: [GET_INVITES_COUNT] },
    )

  /***
   * THIRD PARTY ITEM ATTACHMENTS
   */
  const [unattachUsers, { error: unattachUsersMutationErrors }] = useMutation<
    UnattachUserMutation,
    UnattachUserMutationVariables
  >(UNATTACH_USER)
  useErrorHandler(unattachUsersMutationErrors)

  const [attachUsers, { error: attachUsersMutationErrors }] = useMutation<
    AttachUserMutation,
    AttachUserMutationVariables
  >(ATTACH_USER)
  useErrorHandler(attachUsersMutationErrors)

  /*******************************************************
   * FUNCTIONS: HANDLERS - INVITES GENERIC
   *******************************************************/
  const inviteAcceptHandler = async (
    inviteId: Scalars['ID'],
  ): Promise<void> => {
    void (await inviteAccept({
      variables: { id: inviteId },
    }))

    if (!inviteAcceptMutationError) {
      callback && (await callback())
      snackbar.setMessage({
        type: SnackbarTypes.SUCCESS,
        message: 'Invite accepted!',
        show: true,
        anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
      })
    }
  }

  /**
   * cancel invite if they have a pending invite
   * @param inviteId
   */
  const inviteCancelHandler = async (
    inviteId: Scalars['ID'],
  ): Promise<void> => {
    void (await inviteCancel({
      variables: { id: inviteId },
    }))

    if (!inviteCancelMutationError) {
      callback && callback()
      snackbar.setMessage({
        type: SnackbarTypes.SUCCESS,
        message: 'Invite cancelled',
        show: true,
        anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
      })
    }
  }

  /**
   *  decline invite if current user is originator
   * @param inviteId
   */
  const inviteDeclineHandler = async (
    inviteId: Scalars['ID'],
  ): Promise<void> => {
    void (await inviteDecline({
      variables: { id: inviteId },
    }))

    if (!inviteDeclineMutationError) {
      callback && (await callback())
      snackbar.setMessage({
        type: SnackbarTypes.SUCCESS,
        message: 'Invite declined',
        show: true,
        anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
      })
    }
  }

  /*******************************************************
   * FUNCTIONS: HANDLERS - DEAL INVITES
   *******************************************************/
  /**
   *
   * @param invite
   */
  const dealInviteSendHandler = async (
    invite: InviteUserDealInput,
  ): Promise<void> => {
    await inviteToDealMutation({
      variables: {
        invite,
      },
    })
    if (!inviteToDealMutationMutationErrors) {
      callback && (await callback())
      snackbar.setMessage({
        type: SnackbarTypes.SUCCESS,
        message: 'Invite sent',
        show: true,
        anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
      })
    }
  }

  /**
   *
   * @param userId
   * @param isThirdParty
   * @param email
   */
  const teamInviteSendHandler = async (
    userId: Scalars['ID'] | null,
    isThirdParty = false,
    email?: string,
  ): Promise<void> => {
    if (!myTeamId) return
    const variables: InviteToTeamMutationVariables = {
      input: {
        teamId: myTeamId,
        userId,
        email,
        teamMemberType: isThirdParty ? TeamRole.THIRD_PARTY : undefined,
      },
    }
    await teamInviteSend({ variables })

    if (!addToTeamMutationError) {
      callback && callback()
      snackbar.setMessage({
        ...DEFAULT_MESSAGE,
        message: 'Invite sent',
        type: SnackbarTypes.SUCCESS,
        show: true,
      })
    }
  }

  /**
   *
   * @param {Scalars["ID"]} userId
   * @returns {Promise<void>}
   */
  const userRemoveFromTeamHandler = async (
    userId: Scalars['ID'],
  ): Promise<void> => {
    if (!myTeamId) return
    void (await userRemoveFromTeam({
      variables: { teamId: String(myTeamId), userId },
    }))

    if (!removeUserMutationError) {
      callback && callback()
      snackbar.setMessage({
        ...DEFAULT_MESSAGE,
        message: 'User removed from team',
        type: SnackbarTypes.SUCCESS,
        show: true,
      })
    }
  }

  /**
   *
   * @param {SimplifiedUser} user
   * @param {Item} item
   * @returns {Promise<void>}
   */
  const addThirdPartyHandler = async (
    user: SimplifiedUser,
    item: Item,
  ): Promise<void> => {
    await attachUsers({
      variables: {
        itemId: item.id,
        users: [user.id],
      },
    })

    if (!attachUsersMutationErrors) {
      callback && callback()
      snackbar.setMessage({
        ...DEFAULT_MESSAGE,
        message: 'User added to item',
        type: SnackbarTypes.SUCCESS,
        show: true,
      })
    }
  }

  /**
   *
   * @param user
   * @param item
   */
  const removeThirdPartyHandler = async (
    user: SimplifiedUser,
    item: Item,
  ): Promise<void> => {
    void (await unattachUsers({
      variables: {
        itemId: item.id,
        users: [user.id],
      },
    }))
    if (!unattachUsersMutationErrors) {
      callback && (await callback())
      snackbar.setMessage({
        ...DEFAULT_MESSAGE,
        message: 'User removed from item',
        type: SnackbarTypes.SUCCESS,
        show: true,
      })
    }
  }

  /**
   *
   * remove user if they are a team member
   * @param userId
   */
  const teamLeadMigrateHandler = async (
    userId: Scalars['ID'],
  ): Promise<void> => {
    if (!myTeamId) return
    void (await updateTeamLead({
      variables: { teamId: myTeamId, userId },
    }))

    if (!updateTeamLeadMutationErrors) {
      callback && callback()
      snackbar.setMessage({
        ...DEFAULT_MESSAGE,
        message: 'Team lead transferred!',
        type: SnackbarTypes.SUCCESS,
        show: true,
      })
    }
  }

  /*******************************************************
   * FUNCTIONS: HANDLERS - CONTACTS
   *******************************************************/
  /**
   *
   * @param userId
   * @param email
   */
  const userAddToContactsHandler = async (
    userId: Scalars['ID'] | null,
    email?: string,
  ): Promise<void> => {
    const variables: InviteToContactsMutationVariables = {
      input: {
        email,
        userId,
      },
    }
    void (await addToContacts({
      variables,
    }))
    if (!addToContactsMutationError) {
      callback && callback()
      snackbar.setMessage({
        ...DEFAULT_MESSAGE,
        message: 'User contact invited',
        type: SnackbarTypes.SUCCESS,
        show: true,
      })
    }
  }
  /**
   *
   * @param userId
   */
  const userRemoveContactHandler = async (
    userId: Scalars['ID'],
  ): Promise<void> => {
    void (await removeContact({
      variables: { id: userId },
    }))

    if (!removeContactMutationError) {
      callback && callback()
      snackbar.setMessage({
        ...DEFAULT_MESSAGE,
        message: 'User removed from contacts',
        type: SnackbarTypes.SUCCESS,
        show: true,
      })
    }
  }

  /*******************************************************
   * FUNCTIONS: BUTTON GETTERS
   *******************************************************/

  /*******************************************************
   * CTA/BUTTON
   *******************************************************/

  /**
   *
   * @param dealId
   * @param {SimplifiedUser} user
   * @param email
   * @returns {ContactItemCTA | null}
   */
  const getInviteToDealCTA = (
    dealId: string,
    user: SimplifiedUser,
    email?: string,
  ): ContactItemCTA | null => {
    if (!user?.userDeal && user?.id) {
      return {
        variant: ContactItemVariant.ACTION,
        handler: () =>
          dealInviteSendHandler({ dealId, userId: user.id, email }),
        tooltipText: 'invite this user to make a deal',
        text: 'SEND DEAL',
      }
    }
    return null
  }

  /**
   *
   * @param user
   * @returns {ContactItemCTA | null}
   */
  const getAddTeamMemberCTA = (user): ContactItemCTA | null => {
    /**
     * if user contact doesn't have a 'user deal' relationship already then they have no association with the deal
     * and we can invite them
     */
    if (!user?.userDeal && user.id) {
      return {
        menuTrigger: (
          <Button variant="contained" color="primary">
            ADD
          </Button>
        ),
        variant: ContactItemVariant.MENU,
        items: [
          {
            handler: () => teamInviteSendHandler(user.id, false),
            text: 'As team member',
          },
          {
            handler: () => teamInviteSendHandler(user.id, true),
            text: 'As third party',
          },
        ],
      }
    }
    return null
  }

  /**
   *
   * @param {SimplifiedUser} user
   * @returns {ContactItemCTA | null}
   */
  const getAddToContactsCTA = (user: SimplifiedUser): ContactItemCTA | null => {
    //Invites by email has negative id
    if (user?.id && !user.contact && Number(user.id) > 0) {
      return {
        variant: ContactItemVariant.ACTION,
        handler: () => userAddToContactsHandler(user?.id),
        tooltipText: strings.ADD_CONTACT,
        text: 'Add',
      }
    }

    return null
  }

  /**
   *
   * @param {SimplifiedUser} user
   * @param callback
   * @returns {ContactItemCTA | null}
   */
  const getCancelDealInviteCTA = (
    user: SimplifiedUser,
    callback,
  ): ContactItemCTA | null => {
    const acceptedDealStatus = user?.userDeal?.status
    const inviteId = user?.userDeal?.inviteId
    if (
      user?.userDeal?.inviteType === InviteType.DEAL &&
      acceptedDealStatus === ContactStatus.PENDING &&
      inviteId
    ) {
      return {
        variant: ContactItemVariant.REMOVE,
        handler: async () => {
          await inviteCancelHandler(inviteId)
          callback && callback()
        },
        tooltipText: strings.INVITE_CANCEL,
        text: '',
      }
    }
    return null
  }

  /**
   *
   * @param {SimplifiedUser} user
   * @param item
   * @param canUserEditItem
   * @returns {MenuItem | null}
   */
  const getAddThirdPartyButton = (
    user: SimplifiedUser,
    canUserEditItem: boolean,
    item: Item,
  ): ContactItemCTA | null => {
    const isAdded = item?.users?.some((itemUser) => itemUser.id === user.id)

    if (!isAdded && canUserEditItem) {
      return {
        variant: ContactItemVariant.ACTION,
        handler: () => addThirdPartyHandler(user, item),
        tooltipText: 'Add',
        text: strings.ADD,
      }
    }
    return null
  }

  /**
   *
   * @param {SimplifiedUser} user
   * @param {Item} item
   * @param canUserEditItem
   * @returns {ContactItemCTA | null}
   */
  const getRemoveThirdPartyButton = (
    user: SimplifiedUser,
    canUserEditItem,
    item: Item,
  ): ContactItemCTA | null => {
    const isAdded = item.users.some((itemUser) => itemUser.id === user.id)

    if (isAdded && canUserEditItem) {
      return {
        variant: ContactItemVariant.REMOVE,
        handler: async () => {
          await removeThirdPartyHandler(user, item)
        },
        text: strings.REMOVE_FROM_ITEM,
      }
    }
    return null
  }

  /*******************************************************
   * MENU ITEMS
   *******************************************************/
  /**
   *
   * @param user
   * @param currentUser
   * @param deal
   * @param {ContactStatus | null} contactStatus
   * @param callback
   * @returns {MenuItem | null}
   */
  const getRemoveSelfFromTeamMenuItem = (
    user: SimplifiedUser,
    currentUser: User,
    deal?: GetDealInfoQuery['deal'],
    contactStatus?: ContactStatus | null,
  ): MenuItem | null => {
    if (contactStatus !== ContactStatus.ACCEPTED) return null
    if (!DealModel.canRemoveSelfFromTeam(deal)) return null
    if (user.id !== currentUser.id) return null

    return {
      handler: async () => {
        await userRemoveFromTeamHandler(user.id)
        history.push(`/${RouteNames.HOME}`)
      },
      text: strings.LEAVE_DEAL,
    }
  }

  /**
   *
   * @param user
   * @param currentUser
   * @param deal
   * @param {ContactStatus | null} contactStatus
   * @returns {MenuItem | null}
   */
  const getRemoveUserFromTeamMenuItem = (
    user,
    currentUser: User,
    deal?: GetDealInfoQuery['deal'],
    contactStatus?: ContactStatus | null,
  ): MenuItem | null => {
    if (contactStatus !== ContactStatus.ACCEPTED) return null
    if (!DealModel.canRemoveUserFromTeam(user, currentUser, deal)) return null
    if (user.id === currentUser.id) return null
    return {
      handler: () => userRemoveFromTeamHandler(user.id),
      text: strings.TEAM_REMOVE_USER,
    }
  }

  /**
   *
   * @param {SimplifiedUser} user
   * @returns {MenuItem | null}
   */
  const getAddToContactsMenuItem = (user: SimplifiedUser): MenuItem | null => {
    const inviteId = user?.contact?.inviteId
    if (inviteId && user?.contact?.status === ContactStatus.INVITED) {
      return {
        handler: () => inviteAcceptHandler(inviteId),
        text: strings.INVITE_ACCEPT,
      }
    }

    return null
  }

  /**
   *
   * @param {SimplifiedUser} user
   * @returns {MenuItem | null}
   */
  const getInviteCancelMenuItem = (user: SimplifiedUser): MenuItem | null => {
    const inviteId = user?.contact?.inviteId
    if (inviteId && user.contact?.status === ContactStatus.PENDING) {
      return {
        handler: () => inviteCancelHandler(inviteId),
        text: 'Cancel invite',
      }
    }
    return null
  }
  /**
   *
   * @param {SimplifiedUser} user
   * @param {User} currentUser
   * @param deal
   * @param {ContactStatus | null} contactStatus
   * @returns {MenuItem | null}
   */
  const getInviteCancelTeamMemberMenuItem = (
    user: SimplifiedUser,
    currentUser: User,
    deal?: GetDealInfoQuery['deal'],
    contactStatus?: ContactStatus | null,
  ): MenuItem | null => {
    if (contactStatus !== ContactStatus.PENDING) return null
    if (!DealModel.canCancelTeamInvite(deal)) return null

    // if ContactStatus.PENDING, inviteId must exist
    const inviteId = user.userDeal?.inviteId
    if (inviteId && currentUser?.id !== user.id) {
      return {
        handler: () => inviteCancelHandler(inviteId),
        text: 'Cancel invite',
      }
    }
    return null
  }
  /***
   *
   * @param user
   * @param currentUser
   * @param deal
   * @param {ContactStatus | null} contactStatus
   * @returns {MenuItem | null}
   */
  const getMigrateTeamLeadMenuItem = (
    user: SimplifiedUser,
    currentUser: User,
    deal?: GetDealInfoQuery['deal'],
    contactStatus?: ContactStatus | null,
  ): MenuItem | null => {
    if (contactStatus !== ContactStatus.ACCEPTED) return null
    if (!DealModel.canMigrateTeamLead(currentUser, user, deal)) return null
    if (user.id) {
      return {
        handler: () => teamLeadMigrateHandler(user.id),
        text: 'Make team lead',
      }
    }
    return null
  }

  /**
   *
   * @param {SimplifiedUser} user
   * @returns {MenuItem | null}
   */
  const getDeclineActionMenuItem = (user: SimplifiedUser): MenuItem | null => {
    const inviteId = user?.contact?.inviteId
    const status = user?.contact?.status

    if (
      inviteId &&
      status === ContactStatus.INVITED &&
      user.id &&
      user?.contact
    )
      return {
        handler: () => inviteDeclineHandler(inviteId),
        text: 'Decline invite',
      }
    return null
  }

  /**
   *
   * @param {SimplifiedUser} user
   * @returns {MenuItem | null}
   */
  const getRemoveContactMenuItem = (user: SimplifiedUser): MenuItem | null => {
    if (user.contact?.status === ContactStatus.ACCEPTED) {
      return {
        handler: () => userRemoveContactHandler(user.id),
        text: 'Remove contact',
      }
    }
    return null
  }

  /*******************************************************
   * FUNCTIONS: HELPERS
   *******************************************************/
  /**
   *
   * @returns {MenuItem[]}
   * @param handlers
   */
  const addToMenuItems = (handlers: (() => MenuItem | null)[]): MenuItem[] => {
    const menuItems: MenuItem[] = []
    for (const handler of handlers) {
      const item = handler()
      if (item) {
        menuItems.push(item)
      }
    }
    return menuItems
  }

  return {
    handlers: {
      userRemoveFromTeamHandler,
      teamInviteSendHandler,
      dealInviteSendHandler,
      inviteCancelHandler,
      teamLeadMigrateHandler,
      userAddToContactsHandler,
      userRemoveContactHandler,
      inviteDeclineHandler,
      inviteAcceptHandler,
      addThirdPartyHandler,
      removeThirdPartyHandler,
    },
    buttonGetters: {
      getRemoveThirdPartyButton,
      getAddThirdPartyButton,
      getCancelDealInviteCTA,
      getInviteToDealCTA,
      getAddTeamMemberCTA,
      getAddToContactsCTA,
      getInviteCancelTeamMemberMenuItem,
      getRemoveSelfFromTeamMenuItem,
      getRemoveUserFromTeamMenuItem,
      getMigrateTeamLeadMenuItem,
      getDeclineActionMenuItem,
      getInviteCancelMenuItem,
      getRemoveContactMenuItem,
      getAddToContactsMenuItem,
    },
    addToMenuItems,
  }
}

export default useUserActions
