// language=GraphQL
import { gql } from '@apollo/client'

export const ITEM_UPDATE_SUBSCRIPTION = gql`
  subscription onItemUpdated {
    itemUpdated {
      item {
        id
        name
        stage
        status
        actions
        starred
        permissions
      }
      updatedBy {
        id
        firstName
        lastName
      }
      userMessage {
        id
        type
        msg
      }
    }
  }
`

export const ITEM_CREATE_SUBSCRIPTION = gql`
  subscription onItemCreate {
    itemCreated {
      id
      name
    }
  }
`

export const ITEM_DELETE_SUBSCRIPTION = gql`
  subscription onItemDelete {
    itemDeleted {
      item {
        id
        deal {
          id
        }
      }
    }
  }
`

export const COMMENT_DELETE_SUBSCRIPTION = gql`
  subscription onCommentDelete {
    commentDeleted {
      comment {
        id
        item {
          id
        }
      }
    }
  }
`
