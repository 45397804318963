import axios, { AxiosResponse } from 'axios'
import { mimeTypes } from 'constants/mime'
import { AudioFile } from 'modules/document/types/file'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const ALERT = require('assets/audio/material_product_sounds/wav/primary_system_sounds/navigation_forward-selection.wav')

/**
 *
 */
class FileModel {
  /**
   *
   * @param files
   * @param accessToken
   * @param idToken
   * @param progressHandler
   */
  public async uploadFile(
    files: File[],
    accessToken: string,
    idToken: string,
    progressHandler?,
  ): Promise<AxiosResponse> {
    const formData = new FormData()
    if (!process.env.REACT_APP_API_BASE) {
      throw new TypeError(`REACT_APP_API_BASE env is not specified`)
    }

    const config = {
      progress: (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total,
        )
        progressHandler(percentCompleted)
      },
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total,
        )
        progressHandler && progressHandler(percentCompleted)
      },
    }

    files.forEach((file: File) => {
      let newType = file.type

      if (!('type' in file) || ('type' in file && file.type === '')) {
        // force add mime type
        const ext = file.name.split('.').pop()
        newType = Object.keys(mimeTypes).filter((key) => {
          return mimeTypes[key] === ext
        })[0]
        // if mimetype not on list, throw error
        if (!newType) {
          console.log(`error adding file: ${file.name}`)
          return
        }
      }
      const newFile = new File([file], file.name, { type: newType })
      formData.append('files', newFile)
    })

    return await axios
      .post(`${process.env.REACT_APP_API_BASE}/upload`, formData, {
        ...config,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          authorization: accessToken ? `Bearer ${accessToken}` : '',
          auth0_id: idToken ? `${idToken}` : '',
        },
      })
      .catch(function (error) {
        if (error.response) {
          // Request made and server responded
          console.log(error.response.data)
          console.log(error.response.status)
          console.log(error.response.headers)
        } else if (error.request) {
          // The request was made but no response was received
          console.log(error.request)
        } else {
          // Something happened in setting up the request that triggered an Error
          console.log('Error', error.message)
        }
        return error.response
      })
  }

  /**
   *
   * @param fileLink
   */
  public createDownloadLink = (fileLink?: string, versionId?: string) => {
    if (!fileLink) return

    const link = document.createElement('a')
    link.href = this.getDownloadLink(fileLink, versionId)

    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  }

  /**
   *
   * @param fileLink
   * @param versionId
   */
  public getDownloadLink = (fileLink?: string, versionId?: string): string => {
    if (!fileLink) return ''

    const queryString = versionId ? `?versionId=${versionId}` : ''
    return `${fileLink}${queryString}`
  }

  /**
   *
   * @param key
   * @return {any}
   */
  public getAudioFile(key: AudioFile) {
    switch (key) {
      case AudioFile.ALERT:
        return ALERT
    }
  }
}

export default new FileModel()
