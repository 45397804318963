import { RouteType, RouteNames } from 'constants/routeNames'
import DealItems from 'modules/deal/pages/DealItems'
import ItemSingle from './pages/ItemSingle'
import ItemsList from './pages/ItemsList'

const routes: RouteType[] = [
  {
    component: ItemSingle,
    path: `/${RouteNames.DEALS}/${RouteNames.DETAILS}/:${RouteNames.ID}/${RouteNames.ITEMS}/${RouteNames.DETAILS}/:${RouteNames.ITEM_ID}`,
    type: 'PRIVATE',
    exact: true,
    layout: 'MAIN',
  },
  {
    component: DealItems,
    path: `/${RouteNames.DEALS}/${RouteNames.DETAILS}/:${RouteNames.ID}/${RouteNames.ITEMS}`,
    type: 'PRIVATE',
    layout: 'MAIN',
  },
  {
    component: ItemsList,
    path: `/${RouteNames.ITEMS}`,
    type: 'PRIVATE',
    exact: true,
    layout: 'MAIN',
  },
]

const Routes = {
  routes,
}
export default Routes
