import {
  TeamRole,
  SimplifiedUserNodeFragment,
  GetDealInfoQuery,
} from 'graphql/graphqlTypes'

export default class TeamModel {
  /**
   *
   * @returns {{myTeamid: any; myTeamTeamMembers: any; theirTeamMembers: any; theirTeamId: number | null | undefined | (() => number)}}
   * @param deal
   */
  public static calculateTeamMembership(
    deal?: GetDealInfoQuery['deal'] | null,
  ): {
    myTeamId: string
    myTeamLeadUserId?: string
    theirTeamId?: string
    myTeamTeamMembers?: SimplifiedUserNodeFragment[] | null
    theirTeamMembers?: SimplifiedUserNodeFragment[] | null
    theirTeamLeadUserId?: string
  } | null {
    if (!deal) {
      return null
    }

    /**
     * calculate which team user belongs to
     */

    const myTeamId = String(deal.myTeam)
    const theirTeamId = String(deal.theirTeam)

    const originatingTeam = deal.originatingTeam
    const recipientTeam = deal.recipientTeam
    const originatingTeamId = originatingTeam.id

    const myTeam =
      originatingTeamId === myTeamId ? originatingTeam : recipientTeam
    const theirTeam =
      originatingTeamId === theirTeamId ? originatingTeam : recipientTeam

    const myTeamTeamMembers = myTeam?.teamMembership
    const theirTeamMembers = theirTeam?.teamMembership

    const myTeamLeadUser = myTeamTeamMembers?.find((el) => {
      return el.userDeal?.teamRole === TeamRole.LEAD
    })

    const theirTeamLeadUser = theirTeamMembers?.find((el) => {
      return el.userDeal?.teamRole === TeamRole.LEAD
    })

    return {
      myTeamId,
      theirTeamId,
      myTeamTeamMembers,
      theirTeamMembers,
      myTeamLeadUserId: myTeamLeadUser?.id,
      theirTeamLeadUserId: theirTeamLeadUser?.id,
    }
  }
}
