import React from 'react'
import * as SC from './styledComponens'

type PropsType = {
  icon: JSX.Element
  testId?: string
}

const SmallTableIcon: React.FunctionComponent<PropsType> = ({
  icon,
  testId,
}) => <SC.StyledIcon data-testid={testId}>{icon}</SC.StyledIcon>

export default SmallTableIcon
