// @react
import { useState, useEffect } from 'react'
// @design
import { DialogActions, DialogContent, Grid } from '@material-ui/core'
import * as SC from '../styledComponents/createDealSendDeal'
import * as ButtonsSC from 'modules/common/components/_UI_DUMB/Button/styledComponents'
// @common
import strings from 'constants/strings'
// @graphql
import { useQuery } from '@apollo/client'
import DealSendDialogContent from 'modules/deal/components/Dialogs/DialogContent/DealSendDialogContent'
import {
  GET_DEAL_INFO,
  GET_DEAL_TEAM_MEMBERS,
} from 'graphql/queries/deal.query'
import TeamModel from 'modules/deal/models/team'
import { useForm } from 'react-hook-form'
import {
  GetDealInfoQuery,
  GetDealInfoQueryVariables,
  GetDealTeamMembersQuery,
  GetDealTeamMembersQueryVariables,
  Scalars,
} from 'graphql/graphqlTypes'
import useErrorHandler from 'modules/common/hooks/useErrorHandler'

/**
 * define props
 */
interface SelfProps {
  previousStep: (unknown) => void
  saveAndClose: (...args) => void
  continueSolo: () => void
  create: (...args) => void
  dealId: Scalars['ID']
  changeStepTitle: (title: string) => void
}

type PropsType = SelfProps

/**
 *
 * @param props
 * @constructor
 */
const CreateDealSendDeal = ({
  previousStep,
  saveAndClose,
  continueSolo,
  dealId,
  changeStepTitle,
}: PropsType) => {
  /*******************************************************
   * STATE
   *******************************************************/
  const [isDealSent, setIsDealSent] = useState<boolean>(false)

  /*******************************************************
   * HOOKS
   *******************************************************/
  useEffect(() => {
    changeStepTitle('Send Deal')
  }, [])

  const { handleSubmit } = useForm()

  /*******************************************************
   * GRAPHQL
   *******************************************************/
  /**
   * queries
   */
  const { data, error } = useQuery<GetDealInfoQuery, GetDealInfoQueryVariables>(
    GET_DEAL_INFO,
    {
      fetchPolicy: 'no-cache',
      variables: {
        id: dealId,
      },
    },
  )
  useErrorHandler(error)

  const teamMembership =
    data?.deal && TeamModel.calculateTeamMembership(data.deal)

  if (!teamMembership) {
    return <></>
  }
  const { myTeamId } = teamMembership

  /*******************************************************
   * RENDER COMPONENTS
   *******************************************************/

  const DialogActionsContent = () => {
    return (
      <SC.Wrap>
        <Grid container justifyContent="center" spacing={3}>
          <Grid item xs={12} sm={12}>
            <ButtonsSC.SubmitBtn
              variant="contained"
              onClick={continueSolo}
              type="button"
              color="primary"
              disabled={isDealSent}
            >
              {strings.CONTINUE_SOLO}
            </ButtonsSC.SubmitBtn>
          </Grid>
        </Grid>
        <Grid container justifyContent="center">
          <Grid item>
            <ButtonsSC.ActionBtn
              onClick={previousStep}
              size="small"
              color="secondary"
            >
              <span className="underline">{strings.PREVIOUS_STEP}</span>
            </ButtonsSC.ActionBtn>
          </Grid>
          <Grid item>
            <ButtonsSC.ActionBtn
              onClick={(data) => saveAndClose(data)}
              size="small"
              color="secondary"
            >
              <span className="underline">{strings.SAVE_AND_CLOSE}</span>
            </ButtonsSC.ActionBtn>
          </Grid>
        </Grid>
      </SC.Wrap>
    )
  }

  /*******************************************************
   * RENDER
   * is not wrapped in SwitchboardDialog because this is supplied by EditingChecker
   *******************************************************/
  return (
    <>
      <DialogContent>
        <SC.Subtitle variant="subtitle1">Send To</SC.Subtitle>
        <DealSendDialogContent
          dealId={dealId}
          myTeamId={myTeamId}
          setIsDealSent={setIsDealSent}
        />
      </DialogContent>
      <DialogActions>
        <form
          onSubmit={handleSubmit(() => {
            console.log('form is handling submit')
          })}
        >
          <DialogActionsContent />
        </form>
      </DialogActions>
    </>
  )
}

export default CreateDealSendDeal
