import styled from 'styled-components'
import colors from 'constants/colors'

export const StyledTag = styled.div`
  ${(props: { color: string }) =>
    `background: ${props.color}; color: ${colors.white}`};
  font-size: 14px;
  padding: 5px 11px;
  border-radius: 4px;
`

export const Wrapper = styled.div`
  display: flex;
`
