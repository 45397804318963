// @react
import React from 'react'
import * as SC from 'modules/common/components/_FORM_ELEMENTS/HookForm/form-inputs/FormQuill/styledComponents'

const MessagesTextareaToolbar: React.FunctionComponent = () => (
  <SC.Toolbar id="textAreaToolbar">
    <button className="ql-bold" />
    <button className="ql-italic" />
    <button className="ql-underline" />
    <button className="ql-list" value="bullet" />
    <button className="ql-list" value="ordered" />
  </SC.Toolbar>
)

export default MessagesTextareaToolbar
