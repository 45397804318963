import { gql } from '@apollo/client'
import { BASE_SIMPLIFIED_USER } from './user.fragment'

export const DEAL_FRAGMENT = gql`
  fragment DealNode on Deal {
    id
    createdDate
    modifiedDate
    name
    stage
    status
    type
    propertyType
    rentableAreaValue
    rentableAreaUnit
    yearOfConstruction
    tenancy
    address
    country
    city
    state
    zip
    description
    notifications
    industry
    numberOfProperties
    debt
    subsector
    hotelManagement
    noKeys
    meeting
    interest
    siteAreaValue
    siteAreaUnit
    occupancy
    wale
    clearHeight
    clearHeightUnit
    loadingType
    noDockHighDoors
    acreage
    parkingStalls
    landAreaValue
    landAreaUnit
    zoning
    entitlements
    noFloors
    productType
    totalPropertyRentArea
    areaUnit
    brand
    myRole
    fields {
      id
      value
      name
    }
  }
`

export const TEAM_DATA = gql`
  fragment TeamData on SimpleTeam {
    id
    teamMembership {
      ...BaseSimplifiedUserNode
      userDeal {
        status
        teamRole
        teamId
        teamType
        inviteId
        inviteType
      }
    }
  }
  ${BASE_SIMPLIFIED_USER}
`
