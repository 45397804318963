// @react
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useRouteMatch } from 'react-router'
//@design
import * as SC from 'modules/item/pages/ItemSingle/styledComponents/item'
import { Grid, useMediaQuery, useTheme } from '@material-ui/core'
import { MessagesContainer } from 'modules/common/styledComponents/Message'
//@common
import BROWSER_HISTORY from 'utils/history'
import DragAndDropArea from 'modules/common/components/_UI_DUMB/DragAndDropArea/DragAndDropArea'
import strings from 'constants/strings'
import { ITEM_EDIT_PERMISSIONS } from 'modules/item/config'
import Card from 'modules/common/components/_UI_DUMB/Card'
//@components
import EditItemPopup from 'modules/item/pages/ItemSingle/dialogs/EditItemPopup'
import NotFound from 'modules/common/pages/NotFound'
import { SnackbarTypes } from 'modules/common/components/_UI_DUMB/Snackbars/Snackbar'
// @graphql
import { DealContext } from 'modules/deal/contexts/deal'
import { ITEM_UPDATE } from 'graphql/mutations/item.mutation'
import EditingChecker from 'modules/common/components/_FORM_ELEMENTS/EditingChecker'
import { GET_ITEM } from 'graphql/queries/item.query'
import {
  DealStatus,
  DocumentsOrderByList,
  Document,
  GetDocumentsQuery,
  GetDocumentsQueryVariables,
  GetItemQuery,
  GetItemQueryVariables,
  ItemActions,
  ItemUpdateMutation,
  ItemUpdateMutationVariables,
  OnCommentCreateSubscription,
  OnCommentCreateSubscriptionVariables,
  OnItemUpdatedSubscription,
  OnItemUpdatedSubscriptionVariables,
  Order,
  TeamRole,
  UpdateItemInput,
  User,
  Item as ItemType,
  OnCommentUpdateSubscription,
  OnCommentUpdateSubscriptionVariables,
  OnCommentDeleteSubscriptionVariables,
  OnCommentDeleteSubscription,
  GetThirdpartyUsersQuery,
  GetThirdpartyUsersQueryVariables,
  UsersOrderByList,
} from 'graphql/graphqlTypes'
import { useMutation, useQuery, useSubscription } from '@apollo/client'
import { RouteNames } from 'constants/routeNames'
import { UserContext } from 'modules/user/context/user'
import { ITEM_UPDATE_SUBSCRIPTION } from 'graphql/subscriptions/item.subscription'
import {
  COMMENT_CREATE_SUBSCRIPTION,
  COMMENT_DELETE_SUBSCRIPTION,
  COMMENT_UPDATE_SUBSCRIPTION,
} from 'graphql/subscriptions/comment.subscription'
import { SnackbarContext } from 'modules/common/context/snackbar'
import Spacer from 'modules/common/components/_UI_DUMB/Spacer'
import ItemDetails from './components/ItemDetails'
import ThirdParty from './components/ThirdParty'
import ItemComments from './components/ItemComments'
import { GET_DOCUMENTS } from 'graphql/queries/document.query'
import { isOfType } from 'modules/common/types'
import useErrorHandler from '../../../common/hooks/useErrorHandler'
import ConditionalLoader from '../../../common/components/_UI_DUMB/Loaders/ConditionalLoader'
import ItemToolBar from './components/ItemToolBar'

type TParams = { id: string; itemId: string }

/**
 *
 * @constructor
 * @return {null | any}
 */
const Item = () => {
  /**
   * match params
   */
  const match = useRouteMatch<TParams>()
  const { itemId } = match.params

  const theme = useTheme()
  const isUpMd = useMediaQuery(theme.breakpoints.up('md'))

  /*******************************************************
   * CONTEXT
   *******************************************************/
  // @todo 01-22 fix this type
  const snackbar = useContext(SnackbarContext)
  const currentUser = useContext(UserContext) as { user: User }
  const queryResult = useContext(DealContext)
  const { dealId } = queryResult
  const dealStatus = queryResult.data?.deal.status
  const myRole = queryResult.data?.deal.myRole

  /*******************************************************
   * GRAPHQL
   *******************************************************/

  /**
   * queries
   */
  const { data, refetch, loading, subscribeToMore, error } = useQuery<
    GetItemQuery,
    GetItemQueryVariables
  >(GET_ITEM, {
    variables: {
      id: itemId,
    },
  })
  useErrorHandler(error)

  const {
    data: documentsData,
    refetch: refetchDocs,
    error: getDocumentsError,
    loading: documentsLoading,
  } = useQuery<GetDocumentsQuery, GetDocumentsQueryVariables>(GET_DOCUMENTS, {
    fetchPolicy: 'cache-first',
    // notifyOnNetworkStatusChange: true,
    variables: {
      orderBy: {
        order: Order.DESC,
        value: DocumentsOrderByList.NAME,
      },
      filters: {
        deal: [dealId],
      },
    },
    skip: !dealId,
  })
  useErrorHandler(getDocumentsError)

  /**
   * mutations
   */
  const [updateItemMutation, { error: updateItemMutationErrors }] = useMutation<
    ItemUpdateMutation,
    ItemUpdateMutationVariables
  >(ITEM_UPDATE)
  useErrorHandler(updateItemMutationErrors)

  /*******************************************************
   * HOOKS
   *******************************************************/

  /**
   * ITEM SUBS
   */
  useSubscription<
    OnItemUpdatedSubscription,
    OnItemUpdatedSubscriptionVariables
  >(ITEM_UPDATE_SUBSCRIPTION, {
    shouldResubscribe: true,
  })

  /**
   * COMMENT SUBS
   */
  useEffect(() => {
    return subscribeToMore<
      OnCommentCreateSubscription,
      OnCommentCreateSubscriptionVariables
    >({
      document: COMMENT_CREATE_SUBSCRIPTION,
      updateQuery: (prev, { subscriptionData }) => {
        if (
          itemId === subscriptionData?.data.commentCreated?.comment?.item.id
        ) {
          return {
            ...prev,
            item: {
              ...prev.item,
              comments: [
                ...(prev.item.comments || []),
                subscriptionData.data.commentCreated.comment,
              ],
            },
          }
        }
        return prev
      },
    })
  }, [subscribeToMore])

  useSubscription<
    OnCommentUpdateSubscription,
    OnCommentUpdateSubscriptionVariables
  >(COMMENT_UPDATE_SUBSCRIPTION, {
    shouldResubscribe: true,
  })

  useSubscription<
    OnCommentDeleteSubscription,
    OnCommentDeleteSubscriptionVariables
  >(COMMENT_DELETE_SUBSCRIPTION, {
    shouldResubscribe: true,
    onSubscriptionData: ({ client, subscriptionData }) => {
      const normalizedId = client.cache.identify({
        id: subscriptionData?.data?.commentDeleted.comment?.id,
        __typename: 'Comment',
      })
      client.cache.evict({ id: normalizedId })
      client.cache.gc()
    },
  })

  /*******************************************************
   * FUNCTIONS
   *******************************************************/

  /**
   *
   * @return {Promise<void>}
   * @param values
   */
  const updateItem = async (values: UpdateItemInput) => {
    await updateItemMutation({
      variables: {
        input: {
          ...values,
          id: itemId,
        },
      },
    })
    snackbar.setMessage({
      message: 'Item updated',
      type: SnackbarTypes.SUCCESS,
      show: true,
      anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
    })
  }

  const updateItemFiles = useCallback(
    async (documents) => {
      if (data?.item && documents) {
        const documentIds = documents.map((document) => {
          return document.id
        })

        await updateItemMutation({
          variables: {
            input: {
              id: itemId,
              documents: documentIds,
            },
          },
        })
        void (await refetch())
        void (await refetchDocs())
        snackbar.setMessage({
          message: 'Item documents updated!',
          type: SnackbarTypes.SUCCESS,
          show: true,
          anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
        })
      }
    },
    [data, updateItemMutation, itemId, refetch],
  )

  const itemActions = data?.item.actions

  /**
   *
   * @returns {boolean}
   */
  const canUserEditItem = (): boolean => {
    // @todo what is wrong with the item permissions?
    return (
      dealStatus !== DealStatus.DEAD &&
      (myRole === TeamRole.THIRD_PARTY
        ? data?.item.createdBy.id === currentUser.user.id
        : true)
    )
  }

  /**
   *
   */
  const onEdit = () =>
    BROWSER_HISTORY.push({
      pathname: `/${RouteNames.DEALS}/${RouteNames.DETAILS}/${String(dealId)}/${
        RouteNames.ITEMS
      }/${RouteNames.DETAILS}/${itemId}`,
      search: '?mode=edit',
    })

  /*******************************************************
   * RENDER COMPONENTS
   *******************************************************/

  /**
   *
   * @param item
   */
  const getThirdPartyBlock = (item: ItemType) => {
    return (
      <>
        <Spacer height={20} />
        {dealId && (
          <Card title="Third Parties" noPadding testId="item-third-parties">
            <ThirdParty
              isAllowedToEdit={canUserEditItem()}
              item={item}
              onUpdate={refetch}
            />
          </Card>
        )}
      </>
    )
  }

  /**
   *
   * @param item
   * @param isDesktop
   */
  const getItemDetails = (item: ItemType, isDesktop: boolean) => {
    // casting to Item here via typeGuards, but watch for missing 'notifications' prop
    if (isUpMd && isDesktop) {
      return <ItemDetails item={item} onEdit={onEdit} testId="item-details" />
    }

    if (!isUpMd && !isDesktop) {
      return (
        <>
          <ItemDetails item={item} onEdit={onEdit} testId="item-details" />
          <Spacer height={20} />
        </>
      )
    }

    return <></>
  }

  /**
   *
   * @param {Item} item
   * @returns {JSX.Element}
   */
  const getItemDescription = (item: ItemType) => {
    return (
      <Card
        title="Description"
        footerBtn={{
          onClick: onEdit,
          title: item.description ? 'Edit Description' : 'Add description',
          disabled:
            !item.actions?.some((action) => {
              if (!action) return false
              return [
                ItemActions.ITEM_EDIT_FIELDS_OWN,
                ItemActions.ITEM_EDIT_FIELDS_MYTEAM,
              ].includes(action)
            }) || item.deal.status === DealStatus.DEAD,
        }}
        noPadding
      >
        <SC.CardRow
          dangerouslySetInnerHTML={{
            __html: item.description,
          }}
          noBorder
        />
      </Card>
    )
  }

  /**
   *
   * @param {Item} item
   * @returns {JSX.Element}
   */
  const getFileArea = (item: ItemType) => {
    return (
      <DragAndDropArea
        testId="item-drag-and-drop"
        documents={
          // @todo 01-22 incorrect typing here  no casting to unknown
          item && (item.documents as unknown as Document[])
        }
        // @ts-ignore
        removeFileCallback={refetch}
        fileUpdateCallback={updateItemFiles}
        canUpload={itemActions?.includes(ItemActions.ITEM_ADD_ATTACHMENT)}
        isMultiple
        isButtonMode={!isUpMd}
      />
    )
  }

  const getEditingChecker = (item: ItemType) => {
    if (
      !itemActions?.some((action) => {
        if (!action) return false
        return ITEM_EDIT_PERMISSIONS.includes(action)
      })
    ) {
      return <></>
    }

    return (
      <EditingChecker title={strings.EDIT_ITEM} hideScroll>
        <EditItemPopup id={itemId} item={item} onSubmit={updateItem} />
      </EditingChecker>
    )
  }

  /*******************************************************
   * CONDITIONAL RENDER & CHECKS
   *******************************************************/

  /**
   *
   * @returns {JSX.Element}
   * @constructor
   */
  const content = useMemo(() => {
    if (!data) {
      return <></>
    }

    if (!isOfType<ItemType>(data.item, '__typename', 'Item')) {
      return <></>
    }

    return (
      <>
        <SC.ItemsDetailContainer>
          <SC.TitleGrid container justifyContent="space-between">
            <SC.TitleSection item sm={7} container>
              <SC.ItemTitle variant="h3">{data.item.name}</SC.ItemTitle>
            </SC.TitleSection>
            <ItemToolBar />
          </SC.TitleGrid>

          <Grid container spacing={isUpMd ? 5 : 0}>
            <Grid xs={12} md={8} item>
              {getItemDetails(data.item, false)}
              {getItemDescription(data.item)}
              <Spacer height={20} />
              {getFileArea(data.item)}
            </Grid>
            <Grid xs={12} md={4} item>
              {getItemDetails(data.item, true)}
              {getThirdPartyBlock(data.item)}
            </Grid>
          </Grid>
        </SC.ItemsDetailContainer>

        <ItemComments item={data.item} onUpdate={refetch} />

        {getEditingChecker(data.item)}
      </>
    )
  }, [data])

  /*******************************************************
   * RENDER
   *******************************************************/

  if (!!error) {
    return <NotFound />
  }

  if (!loading && !data) {
    return <NotFound />
  }
  console.log(!!data && !!documentsData && !!currentUser.user && !!dealId)

  return (
    <MessagesContainer>
      <ConditionalLoader
        condition={!!data && !!documentsData && !!currentUser.user && !!dealId}
      >
        {content}
      </ConditionalLoader>
    </MessagesContainer>
  )
}
// Item.whyDidYouRender = true
export default Item
