import { useContext, useEffect } from 'react'
import { SnackbarTypes } from 'modules/common/components/_UI_DUMB/Snackbars/Snackbar'
import strings from 'constants/strings'
import { SnackbarContext } from 'modules/common/context/snackbar'

const useErrorHandler = (error, showMessage = true) => {
  const snackbar = useContext(SnackbarContext)

  useEffect(() => {
    if (error && error.graphQLErrors) {
      if (showMessage) {
        snackbar.setMessage({
          type: SnackbarTypes.ERROR,
          message:
            error.graphQLErrors[0]?.extensions?.exception?.response?.msg ||
            strings.GENERIC_ERROR,
          show: true,
        })
      } else {
        // console.log(error)
      }
    }
  }, [error])
}

export default useErrorHandler
