// @react
import React, { ReactNode } from 'react'
import { useEffect, useState } from 'react'
// @graphql
import queryString from 'query-string'
// @common
import SwitchboardDialog from 'modules/common/components/_UI_DUMB/ModalDialogs/SwitchboardDialog'
import BROWSER_HISTORY from 'utils/history'
import { useLocation } from 'react-router'

/*******************************************************
 * TYPE
 *******************************************************/
type PropsType = {
  children?: ReactNode
  title: string
  hideScroll?: boolean
}

/**
 *
 * @param props
 * @constructor
 */
const EditingChecker = ({ title, hideScroll, children }: PropsType) => {
  /*******************************************************
   * STATE
   *******************************************************/
  const [isEditing, setEditing] = useState(false)

  /*******************************************************
   * HOOKS
   *******************************************************/
  const location = useLocation()
  useEffect(() => {
    const searchObj = queryString.parse(location.search)
    if (searchObj.mode === 'edit' && !isEditing) {
      setEditing(true)
    } else if (searchObj.mode !== 'edit' && isEditing) {
      setEditing(false)
    }
  }, [isEditing, location])

  /*******************************************************
   * FUNCTIONS
   *******************************************************/
  /**
   *
   */
  const handleVisibility = () => {
    BROWSER_HISTORY.push({
      pathname: BROWSER_HISTORY.location.pathname,
      search: isEditing ? '' : '?mode=edit',
    })
  }

  /*******************************************************
   * RENDER
   *******************************************************/
  return isEditing ? (
    <SwitchboardDialog
      title={title}
      open
      onClose={handleVisibility}
      hideScroll={hideScroll}
    >
      {children}
    </SwitchboardDialog>
  ) : null
}

export default EditingChecker
