// @react
import DocumentTitle from 'react-document-title'
import strings from 'constants/strings'
import parse from 'html-react-parser'
import * as SC from 'modules/common/styledComponents/NetworkError'
import * as ButtonsSC from 'modules/common/components/_UI_DUMB/Button/styledComponents'

const NetworkError = () => (
  <DocumentTitle title="Network Error | Switchboard">
    <SC.Wrap>
      <SC.Title>{parse(strings.ERROR_FETCHING)}</SC.Title>
      <div>
        <SC.StyledLink to={`/`}>
          <ButtonsSC.ButtonOrange variant="contained" color="primary">
            {strings.BACK_TO_HOME}
          </ButtonsSC.ButtonOrange>
        </SC.StyledLink>
      </div>
    </SC.Wrap>
  </DocumentTitle>
)

export default NetworkError
