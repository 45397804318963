// language=GraphQL
import { gql } from '@apollo/client'

const typeDefs = gql`
  directive @client on FIELD

  type UnreadCount {
    amount: Int
  }

  extend type Query {
    unreadCount: [UnreadCount]
    getUnreadCount(id: String): UnreadCount
  }

  extend type Mutation {
    setUnreadCount(amount: Int, id: String): UnreadCount
  }
`

export default typeDefs
