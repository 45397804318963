import { ReactElement } from 'react'
import SettingsProvider from 'modules/pipeline/contexts/pipeline'
import Pipeline from 'modules/pipeline/components/Pipeline'

/**
 *
 * @constructor
 */
const PipelinePage = (): ReactElement => {
  /*******************************************************
   * RENDER
   *******************************************************/
  return (
    <SettingsProvider>
      <Pipeline />
    </SettingsProvider>
  )
}

export default PipelinePage
