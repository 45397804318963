// @react
import React from 'react'
interface PropsType {
  width?: number | string
  height?: number | string
}

const Spacer = ({ width = '100%', height = 0 }: PropsType) => {
  return <div style={{ width, height }} />
}

export default Spacer
