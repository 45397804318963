import styled from 'styled-components'
import { Button, Typography } from '@material-ui/core'
import { ProfileEditPasswordContainer } from 'modules/common/styledComponents/Profile'
import { device, from } from 'modules/common/styledComponents/MediaQueries'

export const Wrapper = styled(ProfileEditPasswordContainer)`
  ${from(device.ipadL)} {
    max-width: 100%;
  }
`

export const Title = styled(Typography)`
  && {
    margin-bottom: 1em;
  }
`

export const StyledButton = styled(Button)`
  && {
    width: 100%;
    max-width: 325px;
  }
`
