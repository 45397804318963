import { useState, useEffect } from 'react'
import usePlacesAutocomplete, { getDetails } from 'use-places-autocomplete'
import Autocomplete from '@material-ui/lab/Autocomplete'
import useOnclickOutside from 'react-cool-onclickoutside'
import { Grid } from '@material-ui/core'
import parse from 'autosuggest-highlight/parse'
import { allowedCountryCodes } from 'constants/geo'
import * as SC from './styledComponents'
/*******************************************************
 * TYPES
 *******************************************************/
export interface AddressComponent {
  long_name: string
  short_name: string
  types: string[]
}

interface Address {
  description: string
  place_id: string
  structured_formatting: {
    main_text_matched_substrings: { offset: number; length: number }[]
    main_text: string
    secondary_text: string
  }
}

export interface GoogleAutocomplete {
  google?: {
    maps: {
      places: {
        AutocompleteSessionToken: () => string
      }
    }
  }
}

export type DetailsResult = {
  address_components: AddressComponent[] | undefined
}

type PropsType = {
  onChange: (details: DetailsResult) => void
}

/*******************************************************
 *
 *******************************************************/
const PlacesAutocompleteInput = ({ onChange }: PropsType) => {
  /*******************************************************
   * STATE
   *******************************************************/
  const [refreshSessionToken, setRefreshSessionToken] = useState<boolean>(true)
  const [apiSessionToken, setApiSessionToken] = useState<string>('')
  const [data, setData] = useState<Address[]>([])

  const renewSessionToken = () => {
    return apiSessionToken
  }

  const {
    suggestions: { data: suggestionsData },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      sessionToken: renewSessionToken(),
      componentRestrictions: { country: allowedCountryCodes },
      types: ['address'],
    },
    debounce: 500,
    callbackName: 'initMap',
  })

  /*******************************************************
   * EFFECT HOOKS
   *******************************************************/
  useEffect(() => {
    if (suggestionsData.length) {
      setData(suggestionsData)
    }
  }, [suggestionsData])

  /**
   * hook helps to generate a sessiontoken for google place API queries
   */
  useEffect(() => {
    const windowWithGoogleAutocomplete = window as Window & GoogleAutocomplete
    if (refreshSessionToken) {
      if (windowWithGoogleAutocomplete.google) {
        setApiSessionToken(
          new windowWithGoogleAutocomplete.google.maps.places.AutocompleteSessionToken(),
        )
      }
      setRefreshSessionToken(false)
    }
  }, [refreshSessionToken])

  /*******************************************************
   * FUNCTIONS
   *******************************************************/
  const handleInput = (_, value: string, type: string) => {
    setValue(value, type === 'input')
    if (value === '' && type === 'input') {
      setData([])
    }

    if (type === 'reset') {
      const selectedValue = data.find((place) => place.description === value)
      if (selectedValue) {
        getDetails({ placeId: selectedValue.place_id })
          .then((details: DetailsResult) => {
            if (onChange) onChange(details)
          })
          .catch((error) => {
            console.log('Error: ', error)
            clearSuggestions()
          })
      }
    }
  }

  /*******************************************************
   * RENDER
   *******************************************************/
  return (
    <Autocomplete
      disablePortal
      options={data || []}
      onInputChange={handleInput}
      getOptionLabel={(option) => {
        return typeof option === 'string' ? option : option.description
      }}
      renderOption={(option) => {
        const matches =
          option.structured_formatting.main_text_matched_substrings
        const parts = parse(
          option.structured_formatting.main_text,
          matches.map((match: { offset: number; length: number }) => [
            match.offset,
            match.offset + match.length,
          ]),
        )

        return (
          <Grid container alignItems="center">
            <Grid item xs={12}>
              {parts.map((part, index) => (
                <span
                  key={index}
                  style={{ fontWeight: part.highlight ? 700 : 400 }}
                >
                  {part.text}
                </span>
              ))}
              , {option.structured_formatting.secondary_text}
            </Grid>
          </Grid>
        )
      }}
      renderInput={(params) => (
        <SC.TextField {...params} label="Address Auto-Complete" />
      )}
    />
  )
}

export default PlacesAutocompleteInput
