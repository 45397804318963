import styled from 'styled-components'
import colors from 'constants/colors'
import {
  spaceBetweenCenter,
  dashboardRowBasic,
} from 'modules/common/styledComponents/Mixins'
import { device, from, to } from 'modules/common/styledComponents/MediaQueries'

export const ActiveDealsHeader = styled.div`
  ${spaceBetweenCenter};
  padding: 0.8em;
  border: 1px solid ${colors.veryLightBlue};

  ${from(device.tablet)} {
    padding: 20px 32px;
  }
`

export const ActiveDealsItem = styled.div`
  ${spaceBetweenCenter};
  ${dashboardRowBasic('20px 32px')};
  position: relative;

  ${to(device.mobileL)} {
    padding: 0.8em;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  }
`

export const ActiveDealsColumn = styled.div`
  padding-left: 1em;

  &:first-child {
    padding-left: 0;
  }

  ${to(device.mobileL)} {
    padding-left: 0;
  }
`

export const ActiveDealsTableContent = styled.div`
  margin-bottom: 15px;
  background-color: ${colors.white};
`

export const NotificationItem = styled.div`
  ${spaceBetweenCenter};
  ${dashboardRowBasic('15px 30px')};

  ${to(device.tablet)} {
    padding: 0.8em 0.8em 0.8em 30px;
  }

  .active {
    background: ${colors.white};
  }

  &.disabled:hover {
    cursor: default;
    opacity: 0.95;
    transition: opacity 0.1s;
    background-color: transparent;
  }

  &.disable-hover &:hover {
    cursor: auto;
    background: transparent;

    &.active {
      background: ${colors.paleGrey};
    }
  }
`
