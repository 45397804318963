// @react
import React from 'react'
import * as SC from './styledComponents/avatar'
import config from 'constants/config'

type PropsType = {
  src?: string
  showOnline?: boolean
  isOnline?: boolean
  width?: number
  height?: number
  className?: string
}

const SBAvatar = ({
  src,
  showOnline,
  isOnline,
  className,
  width,
  height,
}: PropsType) => {
  return (
    <SC.AvatarWrapper className={className}>
      <SC.StyledAvatar
        src={src || config.defaultAvatar}
        width={width}
        height={height}
        showonline={showOnline}
        isonline={isOnline} // see https://github.com/styled-components/styled-components/issues/2218
      />
      {showOnline && (
        <SC.OnlineIconWrapper>
          {isOnline ? <SC.OnlineIcon /> : <SC.OfflineIcon />}
        </SC.OnlineIconWrapper>
      )}
    </SC.AvatarWrapper>
  )
}

export default SBAvatar
