import { Grid, Typography } from '@material-ui/core'
import styled from 'styled-components'

export const Wrap = styled.div`
  flex: 1;
  align-items: center;
`

export const Subtitle = styled(Typography)`
  && {
    text-transform: uppercase;
    margin: 17px 0;
  }
`

export const FabWrap = styled(Grid)`
  && {
    margin-right: 8px;
  }
`

export const InputWrap = styled(Grid)`
  && {
    flex: 1;
  }
`
