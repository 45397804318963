// @react
import React, { useRef, useMemo, useEffect } from 'react'
import { Controller } from 'react-hook-form'
import FormInput from 'modules/common/components/_FORM_ELEMENTS/HookForm/form-inputs/FormInput'
import FormSelect from 'modules/common/components/_FORM_ELEMENTS/HookForm/form-inputs/FormSelect'
// @common
import strings from 'constants/strings'
import {
  getAddressLabels,
  getRegions,
  industryTypes,
} from '../../common/common'
import { allowedCountryCodes } from 'constants/geo'
// @design
import * as SC from '../CreateDealWizard/styledComponents/createDealSummary'
import { Grid, RadioGroup, Typography } from '@material-ui/core'
import {
  StyledSectionTitle,
  SkipButton,
  Wrapper,
} from './styledComponents/styledSummaryForm'
// @components
import FormRadio from 'modules/common/components/_FORM_ELEMENTS/HookForm/form-inputs/FormRadio'
import PlacesAutocomplete from 'modules/common/components/_UI_SMART/PlacesAutocomplete'
import { PropertyType } from 'graphql/graphqlTypes'
import { countries } from 'constants/geo'
import FormQuill from 'modules/common/components/_FORM_ELEMENTS/HookForm/form-inputs/FormQuill'
import Hospitality from './components/Hospitality'
import Industrial from './components/Industrial'
import Land from './components/Land'
import Other from './components/Other'
import Office from './components/Office'
import Residential from './components/Residential'
import Retail from './components/Retail'
import Spacer from 'modules/common/components/_UI_DUMB/Spacer'
import { AddressComponent } from 'modules/common/components/_UI_SMART/PlacesAutocomplete/PlacesAutocompleteInput'
import CustomFieldList from './components/customFields'

/*******************************************************
 * TYPES
 *******************************************************/

const getAddressInfo = (
  address_components: AddressComponent[] | undefined,
  componentName: string,
  type?: 'long_name' | 'short_name',
) => {
  if (!address_components) return ''
  const addressComponent = address_components.find((addressComponent) =>
    addressComponent.types.includes(componentName),
  )
  return addressComponent ? addressComponent[type ? type : 'long_name'] : ''
}

/**
 *
 * @param control
 * @param isSubmitting
 * @param changeDealData
 * @param resetFields
 * @param watch
 * @param isEdit
 * @param setValue
 * @constructor
 */
const DealSummaryForm = ({
  control,
  changeDealData,
  resetFields,
  watch,
  isEdit,
  setValue,
  formState,
}) => {
  /*******************************************************
   * HOOKS
   *******************************************************/
  useEffect(() => {
    control.formName = 'deal-summary'
  }, [])
  const watchCountry = watch('country')
  const watchIndustry = watch('industry')
  const watchPropertyType = watch('propertyType')
  const shouldAddressShrink = useMemo(
    () => watch('address') && watch('address').length !== 0,
    [watch],
  )

  const shouldZipShrink = useMemo(
    () => watch('zip') && watch('zip').length !== 0,
    [watch],
  )
  const shouldCountryShrink = useMemo(
    () => watch('country') && watch('country').length !== 0,
    [watch],
  )
  const shouldCityShrink = useMemo(
    () => watch('city') && watch('city').length !== 0,
    [watch],
  )
  const shouldStateShrink = useMemo(
    () => watch('state') && watch('state').length !== 0,
    [watch],
  )

  const renderTypedFields = () => {
    const fieldsProperties = {
      control,
      formState,
    }
    switch (watchIndustry) {
      case 'Hospitality':
        return <Hospitality {...fieldsProperties} />
      case 'Industrial':
        return <Industrial {...fieldsProperties} />
      case 'Land':
        return <Land {...fieldsProperties} />
      case 'Other':
        return <Other {...fieldsProperties} />
      case 'Office':
        return <Office {...fieldsProperties} />
      case 'Residential':
        return <Residential {...fieldsProperties} />
      case 'Retail':
        return <Retail {...fieldsProperties} />
      default:
        return null
    }
  }

  const divRef = useRef<HTMLDivElement>(null)
  /*******************************************************
   * RENDER
   *******************************************************/
  return (
    <Wrapper>
      <FormInput
        name={'name'}
        control={control}
        formState={formState}
        label={`${strings.DEAL_NAME} *`}
      />

      <Grid container spacing={2}>
        <Grid xs={12} sm={5} item>
          <FormSelect
            testid="deal-summary-industry"
            name="industry"
            control={control}
            formState={formState}
            label={`${strings.SECTOR} *`}
            options={industryTypes}
          />
        </Grid>
        <Grid xs={12} sm={5} item>
          <SC.RadioWrap>
            <Typography variant="body1">
              <span>Property Type *</span>
            </Typography>
            <Controller
              as={
                <RadioGroup>
                  <Grid container>
                    <Grid item sm={6}>
                      <FormRadio
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                          changeDealData(e.target.value, 'propertyType', () => {
                            resetFields(PropertyType.PORTFOLIO)
                            divRef.current?.scrollIntoView({
                              behavior: 'smooth',
                              block: 'start',
                            })
                          })
                        }
                        label={strings.SINGLE.toUpperCase()}
                        value={PropertyType.SINGLE}
                      />
                    </Grid>
                    <Grid item sm={6}>
                      <FormRadio
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                          changeDealData(e.target.value, 'propertyType', () => {
                            resetFields(PropertyType.SINGLE)
                            divRef.current?.scrollIntoView({
                              behavior: 'smooth',
                              block: 'start',
                            })
                          })
                        }}
                        label={strings.PORTFOLIO.toUpperCase()}
                        value={PropertyType.PORTFOLIO}
                      />
                    </Grid>
                  </Grid>
                </RadioGroup>
              }
              name="propertyType"
              control={control}
            />
          </SC.RadioWrap>
        </Grid>
        {watchPropertyType === PropertyType.PORTFOLIO && (
          <Grid item xs={4}>
            <FormInput
              name={'numberOfProperties'}
              control={control}
              formState={formState}
              label={strings.NUMBER_OF_PROPERTIES}
            />
          </Grid>
        )}
      </Grid>

      <Grid item xs={12}>
        <Spacer height={5} />
        <PlacesAutocomplete
          onChange={(value) => {
            if (typeof value === 'object') {
              const country: string = getAddressInfo(
                value.address_components,
                'country',
                'short_name',
              )

              const city: string = getAddressInfo(
                value.address_components,
                'locality',
              )

              const state: string = getAddressInfo(
                value.address_components,
                'administrative_area_level_1',
                'short_name',
              )

              const zipCode: string = getAddressInfo(
                value.address_components,
                'postal_code',
              )

              const addrStNumber: string = getAddressInfo(
                value.address_components,
                'street_number',
              )
              const addrStName: string = getAddressInfo(
                value.address_components,
                'route',
              )

              setValue('country', country, {
                shouldValidate: true,
                shouldDirty: true,
              })

              setValue('city', city, {
                shouldValidate: true,
                shouldDirty: true,
              })

              setValue('state', state, {
                shouldValidate: true,
                shouldDirty: true,
              })

              setValue('zip', zipCode, {
                shouldValidate: true,
                shouldDirty: true,
              })

              setValue(
                'address',
                `${
                  addrStNumber === '' ? '' : addrStNumber + ' '
                } ${addrStName}`,
                {
                  shouldValidate: true,
                  shouldDirty: true,
                },
              )
            }
          }}
        />
        <Spacer height={10} />
      </Grid>

      <>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <FormSelect
              name="country"
              control={control}
              formState={formState}
              label={strings.COUNTRY}
              inputLabelProps={{
                shrink: shouldCountryShrink || undefined,
              }}
              options={countries
                .filter((el) => allowedCountryCodes.includes(el.code))
                .map((country) => ({
                  label: country.label,
                  value: country.code,
                }))}
            />

            <FormInput
              name={'address'}
              control={control}
              formState={formState}
              label={strings.ADDRESS}
              inputLabelProps={{ shrink: shouldAddressShrink || undefined }}
            />
          </Grid>

          <>
            <Grid item xs={12} sm={4}>
              <FormInput
                name={'city'}
                control={control}
                formState={formState}
                label={`${strings.CITY}`}
                inputLabelProps={{
                  shrink: shouldCityShrink || undefined,
                }}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <FormSelect
                name="state"
                control={control}
                formState={formState}
                label={getAddressLabels(watchCountry).stateLabel}
                inputLabelProps={{
                  shrink: shouldStateShrink || undefined,
                }}
                options={getRegions(watchCountry).map((region) => ({
                  label: region.name,
                  value: region.code,
                }))}
              />
            </Grid>

            <Grid item xs={12} sm={4}>
              <FormInput
                name={'zip'}
                control={control}
                formState={formState}
                label={getAddressLabels(watchCountry).postalCodeLabel}
                inputLabelProps={{
                  shrink: shouldZipShrink || undefined,
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <FormQuill
                name="description"
                control={control}
                formState={formState}
                label={'Deal Notes'}
              />
            </Grid>
          </>
        </Grid>

        {watchIndustry && (
          <StyledSectionTitle>
            Add sector specific details.
            {!isEdit && (
              <SkipButton
                disableRipple
                disableElevation
                disableFocusRipple
                color="primary"
                type="submit"
              >
                Want to skip for now?
              </SkipButton>
            )}
          </StyledSectionTitle>
        )}

        {renderTypedFields()}
        {watchIndustry && (
          <>
            <StyledSectionTitle>
              Add your custom deal details
            </StyledSectionTitle>
            <CustomFieldList formState={formState} control={control} />
          </>
        )}
      </>
    </Wrapper>
  )
}

export default DealSummaryForm
