// @react
import React, { useState, useEffect } from 'react'
// @design
// @common
import useDebounce from 'modules/common/hooks/useDebounce'
import { config } from 'constants/config'
import userActionsHook from 'modules/deal/components/UserRelationsManage/userActionsHook'
// @components
import ProfileMyContactsSearch from 'modules/user/components/ProfileMyContactsSearch'
import ConditionalLoader from 'modules/common/components/_UI_DUMB/Loaders/ConditionalLoader'
import DealSendContactsList from 'modules/deal/components/UserRelationsManage/DealSendContactsList'
import { Grid } from '@material-ui/core'
import * as ContactItemSC from 'modules/common/components/_UI_SMART/ContactItem/styledComponents/contactItem'
import EmailFieldsList from '../../EmailFieldsList'
// @graphql
import { useQuery } from '@apollo/client'
import { GET_DEAL_CONTACTS } from 'graphql/queries/user.query'
import {
  Order,
  UsersOrderByList,
  ContactStatus,
  InviteType,
  GetUserDealContactsQuery,
  GetUserDealContactsQueryVariables,
  Scalars,
  InviteUserDealInput,
  GetInvitesQuery,
  GetInvitesQueryVariables,
  SimplifiedUserNodeFragment,
} from 'graphql/graphqlTypes'
import { GET_INVITES } from 'graphql/queries/invite.query'
import useErrorHandler from 'modules/common/hooks/useErrorHandler'

/*******************************************************
 * TYPES
 *******************************************************/
interface PropsType {
  dealId: Scalars['ID']
  myTeamId: Scalars['ID']
  setIsDealSent?: (arg: boolean) => void
}
/**
 *
 * @param props
 * @constructor
 */
const DealSendDialogContent = ({ dealId, setIsDealSent }: PropsType) => {
  /*******************************************************
   * STATE
   *******************************************************/
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [foundDealUser, setFoundDealUser] = useState<
    SimplifiedUserNodeFragment | null | undefined
  >(null)

  /*******************************************************
   * HOOKS
   *******************************************************/
  const debouncedSearchValue = useDebounce(searchTerm, config.debounce)

  /**
   * queries
   */
  const { data, refetch, loading, fetchMore } = useQuery<
    GetUserDealContactsQuery,
    GetUserDealContactsQueryVariables
  >(GET_DEAL_CONTACTS, {
    fetchPolicy: 'network-only',
    variables: {
      page: 0,
      filters: {
        search: null,
      },
      orderBy: {
        order: Order.ASC,
        value: UsersOrderByList.FIRST_NAME,
      },
      dealId,
    },
  })

  const {
    data: invitedData,
    refetch: invitesRefetch,
    error: getInvitesQueryError,
  } = useQuery<GetInvitesQuery, GetInvitesQueryVariables>(GET_INVITES, {
    fetchPolicy: 'network-only',
    variables: {
      filters: {
        deal: dealId,
        type: [InviteType.DEAL],
        byEmail: true,
      },
    },
    skip: !dealId,
  })
  useErrorHandler(getInvitesQueryError)

  const refetchHandler = async () => {
    console.log('triggering hrer ')
    await refetch()
    console.log('triggering there  ')
    await invitesRefetch()
  }

  /*******************************************************
   * HOOKS
   *******************************************************/
  const { handlers } = userActionsHook(refetchHandler)

  useEffect(() => {
    if (debouncedSearchValue !== undefined) {
      refetch({ filters: { search: debouncedSearchValue } }).catch((e) =>
        console.log(e),
      )
    }
  }, [debouncedSearchValue])

  useEffect(() => {
    const foundUser = (() => {
      if (invitedData?.invites.data) {
        const foundInvite = invitedData?.invites.data.find((el) => {
          return el?.type === InviteType.DEAL
        })

        if (foundInvite) {
          return {
            id: '-1', // @todo this is not great
            __typename: 'SimplifiedUser' as const,
            email: foundInvite.email,
            uuid: '',
            createdDate: '',
            modifiedDate: '',
            userDeal: {
              inviteType: InviteType.DEAL,
              status: ContactStatus.PENDING,
              inviteId: foundInvite.id,
            },
          }
        }
      }
      if (data?.userDeal.data) {
        return data.userDeal.data.find((el) => {
          return el?.userDeal?.inviteType === InviteType.DEAL
        })
      }
      return
    })()

    if (foundUser) {
      console.log('foundUser')
      setFoundDealUser(foundUser)
      setIsDealSent && setIsDealSent(true)
    } else {
      setFoundDealUser(null)
      setIsDealSent && setIsDealSent(false)
    }
  }, [data, invitedData])

  /*******************************************************
   * FUNCTIONS
   *******************************************************/
  /**
   *
   */
  const loadMoreHandler = async () => {
    if (data?.userDeal.meta) {
      const newPageNum = (data.userDeal.meta.page || 0) + 1

      await fetchMore({
        variables: {
          page: newPageNum,
        },
      })
    }
  }

  const isLoadMoreNeeded =
    (data?.userDeal.meta?.totalPages || 0) >
    (data?.userDeal.meta?.page || 0) + 1

  /*******************************************************
   * RENDER
   *******************************************************/
  return (
    <>
      <ContactItemSC.DialogContactListWrap>
        {!foundDealUser && (
          <Grid container spacing={1} alignItems="center">
            <ProfileMyContactsSearch
              value={searchTerm}
              onClear={() => setSearchTerm('')}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </Grid>
        )}
      </ContactItemSC.DialogContactListWrap>
      <ContactItemSC.DialogContactList data-testid="deal-send-to-list">
        <ConditionalLoader condition={!(!data && loading)}>
          <DealSendContactsList
            userContactsData={
              foundDealUser ? [foundDealUser] : data?.userDeal.data
            }
            loadMore={{
              isLoadMoreNeeded: isLoadMoreNeeded && !foundDealUser,
              loadMoreHandler,
            }}
            refetch={refetchHandler}
            deal={dealId}
          />
        </ConditionalLoader>
      </ContactItemSC.DialogContactList>
      {!foundDealUser && (
        <EmailFieldsList<(invite: InviteUserDealInput) => Promise<void>>
          inviteMenu={[
            {
              handler: async (email) => {
                await handlers.dealInviteSendHandler({ dealId, email })
              },
              text: 'Send Deal',
            },
          ]}
        />
      )}
    </>
  )
}

export default DealSendDialogContent
