// @react
import React from 'react'
import { FunctionComponent } from 'react'
import { FormControlLabel, makeStyles, Radio } from '@material-ui/core'
import { colors } from 'constants/colors'

/*******************************************************
 * CONST
 *******************************************************/
export const style = () => ({
  radioRoot: {
    padding: 6,
    marginRight: 20,
  },
  label: {
    fontSize: '13px',
    paddingLeft: 4,
    color: colors.battleshipGrey,
  },
})
const useStyles = makeStyles(style)

/*******************************************************
 * TYPE
 *******************************************************/
export type PropsType = {
  label: string
  className?: string
  [custom: string]: any
}

/**
 *
 * @param input
 * @param value
 * @param label
 * @param touched
 * @param error
 * @param custom
 * @constructor
 */
const FormRadio: FunctionComponent<PropsType> = ({
  input,
  value,
  label,
  touched,
  error,
  ...custom
}) => {
  /*******************************************************
   *
   *******************************************************/
  const classes = useStyles()

  /*******************************************************
   * RENDER
   *******************************************************/
  return (
    <FormControlLabel
      {...input}
      {...custom}
      style={{ width: '100%' }}
      value={value?.toString()}
      name={label.toLocaleLowerCase()}
      classes={{
        label: classes.label,
      }}
      control={<Radio color="primary" />}
      label={label}
    />
  )
}

export default FormRadio
