import { Countries } from 'country-and-province'
export const countries: {
  code: string
  label: string
}[] = []
;(function () {
  for (const [, value] of Object.entries(Countries.data)) {
    countries.push({
      code: value.code,
      label: value.name,
    })
  }
})()

export const allowedCountryCodes = ['US', 'CA']
