// @react
import { ReactElement, useCallback } from 'react'
// @common
import { isArrayType } from 'modules/common/types'
// @design
import { makeStyles } from '@material-ui/core'
import * as SC from './styledComponents/tableWrapper'
// @components
import TableChangeModel from 'modules/common/models/sharedTableChange'
// @graphql
import LoadMoreBtn from 'modules/common/components/_UI_SMART/LoadMoreBtn'
import {
  DealsMeta,
  DocumentsMeta,
  GetDealsByStatusQuery,
  InvitesMeta,
  ItemMetaFragment,
} from 'graphql/graphqlTypes'
import { TableSortableRowModel } from 'modules/common/models/sharedTableChange/SharedTableSortableRow.model'
import { useContext } from 'react'
import { TableContext } from '../ContextTableWrapper'

/*******************************************************
 * TYPES
 *******************************************************/
type PropsType = {
  loading?: boolean
  loadingProgress?: boolean
  rowCount?: number
  className?: string
  responsiveStyles?: string
  noDataLabel?: string
  headerRow: TableSortableRowModel[]
  bodyRow: ReactElement | ReactElement[] | void
  handleChange?: (param: TableChangeModel) => void
  refetch: (OwnProps) => void
  dataMeta?:
    | GetDealsByStatusQuery['deals']['meta'] // @todo this should be turned into a fragment at the query for cleaner typing
    | DealsMeta
    | DocumentsMeta
    | ItemMetaFragment
    | InvitesMeta
  fetchMore?: (any: any) => void
  queryName?: string
  variables?: any // @todo union of all variables types that can go through the table
}

/*******************************************************
 * CONST
 *******************************************************/
const useStyles = makeStyles(SC.style)

/***
 *
 * @param bodyRow
 * @param dataMeta
 * @param fetchMore
 * @param headerRow
 * @param loading
 * @param noDataLabel
 * @param queryName
 * @param refetch
 * @param variables
 * @constructor
 */
const TableWrapper = ({
  bodyRow,
  dataMeta,
  fetchMore,
  loading,
  noDataLabel,
  queryName,
}: PropsType) => {
  /*******************************************************
   * PROPS
   *******************************************************/
  const isLoadMoreNeeded = (dataMeta?.page || 0) + 1 !== dataMeta?.totalPages

  console.log('dataMeta')
  console.log(dataMeta)

  /*******************************************************
   * HOOKS
   *******************************************************/
  const classes = useStyles()

  /*******************************************************
   * CONTEXT
   *******************************************************/
  const userContext = useContext(TableContext)
  const { SortableHeader } = userContext

  /**
   *
   */
  const loadMoreHandler = useCallback(() => {
    if (dataMeta && fetchMore && queryName) {
      const newPageNum = (dataMeta.page || 0) + 1

      fetchMore({
        variables: {
          page: newPageNum,
        },
      })
    }
  }, [dataMeta, fetchMore, queryName])

  /*******************************************************
   * RENDER
   *******************************************************/
  if (bodyRow && isArrayType<ReactElement>(bodyRow)) {
    return bodyRow.length > 0 ? (
      <>
        <SC.StyledTable component="div" className={classes.table}>
          <SortableHeader />
          <SC.StyledTableBody component="div">{bodyRow}</SC.StyledTableBody>
        </SC.StyledTable>
        <LoadMoreBtn
          isVisible={isLoadMoreNeeded}
          isLoading={!!loading}
          onClick={loadMoreHandler}
        />
      </>
    ) : (
      <SC.InfoWrapper>{noDataLabel || bodyRow}</SC.InfoWrapper>
    )
  }
  return <></>
}

export default TableWrapper
