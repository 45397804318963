import styled from 'styled-components'

/*******************************************************
 * TYPES
 *******************************************************/
export type BreakPointLabel =
  | 'mobileS'
  | 'mobile'
  | 'mobileL'
  | 'materialXS'
  | 'tabletS'
  | 'tablet'
  | 'tabletL'
  | 'tabletXL'
  | 'ipad'
  | 'ipadL'
  | 'mbp'
  | 'desktop'

type DeviceMap<T> = { [pixels in BreakPointLabel]: T }

/*******************************************************
 *
 *******************************************************/
export const device: DeviceMap<number> = {
  mobileS: 320,
  mobile: 375,
  mobileL: 425,
  materialXS: 600,
  tabletS: 640,
  tablet: 768,
  tabletL: 900,
  tabletXL: 960,
  ipad: 1024,
  ipadL: 1280,
  mbp: 1440,
  desktop: 1650,
}
/***
 *
 * @param breakpoint
 */
export const from = (breakpoint: number) => {
  return `@media (min-width: ${breakpoint}px)`
}

export const to = (breakpoint: number) => {
  return `@media (max-width: ${breakpoint - 1}px)`
}

export const between = (from: number, to: number) => {
  return `@media (min-width: ${from}px) and (max-width: ${to - 1}px)`
}

export const HideOnMobile = styled.span`
  ${to(device.tablet)} {
    display: none;
  }
`

export const HideOnDesktop = styled.span`
  ${from(device.tablet)} {
    display: none;
  }
`
