import { RouteType, RouteNames } from 'constants/routeNames'
import Pipeline from './pages/Pipeline'

const routes: RouteType[] = [
  {
    component: Pipeline,
    path: `/${RouteNames.PIPELINE}`,
    type: 'PRIVATE',
    exact: true,
    layout: 'MAIN',
  },
]

const RoutesModule = { routes }
export default RoutesModule
