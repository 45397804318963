// language=GraphQL
import { gql } from '@apollo/client'
import { DOCUMENT_FRAGMENT } from 'graphql/fragments/document.fragment'

export const DOCUMENT_DELETE = gql`
  mutation DocumentDelete($id: ID!) {
    documentDelete(id: $id) {
      message
      status
    }
  }
`

export const DOCUMENT_UPDATE = gql`
  mutation DocumentUpdate($input: UpdateDocumentInput!) {
    documentUpdate(input: $input) {
      ...DocumentNode
    }
  }
  ${DOCUMENT_FRAGMENT}
`
