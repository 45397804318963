import styled from 'styled-components'

export const Wrap = styled.div`
  flex: 1;
`

export const RadioWrap = styled.div`
  padding-top: 5px;
  padding-bottom: 5px;
`
