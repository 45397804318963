// @react
import React from 'react'
import { LaneFooter as TrelloLaneFooter } from 'react-trello/dist/styles/Base'
import CreateItem from 'modules/item/components/Create'
import { useContext, useState } from 'react'
import { ItemContext } from 'modules/item/contexts/item'
import { DealContext } from 'modules/deal/contexts/deal'
import { Button } from '@material-ui/core'
import { FooterWrapper } from './styledComponents/laneFooter'
import { DealActions } from 'graphql/graphqlTypes'

/**
 *
 * @constructor
 */
const LaneFooter = () => {
  const [isOpen, setIsOpen] = useState(false)
  const { stage } = useContext(ItemContext)

  const { data } = useContext(DealContext)
  const deal = data?.deal

  /*******************************************************
   * RENDER
   *******************************************************/
  return (
    <FooterWrapper>
      <CreateItem
        open={isOpen}
        initData={stage ? { stage } : undefined}
        handleClose={() => setIsOpen(false)}
      />
      <TrelloLaneFooter>
        <Button
          variant={'contained'}
          color="primary"
          fullWidth={true}
          disabled={!deal?.actions?.includes(DealActions.DEAL_CREATE_ITEM)}
          onClick={() => {
            setIsOpen(true)
          }}
        >
          ADD ITEM
        </Button>
      </TrelloLaneFooter>
    </FooterWrapper>
  )
}

export default LaneFooter
