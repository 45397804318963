import { Button } from '@material-ui/core'
import colors from 'constants/colors'
import styled from 'styled-components'
import { flexCenter } from 'modules/common/styledComponents/Mixins'

const ButtonBase = styled(Button)`
  && {
    padding: 5px 25px;
    margin-right: 8px;
  }
`

export const ButtonOrange = ButtonBase

/*******************************************************
 * COLOR OVERRIDES
 *******************************************************/
export const ButtonRed = styled(ButtonBase)`
  && {
    background-color: ${colors.grapefruit};

    &:hover {
      background-color: ${colors.grapefruitDark};
    }
  }
`
export const ButtonAquaMarineLight = styled(ButtonBase)`
  && {
    color: ${colors.green};
    background-color: ${colors.aquaMarineLight};
    text-transform: none;

    &:hover {
      color: ${colors.white};
      background-color: ${colors.aquaMarine};
    }
  }
`

export const ButtonAqua = styled(ButtonBase)`
  && {
    color: ${colors.white};
    background-color: ${colors.aquaMarine};

    &:hover {
      background-color: ${colors.aquaMarineDark};
    }
  }
`

export const ButtonRedMini = styled(ButtonRed)`
  && {
    height: 10px;
  }
`
export const ButtonAquaMarineLightMini = styled(ButtonAquaMarineLight)`
  && {
    min-height: 5px;
    height: 25px;
    font-size: 12px;
  }
`

export const ButtonAquaMini = styled(ButtonAqua)`
  && {
    min-height: 10px;
  }
`

/*******************************************************
 * SIZE OVERRIDES
 *******************************************************/
export const StretchButton = styled(ButtonBase)`
  && {
    ${flexCenter}
  }
  && {
    width: 100%;
    height: 50px;
    justify-content: center;
    margin-top: 20px;
  }
`

export const CenteredButtonWrap = styled.div`
  && {
    ${flexCenter}
  }
  && {
    width: 100%;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
  }
`

/*******************************************************
 * CONTEXT SPECIFIC BUTTONS
 *******************************************************/
export const EditBtn = styled(Button)`
  && {
    color: ${colors.yellowishOrange};
    background-color: transparent;
    font-size: 32px;

    &:hover {
      background-color: transparent;
    }
  }
`

export const SubmitBtn = styled(Button)`
  && {
    width: 100%;
    margin-bottom: 20px;
  }
`

export const ActionBtn = styled(Button)`
  && {
    font-size: 14px;
    text-transform: none;
  }
`
