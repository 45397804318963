import { MenuItem, Typography } from '@material-ui/core'
import colors from 'constants/colors'
import styled from 'styled-components'
import { Cancel } from '@material-ui/icons'

export const ShowList = styled(MenuItem)`
  && {
    background-color: ${colors.aquaMarine};

    &:hover {
      background-color: ${colors.aquaMarineDark};
    }
  }
`

export const AllDates = styled(MenuItem)`
  && {
    background-color: ${colors.yellowishOrangeLight};

    &:hover {
      background-color: ${colors.yellowishOrange};
    }
  }
`

export const ClearDates = styled(MenuItem)`
  && {
    border-bottom: solid 1px;
    border-color: ${colors.medGrey};
  }
`

export const DatepickerWrap = styled(MenuItem)`
  && {
    cursor: auto;
    padding: 0;
    background-color: transparent;
    border: 0;

    &:hover {
      background-color: transparent;
    }
  }

  .MuiTouchRipple-root {
    display: none;
  }
`

export const CancelIcon = styled(Cancel)`
  && {
    font-size: 24px;
    color: ${colors.grapefruit};
    margin-right: 4px;
  }
`

export const StyledText = styled(Typography)`
  && {
    font-weight: 600;
    color: white;
  }
`
