// @react
import React from 'react'
// @common
import strings from 'constants/strings'

import { DialogActions, DialogContent, Grid } from '@material-ui/core'
import StyledDialog from 'modules/common/components/_UI_DUMB/ModalDialogs/styledComponents/StyledDialog'
// @graphql
import { Scalars } from 'graphql/graphqlTypes'
import ManageDealTeamDialogContent from './DialogContent/ManageDealTeamDialogContent'

/*******************************************************
 * TYPES
 *******************************************************/
interface PropsType {
  myTeamId: Scalars['ID']
  dealId: Scalars['ID']
  handleClose: () => void
}

/**
 *
 * @param props
 * @constructor
 */
const ManageTeamDialog = ({ handleClose, myTeamId, dealId }: PropsType) => {
  /*******************************************************
   * RENDER COMPONENTS
   *******************************************************/
  const DialogActionsContent = () => {
    return (
      <StyledDialog.Wrap>
        <Grid container justifyContent="center" spacing={3}>
          <Grid item xs={12} sm={6}>
            <StyledDialog.SubmitBtn
              onClick={handleClose}
              variant="contained"
              color="primary"
            >
              {strings.CLOSE}
            </StyledDialog.SubmitBtn>
          </Grid>
        </Grid>
      </StyledDialog.Wrap>
    )
  }

  /*******************************************************
   * RENDER
   *******************************************************/
  return (
    <>
      <DialogContent>
        <StyledDialog.Subtitle variant="subtitle1">
          MY TEAM
        </StyledDialog.Subtitle>
        <ManageDealTeamDialogContent myTeamId={myTeamId} dealId={dealId} />
      </DialogContent>
      <DialogActions>
        <DialogActionsContent />
      </DialogActions>
    </>
  )
}

export default ManageTeamDialog
