// @react
import { useState } from 'react'
import { useMutation } from '@apollo/client'
import { ITEM_UPDATE } from 'graphql/mutations/item.mutation'
import { GET_ITEM } from 'graphql/queries/item.query'
import {
  GetItemQuery,
  GetItemQueryVariables,
  ItemUpdateMutation,
  ItemUpdateMutationVariables,
  Scalars,
} from 'graphql/graphqlTypes'
import * as SC from 'modules/item/components/StarredButton/styledComponents'
import useErrorHandler from 'modules/common/hooks/useErrorHandler'
import ConditionalLoader from 'modules/common/components/_UI_DUMB/Loaders/ConditionalLoader'

/*******************************************************
 * TYPES
 *******************************************************/
type PropTypes = {
  itemId: Scalars['ID']
  disabled: boolean
  isStarred: boolean
  isHiddenLabel?: boolean
  iconSize?: number
}

/**
 *
 * @param props
 * @constructor
 */
const StarredButton = ({
  itemId,
  isStarred,
  disabled,
  isHiddenLabel,
  iconSize,
}: PropTypes) => {
  /*******************************************************
   * STATE
   *******************************************************/
  const [isLoading, setIsLoading] = useState<boolean>(false)

  /*******************************************************
   * GRAPHQL
   *******************************************************/
  const [updateItemMutation, { error: updateItemMutationErrors }] = useMutation<
    ItemUpdateMutation,
    ItemUpdateMutationVariables
  >(ITEM_UPDATE)
  useErrorHandler(updateItemMutationErrors)

  /**
   *
   */
  const toggleStarring = async () => {
    setIsLoading(true)
    await updateItemMutation({
      variables: {
        input: {
          id: itemId,
          starred: !isStarred,
        },
      },
      update(cache, { data: itemUpdateData }) {
        const itemUpdate = itemUpdateData?.itemUpdate
        const itemQuery = cache.readQuery<GetItemQuery>({
          query: GET_ITEM,
          variables: { id: itemId },
        })
        if (!itemQuery?.item.id) return
        cache.writeQuery<GetItemQuery, GetItemQueryVariables>({
          query: GET_ITEM,
          variables: { id: itemId },
          data: {
            item: {
              ...itemQuery.item,
              starred: itemUpdate?.starred,
            },
          },
        })
      },
    })

    setIsLoading(false)
  }

  /*******************************************************
   * RENDER COMPONENTS
   *******************************************************/
  const starIconNode = isStarred ? (
    <SC.StarIcon
      disabled={disabled}
      color="primary"
      size={iconSize}
      data-testid="icon-star-filled"
    />
  ) : (
    <SC.StarBorderIcon
      disabled={disabled}
      size={iconSize}
      data-testid="icon-star-bordered"
    />
  )

  /*******************************************************
   * RENDER
   *******************************************************/
  if (disabled)
    return (
      <SC.FlexCenter data-testid="item-star-disabled">
        {starIconNode}
      </SC.FlexCenter>
    )

  return (
    <ConditionalLoader condition={!isLoading}>
      <SC.GridWithPointer
        item
        onClick={toggleStarring}
        data-testid="item-star-toggle"
      >
        <SC.FlexCenter>
          {starIconNode}
          {!isHiddenLabel && <SC.Label variant="body1">Starred</SC.Label>}
        </SC.FlexCenter>
      </SC.GridWithPointer>
    </ConditionalLoader>
  )
}

export default StarredButton
