import { Typography, Button as MuiButton } from '@material-ui/core'
import { colors } from 'constants/colors'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import AddCircleOutlined from '@material-ui/icons/AddCircleOutlined'
import styled from 'styled-components'
import { device, to, from } from 'modules/common/styledComponents/MediaQueries'
import { flexCenter } from 'modules/common/styledComponents/Mixins'

export const Wrap = styled.div`
  position: relative;
`

export const DragArea = styled.div`
  display: flex;
  min-height: 86px;
  margin-bottom: 12px;
  border: 1px dashed ${colors.lightGreyBlue};
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  transition: all 0.2s ease;
  background-color: ${colors.white}
    ${(props) =>
      props.dragging &&
      `
        background-color: ${colors.veryLightBlue};
    `};
`

export const DropText = styled.span`
  color: ${colors.dark};
  font-weight: 600;
  ${to(device.ipadL)} {
    display: none;
  }
`

export const SelectText = styled.label`
  cursor: pointer;
  text-decoration: underline;
  font-weight: 600;
  color: ${colors.yellowishOrange};
  user-select: none;

  &:hover {
    color: ${colors.dusk};
  }

  ${from(device.ipadL)} {
    text-transform: lowercase;
  }
`

export const Input = styled.input`
  position: absolute;
  left: 0;
  top: 0;
  width: 0;
  height: 0;
  opacity: 0;
`

export const Icon = styled(CloudDownloadIcon)`
  && {
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }
`

export const CancelIcon = styled(AddCircleOutlined)`
  && {
    transform: rotate(45deg);
    font-size: 18px;
    color: ${colors.grapefruit};
  }
`

export const FileItem = styled.div`
  position: relative;
  margin-bottom: 12px;
  padding: 10px 19px;
  border-radius: 4px;
  background-color: ${colors.white};
`

export const FileName = styled(Typography)`
  && {
    ${flexCenter}
  }
  && {
    overflow-wrap: break-word;
    margin-right: 30px;
    font-size: 14px;
    font-weight: 600;
    color: ${colors.dark};
    &:hover {
      cursor: pointer;
    }
  }
`

export const RemoveBtnContainer = styled.div`
  position: absolute;
  top: 0px;
  right: 5px;
  bottom: 0px;
  display: flex;
  justify-content: center;
  flex-direction: column;
`

export const LockBtnContainer = styled.div`
  position: absolute;
  top: 0px;
  right: 40px;
  bottom: 0px;
  display: flex;
  justify-content: center;
  flex-direction: column;
`

export const Button = styled(MuiButton)`
  && {
    padding: 0px;
    margintop: 10;
  }
`

export const ButtonLabel = styled.label`
  padding: 5px 25px;
  cursor: pointer;
`
export const LockIconWrapper = styled.span`
  &:hover {
    cursor: pointer;
  }
`
