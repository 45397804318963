// @design
import { Grid } from '@material-ui/core'
import styled from 'styled-components'
import { device, from, to } from 'modules/common/styledComponents/MediaQueries'

export const Wrap = styled.div``

export const Column = styled(Grid)`
  && {
    flex-grow: 1;

    ${to(device.mobileL)} {
      width: 100%;
      padding-top: 8px;
      padding-bottom: 8px;
    }

    ${from(device.tabletL)} {
      max-width: 230px;
    }
  }
`

export const SearchColumn = styled(Column)`
  && {
    flex-grow: 1;

    ${to(device.tabletL)} {
      width: 100%;
    }

    ${from(device.materialXS)} {
      max-width: none;
    }
  }
`
