// @react
import React, { ReactElement } from 'react'
// @design
// @components
import {
  DealStatus,
  GetDealsQuery,
  InviteType,
  GetDealsQueryVariables,
  GetInvitesCountQuery,
  GetInvitesCountQueryVariables,
  GetDealCountByStatusQuery,
  GetDealCountByStatusQueryVariables,
} from 'graphql/graphqlTypes'
import { QueryResult, useQuery } from '@apollo/client'
import ChipBtn from 'modules/common/components/_UI_DUMB/ChipBtn'
import strings from 'constants/strings'
import { RouteNames } from 'constants/routeNames'
import { GET_DEAL_COUNT_BY_STATUS } from 'graphql/queries/deal.query'
import { ChipFiltersWrapper } from 'modules/common/components/_UI_DUMB/ChipBtn/styledComponents'
import { GET_INVITES_COUNT } from 'graphql/queries/invite.query'

/*******************************************************
 * TYPES
 *******************************************************/
type PropsType = {
  queryResult: QueryResult<GetDealsQuery, GetDealsQueryVariables>
  tabHandler?: (number) => void
}

/**
 *
 * @param {number} activeIndex
 * @returns {React.ReactElement}
 * @constructor
 */
const DealsTabs: React.FC<PropsType> = ({}: PropsType): ReactElement => {
  /*******************************************************
   * GRAPHQL
   *******************************************************/
  const { data: dataCount } = useQuery<
    GetDealCountByStatusQuery,
    GetDealCountByStatusQueryVariables
  >(GET_DEAL_COUNT_BY_STATUS)
  const { data: invitesData } = useQuery<
    GetInvitesCountQuery,
    GetInvitesCountQueryVariables
  >(GET_INVITES_COUNT)
  const counts = dataCount?.dealCountByStatus.counts || {}
  const allCount = Object.values(DealStatus).reduce(
    (sum, val) => sum + Number(counts[val]),
    0,
  )

  const sentInvites =
    invitesData?.invitesCount.sent.find(({ type }) => type === InviteType.DEAL)
      ?.count || 0

  const receivedInvites =
    invitesData?.invitesCount.received.reduce(
      (totalCount: number, { type, count }) => {
        const isReceivedType = [
          InviteType.DEAL,
          InviteType.TEAM,
          InviteType.THIRD_PARTY,
        ].includes(type)

        return isReceivedType ? totalCount + count : totalCount
      },
      0,
    ) || 0

  /*******************************************************
   * RENDER
   *******************************************************/
  return (
    <>
      <ChipFiltersWrapper>
        <ChipBtn
          labelText={strings.ALL_DEALS}
          url={`/${RouteNames.DEALS}`}
          count={allCount}
        />
        <ChipBtn
          labelText={strings.INVITES}
          url={`/${RouteNames.DEALS}/${RouteNames.INVITES_PENDING}`}
          count={receivedInvites}
        />
        <ChipBtn
          labelText={strings.SENT_DEALS}
          url={`/${RouteNames.DEALS}/${RouteNames.INVITES_SENT}`}
          count={sentInvites}
        />
      </ChipFiltersWrapper>
    </>
  )
}

export default DealsTabs
