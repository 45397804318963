import * as Yup from 'yup'

const emptyToNumber = (_, value) => (value === '' ? null : Number(value))

export default Yup.object().shape({
  name: Yup.string().required('Name is required!'),
  industry: Yup.string().required('Industry is required!'),
  address: Yup.string().nullable(true),
  city: Yup.string().nullable(true),
  zip: Yup.string().nullable(true),
  description: Yup.string().nullable(true),
  yearOfConstruction: Yup.number()
    .typeError('Must be a number')
    .transform(emptyToNumber)
    .nullable(true),
  rentableAreaValue: Yup.number()
    .typeError('Must be a number')
    .transform(emptyToNumber)
    .nullable(true),
  numberOfProperties: Yup.number()
    .typeError('Must be a number')
    .transform(emptyToNumber)
    .nullable(true),
  noKeys: Yup.number()
    .typeError('Must be a number')
    .transform(emptyToNumber)
    .nullable(true),
  siteAreaValue: Yup.number()
    .typeError('Must be a number')
    .transform(emptyToNumber)
    .nullable(true),
  occupancy: Yup.number()
    .typeError('Must be a number')
    .transform(emptyToNumber)
    .nullable(true),
  wale: Yup.number()
    .typeError('Must be a number')
    .typeError('Must be a number')
    .transform(emptyToNumber)
    .nullable(true),
  clearHeight: Yup.number()
    .typeError('Must be a number')
    .transform(emptyToNumber)
    .nullable(true),
  noDockHighDoors: Yup.number()
    .typeError('Must be a number')
    .transform(emptyToNumber)
    .nullable(true),
  acreage: Yup.number()
    .typeError('Must be a number')
    .transform(emptyToNumber)
    .nullable(true),
  parkingStalls: Yup.number()
    .typeError('Must be a number')
    .transform(emptyToNumber)
    .nullable(true),
  landAreaValue: Yup.number()
    .typeError('Must be a number')
    .transform(emptyToNumber)
    .nullable(true),
  noFloors: Yup.number()
    .typeError('Must be a number')
    .transform(emptyToNumber)
    .nullable(true),
  totalPropertyRentArea: Yup.number()
    .typeError('Must be a number')
    .transform(emptyToNumber)
    .nullable(true),
  meeting: Yup.number()
    .typeError('Must be a number')
    .transform(emptyToNumber)
    .nullable(true),
})
