// @react
import React from 'react'
import { useState, FC, useEffect } from 'react'
// @design
import * as SC from './styledComponents/freeDatePicker'
// @components
import FreeDatePickerArrow from './components/FreeDatePickerArrow'
// @common
import DayPicker, { DayPickerProps, Modifiers } from 'react-day-picker'

/*******************************************************
 * CONSTANTS
 *******************************************************/
const defaultOptions: DayPickerProps = {
  numberOfMonths: 2,
}
/*******************************************************
 * TYPES
 *******************************************************/
interface InjectPropsNotStrict {
  options?: DayPickerProps
  from?: Date
  to?: Date
  modifiers?: Modifiers
}

interface InjectProps extends InjectPropsNotStrict {
  onChange: (...args) => void
}

type PropsType = InjectProps

/**
 *
 * @param props
 * @constructor
 */
const FreeDatePicker: FC<PropsType> = ({ options, onChange, from, to }) => {
  const newOptions: DayPickerProps = {
    ...defaultOptions,
    ...options,
  }

  /*******************************************************
   * STATE
   *******************************************************/
  const [fromState, setFromState] = useState<Date | undefined>(from)
  const [toState, setToState] = useState<Date | undefined>(to)
  const [modifiers, setModifiers] = useState({ start: fromState, end: toState })

  /*******************************************************
   * EFFECT HOOKS
   *******************************************************/
  useEffect(() => {
    setModifiers({ start: fromState, end: toState })
  }, [fromState, toState])

  /*******************************************************
   * FUNCTIONS
   *******************************************************/
  const handleDayClick = (day) => {
    if (fromState && toState && day >= fromState && day <= toState) {
      resetDays(day)
      return
    }

    const range = DayPicker.DateUtils.addDayToRange(day, {
      from: fromState,
      to: toState,
    })
    range.from && setFromState(range.from)
    range.to && setToState(range.to)
  }

  const resetDays = (day?: Date) => {
    day && setFromState(day)
    setToState(undefined)
    onChange({ from: null, to: null })
  }

  const onBlur = (e) => {
    // this means that click was outside of date picker
    if (e.relatedTarget === null) onChange({ from: fromState, to: toState })
  }

  /*******************************************************
   * RENDER
   *******************************************************/
  return (
    <SC.Container>
      <DayPicker
        {...newOptions}
        className="Selectable"
        fixedWeeks
        modifiers={modifiers}
        selectedDays={[
          fromState,
          {
            from: fromState,
            to: toState,
          },
        ]}
        onDayClick={handleDayClick}
        onBlur={onBlur}
        navbarElement={FreeDatePickerArrow}
      />
    </SC.Container>
  )
}

export default FreeDatePicker
