import styled from 'styled-components'
import { Grid } from '@material-ui/core'

export const TopWrap = styled(Grid)`
  && {
    margin-bottom: 30px;
  }
`

export const Steppers = styled.div`
  margin-bottom: 30px;
  padding-left: 0;
  padding-right: 0;
`
