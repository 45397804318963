// @react
import { Grid, InputAdornment } from '@material-ui/core'
import FormInput from 'modules/common/components/_FORM_ELEMENTS/HookForm/form-inputs/FormInput'
import FormSelect from 'modules/common/components/_FORM_ELEMENTS/HookForm/form-inputs/FormSelect'
import strings from 'constants/strings'
import {
  debtTypes,
  interestTypes,
  rentableAreaUnits,
  subsectorTypes,
} from 'modules/deal/common/common'
import { InterestType, RentableAreaUnit } from 'graphql/graphqlTypes'

const Residential = ({ control, formState }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={4}>
        <FormInput
          name="yearOfConstruction"
          label={strings.YEAR_OF_CONSTRUCTION}
          control={control}
          formState={formState}
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={8}>
            <FormInput
              id={'deal-summary-rentableAreaValue'}
              name="rentableAreaValue"
              label={`${strings.RENTABLE_AREA}`}
              control={control}
              formState={formState}
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <FormSelect
              name="rentableAreaUnit"
              control={control}
              formState={formState}
              defaultValue={
                rentableAreaUnits.find(
                  ({ value }) => value === RentableAreaUnit.SQFT,
                )?.value
              }
              label={' '}
              options={rentableAreaUnits}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormSelect
          name="subsector"
          control={control}
          formState={formState}
          defaultValue={subsectorTypes[0]}
          label={`${strings.SUBSECTOR}`}
          options={subsectorTypes}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormInput
          id={'deal-summary-occupancy'}
          name="occupancy"
          label={strings.OCCUPANCY}
          inputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
          control={control}
          formState={formState}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormSelect
          name="interest"
          control={control}
          formState={formState}
          defaultValue={
            interestTypes.find(({ value }) => value === InterestType.FEE_SIMPLE)
              ?.value
          }
          label={strings.INTEREST}
          options={interestTypes}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormSelect
          name="debt"
          control={control}
          defaultValue={debtTypes[0]}
          formState={formState}
          label={strings.DEBT}
          options={debtTypes}
        />
      </Grid>
    </Grid>
  )
}

export default Residential
