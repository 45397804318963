// @react
import React, { ReactElement } from 'react'
// @design
import * as SC from 'modules/common/components/_UI_SMART/SharedTable/styledComponents/sharedTable'
import { Grid } from '@material-ui/core'
// @components
import ListFilter from 'modules/common/components/_UI_SMART/SharedTable/Filters/ListFilter'
import useFilters from '../../common/components/_UI_SMART/SharedTable/hook/filters'
// @graphql
import { ApolloQueryResult, QueryResult } from '@apollo/client'
import {
  DealsFilterInput,
  GetDealsQuery,
  GetDealsQueryVariables,
  GetDealTotalsQuery,
  GetDealTotalsQueryVariables,
  ItemsFilterInput,
} from 'graphql/graphqlTypes'

/*******************************************************
 * TYPE
 *******************************************************/

interface HybridQueryResultType
  extends Omit<QueryResult<GetDealsQuery, GetDealsQueryVariables>, 'data'> {
  data: QueryResult<GetDealTotalsQuery, GetDealTotalsQueryVariables>['data']
}

type PropsType = {
  queryResult: HybridQueryResultType
  refetchOverride: (variables: {
    filters?: DealsFilterInput | ItemsFilterInput
  }) => void
}

/**
 *
 * @param changeCriteria
 * @constructor
 */
const DealsTotalsSearch: React.FunctionComponent<PropsType> = ({
  queryResult,
  refetchOverride,
}): ReactElement => {
  /*******************************************************
   * HOOKS
   *******************************************************/

  const { changeCriteria, data } = useFilters({
    queryResult,
    refetchOverride,
  })
  /*******************************************************
   * CONST
   *******************************************************/
  const industryFilters = data.find((el) => el?.key === 'industry')
  const stageFilters = data.find((el) => el?.key === 'stage')
  const countryFilters = data.find((el) => el?.key === 'country')

  /*******************************************************
   * RENDER
   *******************************************************/
  return (
    <SC.Wrap>
      <Grid container spacing={1}>
        <SC.Column item>
          <ListFilter
            type={'industry'}
            filters={industryFilters}
            changeCriteria={changeCriteria}
          />
        </SC.Column>
        <SC.Column item>
          <ListFilter
            type={'stage'}
            filters={stageFilters}
            changeCriteria={changeCriteria}
          />
        </SC.Column>
        <SC.Column item>
          <ListFilter
            type={'country'}
            filters={countryFilters}
            changeCriteria={changeCriteria}
          />
        </SC.Column>
        <SC.Column item />
      </Grid>
    </SC.Wrap>
  )
}

export default DealsTotalsSearch
