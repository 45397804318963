// @react
import React from 'react'
import { FC, useRef, useState } from 'react'
// @design
import * as SC from '../../styledComponents/profileActionDialog'
import { makeStyles } from '@material-ui/core'
// @components
import SwitchboardDialog from 'modules/common/components/_UI_DUMB/ModalDialogs/SwitchboardDialog'
import FileModel from 'modules/document/models/file'
import strings from 'constants/strings'
import useAuth0Wrapper from 'auth/hooks/useAuth0Wrapper'
/*******************************************************
 * CONST
 *******************************************************/
const useStyles = makeStyles(SC.style)

/*******************************************************
 * TYPES
 *******************************************************/

type PropsType = {
  open: boolean
  dialogs: {
    id: string
    title: string
    content: string
    acceptText: string
    declineText: string
    profileImg?: string
  }
  handleAccept: (fileId?: number | undefined) => void
  handleDecline: () => void
}

type OwnState = {
  profileImg: string | undefined
  confirmAction: boolean
  files: File[] | null
  completed: number
}

/**
 *
 * @param id
 * @param title
 * @param acceptText
 * @param declineText
 * @param handleAccept
 * @param handleDecline
 * @param isOpen
 * @constructor
 */
const ProfileActionDialog: FC<PropsType> = ({
  dialogs: { id, title, acceptText, declineText },
  handleAccept,
  handleDecline,
  open: isOpen,
}) => {
  /*******************************************************
   * CONST
   *******************************************************/
  const classes = useStyles()

  /*******************************************************
   * STATE
   *******************************************************/
  const [state, setState] = useState<OwnState>({
    profileImg: '',
    confirmAction: false,
    files: null,
    completed: 0,
  })

  /*******************************************************
   * HOOKS
   *******************************************************/
  const uploadRef = useRef<HTMLInputElement>()
  const { getIdTokenWrapper, getAccessTokenWrapper } = useAuth0Wrapper()

  /*******************************************************
   * FUNCTIONS
   *******************************************************/
  const updateState = (newState: Partial<OwnState>) => {
    setState((prevState) => ({ ...prevState, ...newState }))
  }
  /**
   *
   */
  const handleAcceptClick = (): void => {
    if (id === 'UPDATE_IMAGE' && uploadRef.current) {
      uploadRef.current.click()
    } else {
      handleAccept()
    }
  }

  /**
   *
   * @param event
   */
  const handleFileOnchange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const { files: fileList } = event.currentTarget
    const files = fileList ? [...fileList] : []
    event.stopPropagation()
    event.preventDefault()
    if (files) {
      setState((prevVal) => ({
        ...prevVal,
        profileImg: URL.createObjectURL(files[0]),
        confirmAction: true,
        files: files,
      }))
    }
  }

  /**
   *
   * @param percentCompleted
   */
  const progressHandler = (percentCompleted) => {
    updateState({
      completed: percentCompleted,
    })
  }

  /**
   *
   * @return {Promise<void>}
   */
  const handleFileUpload = async () => {
    const files = state.files
    if (!files) {
      console.log('nothing to upload')
      return
    }
    const accessToken = await getAccessTokenWrapper()
    const idToken = await getIdTokenWrapper()

    const res = await FileModel.uploadFile(
      files,
      accessToken as string,
      idToken as string,
      progressHandler,
    )
    // user avatar only takes one file
    let fileId
    if (res && res.data) {
      fileId = res.data[0].id
    }
    updateState({
      confirmAction: false,
    })
    handleAccept(fileId)
    handleDecline()
  }

  /*******************************************************
   * RENDER
   *******************************************************/
  return (
    <SwitchboardDialog
      className={classes.paper}
      open={isOpen}
      onClose={handleDecline}
      title={title}
      type="kill"
    >
      <SC.HiddenInput
        onChange={handleFileOnchange}
        type="file"
        id="file"
        ref={uploadRef}
      />
      <SC.StyledDialogContent>
        <SC.StyledAvatar src={state.profileImg}>{''}</SC.StyledAvatar>
      </SC.StyledDialogContent>
      {!!state.completed && (
        <SC.LinearProgressWrapper
          color={'primary'}
          variant="determinate"
          value={state.completed}
        />
      )}
      <SC.Actions>
        <SC.CenteredFlex>
          <SC.SelectButton
            variant="contained"
            type="submit"
            color="primary"
            onClick={handleAcceptClick}
          >
            {acceptText}
          </SC.SelectButton>
          <SC.UpdateButton
            variant="contained"
            type="submit"
            color="primary"
            disabled={!state.confirmAction}
            onClick={handleFileUpload}
          >
            {strings.UPDATE_IMAGE}
          </SC.UpdateButton>
        </SC.CenteredFlex>
        <SC.CloseButton type="submit" color="secondary" onClick={handleDecline}>
          {declineText}
        </SC.CloseButton>
      </SC.Actions>
    </SwitchboardDialog>
  )
}

export default ProfileActionDialog
