// @react
import React from 'react'
import styled from 'styled-components'
import { CircularProgress } from '@material-ui/core'
import { SCLoaderWrapper } from './styledComponents/blockingCenteredLoader'

export default function BlockingCenteredLoader() {
  return (
    <SCLoaderWrapper>
      <CircularProgress size={100} />
    </SCLoaderWrapper>
  )
}
