// @react
import React from 'react'
import CenteredLoader from 'modules/common/components/_UI_DUMB/Loaders/CenteredLoader'
import * as ButtonSC from 'modules/common/components/_UI_DUMB/Button/styledComponents'
import { CenteredButtonWrap } from 'modules/common/components/_UI_DUMB/Button/styledComponents'

interface PropsType {
  isVisible: boolean
  isLoading: boolean | undefined
  onClick: () => void
}

const LoadMoreBtn = ({ isVisible = true, isLoading, onClick }: PropsType) => {
  return isVisible ? (
    <ButtonSC.CenteredButtonWrap>
      <ButtonSC.ButtonOrange
        variant="contained"
        color="primary"
        size="large"
        disabled={isLoading}
        onClick={onClick}
      >
        {isLoading ? <CenteredLoader /> : 'LOAD MORE'}
      </ButtonSC.ButtonOrange>
    </ButtonSC.CenteredButtonWrap>
  ) : null
}

export default LoadMoreBtn
