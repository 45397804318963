// @react
import React from 'react'
import { CircularProgress } from '@material-ui/core'
import { SCLoaderWrapper } from './styledComponents/centeredLoader'

interface InjectProps {
  size?: number
}

type PropsType = InjectProps

const CenteredLoader = ({ size }: PropsType) => {
  return (
    <SCLoaderWrapper>
      <CircularProgress size={size || 40} />
    </SCLoaderWrapper>
  )
}

export default CenteredLoader
