// @react
import { ReactElement } from 'react'
import { RouteComponentProps } from 'react-router'
// @common
import UserModel from 'modules/user/models/user'
// @design
import { Grid } from '@material-ui/core'
import * as SC from '../../styledComponents/profileUser'
// @components
import CenteredLoader from 'modules/common/components/_UI_DUMB/Loaders/CenteredLoader'
import NotFound from 'modules/common/pages/NotFound'
import { ProfileContainer } from 'modules/common/styledComponents/Profile'
// @graphql
import { GET_USER_INFO_BY_ID } from 'graphql/queries/user.query'
import { useQuery } from '@apollo/client'
import {
  GetUserInfoByIdQuery,
  GetUserInfoByIdQueryVariables,
} from 'graphql/graphqlTypes'
import useErrorHandler from 'modules/common/hooks/useErrorHandler'

type PropTypes = { id: string }

/**
 *
 * @constructor
 * @return {any}
 */
const ProfileUser = ({
  match,
}: RouteComponentProps<PropTypes>): ReactElement | null => {
  const { id } = match.params

  const { error, data, loading } = useQuery<
    GetUserInfoByIdQuery,
    GetUserInfoByIdQueryVariables
  >(GET_USER_INFO_BY_ID, {
    variables: {
      id,
    },
  })
  useErrorHandler(error)

  if (loading || !data) return <CenteredLoader />

  if (
    !loading &&
    (!data.user ||
      (data.user &&
        !UserModel.checkIsAllMandatoryFieldsProvided<
          GetUserInfoByIdQuery['user']
        >(data.user)))
  ) {
    return <NotFound />
  }

  return (
    <>
      <ProfileContainer>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <SC.StyledAvatar src={data.user?.avatar?.file?.location} />
          </Grid>
          <Grid item xs={12}>
            <SC.Title variant="h3" color="secondary">
              {data.user?.firstName} {data.user?.lastName}
            </SC.Title>
            <SC.Title variant="h4" color="secondary">
              {data.user?.email}
            </SC.Title>
            <SC.Title variant="h4" color="secondary">
              {data.user?.title}
              {data.user?.company && `, ${data.user.company}`}
            </SC.Title>
            <SC.Title variant="h4" color="secondary">
              {data.user?.country}
              {data.user?.state && `, ${data.user.state}`}
              {data.user?.city && `, ${data.user.city}`}
            </SC.Title>
          </Grid>
        </Grid>
      </ProfileContainer>
    </>
  )
}

export default ProfileUser
