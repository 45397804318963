// @react
import React, { useContext, useEffect } from 'react'
import { FC, useRef, useState } from 'react'
// @design
import * as SC from '../../styledComponents/profileActionDialog'
import { Box, Grid, makeStyles } from '@material-ui/core'
import profilePlaceholderImage from 'assets/img/profile-placeholder.png'
// @components
import SwitchboardDialog from 'modules/common/components/_UI_DUMB/ModalDialogs/SwitchboardDialog'
import FileModel from 'modules/document/models/file'
import strings from 'constants/strings'
import useAuth0Wrapper from 'auth/hooks/useAuth0Wrapper'
import { useQuery } from '@apollo/client'
import {
  GetUserInfoQuery,
  GetUserInfoQueryVariables,
} from 'graphql/graphqlTypes'
import { GET_USER_INFO } from 'graphql/queries/user.query'
import { Auth0Context } from '@auth0/auth0-react'
import { UserContext } from 'modules/user/context/user'
import UserModel from 'modules/user/models/user'
import useAsyncEffect from 'use-async-effect'
import { SnackbarTypes } from 'modules/common/components/_UI_DUMB/Snackbars/Snackbar'
import { SnackbarContext } from 'modules/common/context/snackbar'
/*******************************************************
 * CONST
 *******************************************************/
const useStyles = makeStyles(SC.style)

/*******************************************************
 * TYPES
 *******************************************************/

type PropsType = {
  open: boolean
  profileImg?: string
  dialogs: {
    id: string
    title: string
    content: string
    acceptText: string
    declineText: string
  }
  handleAccept: (fileId?: number | undefined) => void
  handleDecline: () => void
}

type OwnState = {
  profileImg: string | undefined
  confirmAction: boolean
  files: File[] | null
  completed: number
}

/**
 *
 * @param id
 * @param title
 * @param acceptText
 * @param declineText
 * @param profileImg
 * @param handleAccept
 * @param handleDecline
 * @param isOpen
 * @constructor
 */
const ProfileUpdateAvatar: FC<PropsType> = ({
  dialogs: { id, title, acceptText, declineText },
  profileImg,
  handleAccept,
  handleDecline,
  open: isOpen,
}) => {
  /*******************************************************
   * CONST
   *******************************************************/
  const classes = useStyles()
  /*******************************************************
   * STATE
   *******************************************************/
  const [state, setState] = useState<OwnState>({
    profileImg,
    confirmAction: false,
    files: null,
    completed: 0,
  })
  const [socialText, setSocialText] = useState<string | undefined>()
  const [socialAvatar, setSocialAvatar] = useState<any>()

  /*******************************************************
   * CONTEXT
   *******************************************************/
  const authContext = useContext(Auth0Context)
  const userContext = useContext(UserContext)
  const snackbar = useContext(SnackbarContext)

  /*******************************************************
   * GRAPHQL
   *******************************************************/
  /**
   * define queries
   */
  const { data, loading, refetch } = useQuery<
    GetUserInfoQuery,
    GetUserInfoQueryVariables
  >(GET_USER_INFO, {
    fetchPolicy: 'cache-only',
  })

  /*******************************************************
   * HOOKS
   *******************************************************/
  const uploadRef = useRef<HTMLInputElement>()
  const { getIdTokenWrapper, getAccessTokenWrapper } = useAuth0Wrapper()

  /*******************************************************
   * LIFE CYCLE HOOKS
   *******************************************************/
  useEffect(() => {
    updateState({ profileImg: data?.user?.avatar?.file?.location || '' })
  }, [data?.user?.avatar?.file?.location])

  useAsyncEffect(async () => {
    if (authContext.user?.picture) {
      await fetch(authContext.user?.picture)
        .then((res) => res.blob()) // Gets the response and returns it as a blob
        .then((blob) => {
          setSocialAvatar(blob)
        })
        .catch((error) => {
          console.log(error)
        })
    }
  }, [authContext.user?.picture])

  useEffect(() => {
    const socialName = UserModel.getSocialName(userContext.user)
    if (!socialName) return
    setSocialText('Import from ' + socialName)
  }, [userContext.user?.provider])

  /*******************************************************
   * FUNCTIONS
   *******************************************************/
  const updateState = (newState: Partial<OwnState>) => {
    setState((prevState) => ({ ...prevState, ...newState }))
  }
  /**
   *
   */
  const handleAcceptClick = (): void => {
    if (id === 'UPDATE_IMAGE' && uploadRef.current) {
      uploadRef.current.click()
    } else {
      handleAccept()
    }
  }

  /**
   *
   * @param event
   */
  const handleFileOnchange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ): void => {
    const { files: fileList } = event.currentTarget
    const files = fileList ? [...fileList] : []
    event.stopPropagation()
    event.preventDefault()
    if (files) {
      setState((prevVal) => ({
        ...prevVal,
        profileImg: URL.createObjectURL(files[0]),
        confirmAction: true,
        files: files,
      }))
    }
  }

  /**
   *
   * @param percentCompleted
   */
  const progressHandler = (percentCompleted) => {
    updateState({
      completed: percentCompleted,
    })
  }

  /**
   *
   */
  const setImageFromSocials = () => {
    const files = [
      new File([socialAvatar], 'social.jpg', { type: socialAvatar.type }),
    ]
    setState((prevVal) => ({
      ...prevVal,
      profileImg: URL.createObjectURL(files[0]),
      confirmAction: true,
      files: files,
    }))
  }

  /**
   *
   * @return {Promise<void>}
   */
  const handleFileUpload = async () => {
    let fileId = undefined
    const files = state.files
    const accessToken = await getAccessTokenWrapper()
    const idToken = await getIdTokenWrapper()
    if (files) {
      const res = await FileModel.uploadFile(
        files,
        accessToken as string,
        idToken as string,
        progressHandler,
      )

      // user avatar only takes one file
      switch (res.status) {
        case 200:
          fileId = res.data[0].id
          handleAccept(fileId)
          updateState({
            confirmAction: false,
          })
          handleDecline()
          break
        default:
          console.log(data)
          updateState({
            completed: 0,
          })
          snackbar.setMessage({
            type: SnackbarTypes.ERROR,
            message: res.data,
            show: true,
            anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
          })
          break
      }
    }
  }

  /*******************************************************
   * RENDER
   *******************************************************/
  return (
    <SwitchboardDialog
      className={classes.paper}
      open={isOpen}
      onClose={handleDecline}
      title={title}
      type="kill"
    >
      <SC.HiddenInput
        onChange={handleFileOnchange}
        type="file"
        id="file"
        ref={uploadRef}
      />
      <SC.StyledDialogContent>
        <Grid container justifyContent="center" spacing={3}>
          <Grid item>
            <SC.StyledAvatar src={state.profileImg || profilePlaceholderImage}>
              {''}
            </SC.StyledAvatar>
          </Grid>
          <Box display="flex" alignItems={'center'}>
            <SC.SelectButtonGroup>
              {socialText && (
                <SC.SelectButton
                  variant="contained"
                  type="submit"
                  color="primary"
                  onClick={setImageFromSocials}
                >
                  {socialText}
                </SC.SelectButton>
              )}
              <SC.SelectButton
                variant="contained"
                type="submit"
                color="primary"
                onClick={handleAcceptClick}
              >
                {acceptText}
              </SC.SelectButton>
              <SC.RemoveButton
                variant="contained"
                type="submit"
                color={'primary'}
                onClick={() => {
                  updateState({
                    profileImg: '',
                    confirmAction: true,
                    files: null,
                  })
                }}
              >
                {strings.REMOVE_IMAGE}
              </SC.RemoveButton>
            </SC.SelectButtonGroup>
          </Box>
        </Grid>
      </SC.StyledDialogContent>
      {!!state.completed && (
        <SC.LinearProgressWrapper
          color={'primary'}
          variant="determinate"
          value={state.completed}
        />
      )}
      <SC.Actions>
        <SC.CenteredFlex>
          <SC.UpdateButton
            variant="contained"
            type="submit"
            color={'primary'}
            disabled={!state.confirmAction}
            onClick={handleFileUpload}
          >
            {strings.SAVE}
          </SC.UpdateButton>
        </SC.CenteredFlex>
      </SC.Actions>
    </SwitchboardDialog>
  )
}

export default ProfileUpdateAvatar
