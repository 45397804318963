import {
  DealActions,
  DealNodeFragment,
  DealStage,
  DealStatus,
  DealType,
  GetDealInfoQuery,
  GetDealTeamMembersQuery,
  Scalars,
  SimplifiedUser,
  User,
} from 'graphql/graphqlTypes'
import { RouteNames } from 'constants/routeNames'
import TeamModel from 'modules/deal/models/team'

export type AllDealStage = {
  stage: DealStage
  order: number
  label: DealStageLabels
}
type AllDealStages = AllDealStage[]

export enum DealStageLabels {
  CLOSED = 'Closed',
  DUE_DILIGENCE = 'Due Diligence',
  POST_CLOSING = 'Post Closing',
  PRELIM = 'Draft',
  REVIEW = 'Review',
  UNDERWRITING = 'Underwriting',
  CLOSING = 'Closing',
}

export enum DealStatusLabels {
  PENDING = 'Pending',
  OPEN = 'Live',
  CLOSED = 'Closed',
  DRAFT = 'Draft',
  DEAD = 'Dead',
  DECLINED = 'Declined',
  FINALIZED = 'Closed (finalized)',
}

/**
 *
 */
export default class DealModel {
  private readonly entity

  constructor() {
    this.entity = 'DEAL'
  }

  /**
   * static properties
   */
  public static allDealStages = [
    {
      stage: DealStage.PRELIM,
      order: 0,
      label: DealStageLabels.PRELIM,
    },
    {
      stage: DealStage.REVIEW,
      order: 1,
      label: DealStageLabels.REVIEW,
    },
    {
      stage: DealStage.UNDERWRITING,
      order: 2,
      label: DealStageLabels.UNDERWRITING,
    },
    {
      stage: DealStage.DUE_DILIGENCE,
      order: 3,
      label: DealStageLabels.DUE_DILIGENCE,
    },
    {
      stage: DealStage.CLOSING,
      order: 4,
      label: DealStageLabels.CLOSING,
    },
    {
      stage: DealStage.CLOSED,
      order: 5,
      label: DealStageLabels.CLOSED,
    },
  ] as AllDealStages

  /**
   *
   * @param stage
   */
  public static getStageLabel(stage: DealStage): string {
    return this.allDealStages.reduce((accumulator, currentKey) => {
      return currentKey.stage === stage ? currentKey.label : accumulator
    }, '')
  }

  public static getStagesArrayByOrder(): DealStage[] {
    return this.allDealStages.map((stage) => {
      return stage.stage
    })
  }

  /**
   *
   * @param stage
   */
  public static getStageOrder(stage: DealStage): number {
    return this.allDealStages.reduce((accumulator, currentKey) => {
      return currentKey.stage === stage ? currentKey.order : accumulator
    }, 0)
  }

  /**
   *
   */
  public static getStageViewStages(): DealStage[] {
    return this.getStagesArrayByOrder().filter((item) => {
      return item !== DealStage.PRELIM
    })
  }

  /**
   *
   * @param dealStatus
   * @param dealId
   * @returns {string}
   */
  public static dealStatusRouter(
    dealStatus: DealStatus,
    dealId: Scalars['ID'],
  ): string {
    switch (dealStatus) {
      case DealStatus.OPEN:
      case DealStatus.DECLINED:
      case DealStatus.DEAD:
        return `/${RouteNames.DEALS}/${RouteNames.DETAILS}/${dealId}/${RouteNames.ITEMS}`
      case DealStatus.PENDING:
      case DealStatus.DRAFT:
      default:
        return `/${RouteNames.DEALS}/${RouteNames.DETAILS}/${dealId}/${RouteNames.SUMMARY}`
    }
  }

  /**
   *
   * @param deal
   * // @todo should this be a DEAL ACTION?
   */
  public static isDealDraft(deal): boolean {
    return !(deal?.status !== 'DRAFT' && deal?.status !== 'PENDING')
  }

  /**
   * draft is not really a stage
   * we use term synthetic here really to just mean UI label
   * @param stage
   */
  public static syntheticStage(stage?: DealStage | null): string {
    if (!stage) return ''

    if (stage === DealStage.PRELIM) {
      return 'Draft'
    }
    return stage
  }

  /**
   *
   * @param status
   * @param stage
   * see also: https://docs.google.com/document/d/143xbPW8HgRc6_5qWlGwbXJMT8epDWKwvNXb4GUZ9alM/edit#
   */
  public static syntheticStatus(
    status?: DealStatus | null,
    stage?: DealStage | null,
  ) {
    if (!status || !stage) return ''

    // return DealStatusLabels[status]

    if (status === DealStatus.OPEN && stage === DealStage.CLOSED) {
      // disable this logic as part of https://littlebox.atlassian.net/browse/SP1-1253
      return DealStatusLabels[status]
      // return 'Closed'
    }
    return DealStatusLabels[status]
  }

  /**
   *
   * @param status
   * @param stage'
   * deal remains editable when in mos states, but we will toggle the label to inactive when stage is CLOSED (deal technically remains OPEN/live)
   */
  public static isDealActive(
    status?: DealStatus | null,
    stage?: DealStage | null,
  ) {
    if (
      stage === DealStage.CLOSED ||
      status === DealStatus.DECLINED ||
      status === DealStatus.DEAD
    ) {
      return false
    }
    return true
  }

  /**
   *
   * @returns {{next: any, prev: any}}
   * @param deal
   */
  public static calculatePrevNextStage(deal: DealNodeFragment): {
    prev: AllDealStage | null
    next: AllDealStage | null
  } {
    let order: number
    const { stage } = deal
    const foundItem = this.allDealStages.find((item) => {
      return item.stage === stage
    })

    if (foundItem) {
      order = foundItem.order
    }

    const prevDealStage =
      this.allDealStages.find((item) => {
        return item.order === order - 1
      }) || null

    const nextDealStage =
      this.allDealStages.find((item) => {
        return item.order === order + 1
      }) || null

    return {
      prev: prevDealStage,
      next: nextDealStage,
    }
  }
  /*******************************************************
   * PERMISSION HELPERS
   *******************************************************/

  /**
   *
   * @param {GetDealInfoQuery["deal"]} deal
   * @returns {boolean}
   */
  public static canRemoveSelfFromTeam = (
    deal?: GetDealInfoQuery['deal'],
  ): boolean => {
    if (!deal) return false
    return !!deal.actions?.find((el) => el === DealActions.DEAL_REMOVE_SELF)
  }

  /**
   *
   * @param user
   * @param currentUser
   * @param {GetDealInfoQuery["deal"]} deal
   * @returns {boolean}
   */
  public static canRemoveUserFromTeam = (
    user,
    currentUser: User,
    deal?: GetDealInfoQuery['deal'],
  ): boolean => {
    if (!deal) return false
    // if (user team id !== my team id)
    const teamMembership = TeamModel.calculateTeamMembership(deal)
    // is current user the same as uer being removed?
    const isSameUser = currentUser?.id === user?.id
    const canPerformAction = !!deal.actions?.find(
      (el) => el === DealActions.DEAL_REMOVE_TEAM_MEMBER,
    )
    const sameTeam = teamMembership?.myTeamId === user.userDeal?.teamId
    return !isSameUser && canPerformAction && sameTeam
  }

  /**
   *
   * @param {GetDealInfoQuery["deal"]} deal
   * @returns {boolean}
   */
  public static canCancelTeamInvite = (
    deal?: GetDealInfoQuery['deal'],
  ): boolean => {
    if (!deal) return false
    return !!deal.actions?.find(
      (el) => el === DealActions.DEAL_REMOVE_TEAM_MEMBER,
    )
  }

  /**
   *
   * @param currentUser
   * @param user
   * @param {GetDealInfoQuery["deal"]} deal
   * @returns {boolean}
   */
  public static canMigrateTeamLead = (
    currentUser: User,
    user: SimplifiedUser,
    deal?: GetDealInfoQuery['deal'],
  ): boolean => {
    if (!deal) return false
    const teamMembership = TeamModel.calculateTeamMembership(deal)
    // is team lead?
    const isCurrentUserLead =
      teamMembership?.myTeamLeadUserId === currentUser?.id
    // is user on same team?
    const sameTeam = teamMembership?.myTeamId === user.userDeal?.teamId

    // is current user the same as uer being removed?
    const isSameUser = currentUser?.id === user?.id
    return isCurrentUserLead && !isSameUser && sameTeam
  }
}
