import { createContext } from 'react'
import { DealStage } from '../../../../graphql/graphqlTypes'
import { GetItemUsersQuery } from 'graphql/graphqlTypes'
import {
  EditCommentWrapperProps,
  EditCommentTextProps,
} from 'modules/item/pages/ItemSingle/components/ItemComments'

interface ItemContextValue {
  stage: null | DealStage
}

export const ItemContext = createContext<ItemContextValue>({
  stage: null,
})

export type ItemEditCommentProps = {
  editedCommentText: EditCommentTextProps
  editHandler: (data: EditCommentWrapperProps) => Promise<void>
  submitHandler: (inputData: any) => Promise<void>
  userMentions: GetItemUsersQuery['userItem']['data']
  cancelEditing: () => void
  parentEntityId: string
}

export const ItemEditCommentContext =
  createContext<null | ItemEditCommentProps>(null)
