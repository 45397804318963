// @react
import { ReactElement } from 'react'
import { Route, withRouter } from 'react-router'
// @common
import { QueryResult, useQuery } from '@apollo/client'
// @components
import * as SCGlobal from 'modules/common/styledComponents/Global'
import DealsTabs from './components/DealsTabs'
import { RouteNames } from 'constants/routeNames'
import DealsFiltered from 'modules/deal/pages/DealsFiltered'
import DealsInvites from 'modules/deal/pages/DealsInvites'
// @graphql
import { GET_DEALS } from 'graphql/queries/deal.query'
import {
  DealsOrderByList,
  GetDealsQuery,
  GetDealsQueryVariables,
  InviteStatus,
  Order,
} from 'graphql/graphqlTypes'

/**
 *
 * @constructor
 * @return {any}
 */
const Deals = (): ReactElement => {
  /*******************************************************
   * GRAPHQL
   *******************************************************/
  const queryResult: QueryResult<GetDealsQuery, GetDealsQueryVariables> =
    useQuery<GetDealsQuery, GetDealsQueryVariables>(GET_DEALS, {
      variables: {
        orderBy: {
          order: Order.DESC,
          value: DealsOrderByList.CREATED_DATE,
        },
      },
    })

  const tabs = (
    <>
      <Route path={`/${RouteNames.DEALS}`} exact component={DealsFiltered} />
      <Route
        path={`/${RouteNames.DEALS}/${RouteNames.INVITES_PENDING}`}
        exact
        render={() => (
          <DealsInvites staticProps={{ filter: InviteStatus.RECEIVED }} />
        )}
      />
      <Route
        exact
        path={`/${RouteNames.DEALS}/${RouteNames.INVITES_SENT}`}
        render={() => (
          <DealsInvites staticProps={{ filter: InviteStatus.SENT }} />
        )}
      />
    </>
  )
  /**
   *
   */
  return (
    <SCGlobal.ContentContainer maxWidth="large">
      <SCGlobal.PushContainer>
        <DealsTabs queryResult={queryResult} />
      </SCGlobal.PushContainer>
      {tabs}
    </SCGlobal.ContentContainer>
  )
}

export default withRouter(Deals)
