// @react
import React from 'react'
import { ReactNode } from 'react'
// @design
import * as SC from './styledComponents'
import * as ButtonsSC from 'modules/common/components/_UI_DUMB/Button/styledComponents'

interface PropsType {
  testId?: string
  title: string
  children: ReactNode
  noPadding?: boolean
  bodyProps?: {
    bodyTextSize?: string
  }
  footerBtn?: {
    onClick: () => void
    title: string
    disabled?: boolean
  }
}

/**
 *
 * @param title
 * @param children
 * @param noPadding
 * @param bodyProps
 * @param footerBtn
 * @param testId
 * @constructor
 */
const Card = ({
  title,
  children,
  noPadding,
  bodyProps,
  footerBtn,
  testId,
}: PropsType) => {
  return (
    <SC.Card data-testid={testId}>
      <SC.CardHeader>{title}</SC.CardHeader>
      <SC.CardBody noPadding={noPadding} {...bodyProps}>
        {children}
        {footerBtn && (
          <SC.Footer>
            <ButtonsSC.ButtonOrange
              variant="contained"
              size="small"
              color="primary"
              onClick={footerBtn.onClick}
              disabled={footerBtn.disabled}
            >
              {footerBtn.title}
            </ButtonsSC.ButtonOrange>
          </SC.Footer>
        )}
      </SC.CardBody>
    </SC.Card>
  )
}

export default Card
