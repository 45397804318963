import styled from 'styled-components'
import {
  dashboardRowBasic,
  flexCenter,
  spaceBetweenCenter,
} from 'modules/common/styledComponents/Mixins'
import { ActiveDealsHeader } from 'modules/common/styledComponents/Dashboard'
import colors from 'constants/colors'
import { device, from, to } from 'modules/common/styledComponents/MediaQueries'
import { Typography } from '@material-ui/core'

export const Wrap = styled.div`
  position: relative;
  background-color: ${colors.white};
`

export const Container = styled.div`
  && {
    ${flexCenter}
  }
  border: 1px solid ${colors.veryLightBlue};
  flex-direction: row;
  justify-content: space-between;

  ${to(600)} {
    padding: 0 20px;
  }
`

export const InlineFormColumn = styled.div`
  &:first-child {
    padding-left: 0;
  }

  ${to(device.mobileL)} {
    padding-left: 0;
  }
`

export const Label = styled(InlineFormColumn)`
  flex-basis: 50%;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0.35px;
  ${to(device.mobileL)} {
    padding-right: 2.5em;
  }
`

export const Value = styled(InlineFormColumn)`
  && {
    ${flexCenter}
  }
  min-height: 48px !important;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 600 !important;
  justify-content: flex-end;
  ${from(device.mobileL)} {
    flex-basis: 90%;
    padding-right: 40em;
  }
`
export const Field = styled.div`
  ${dashboardRowBasic('0px 32px')};
  ${spaceBetweenCenter};
  position: relative;

  ${to(device.mobileL)} {
    padding: 0.8em;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
  }

  ${(props) =>
    !props.isactive &&
    `cursor: default;
      `}
  &:hover {
    ${(props) =>
      !props.isactive &&
      `background: none;
      cursor: auto;
      `}
  }
`
export const InlineFormContent = styled.div`
  background-color: ${colors.white};
`

export const Title = styled(Typography)`
  && {
    display: flex;
    justify-content: flex-start;
    font-weight: 600;
    color: ${colors.dark};
  }
`

export const HeaderInner = styled.div`
  ${flexCenter};
`

export const ActiveValue = styled.div`
  padding: 0px 32px !important;
  justify-content: flex-start;
  flex-basis: 50%;
  justify-content: space-between;
  display: flex;
  ${(props) =>
    !props.isactive &&
    `display: none;
    `}
`

export const InlineActions = styled.div`
  padding: 20px 0px 20px 32px;
  border: 1px solid ${colors.veryLightBlue};
  border-top: none;
  & button {
    background-color: ${colors.yellowishOrange};
    color: ${colors.white};
    margin-right: 10px !important;
    &:hover {
      background-color: rgb(168, 117, 7);
    }
  }
`

export const InlineDoubleStyled = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  .MuiFormControl-root {
    width: 95% !important;
  }
`

export const FieldLabel = styled(Typography)`
  && {
    font-weight: 400;
  }
`

export const Header = styled(ActiveDealsHeader)`
  ${to(device.tabletS)} {
    flex-direction: column;
    align-items: flex-start;
  }
`
