// @react
import React from 'react'
import DocumentTitle from 'react-document-title'
import strings from 'constants/strings'
import parse from 'html-react-parser'
import * as SC from 'modules/common/styledComponents/NetworkError'

/**
 *
 * @returns {JSX.Element}
 * @constructor
 */
const NotAuthorized = () => (
  <>
    <DocumentTitle title="Not Authorized | Switchboard">
      <SC.Wrap>
        <SC.Title>{parse(strings.NOT_AUTHORIZED)}</SC.Title>
        <div />
      </SC.Wrap>
    </DocumentTitle>
  </>
)

export default NotAuthorized
