// language=GraphQL
import { gql } from '@apollo/client'
import { COMMENT } from '../fragments/comment.fragment'

export const COMMENT_CREATE_SUBSCRIPTION = gql`
  subscription onCommentCreate {
    commentCreated {
      comment {
        ...CommentNode
        item {
          id
        }
      }
      userMessage {
        id
        type
        msg
      }
      createdBy {
        id
        lastName
        firstName
        avatar {
          id
          file {
            id
            location
          }
        }
      }
    }
  }
  ${COMMENT}
`

export const COMMENT_UPDATE_SUBSCRIPTION = gql`
  subscription onCommentUpdate {
    commentUpdated {
      comment {
        id
        modifiedDate
        body
      }
    }
  }
`

export const COMMENT_DELETE_SUBSCRIPTION = gql`
  subscription onCommentDelete {
    commentDeleted {
      comment {
        id
        item {
          id
        }
      }
    }
  }
`
