import { Avatar, Typography } from '@material-ui/core'
import { colors } from 'constants/colors'
import styled from 'styled-components'
import { avatarImage, flexCenter } from 'modules/common/styledComponents/Mixins'
import MessagesTextarea from 'modules/common/components/_FORM_ELEMENTS/Message/MessagesTextarea'

export const StyledAvatar = styled(Avatar)`
  && {
    ${avatarImage}
  }
`

export const Name = styled(Typography)`
  && {
    margin-right: 10px;
  }
`

export const FromTime = styled(Typography)`
  && {
    font-size: 10px;
    font-style: italic;
    color: ${colors.lightGreyBlue};
  }
`

export const Text = styled(Typography)`
  && {
    font-size: 12px;
    color: ${colors.dark};
  }
`

export const FlexCenter = styled.div`
  ${flexCenter};
`

export const Wrap = styled(FlexCenter)`
  flex: 2;
`

export const RightColumn = styled.div`
  flex-grow: 1;
`

export const Menu = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
`

export const MenuItem = styled.span`
  margin-right: 15px;
  font-size: 10px;
  text-decoration: underline;
  color: ${colors.battleshipGrey};
  transition: 0.2s all ease;

  &:hover {
    color: ${colors.yellowishOrange};
  }
`

export const EditMessageTextarea = styled(MessagesTextarea)`
  margin-top: 10px;
  padding: 0;
`
