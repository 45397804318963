import { gql } from '@apollo/client'

export const DOCUMENT_FRAGMENT = gql`
  fragment DocumentNode on Document {
    id
    name
    createdDate
    modifiedDate
    finalized
    file {
      id
      originalName
      location
      mimetype
      size
      contentType
      extension
      createdBy {
        id
      }
      versionId
      createdDate
      actions
    }
  }
`

export const DOCUMENT_SIMPLIFIED_FRAGMENT = gql`
  fragment SimpleDocument on Document {
    id
    name
    finalized
  }
`
