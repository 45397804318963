import config from 'constants/config'

export default class GlobalHelperModel {
  /**
   *  ID generator
   * @returns {string}
   */
  public static get id(): string {
    let text = ''
    const possible =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'

    for (let i = 0; i < 20; i++) {
      text += possible.charAt(Math.floor(Math.random() * possible.length))
    }

    return text
  }

  /**
   *  nameToLowDash
   *
   * convert string to machine safe format
   * @param str
   * @returns {string}
   */
  // public static nameToLowDash(str: string) {
  //   return str.toLowerCase().split(' ').join('_')
  // }

  /**
   * formatBytes
   * helper to convert raw bytes int to human readable values
   * @param bytesValue
   * @param decimalPlace
   * @returns {string}
   */
  public static formatBytes(bytesValue: number, decimalPlace?: number) {
    if (0 === bytesValue) return '0'
    const c = 1024
    const d = decimalPlace || 2
    const e = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
    const f = Math.floor(Math.log(bytesValue) / Math.log(c))
    return (
      String(parseFloat((bytesValue / Math.pow(c, f)).toFixed(d))) + ' ' + e[f]
    )
  }

  /**
   * convert base 64 to blob
   * @param b64Data
   * @param contentType
   * @param sliceSize
   * @returns {Blob}
   */
  // public static b64toBlob(b64Data, contentType, sliceSize) {
  //   contentType = contentType || ''
  //   sliceSize = sliceSize || 512
  //
  //   const byteCharacters = atob(b64Data)
  //   const byteArrays: Uint8Array[] = []
  //
  //   for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
  //     const slice = byteCharacters.slice(offset, offset + sliceSize)
  //
  //     const byteNumbers = new Array(slice.length)
  //     for (let i = 0; i < slice.length; i++) {
  //       byteNumbers[i] = slice.charCodeAt(i)
  //     }
  //
  //     byteArrays.push(new Uint8Array(byteNumbers))
  //   }
  //
  //   return new Blob(byteArrays, { type: contentType })
  // }

  /**
   * objToArray
   *
   * convert object to array
   * this requires that object and resulting array has uniform child type
   * @param obj
   * @returns {any[]}
   */
  // public static objToArray<T>(obj?: null | { [key: string]: T[] }): T[] {
  //   return obj ? Object.keys(obj).map((key) => obj[key]) : []
  // }

  public static objToArray<T>(obj?: null | { [key: string]: T[] }): T[] {
    const myArray: T[] = []
    if (!obj) return myArray

    const map = Object.keys(obj).map((key) => obj[key])

    myArray.concat(...map)
    return myArray
  }

  /**
   * string truncator
   * @param text
   * @param limit
   * @returns {string | any}
   */
  public static truncateText(text: string, limit: number): string {
    if (text.length > limit) {
      for (let i = limit; i > 0; i--) {
        if (
          text.charAt(i) === ' ' &&
          (text.charAt(i - 1) !== ',' ||
            text.charAt(i - 1) !== '.' ||
            text.charAt(i - 1) !== ';')
        ) {
          return text.substring(0, i) + '...'
        }
      }
      return text.substring(0, limit) + '...'
    } else return text
  }

  /**
   * normalize Words
   * @param text
   */
  public static normalizeWords = (text?: string | null): string | null => {
    if (!text) return null

    const formattedText = text
      .toLowerCase()
      .replace('_', ' ')
      .split(' ')
      .map((s) => s.charAt(0).toUpperCase() + s.substring(1))
      .join(' ')

    return formattedText === 'Prelim' ? 'Preliminary Analysis' : formattedText
  }

  /**
   *
   * @param arr
   * @param indexedKeys
   * @param isPrioritizeFormer
   */
  // public static distinct = (arr, indexedKeys, isPrioritizeFormer = true) => {
  //   const lookup = new Map()
  //   const makeIndex = (el) =>
  //     indexedKeys.reduce((index, key) => `${index};;${el[key]}`, '')
  //   arr.forEach((el) => {
  //     const index = makeIndex(el)
  //     if (lookup.has(index) && isPrioritizeFormer) {
  //       return
  //     }
  //     lookup.set(index, el)
  //   })
  //
  //   return Array.from(lookup.values())
  // }

  /**
   *
   * @param arr
   */
  public static generateStringFromItems = (
    arr: (string | undefined | null)[],
  ): string => {
    return arr.reduce((listedItems: string, item) => {
      if (item) {
        return listedItems ? `${listedItems}, ${item}` : item
      }
      return listedItems
    }, '')
  }

  /**
   *
   * @param string
   */
  public static checkOnEmptiness = (
    string: string | null | undefined,
  ): string => {
    return string || config.emptinessSymbol
  }

  /**
   *
   * @param value
   */
  public static setThousandsSeparator(value: number) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  }

  /**
   *
   * @param value
   */
  public static roundToInteger(value: number) {
    return Math.round(value)
  }

  /**
   *
   * @param value
   */
  public static getCleanNumber(value: number) {
    return value ? this.setThousandsSeparator(this.roundToInteger(value)) : ''
  }

  /**
   *
   * @param word
   */
  public static capitalize = (word: string): string => {
    const [first, ...rest] = word
    return String(first.toUpperCase()) + String(rest.join('').toLowerCase())
  }

  /**
   *
   * @param value
   */
  public static safeTotoLowerCase = (
    value?: string | number | null,
  ): string | number | null | undefined => {
    if (value && typeof value === 'string') {
      return value.toLowerCase()
    }
    return value
  }

  public static cleanStrArray = (
    value: (null | undefined | string)[],
  ): string[] => {
    return value.filter((obj: null | undefined | string): obj is string => {
      return typeof obj === 'string'
    })
  }
}
