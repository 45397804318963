import { Typography } from '@material-ui/core'
import styled from 'styled-components'

export const Wrap = styled.div`
  flex: 1;
`

export const Subtitle = styled(Typography)`
  && {
    margin: 17px 0;
  }
`

export const DNDWrap = styled.div`
  margin-bottom: 12px;
`
