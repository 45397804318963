import { IconButton, Typography } from '@material-ui/core'
import styled from 'styled-components'
import { colors } from 'constants/colors'
import {
  dashboardRowBasic,
  flexCenter,
  spaceBetweenCenter,
} from 'modules/common/styledComponents/Mixins'
import { device, from, to } from 'modules/common/styledComponents/MediaQueries'
import { ItemStatus } from 'modules/common/types'
import People from '@material-ui/icons/People'

export const style = () => ({
  inviteBtn: {
    marginRight: '10px',
    padding: '8px 32px',
    fontSize: '10px',
  },
  cancelIcon: {
    transform: 'rotate(45deg)',
    fontSize: '21px',
    color: colors.grapefruit,
  },
  name: {
    marginRight: 15,
  },
  typeIcon: {
    marginRight: 4,
    width: 16,
    height: 16,
  },
})

export const ContactTextWrap = styled.div`
  min-height: 21px;
`

export const ContactText = styled(Typography)`
  && {
    display: inline;
    font-size: 12px;
    ${from(device.ipadL)} {
      &:not(:last-child)::after {
        margin-right: 0.3em;
      }
    }
  }
`

export const FlexCenter = styled.div`
  ${flexCenter};
`

export const Link = styled.a`
  display: flex;
  text-decoration: none;
`

export const Wrap = styled.div`
  ${flexCenter};
  flex: 2;

  ${to(device.ipadL)} {
    align-items: flex-start;
  }
`
/**
 * TAG
 */
export const TagWrap = styled.div`
  display: flex;
`

export const TagWrapDesktop = styled(TagWrap)`
  ${to(device.ipad)} {
    display: none;
  }
`

export const TagWrapMobile = styled(TagWrap)`
  margin-top: 0.3em;

  &:empty {
    display: none;
  }

  ${from(device.ipad)} {
    display: none;
  }
`
/**
 * ICON
 */
export const AddIconButton = styled(IconButton)`
  && {
    padding: 2px;
    background-color: ${colors.yellowishOrange};
    color: ${colors.white};

    &:hover {
      background-color: ${colors.yellowishOrangeHover};
    }
  }

  svg {
    font-size: 16px;
  }
`

export const StyledPeopleIcon = styled(People)`
  height: 15px;
  top: 5px;
  right: 5px;
  color: ${colors.lightGreyBlue};
  z-index: 1;
`

/*******************************************************
 * MIGRATED FROM DASHBOARD
 *******************************************************/
export const ContactRequestItem = styled.div`
  ${spaceBetweenCenter};
  ${dashboardRowBasic('15px 30px')};
  position: relative;
  background-color: ${colors.white};

  ${to(device.tabletL)} {
    padding: 0.8em;
    align-items: flex-start;
    flex-direction: column;
  }
`

export const ContactRequestItemActions = styled.div`
  transition: 0.2s ease;

  ${to(device.tabletL)} {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 0.5em;
    padding-left: 70px;
  }

  ${from(device.tabletL)} {
    margin-left: 1em;
    padding-right: 35px;
  }

  ${from(device.ipadL)} {
    opacity: 0;

    ${ContactRequestItem}:hover & {
      opacity: 1;
    }

    ${(props) =>
      props.active &&
      `
      opacity: 1;
    `}
  }
`

/*******************************************************
 * used in pending contacts
 *******************************************************/
export const Actions = styled.div`
  & ${ContactRequestItemActions} {
    opacity: 1;
    padding-right: 0px;
    .MuiSvgIcon-root {
      color: ${colors.lightGreyBlue};
    }
    button:hover {
      background-color: ${colors.veryDarkOrange};
    }
  }
`
/*******************************************************
 * Contact item
 *******************************************************/
export const StyledContactRequestItem = styled(ContactRequestItem)`
  height: 100%;

  /*
  ${(props: { itemStatus: ItemStatus; isAdded: boolean }) =>
    (props.itemStatus === ItemStatus.CONNECTED || props.isAdded) &&
    `
    background-color: ${colors.paleGrey}
  `};
*/

  ${(props: { disabled: boolean }) =>
    props.disabled &&
    `
    background-color: ${colors.darkenBlue}

    &:hover {
      pointer-events: all !important;
      cursor: not-allowed !important;
      background-color: ${colors.darkenBlue}
    }
  `};
`

export const ContactWrap = styled.div`
  width: 100%;
  & ${StyledContactRequestItem}:hover {
    background-color: ${colors.darkenBlue};
  }
  ${from(device.tablet)} {
    width: 100%;
    display: flex;
    flex-direction: column;

    > * {
      height: 100%;
    }
  }
`

export const ContactListWrap = styled.div`
  display: flex;
  align-items: stretch;
  flex-wrap: wrap;
  /* background-color: ${colors.paleGrey}; */
  border-top: 1px solid #e5e9ed;
`

/**
 * for in modal dialogs only
 */
export const DialogContactList = styled.div`
  max-height: 80vh;
  margin-bottom: 50px;
  overflow: auto;
  border-top: 1px solid ${colors.veryLightBlue};
  border-bottom: 1px solid ${colors.veryLightBlue};
`

export const DialogContactListWrap = styled.div`
  margin-bottom: 50px;
`

/*******************************************************
 * used in create item
 *******************************************************/
export const ContactItemWrap = styled.div`
  ${({ rows }) =>
    rows &&
    `
    width: ${100 / rows}%;
    border-top: 1px solid ${colors.veryLightBlue};
  `}
`
