// @react
import { useContext } from 'react'
// @design
import { Grid } from '@material-ui/core'
import * as ButtonsSC from 'modules/common/components/_UI_DUMB/Button/styledComponents'

// @constants
import strings from 'constants/strings'
// @graphql
import {
  DealActions as DealActionsEnum,
  DealStage,
  DealStageDirection,
} from 'graphql/graphqlTypes'
import { DealContext } from 'modules/deal/contexts/deal'
import { DealStepperContext } from 'modules/deal/contexts/dealStepper'

/**
 *
 * @constructor
 */
const DealMigrateActions = () => {
  /*******************************************************
   * CONTEXT
   *******************************************************/
  const queryResult = useContext(DealContext)
  const { setDirection, dealStage, moveStage } = useContext(DealStepperContext)
  const { data } = queryResult
  const stage = dealStage || data?.deal.stage
  /*******************************************************
   * FUNCTIONS
   *******************************************************/
  /**
   *
   * @param direction
   */
  const clickHandlerWrapper = (direction: DealStageDirection) => {
    setDirection && setDirection(direction)
    // passed from props, this toggles visibility
    moveStage(direction)
  }

  /**
   *
   */
  const dealActions = [
    {
      action: DealActionsEnum.DEAL_MIGRATE_BACKWARD,
      label:
        stage === DealStage.CLOSED
          ? strings.REOPEN_DEAL
          : strings.PREVIOUS_STAGE,
      component: ButtonsSC.ButtonOrange,
      isHidden: () => {
        return stage === DealStage.REVIEW
      },
      clickHandler: () => {
        setDirection && setDirection(DealStageDirection.BACKWARD)
        clickHandlerWrapper(DealStageDirection.BACKWARD)
      },
      // callback: sendDeal,
    },
    {
      action: DealActionsEnum.DEAL_MIGRATE_FORWARD,
      label:
        stage === DealStage.CLOSING ? strings.CLOSE_DEAL : strings.NEXT_STAGE,
      component: ButtonsSC.ButtonOrange,
      isHidden: () => {
        return stage === DealStage.CLOSED
      },
      clickHandler: () => {
        setDirection && setDirection(DealStageDirection.FORWARD)
        clickHandlerWrapper(DealStageDirection.FORWARD)
      },
    },
  ]

  /*******************************************************
   * RENDER
   *******************************************************/
  return (
    <>
      {dealActions.map((el, key: number) => {
        const Button = el.component
        if (data?.deal.actions?.includes(el.action) && !el.isHidden()) {
          return (
            <Grid key={key} item>
              <Button
                onClick={el.clickHandler}
                variant="contained"
                size="small"
                color="primary"
              >
                {el.label}
              </Button>
            </Grid>
          )
        }
        return null
      })}
    </>
  )
}

export default DealMigrateActions
