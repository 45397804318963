import { RouteType } from 'constants/routeNames'
import chat from './chat'
import common from './common'
import dashboard from './dashboard'
import deal from './deal'
import document from './document'
import item from './item'
import kanban from './kanban'
import notification from './notification'
import profile from './user'
import pipeline from './pipeline'

const modules = [
  chat,
  common,
  dashboard,
  deal,
  document,
  item,
  kanban,
  notification,
  profile,
  pipeline,
]

const mergedModules = {
  routes: modules.reduce(
    (acc: RouteType[], { routes }: { routes?: RouteType[] }) => {
      return routes ? [...acc, ...routes] : acc
    },
    [],
  ),
}

export default mergedModules
