import styled from 'styled-components'
import { device, to } from 'modules/common/styledComponents/MediaQueries'

export const ItemTableWrapper = styled.div`
  ${to(device.tabletXL)} {
    .MuiTableCell-root:first-child,
    .MuiTableCell-root:last-child {
      display: none;
    }
  }

  ${to(device.tablet)} {
    .MuiTableHead-root {
      display: none;
    }

    .MuiTableRow-root {
      display: flex;
      padding: 1em;
      flex-wrap: wrap;
      align-items: center;
    }

    .MuiTableCell-root:nth-child(2),
    .MuiTableCell-root:nth-child(3) {
      width: 100%;
    }

    .MuiTableCell-root:nth-child(3) {
      margin-bottom: 0.3em;
    }

    .MuiTableCell-root:nth-child(4) {
      margin-right: 1em;
    }

    .MuiTableCell-body {
      padding: 0;
      border: 0;
    }

    .underline {
      text-decoration: none !important;
    }
  }
`
