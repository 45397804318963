// @react
import React, { useEffect } from 'react'
// @design
import * as SC from 'modules/item/components/Create/styledComponents/createItem'
import {
  DialogActions,
  DialogContent,
  Grid,
  RadioGroup,
  Typography,
} from '@material-ui/core'
// @graphql
import {
  Item,
  UpdateItemInput,
  ItemActions,
  Scalars,
} from 'graphql/graphqlTypes'
// @common
import strings from 'constants/strings'
import Spacer from 'modules/common/components/_UI_DUMB/Spacer'
import validationSchema from 'modules/item/utils/validation'
import FormQuill from 'modules/common/components/_FORM_ELEMENTS/HookForm/form-inputs/FormQuill'
// @components
import BROWSER_HISTORY from 'utils/history'
import StyledDialog from 'modules/common/components/_UI_DUMB/ModalDialogs/styledComponents/StyledDialog'
import StarredButton from 'modules/item/components/StarredButton'
import { Controller, useForm } from 'react-hook-form'
import FormRadio from 'modules/common/components/_FORM_ELEMENTS/HookForm/form-inputs/FormRadio'
import { yupResolver } from '@hookform/resolvers/yup'
import { ITEM_STAGE_OPTIONS } from 'modules/item/config'

interface SelfProps {
  onSubmit: (values: UpdateItemInput) => Promise<void>
  item: Item
  id: Scalars['ID']
}

type PropsType = SelfProps

/**
 *
 * @param props
 * @constructor
 */
const EditItemPopup: React.FC<PropsType> = ({ item, onSubmit }) => {
  const { control, handleSubmit, formState } = useForm({
    defaultValues: {
      name: item.name,
      permissions: item.permissions,
      description: item.description,
      starred: item.starred,
      stage: item.stage,
      dueDate: item.dueDate,
    },
    resolver: yupResolver(validationSchema),
  })

  useEffect(() => {
    ;(control as { formName?: string }).formName = 'item-edit'
  }, [])

  /**
   * handleSubmit
   */
  const submitHandler = async (data) => {
    await onSubmit(data)
    onClose()
  }

  /**
   * handleSubmit
   */
  const onClose = () => {
    BROWSER_HISTORY.push({
      pathname: BROWSER_HISTORY.location.pathname,
      search: '',
    })
  }

  const canEditFields = item.actions?.some((action) => {
    return action
      ? [
          ItemActions.ITEM_EDIT_FIELDS_MYTEAM,
          ItemActions.ITEM_EDIT_FIELDS_OWN,
        ].includes(action)
      : false
  })

  const canEditVisibility = item.actions?.some((action) => {
    return action
      ? [
          ItemActions.ITEM_EDIT_PERMISSIONS_OWN,
          ItemActions.ITEM_EDIT_PERMISSIONS_MY_TEAM,
        ].includes(action)
      : false
  })

  const canEditDueDate = item.actions?.some((action) => {
    return action
      ? [
          ItemActions.ITEM_EDIT_DUE_DATE,
          ItemActions.ITEM_EDIT_DUE_DATE_OWN,
        ].includes(action)
      : false
  })

  /**
   * return/render form
   */
  return (
    <>
      <form onSubmit={handleSubmit((data) => submitHandler(data))}>
        <DialogContent>
          <SC.FlexWrap>
            <StarredButton
              itemId={item.id}
              disabled={!item.actions?.includes(ItemActions.ITEM_STAR)}
              isStarred={!!item.starred}
            />{' '}
          </SC.FlexWrap>
          <SC.StyledInputField
            name="name"
            control={control}
            disabled={!canEditFields}
            formState={formState}
            label={`${strings.ITEM_NAME} *`}
          />
          <Grid container spacing={4}>
            <Grid item xs={6}>
              <SC.StyledSelectField
                name="stage"
                control={control}
                formState={formState}
                label={`${strings.STAGE} *`}
                options={ITEM_STAGE_OPTIONS}
              />
            </Grid>
            <Grid item xs={6}>
              <SC.StyledDatepickerField
                control={control}
                name="dueDate"
                formState={formState}
                label={'Due date'}
                disabled={!canEditDueDate}
                isFullWidth
                isRightAlign
              />
            </Grid>
          </Grid>
          <FormQuill
            name="description"
            control={control}
            disabled={!canEditFields}
            formState={formState}
            label={strings.ITEM_DESCRIPTION}
          />
          <Spacer height={40} />
          <SC.GridContainer container spacing={2} alignItems="center">
            <Grid item xs={12} sm={2}>
              <Typography variant="body1" className="uppercase">
                {strings.VISIBILITY}
              </Typography>
            </Grid>
            <Controller
              as={
                <RadioGroup>
                  <Grid item>
                    <FormRadio
                      disabled={!canEditVisibility}
                      label={strings.MY_TEAM}
                      value={'MY_TEAM'}
                    />
                  </Grid>
                  <Grid item>
                    <FormRadio
                      disabled={!canEditVisibility}
                      label={strings.BOTH_TEAMS}
                      value={'BOTH_TEAMS'}
                    />
                  </Grid>
                </RadioGroup>
              }
              name="permissions"
              control={control}
            />
          </SC.GridContainer>
          {/*<DragAndDropArea />*/}
        </DialogContent>
        <DialogActions>
          <StyledDialog.Wrap>
            <Grid container justifyContent="center" spacing={3}>
              <Grid item xs={12} sm={6}>
                <StyledDialog.SubmitBtn
                  //onClick={data => handleSubmit(data)}
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  {strings.SAVE_AND_CLOSE}
                </StyledDialog.SubmitBtn>
              </Grid>
              <Grid item xs={12} sm={6}>
                <StyledDialog.SubmitBtn
                  onClick={onClose}
                  variant="contained"
                  color="default"
                >
                  {strings.CANCEL}
                </StyledDialog.SubmitBtn>
              </Grid>
            </Grid>
          </StyledDialog.Wrap>
        </DialogActions>
      </form>
    </>
  )
}

export default EditItemPopup
