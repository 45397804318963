// @react
import React from 'react'
import colors from 'constants/colors'
import FormInputInline from 'modules/common/components/_FORM_ELEMENTS/HookForm/form-inputs/FormInput/inline'
import FormInput from 'modules/common/components/_FORM_ELEMENTS/HookForm/form-inputs/FormInput'
import {
  Controller,
  useFieldArray,
  Control,
  FormState,
  FieldValues,
} from 'react-hook-form'
import {
  StyledAddButton,
  StyledAddIcon,
  StyledCustomList,
  StyledFab,
  StyledRemoveIcon,
} from './styledComponents/customFields'
import { Box, Grid } from '@material-ui/core'

interface CustomFieldsProps {
  control: Control
  formState: FormState<FieldValues>
}

const CUSTOM_FIELDS_MAX_AMOUNT = 10

const CustomFieldList: React.FC<CustomFieldsProps> = ({
  control,
  formState,
}) => {
  /*******************************************************
   * HOOKS
   *******************************************************/
  const { fields, append, remove } = useFieldArray({
    control, // control props comes from useForm (optional: if you are using FormContext)
    name: 'fields', // unique name for your Field Array
  })

  /*******************************************************
   * FUNCTIONS
   *******************************************************/
  const addFieldHandler = () => {
    append({ name: '', value: '' })
  }
  const removeFieldHandler = (index: number) => {
    remove(index)
  }
  /*******************************************************
   * RENDER COMPONENTS
   *******************************************************/
  const buttonChooser = (index: number) => {
    return (
      <>
        {
          <StyledFab
            role={'button'}
            data-testid={`remove-custom-deal-field.${index}`}
            color="primary"
            size="small"
            onClick={() => removeFieldHandler(index)}
          >
            <StyledRemoveIcon htmlColor={colors.white} />
          </StyledFab>
        }
      </>
    )
  }
  /*******************************************************
   * RENDER
   *******************************************************/
  return (
    <StyledCustomList>
      {fields.map((field, index) => (
        <Grid container spacing={2} key={field.id}>
          <Grid item sm={6}>
            <FormInput
              key={field.id}
              name={`fields.${index}.name`}
              formState={formState}
              control={control}
              defaultValue={field.name}
              label="Label"
            />
          </Grid>
          <Grid item sm={6}>
            <FormInput
              key={field.id}
              name={`fields.${index}.value`}
              formState={formState}
              control={control}
              defaultValue={field.name}
              label="Value"
              inputProps={{
                endAdornment: buttonChooser(index),
              }}
            />
          </Grid>
          {field.id && (
            <Controller
              name={`fields.${index}.id`}
              control={control}
              //@ts-ignore
              defaultValue={isNaN(field.id) ? undefined : field.id}
              render={(values) => (
                <Box display="none">
                  <FormInputInline {...values} key={index} label={''} />
                </Box>
              )}
            />
          )}
        </Grid>
      ))}
      {fields.length < CUSTOM_FIELDS_MAX_AMOUNT && (
        <StyledAddButton>
          <StyledFab
            role={'button'}
            aria-label={'add-custom-field'}
            color="primary"
            size="small"
            onClick={addFieldHandler}
          >
            <StyledAddIcon htmlColor={colors.white} />
          </StyledFab>
        </StyledAddButton>
      )}
    </StyledCustomList>
  )
}

export default CustomFieldList
