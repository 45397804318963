import { Typography } from '@material-ui/core'
import styled from 'styled-components'
import colors from 'constants/colors'

export const Toolbar = styled.div`
  && {
    padding: 0;
    border: 0;
  }
`

export const Label = styled(Typography)`
  && {
    transform-origin: top left;
    transition: transform 0.3s ease;
    color: ${colors.dark};
    ${(props) =>
      props.$isDisabled &&
      `
      margin-bottom: 25px;
  `}
  }
`

export const Wrap = styled.div`
  border-bottom: 1px solid;
  position: relative;
  flex: 1;

  #toolbar-wrapper {
    display: none;
  }

  ${(props) =>
    props.showToolbar &&
    !props.readOnly &&
    `
    border: 0px;
    border-radius: 0px;
    border-bottom: 1px solid ${colors.yellowishOrange};
`}
  ${(props) =>
    props.showToolbar &&
    !props.readOnly &&
    `
    #toolbar-wrapper {
      display: block !important;
    }
  `}

  ${(props) =>
    props.readOnly &&
    `
    .MuiTypography-root {color: rgba(0, 0, 0, 0.38);}
   p {
    color: rgba(0, 0, 0, 0.38);
  }
  `}

  .MuiTypography-root {
    transition: all 0.2s ease;
    transform: translate(0, 0.3rem) scale(0.85);

    ${(props) => {
      const isTransform =
        (!props.showToolbar &&
          (!props.value || props.value === '<p><br></p>')) ||
        props.readOnly
      return (
        isTransform &&
        `
      transform: translateY(1.7rem);
    `
      )
    }}
  }

  .ql-editor {
    border: none !important;
    padding: 9px 0 10px;
    min-height: auto;
    line-height: 1.2;
    background-color: transparent;
    font-size: 14px;
    font-family: 'Open Sans';

    ${(props) =>
      !props.showToolbar &&
      `background: transparent;
  `}

    ${(props) =>
      props.readOnly &&
      `border-bottom: 1px solid rgba(0, 0, 0, 0.42);
  `}
  }
`
