import {
  ContactStatus,
  SimplifiedUser,
  SimplifiedUserNodeFragment,
  UserDealFragment,
} from 'graphql/graphqlTypes'
import {
  ContactItemCTA,
  ContactItemVariant,
  MenuItem,
} from 'modules/common/components/_UI_SMART/ContactItem/type'
import { isOfType, ItemStatus } from 'modules/common/types'
import ContactItem from 'modules/common/components/_UI_SMART/ContactItem/ContactItem'
import LoadMoreBtn from 'modules/common/components/_UI_SMART/LoadMoreBtn'
import React, { ReactNode } from 'react'
import useUserActions from './userActionsHook'
import BROWSER_HISTORY from 'utils/history'
import { RouteNames } from 'constants/routeNames'
import UserModel from 'modules/user/models/user'

/*******************************************************
 * FUNCTIONS: HELPERS
 *******************************************************/
/**
 *  item status shows whether user has a relationship or not
 * @param {SimplifiedUser} user
 * @returns {ItemStatus | undefined}
 */
const getItemStatus = (user: SimplifiedUser): ItemStatus | undefined => {
  if (user?.contact?.status === ContactStatus.ACCEPTED) {
    return ItemStatus.CONNECTED
  }
  return
}
/*******************************************************
 * BUTTON AND MENU ASSEMBLY
 *******************************************************/

/**
 *
 * @param {SimplifiedUser} user
 * @param {MenuItem[]} menuItems
 * @param buttonGetters
 * @returns {ContactItemCTA[]}
 */
const getButtons = (
  user: SimplifiedUser,
  menuItems: MenuItem[],
  buttonGetters,
): ContactItemCTA[] => {
  const buttons: ContactItemCTA[] = []

  const addToContactsButton = buttonGetters.getAddToContactsCTA(user)
  if (addToContactsButton) {
    buttons.push(addToContactsButton)
  }

  if (menuItems.length) {
    buttons.push({
      variant: ContactItemVariant.MENU,
      items: menuItems,
    })
  }

  return buttons
}

/**
 *
 * @param userContact
 * @param buttonGetters
 * @returns {(() => (MenuItem | null))[]}
 */
const getMenuItems = (
  userContact: SimplifiedUser,
  buttonGetters,
): (() => MenuItem | null)[] => {
  return [
    () => buttonGetters.getAddToContactsMenuItem(userContact),
    () => buttonGetters.getInviteCancelMenuItem(userContact),
    () => buttonGetters.getRemoveContactMenuItem(userContact),
    () => buttonGetters.getDeclineActionMenuItem(userContact),
  ]
}

/**
 *
 * @param userContact
 * @param buttonGetters
 * @param addToMenuItems
 * @returns {ContactItemCTA[]}
 */
const buttonAssembly = (
  userContact: SimplifiedUser,
  buttonGetters,
  addToMenuItems: (handlers: (() => MenuItem | null)[]) => MenuItem[],
): ContactItemCTA[] => {
  const menuItemsFuncs = getMenuItems(userContact, buttonGetters)

  const menuItems = addToMenuItems(menuItemsFuncs)

  const buttons = getButtons(userContact, menuItems, buttonGetters)
  return buttons
}

/**
 *
 * @param {(SimplifiedUserNodeFragment & UserDealFragment)[] | null} userContactsData
 * @param {{id?: string, teamLeadUserId: string}} teamContext
 * @param {{isLoadMoreNeeded: boolean, loadMoreHandler: () => void}} loadMore
 * @param {any} refetch
 * @returns {JSX.Element | null}
 * @constructor
 */
/**
 *
 */
const UserContactsList = ({
  userContactsData,
  loadMore,
  refetch,
  notFound,
}: {
  userContactsData?: (SimplifiedUserNodeFragment & UserDealFragment)[] | null
  loadMore?: {
    isLoadMoreNeeded: boolean
    loadMoreHandler: () => void
  }
  refetch
  notFound?: ReactNode
}) => {
  const { buttonGetters, addToMenuItems } = useUserActions(refetch)
  const finalNotFound = notFound ?? <div>nothing found</div>
  const contactList = userContactsData?.map((userContact) => {
    if (
      !isOfType<SimplifiedUser>(userContact, '__typename', 'SimplifiedUser')
    ) {
      return null
    }
    const buttons = buttonAssembly(userContact, buttonGetters, addToMenuItems)

    const itemStatus = getItemStatus(userContact)
    const userMeta = UserModel.nameFormatter(userContact)

    return (
      <ContactItem
        key={userContact?.id}
        userId={userContact?.id}
        name={userMeta.name}
        email={userContact?.email}
        text={userMeta.title}
        avatar={userContact?.avatar?.file?.location}
        contactStatus={userContact.contact?.status} // @TODO NEEDS WORK
        itemStatus={itemStatus}
        buttons={buttons}
        onUserClick={() => {
          // @todo is handleClose() used?
          // handleClose()
          BROWSER_HISTORY.push(`/${RouteNames.USER}/${String(userContact?.id)}`)
        }}
        role={userContact?.userDeal?.teamRole}
      />
    )
  })

  return (
    <>
      {contactList?.length ? contactList : finalNotFound}
      {loadMore && (
        <LoadMoreBtn
          isVisible={loadMore.isLoadMoreNeeded}
          isLoading={false}
          onClick={loadMore.loadMoreHandler}
        />
      )}
    </>
  )
}

export default UserContactsList
