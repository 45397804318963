// @react
import { useState, useContext, DragEvent } from 'react'
// @design
import * as SC from './styledComponents/dragAndDropArea'
import LinearProgress from '@material-ui/core/LinearProgress'
import { IconButton, Typography } from '@material-ui/core'
// @config
import { config } from 'constants/config'
// @components
import SwitchBoardFileIcon from 'modules/common/components/_UI_DUMB/SwitchboardFileIcon/SwitchBoardFileIcon'
import SwitchboardTooltip from 'modules/common/components/_UI_DUMB/SwitchboardTooltip/SwitchboardTooltip'
// @common
import FileModel from 'modules/document/models/file'
import GlobalHelperModel from 'modules/common/models/globalHelper'
import strings from 'constants/strings'
import useAsyncEffect from 'use-async-effect'
// @graphql
import { DOCUMENT_DELETE } from 'graphql/mutations/document.mutation'
import { useMutation } from '@apollo/client'
import { SnackbarContext } from 'modules/common/context/snackbar'
import { SnackbarTypes } from 'modules/common/components/_UI_DUMB/Snackbars/Snackbar'
import {
  DocumentDeleteMutation,
  DocumentDeleteMutationVariables,
  ItemActions,
  Scalars,
  Document,
  DocumentNodeFragment,
} from 'graphql/graphqlTypes'
import useAuth0Wrapper from 'auth/hooks/useAuth0Wrapper'
import DocumentFinalize from './components/DocumentFinalize'
import useErrorHandler from 'modules/common/hooks/useErrorHandler'

const ERROR_STATUS = 'error'

interface SortedFiles {
  allowedFiles: File[]
  rejectedFiles: File[]
}

interface PropsType {
  documents?: Document[] | null
  // removeFileCallback?: (id: Scalars['ID']) => void
  removeFileCallback?: (id: string) => void
  // fileUpdateCallback?: (fileIds: Scalars['ID'][]) => void
  fileUpdateCallback?: (arg: Document[]) => void
  canUpload?: boolean
  canDeleteDocuments?: boolean
  hideUploader?: boolean
  isMultiple: boolean
  isButtonMode?: boolean
  testId?: string
  accept?: string[]
  forceDisableFinalize?: boolean
}

/**
 *
 * @param props
 * @returns {any}
 * @constructor
 */
const DragAndDropArea = ({
  documents,
  removeFileCallback,
  fileUpdateCallback,
  canUpload,
  canDeleteDocuments,
  hideUploader,
  isMultiple,
  isButtonMode,
  testId,
  accept,
  forceDisableFinalize = false,
}: PropsType) => {
  /*******************************************************
   * STATE
   *******************************************************/
  const [dragging, setDragging] = useState<boolean>(false)
  const [fileList, setFileList] = useState<File[]>([])
  const [completed, setCompleted] = useState<number>(0)

  /*******************************************************
   * HOOKS
   *******************************************************/
  const snackbar = useContext(SnackbarContext)
  const { getIdTokenWrapper, getAccessTokenWrapper } = useAuth0Wrapper()

  /*******************************************************
   * GQL
   *******************************************************/
  const [deleteDocument, { error }] = useMutation<
    DocumentDeleteMutation,
    DocumentDeleteMutationVariables
  >(DOCUMENT_DELETE)
  useErrorHandler(error)

  /*******************************************************
   * EFFECTS HOOKS
   *******************************************************/

  /**
   *
   */
  useAsyncEffect(
    async (isMounted) => {
      await handleFileUpload()
      if (!isMounted()) return
    },
    [fileList],
  )

  /*******************************************************
   * FUNCTIONS
   *******************************************************/

  /**
   *
   * @param evt
   */
  const dragEnterHandler = (evt: DragEvent) => {
    evt.stopPropagation()
    evt.preventDefault()

    setDragging(true)
  }

  /**
   *
   * @param evt
   */
  const dragLeaveHandler = (evt: DragEvent) => {
    evt.stopPropagation()
    evt.preventDefault()

    setDragging(false)
  }

  /**
   *
   * @param evt
   */
  const dragOverHandler = (evt: DragEvent) => {
    evt.stopPropagation()
    evt.preventDefault()

    setDragging(true)
  }

  // const getFiles = (fileList: FileList): File[] => {
  //   const files: File[] = []
  //   for (let file of fileList) {
  //     files.push(file)
  //   }
  //   return files
  // }

  /**
   *
   * @param evt
   */
  const dropHandler = (evt: DragEvent) => {
    evt.stopPropagation()
    evt.preventDefault()

    const files = isMultiple
      ? evt.dataTransfer.files
      : [evt.dataTransfer.files[0]]

    changeFileList(Array.from(files))

    setDragging(false)
  }

  /**
   *
   * @param evt
   */
  const changeInput = (evt: Event) => {
    const target = evt.target as HTMLInputElement
    const files = Array.from(target.files || [])
    const { allowedFiles, rejectedFiles }: SortedFiles = files.reduce(
      (acc: SortedFiles, file: File) => {
        return file.size > config.maxFileSize
          ? { ...acc, rejectedFiles: [...acc.rejectedFiles, file] }
          : { ...acc, allowedFiles: [...acc.allowedFiles, file] }
      },
      { allowedFiles: [], rejectedFiles: [] },
    )

    if (rejectedFiles.length) {
      let message

      switch (true) {
        case rejectedFiles.length === 1 && !allowedFiles.length:
          message = strings.ERROR_FILE_NOT_UPLOADED
          break
        case !!allowedFiles.length:
          message = strings.ERROR_SOME_FILES_NOT_UPLOADED
          break
        default:
          message = strings.ERROR_FILES_NOT_UPLOADED
      }

      snackbar.setMessage({
        type: SnackbarTypes.ERROR,
        message: (
          <div>
            <div>{message}</div>
            <div>{strings.ERROR_MAX_FILE_SIZE}</div>
          </div>
        ),
        show: true,
      })
    }

    evt.stopPropagation()
    evt.preventDefault()

    changeFileList(Array.from(allowedFiles))
  }

  /**
   *
   * @param files
   */
  const changeFileList = (files: File[]) => {
    const newFileList = [...fileList, ...files]

    setFileList(newFileList)
  }

  /**
   *
   * @param percentCompleted
   */
  const progressHandler = (percentCompleted) => {
    setCompleted(percentCompleted)
  }

  /**
   * handleFileUpload
   */
  const handleFileUpload = async () => {
    if (fileList && !fileList.length) {
      return
    }
    const accessToken = await getAccessTokenWrapper()
    const idToken = await getIdTokenWrapper()
    setCompleted(0)
    let res
    let errorMessage

    try {
      res = await FileModel.uploadFile(
        fileList,
        accessToken as string,
        idToken as string,
        progressHandler,
      )
    } catch (e) {
      //@ts-ignore
      errorMessage = e.response.data
    }

    setCompleted(0)

    if (res && res.data?.length) {
      const { failed, successful } = res.data.reduce(
        (acc, file) =>
          file.status === ERROR_STATUS
            ? { ...acc, failed: [...acc.failed, file] }
            : { ...acc, successful: [...acc.successful, file] },
        { failed: [], successful: [] },
      )
      setFileList([])

      failed.forEach((file) => {
        snackbar.setMessage({
          type: SnackbarTypes.ERROR,
          message: `there was an error uploading ${String(file.name)}`,
          show: true,
        })
      })

      if (successful.length && fileUpdateCallback) {
        fileUpdateCallback(
          res.data.filter(({ status }) => status !== ERROR_STATUS),
        )
      }
    } else {
      snackbar.setMessage({
        type: SnackbarTypes.ERROR,
        message: errorMessage || `there was an error uploading your files`,
        show: true,
      })
    }
  }

  /**
   *
   * @returns {Promise<void>}
   * @param id
   */
  const removeFile = async (id: Scalars['ID']) => {
    void (await deleteDocument({
      variables: { id },
    }))

    removeFileCallback && removeFileCallback(id)
  }

  /**
   *
   * @type {string}
   */
  const componentId = GlobalHelperModel.id

  /*******************************************************
   * RENDER
   *******************************************************/
  const getSwitchboardTooltip = (el: DocumentNodeFragment) => {
    if (
      canDeleteDocuments ||
      el.file?.actions?.some((action) => {
        if (!action) return false
        return [
          ItemActions.ITEM_DELETE_ATTACHMENT_MY_TEAM,
          ItemActions.ITEM_DELETE_ATTACHMENT_OWN,
        ].includes(action)
      })
    )
      return (
        <SwitchboardTooltip title={strings.REMOVE_DOCUMENT} theme={'warning'}>
          <SC.RemoveBtnContainer>
            <IconButton
              component="span"
              data-testid="file-remove"
              onClick={(event) => {
                event.stopPropagation()
                event.preventDefault()
                removeFile(el.id).catch((e) => console.log(e))
              }}
            >
              <SC.CancelIcon />
            </IconButton>
          </SC.RemoveBtnContainer>
        </SwitchboardTooltip>
      )

    return <></>
  }

  return (
    <SC.Wrap data-testid={testId}>
      {canUpload && !hideUploader && !isButtonMode && (
        <SC.DragArea
          dragging={dragging}
          onDragEnter={dragEnterHandler}
          onDragLeave={dragLeaveHandler}
          onDragOver={dragOverHandler}
          onDrop={dropHandler}
        >
          <SC.Icon />

          <Typography variant="body1">
            <SC.DropText>{strings.DROP_FILES_ANYWHERE}</SC.DropText>{' '}
            <SC.SelectText htmlFor={`input-file-${componentId}`}>
              {isMultiple ? strings.SELECT_FILES : strings.SELECT_FILE}
            </SC.SelectText>
          </Typography>
        </SC.DragArea>
      )}
      {!!completed && (
        <LinearProgress
          color={'primary'}
          variant="determinate"
          value={completed}
        />
      )}
      {documents &&
        documents[0] !== null &&
        documents.map((el, idx) => {
          return (
            <SC.FileItem key={el.id} data-testid={`dropzone-file-${idx}`}>
              <SC.FileName
                onClick={() => {
                  FileModel.createDownloadLink(
                    el.file?.location || '',
                    el.file?.versionId || '',
                  )
                }}
                variant="subtitle2"
              >
                {el.name || el.file?.originalName}{' '}
                {el.file && <SwitchBoardFileIcon file={el.file} />}
              </SC.FileName>
              {!forceDisableFinalize && (
                <SC.LockBtnContainer>
                  <DocumentFinalize document={el} disabled={!canUpload} />
                </SC.LockBtnContainer>
              )}

              {getSwitchboardTooltip(el)}
            </SC.FileItem>
          )
        })}
      {isButtonMode && canUpload && (
        <SC.Button variant="contained" size="small" color="primary">
          <SC.ButtonLabel htmlFor={`input-file-${componentId}`}>
            Add Documents
          </SC.ButtonLabel>
        </SC.Button>
      )}
      <SC.Input
        onChange={changeInput}
        multiple={isMultiple}
        type="file"
        accept={accept}
        id={`input-file-${componentId}`}
      />
    </SC.Wrap>
  )
}

export default DragAndDropArea
