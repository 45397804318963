// @react
import { ReactNode, useEffect, useMemo, useState } from 'react'
// @common
import Snackbar, {
  SnackbarTypes,
} from 'modules/common/components/_UI_DUMB/Snackbars/Snackbar'
import { SnackbarContext } from 'modules/common/context/snackbar'

type PropTypes = {
  children: ReactNode
}
export type SnackBarProviderProps = {
  message?: string | ReactNode
  show?: boolean
  onClose?: () => void
  type?: SnackbarTypes
  icon?: ReactNode | null
  anchorOrigin?: {
    vertical: 'bottom' | 'top'
    horizontal: 'left' | 'right'
  }
}

const SnackbarProvider = ({ children }: PropTypes) => {
  const [snackbarData, setSnackbarData] = useState<SnackBarProviderProps>()
  const [snackbarsQueue, setSnackbarsQueue] = useState<SnackBarProviderProps[]>(
    [],
  )
  const [isShowing, setIsShowing] = useState<boolean>(false)

  const nextSnackbar = () => {
    if (snackbarsQueue.length > 0) {
      setSnackbarData(snackbarsQueue[0])
      setSnackbarsQueue((old) => old.splice(1))
      setIsShowing(true)
    }
  }

  useEffect(() => {
    if (setSnackbarsQueue.length === 1 && !isShowing) {
      nextSnackbar()
    }
  }, [snackbarsQueue])

  useEffect(() => {
    if (!isShowing) {
      nextSnackbar()
    }
  }, [isShowing])

  const snackbar = useMemo(
    () => (
      <Snackbar
        anchorOrigin={snackbarData?.anchorOrigin}
        icon={snackbarData?.icon}
        message={snackbarData?.message}
        closeCallback={() => {
          setIsShowing(false)
        }}
        onClose={() => {
          setIsShowing(false)
          setSnackbarData({
            show: false,
          })
        }}
        show={snackbarData?.show}
        type={snackbarData?.type}
      />
    ),
    [snackbarData, isShowing],
  )

  return (
    <SnackbarContext.Provider
      value={{
        setMessage: (data) => {
          // @ts-ignore
          return setSnackbarsQueue((old) => {
            if (old) {
              return [...old, data]
            }
            return data
          })
        },
      }}
    >
      {snackbar}
      {children}
    </SnackbarContext.Provider>
  )
}

export default SnackbarProvider
