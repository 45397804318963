import { DealIndustry } from 'graphql/graphqlTypes'

const IndustryColor = {
  [DealIndustry.HOSPITALITY]: '#1818c4',
  [DealIndustry.INDUSTRIAL]: '#6262ff',
  [DealIndustry.LAND]: '#1bc483',
  [DealIndustry.OTHER]: '#2b9bff',
  [DealIndustry.OFFICE]: '#b2b2bf',
  [DealIndustry.RESIDENTIAL]: '#ec6f6f',
  [DealIndustry.RETAIL]: '#b24aff',
}

export default IndustryColor
