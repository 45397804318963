import styled from 'styled-components'
import {
  Button,
  DialogTitle,
  FormLabel,
  Grid,
  Typography,
} from '@material-ui/core'
import { device, from } from 'modules/common/styledComponents/MediaQueries'

export const SCWrap = styled.div`
  position: relative;
`

export const SCTitleWrap = styled(DialogTitle)`
  ${(props) =>
    props.type === 'kill' && // @todo no, don't pass context to control style
    `
    && {
      border: 0;
    }
  `}
`

export const SCTitle = styled.span`
  text-transform: uppercase;
  padding: 20px 20px 10px;
`

export const SCClose = styled.span`
  position: absolute;
  top: -10px;
  right: 0;
  cursor: pointer;

  ${from(device.tablet)} {
    top: 0;
  }
`

const Wrap = styled.div`
  flex: 1;
`

const SubmitBtn = styled(Button)`
  && {
    width: 100%;
    padding: 12px 85px;
  }
`

const PrevBtn = styled(Button)`
  && {
    margin-top: 30px;
    font-size: 14px;
    text-transform: none;
  }
`

const Subtitle = styled(Typography)`
  && {
    margin: 0 0 17px 0;
    text-transform: uppercase;
  }
`

const FabWrap = styled(Grid)`
  && {
    margin-right: 8px;
  }
`

const InputWrap = styled(Grid)`
  && {
    flex: 1;
  }
`

const InputRow = styled(Grid)`
  && {
    display: flex;
    margin-top: 15px;
    width: 100%;
    justify-content: space-between;
  }
`

const FormLabelHeader = styled(FormLabel)`
  && {
    margin-top: 35px;
    cursor: pointer;
    display: flex;
    align-items: center;
    font-size: 16px;
    transition: all 0.35s;
  }
`

const StyledDialog = {
  InputWrap,
  FabWrap,
  SubmitBtn,
  Wrap,
  Subtitle,
  PrevBtn,
  InputRow,
  FormLabelHeader,
}

export default StyledDialog
