import WebSocketLink from './WebSocketLink'

/**
 * wrapper class around WebSocketlink, which helps to manage JWT token injection
 */
class AuthorizedWsLink {
  /**
   * class properties
   */
  private readonly wsSubscriptionsServer
  private readonly tokenProvider
  private token
  public wsLink

  private restartRequestedBeforeConnected = false
  private gracefullyRestart = () => {
    this.restartRequestedBeforeConnected = true
  }
  /**
   * constructor
   * @param tokenProvider
   * @param wsSubscriptionsServer
   */
  constructor(tokenProvider, wsSubscriptionsServer) {
    this.wsSubscriptionsServer = wsSubscriptionsServer
    this.tokenProvider = tokenProvider

    tokenProvider().then((token) => {
      this.token = token
    })

    this.wsLink = new WebSocketLink({
      url: this.wsSubscriptionsServer,
      lazyCloseTimeout: 5000,
      lazy: true,
      keepAlive: 10_000,
      retryAttempts: 4,
      onNonLazyError: () => {
        console.log('onNonLazyError')
      },
      isFatalConnectionProblem: () => false,
      on: {
        connecting: () => {
          console.log('connecting ')
        },
        opened: () => {
          console.log('opened')
        },
        connected: (socket: any) => {
          console.log('connected')
          this.gracefullyRestart = () => {
            if (socket.readyState === WebSocket.OPEN) {
              socket.close(4205, 'Client Restart')
            }
          }

          // just in case you were eager to restart
          if (this.restartRequestedBeforeConnected) {
            this.restartRequestedBeforeConnected = false
            this.gracefullyRestart()
          }
        },
        error: (error) => {
          console.log('error from ws link')
          console.log(error)
          //  throw new Error('error from ws link')
        },
        ping: (received) => {
          // console.log('ping received')
          // if (!received)
          //   // sent
          //   timedOut = setTimeout(() => {
          //     if (activeSocket.readyState === WebSocket.OPEN)
          //       activeSocket.close(4408, 'Request Timeout')
          //   }, 5_000) // wait 5 seconds for the pong and then close the connection
        },
        pong: (received) => {
          // console.log('pong received')
          // if (received) clearTimeout(timedOut) // pong is received, clear connection close timeout
        },
      },
      connectionParams: async () => {
        const token = await tokenProvider()
        return {
          authToken: token,
          Authorization: `Bearer ${String(token)}`,
        }
      },
    })
  }

  /**
   * resetLink
   */
  resetLink = async () => {
    // get a new token
    this.token = await this.tokenProvider()
    // Reset the WS connection for it to carry the new JWT.
    this.wsLink.subscriptionClient.unsubscribeAll()
    this.wsLink.subscriptionClient.close()
  }

  /**
   * creatLink
   */
  createLink = () => this.wsLink
}

export default AuthorizedWsLink
