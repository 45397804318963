import {
  ContactStatus,
  DealStatus,
  GetDealInfoQuery,
  Item,
  ItemActions,
  SimplifiedUser,
  SimplifiedUserNodeFragment,
  TeamRole,
  User,
  UserDealFragment,
} from 'graphql/graphqlTypes'
import { ContactItemCTA } from 'modules/common/components/_UI_SMART/ContactItem/type'
import { isOfType, ItemStatus, Team } from 'modules/common/types'
import ContactItem from 'modules/common/components/_UI_SMART/ContactItem/ContactItem'
import LoadMoreBtn from 'modules/common/components/_UI_SMART/LoadMoreBtn'
import React, { useContext } from 'react'
import { UserContext } from 'modules/user/context/user'
import useUserActions from './userActionsHook'
import { DealContext } from 'modules/deal/contexts/deal'
import UserModel from '../../../user/models/user'

/*******************************************************
 * INDIVIDUAL BUTTON ACTIONS PERMISSIONS
 *******************************************************/

const canActOnList = (item): boolean => {
  return true
  // return item.actions.some(ItemActions.ITEM_EDIT)
}

/*******************************************************
 * BUTTON AND MENU ASSEMBLY
 *******************************************************/
/**
 *
 * @param {SimplifiedUser} user
 * @param currentUser
 * @param item
 * @param canUserEditItem
 * @param buttonGetters
 * @returns {ContactItemCTA[]}
 */
const getButtons = (
  user: SimplifiedUser,
  currentUser: User,
  canUserEditItem: boolean,
  buttonGetters,
  item: Item,
): ContactItemCTA[] => {
  const buttons: ContactItemCTA[] = []

  if (!canActOnList(item)) {
    return []
  }

  const addThirdPartyButton = buttonGetters.getAddThirdPartyButton(
    user,
    canUserEditItem,
    item,
  )
  if (addThirdPartyButton) {
    buttons.push(addThirdPartyButton)
  }

  const removeThirdPartyButton = buttonGetters.getRemoveThirdPartyButton(
    user,
    canUserEditItem,
    item,
  )
  if (removeThirdPartyButton) {
    buttons.push(removeThirdPartyButton)
  }

  return buttons
}

/**
 *  item status shows whether user has a relationship or not
 * @param {SimplifiedUser} user
 * @param {boolean} isUserLead
 * @returns {ItemStatus | undefined}
 */
const getItemStatus = (
  user: SimplifiedUser,
  isUserLead: boolean,
): ItemStatus | undefined => {
  const contactStatus = user.userDeal?.status
  if (
    contactStatus === ContactStatus.PENDING ||
    contactStatus === ContactStatus.ACCEPTED
  ) {
    return ItemStatus.CONNECTED
  }
  if (user?.userDeal?.teamType === Team.THEIR_TEAM || isUserLead) {
    return ItemStatus.DISABLED
  }

  return undefined
}

/**
 *
 * @returns {boolean}
 */
const canUserEditItem = (
  deal: GetDealInfoQuery['deal'] | undefined,
  item: Item,
  currentUser: User,
): boolean => {
  if (!deal) return false

  const dealStatus = deal.status
  const myRole = deal.myRole
  // @todo what is wrong with the item permissions?
  return (
    dealStatus !== DealStatus.DEAD &&
    (myRole === TeamRole.THIRD_PARTY
      ? item.createdBy.id === currentUser.id
      : true)
  )
}

/**
 *
 * @param {(SimplifiedUserNodeFragment & UserDealFragment)[] | null} userContactsData
 * @param {{id?: string, teamLeadUserId: string}} teamContext
 * @param {{isLoadMoreNeeded: boolean, loadMoreHandler: () => void}} loadMore
 * @param {any} refetch
 * @param item
 * @param disableActions
 * @returns {JSX.Element | null}
 * @constructor
 */
const DealTeamThirdPartyContactsList = ({
  userContactsData,
  teamContext,
  loadMore,
  refetch,
  item,
  disableActions = false,
}: {
  userContactsData?: (SimplifiedUserNodeFragment & UserDealFragment)[] | null
  teamContext?: {
    id?: string
    teamLeadUserId: string
  }
  loadMore?: {
    isLoadMoreNeeded: boolean
    loadMoreHandler: () => void
  }
  refetch
  item: Item
  disableActions?: boolean
}) => {
  const { user: currentUser } = useContext(UserContext)
  const queryResult = useContext(DealContext)
  const { buttonGetters } = useUserActions(refetch, teamContext?.id)

  if (!currentUser || !userContactsData?.length) return null

  const contactList = userContactsData.map((userContact) => {
    if (
      !isOfType<SimplifiedUser>(userContact, '__typename', 'SimplifiedUser')
    ) {
      return null
    }

    const contactStatus = userContact?.userDeal?.status
    const isUserLead = userContact.userDeal?.teamRole === TeamRole.LEAD
    const itemStatus = getItemStatus(userContact, isUserLead)

    const buttons: ContactItemCTA[] = getButtons(
      userContact,
      currentUser,
      canUserEditItem(queryResult.data?.deal, item, currentUser),
      buttonGetters,
      item,
    )
    const userMeta = UserModel.nameFormatter(userContact)

    return (
      <ContactItem
        key={userContact?.id}
        userId={userContact?.id}
        email={userContact?.email}
        name={userMeta.name}
        text={userMeta.title}
        avatar={userContact?.avatar?.file?.location}
        contactStatus={contactStatus}
        teamType={userContact?.userDeal?.teamType}
        showLeadIcon={false}
        buttons={!disableActions ? buttons : []}
        role={userContact?.userDeal?.teamRole}
        itemStatus={itemStatus}
        onUserClick={() => {
          console.log('on user click')
        }}
      />
    )
  })

  return (
    <>
      {contactList}
      {loadMore && (
        <LoadMoreBtn
          isVisible={loadMore.isLoadMoreNeeded}
          isLoading={false}
          onClick={loadMore.loadMoreHandler}
        />
      )}
    </>
  )
}

export default DealTeamThirdPartyContactsList
