import { Button, Typography } from '@material-ui/core'
import styled from 'styled-components'
// @components
import { ProfileEditPasswordContainer } from 'modules/common/styledComponents/Profile'

export const Wrapper = styled(ProfileEditPasswordContainer)`
  && {
    max-width: none;
  }
`

export const StyledBtn = styled(Button)`
  && {
    width: 100%;
    max-width: 325px;
  }
`

export const Title = styled(Typography)`
  && {
    margin-bottom: 15px;
  }
`

export const Container = styled.div`
  margin-bottom: 50px;
`

export const FieldsWrap = styled.div`
  display: flex;
  flex-direction: column;
`
