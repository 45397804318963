// language=GraphQL
import { gql } from '@apollo/client'
import { NOTIFICATION_ITEM } from '../fragments/notification.fragment'

export const MARK_ALL_AS_READ = gql`
  mutation MarkAllAsRead($input: [Int]) {
    notificationsSetAllAsRead(input: $input) {
      ...NotificationItem
    }
  }
  ${NOTIFICATION_ITEM}
`

export const UPDATE_NOTIFICATION = gql`
  mutation notificationUpdate($input: UpdateNotificationInput) {
    notificationUpdate(input: $input) {
      ...NotificationItem
    }
  }
  ${NOTIFICATION_ITEM}
`
