import { TextField as MuiTextField } from '@material-ui/core'
import styled from 'styled-components'
import colors from 'constants/colors'

export const TextField = styled(MuiTextField)`
  && {
    input {
      padding-bottom: 12px !important;
    }
    .MuiAutocomplete-popupIndicator {
      color: ${colors.black};
    }
    .MuiAutocomplete-popupIndicator:hover {
      background: none;
    }
  }
`
