import { ItemActions, DealStage } from 'graphql/graphqlTypes'
import { DealStageLabels } from 'modules/deal/models/deal'

export const ITEM_REOPEN_PERMISSIONS = [
  ItemActions.ITEM_REOPEN,
  ItemActions.ITEM_REOPEN_OWN,
  ItemActions.ITEM_REOPEN_MY_TEAM,
]

export const ITEM_CLOSE_PERMISSIONS = [
  ItemActions.ITEM_CLOSE,
  ItemActions.ITEM_CLOSE_OWN,
  ItemActions.ITEM_CLOSE_OWN,
]

export const ITEM_EDIT_PERMISSIONS = [
  ItemActions.ITEM_EDIT_FIELDS_MYTEAM,
  ItemActions.ITEM_EDIT_FIELDS_OWN,
  ItemActions.ITEM_EDIT_PERMISSIONS_MY_TEAM,
  ItemActions.ITEM_EDIT_PERMISSIONS_MY_TEAM,
  ItemActions.ITEM_EDIT_PERMISSIONS_OWN,
  ItemActions.ITEM_STAR,
]

export const ITEM_DELETE_PERMISSIONS = [
  ItemActions.ITEM_DELETE_ANY,
  ItemActions.ITEM_DELETE_OWN,
]

export const ITEM_STAGE_OPTIONS = [
  { value: DealStage.REVIEW, label: DealStageLabels.REVIEW },
  { value: DealStage.UNDERWRITING, label: DealStageLabels.UNDERWRITING },
  { value: DealStage.DUE_DILIGENCE, label: DealStageLabels.DUE_DILIGENCE },
  { value: DealStage.CLOSING, label: DealStageLabels.CLOSING },
  { value: DealStage.CLOSED, label: DealStageLabels.CLOSED },
]
