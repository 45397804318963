// language=GraphQL
import { gql } from '@apollo/client'

export const REMOVE_FROM_TEAM = gql`
  mutation RemoveFromTeam($teamId: ID, $userId: ID) {
    teamRemoveUser(teamId: $teamId, userId: $userId) {
      message
      status
    }
  }
`

export const UPDATE_TEAM_LEAD = gql`
  mutation TeamUpdateTeamLead($teamId: ID, $userId: ID) {
    teamMigrateTeamLead(teamId: $teamId, userId: $userId) {
      message
      status
    }
  }
`
