import styled from 'styled-components'
// @common
import colors from 'constants/colors'

export const Wrap = styled.div`
  position: relative;
  background-color: ${colors.white};
`

export const FloatRightDiv = styled.div`
  && {
    display: inline-block;
    float: right;
  }
`
