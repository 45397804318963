import styled from 'styled-components'
import { from, device, to } from 'modules/common/styledComponents/MediaQueries'
import colors from 'constants/colors'
import { Grid } from '@material-ui/core'

const wrapWidth = {
  large: '1200px',
}

export const ContentContainer = styled.div<{
  maxWidth?: keyof typeof wrapWidth
}>`
  padding: 1em;

  ${from(device.materialXS)} {
    padding: 1.7em 1.5em;
  }

  ${from(device.ipadL)} {
    padding: 40px 50px;
  }

  .MuiTable-root {
    margin-top: 30px;
  }

  ${(props) =>
    props.maxWidth &&
    `
    margin-left: auto;
    margin-right: auto;
    max-width: ${wrapWidth[props.maxWidth as keyof typeof wrapWidth]};
  `}
`

export const PushContainer = styled.div`
  margin-bottom: 40px;
`

export const TopWrap = styled(Grid)`
  && {
    margin-bottom: 30px;
  }
`

export const HomeContainer = styled.div`
  position: relative;
  display: flex;
  height: 100%;
`

export const BorderedContainer = styled.div`
  border: 1px solid ${colors.veryLightBlue};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  ${to(600)} {
    padding: 0 20px;
  }
`

export const CenteredMedContainer = styled.div`
  position: relative;
  max-width: 835px;
  margin: auto;

  ${to(600)} {
    padding: 0 20px;
  }
`
