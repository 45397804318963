import { Currency } from 'graphql/graphqlTypes'
import { ChartType, TotalsSumBy } from 'modules/pipeline/enum'
import { createContext, useReducer } from 'react'

interface PipelineContextProps {
  sumBy: TotalsSumBy
  chartType: ChartType
  currency: Currency
}

const defaultValues = {
  sumBy: TotalsSumBy.COUNT,
  chartType: ChartType.CHART,
  currency: Currency.USD,
}

interface Props {
  state: PipelineContextProps
  dispatch: (state, action) => void
}

const settingsReducer = (state, action) => {
  switch (action.type) {
    case 'setSumBy': {
      return { ...state, sumBy: action.payload.sumBy }
    }
    case 'setChartType': {
      return { ...state, chartType: action.payload.chartType }
    }
    case 'setCurrency': {
      return { ...state, currency: action.payload.currency }
    }
    default: {
      return state
    }
  }
}

/**
 *
 */
export const PipelineContext = createContext<Props>({
  state: defaultValues,
  dispatch: settingsReducer,
})

/**
 *
 * @param children
 * @constructor
 */
const SettingsProvider = ({ children }) => {
  const [state, dispatch] = useReducer(settingsReducer, defaultValues)

  const value = { state, dispatch }
  return (
    <PipelineContext.Provider value={value}>
      {children}
    </PipelineContext.Provider>
  )
}

export default SettingsProvider
