import { ReactElement } from 'react'
import useScript from 'react-script-hook'
import PlacesAutocompleteInput, {
  DetailsResult,
} from './PlacesAutocompleteInput'

type PropsType = {
  onChange: (details: DetailsResult) => void
}

/**
 *
 * @param onChange
 * @constructor
 */
const PlacesAutocomplete = ({ onChange }: PropsType): ReactElement => {
  /*******************************************************
   * HOOKS
   *******************************************************/
  const [scriptLoading, scriptError] = useScript({
    src: `https://maps.googleapis.com/maps/api/js?key=${String(
      process.env.REACT_APP_GOOGLE_PLACES_API_KEY,
    )}&libraries=places`,
    async: true,
    defer: true,
    checkForExisting: true,
  })

  /*******************************************************
   * RENDER
   *******************************************************/
  if (!process.env.REACT_APP_GOOGLE_PLACES_API_KEY) {
    return <h4>NO API WAS FOUND!</h4>
  }
  if (scriptLoading) {
    return <h4>Loading Google Places API...</h4>
  }
  if (scriptError) {
    return <h4>Failed to load Google Places API: {scriptError.message}</h4>
  }

  return <PlacesAutocompleteInput onChange={onChange} />
}

export default PlacesAutocomplete
