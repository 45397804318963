import { colors } from 'constants/colors'
import { Chip } from '@material-ui/core'
import styled from 'styled-components'

export const ChipBtn = styled(Chip)`
  height: 40px;
  margin-right: 10px;
  padding: 8px 10px;
  font-size: 14px;
  font-weight: 600;
  transition: all 0.2s ease;
  border-radius: 3em;

  &&:hover {
    color: ${colors.lightPurple};
  }

  &&:hover,
  &&:focus {
    background: ${colors.darkPurple};
  }

  &&:focus {
    color: ${colors.white};
  }

  ${(props) =>
    props['data-active']
      ? `background: ${colors.darkPurple}; color: ${colors.white};`
      : `background: ${colors.lightPurple}; color: ${colors.darkPurple};`}
`
export const DisabledWrapper = styled.span`
  display: inline-block;
  &&:hover {
    cursor: not-allowed;
  }
`

export const ChipCount = styled.span`
  display: inline-block;
  min-width: 24px;
  margin-right: 9px;
  margin-left: -12px;
  padding: 0 5px;
  line-height: 24px;
  border-radius: 1em;
  text-align: center;
  font-size: 12px;
  color: ${colors.darkPurple};
  background-color: ${colors.white};
`

export const TabLabel = styled.span`
  margin-right: 5px;
`

export const ChipFiltersWrapper = styled.div`
  margin-bottom: 30px;
`
