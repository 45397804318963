// @react
import React from 'react'
// @design
import * as SC from './styledComponents'

type PropsType = {
  placeholder?: string
  value?: string
  className?: string
  onClear: () => void
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}

/**
 *
 * @param props
 * @returns {any}
 * @constructor
 */
const SearchBar = ({
  className,
  placeholder,
  value,
  onClear,
  onChange,
}: PropsType) => {
  /*******************************************************
   * RENDER
   *******************************************************/
  return (
    <SC.Wrap aria-label="search" className={className}>
      <SC.Input
        fullWidth
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        filled={value && value.length}
        InputProps={{
          startAdornment: (
            <SC.Adornment position="start">
              <SC.SearchIcon />
            </SC.Adornment>
          ),
          endAdornment: (
            <SC.Adornment position="end">
              <SC.ClearButton onClick={onClear}>
                <SC.CloseIcon />
              </SC.ClearButton>
            </SC.Adornment>
          ),
        }}
      />
    </SC.Wrap>
  )
}

export default SearchBar
