import {
  FormControlLabel,
  Radio,
  Typography,
  RadioGroup as MuiRadioGroup,
} from '@material-ui/core'
import styled from 'styled-components'
import { colors } from 'constants/colors'

export const Title = styled(Typography)`
  && {
    margin-bottom: 20px;
    text-transform: uppercase;
  }
`

export const Label = styled(FormControlLabel).attrs({
  classes: {
    label: 'label',
    root: 'root',
  },
})`
  .label {
    font-size: 12px;
    color: ${colors.dark};
    font-weight: 600;
  }

  &.root {
    margin: 0 0 0 -5px;
  }
`

export const RadioGroup = styled(MuiRadioGroup)`
  && {
    flex-wrap: nowrap;
    flex-direction: row;
    label {
      :first-child {
        margin-right: 14px;
      }
    }
  }
`

export const StyledRadio = styled(Radio).attrs({
  classes: { root: 'root' },
})`
  &.root {
    margin-right: 3px;
    padding: 2px;
  }
`

export const ItemsDetailsPermissions = styled.div`
  margin: 40px 0;
`

export const InlineBlock = styled.div`
  display: inline-block;
`
