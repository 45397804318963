// @react
import { ReactElement, useContext } from 'react'
// @design
import * as SC from 'modules/common/components/_UI_SMART/SharedTable/styledComponents/sharedTable'
import { Grid } from '@material-ui/core'
// @components
import DatesFilter from 'modules/common/components/_UI_SMART/SharedTable/Filters/DatesFilter'
import ListFilter from 'modules/common/components/_UI_SMART/SharedTable/Filters/ListFilter'
import SearchFilter from 'modules/common/components/_UI_SMART/SharedTable/Filters/SearchFilter'
import useFilters from 'modules/common/components/_UI_SMART/SharedTable/hook/filters'
// @common
import strings from 'constants/strings'
// @graphql
import {
  GetDocumentsQuery,
  GetDocumentsQueryVariables,
} from 'graphql/graphqlTypes'
import { QueryResult } from '@apollo/client'
import { TableContext } from 'modules/common/components/_UI_SMART/SharedTable/ContextTableWrapper'

/*******************************************************
 * TYPES
 *******************************************************/
type PropsType = {
  showDealFilter?: boolean
}

/**
 *
 * @param changeCriteria
 * @param showDealFilter
 * @constructor
 */
const DocumentsSortSearch = ({ showDealFilter }: PropsType): ReactElement => {
  /*******************************************************
   * HOOKS
   *******************************************************/
  const { changeCriteria, datePickerChangeHandler, data } =
    useContext(TableContext)

  /*******************************************************
   * CONSTANTS
   *******************************************************/
  const dealFilters = data.find((el) => el?.key === 'deal')
  const fileTypeFilters = data.find((el) => el?.key === 'fileType')
  const createdDateStartFilter = data.find(
    (el) => el?.key === 'createdDateStart',
  )?.values[0]?.value
  const createdDateEndFilter = data.find((el) => el?.key === 'createdDateEnd')
    ?.values[0]?.value

  /*******************************************************
   * RENDER
   *******************************************************/
  return (
    <SC.Wrap>
      <Grid container spacing={3} alignItems="flex-end">
        {showDealFilter && (
          <SC.Column item>
            <ListFilter
              type={'deal'}
              //@ts-ignore
              changeCriteria={changeCriteria}
              filters={dealFilters}
            />
          </SC.Column>
        )}

        <SC.Column item>
          <ListFilter
            type={'fileType'}
            //@ts-ignore
            changeCriteria={changeCriteria}
            filters={fileTypeFilters}
          />
        </SC.Column>

        <SC.Column item>
          <DatesFilter
            //@ts-ignore
            changeCriteria={changeCriteria}
            datePickerChangeHandler={datePickerChangeHandler}
            createdDateStart={createdDateStartFilter}
            createdDateEnd={createdDateEndFilter}
          />
        </SC.Column>

        <SC.SearchColumn item>
          <SearchFilter
            placeholder={strings.TABLE_FILTER_SEARCH}
            type="name"
            //@ts-ignore
            changeCriteria={changeCriteria}
          />
        </SC.SearchColumn>
      </Grid>
    </SC.Wrap>
  )
}
export default DocumentsSortSearch
