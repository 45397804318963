import styled from 'styled-components'
import { Grid } from '@material-ui/core'

export const FabWrap = styled(Grid)`
  && {
    margin-right: 8px;
  }
`

export const InputWrap = styled(Grid)`
  && {
    flex: 1;
  }
`
