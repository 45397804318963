// @react
import React from 'react'
import { FC } from 'react'
// @design
import {
  Dialog,
  DialogContent,
  makeStyles,
  Typography,
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import {
  SCWrap,
  SCTitleWrap,
  SCTitle,
  SCClose,
} from './styledComponents/StyledDialog'

/*******************************************************
 * CONST
 *******************************************************/
const style = () => ({
  paper: {
    width: 750,
    maxWidth: 750,
  },
  hideScroll: {
    'overflow-y': 'inherit',
  },
})
const useStyles = makeStyles(style)

/*******************************************************
 * TYPES
 *******************************************************/
type PropsType = {
  open: boolean
  onClose: () => void
  title: string
  testId?: string
  type?: string
  text?: string
  className?: string
  hideScroll?: boolean
}

/**
 *
 * @param testId
 * @param className
 * @param open
 * @param onClose
 * @param title
 * @param text
 * @param type
 * @param children
 * @param hideScroll
 * @constructor
 */
const SwitchboardDialog: FC<PropsType> = ({
  testId,
  className,
  open,
  onClose,
  title,
  text,
  type,
  children,
  hideScroll,
}) => {
  /*******************************************************
   * CONST
   *******************************************************/
  const classes = useStyles()

  /*******************************************************
   * RENDER
   *******************************************************/
  return (
    <Dialog
      data-testid={testId}
      classes={{
        paper: `${className as string} ${classes.paper} ${
          hideScroll ? classes.hideScroll : ''
        }`,
      }}
      open={open}
      onClose={onClose}
    >
      <SCTitleWrap type={type}>
        <SCWrap>
          {title && <SCTitle>{title}</SCTitle>}
          <SCClose data-testid="close-button" onClick={onClose}>
            <CloseIcon />
          </SCClose>
        </SCWrap>
      </SCTitleWrap>
      {text && (
        <DialogContent>
          <Typography variant="h3" align="center">
            {text}
          </Typography>
        </DialogContent>
      )}
      {children}
    </Dialog>
  )
}

export default SwitchboardDialog
