//@common
import React, { ReactElement, useEffect, useState } from 'react'
//@design
import * as SC from './styledComponents/inlineForm'
import * as ButtonsSC from 'modules/common/components/_UI_DUMB/Button/styledComponents'
import * as SCGlobal from 'modules/common/styledComponents/Global'
//@common
import dateFnsFormat from 'date-fns/format'
import config from 'constants/config'
import { Controller, Resolver, useForm } from 'react-hook-form'
import { isMatch, parse } from 'date-fns'

/*******************************************************
 * TYPES
 *******************************************************/
interface Props {
  testid?: string
  disabled?: boolean
  editButtonText?: string
  title: string
  onSubmit: (args) => Promise<void>
  defaultValues?: Object
  children: ReactElement | ReactElement[]
  resolver?: Resolver
}

/***
 *
 * @param props
 * @constructor
 */
const InlineForm = ({
  editButtonText,
  title,
  children,
  onSubmit,
  defaultValues,
  resolver,
  disabled,
  testid,
}: Props) => {
  /*******************************************************
   * STATE
   *******************************************************/
  const [active, setActive] = useState<boolean>(false)
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [viewValues, setViewValues] = useState(defaultValues || {})

  /*******************************************************
   * HOOKS
   *******************************************************/
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { handleSubmit, control, formState, setValue } = useForm({
    defaultValues,
    resolver,
  })
  const { errors, isSubmitSuccessful } = formState

  /*******************************************************
   * EFFECTS HOOKS
   *******************************************************/
  useEffect(() => {
    if (!defaultValues) return
    setViewValues(defaultValues)
    for (const key in defaultValues) {
      setValue(key, defaultValues[key])
    }
  }, [defaultValues])

  /*******************************************************
   * FUNCTIONS
   *******************************************************/
  const toggleActive = () => {
    setActive((prevValue) => !prevValue)
  }

  /**
   *
   * @param value
   * @param formatFunc
   */
  const formatValue = (value, formatFunc) => {
    if (isMatch(value, config.isoDateFormat)) {
      return dateFnsFormat(
        parse(value, config.isoDateFormat, new Date()),
        'MMMM do, yyyy',
      )
    }
    return formatFunc ? formatFunc(value) : value
  }

  /*******************************************************
   * RENDER
   *******************************************************/
  /**
   *
   */
  const submit = handleSubmit((data) => {
    setIsSubmitting(true)
    void onSubmit(data)
    toggleActive()
  })

  /*******************************************************
   * RENDER ELEMENTS
   *******************************************************/
  const renderController = (name, child, control) => {
    const p = child.props
    return (
      <Controller
        key={name}
        name={name}
        control={control}
        render={({ ref, value, ...values }) => {
          return React.cloneElement(child, {
            ...p,
            ...values,
            value: value || '',
            withoutLabel: true,
            label: p.label || '',
            isSubmitting,
            isSubmitSuccessful,
            error: errors[name]?.message,
          })
        }}
      />
    )
  }

  /**
   *
   */
  const renderActions = () => {
    if (active) {
      return (
        <>
          <ButtonsSC.ButtonOrange
            variant="contained"
            onClick={submit}
            type="button"
            color="primary"
            size="small"
          >
            Save
          </ButtonsSC.ButtonOrange>
          <ButtonsSC.ButtonOrange
            onClick={toggleActive}
            variant="contained"
            type="button"
            color="primary"
            size="small"
          >
            Cancel
          </ButtonsSC.ButtonOrange>
        </>
      )
    }
    return (
      <ButtonsSC.ButtonOrange
        disabled={disabled}
        variant="contained"
        type="button"
        color="primary"
        size="small"
        onClick={toggleActive}
      >
        {editButtonText || 'Edit'}
      </ButtonsSC.ButtonOrange>
    )
  }

  /**
   *
   * @param component
   * @param p
   */
  const renderControllers = (component, p) => {
    const name = p.name || p.label.toLocaleLowerCase()
    if (p.container) {
      return React.cloneElement(component, {
        children: p.children.map((ch) => {
          return renderController(ch.props.name, ch, control)
        }),
      })
    } else {
      return renderController(name, component, control)
    }
  }

  /**
   *
   */
  const renderFields = () => {
    return (children instanceof Array ? children : [children]).map((child) => {
      const p = child.props
      const name = p.name || p.label.toLocaleLowerCase()

      const symbol = p?.inputProps?.endAdornment?.props?.children
      const transformFunc = p?.inputProps?.transformFunc
      let value
      if (p.container) {
        const names = p.children.map((it) => it.props.name)
        value = names
          ?.map((it) => formatValue(viewValues[it], transformFunc))
          .join(' ')
      } else {
        value = formatValue(viewValues[name], transformFunc)
      }
      if (symbol && value) {
        value = `${String(
          formatValue(viewValues[name], transformFunc),
        )} ${String(symbol)}`
      }
      return (
        <SC.Field
          key={name}
          isactive={active ? String(active) : undefined}
          data-testid={`field-${name as string}`}
        >
          <SC.Label>
            <SC.FieldLabel variant="subtitle1">{`${String(
              p.label,
            )}:`}</SC.FieldLabel>
          </SC.Label>
          <SC.ActiveValue isactive={active ? String(active) : undefined}>
            {renderControllers(child, p)}
          </SC.ActiveValue>
          {!active && <SC.Value>{viewValues ? value : ''}</SC.Value>}
        </SC.Field>
      )
    })
  }

  /*******************************************************
   * RENDER
   *******************************************************/
  return (
    <>
      <SCGlobal.ContentContainer data-testid={testid}>
        <SC.Wrap>
          <form onSubmit={submit}>
            <SC.InlineFormContent>
              <SC.Header>
                <SC.HeaderInner>
                  <SC.Title variant="h4">{title}</SC.Title>
                </SC.HeaderInner>
              </SC.Header>
              <div>{renderFields()}</div>
            </SC.InlineFormContent>
          </form>
          <SC.InlineActions>{renderActions()}</SC.InlineActions>
        </SC.Wrap>
      </SCGlobal.ContentContainer>
    </>
  )
}

export default React.memo(InlineForm)
