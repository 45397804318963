import { device, from, to } from 'modules/common/styledComponents/MediaQueries'
import { IconButton, TableCell } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { colors } from 'constants/colors'
import styled from 'styled-components'
import { ContentContainer } from 'modules/common/styledComponents/Global'

export const Wrapper = styled(ContentContainer)`
  .MuiTableCell-head:last-child {
    text-align: center;
  }
`

export const MobileDateText = styled.span`
  ${from(device.tablet)} {
    display: none;
  }
`

export const ActionCell = styled(TableCell)`
  && {
    position: relative;
  }
`

export const CancelIcon = styled(CloseIcon)`
  && {
    padding: 2px;
    border-radius: 50%;
    font-size: 21px;
    color: ${colors.white};
    background-color: ${colors.grapefruit};
  }
`

export const CanceLButton = styled(IconButton)`
  && {
    background-color: transparent;
  }
`

export const CenteredWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  && {
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
`

export const DocumentsTableWrap = styled.div`
  ${to(device.tablet)} {
    .table-head,
    .table-body > * {
      > *:nth-child(2),
      > *:nth-child(4) {
        display: none;
      }

      > *:nth-child(3) {
        width: 1em;
      }
    }
  }
`

export const FinalizedTableCell = styled(TableCell)`
  text-align: center !important;
`
