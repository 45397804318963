// @react
import { useEffect, useRef, useState } from 'react'
import colors from 'constants/colors'
import FormInputInline from 'modules/common/components/_FORM_ELEMENTS/HookForm/form-inputs/FormInput/inline'
import * as yup from 'yup'
// @design
import * as ButtonsSC from 'modules/common/components/_UI_DUMB/Button/styledComponents'
import * as SC from './styledComponents/emailFieldList'
import Dropdown from 'modules/common/components/_UI_DUMB/Dropdown'
import {
  InviteToContactsMutation,
  InviteToContactsMutationVariables,
  InviteToTeamMutation,
  InviteToTeamMutationVariables,
  Scalars,
} from 'graphql/graphqlTypes'
import { MutationFunctionOptions } from '@apollo/client/react/types/types'
import { FetchResult } from '@apollo/client/link/core'
import { globalUseStyles } from 'App/matTheme'

/*******************************************************
 * CONSTANTS
 *******************************************************/
const emailValidator = yup.string().email()

/*******************************************************
 * TYPES
 *******************************************************/
export type AddToContactsMutation = (
  options?: MutationFunctionOptions<
    InviteToContactsMutation,
    InviteToContactsMutationVariables
  >,
) => Promise<FetchResult<InviteToContactsMutation>>

export type AddToTeamMutation = (
  options?: MutationFunctionOptions<
    InviteToTeamMutation,
    InviteToTeamMutationVariables
  >,
) => Promise<FetchResult<InviteToTeamMutation>>
export type AddToContactsByEmail = (email: string) => void

interface EmailFieldListProps<T> {
  addToContacts?: T
  inviteOptions?: { teamId: Scalars['ID'] }
  inviteMenu?: { text: string; handler: (email: string) => Promise<void> }[]
  dealId?: Scalars['ID']
}
/**
 *
 * @param addToContacts
 * @param inviteOptions
 * @param inviteMenu
 * @param dealId
 * @constructor
 */
// @todo needs more work on generic here, extends Function is pretty useless
const EmailFieldsList = <T extends Function>({
  inviteMenu,
}: EmailFieldListProps<T>) => {
  /*******************************************************
   * STATE
   *******************************************************/
  const [fields, setFields] = useState<{ id: number; value: string }[]>([
    { id: 0, value: '' },
  ])
  const [sentEmails, setSentEmails] = useState<string[]>([])
  const [anchorEl, setAnchorEl] = useState<(EventTarget & HTMLElement) | null>()

  /*******************************************************
   * HOOKS
   *******************************************************/
  const lastElement = useRef<null | HTMLDivElement>(null)

  /*******************************************************
   * EFFECTS HOOKS
   *******************************************************/
  useEffect(() => {
    if (fields.length < 5 && sentEmails.length >= fields.length) {
      setFields((prev) => [
        ...prev,
        { id: prev[prev.length - 1].id + 1, value: '' },
      ])
    }
  }, [sentEmails.length])

  useEffect(() => {
    if (lastElement.current && fields.length > 1) {
      lastElement.current.scrollIntoView({
        behavior: 'smooth',
      })
    }
  }, [fields])

  /*******************************************************
   * FUNCTIONS
   *******************************************************/

  /**
   *
   */
  const handleClose = () => {
    setAnchorEl(null)
  }

  /**
   *
   * @param index
   */
  const removeFieldHandler = (index: number) => {
    setFields((prev) => prev.filter((it) => it.id !== index))
  }

  /**
   *
   * @param item
   */
  const getFieldValue = (item: number): string => {
    return fields.find((it) => it.id === item)?.value || ''
  }

  /**
   *
   * @param item
   */
  const isEmailValid = (item: number) => {
    const value = getFieldValue(item)
    return value && emailValidator.isValidSync(value)
  }

  /**
   *
   * @param email
   */
  const checkIsSent = (email: string) => {
    return sentEmails.indexOf(email) >= 0
  }

  /**
   *
   * @param index
   * @param handler
   */
  const sendInviteHandler = async (
    index: number,
    handler: (email: string) => Promise<void>,
  ): Promise<void> => {
    if (!isEmailValid(index) || !handler) return
    const email = getFieldValue(index)

    void (await handler(email))

    setSentEmails((prev) => [...prev, getFieldValue(index)])
  }

  /*******************************************************
   * RENDER ELEMENTS
   *******************************************************/

  /**
   *
   * @param index
   */
  const renderInviteButton = (index: number) => {
    if (!inviteMenu) return null

    if (inviteMenu?.length === 1) {
      return (
        <ButtonsSC.ButtonAquaMarineLightMini
          onClick={() => sendInviteHandler(index, inviteMenu[0].handler)}
        >
          <>{inviteMenu[0].text}</>
        </ButtonsSC.ButtonAquaMarineLightMini>
      )
    }

    const menuItems = inviteMenu.map((it) => {
      return {
        clickAction: () => sendInviteHandler(index, it.handler),
        Component: <>{it.text}</>,
      }
    })
    return (
      <>
        <ButtonsSC.ButtonAquaMarineLightMini
          variant={'small'}
          onClick={(event) => {
            event.preventDefault()
            setAnchorEl(event.currentTarget)
          }}
        >
          Send Invite
        </ButtonsSC.ButtonAquaMarineLightMini>

        <Dropdown
          handleCloseOutside={handleClose}
          openEventTarget={anchorEl}
          menuItems={menuItems}
          variant="yellow"
        />
      </>
    )
  }

  /**
   *
   * @param index
   */
  const buttonChooser = (index: number) => {
    const isEmailSent = checkIsSent(getFieldValue(index))

    if (!isEmailValid(index) && getFieldValue(index) && !isEmailSent) {
      return <SC.ErrorText>Invalid email</SC.ErrorText>
    }

    if (isEmailValid(index) && !isEmailSent) {
      return renderInviteButton(index)
    }

    if (isEmailSent) {
      return (
        <ButtonsSC.ButtonAquaMarineLightMini disabled={true}>
          Invite Sent
        </ButtonsSC.ButtonAquaMarineLightMini>
      )
    }

    if (fields.length - sentEmails.length > 1 && !isEmailSent) {
      return (
        <SC.StyledFab
          color="primary"
          size="small"
          onClick={() => removeFieldHandler(index)}
        >
          <SC.StyledRemoveIcon htmlColor={colors.white} />
        </SC.StyledFab>
      )
    }

    return <></>
  }

  /*******************************************************
   * RENDER
   *******************************************************/
  return (
    <div>
      <SC.StyledTypographyTitle variant="h4">
        Invite colleagues and team members to SwitchBoard
      </SC.StyledTypographyTitle>
      {fields.map((item, index) => (
        <FormInputInline
          key={item.id}
          name={`email-${item.id}`}
          type="text"
          label=""
          disabled={checkIsSent(item.value) && index <= sentEmails.length - 1}
          onChange={(e) => {
            setFields((prevState) => {
              const newArr = [...prevState]
              const result = { ...item, value: e.target.value }
              newArr.splice(index, 1, result)
              return newArr
            })
          }}
          inputProps={{
            autoComplete: 'no',
            endAdornment: buttonChooser(item.id),
            placeholder: 'Email',
          }}
        />
      ))}
      <div ref={lastElement}>{''}</div>
    </div>
  )
}

export default EmailFieldsList
