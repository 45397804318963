import { AccessTime } from '@material-ui/icons'
import styled from 'styled-components'
import colors from 'constants/colors'

export const StyledTag = styled.div`
  ${(props) =>
    !props.closed && props.isOverdue && `background: ${colors.pink}`};
  font-size: 14px;
  height: 30px;
  padding: 6px 10px 6px 11px;
  border-radius: 4px;
`

export const StyledAccessTime = styled(AccessTime)`
  color: ${colors.red} !important;
  && {
    width: 16px;
    height: 16px;
    margin-bottom: -2px;
  }
`

export const Wrapper = styled.div`
  display: flex;
  min-width: 120px;
  white-space: nowrap;
`
