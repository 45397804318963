// @react
import React, { useState, useContext, useEffect } from 'react'
// @design
import { DialogContent, Grid } from '@material-ui/core'
import * as ContactItemSC from 'modules/common/components/_UI_SMART/ContactItem/styledComponents/contactItem'
// @common
import useDebounce from 'modules/common/hooks/useDebounce'
import { config } from 'constants/config'
import strings from 'constants/strings'
// @components
import SwitchboardDialog from 'modules/common/components/_UI_DUMB/ModalDialogs/SwitchboardDialog'
import { useLazyQuery, useQuery, useSubscription } from '@apollo/client'
// @context
import { UserContext } from 'modules/user/context/user'
// @graphql
import { GET_USERS } from 'graphql/queries/user.query'
import { GET_USER_CONTACTS } from 'graphql/queries/user.query'
import {
  ContactStatus,
  GetUserContactsQuery,
  GetUserContactsQueryVariables,
  GetUsersQuery,
  GetUsersQueryVariables,
  OnUserCanceledInvitationToContactsSubscription,
  OnUserCanceledInvitationToContactsSubscriptionVariables,
  OnUserDeclinedInvitationToContactsSubscription,
  OnUserDeclinedInvitationToContactsSubscriptionVariables,
  Scalars,
} from 'graphql/graphqlTypes'
import {
  USER_DECLINED_INVITATION_TO_CONTACTS,
  USER_CANCELED_INVITATION_TO_CONTACTS,
  USER_REMOVED_FROM_CONTACTS,
} from 'graphql/subscriptions/user.subscription'
import EmailFieldsList from 'modules/deal/components/EmailFieldsList'
import userActionsHook from 'modules/deal/components/UserRelationsManage/userActionsHook'
import ProfileMyContactsSearch from 'modules/user/components/ProfileMyContactsSearch'
import ConditionalLoader from 'modules/common/components/_UI_DUMB/Loaders/ConditionalLoader'
import UserContactsList from 'modules/deal/components/UserRelationsManage/UserContactsList'

type PropsType = {
  open: boolean
  handleClose: () => void
}

/**
 *
 * @param props
 * @returns {any}
 * @constructor
 */
const ProfileAddContact = ({ open, handleClose }: PropsType) => {
  /*******************************************************
   * STATE
   *******************************************************/
  const [searchTerm, setSearchTerm] = useState<string>('')
  const debouncedSearchTerm = useDebounce(searchTerm, config.debounce)

  /*******************************************************
   * CONTEXT
   *******************************************************/
  const user = useContext(UserContext)

  /*******************************************************
   * GRAPHQL
   *******************************************************/
  // @todo this query is wrong, it's fetching connected users as well
  const { data, refetch, loading, updateQuery, fetchMore } = useQuery<
    GetUsersQuery,
    GetUsersQueryVariables
  >(GET_USERS, {
    fetchPolicy: 'network-only',
    variables: {
      filters: {
        search: debouncedSearchTerm,
      },
    },
    pollInterval: config.pollInterval,
  })

  // @todo this query is wrong, it's assigning all
  const [loadInvites, { data: invitedData, refetch: userContactsRefetch }] =
    useLazyQuery<GetUserContactsQuery, GetUserContactsQueryVariables>(
      GET_USER_CONTACTS,
      {
        fetchPolicy: 'network-only',
        variables: {
          contactStatus: ContactStatus.INVITED,
          filters: {
            search: debouncedSearchTerm,
          },
        },
        pollInterval: config.pollInterval,
      },
    )

  useEffect(() => {
    if (searchTerm) {
      loadInvites()
    }
  }, [searchTerm])

  /**
   * subscriptions
   */
  useSubscription<
    OnUserDeclinedInvitationToContactsSubscription,
    OnUserDeclinedInvitationToContactsSubscriptionVariables
  >(USER_DECLINED_INVITATION_TO_CONTACTS, {
    onSubscriptionData: async ({ subscriptionData }) => {
      await userContactsRefetch()
      await refetch()
    },
  })

  useSubscription<
    OnUserCanceledInvitationToContactsSubscription,
    OnUserCanceledInvitationToContactsSubscriptionVariables
  >(USER_CANCELED_INVITATION_TO_CONTACTS, {
    onSubscriptionData: async ({ subscriptionData }) => {
      await userContactsRefetch()
      await refetch()
    },
  })

  useSubscription(USER_REMOVED_FROM_CONTACTS, {
    onSubscriptionData: async ({ subscriptionData }) => {
      await userContactsRefetch()
      await refetch()
    },
  })

  /*******************************************************
   * HOOKS
   *******************************************************/
  const { handlers } = userActionsHook(refetch)
  //  const { sendEmailInvite, addToContacts } = useEmailInviteActions(
  //    refetch,
  //    myTeamId,
  //  )

  /*******************************************************
   * FUNCTIONS
   *******************************************************/

  /**
   *
   */
  const loadMoreHandler = async () => {
    if (data?.users.meta) {
      const newPageNum = (data.users.meta.page || 0) + 1

      await fetchMore<GetUsersQuery, GetUsersQueryVariables>({
        variables: {
          page: newPageNum,
        },
      })
    }
  }

  /*******************************************************
   * RENDER COMPONENTS
   *******************************************************/
  const emailInvites = (invitedData?.userContacts.data || []).filter(
    (contact) => Number(contact?.id) < 0,
  )

  /*******************************************************
   * RENDER
   *******************************************************/
  const filteredUserData = data?.users.data?.filter(
    (el) => user.user?.id !== el?.id,
  )

  /**
   *
   * @returns {any[] | null | any}
   */
  const isLoadMoreNeeded =
    (data?.users.meta?.totalPages || 0) > (data?.users.meta?.page || 0) + 1

  return (
    <SwitchboardDialog
      open={open}
      onClose={handleClose}
      title={strings.ADD_CONTACT_CTA}
    >
      <DialogContent>
        <ContactItemSC.DialogContactListWrap>
          <Grid container spacing={1} alignItems="center">
            <ProfileMyContactsSearch
              data-testid="add-contact-search-input"
              value={searchTerm}
              onClear={() => setSearchTerm('')}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </Grid>
        </ContactItemSC.DialogContactListWrap>
        <ContactItemSC.DialogContactList data-testid="add-contacts-list">
          <ConditionalLoader condition={!loading}>
            <UserContactsList
              userContactsData={filteredUserData}
              loadMore={{
                isLoadMoreNeeded: isLoadMoreNeeded,
                loadMoreHandler,
              }}
              refetch={async () => {
                await refetch()
                await userContactsRefetch()
              }}
            />
          </ConditionalLoader>
        </ContactItemSC.DialogContactList>
        <EmailFieldsList<
          (userId: Scalars['ID'] | null, email?: string) => Promise<void>
        >
          inviteMenu={[
            {
              handler: (email) =>
                handlers.userAddToContactsHandler(null, email),
              text: 'Invite',
            },
          ]}
        />
      </DialogContent>
    </SwitchboardDialog>
  )
}

export default ProfileAddContact
