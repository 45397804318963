// language=GraphQL
import { gql } from '@apollo/client'
import { FULL_ITEM } from '../fragments/item.fragment'

export const ITEM_UPDATE = gql`
  mutation ItemUpdate($input: UpdateItemInput!) {
    itemUpdate(input: $input) {
      ...FullItem
    }
  }
  ${FULL_ITEM}
`

export const CREATE_ITEM = gql`
  mutation CreateItem($input: CreateItemInput!) {
    itemCreate(input: $input) {
      id
    }
  }
`

export const DELETE_ITEM = gql`
  mutation DeleteItem($id: ID!) {
    itemDelete(id: $id) {
      status
      message
    }
  }
`
export const ATTACH_USER = gql`
  mutation AttachUser($itemId: ID!, $users: [ID!]!) {
    attachUsers(itemId: $itemId, users: $users) {
      id
    }
  }
`

export const UNATTACH_USER = gql`
  mutation UnattachUser($itemId: ID!, $users: [ID!]!) {
    unattachUsers(itemId: $itemId, users: $users) {
      id
    }
  }
`
