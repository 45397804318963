import { useAuth0 } from '@auth0/auth0-react'

/**
 *
 */
export default function useAuth0Wrapper() {
  const { getAccessTokenSilently, getIdTokenClaims } = useAuth0()
  const getAccessTokenWrapper = async (): Promise<string | void> => {
    if (window.Cypress) {
      const auth0LocalResult = localStorage.getItem('auth0Cypress')
      if (auth0LocalResult) {
        const auth0 = JSON.parse(auth0LocalResult)
        return auth0?.access_token
      }
      return await getAccessTokenSilently()
    } else {
      return await getAccessTokenSilently()
    }
  }

  const getIdTokenWrapper = async (): Promise<string | void> => {
    if (window.Cypress) {
      const auth0LocalResult = localStorage.getItem('auth0Cypress')
      if (auth0LocalResult) {
        const auth0 = JSON.parse(auth0LocalResult)
        return auth0?.id_token
      }
      return
    } else {
      const idTokenObject = await getIdTokenClaims() //Auth0Service.getIdToken();
      return idTokenObject?.__raw
    }
  }

  return {
    getAccessTokenWrapper,
    getIdTokenWrapper,
  }
}
