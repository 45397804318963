// @react
import { useContext, ReactElement } from 'react'
import { RouteComponentProps } from 'react-router-dom'
// @design
import * as SC from '../../styledComponents/documents'
import { TableCell, TableRow, Typography } from '@material-ui/core'
// @components
import CenteredLoader from 'modules/common/components/_UI_DUMB/Loaders/CenteredLoader'
import DocumentsSortSearch from '../../components/DocumentsSearch'
import Table from 'modules/common/components/_UI_SMART/SharedTable/TableWrapper/TableWrapper'
import * as SCGLobal from 'modules/common/styledComponents/Global'
import DownloadFileLink from 'modules/common/components/_UI_DUMB/DownloadFileLink/DownloadFileLink'
import ErrorText from 'modules/common/components/_UI_DUMB/ErrorText/ErrorText'
import NotFound from 'modules/common/pages/NotFound'
// @common
import GlobalHelperModel from 'modules/common/models/globalHelper'
import { parseISO, format } from 'date-fns'
import config from 'constants/config'
import strings from 'constants/strings'
// @graphql
import { DOCUMENT_DELETE } from 'graphql/mutations/document.mutation'
import { GET_DOCUMENTS } from 'graphql/queries/document.query'
import { useMutation, useQuery } from '@apollo/client'
// @types
import {
  DocumentsOrderByList,
  Order,
  DealActions,
  ItemActions,
  GetDocumentsQueryVariables,
  GetDocumentsQuery,
  DocumentDeleteMutationVariables,
  DocumentDeleteMutation,
  Scalars,
  Document,
  DocumentsMeta,
} from 'graphql/graphqlTypes'
import { DealContext } from 'modules/deal/contexts/deal'
import DocumentFinalize from 'modules/common/components/_UI_DUMB/DragAndDropArea/components/DocumentFinalize'
import ContextTableWrapper from 'modules/common/components/_UI_SMART/SharedTable/ContextTableWrapper'
import useErrorHandler from 'modules/common/hooks/useErrorHandler'
import FileModel from 'modules/document/models/file'

// @types
const DEAL_ATTACHMENT_DELETE_PERMISSIONS = [DealActions.DEAL_CRUD_DOCS]
const ITEM_ATTACHMENT_DELETE_PERMISSIONS = [
  ItemActions.ITEM_DELETE_ATTACHMENT_MY_TEAM,
  ItemActions.ITEM_DELETE_ATTACHMENT_OWN,
]

interface SelfProps {
  staticProps: {
    filter?: {
      finalized?: boolean
    }
  }
}

type PropsType = RouteComponentProps & SelfProps

/**
 *
 * @param props
 * @constructor
 */
const Documents = ({ staticProps }: PropsType): ReactElement => {
  /*******************************************************
   * CONTEXT
   *******************************************************/
  const queryResult = useContext(DealContext)
  const { dealId } = queryResult
  const showDealFilter = !dealId

  const { filter } = staticProps

  const showFinalizedColumn = !filter?.hasOwnProperty('finalized')

  /*******************************************************
   * GRAPHQL
   *******************************************************/

  /**
   * queries
   */
  const documentQueryResult = useQuery<
    GetDocumentsQuery,
    GetDocumentsQueryVariables
  >(GET_DOCUMENTS, {
    fetchPolicy: 'cache-first',
    // @todo 01-22 why do we only use notifyOnNetworkStatusChange here?
    notifyOnNetworkStatusChange: true,
    variables: {
      orderBy: {
        order: Order.DESC,
        value: DocumentsOrderByList.NAME,
      },
      filters: {
        deal: dealId ? [dealId] : undefined,
        finalized: filter?.hasOwnProperty('finalized')
          ? filter.finalized
          : undefined,
      },
    },
  })
  const { loading, error, data, refetch, fetchMore, variables } =
    documentQueryResult
  useErrorHandler(error)

  /**
   * mutations
   */
  const [deleteDocument, { error: deleteDocumentMutationError }] = useMutation<
    DocumentDeleteMutation,
    DocumentDeleteMutationVariables
  >(DOCUMENT_DELETE)
  useErrorHandler(deleteDocumentMutationError)

  /**
   *
   * @param id
   * @return {Promise<void>}
   */
  const removeDocument = async (id: Scalars['ID']) => {
    void (await deleteDocument({
      variables: { id },
    }))

    if (!deleteDocumentMutationError) {
      void (await refetch())
    }
  }

  /**
   *
   * @param variables
   */
  const dealRefetch = (variables) => {
    const filters = variables.filters
    if (Array.isArray(filters.deal)) {
      filters.deal = filters.deal?.map((it) => Number(it))
    }

    return refetch({ ...variables, filters })
  }

  /**
   *
   * @return {any}
   */
  const renderTableData = () => {
    if (error) {
      console.log(error)
      return <ErrorText errorText={`${strings.GENERIC_ERROR} `} />
    }

    if (data) {
      if (data.documents.data?.length === 0) {
        return <ErrorText errorText={strings.NO_DOCUMENTS} />
      }

      return data.documents.data?.map((document, key: number) => {
        const fileActions = document?.file?.actions
        const isAllowedRemoving = fileActions
          ? ITEM_ATTACHMENT_DELETE_PERMISSIONS.some((action) =>
              fileActions.includes(action),
            )
          : DEAL_ATTACHMENT_DELETE_PERMISSIONS.some((action) =>
              document?.deal?.actions?.includes(action),
            )

        return (
          <TableRow
            key={key}
            component={DownloadFileLink}
            link={FileModel.getDownloadLink(
              document?.file?.location || '',
              document?.file?.versionId || '',
            )}
            className="hover-row-handler"
          >
            <TableCell component="div">
              <Typography variant="subtitle1" className="underline">
                {document?.file?.originalName}
              </Typography>
              <SC.MobileDateText>
                {typeof document?.file?.size === 'number' &&
                  GlobalHelperModel.formatBytes(document.file.size, 0)}
              </SC.MobileDateText>
            </TableCell>
            <TableCell component="div">{document?.deal?.name}</TableCell>
            <TableCell component="div">
              {format(
                parseISO(document?.createdDate),
                config.dateSlashedFormat,
              )}
            </TableCell>
            <TableCell component="div">
              {typeof document?.file?.size === 'number' &&
                GlobalHelperModel.formatBytes(document.file.size, 0)}
            </TableCell>
            {showFinalizedColumn && (
              <SC.FinalizedTableCell component="div">
                <DocumentFinalize
                  document={document as Document}
                  disabled={true}
                />
              </SC.FinalizedTableCell>
            )}
            <SC.ActionCell component="div">
              {isAllowedRemoving && (
                <SC.CenteredWrapper className="show-on-hover">
                  <SC.CanceLButton
                    onClick={(event) => {
                      event.preventDefault()
                      if (document?.id) {
                        removeDocument(document.id).catch((e) => console.log(e))
                      }
                    }}
                    color="secondary"
                    component="span"
                  >
                    <SC.CancelIcon />
                  </SC.CanceLButton>
                </SC.CenteredWrapper>
              )}
            </SC.ActionCell>
          </TableRow>
        )
      })
    } else {
      return <CenteredLoader />
    }
  }

  /**
   *
   */
  const headerRow = [
    { name: strings.NAME, value: DocumentsOrderByList.NAME },
    { name: strings.DEAL, value: DocumentsOrderByList.DEAL },
    { name: strings.DATE_CREATED, value: DocumentsOrderByList.CREATED_DATE },
    { name: strings.FILE_SIZE, value: DocumentsOrderByList.SIZE },
  ]

  if (showFinalizedColumn) {
    headerRow.push({
      name: strings.FINAL_STATE,
      value: DocumentsOrderByList.FINALIZED,
    })
  }

  /*******************************************************
   * RENDER
   *******************************************************/

  if (dealId && !queryResult.data && !queryResult.loading) {
    return <NotFound />
  }

  /**
   *
   */
  return (
    <SC.Wrapper data-testid="page-documents">
      <ContextTableWrapper
        queryResult={documentQueryResult}
        refetch={refetch}
        refetchOverride={dealRefetch}
        headerRow={headerRow}
      >
        <SCGLobal.PushContainer>
          <DocumentsSortSearch showDealFilter={showDealFilter} />
        </SCGLobal.PushContainer>
        <SC.DocumentsTableWrap>
          <Table
            refetch={refetch}
            className="table-shared m-0"
            loading={loading}
            headerRow={headerRow}
            bodyRow={renderTableData()}
            // @todo 01-22 fix this type
            dataMeta={(data?.documents.meta as DocumentsMeta) || {}}
            fetchMore={fetchMore}
            queryName="documents"
            variables={variables}
          />
        </SC.DocumentsTableWrap>
      </ContextTableWrapper>
    </SC.Wrapper>
  )
}

export default Documents
