import * as SC from './styledComponents'
import BROWSER_HISTORY from 'utils/history'
import { useLocation } from 'react-router-dom'

type Props = {
  isActive?: boolean
  onClick?: () => void
  labelText: string
  count?: number | null
  disabled?: boolean
  url?: string
}

const ChipBtn = ({
  onClick,
  isActive,
  labelText,
  count,
  disabled = false,
  url,
}: Props) => {
  const labelCount = typeof count === 'number' && (
    <SC.ChipCount>{String(count)}</SC.ChipCount>
  )
  const location = useLocation()
  const path = location.pathname

  const onChipClick = () => {
    url && BROWSER_HISTORY.push(url)
    onClick && onClick()
  }

  const isChipActive = url ? url === path : isActive

  const chip = (
    <SC.ChipBtn
      onClick={onChipClick}
      data-active={isChipActive}
      disabled={disabled}
      label={
        <SC.TabLabel>
          {labelCount}
          {labelText}
        </SC.TabLabel>
      }
    />
  )

  return disabled ? <SC.DisabledWrapper>{chip}</SC.DisabledWrapper> : chip
}

export default ChipBtn
