import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { device, from } from 'modules/common/styledComponents/MediaQueries'

export const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 700px;
  margin: 4em auto 2em;
  align-items: center;
  text-align: center;
`

export const Title = styled.h1`
  margin-bottom: 1.5em;
  padding: 0 1em;
  font-size: 30px;
  font-weight: 600;
  letter-spacing: 1px;

  ${from(device.ipadL)} {
    font-size: 40px;
  }

  small {
    display: block;
    letter-spacing: 0.75px;
    margin-top: 0.3em;
    font-size: 20px;
    font-weight: 400;

    ${from(device.ipadL)} {
      font-size: 30px;
    }
  }
`

export const StyledLink = styled(Link)`
  && {
    text-decoration: none;
  }
`
