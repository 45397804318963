import styled from 'styled-components'
import colors from 'constants/colors'
import { Button } from '@material-ui/core'

export const StyledSectionTitle = styled.div`
  margin-top: 30px;
  font-family: Open Sans;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: ${colors.dark};

  & .MuiButton-root {
    text-transform: none;
  }
`

export const SkipButton = styled(Button)`
  && {
    text-decoration: underline;
    margin-bottom: 4px;
    font-size: 14px;
    font-family: Open Sans;
    font-weight: 600;

    &: hover {
      background: none;
    }
  }
`

export const Wrapper = styled.div`
  .MuiGrid-item {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
  .MuiGrid-spacing-xs-2 {
    margin-top: 0px !important;
  }
`
