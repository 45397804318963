import { config } from './config'

export const strings = {
  /**
   * DEALS
   */
  DEAL: 'Deal',
  ALL_DEALS: 'All deals',
  PENDING_DEALS: 'Pending deals',
  SENT_DEALS: 'Sent deals',
  EDIT_DEAL: 'Edit Deal',
  DEAL_ACTIONS: 'Deal Actions',
  EDIT_SUMMARY: 'Edit Summary',
  DESCRIPTION: 'Description',
  SECTOR: 'Sector',
  SUBSECTOR: 'Subsector',
  BRAND: 'Brand',
  HOTEL_MANAGEMENT: 'Hotel management',
  NUMBER_OF_ROOMS: 'Number of rooms',
  NUMBER_EVENT_SPACES: 'Number of event spaces',
  NUMBER_OF_FLOORS: 'Number of floors',
  OCCUPANCY: 'Occupancy',
  WALE: 'WALE',
  CLEAR_HEIGHT: 'Clear height',
  LOADING_TYPE: 'Loading type',
  DOCK_HIGH_DOORS: 'Dock high doors',
  ACREAGE: 'Acreage',
  PARKING_STALLS: 'Parking stalls',
  DEBT: 'Debt',
  ZONING: 'Zoning',
  ENTITLEMENTS: 'Entitlements',
  INTEREST: 'Interest',
  PRODUCT_TYPE: 'Product type',
  DEAL_NAME: 'Deal Name',
  YEAR_OF_CONSTRUCTION: 'Year of construction',
  RENTABLE_AREA: 'Rentable area',
  LAND_AREA: 'Land area',
  SITE_AREA: 'Site area',
  TOTAL_RENTABLE_AREA: 'Total rentable area',
  NUMBER_OF_PROPERTIES: 'Number of properties',
  PROPERTY_TYPE: 'Property Type',
  TENANCY: 'Tenancy',
  UNIT: 'Unit',
  LIVE: 'Live',
  APPLY: 'Apply',
  DELETE_DEAL: 'Delete Deal',
  CLOSE_DEAL: 'Close Deal',
  UNCLOSE_DEAL: 'Unclose Deal',
  FINALIZE_DEAL: 'Finalize Deal',
  UNFINALIZE_DEAL: 'Unfinalize Deal',
  MARK_CLOSED: 'close item',
  MARK_OPEN: 'reopen item',
  SUMMARY: 'Summary',
  DATES_AND_METRICS: 'Dates and Metrics',
  TEAM: 'Team',
  SEE_ALL_ACTIVE_DEALS: 'See All Active Deals',
  ACCEPT_DEAL: 'Accept deal',
  ACTIVE_DEALS: 'Active Deals',
  DEAL_INVITES: 'Deal invites',
  SEE_ALL_CONTACT_REQUESTS: 'See All Contact Requests',
  CONTACT_REQUESTS: 'Contact requests',
  SEE_ALL_INVITES: 'See All Invites',
  SEE_ALL_NOTIFICATIONS: 'See All Notifications',
  RECENT_NOTIFICATIONS: 'Recent Notifications',
  CREATE_NEW_DEAL: 'Create New Deal',
  INACTIVE: 'Inactive',
  SINGLE: 'Single',
  PORTFOLIO: 'Portfolio',
  OPTIONAL_DEAL_DETAILS: 'Optional Deal Details',
  TABLE_FILTER_SEARCH: 'Contains text...',

  /**
   * DEAL: STAGE
   */
  PREVIOUS_STEP: 'Previous Step',
  PREVIOUS_STAGE: 'Previous Stage',
  NEXT_STAGE: 'Next Stage',
  REOPEN_DEAL: 'REOPEN DEAL',
  SAVE_AND_CLOSE: 'Save and Close',
  CONTINUE_SOLO: 'Continue Solo',
  NEXT_STEP: 'Next Step',
  STAGE_VIEW: 'Stage View',
  STAGE: 'Stage',
  CARD_VIEW: 'Card View',
  DEAL_STAGE_ERROR_DRAFT:
    'You cannot visit the stage view in while a deal is in draft mode.',
  DEAL_METRICS_ERROR:
    'You cannot view the Dates and Metrics section at this stage of the deal.',
  DEAL_ITEMS_ERROR_DRAFT:
    'You cannot view the deal items while a deal is in draft mode.',
  DEAL_ERROR_DRAFT: 'You cannot see this item while the deal is in draft mode',
  MOVE_STAGE: 'Move Stage',

  /**
   * DEAL: ITEMS TABS
   */
  LIST: 'List',
  CARDS: 'Cards',
  /**
   * DEAL: MIGRATE
   */
  MOVE_DEAL: 'Move deal',
  MOVE_DEAL_DETAILS:
    "Select the items below you'd like to migrate <br> Keep in mind you cannot see all items! <br> You can select items individually or choose to <br> 1) Migrate all current open items. Items will remain open, but be active in the new stage <br> 2) Close all current items. Items will stay in the current stage with status: 'CLOSED', however you can still access them, and re-open later",
  MOVE_DEAL_SUMMARY: 'Here you can move your deal to a different stage.',
  MOVE_DEAL_SUMMARY_V2: 'You are about to move the deal to another stage.',
  MOVE_ALL_ITEMS: 'Move all open items',
  CLOSE_ALL_ITEMS: 'Close all items in current stage',
  LET_ME_CHOOSE: 'Let me choose',

  /**
   * DEAL: METRICS DATES
   */
  MD_EDIT_BUTTON: 'EDIT DATES',
  MD_TITLE: 'Key Dates',
  MD_BID_DATE: 'Bid date',
  MD_INVESTMENT_COMMITTEE: 'Investment committee',
  MD_END_DATE: 'Due diligence end date',
  MD_CLOSING_DATE: 'Estimated closing date',
  /**
   * DEAL: METRICS TARGETS
   */
  MT_EDIT_BUTTON: 'EDIT TARGETS',
  MT_TITLE: 'Transaction Targets',
  MT_LTV: 'LTV',
  MT_GROSS_UNLEVERED_IRR: 'Gross unlevered IRR',
  MT_GROSS_LEVERED_IRR: 'Gross levered IRR',
  MT_NET_LEVERED_IRR: 'Net levered IRR',
  MT_MULTIPLE_ON_INVESTED_CAPITAL: 'Multiple on invested capital',
  MT_GROSS_ASSET: 'Gross asset value',
  MT_NET_ASSET: 'Net asset value',
  MT_CURRENCY: 'Currency',
  MT_CAP_RATE: 'Cap rate / yield',
  MT_HOLDING_PERIOD: 'Holding period',

  /**
   * CONTACTS
   */
  CONTACT: 'Contact',
  SEL_ALL_CONTACTS: 'See all contacts',
  REMOVE_CONTACT: "REMOVE CONTACT<br>This action can't be undone.",
  ADD_CONTACT: 'Invite user to your contacts',
  REMOVE_TEAM_MEMBER: 'Remove team member',
  LEAVE_DEAL: 'Leave deal',
  LEAVE_TEAM: 'Leave team',
  MAKE_TEAM_LEAD: 'Make team lead',
  /**
   * COMMENTS
   */
  EDIT_COMMENT: 'Edit Comment',
  DELETE_COMMENT: 'Delete Comment',
  EDIT: 'Edit',
  DELETE: 'Delete',
  /**
   * INVITES
   */
  INVITE_DECLINE: "DECLINE INVITE<br>This action can't be undone.",
  INVITE_ACCEPT: 'Accept Invite',
  ACCEPT: 'Accept',
  INVITE_CANCEL: "CANCEL INVITE<br>This action can't be undone.",
  /**
   * TEAMS
   */
  ITEM: 'Item',
  TEAM_REMOVE_USER: 'Remove User from Team',
  TEAM_REMOVE_SELF: "LEAVE TEAM<br>This action can't be undone.",
  TEAM_JOIN: 'Join Team',
  MY_TEAM: 'My Team',
  THEIR_TEAM: 'Their Team',
  EDIT_TEAM_CTA: 'Manage Team',
  BOTH_TEAMS: 'Both Teams',
  LEAD: 'Lead',

  /**
   * ITEMS
   */
  ITEMS: 'Items',
  OWNER: 'Owner',
  ITEM_OWNER: 'Item Owner',
  ITEM_DESCRIPTION: 'Item Description',
  ITEM_NAME: 'Item Name',
  VISIBILITY: 'Visibility',
  CLOSE: 'Close',
  CLOSED: 'Closed',
  OPEN: 'Open',
  CREATE_ITEM: 'Create Item',
  EDIT_ITEM: 'Edit Item',
  ADD_THIRD_PARTY: 'Add Third Party',
  REMOVE_FROM_ITEM: 'Remove from item',
  LEAVE_ITEM: 'Leave item',
  THIRD_PARTIES: 'THIRD PARTIES',
  /**
   * DOCUMENTS
   */
  UPLOAD_DEAL_IMAGES: 'Upload deal images',
  UPLOAD_PITCH_BOOK: 'Upload pitchbook',
  UPLOAD_CONFI: 'Upload confidentiality agreement',
  UPLOAD_DEAL_DOCS: 'Upload supporting deal documents',
  UPLOAD: 'Upload',
  SAVE: 'SAVE',
  CHOOSE_IMAGE: 'Select Image',
  UPDATE_IMAGE_CONFIRM: 'Are you sure you want to update this image?',
  SUCCESS_UPDATE: 'Successfully updated',
  UPDATE_IMAGE: 'Update Image',
  UPDATE_PROFILE_IMAGE: 'Update profile image',
  ADD_PROFILE_IMAGE: 'Add profile image',
  REMOVE_IMAGE: 'Remove image',
  DOCUMENTS: 'Documents',
  DROP_FILES_ANYWHERE: 'Drop files anywhere to upload or',
  SELECT_FILES: 'Select Files',
  SELECT_FILE: 'Select File',
  REMOVE_DOCUMENT: "DELETE DOCUMENT<br>This action can't be undone.",
  FINAL_STATE: 'Final State',

  /**
   * DASHBOARD
   */
  DASHBOARD_NO_CONTACTS: 'You have no contact invites.',
  DASHBOARD_NO_CONTACTS_EXPLORE: 'View your contacts',
  DASHBOARD_NO_DEALS_INVITES: "You have no deal invites.<br />What's next?",
  DASHBOARD_NO_DEALS_ACTIVE: "You have no active deals! <br />What's next?",
  DASHBOARD_NO_DEALS_EXPLORE: 'Explore your deals',
  DASHBOARD_NO_DEALS_CREATE: 'Create a deal',

  /**
   * NOTIFICATIONS
   */
  MARK_ALL_READ: 'Mark All as Read',
  /**
   * MESSAGES
   */
  NEW_MESSAGE: 'New Message',
  NO_MESSAGES: 'No Messages Yet',
  /**
   * USER:PROFILE
   */
  WELCOME_TITLE: 'Welcome to SwitchBoard!',
  WELCOME_CONTENT:
    'Finish filling out your profile information to start collaborating with your colleagues.<br><br>We know you’re busy, this will only take a moment.',
  WELCOME_CTA: 'Fill out my profile',
  PASSWORD_RESET_LINK:
    'Click the button below to reset your password. <br> An email will be sent to your address with a link and instructions.',
  RESET: 'Reset',
  IMPORT_FROM_LINKEDIN: 'Import from LinkedIn',
  IMPORT_FROM_LINKEDIN_CONFIRM:
    'Are you sure you want to import your profile image from LinkedIn?',
  UPDATE_SETTINGS: 'UPDATE SETTINGS',
  EMAIL_NOTIFICATIONS: 'Email Notifications',
  EMAIL_NOTIFICATIONS_LABEL: 'I want to receive email notifications',
  EMAIL_NOTIFICATIONS_LABEL_CRITICAL:
    'I only want to receive critical notifications',
  EMAIL_NOTIFICATIONS_LABEL_IMPORTANT:
    'I want to receive important and critical notifications',
  EMAIL_NOTIFICATIONS_LABEL_ALL: 'I want to receive all notifications',
  NOTIFICATIONS_LEVEL: 'Notifications Level',
  CONTACTS: 'Contacts',
  ACCOUNT_SETTINGS: 'Account Settings',
  INFORMATION_NOT_FILLED:
    'We need a little more information before you can unlock the rest of the site!',
  CONTACT_SEARCH: 'Search by name...',
  VIEW_BY: 'View by',
  UPDATE_PROFILE: 'Update profile',
  GET_STARTED: 'Save profile and get started',
  // not found
  // @todo should probably use variable substitution for these elements

  BACK_TO_HOME: 'BACK TO DASHBOARD',
  BACK_TO_MAIN_MENU: 'Back to main menu',
  TO_HOME: 'HOME',
  /**
   * USER:ACCOUNT
   */
  DEACTIVATE_ACCOUNT: 'Deactivate account',
  DEACTIVATE_ACCOUNT_CONFIRM:
    'Are you sure you want to deactivate this account? You will not be able to reactivate it.',
  LAST_NAME: 'Last Name',
  FIRST_NAME: 'First Name',
  COUNTRY: 'Country',
  COMPANY: 'Company',
  ADDRESS: 'Address',
  CITY: 'City',
  STATE: 'State',
  PROVINCE: 'Province',
  ZIP: 'Zip/Postal Code',
  TITLE: 'Title',
  EMAIL: 'Email',
  PROFILE: 'Profile',
  PASSWORD: 'Password',
  /**
   * ERRORS
   */
  GENERIC_ERROR: 'An error occurred',
  NO_DEALS: 'No deals yet',
  NO_ITEMS: 'No items yet',
  NO_DOCUMENTS: 'No documents yet',
  NO_NOTIFICATIONS: 'No notifications yet',
  NOT_FOUND_MESSAGE:
    'Oh no! <small>We can’t seem to be able to find the page you’re looking for!</small>',
  NOT_AUTHORIZED:
    'You are not authorized yet to use this platform, please contact <a href="mailto:admin@switchboard.exchange">admin@switchboard.exchange</a> to request access',
  ERROR_INVALID_EMAIL: 'This is not a valid email',
  ERROR_CREATING_DEAL:
    'There was an error creating the deal, please try again!',
  ERROR_FETCHING:
    'There was an error getting data from the server, please refresh the page!',
  ERROR_FILE_NOT_UPLOADED: 'The file was not uploaded!',
  ERROR_SOME_FILES_NOT_UPLOADED: 'Some files were not uploaded!',
  ERROR_FILES_NOT_UPLOADED: 'Files were not uploaded!',
  ERROR_MAX_FILE_SIZE: `The maximum file size is ${
    config.maxFileSize / (1024 * 1024)
  } MB.`,
  /**
   * GENERIC
   */
  CANCEL: 'Cancel',
  PROCEED: 'Proceed',
  YES: 'Yes',
  NO: 'No',
  LAST_UPDATED: 'Last updated',
  ADD: 'ADD',
  SELECT_ALL: 'Select All',
  FROM: 'From',
  STATUS: 'Status',
  CREATOR: 'Creator',
  RECEIVED: 'Received',
  PENDING_INVITES: 'Pending invites',
  SENT_TO: 'Sent to',
  DATE_SENT: 'Date sent',
  CREATED_BY: 'Created by',

  DONE: 'Done',
  /**
   * FORMS
   */
  ALL_DATES: 'All Dates',
  DATE: 'Date',
  DATE_CREATED: 'Date Created',
  NAME: 'Name',
  FILE_SIZE: 'File Size',
  CLEAR_SELECTION: 'Clear Selection',

  /**
   * CONTACT LIST
   */
  SEARCH_NAME_HINT: 'Search for a user by name or email',
  NOT_LISTED: 'Not listed Above?',

  /**
   * NETWORK
   */
  NETWORK_EMPTY_SEARCH: (searchTerm: string) =>
    `We couldn’t find any match for "${searchTerm}"`,
  NETWORK_EMPTY_CONTACTS:
    "It's lonely out here...<br />Invite your team members to join.",
  NETWORK_EMPTY_SENT:
    'You have no sent invites.<br />Invite your team members to join.',
  NETWORK_EMPTY_PENDING:
    'You have no pending invites...<br />But you can be the first one to make a move!',
  NETWORK_PENDING_MORE: 'Good job!<br />Don’t stop there.',
  ADD_CONTACT_CTA: 'Add contact',

  /**
   *
   */
  SENT: 'Sent',
  INVITES: 'Invites',
  MY: 'My',
  PENDING: 'Pending',
  SEE_ALL: 'See all',

  /**
   * MENU
   */
  HOME: 'Home',
  NETWORK: 'Network',
  DASHBOARD: 'Dashboard',
  MY_PROFILE: 'My Profile',
  MY_ITEMS: 'Items',
  MY_DEALS: 'Deals',
  MY_DOCUMENTS: 'Documents',
  PIPELINE: 'Pipeline',
  NOTIFICATIONS: 'Notifications',
  MESSAGES: 'Messages',
  SIGN_OUT: 'Sign out',
  ALL_ITEMS: 'All Items',
  OPEN_ITEMS: 'Open Items',
  CLOSED_ITEMS: 'Closed Items',

  /***
   * STATIC
   */
  TERMS_AND_CONDITIONS: 'Terms and Conditions',

  /***
   * PIPELINE
   */
  PL_TITLE: 'Pipeline by stage',
  PL_FILTER_TITLE: 'Refine report',
  PL_DISPLAYED_CURRENCY: 'Displayed currency',
  PL_SHOW_BY: 'Show by',
  PL_CHART_FORMAT: 'Chart format',
}

export default strings
