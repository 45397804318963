import { TableCell, TableHead, TableRow } from '@material-ui/core'
import colors from 'constants/colors'
import styled from 'styled-components'

export const TableCellStyled = styled(TableCell)`
    &.MuiTableCell-body {
        text-align: right;
        border: 1px solid ${colors.veryLightBlue};
        ${(props) =>
          props.iseven === 'true' ? `background-color: ${colors.paleGrey}` : ''}
`

export const TableHeadStyled = styled(TableHead)`
    .MuiTableHead-root {
        border: 0px;
`

export const TableCellNBStyled = styled(TableCell)`
  &.MuiTableCell-body {
    text-align: right;
    border: 0px;
    float: right;
  }
`
export const TableCellNoBorderStyled = styled(TableRow)`
  &.MuiTableRow-root {
    border: 0px !important;
  }
`
