import { Step, StepLabel, Stepper } from '@material-ui/core'
import { colors } from 'constants/colors'
import styled from 'styled-components'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'

export const StyledStepper = styled(Stepper)`
  && {
    padding-right: 0;
    padding-left: 0;
    background-color: transparent;
  }
`

export const IconWrap = styled.div`
  display: flex;
  padding: 14px;
  margin-top: 3px;
  margin-right: -8px;
  border-radius: 50%;

  ${(props) =>
    props.active &&
    `
        background-color: ${colors.aquaMarineLight},
    `}
`

export const Icon = styled.span`
  position: relative;
  width: 0;
  height: 0;
  left: 2px;
  border-top: 10px solid transparent;
  border-left: 20px solid ${colors.aquaMarine};
  border-bottom: 10px solid transparent;
`

export const DownIcon = styled(ArrowDropDownIcon)`
  && {
    position: absolute;
    top: -31px;
    width: 100%;
    display: block;
    color: ${colors.aquaMarine};
  }
`

export const StyledStepLabel = styled(StepLabel).attrs({
  classes: {
    iconContainer: 'container',
    label: 'label',
  },
})`
  .container {
    ${(props) =>
      !props['data-first-step'] &&
      `
        cursor: pointer;
    transition: all 0.2s ease;

    &:hover {
      transform: scale(1.1);
    }
    `}
  }

  .label {
    font-size: 14px;
  }
`

export const StyledStep = styled(Step)`
  && {
    &.currentStep {
      .MuiStepIcon-root {
        background-color: #cdf4ed;
        border-radius: 50%;
      }
      .MuiStepLabel-label {
        color: ${colors.aquaMarine};
      }
    }
    &.completedStep {
      .MuiSvgIcon-root {
        color: ${colors.aquaMarine};
      }
    }
    &.inactiveStep {
      svg {
        color: ${colors.medGrey};
      }
      .MuiStepConnector-root span {
        border-color: ${colors.medGrey};
      }
    }
  }
`
