// language=GraphQL
import { gql } from '@apollo/client'
import { DEAL_FRAGMENT } from '../fragments/deal.fragment'

export const ADD_DEAL = gql`
  mutation AddDeal($DealInput: CreateDealInput!) {
    dealCreate(input: $DealInput) {
      id
      createdDate
      createdBy {
        id
      }
      originatingTeam {
        id
      }
    }
  }
`

export const DEAL_LAUNCH_SOLO = gql`
  mutation ContinueSolo($id: ID!) {
    dealContinueSolo(id: $id) {
      id
    }
  }
`

export const DEAL_COPY = gql`
  mutation CopyDeal($id: ID!) {
    dealCopy(id: $id) {
      id
    }
  }
`

export const DEAL_KILL = gql`
  mutation KillDeal($id: ID!) {
    dealKill(id: $id) {
      id
    }
  }
`

export const DEAL_UNKILL = gql`
  mutation UnkillDeal($id: ID!) {
    dealUnkill(id: $id) {
      id
    }
  }
`

export const DEAL_CLOSE = gql`
  mutation CloseDeal($id: ID!) {
    dealClose(id: $id) {
      id
    }
  }
`

export const DEAL_UNCLOSE = gql`
  mutation UncloseDeal($id: ID!) {
    dealUnclose(id: $id) {
      id
    }
  }
`

export const DEAL_DELETE = gql`
  mutation DeleteDeal($id: ID!) {
    dealDelete(id: $id) {
      message
      status
    }
  }
`

export const DEAL_UPDATE = gql`
  mutation UpdateDeal($deal: UpdateDealInput!) {
    dealUpdate(input: $deal) {
      ...DealNode
    }
  }
  ${DEAL_FRAGMENT}
`

export const DEAL_ADD_DOCUMENTS = gql`
  mutation AddDealDocuments($id: ID!, $documents: [DealDocumentInput!]!) {
    dealDocumentAdd(id: $id, documents: $documents) {
      ...DealNode
    }
  }
  ${DEAL_FRAGMENT}
`

export const DEAL_MIGRATE = gql`
  mutation MigrateDeal($id: ID!, $direction: DealStageDirection) {
    dealMigrate(id: $id, direction: $direction) {
      ...DealNode
    }
  }
  ${DEAL_FRAGMENT}
`
