// language=GraphQL
import { gql } from '@apollo/client'
import { MESSAGE_ITEM } from '../fragments/message.fragment'

export const MESSAGE_UPDATE_SUBSCRIPTION = gql`
  subscription onMessageUpdated {
    messageUpdated {
      id
    }
  }
`

export const MESSAGE_CREATE_SUBSCRIPTION = gql`
  subscription onMessageCreated {
    messageCreated {
      ...MessageItem
    }
  }
  ${MESSAGE_ITEM}
`

export const MESSAGE_CREATE_UNREAD_COUNT = gql`
  subscription onMessageUnreadCountUpdate {
    messageUnreadCountUpdated
  }
`
