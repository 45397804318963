import styled from 'styled-components'
import { Avatar, Typography } from '@material-ui/core'
import { device, from } from 'modules/common/styledComponents/MediaQueries'
// @graphql

export const Title = styled(Typography)`
  && {
    margin-bottom: 15px;
  }
`
export const StyledAvatar = styled(Avatar)`
  && {
    width: 130px;
    height: 130px;
    margin-right: 1.3em;

    ${from(device.mobileL)} {
      width: 170px;
      height: 170px;
      margin-right: 28px;
    }

    ${from(device.ipad)} {
      width: 210px;
      height: 210px;
    }
  }
`
