/*******************************************************
 * NOTE:
 * This component is instead meant to be used "inline",
 * usually as a part of <InlineForm> or when you need a
 * standalone/separate component with more control over
 * various events, presentation and overall behavior.
 * <InlineForm> internally also relies on react-hook-form
 * and generates Controller wrappers for the "inline"
 * inputs (like this one) that were passed in.
 *
 * This component has the "regular" counterpart (./index)
 * which is instead used as a part of react-hook-form.
 * The main differences between "regular" and "inline" is
 * that the "regular" one comes wrapped with RHF's Controller
 * whereas "inline" one gets wrapped by <InlineForm> when
 * it clones the children.
 *
 * Ideally, everything this "inline" component does should
 * also be handled by the "regular" counterpart with potentially
 * some sort of "inline" (boolean) prop passed in. However,
 * that would also make the final component code messy since
 * the markup differences are big and essentially require either
 * a fully modular component, where we can somehow only use bits
 * we're interested in, or keeping the duplicate as we do now since
 * the differences in markup and behavior are so big that it's
 * justified.
 *******************************************************/

// @react
import React from 'react'
import {
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
} from '@material-ui/core'
import { colors } from 'constants/colors'
import * as SC from 'modules/deal/common/styledComponents'
/*******************************************************
 * CONST
 *******************************************************/
export const style = () => ({
  wrap: {
    width: '100%',
    marginBottom: '10px',
  },
  select: {
    '&& p': {
      fontSize: '14px',
      color: `${colors.dark}`,
    },
  },
})
const useStyles = makeStyles(style)
/*******************************************************
 * TYPES
 *******************************************************/
export type PropsType = {
  label?: string
  className?: string
  [custom: string]: any
  withoutLabel?: boolean
  name?: string
  options: ({ label: string; value: string } | string)[]
}

/**
 *
 * @param children
 * @param input
 * @param label
 * @param inputProps
 * @param error
 * @param isSubmitting
 * @param isSubmitSuccessful
 * @param name
 * @param withoutLabel
 * @param options
 * @param inputLabelProps
 * @param custom
 * @constructor
 */
const FormSelect: React.FunctionComponent<PropsType> = ({
  children,
  input,
  label,
  inputProps,
  error,
  isSubmitting,
  isSubmitSuccessful,
  name,
  withoutLabel,
  options,
  inputLabelProps = {},
  ...custom
}) => {
  /*******************************************************
   * CONST
   *******************************************************/
  const classes = useStyles()

  const isHidden = custom.hidden ? 'd-none' : ''
  const submitFailed = !isSubmitSuccessful
  const isError = (isSubmitting && !!error) || (submitFailed && !!error)

  /*******************************************************
   * FUNCTIONS
   *******************************************************/
  const renderLabel = () => {
    if (withoutLabel) return
    return label ? (
      <InputLabel shrink={inputLabelProps.shrink}>{label}</InputLabel>
    ) : null
  }

  /*******************************************************
   * RENDER
   *******************************************************/
  return (
    <FormControl
      className={[custom.className, isHidden, classes.wrap].join(' ')}
      error={isError}
    >
      {renderLabel()}

      <Select
        {...(input as any)}
        {...custom}
        classes={{
          select: classes.select,
        }}
        input={<Input name={name} />}
        name={name}
        error={isError}
      >
        {options.map((item) => {
          const value = typeof item === 'string' ? item : item.value
          const label = typeof item === 'string' ? item : item.label
          return (
            <MenuItem key={value} value={value}>
              <SC.OptionItem>{label}</SC.OptionItem>
            </MenuItem>
          )
        })}
      </Select>
      {
        // @todo we're still allowing the option to pass some custom values in here,
        // right now we don't control for custom versus native error (from validation Model)
      }
      {custom.helperText && !custom.errorText && (
        <FormHelperText>{custom.helperText}</FormHelperText>
      )}
      {custom.errorText && isError && (
        <FormHelperText>{custom.errorText}</FormHelperText>
      )}
      {error && isError && <FormHelperText>{error}</FormHelperText>}
    </FormControl>
  )
}

export default FormSelect
