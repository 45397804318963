import { Fab } from '@material-ui/core'
import styled from 'styled-components'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import { colors } from 'constants/colors'

export const StyledFabLeft = styled(Fab)`
    && {
    left: -18px;
    width: 21px;
    height: 21px;
    min-height: 21px;
    background-color: ${colors.veryLightBlue};
    top: 140px;
    position: absolute;
    box-shadow: none;
    ${(props) =>
      props.issingle === 'true' &&
      `
    top: 168px;
    `}
    &:hover: {
      background-color: ${colors.veryLightBlue};
    },
    }
`

export const StyledFabRight = styled(Fab)`
    && {
    right: -18px;
    width: 21px;
    height: 21px;
    min-height: 21px;
    background-color: ${colors.veryLightBlue};
    top: 140px;
    position: absolute;
    box-shadow: none;
    ${(props) =>
      props.isSingle === 'true' &&
      `
    top: 168px;
    `}
    '&:hover': {
      background-color: ${colors.veryLightBlue};
    },
    }
`
export const StyledKeyboardArrowLeftIcon = styled(KeyboardArrowLeftIcon)`
  &&{
    width: 15px,
    height: 15px,
    margin-top: -1px;
    &:hover {
      color: ${colors.white};
    }
  }
`
export const StyledKeyboardArrowRightIcon = styled(KeyboardArrowRightIcon)`
 &&{
     width: 15px,
    height: 15px,
    margin-top: -1px;
    &:hover {
      color: ${colors.white};
    }
  }
`
