import React from 'react'
import { Button } from '@material-ui/core'
import { Link } from 'react-router-dom'
import * as SC from 'modules/common/components/_UI_DUMB/Section/SectionEmpty/styledComponents'
import htmlParser from 'html-react-parser'

type PropsType = {
  title: string
  illustration?: {
    src: string
    placement: 'top' | 'bottom'
  }
  cta?: {
    type?: 'primary'
    label: string
    href?: string
    onClick?: () => void
  }[]
  modal?: React.ReactNode
}

const SectionEmpty = ({ title, illustration, cta, modal }: PropsType) => {
  const ctaButtons = cta
    ? cta.map((button) => {
        return (
          <Button
            key={button.label}
            variant="contained"
            color={button.type}
            onClick={button.onClick}
            component={button.href ? Link : 'button'}
            to={button.href ? button.href : ''}
          >
            {button.label}
          </Button>
        )
      })
    : null

  return (
    <SC.Wrap>
      {illustration && illustration.placement === 'top' ? (
        <SC.Illustration placement={illustration.placement}>
          <img src={illustration.src} alt="Illustration" />
        </SC.Illustration>
      ) : null}

      <SC.Title>{htmlParser(title)}</SC.Title>

      {ctaButtons ? <SC.ButtonWrap>{ctaButtons}</SC.ButtonWrap> : null}

      {illustration && illustration.placement === 'bottom' ? (
        <SC.Illustration>
          <img src={illustration.src} alt="Illustration" />
        </SC.Illustration>
      ) : null}

      {modal}
    </SC.Wrap>
  )
}

export default SectionEmpty
