// @react
import { ReactElement } from 'react'
// @common
import { format, parseISO } from 'date-fns'
import config from 'constants/config'
import strings from 'constants/strings'
// @UI
import { TableCell, TableRow, Typography } from '@material-ui/core'
import * as SC from './styledComponents/dealsList'
// @components
import CenteredLoader from 'modules/common/components/_UI_DUMB/Loaders/CenteredLoader'
import ErrorText from 'modules/common/components/_UI_DUMB/ErrorText/ErrorText'
import GlobalHelperModel from 'modules/common/models/globalHelper'
import Table from 'modules/common/components/_UI_SMART/SharedTable/TableWrapper/TableWrapper'
// @graphql
import {
  DealsOrderByList,
  DealStatus,
  GetDealsQuery,
  GetDealsQueryVariables,
  Scalars,
} from 'graphql/graphqlTypes'
// @models
import DealModel from 'modules/deal/models/deal'
import { RouteComponentProps, withRouter } from 'react-router'
import { ApolloError } from '@apollo/client'
import { ApolloFetchMoreType } from 'modules/common/types'
import { ApolloQueryResult } from '@apollo/client/core/types'

/*******************************************************
 * TYPES
 *******************************************************/
interface HeaderRow {
  name: string
  value?: string
}

interface Props {
  data: GetDealsQuery | undefined
  loading: boolean
  error?: ApolloError | undefined
  refetch: (
    variables?: Partial<GetDealsQueryVariables>,
  ) => Promise<ApolloQueryResult<GetDealsQuery>>
  fetchMore: ApolloFetchMoreType<GetDealsQuery, GetDealsQueryVariables>
  variables: GetDealsQueryVariables | undefined
  header?: HeaderRow[]
  tableData?: () => ReactElement | ReactElement[] | void
  wrapper?: HTMLDivElement
}

/**
 *
 * @param props
 * @constructor
 */
const DealsList = ({
  data,
  error,
  loading,
  refetch,
  fetchMore,
  variables,
  header,
  wrapper,
  history,
  tableData: propsTableData,
}: Props & RouteComponentProps) => {
  /*******************************************************
   * CONSTANTS
   *******************************************************/
  const headerRowData = header || [
    { name: strings.NAME, value: DealsOrderByList.NAME },
    { name: strings.CREATOR },
    { name: strings.STAGE, value: DealsOrderByList.STAGE },
    { name: strings.STATUS, value: DealsOrderByList.STATUS },
    { name: strings.LAST_UPDATED, value: DealsOrderByList.CREATED_DATE },
  ]

  /*******************************************************
   * FUNCTIONS
   *******************************************************/

  /**
   *
   * @param {Scalars["ID"]} id
   * @param {DealStatus} status
   */
  const handleClick = (id: Scalars['ID'], status: DealStatus) => {
    const route = DealModel.dealStatusRouter(status, id)
    history.push(route)
  }

  /*******************************************************
   * RENDER ELEMENTS
   *******************************************************/
  const tableData = (): ReactElement | ReactElement[] | void => {
    if (error) {
      console.log(error)
      return <ErrorText errorText={`${strings.GENERIC_ERROR}:`} />
    }

    if (loading && !data) {
      return <CenteredLoader />
    }

    if (data?.deals && data.deals.data?.length === 0) {
      return <ErrorText errorText={strings.NO_DEALS} />
    }

    if (data?.deals.data?.length) {
      return data.deals.data.map((el, key: number) => {
        if (!el.id) {
          return <></>
        }

        return (
          <TableRow
            key={key}
            component="div"
            onClick={() => handleClick(el.id, el.status)}
          >
            <TableCell component="div">
              <Typography variant="subtitle1" className="underline">
                {el.name}
              </Typography>
              <SC.MobileStatusText>
                {GlobalHelperModel.normalizeWords(el.status)}
              </SC.MobileStatusText>
            </TableCell>
            <TableCell component="div">
              {el.createdBy.firstName} {el.createdBy.lastName}
            </TableCell>
            <TableCell component="div">
              {el.stage &&
                GlobalHelperModel.normalizeWords(
                  DealModel.syntheticStage(el.stage),
                )}
            </TableCell>
            <TableCell component="div">
              {GlobalHelperModel.normalizeWords(
                el.stage && DealModel.syntheticStatus(el.status, el.stage),
              )}
            </TableCell>
            <TableCell component="div">
              {format(parseISO(el.createdDate), config.dateSlashedFormat)}
            </TableCell>
          </TableRow>
        )
      })
    }
  }

  /*******************************************************
   * RENDER
   *******************************************************/
  const Wrapper = wrapper ? wrapper : SC.TableWrapper

  return (
    <Wrapper>
      <Table
        bodyRow={propsTableData ? propsTableData() : tableData()}
        dataMeta={data?.deals.meta}
        fetchMore={fetchMore}
        headerRow={headerRowData}
        loading={loading}
        queryName="deals"
        refetch={refetch}
        variables={variables}
      />
    </Wrapper>
  )
}

export default withRouter(DealsList)
