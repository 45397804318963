/*******************************************************
 * NOTE:
 * This component is instead meant to be used "inline",
 * usually as a part of <InlineForm> or when you need a
 * standalone/separate component with more control over
 * various events, presentation and overall behavior.
 * <InlineForm> internally also relies on react-hook-form
 * and generates Controller wrappers for the "inline"
 * inputs (like this one) that were passed in.
 *
 * This component has the "regular" counterpart (./index)
 * which is instead used as a part of react-hook-form.
 * The main differences between "regular" and "inline" is
 * that the "regular" one comes wrapped with RHF's Controller
 * whereas "inline" one gets wrapped by <InlineForm> when
 * it clones the children.
 *
 * Ideally, everything this "inline" component does should
 * also be handled by the "regular" counterpart with potentially
 * some sort of "inline" (boolean) prop passed in. However,
 * that would also make the final component code messy since
 * the markup differences are big and essentially require either
 * a fully modular component, where we can somehow only use bits
 * we're interested in, or keeping the duplicate as we do now since
 * the differences in markup and behavior are so big that it's
 * justified.
 *******************************************************/

// @react
import React from 'react'
// @design
import {
  FormControl,
  FormHelperText,
  InputProps,
  makeStyles,
  TextField,
} from '@material-ui/core'

/*******************************************************
 * CONST
 *******************************************************/
export const style = () => ({
  wrap: {
    width: '100%',
    marginBottom: '10px',
    '& .Mui-disabled': {
      fontStyle: 'normal',
    },
  },
})
const useStyles = makeStyles(style)

/*******************************************************
 * TYPES
 *******************************************************/
export type PropsType = {
  label?: string
  className?: string
  [custom: string]: any
  inputProps?: Partial<InputProps>
  disabled?: boolean
  withoutLabel?: boolean
  InputLabelProps?: { shrink?: boolean }
}

/**
 *
 * @param classes
 * @param input
 * @param label
 * @param name
 * @param multiline
 * @param rows
 * @param rowsMax
 * @param onChange
 * @param inputProps
 * @param isSubmitting
 * @param isSubmitSuccessful
 * @param disabled
 * @param value
 * @param error
 * @param withoutLabel
 * @param custom
 * @constructor
 */
const FormInput: React.FunctionComponent<PropsType> = ({
  input,
  label,
  name,
  multiline,
  rows,
  rowsMax,
  onChange,
  inputProps,
  isSubmitting,
  isSubmitSuccessful,
  disabled,
  value,
  error,
  withoutLabel,
  inputLabelProps,
  ...custom
}) => {
  /*******************************************************
   * CONST
   *******************************************************/
  const submitFailed = !isSubmitSuccessful
  const isHidden = custom.hidden ? 'd-none' : ''
  const isError = (isSubmitting && !!error) || (submitFailed && !!error)

  /*******************************************************
   * HOOKS
   *******************************************************/
  const classes = useStyles()

  /*******************************************************
   * RENDER
   *******************************************************/
  return (
    <FormControl
      className={[custom.className, isHidden, classes.wrap].join(' ')}
      error={isError}
    >
      <TextField
        data-testid={name || label?.toLocaleLowerCase()}
        id={name || label?.toLocaleLowerCase()}
        name={name || label?.toLocaleLowerCase()}
        variant={'standard'}
        style={{ width: '100%' }}
        multiline={multiline}
        rows={rows}
        rowsMax={rowsMax}
        onChange={(e) => {
          if (onChange) {
            onChange(e)
          }
        }}
        helperText={submitFailed && typeof error === 'string' ? error : null}
        label={withoutLabel ? '' : label}
        disabled={disabled || false}
        error={isError}
        type={custom.type || 'text'}
        InputProps={inputProps}
        value={value}
        InputLabelProps={inputLabelProps}
        {...input}
      />
      {custom.helperText && !custom.errorText && (
        <FormHelperText>{custom.helperText}</FormHelperText>
      )}
      {custom.errorText && isError && (
        <FormHelperText>{custom.errorText}</FormHelperText>
      )}
    </FormControl>
  )
}

export default FormInput
