// @react
import React from 'react'
import * as SC from './styledComponents/contactTag'

interface Props {
  type: string
}
// @todo .... clean this up
const ContactTag = (props: Props) => {
  const { type } = props

  const options = {
    'THEIR TEAM': { text: 'Their team', color: '#c6e8fa' },
    PENDING: { text: 'Pending invite', color: '#fff9ad' },
    ACCEPTED: { text: 'Accepted', color: '#c8fff5' },
    'MY TEAM': { text: 'My team', color: '#abebe1' },
  }
  const currentOptions = options[type]
  return currentOptions ? (
    <SC.StyledTag color={currentOptions.color}>
      {currentOptions.text}
    </SC.StyledTag>
  ) : null
}

export default ContactTag
