// @react
import { FC, useEffect, useMemo, useState } from 'react'
// @design
import * as SC from './styledComponents/messagesTextarea'
import * as ButtonsSC from 'modules/common/components/_UI_DUMB/Button/styledComponents'
// @common
import ReactQuill, { Quill } from 'react-quill'
import QuillMention from 'quill-mention'
import MessagesTextareaToolbar from './MessagesTextareaToolbar'
import config from 'constants/config'
import {
  Scalars,
  BaseSimplifiedUserNodeFragment,
  AvatarDocumentNodeFragment,
  Maybe,
} from 'graphql/graphqlTypes'

Quill.register('modules/mentions', QuillMention)
/*******************************************************
 * TYPES
 *******************************************************/
type PropsType = {
  className?: string
  testid?: string
  noAvatar?: boolean
  isWhiteBackground?: boolean
  editedCommentText: {
    commentId?: Scalars['ID']
    value?: string
  }
  userMentions?: Maybe<BaseSimplifiedUserNodeFragment | undefined>[] | null
  userAvatar?: string
  parentEntityId?: Scalars['ID']
  editHandler?: (input: {
    id: Scalars['ID']
    body: string
    parentEntity: Scalars['ID']
  }) => void
  submitHandler?: (input: { body: string; parentEntity: Scalars['ID'] }) => void
  cancelEditing?: () => void
}

type AtValue = {
  id?: Scalars['ID']
  value: string
  avatar?: AvatarDocumentNodeFragment | null
}

export const isEmptyText = (text: string) => {
  if (!text || text === '<p><br></p>') {
    return true
  }
  return false
}

/**
 *
 * @param props
 * @constructor
 */
const MessagesTextarea: FC<PropsType> = ({
  editedCommentText,
  className,
  testid,
  noAvatar,
  isWhiteBackground,
  userMentions,
  userAvatar,
  parentEntityId,
  editHandler,
  submitHandler,
  cancelEditing,
}: PropsType) => {
  /*******************************************************
   * STATE
   *******************************************************/
  const [text, setText] = useState<string>('')
  const [commentId, setCommentId] = useState<Scalars['ID'] | null | undefined>()
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const [isFocused, setIsFocused] = useState<boolean>(false)

  /*******************************************************
   * EFFECT HOOKS
   *******************************************************/
  useEffect(() => {
    setIsEditing(!!commentId)

    if (editedCommentText.commentId) {
      setCommentId(editedCommentText.commentId)
    }
    if (editedCommentText.value) {
      setText(editedCommentText.value)
    }

    if (commentId && !editedCommentText.commentId) {
      resetState()
    }
  }, [editedCommentText, commentId])

  /*******************************************************
   * FUNCTIONS
   *******************************************************/
  const resetState = () => {
    setText('')
    setCommentId(null)
  }

  const handleChange = (value) => {
    setText(value)
  }

  const atValues: AtValue[] = userMentions
    ? userMentions.map((user) => ({
        id: user?.id,
        value: `${String(user?.firstName)} ${String(user?.lastName)}`,
        avatar: user?.avatar,
      }))
    : []

  /*******************************************************
   * HOOKS
   *******************************************************/
  const mention = useMemo(
    () => ({
      allowedChars: /^[A-Za-z\sÅÄÖåäö]*$/,
      mentionDenotationChars: ['@'],
      spaceAfterInsert: true,
      defaultMenuOrientation: 'top',
      // eslint-disable-next-line prettier/prettier
      renderItem: (item) => {
        return `<span class="item" href="#"><img src="${String(
          item?.avatar?.file?.location ?? config.defaultAvatar,
        )}"><div class="name">${String(item.value)}</div></span>`
      },
      source: function (searchTerm, renderList, mentionChar) {
        let values
        if (mentionChar === '@') {
          values = atValues
        }

        if (searchTerm.length === 0) {
          renderList(values, searchTerm)
        } else {
          const matches = values?.filter(
            (value) =>
              value.value.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0,
          )
          renderList(matches, searchTerm)
        }
      },
    }),
    [userMentions],
  )

  const modules = {
    mention,
    toolbar: {
      container: '#textAreaToolbar',
    },
  }

  /*******************************************************
   * RENDER
   *******************************************************/
  return (
    <SC.Container
      className={className}
      isWhiteBackground={isWhiteBackground}
      data-testid={testid || 'message-textarea-wrapper'}
    >
      {!noAvatar ? (
        <SC.StyledAvatar
          src={userAvatar || 'https://www.w3schools.com/howto/img_avatar.png'}
        />
      ) : null}
      <SC.Wrap isFocused={isFocused}>
        <SC.Textarea id="target">
          <ReactQuill
            modules={modules}
            value={text}
            onChange={handleChange}
            onFocus={() => setIsFocused(true)}
            onBlur={() => setIsFocused(false)}
          />
          <SC.Actions>
            <MessagesTextareaToolbar />
            <div
              style={{
                width: '30%',
                display: 'flex',
                justifyContent: 'flex-end',
              }}
            >
              {isEditing && (
                <ButtonsSC.ButtonOrange
                  onClick={cancelEditing}
                  variant="contained"
                  color="primary"
                  style={{ marginRight: 5 }}
                >
                  Cancel
                </ButtonsSC.ButtonOrange>
              )}
              <ButtonsSC.ButtonOrange
                onClick={() => {
                  if (commentId) {
                    editHandler &&
                      editHandler({
                        id: commentId,
                        body: text || '',
                        parentEntity: parentEntityId || '',
                      })
                    cancelEditing && cancelEditing()
                  } else {
                    console.log('text')
                    console.log(text)
                    console.log(parentEntityId)
                    submitHandler &&
                      submitHandler({
                        body: text || '',
                        parentEntity: parentEntityId || '',
                      })
                  }
                  resetState()
                }}
                variant="contained"
                color="primary"
                disabled={isEmptyText(text)}
              >
                {isEditing ? 'Update comment' : 'Comment'}
              </ButtonsSC.ButtonOrange>
            </div>
          </SC.Actions>
        </SC.Textarea>
      </SC.Wrap>
    </SC.Container>
  )
}

export default MessagesTextarea
