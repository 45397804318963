import styled from 'styled-components'

// @todo NO: data-test-contact-item-user this not a valid selector

export const ContactsWrapper = styled.div`
  & * {
    border: 0px !important;
  }
  div[data-test-contact-item-user] {
    border-bottom: 1px solid #f3f7fb !important;
  }
`
