import { gql } from '@apollo/client'

export const FILTERS_FRAGMENT = gql`
  fragment FiltersNode on Filters {
    key
    type
    label
    allLabel
    values {
      value
      label
      isActive
    }
  }
`
