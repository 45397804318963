import React from 'react'

export enum RouteNames {
  HOME = '',
  CALLBACK = 'callback',

  PROFILE = 'profile',
  USER = 'user',
  PASSWORD = 'password',
  CONTACTS = 'contacts',
  NETWORK = 'network',
  CONTACT_PENDING = 'pending',
  CONTACT_SENT = 'sent',

  DEALS = 'deals',
  OPEN_DEALS = 'open-deals',
  CLOSED_DEALS = 'closed-deals',
  DEAD_DEALS = 'dead-deals',
  INVITES_PENDING = 'deal-invites',
  INVITES_SENT = 'sent-deals',
  SUMMARY = 'summary',
  DATES_AND_METRICS = 'dates-and-metrics',
  DETAILS = 'details',
  STAGE_VIEW = 'stage-view',
  CARD_VIEW = 'card-view',
  TEAM = 'team',
  PIPELINE = 'pipeline',

  ITEMS = 'items',
  OPEN_ITEMS = 'open-items',
  CLOSED_ITEMS = 'closed-items',

  DOCUMENTS = 'documents',
  FINALIZED = 'finalized',
  NOTIFICATIONS = 'notifications',
  ACCOUNT_SETTINGS = 'account-settings',
  MESSAGES = 'messages',

  LOGIN = 'login',
  SIGN_UP = 'sign-up',

  ID = 'id',
  ITEM_ID = 'itemId',
  NOT_FOUND = '404',
  NEW = 'new',
  ERROR = 'error',

  TOS = 'terms-and-conditions',
}

export interface RouteType {
  path: string
  exact?: boolean
  /* eslint-disable @typescript-eslint/no-explicit-any */
  component: React.ComponentType<any>
  type: 'PRIVATE' | 'PUBLIC'
  // rest props
  title?: string
  props?: Record<string, unknown>
  layout?: 'MAIN'
  // Route with white background wrapping the nested components
  whiteInnerWrap?: boolean
}

export interface RouteWrapperType
  extends Omit<RouteType, 'component' | 'type'> {
  component: React.ElementType
  key: number | string
}
