// @react
import React from 'react'
import colors from 'constants/colors'

const DestroyDocSvgIcon: React.FunctionComponent = () => (
  <svg
    height="24px"
    width="24px"
    id="Layer_1"
    viewBox="0 0 24 20"
    style={{
      fill: colors.white,
    }}
  >
    <g>
      <rect height="1" width="12" x="5" y="8" />
      <rect height="1" width="12" x="5" y="6" />
      <rect height="1" width="12" x="5" y="10" />
      <rect height="1" width="12" x="5" y="12" />
      <path d="M20.9,0H1.1C0.5,0,0,0.5,0,1.1V16h2V2h18v14h2V1.1C22,0.5,21.5,0,20.9,0z" />
      <rect height="2" width="2" y="18" />
      <rect height="2" width="2" x="2" y="20" />
      <rect height="2" width="2" x="7" y="22" />
      <rect height="2" width="2" x="10" y="18" />
      <rect height="2" width="2" x="6" y="16" />
      <rect height="1" width="1" x="13" y="22" />
      <rect height="1" width="1" x="6" y="20" />
      <rect height="1" width="1" x="13" y="16" />
      <rect height="1" width="1" x="17" y="17" />
      <rect height="2" width="2" x="18" y="22" />
      <rect height="2" width="2" x="20" y="18" />
      <rect height="2" width="2" x="16" y="20" />
    </g>
  </svg>
)

export default DestroyDocSvgIcon
