// @react
import React from 'react'
import { ReactElement } from 'react'
// @design
import * as SC from '../../styledComponents/messageItem'
import Avatar from 'modules/common/components/_UI_DUMB/Avatar/Avatar'
// @common
import parse from 'html-react-parser'

interface InjectProps {
  owner: boolean
  body: string
  authorName: string
  avatar: string
  time: string
}

type PropsType = InjectProps

/**
 *
 * @param props
 * @returns {any}
 * @constructor
 */
const MessageItem = (props: PropsType): ReactElement => {
  /*******************************************************
   * PROPS
   *******************************************************/
  const { owner } = props

  /**
   *
   */
  const content = (
    <React.Fragment>
      <Avatar src={props.avatar} width={40} height={40} />
      <SC.MessageTextWrap>
        <SC.MessageArrow />
        <SC.MessageText>{parse(props.body)}</SC.MessageText>
        <SC.TypographyMini>{props.time}</SC.TypographyMini>
      </SC.MessageTextWrap>
    </React.Fragment>
  )

  return owner ? (
    <SC.MyMessage>{content}</SC.MyMessage>
  ) : (
    <SC.NotMyMessage>{content}</SC.NotMyMessage>
  )
}

export default MessageItem
