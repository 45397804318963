import {
  DealIndustry,
  DealNodeFragment,
  PropertyType,
} from 'graphql/graphqlTypes'
import strings from 'constants/strings'
import { interestTypes } from 'modules/deal/common/common'

const mapUnits: { [key: string]: string } = {
  SQFT: 'sqft',
  ACRES: 'ac',
  SQM: 'sqm',
  UNITS: 'units',
  FEET: 'ft',
  METERS: 'm',
}

const getMapUnits = (value?: string | null): string => {
  if (!value) return ''
  return mapUnits[value]
}

/**
 *
 * @param deal
 */
export const getAdditionalInformationRows = (deal: DealNodeFragment) => {
  /*******************************************************
   * CONSTANTS
   *******************************************************/

  const numberOfProperties = { name: strings.NUMBER_OF_PROPERTIES }
  const yearOfConstruction = { name: strings.YEAR_OF_CONSTRUCTION }
  const subsector = { name: strings.SUBSECTOR }
  const brand = { name: strings.BRAND }
  const hotelManagement = { name: strings.HOTEL_MANAGEMENT }
  const noKeys = { name: strings.NUMBER_OF_ROOMS }
  const meeting = { name: strings.NUMBER_EVENT_SPACES }
  const interest = {
    name: strings.INTEREST,
    mapFunc: (value: string): string => {
      const interestType = interestTypes.find(
        (interestType) => interestType.value === value,
      )
      return interestType ? interestType.label : value
    },
  }
  const rentableAreaValue = {
    name: strings.RENTABLE_AREA,
    mapFunc: (value: string): string =>
      value
        ? `${Number(value).toLocaleString()} ${getMapUnits(
            deal.rentableAreaUnit,
          )}`.trim()
        : value,
  }
  const landAreaValue = {
    name: strings.LAND_AREA,
    mapFunc: (value: string): string =>
      value
        ? `${Number(value).toLocaleString()} ${getMapUnits(
            deal.landAreaUnit,
          )}`.trim()
        : value,
  }
  const productType = { name: strings.PRODUCT_TYPE }
  const zoning = { name: strings.ZONING }
  const entitlements = { name: strings.ENTITLEMENTS }
  const tenancy = { name: strings.TENANCY }

  const occupancy = {
    name: strings.OCCUPANCY,
    mapFunc: (value: string): string =>
      value ? `${Number(value).toLocaleString()}%` : value,
  }
  const wale = { name: strings.WALE }
  const totalPropertyRentArea = {
    name: strings.TOTAL_RENTABLE_AREA,
    mapFunc: (value: string): string =>
      value
        ? `${Number(value).toLocaleString()} ${getMapUnits(
            deal.areaUnit,
          )}`.trim()
        : value,
  }
  const noFloors = { name: strings.NUMBER_OF_FLOORS }
  const clearHeight = {
    name: strings.CLEAR_HEIGHT,
    mapFunc: (value: string): string =>
      value
        ? `${Number(value).toLocaleString()} ${getMapUnits(
            deal.clearHeightUnit,
          )}`.trim()
        : value,
  }

  const loadingType = { name: strings.LOADING_TYPE }
  const noDockHighDoors = {
    name: strings.DOCK_HIGH_DOORS,
    mapFunc: (value: string): string =>
      value ? Number(value).toLocaleString() : value,
  }
  const acreage = {
    name: strings.ACREAGE,
    mapFunc: (value: string): string =>
      value ? Number(value).toLocaleString() : value,
  }
  const parkingStalls = {
    name: strings.PARKING_STALLS,
    mapFunc: (value: string): string =>
      value ? Number(value).toLocaleString() : value,
  }
  const debt = { name: strings.DEBT }
  const siteAreaValue = {
    name: strings.SITE_AREA,
    mapFunc: (value: string): string =>
      value
        ? `${Number(value).toLocaleString()} ${getMapUnits(
            deal.siteAreaUnit,
          )}`.trim()
        : value,
  }

  const portfolioRows =
    deal.propertyType === PropertyType.PORTFOLIO ? { numberOfProperties } : {}

  const allIndustries = {
    [DealIndustry.HOSPITALITY]: {
      yearOfConstruction,
      subsector,
      brand,
      hotelManagement,
      noKeys,
      meeting,
      interest,
      debt,
      siteAreaValue,
    },
    [DealIndustry.INDUSTRIAL]: {
      yearOfConstruction,
      rentableAreaValue,
      tenancy,
      occupancy,
      wale,
      clearHeight,
      loadingType,
      noDockHighDoors,
      acreage,
      parkingStalls,
      debt,
    },
    [DealIndustry.LAND]: {
      landAreaValue,
      zoning,
      entitlements,
      interest,
      debt,
    },
    [DealIndustry.OTHER]: {
      yearOfConstruction,
      landAreaValue,
      interest,
      debt,
    },
    [DealIndustry.OFFICE]: {
      yearOfConstruction,
      rentableAreaValue,
      tenancy,
      occupancy,
      wale,
      noFloors,
      interest,
      debt,
    },
    [DealIndustry.RESIDENTIAL]: {
      yearOfConstruction,
      rentableAreaValue,
      subsector,
      occupancy,
      interest,
      debt,
    },
    [DealIndustry.RETAIL]: {
      yearOfConstruction,
      rentableAreaValue,
      productType,
      occupancy,
      totalPropertyRentArea,
      acreage,
      interest,
      debt,
    },
  }

  const currentIndustryRows = deal.industry && allIndustries[deal.industry]

  if (!currentIndustryRows) return []

  type RowType = {
    prop: string
    name: string
    mapFunc: (arg: string) => string
  }

  return Object.entries({
    ...portfolioRows,
    ...currentIndustryRows,
  }).map(
    ([key, value]: unknown[]) =>
      ({ prop: key, ...(value as object) } as RowType),
  )
}
