// @react
import React from 'react'
import { useEffect } from 'react'
import { RouteComponentProps, withRouter } from 'react-router'
import FullPageLoader from 'modules/common/components/_UI_DUMB/Loaders/PageLoader'
import { useAuth0 } from '@auth0/auth0-react'
import { CREATE_USER } from 'graphql/mutations/user.mutation'
import { useMutation } from '@apollo/client'
import {
  UserCreateMutation,
  UserCreateMutationVariables,
} from 'graphql/graphqlTypes'
import useErrorHandler from 'modules/common/hooks/useErrorHandler'

type PropsType = RouteComponentProps

/**
 *
 * @param {History<LocationState>} history
 * @returns {JSX.Element}
 * @constructor
 */
const PageLoader = ({ history }: PropsType) => {
  const { isAuthenticated } = useAuth0()

  const [createUserMutation, { error }] = useMutation<
    UserCreateMutation,
    UserCreateMutationVariables
  >(CREATE_USER)
  useErrorHandler(error)

  useEffect(() => {
    const createUser = async () => {
      await createUserMutation()
      history.replace(window.sessionStorage.getItem('requiredUrl') || '/')
    }
    void createUser()
  }, [isAuthenticated])

  return <FullPageLoader />
}

export default withRouter(PageLoader)
