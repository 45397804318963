import { FormControl, Input, Select } from '@material-ui/core'
import styled from 'styled-components'

export const StyledSelect = styled(Select).attrs({
  classes: { icon: 'icon' },
})`
  .icon {
    transition: all 0.2s ease;
    transform: rotate(0deg);

    ${(props) =>
      props.open &&
      `
            transform: rotate(180deg);
        `}
  }
`

export const StyledFormControl = styled(FormControl)`
  && {
    width: 100%;
  }
`

export const StyledInput = styled(Input).attrs({
  classes: { input: 'input' },
})`
  .input {
    font-size: 16px;

    ${(props) =>
      props.theme &&
      `
            color: ${String(props.theme.palette.primary[100])};
        `}

    &::placeholder {
      font-style: italic;
    }

    p {
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
`
