import React from 'react'
import { colors } from 'constants/colors'
import { NavbarElementProps } from 'react-day-picker'
import * as SC from '../styledComponents/freeDatePickerArrow'

type OwnProps = { isSingle?: boolean }

type PropsType = OwnProps & Partial<NavbarElementProps>

/**
 *
 * @param onPreviousClick
 * @param isSingle
 * @param onNextClick
 * @constructor
 */
const FreeDatePickerArrow = ({
  onPreviousClick,
  isSingle,
  onNextClick,
}: PropsType):
  | React.ReactElement<Partial<PropsType>>
  | React.ReactElement<Partial<NavbarElementProps>> => {
  /*******************************************************
   * RENDER
   *******************************************************/
  return (
    <>
      <SC.StyledFabLeft
        color="primary"
        onClick={() => onPreviousClick && onPreviousClick()}
        issingle={String(isSingle)}
      >
        <SC.StyledKeyboardArrowLeftIcon htmlColor={colors.battleshipGrey} />
      </SC.StyledFabLeft>
      <SC.StyledFabRight
        color="primary"
        onClick={() => onNextClick && onNextClick()}
        issingle={String(isSingle)}
      >
        <SC.StyledKeyboardArrowRightIcon htmlColor={colors.battleshipGrey} />
      </SC.StyledFabRight>
    </>
  )
}

export default FreeDatePickerArrow
