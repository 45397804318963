import DealItems from 'modules/deal/pages/DealItems'
import { RouteType, RouteNames } from 'constants/routeNames'
import Deals from './pages/Deals'
import DealSummary from './pages/DealSummary'
import DealTeam from './pages/DealTeam'
import DatesAndMetrics from './pages/DatesAndMetrics'

const routes: RouteType[] = [
  {
    component: Deals,
    path: `/${RouteNames.DEALS}`,
    exact: true,
    type: 'PRIVATE',
    layout: 'MAIN',
  },
  {
    component: Deals,
    path: `/${RouteNames.DEALS}/${RouteNames.INVITES_PENDING}`,
    exact: true,
    type: 'PRIVATE',
    layout: 'MAIN',
  },

  {
    component: Deals,
    path: `/${RouteNames.DEALS}/${RouteNames.INVITES_SENT}`,
    exact: true,
    type: 'PRIVATE',
    layout: 'MAIN',
  },
  {
    component: DealItems,
    path: `/${RouteNames.DEALS}/${RouteNames.DETAILS}/:${RouteNames.ID}/${RouteNames.ITEMS}`,
    exact: true,
    type: 'PRIVATE',
  },
  {
    component: DealItems,
    path: `/${RouteNames.DEALS}/${RouteNames.DETAILS}/:${RouteNames.ID}/${RouteNames.STAGE_VIEW}`,
    type: 'PRIVATE',
  },
  {
    component: DealItems,
    path: `/${RouteNames.DEALS}/${RouteNames.DETAILS}/:${RouteNames.ID}/${RouteNames.CARD_VIEW}`,
    type: 'PRIVATE',
  },
  {
    component: DealSummary,
    path: `/${RouteNames.DEALS}/${RouteNames.DETAILS}/:${RouteNames.ID}/${RouteNames.SUMMARY}`,
    type: 'PRIVATE',
    layout: 'MAIN',
  },
  {
    component: DatesAndMetrics,
    path: `/${RouteNames.DEALS}/${RouteNames.DETAILS}/:${RouteNames.ID}/${RouteNames.DATES_AND_METRICS}`,
    type: 'PRIVATE',
    layout: 'MAIN',
  },
  {
    component: DealTeam,
    path: `/${RouteNames.DEALS}/${RouteNames.DETAILS}/:${RouteNames.ID}/${RouteNames.TEAM}`,
    type: 'PRIVATE',
    layout: 'MAIN',
  },
]
const Routes = {
  routes,
}
export default Routes
