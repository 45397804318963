import { NotificationItemFragment as NotificationType } from 'graphql/graphqlTypes'
import { RouteNames } from 'constants/routeNames'

export default class NotificationModel {
  public static getLink(notification: NotificationType): string {
    /*******************************************************
     * PROPS
     *******************************************************/
    if (!(notification.hasOwnProperty('link') && notification.link)) {
      return ''
    }
    const linkType = notification.link.entityType

    switch (linkType) {
      // case 'CONTACT':
      case 'USER':
        return `/${RouteNames.NETWORK}/${RouteNames.CONTACT_PENDING}`
      case 'DEAL':
        return `/${RouteNames.DEALS}/${RouteNames.DETAILS}/${String(
          notification.link.entityId,
        )}/${RouteNames.SUMMARY}`
      case 'TEAM':
        return `/${RouteNames.DEALS}/${RouteNames.DETAILS}/${String(
          notification.link.relatedEntityId,
        )}/${RouteNames.TEAM}`
      case 'ITEM':
        return `/${RouteNames.DEALS}/${RouteNames.DETAILS}/${String(
          notification.link.relatedEntityId,
        )}/${RouteNames.ITEMS}/${RouteNames.DETAILS}/${String(
          notification.link.entityId,
        )}`
      default:
        return '/notifications'
    }
  }
}
