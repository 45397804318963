import { AccessTime, People } from '@material-ui/icons'
import styled from 'styled-components'

export const AccessTimeStyled = styled(AccessTime)`
  && {
    color: #ff695f;
    width: 14px;
    height: 14px;
    margin-right: 5px;
  }
`

export const PeopleIcon = styled(People)`
  && {
    width: 14px;
    height: 14px;
    margin-right: 5px;
  }
`
