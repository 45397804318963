// @react
import React from 'react'
import { useEffect, useState } from 'react'
// @design
import * as SC from './styledComponents'
import { GroupedMenuItem } from 'modules/common/types'

export interface Props {
  menuItems?: {
    clickAction: () => void
    Component: React.ReactNode
    separator?: boolean
  }[]
  groupedMenuItems?: GroupedMenuItem[]
  openEventTarget: React.ReactNode
  handleCloseOutside: () => void
  testId?: string
  target?: string
  menuTarget?: string
  variant?: 'yellow'
  title?: string
}

/**
 *
 * @param testId
 * @param menuItems
 * @param groupedMenuItems
 * @param openEventTarget
 * @param handleCloseOutside
 * @param target
 * @param menuTarget
 * @param variant
 * @param title
 * @returns {any}
 * @constructor
 */
const Dropdown = ({
  testId,
  menuItems,
  groupedMenuItems,
  openEventTarget,
  handleCloseOutside,
  target,
  menuTarget,
  variant,
  title,
}: Props) => {
  /*******************************************************
   * PROPS
   *******************************************************/
  const [anchorEl, setAnchorEl] = useState<React.ReactNode>(null)

  /**
   * useffect
   */
  useEffect(() => {
    setAnchorEl(openEventTarget)
  }, [openEventTarget])

  /**
   * handleClose
   */
  const handleClose = () => {
    setAnchorEl(null)
    handleCloseOutside()
  }

  /**
   *
   */
  return (
    <SC.StyledMenu
      id={testId}
      data-testid={testId}
      anchorEl={anchorEl}
      keepMounted
      disableAutoFocusItem={true}
      open={
        Boolean(anchorEl) && ((!!target && menuTarget === target) || !target)
      }
      onClose={handleClose}
      elevation={0}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
    >
      {window.Cypress && title}
      {menuItems &&
        menuItems.map((item, key) => (
          <SC.StyleMenuItem
            variant={variant}
            key={key}
            as={item.separator && SC.StyledMenuItemSeparator}
            onClick={() => {
              item.clickAction()
              handleClose()
            }}
          >
            {item.Component}
          </SC.StyleMenuItem>
        ))}

      {groupedMenuItems &&
        groupedMenuItems.map((item, key) => {
          if (item.data.length) {
            return (
              <div key={key}>
                <SC.SubMenu className={'subMenu'}>
                  {item.title && (
                    <SC.SubMenuItem
                      className={`subTitle ${key === 0 ? 'first' : ''}`}
                    >
                      {item.title}
                    </SC.SubMenuItem>
                  )}
                  {item.data.map((subItem, subKey) => (
                    <SC.SubMenuItem
                      key={subKey}
                      role="menuitem"
                      onClick={() => {
                        subItem.clickAction()
                        handleClose()
                      }}
                    >
                      {subItem.Component}
                    </SC.SubMenuItem>
                  ))}
                </SC.SubMenu>
              </div>
            )
          }
          return null
        })}
    </SC.StyledMenu>
  )
}

export default Dropdown
