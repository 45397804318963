import { Avatar } from '@material-ui/core'
import styled from 'styled-components'
import { avatarImage } from 'modules/common/styledComponents/Mixins'
import { device, from } from 'modules/common/styledComponents/MediaQueries'
import colors from 'constants/colors'

export const StyledAvatar = styled(Avatar)`
  && {
    ${(props: { color: string }) =>
      props.color && `box-shadow: 0 0 0 3px ${String(props.color)};`};

    ${avatarImage}
    margin-right: 0 !important;
  }
`

export const StyledTag = styled.span`
  ${(props: { color: string }) =>
    props.color && `background: ${String(props.color)}`};
  text-transform: capitalise;
  font-family: Open Sans;
  font-size: 8px;
  color: ${colors.white};
  position: absolute;
  padding: 2px 4px;
  border-radius: 4px;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  white-space: nowrap;

  ${from(device.tabletL)} {
    font-size: 10px;
  }

  ${(props) =>
    props.hasIcon &&
    `
    svg {
      margin-bottom: 0px;
      margin-right: 3px;
      margin-left: 1px;
    }
  `};
`

export const StyledAvatarWrapper = styled.div`
  position: relative;
  margin-right: 20px;

  ${from(device.ipad)} {
    margin-right: 28px;
  }

  ${(props: { hasLabel: string }) =>
    props.hasLabel &&
    `
    padding-bottom: 0.5em;
  `}
`
