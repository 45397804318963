import styled from 'styled-components'
import { from, device } from 'modules/common/styledComponents/MediaQueries'

export const Wrapper = styled.div`
  ${from(device.materialXS)} {
    margin-top: -20px;
  }

  ${from(device.ipadL)} {
    margin-top: -40px;
  }
`
