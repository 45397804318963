// @react
import localCacheActions from '../graphql/localCache/localCacheActions'
import typeDefs from 'graphql/localCache/localSchema'
// @apollo
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  DefaultOptions,
  split,
  HttpLink,
  InMemoryCache,
} from '@apollo/client'
import AuthorizedWsLink from '../graphql/Apollo/wsLink'
import { getMainDefinition } from 'apollo-utilities'
import { onError } from '@apollo/client/link/error'
import possibleTypes from '../graphql/possibleTypes.json'
import { setContext } from '@apollo/client/link/context'
import Config from 'constants/config'
// @types
import BROWSER_HISTORY from 'utils/history'
import { HttpErrorMsg } from 'graphql/intGraphqlTypes'
import { RouteNames } from 'constants/routeNames'
import { useAuth0 } from '@auth0/auth0-react'
import { useEffect, useMemo } from 'react'
import useAuth0Wrapper from './hooks/useAuth0Wrapper'
import { Item, Deal } from 'graphql/graphqlTypes'

export type Auth0LocalResult = {
  access_token?: string
  id_token?: string
} | null

/**
 *
 * @type {{mutate: {errorPolicy: "all"}, query: {errorPolicy: "all"}, watchQuery: {fetchPolicy: "cache-and-network", errorPolicy: "all"}}}
 */
const defaultOptions: DefaultOptions = {
  watchQuery: {
    fetchPolicy: 'cache-and-network',
    errorPolicy: 'all',
  },
  query: {
    errorPolicy: 'all',
  },
  mutate: {
    errorPolicy: 'all',
  },
}

type Entity = Deal | Item | Document

type PaginatedDataType = { data: []; meta: { page?: number } }

const mergeFunction = (
  existing: PaginatedDataType = { data: [], meta: {} },
  incoming,
  { args },
) => {
  if (existing.meta?.page === incoming.meta?.page) {
    return incoming
  }
  const { page = 0, pageSize = Config.defaultPageSize } = args || {}
  const existingData = existing.data ? existing.data.slice(0) : []
  const mergedData = incoming.data?.reduce(
    (mergedData: Entity[], item: Entity, i: number) => {
      mergedData[page * pageSize + i] = item
      return [...mergedData]
    },
    existingData,
  )

  return {
    ...incoming,
    meta: incoming.meta,
    data: mergedData,
  }
}

/*
// const fragmentMatcher = new IntrospectionFragmentMatcher({
//   introspectionQueryResultData,
// });
*/

//@todo: check unreadCount logic, maybe we should move out it from apollo cache
const cache = new InMemoryCache({
  possibleTypes: possibleTypes.possibleTypes,
  typePolicies: {
    Query: {
      fields: {
        unreadCount: {
          read() {
            return []
          },
        },
        items: {
          keyArgs: ['filters', 'orderBy'],
          merge: mergeFunction,
        },
        deals: {
          keyArgs: ['filters', 'orderBy'],
          merge: mergeFunction,
        },
        documents: {
          keyArgs: ['filters', 'orderBy'],
          merge: mergeFunction,
        },
        userDeal: {
          keyArgs: ['filters', 'orderBy', 'dealId'],
          merge: mergeFunction,
        },
        invites: {
          keyArgs: ['filters', 'orderBy'],
          merge: mergeFunction,
        },
        dealsInvites: {
          keyArgs: ['filters', 'orderBy'],
          merge: mergeFunction,
        },
        thirdPartyUsers: {
          keyArgs: ['filters', 'dealId', 'orderBy'],
          merge: mergeFunction,
        },
        notifications: {
          keyArgs: ['filters', 'orderBy'],
          merge: mergeFunction,
        },
        users: {
          keyArgs: ['filters'],
          merge: mergeFunction,
        },
        userContacts: {
          keyArgs: ['filters', 'contactStatus'],
          merge: mergeFunction,
        },
      },
    },
  },
})

/**
 *
 */

const AuthorizedApolloProvider = ({ children }) => {
  const { getAccessTokenSilently, loginWithRedirect, logout } = useAuth0()

  const { getIdTokenWrapper, getAccessTokenWrapper } = useAuth0Wrapper()

  /**
   * Cypress has a lot of difficulty with the redirect method and memory store for auth0 (sessions lost between tests)
   * in this case we detect if we're in the Cypress environment, if so we fetch the auth token and id token from the localstorage directly
   * this token will have been stored as part of a manual auth process in cypress itself (see cypress testst)
   */
  useEffect(() => {
    if (!window.Cypress) {
      const getAccess = async (): Promise<void> => {
        try {
          await getAccessTokenSilently()
        } catch (e) {
          console.log(e)
          await loginWithRedirect()
        }
      }
      getAccess()
    }
  }, [])

  /**
   * WS link
   */
  const authorizedWsLink = new AuthorizedWsLink(
    getAccessTokenWrapper,
    process.env.REACT_APP_WS_URI as string,
  )
  const wsLink = authorizedWsLink.createLink()

  /**
   *
   * @type {HttpLink}
   */
  const httpLink = new HttpLink({
    uri: process.env.REACT_APP_API_URI,
  })

  /**
   * WS handlers
   */

  authorizedWsLink.wsLink.subscriptionClient?.onConnected(async () => {
    // grab current state
    // @todo this was used for storing in redux, what's it for now?
    await getAccessTokenWrapper()
  })

  authorizedWsLink.wsLink.subscriptionClient?.onReconnected(async () => {
    // @todo this was used for storing in redux, what's it for now?
    console.log('subscriptionClient?.onReconnected')
    await getAccessTokenWrapper()
  })

  authorizedWsLink.wsLink.subscriptionClient?.onReconnecting(async () => {
    const token = await getAccessTokenWrapper()
    if (token) {
      // authorizedWsLink.wsLink.subscriptionClient.use([subscriptionMiddlewareWrapper(token)]);
    }

    console.log('subscriptionClient?.onReconnecting')
  })

  authorizedWsLink.wsLink.subscriptionClient?.onError(async () => {
    if (wsLink && wsLink.options && wsLink.options.connectionParams) {
      wsLink.options.connectionParams.authToken = await getAccessTokenWrapper()
    }
    console.log('connected f client f onError')
  })

  /**
   *
   * @type {ApolloLink}
   */
  const authLink = setContext(async (_, { headers }) => {
    const accessToken = await getAccessTokenWrapper() //Auth0Service.getAccessToken();
    const idToken = await getIdTokenWrapper() //Auth0Service.getAccessToken();
    return {
      headers: {
        ...headers,
        authorization: accessToken ? `Bearer ${accessToken}` : '',
        auth0_id: idToken ? `${idToken}` : '',
      },
    }
  })

  /**
   *
   * @type {ApolloLink}
   */
  const HTTPLink = authLink.concat(httpLink)

  let isLoggingOut = false

  /**
   * HTTP errors
   * @type {ApolloLink}
   */
  const errorHTTPLink = onError((args) => {
    const { networkError, graphQLErrors } = args
    console.log('errorHTTPLink')
    console.log(errorHTTPLink)
    if (graphQLErrors) {
      const errors = graphQLErrors.map(({ message, extensions }) => {
        if (!isLoggingOut && extensions?.exception?.status === 401) {
          isLoggingOut = true
          logout()
          return
        }
        try {
          if (extensions?.exception.response.msg) {
            //            snackbar.setMessage({
            //              type: SnackbarTypes.ERROR,
            //              message: extensions.exception.response.msg,
            //              show: true,
            //            })
            // return extensions?.exception.response.msg
          }
        } catch (error) {
          console.log(error)
        }
        return message
      })

      // @todo handle arrays of errors, use case hasn't come up yet
      // @ts-ignore
      if (errors && errors[0]) {
        // @ts-ignore
        switch (errors[0] as HttpErrorMsg) {
          case HttpErrorMsg.ENTITY_PERMISSION_DENIED:
            break
          case HttpErrorMsg.EMAIL_EXISTS:
            window.sessionStorage.setItem(
              'requiredUrl',
              `/${RouteNames.SIGN_UP}/${RouteNames.ERROR}`,
            )
            BROWSER_HISTORY.push(`/${RouteNames.SIGN_UP}/${RouteNames.ERROR}`)
            break
        }
      }
    }
    if (networkError) console.log(`[Network error]: ${String(networkError)}`)
  })

  /**
   * WS errors
   * @type {ApolloLink}
   *  this is only called if we throw a fatal connection problem inside wslink
   *  isFatalConnectionProblem: () => true
   *  otherwise this error is never called
   *
   */
  const errorWsLink = onError(({ graphQLErrors }) => {
    graphQLErrors?.forEach(({ extensions }) => {
      if (!isLoggingOut && extensions?.exception?.status === 401) {
        // @todo we must figure out why the WSlink errors in cypress env, for now we disable the auto-logout since it's disrupting tests
        if (!window.Cypress) {
          isLoggingOut = true
          logout()
        }
        return
      }
    })

    if (
      graphQLErrors?.length &&
      graphQLErrors[0].extensions &&
      (graphQLErrors[0].extensions.code === 'UNAUTHENTICATED' ||
        // @ts-ignore
        graphQLErrors[0].message.statusCode === 403)
    ) {
      console.log('calling the reset WS link ')
      authorizedWsLink.resetLink()
    }
  })

  /**
   * final link construction
   * @type {ApolloLink}
   */
  const alink = ApolloLink.from([errorHTTPLink, HTTPLink])
  const wlink = ApolloLink.from([errorWsLink, wsLink])

  const link = split(
    // split based on operation type
    ({ query }) => {
      const definition = getMainDefinition(query)
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      )
    },
    wlink,
    alink,
  )

  const apolloClient = useMemo(() => {
    return new ApolloClient({
      link,
      cache,
      connectToDevTools: process.env.NODE_ENV === 'development',
      resolvers: localCacheActions.resolvers,
      defaultOptions,
      typeDefs,
    })
  }, [])

  return <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
}

export default AuthorizedApolloProvider
