import { RouteType, RouteNames } from 'constants/routeNames'
import Chat from './pages/Chat'
import CreateMessage from './pages/CreateMessage'

const routes: RouteType[] = [
  {
    path: `/${RouteNames.MESSAGES}/${RouteNames.NEW}`,
    component: CreateMessage,
    exact: true,
    type: 'PRIVATE',
    layout: 'MAIN',
    whiteInnerWrap: true,
  },
  {
    path: `/${RouteNames.MESSAGES}/:${RouteNames.ID}`,
    component: Chat,
    exact: true,
    type: 'PRIVATE',
    layout: 'MAIN',
    whiteInnerWrap: true,
  },
]

const Routes = { routes }
export default Routes
