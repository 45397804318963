import styled from 'styled-components'
import { device, from } from 'modules/common/styledComponents/MediaQueries'
import { Fab, TableCell, Typography } from '@material-ui/core'
import colors from 'constants/colors'

export const Wrap = styled.div`
  ${from(device.tabletXL)} {
    display: none;
  }

  .MuiTable-root {
    background-color: transparent;
    border: 0;
  }

  .MuiTableRow-root {
    border: 0;
  }
`

export const FilterTitleWrap = styled.div`
  display: flex;
  align-items: center;
  justofy-content: space-between;
`

export const ArrowToggle = styled(Fab)`
  position: relative;
  width: 22px;
  height: 22px;
  min-height: 22px;
  flex-shrink: 0;

  &::before {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 200%;
    height: 200%;
    transform: translate(-50%, -50%);
  }

  svg {
    font-size: 1.2rem;
  }
`

export const FilterTitle = styled(Typography)`
  flex-grow: 1;
  padding-left: 0.5em;
  padding-right: 0.5em;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
`

const Cell = styled(TableCell)`
  background: transparent;
  border: 0;
`

export const FilterCell = styled(Cell)`
  padding-left: 0;
  padding-right: 0;
`

export const LabelCell = styled(Cell)`
  width: 0;
  text-align: right;

  > div {
    display: inline-block;
  }
`

export const ValueCell = styled(Cell)`
  text-align: right;
  background-color: ${colors.white};

  tr:not(:last-child) & {
    border-bottom: 1px solid ${colors.paleGrey};
  }
`
