// @react
import * as SC from './styledComponents/contactAvatar'
import ThirdParty from '../../../../_UI_DUMB/Icons/SvgIcons/ThirdParty'
import colors from 'constants/colors'
import { TeamRole } from 'graphql/graphqlTypes'
import profilePlaceholderImage from 'assets/img/profile-no-avatar.png'

/*******************************************************
 * TYPE
 *******************************************************/
export enum ContactAvatarTheirTeam {
  THEIR_TEAM_LEAD = 'THEIR_TEAM_LEAD',
}
export const ContactAvatarRole = { ...ContactAvatarTheirTeam, ...TeamRole }
export type ContactAvatarRoleType = typeof ContactAvatarTheirTeam &
  typeof TeamRole

type SelfProps = {
  avatar?: string
  options?: {
    avatar?: {
      width: number
      height: number
    }
  }
  type?: ContactAvatarRoleType | TeamRole | ContactAvatarTheirTeam | null // not equivalent to type TeamRole
}

/**
 *
 * @param avatar
 * @param options
 * @param type
 * @constructor
 */
const ContactAvatar = ({ avatar, options, type }: SelfProps) => {
  /*******************************************************
   * PROPS
   *******************************************************/
  const styleOptions = {
    [TeamRole.LEAD]: { color: colors.teamLead, label: 'Team Lead' },
    [ContactAvatarRole.THEIR_TEAM_LEAD]: {
      color: colors.theirTeamLead,
      label: 'Team Lead',
    },
    [TeamRole.THIRD_PARTY]: {
      color: colors.thirdParty,
      label: 'Third Party',
      icon: <ThirdParty />,
    },
  }

  const stylesOption = styleOptions[type?.toString() || '']

  /*******************************************************
   * RENDER
   *******************************************************/
  return (
    <SC.StyledAvatarWrapper hasLabel={!!stylesOption?.label}>
      <SC.StyledAvatar
        color={stylesOption?.color}
        src={avatar || profilePlaceholderImage}
        style={{
          ...options?.avatar,
        }}
      />

      {stylesOption?.label && (
        <SC.StyledTag color={stylesOption.color} hasIcon={!!stylesOption.icon}>
          {stylesOption.icon}
          {stylesOption.label}
        </SC.StyledTag>
      )}
    </SC.StyledAvatarWrapper>
  )
}

export default ContactAvatar
