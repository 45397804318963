// @react
import React, { ReactElement } from 'react'
import { useRouteMatch } from 'react-router'
// @design
import * as SC from '../../styledComponents/notifications'
// @common
import strings from 'constants/strings'
import colors from 'constants/colors'
import Badge from 'modules/common/components/_UI_DUMB/Badge'
import { RouteNames } from 'constants/routeNames'
import { ComponentWrap } from 'modules/common/components/_UI_SMART/HomeWrapper/styledComponents'
// @components
import LoadMoreBtn from 'modules/common/components/_UI_SMART/LoadMoreBtn'
import CenteredLoader from 'modules/common/components/_UI_DUMB/Loaders/CenteredLoader'
import NotificationItem from 'modules/notification/components/NotificationItem'
// @graphql
import { useMutation, useQuery, useSubscription } from '@apollo/client'
import {
  MARK_ALL_AS_READ,
  UPDATE_NOTIFICATION,
} from 'graphql/mutations/notification.mutation'
import { GET_NOTIFICATIONS } from 'graphql/queries/notification.query'
import {
  GetNotificationsQuery,
  GetNotificationsQueryVariables,
  MarkAllAsReadMutation,
  MarkAllAsReadMutationVariables,
  NotificationsOrderByList,
  NotificationUpdateMutation,
  NotificationUpdateMutationVariables,
  Order,
} from 'graphql/graphqlTypes'
import { ContentContainer } from 'modules/common/styledComponents/Global'
import { useHistory } from 'react-router-dom'
import { NOTIFICATION_CREATE_SUBSCRIPTION } from 'graphql/subscriptions/notification.subscription'
import useErrorHandler from 'modules/common/hooks/useErrorHandler'
import { Grid } from '@material-ui/core'
import SectionEmpty from 'modules/common/components/_UI_DUMB/Section/SectionEmpty'
import illustration from 'assets/img/illustrations/manHoldingExclamation.svg'

/*******************************************************
 * RENDER COMPONENTS
 *******************************************************/
const RenderNotifications = ({ loading, data, updateNotification }) => {
  if (loading) {
    return <CenteredLoader />
  }
  if (!data) {
    return <div>no data</div>
  }

  if (data?.notifications.data?.length) {
    return data.notifications.data.map((el) => {
      return (
        <NotificationItem
          key={`${el.id as string}`}
          notification={el}
          updateNotification={updateNotification}
        />
      )
    })
  }

  return (
    <SC.CenteredError>
      <Grid item xs={12}>
        <SectionEmpty
          title={strings.NO_NOTIFICATIONS}
          illustration={{
            src: illustration,
            placement: 'top',
          }}
        />
      </Grid>
    </SC.CenteredError>
  )
}

/**
 * Types
 */
type TParams = { id: string | undefined }

/**
 *
 * @constructor
 */
const Notifications = (): ReactElement => {
  /*******************************************************
   * PROPS
   *******************************************************/
  const history = useHistory()
  const match = useRouteMatch<TParams>()

  const { id } = match.params
  const dealIds = id ? [id] : null

  /*******************************************************
   * GRAPHQL
   *******************************************************/
  /**
   * queries
   */
  const { error, data, refetch, loading, fetchMore, variables } = useQuery<
    GetNotificationsQuery,
    GetNotificationsQueryVariables
  >(GET_NOTIFICATIONS, {
    variables: {
      filters: { deal: dealIds },
      orderBy: {
        order: Order.DESC,
        value: NotificationsOrderByList.CREATED_DATE,
      },
    },
  })
  let unreadCount = data?.notifications.meta.unreadCount || 0
  useErrorHandler(error)

  /**
   * mutations
   */
  const [
    markAsRead,
    { loading: mutationLoading, error: markAsReadMutationErrors },
  ] = useMutation<MarkAllAsReadMutation, MarkAllAsReadMutationVariables>(
    MARK_ALL_AS_READ,
    {
      update(cache, { data: MarkAllAsReadData }) {
        if (!data) return

        cache.writeQuery<GetNotificationsQuery, GetNotificationsQueryVariables>(
          {
            query: GET_NOTIFICATIONS,
            variables,
            data: {
              notifications: {
                meta: {
                  ...data.notifications.meta,
                  unreadCount: 0,
                },
                data: MarkAllAsReadData?.notificationsSetAllAsRead,
                __typename: 'NotificationsPayload',
              },
            },
          },
        )
      },
    },
  )
  useErrorHandler(markAsReadMutationErrors)

  const [updateNotification, { error: updateNotificationErrors }] = useMutation<
    NotificationUpdateMutation,
    NotificationUpdateMutationVariables
  >(UPDATE_NOTIFICATION, {
    update(cache, { data: updateNotification }) {
      unreadCount = unreadCount - 1

      if (!data) return

      cache.writeQuery<GetNotificationsQuery, GetNotificationsQueryVariables>({
        query: GET_NOTIFICATIONS,
        variables,
        data: {
          notifications: {
            meta: {
              ...data.notifications.meta,
              unreadCount: data.notifications.meta.unreadCount
                ? data.notifications.meta.unreadCount - 1
                : 0,
            },
            data: data.notifications.data?.map((notification) => {
              if (
                notification.id === updateNotification?.notificationUpdate.id
              ) {
                return { ...notification, read: true }
              }
              return notification
            }),
            __typename: 'NotificationsPayload',
          },
        },
      })
    },
  })
  useErrorHandler(updateNotificationErrors)

  const {
    data: subscriptionData,
    // _error,
    // _loading,
  } = useSubscription(NOTIFICATION_CREATE_SUBSCRIPTION, {
    shouldResubscribe: true,
    onSubscriptionData: () => refetch(),
  })

  const isLoadMoreNeeded =
    (data?.notifications.meta.page || 0) + 1 !==
      data?.notifications.meta.totalPages &&
    data?.notifications.meta.totalPages !== 0

  /*******************************************************
   * FUNCTIONS
   *******************************************************/
  /**
   *
   */
  const loadMoreHandler = () => {
    if (data?.notifications.meta && fetchMore) {
      const newPageNum = (data.notifications.meta.page || 0) + 1

      void fetchMore<GetNotificationsQuery, GetNotificationsQueryVariables>({
        variables: {
          page: newPageNum,
        },
      })
    }
  }

  /*******************************************************
   * RENDER COMPONENTS
   *******************************************************/

  /**
   *
   * @returns {JSX.Element}
   * @constructor
   */
  const NotificationsActions = () => (
    <SC.Header>
      <SC.Text>
        <SC.Title variant="h4">{strings.NOTIFICATIONS}</SC.Title>
        {unreadCount > 0 && <Badge unread count={unreadCount} />}
      </SC.Text>
      <SC.Actions>
        <SC.Action
          disabled={!unreadCount}
          onClick={markAsReadHandler}
          data-testid="notifications-mark-as-read"
        >
          {loading || mutationLoading ? (
            <CenteredLoader />
          ) : (
            strings.MARK_ALL_READ
          )}
        </SC.Action>
        <SC.Separator>|</SC.Separator>
        <SC.Action
          onClick={() =>
            history.push(
              `/${RouteNames.PROFILE}/${RouteNames.ACCOUNT_SETTINGS}`,
            )
          }
        >
          Settings
        </SC.Action>
      </SC.Actions>
    </SC.Header>
  )

  /**
   *
   */
  const markAsReadHandler = async () => {
    await markAsRead()
    if (!markAsReadMutationErrors) {
      void (await refetch())
    }
  }

  /*******************************************************
   * RENDER
   *******************************************************/
  return (
    <ContentContainer data-testid="notifications-outer-wrap">
      <ComponentWrap background={colors.white}>
        <NotificationsActions />
        <RenderNotifications
          data={data}
          loading={loading}
          updateNotification={updateNotification}
        />
      </ComponentWrap>
      <LoadMoreBtn
        isVisible={isLoadMoreNeeded}
        isLoading={loading}
        onClick={loadMoreHandler}
      />
    </ContentContainer>
  )
}

export default Notifications
