// @react
import { useEffect, ReactElement, useRef, Fragment } from 'react'
// @design
import * as SC from '../../styledComponents/messagesList'
// @components
import MessageItem from '../MessageItem'

export type MessageData = {
  id: number
  body?: string
  owner: boolean
  firstName?: string
  lastName?: string
  avatar?: string
  createdDate: Date
  createdDay: string
  displayTime: string
}

export type GroupedMessageData = {
  [key: string]: MessageData[] | undefined
}

/**
 * propsType
 */
type PropsType = {
  data: MessageData[]
  groupedData: GroupedMessageData
}

/**
 *
 * @param props
 * @returns {any}
 * @constructor
 */
const MessagesList = ({ groupedData, data }: PropsType): ReactElement => {
  /*******************************************************
   * PROPS
   *******************************************************/
  const lastMessageRef = useRef<HTMLDivElement | null>(null)
  const lastItem = data.length && [...data].pop()

  /*******************************************************
   * HOOKS
   *******************************************************/
  useEffect(() => {
    if (Array.isArray(data)) {
      lastMessageRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
      })
    }
  }, [data, lastMessageRef])

  /*******************************************************
   * RENDER
   *******************************************************/
  return (
    <SC.Container>
      {Object.keys(groupedData).map((group) => {
        const list = groupedData[group]
        return (
          <Fragment key={group}>
            <SC.DateTitle>{group}</SC.DateTitle>
            <SC.List>
              {list?.map((el) => {
                return (
                  <div
                    key={el.id}
                    ref={
                      lastItem && el.id === lastItem.id ? lastMessageRef : null
                    }
                  >
                    <MessageItem
                      key={el.id}
                      owner={el.owner}
                      body={el.body || ''}
                      authorName={`${String(el.firstName)} ${String(
                        el.lastName,
                      )}`}
                      avatar={el.avatar || ''}
                      time={el.displayTime}
                    />
                  </div>
                )
              })}
            </SC.List>
          </Fragment>
        )
      })}
    </SC.Container>
  )
}

export default MessagesList
