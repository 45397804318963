// language=GraphQL
import { gql } from '@apollo/client'
import { METRIC_FRAGMENT } from '../fragments/metric.fragment'

export const GET_METRIC = gql`
  query GetMetric($dealId: ID!) {
    metric(dealId: $dealId) {
      ...MetricNode
    }
  }
  ${METRIC_FRAGMENT}
`
