export enum HttpErrorMsg {
  ERROR_GENERIC = 'ERROR_GENERIC',
  ACTION_GENERAL = 'ACTION_GENERAL',
  EMAIL_INVITE_EXISTS = 'EMAIL_INVITE_EXISTS',
  EMAIL_EXISTS = 'EMAIL_EXISTS',
  ENTITY_EXISTS = 'ENTITY_EXISTS',
  ENTITY_NOT_FOUND = 'ENTITY_NOT_FOUND',
  ENTITY_EDIT_PERMISSION_DENIED = 'ENTITY_EDIT_PERMISSION_DENIED',
  TEAM_LEAD_REMOVE_SELF = 'TEAM_LEAD_REMOVE_SELF',
  TEAM_MEMBER_CONFLICT = 'TEAM_MEMBER_CONFLICT',
  TEAM_MEMBER_LIMIT_EXCEEDED = 'TEAM_MEMBER_LIMIT_EXCEEDED',
  INVITE_DECLINE = 'INVITE_DECLINE',
  INVITE_ACCEPT = 'INVITE_ACCEPT',
  SPECIFY_EMAIL_OR_ID_ONLY = 'SPECIFY_EMAIL_OR_ID_ONLY',
  ENTITY_NOT_SPECIFIED = 'ENTITY_NOT_SPECIFIED',
  ENTITY_DELETE = 'ENTITY_DELETE',
  ENTITY_CREATE = 'ENTITY_CREATE',
  ENTITY_PERMISSION_DENIED = 'ENTITY_PERMISSION_DENIED',
  ENTITY_CREATE_PERMISSION_DENIED = 'ENTITY_CREATE_PERMISSION_DENIED',
  INTEGRITY_MISMATCH = 'INTEGRITY_MISMATCH',
  ENTITY_VALIDATION = 'ENTITY_VALIDATION',
  INVALID_INPUT = 'INVALID_INPUT',
  USER_NOT_APPROVED = 'USER_NOT_APPROVED',
}
