import React, { useState } from 'react'
// @design
import { Table, TableBody, TableRow } from '@material-ui/core'
import * as SC from './styledComponents'
import { dealIndustries } from '../../../PipeLineChart/PipeLineChart'
import IndustryTag from '../../../IndustryTag'
import { TotalsSumBy } from '../../../../enum'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import GlobalHelperModel from 'modules/common/models/globalHelper'

type PropsType = {
  data: any
  sumBy: keyof typeof TotalsSumBy
}

const dealStages = [
  {
    label: 'Review',
    key: 'REVIEW',
  },
  {
    label: 'Underwriting',
    key: 'UNDERWRITING',
  },
  {
    label: 'Due Diligence',
    key: 'DUE_DILIGENCE',
  },
  {
    label: 'Closing',
    key: 'CLOSING',
  },
]

/**
 *
 * @param {any} data
 * @param {"COUNT" | "GAV" | "NAV"} sumBy
 * @returns {JSX.Element}
 * @constructor
 */
const StagesMobile = ({ data, sumBy }: PropsType) => {
  /*******************************************************
   * STATE
   *******************************************************/
  const [stage, setStage] = useState(dealStages[0])

  /*******************************************************
   * FUNCTIONS
   *******************************************************/
  /**
   *
   * @param {"prev" | "next"} direction
   */
  const onArrowClick = (direction: 'prev' | 'next') => {
    const index = dealStages.findIndex(
      (dealStage) => dealStage.key === stage.key,
    )

    const newStage =
      direction === 'prev'
        ? index === 0 // if we're on the first one
          ? dealStages.length - 1 // go to the last one
          : index - 1 // go to previous one
        : index === dealStages.length - 1 // if we're on the last one
        ? 0 // go to the irst one
        : index + 1 // go to the next one

    setStage(dealStages[newStage])
  }

  /**
   *
   * @param industry
   * @returns {any}
   */
  const getIndustrySumValue = (industry) => {
    const result =
      data &&
      data.length &&
      data.filter((item) => {
        return item.industry === industry && item.stage === stage.key
      })

    switch (sumBy) {
      case 'COUNT':
        if (result && result.length > 0) {
          return result[0].count
        }
        break

      case 'GAV':
        if (result && result.length > 0) {
          return GlobalHelperModel.getCleanNumber(result[0].gav)
        }
        break

      case 'NAV':
        if (result && result.length > 0) {
          return GlobalHelperModel.getCleanNumber(result[0].nav)
        }
        break

      default:
        break
    }

    return ''
  }

  /*******************************************************
   * RENDER
   *******************************************************/
  return (
    <SC.Wrap>
      <Table>
        <TableBody>
          <TableRow>
            <SC.LabelCell />
            <SC.FilterCell>
              <SC.FilterTitleWrap>
                <SC.ArrowToggle
                  size="small"
                  onClick={() => onArrowClick('prev')}
                >
                  <ChevronLeftIcon />
                </SC.ArrowToggle>
                <SC.FilterTitle>{stage.label}</SC.FilterTitle>
                <SC.ArrowToggle
                  size="small"
                  onClick={() => onArrowClick('next')}
                >
                  <ChevronRightIcon />
                </SC.ArrowToggle>
              </SC.FilterTitleWrap>
            </SC.FilterCell>
          </TableRow>
          {dealIndustries.map((industry) => (
            <TableRow key={industry}>
              <SC.LabelCell>
                <IndustryTag industry={industry} />
              </SC.LabelCell>
              <SC.ValueCell>{getIndustrySumValue(industry)}</SC.ValueCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </SC.Wrap>
  )
}

export default StagesMobile
