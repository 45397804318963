// @components
import * as SC from '../../styledComponents/profileWelcomeDialog'
import { Button } from '@material-ui/core'
import strings from 'constants/strings'
import illustration from './illustration.svg'

type PropsType = {
  open: boolean
  onClose: () => void
}

const WelcomeDialog = ({ open, onClose }: PropsType) => {
  return (
    <SC.StyledSwitchboardDialog
      open={open}
      onClose={onClose}
      title=""
      type="welcome"
    >
      <SC.StyledDialogContent>
        <SC.Image>
          <img src={illustration} alt={strings.WELCOME_TITLE} />
        </SC.Image>
        <SC.Content>
          <SC.Title variant="h2">{strings.WELCOME_TITLE}</SC.Title>
          <SC.Text
            dangerouslySetInnerHTML={{
              __html: strings.WELCOME_CONTENT,
            }}
          />
          <Button
            variant="contained"
            type="submit"
            color="primary"
            onClick={onClose}
          >
            {strings.WELCOME_CTA}
          </Button>
        </SC.Content>
      </SC.StyledDialogContent>
    </SC.StyledSwitchboardDialog>
  )
}

export default WelcomeDialog
