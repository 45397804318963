// language=GraphQL
import { gql } from '@apollo/client'
import { USER_ITEM } from '../fragments/user.fragment'

export const CREATE_USER = gql`
  mutation UserCreate {
    userCreate {
      id
    }
  }
`

export const UPDATE_USER = gql`
  mutation UserUpdate($userInput: UpdateUserInput!) {
    userUpdate(input: $userInput) {
      ...UserNode
    }
  }
  ${USER_ITEM}
`

export const UPDATE_USER_ACTIVITY = gql`
  mutation UserActivityUpdate($userActivityInput: UpdateUserActivityInput!) {
    userActivityUpdate(input: $userActivityInput)
  }
`
