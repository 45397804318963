export const colors = {
  black: '#000',
  dark: '#252839',
  dusk: '#464c6c',
  battleshipGrey: '#677077',
  grey: '#979797',
  medGrey: '#bdbdbd',
  lightGreyBlue: '#b5b5b7',
  veryLightBlue: '#e5e9ed',
  veryLightPink: '#ffecea',
  pink: '#ffe0de',
  paleGrey: '#f3f7fb',
  white: '#ffffff',
  yellowishOrange: '#f0a80a',
  yellowishOrangeLight: '#ffd476',
  yellowishOrangeHover: '#a87507',
  yellowishOrangeAlt: '#d29309',
  paleYellow: '#F6DA02',
  grapefruit: '#ff695f',
  grapefruitDark: '#c45149',
  darkRed: '#CD5C5C',
  grapefruitLight: '#ff695f30',
  aquaMarine: '#49c5b6',
  aquaMarineDark: '#33887e',
  aquaMarineLight: '#cdf4ed',
  linkedIn: '#0073B1',
  linkedInHover: '#005685',
  statusOnline: '#389f92',
  darkenBlue: '#e8f3fe',
  teamLead: '#389f92',
  thirdParty: '#fc722d',
  theirTeamLead: '#3ba2d9',
  green: '#379489',
  duckEggBlue: '#cdf4ed',
  red: '#f9695f',
  darkPurple: '#6B52FF',
  lightPurple: '#DCD7FF',
  veryDarkOrange: '#9C6D06',
}

export default colors
