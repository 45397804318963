// @react
import React, { ReactElement } from 'react'
// @design
// @components
import ChipBtn from 'modules/common/components/_UI_DUMB/ChipBtn'
import strings from 'constants/strings'
import { RouteNames } from 'constants/routeNames'
import { ChipFiltersWrapper } from 'modules/common/components/_UI_DUMB/ChipBtn/styledComponents'

/*******************************************************
 * TYPES
 *******************************************************/
type PropsType = {
  activeIndex: number
  tabHandler: (number) => void
  counts: {
    contactsCount: number
    sentCount: number
    pendingCount: number
  }
}

/**
 *
 * @param {number} activeIndex
 * @param counts
 * @returns {React.ReactElement}
 * @constructor
 */
const NetworkTabs: React.FC<PropsType> = ({
  counts,
}: PropsType): ReactElement => {
  /*******************************************************
   * RENDER
   *******************************************************/
  return (
    <>
      <ChipFiltersWrapper>
        <ChipBtn
          labelText={strings.PENDING}
          url={`/${RouteNames.NETWORK}/${RouteNames.CONTACT_PENDING}`}
          count={counts.pendingCount}
        />

        <ChipBtn
          labelText={strings.SENT}
          url={`/${RouteNames.NETWORK}/${RouteNames.CONTACT_SENT}`}
          count={counts.sentCount}
        />

        <ChipBtn
          labelText={strings.CONTACTS}
          url={`/${RouteNames.NETWORK}`}
          count={counts.contactsCount}
        />
      </ChipFiltersWrapper>
    </>
  )
}

export default NetworkTabs
