import { RouteType, RouteNames } from 'constants/routeNames'
import Documents from './pages/Documents'

const routes: RouteType[] = [
  {
    component: Documents,
    path: `/${RouteNames.DEALS}/${RouteNames.DETAILS}/:${RouteNames.ID}/${RouteNames.DOCUMENTS}`,
    type: 'PRIVATE',
    layout: 'MAIN',
  },
  {
    component: Documents,
    path: `/${RouteNames.DOCUMENTS}`,
    type: 'PRIVATE',
    layout: 'MAIN',
    exact: true,
  },
  {
    component: Documents,
    path: `/${RouteNames.DOCUMENTS}/${RouteNames.FINALIZED}`,
    type: 'PRIVATE',
    layout: 'MAIN',
    exact: true,
    props: {
      filter: {
        finalized: true,
      },
    },
  },
]

const Routes = {
  routes,
}
export default Routes
