// language=GraphQL
import { gql } from '@apollo/client'

export const GET_EXCHANGE_RATES = gql`
  query getExchangeRates {
    getExchangeRates {
      currency
      rate
    }
  }
`
