import styled from 'styled-components'
import { Button, Typography } from '@material-ui/core'

export const Wrap = styled.div`
  width: 100%;
  max-width: 630px;
  margin: 1.5em auto auto;
  padding: 0 1em;
`

export const Title = styled(Typography)`
  && {
    margin-top: 1em;
    margin-bottom: 1em;
    text-align: center;
  }
`

export const StyledButton = styled(Button)`
  && {
    text-transform: none;
    font-weight: 600;
  }

  svg + span {
    border-bottom: 1px solid currentColor;
    line-height: 1.4;
  }
`
