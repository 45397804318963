import colors from 'constants/colors'
import styled from 'styled-components'
import { device, from } from 'modules/common/styledComponents/MediaQueries'
import { alpha } from '@material-ui/core/styles'
// @components
import { Avatar } from '@material-ui/core'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline'
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked'

export const OnlineIcon = styled(CheckCircleOutlineIcon)`
  && {
    color: ${colors.aquaMarine}
    width:15px;
    height:15px;
    background-color: ${alpha(colors.dark, 0.7)};
    border-radius: 100%;
    animation: ripple 1.2s infinite ease-in-out;
    @keyframes ripple {
      0% {
        box-shadow: 0 0 0 0px ${alpha(colors.aquaMarine, 0.7)};
      }
      100% {
        box-shadow: 0 0 0 2px ${alpha(colors.aquaMarine, 0.0)};
        }
    }
  }
`

export const OfflineIcon = styled(RadioButtonUncheckedIcon)`
  && {
    color: ${colors.medGrey}
    width:15px;
    height:15px;
    background-color: ${alpha(colors.dark, 0.7)};
    border-radius: 100%;
  }
`

export const StyledAvatar = styled(Avatar)`
  && {
    ${(props) =>
      props.width &&
      props.height &&
      `
          width: ${String(props.width)}px;
          height: ${String(props.width)}px;
        `}
    transition: opacity 0.2s;
    ${(props) =>
      props.showonline &&
      !props.isonline &&
      `
          opacity: 0.6;
        `}

    ${from(device.mobileL)} {
      ${(props) =>
        props.width &&
        props.height &&
        `
          width: ${String(props.width)}px;
          height: ${String(props.width)}px;
        `}
    }

    ${from(device.ipad)} {
      ${(props) =>
        props.width &&
        props.height &&
        `width: ${String(props.width)}px;
         height: ${String(props.height)}px;
        `}
    }
  }
`

export const AvatarWrapper = styled.div`
  position: relative;
  && {
    margin-right: 1.3em;

    ${from(device.mobileL)} {
      margin-right: 28px;
    }
  }
`

export const OnlineIconWrapper = styled.div`
  position: absolute;
  bottom: 0;
  line-height: 0;
  right: -3px;
`
