// @react
import { useCallback, useState } from 'react'
// @design
import { DialogActions, DialogContent, Grid } from '@material-ui/core'
import * as SC from 'modules/deal/components/CreateDealWizard/styledComponents/createDealSummary'
import * as ButtonsSC from 'modules/common/components/_UI_DUMB/Button/styledComponents'
// @common
import validationSchema from 'modules/deal/utils/validation'
// @components
import CenteredLoader from 'modules/common/components/_UI_DUMB/Loaders/CenteredLoader'
import {
  Scalars,
  UpdateDealInput,
  GetDealInfoQuery,
} from 'graphql/graphqlTypes'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import DealSummaryForm from '../DealSummaryForm'

interface SelfProps {
  id: Scalars['String']
  ctaLabel: string
  onSubmit: (updateDeal: UpdateDealInput) => void
  loading?: boolean
  deal: GetDealInfoQuery['deal']
  change?: (arg1: string, arg2: string) => void
  prevValues?: GetDealInfoQuery['deal']
}

type PropsType = SelfProps

// export const tenancyTypes = ['SINGLE', 'MULTI']
// export const rentableAreaUnits = ['ACRES', 'SQFT', 'SQM', 'UNITS']
export const propertyTypeFields = {
  SINGLE: [
    'address',
    'country',
    'zip',
    'state',
    'city',
    'tenancy',
    'yearOfConstruction',
  ],
  PORTFOLIO: ['numberOfProperties'],
}

const EditSummaryDialog = (props: PropsType) => {
  /*******************************************************
   * PROPS
   *******************************************************/
  const { loading, change, prevValues, ctaLabel, onSubmit } = props
  const initialFormValues: GetDealInfoQuery['deal'] = {
    ...prevValues,
    fields: prevValues?.fields ? prevValues.fields : [], //due to the fact that we ame using useFields from RHF we cant pass null
  } as GetDealInfoQuery['deal']

  // loop through all the initialFormValues and convert null values to ''
  Object.keys(initialFormValues).forEach((key) => {
    const value = initialFormValues[key]
    initialFormValues[key] = value === null ? '' : value
  })

  /*******************************************************
   * STATE
   *******************************************************/
  const [optionalOpen, changeOpenState] = useState<boolean>(false)
  const [dealData, setDealData] =
    useState<GetDealInfoQuery['deal']>(initialFormValues)

  const toggleOptionalView = useCallback(
    () => changeOpenState(!optionalOpen),
    [optionalOpen],
  )

  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { handleSubmit, watch, control, formState, setValue } = useForm({
    defaultValues: initialFormValues,
    resolver: yupResolver(validationSchema),
  })
  const { errors, isSubmitting, isSubmitSuccessful } = formState

  const changeDealData = useCallback(
    (value, key, callback?) => {
      callback && callback()
      setDealData({
        ...dealData,
        [key]: value,
      })
    },
    [dealData],
  )

  const resetFields = (fieldKey) => {
    const resetData = propertyTypeFields[fieldKey].reduce((acc, item) => {
      change && change(item, initialFormValues[item])
      acc[item] = initialFormValues[item]
      return acc
    }, {})
    setDealData({
      ...dealData,
      ...resetData,
    })
  }

  /**
   *
   */
  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <DialogContent style={{ paddingBottom: '50px' }}>
        <DealSummaryForm
          {...{
            control,
            isSubmitting,
            isSubmitSuccessful,
            errors,
            changeDealData,
            resetFields,
            toggleOptionalView,
            optionalOpen,
            watch,
            isEdit: true,
            setValue,
            formState,
          }}
        />
      </DialogContent>
      <DialogActions>
        <SC.Wrap>
          <Grid container justifyContent="center" spacing={3}>
            <Grid item xs={12} sm={6}>
              <ButtonsSC.SubmitBtn
                disabled={loading}
                variant="contained"
                color="primary"
                type="submit"
              >
                {loading ? <CenteredLoader size={25} /> : ctaLabel}
              </ButtonsSC.SubmitBtn>
            </Grid>
          </Grid>
        </SC.Wrap>
      </DialogActions>
    </form>
  )
}

export default EditSummaryDialog
