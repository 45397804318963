// @react
// @design
import {
  makeStyles,
  TableSortLabel,
  Tooltip,
  Typography,
} from '@material-ui/core'
import { StyledTableHeadCell } from '../styledComponents/tableWrapper'
// @common
import { SortDirection } from '@material-ui/core/TableCell'
// @model
import { TableSortableRowModel } from 'modules/common/models/sharedTableChange/SharedTableSortableRow.model'
import clsx from 'clsx'

export interface InjectProps {
  row: TableSortableRowModel[]
  onSort: (type: string) => void
  variables: any
}

type PropsType = InjectProps

const useStyles = makeStyles(() => ({
  small: {
    width: '10px',
  },
  width100: {
    width: '100%',
  },
}))

/**
 *
 * @constructor
 * @param props
 * @return {any[] | null}
 */
const TableHeader = (props: PropsType) => {
  /**
   *
   */
  const { row, onSort, variables } = props
  const classes = useStyles()
  const orderBy = {
    order: variables.orderBy.order,
    value: variables.orderBy.value,
  }

  const createSortHandler = (property: string) => {
    return (event: any) => {
      onSort(property)
      return event
    }
  }

  const renderLabel = (el) => {
    if (el.hideDirection) {
      return (
        <div className={el.size && clsx(classes[el.size])}>{el.component}</div>
      )
    }

    const sortLabel = () => {
      return (
        <TableSortLabel
          classes={{ root: classes.width100 }}
          active={orderBy.value === el.value}
          // @todo 01-22 fix this type
          direction={orderBy.order.toLowerCase() as 'asc' | 'desc'}
        >
          {el.name ? (
            <Typography variant="h4">{el.name}</Typography>
          ) : (
            el.component
          )}
        </TableSortLabel>
      )
    }

    const unsortableLabel = () => {
      return el.name ? (
        <Typography variant="h4">{el.name}</Typography>
      ) : (
        el.component
      )
    }

    return el.value ? (
      <Tooltip
        title={
          orderBy.value === el.value
            ? orderBy.order === 'DESC'
              ? 'Descending'
              : 'Ascending'
            : ''
        }
        placement={orderBy.order === 'DESC' ? 'bottom-start' : 'bottom-end'}
        enterDelay={300}
      >
        {sortLabel()}
      </Tooltip>
    ) : (
      unsortableLabel()
    )
  }

  return (
    <>
      {row.map((el: TableSortableRowModel, key: number) => (
        <StyledTableHeadCell
          key={key}
          component="div"
          sortDirection={orderBy.order.toLowerCase() as SortDirection}
          onClick={el.value && createSortHandler(el.value)}
          $withHoverStyles={el.name !== ''}
        >
          {renderLabel(el)}
        </StyledTableHeadCell>
      ))}
    </>
  )
}

export default TableHeader
