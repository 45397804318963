import { gql } from '@apollo/client'

export const NOTIFICATION_ITEM = gql`
  fragment NotificationItem on Notification {
    id
    createdDate
    modifiedDate
    code
    category
    message
    priority
    entityId
    category
    read
    alertType
    createdBy {
      firstName
      lastName
      avatar {
        id
        file {
          id
          location
          versionId
        }
      }
    }
    link {
      id
      entityType
      entityId
      relatedEntityType
      relatedEntityId
    }
  }
`
