import React from 'react'
import { Route } from 'react-router-dom'
import { RouteWrapperType } from 'constants/routeNames'

/**
 *
 * @param componentProps
 * @param Component
 * @param path
 * @param key
 * @param exact
 * @constructor
 */
const PublicRoute = ({
  props: componentProps = {},
  component: Component,
  path,
  key,
  exact,
}: RouteWrapperType) => {
  return (
    <Route
      key={key}
      exact={exact}
      path={path}
      render={(props) => <Component {...componentProps} {...props} />}
    />
  )
}

export default PublicRoute
