// @react
import React, { useEffect, useState, useContext, useMemo } from 'react'
import { useLocation, useRouteMatch } from 'react-router'
// @design
import * as SCGlobal from 'modules/common/styledComponents/Global'
import * as SC from './styledComponents/dealTeam'
import * as TabsSC from 'modules/common/styledComponents/Tabs'
import * as ButtonsSC from 'modules/common/components/_UI_DUMB/Button/styledComponents'
import * as ContactItemSC from 'modules/common/components/_UI_SMART/ContactItem/styledComponents/contactItem'
// @common
import BROWSER_HISTORY from 'utils/history'
import queryString from 'query-string'
import strings from 'constants/strings'
import { RouteNames } from 'constants/routeNames'
import userActionsHook from 'modules/deal/components/UserRelationsManage/userActionsHook'
import { UserContext } from 'modules/user/context/user'
import { DealContext } from '../../contexts/deal'
// @components
import ManageTeamDialog from 'modules/deal/components/Dialogs/ManageTeamDialog'
import EditingChecker from 'modules/common/components/_FORM_ELEMENTS/EditingChecker'
import NotFound from 'modules/common/pages/NotFound'
import DealTeamContactsList from 'modules/deal/components/UserRelationsManage/DealTeamContactsList'
import DealTeamsTabs from './components/DealTeamsTabs'
import ConditionalLoader from 'modules/common/components/_UI_DUMB/Loaders/ConditionalLoader'
// @graphql
import { DealType, DealActions } from 'graphql/graphqlTypes'
// @models
import TeamModel from 'modules/deal/models/team'
import DealModel from 'modules/deal/models/deal'
import { useHistory } from 'react-router-dom'

/**
 * types
 */
type TParams = { id: string | undefined }

/**
 *
 * @constructor
 */
const DealTeam = () => {
  /*******************************************************
   * PROPS
   *******************************************************/
  const location = useLocation()
  const match = useRouteMatch<TParams>()

  const { id } = match.params

  /*******************************************************
   * STATE
   *******************************************************/
  const [tabIndex, changeIndex] = useState<number>(0)
  const [isEditing, setEditing] = useState(false)
  const [theirTeamTabActive, setTheirTeamTabActive] = useState(false)
  /*******************************************************
   * HOOKS
   *******************************************************/
  const history = useHistory()

  /*******************************************************
   *  CONTEXT
   *******************************************************/
  const { user } = useContext(UserContext)
  const { data, refetch, loading, error } = useContext(DealContext)

  /*******************************************************
   * QUERIES
   *******************************************************/

  /*******************************************************
   * LIFECYCLE HOOKS
   *******************************************************/
  const teamMembership = useMemo(() => {
    return TeamModel.calculateTeamMembership(data?.deal)
  }, [data])

  // @todo why are we using query strings here
  useEffect(() => {
    const searchObj = queryString.parse(location.search)
    if (searchObj.mode === 'edit' && !isEditing) {
      setEditing(true)
    } else if (searchObj.mode !== 'edit' && isEditing) {
      setEditing(false)
    }
  }, [isEditing, location])

  // to determine whether we disable Their Team tab
  useEffect(() => {
    if (
      data?.deal.type !== DealType.SOLO &&
      data?.deal.recipientTeam?.teamMembership?.length
    ) {
      setTheirTeamTabActive(true)
    } else {
      setTheirTeamTabActive(false)
    }
  }, [data])

  const refetchHandler = () => {
    refetch && refetch()
    history.push(`/${RouteNames.HOME}`)
  }

  const { handlers } = userActionsHook(refetchHandler, teamMembership?.myTeamId)
  /*******************************************************
   * FUNCTIONS
   *******************************************************/

  /**
   * handleVisibility
   */
  const handleVisibility = () => {
    const [, deals, details, dealId] = location.pathname.split('/')
    const BASE_URL = `/${deals}/${details}/${dealId}`
    BROWSER_HISTORY.push({
      pathname: `${BASE_URL}/${RouteNames.TEAM}`,
      search: isEditing ? '' : '?mode=edit',
    })
  }

  /**
   * 5/10 useMemo removed from getTab becuase we're not using activity update right now
   * elsewhere this is solved with react router
   * @type {JSX.Element}
   *
   */
  const getTab = () => {
    switch (tabIndex) {
      case 0:
        return (
          <ContactItemSC.ContactListWrap>
            <ContactItemSC.ContactWrap>
              <ConditionalLoader condition={!loading}>
                <DealTeamContactsList
                  userContactsData={teamMembership?.myTeamTeamMembers}
                  //                  teamContext={{
                  //                    id: teamMembership?.myTeamId,
                  //                    teamLeadUserId: teamMembership?.myTeamLeadUserId || '',
                  //                  }}
                  loadMore={{
                    isLoadMoreNeeded: false,
                    loadMoreHandler: () => {},
                  }}
                  refetch={refetch}
                />
              </ConditionalLoader>
            </ContactItemSC.ContactWrap>
          </ContactItemSC.ContactListWrap>
        )
      case 1:
        return theirTeamTabActive ? (
          <ContactItemSC.ContactListWrap>
            <ContactItemSC.ContactWrap>
              <ConditionalLoader condition={!loading}>
                <DealTeamContactsList
                  userContactsData={teamMembership?.theirTeamMembers}
                  //                  teamContext={{
                  //                    id: teamMembership?.myTeamId,
                  //                    teamLeadUserId: teamMembership?.myTeamLeadUserId || '',
                  //                  }}
                  loadMore={{
                    isLoadMoreNeeded: false,
                    loadMoreHandler: () => {},
                  }}
                  refetch={refetch}
                />
              </ConditionalLoader>
            </ContactItemSC.ContactWrap>
          </ContactItemSC.ContactListWrap>
        ) : (
          <></>
        )
    }
    return <></>
  }

  // @todo should be deal action but deal actions don't include REMOVE_SELF

  /*******************************************************
   * RENDER COMPONENTS
   *******************************************************/

  /**
   * @todo this should be a more global routing control
   */
  if (error?.message === 'Permission denied') {
    return <NotFound />
  }

  const editTeamButton = (
    <ButtonsSC.ButtonOrange
      disabled={
        !data?.deal.actions?.some((action) => {
          if (!action) return false
          return [
            DealActions.DEAL_INVITE_TO_TEAM,
            DealActions.DEAL_REMOVE_TEAM_MEMBER,
          ].includes(action)
        })
      }
      variant="contained"
      onClick={handleVisibility}
      type="button"
      color="primary"
      size="small"
    >
      {strings.EDIT_TEAM_CTA}
    </ButtonsSC.ButtonOrange>
  )

  const leaveTeamButton = (
    <ButtonsSC.ButtonOrange
      disabled={!DealModel.canRemoveSelfFromTeam(data?.deal)}
      variant="contained"
      onClick={async () => {
        user && (await handlers.userRemoveFromTeamHandler(user.id))
      }}
      type="button"
      color="secondary"
      size="small"
    >
      {strings.LEAVE_DEAL}
    </ButtonsSC.ButtonOrange>
  )

  /*******************************************************
   * RENDER
   *******************************************************/
  return (
    <>
      <SCGlobal.ContentContainer data-testid={'deal-single-team-page'}>
        <TabsSC.TabsContainer>
          <SCGlobal.PushContainer>
            <TabsSC.ViewBy>{strings.VIEW_BY}</TabsSC.ViewBy>
            <DealTeamsTabs
              tabHandler={changeIndex}
              activeIndex={tabIndex}
              tab1Active={theirTeamTabActive}
            />
            <SC.FloatRightDiv>
              {editTeamButton}
              {leaveTeamButton}
            </SC.FloatRightDiv>
          </SCGlobal.PushContainer>
          <SC.Wrap>{getTab()}</SC.Wrap>
        </TabsSC.TabsContainer>
      </SCGlobal.ContentContainer>
      {id && teamMembership?.myTeamId && (
        <EditingChecker title="EDIT TEAM">
          {data?.deal.id && (
            <ManageTeamDialog
              myTeamId={teamMembership.myTeamId}
              dealId={data?.deal.id}
              handleClose={handleVisibility}
            />
          )}
        </EditingChecker>
      )}
    </>
  )
}

export default DealTeam
