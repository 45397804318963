import { Grid, Typography, IconButton } from '@material-ui/core'
import colors from 'constants/colors'
import styled from 'styled-components'
import { ContentContainer } from 'modules/common/styledComponents/Global'

export const TopWrap = styled(Grid)`
  && {
    margin-bottom: 20px;
  }
`

export const Title = styled(Typography)`
  && {
    font-weight: 600;
    font-size: 24px;
    font-family: 'Open Sans';
    line-height: 1.5;
    color: ${colors.dark};
  }
`

export const DragAndDropSection = styled.div`
  margin-top: 25px;
`

export const ImagesSection = styled(Grid)`
  ${(props) =>
    props.noimages === 'true' &&
    `
    margin-top: -32px !important;
  `}

  ${(props) =>
    props.isempty === 'true' &&
    `
    order: 2;
  `}
`

export const UploadLabel = styled(Typography)`
  && {
    color: ${colors.dark}
    font-size: 14px;
    margin-top: 10px;
  }
`

export const PreviewImage = styled.div`
  margin-bottom: 30px;
`

export const IconWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;
`

export const DealIconBlock = styled.span`
  && {
    display: inline-flex;
    align-items: center;
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 10px;
    border-radius: 4px;
    background: ${colors.white};
  }
`

export const DealIcon = styled.img`
  width: 20px;
  height: 20px;
`

export const DealLabel = styled.span`
  margin-left: 15px;
  font-size: 14px;
`

export const ImageMain = styled.div`
  height: 400px;
  margin-bottom: 20px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`

export const PreviewImageSmallWrap = styled.div`
  display: flex;
  overflow-x: auto;

  div {
    width: 94px;
    height: 94px;
    margin-right: 8px;
    flex-shrink: 0;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  div: last-child {
    margin-right: 0px;
  }

  &:hover {
    cursor: pointer;
  }
`

export const AddImageGrid = styled(Grid)`
  && {
    height: 100%;
    background-color: ${colors.white};
    flex-direction: column;
  }
`

export const AddMainImageGrid = styled(Grid)`
  && {
    height: 400px;
    background-color: ${colors.white};
    flex-direction: column;
  }
`

export const SummarySubtitle = styled.h3`
  font-size: 16px;
  color: ${colors.dark};
  font-weight: 600;
  margin-top: 30px;
`

export const StyledDealContainer = styled(ContentContainer)`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

export const AddBtn = styled(IconButton)`
  && {
    padding: 7px;
    background-color: ${colors.yellowishOrange};
    color: ${colors.white};

    &:hover {
      background-color: ${colors.yellowishOrangeHover};
    }

    svg {
      transform: scale(1.2);
      font-size: 16px;
    }
  }
`

export const CardRow = styled.div`
  padding: 12px 38px 12px 20px;
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  color: ${colors.dark};
  border-bottom: 1px solid ${colors.paleGrey};
`

export const CardValue = styled.span`
  font-weight: 600;
  font-size: 14px;
  &:first-letter {
    text-transform: capitalize;
  }
`

export const PreviewImageMain = styled.div`
  margin-bottom: 20px;

  img {
    width: 100%;
  }
`
