import { gql } from '@apollo/client'

/*******************************************************
 * USER TYPE
 *******************************************************/
export const USER_ITEM = gql`
  fragment UserNode on User {
    id
    username
    age
    email
    firstName
    lastName
    avatar {
      id
      file {
        id
        location
        versionId
      }
    }
    company
    title
    country
    city
    state
    provider
  }
`

/*******************************************************
 * SIMPLIFIED USER TYPE
 *******************************************************/
export const AVATAR_DOCUMENT = gql`
  fragment AvatarDocumentNode on Document {
    id
    file {
      id
      location
      versionId
    }
  }
`

export const BASE_SIMPLIFIED_USER = gql`
  fragment BaseSimplifiedUserNode on SimplifiedUser {
    id
    uuid
    username
    age
    email
    createdDate
    modifiedDate
    firstName
    lastName
    company
    title
    avatar {
      ...AvatarDocumentNode
    }
  }
  ${AVATAR_DOCUMENT}
`

export const SIMPLIFIED_USER = gql`
  fragment SimplifiedUserNode on SimplifiedUser {
    ...BaseSimplifiedUserNode
    contact {
      inviteId
      inviteType
      status
    }
  }
  ${BASE_SIMPLIFIED_USER}
`
export const CONTACT_ITEM = gql`
  fragment ContactItem on UserContactPayload {
    meta {
      totalPages
      page
      count
    }
    data {
      ...SimplifiedUserNode
    }
  }
  ${SIMPLIFIED_USER}
`
export const USER_DEAL = gql`
  fragment UserDeal on SimplifiedUser {
    userDeal {
      status
      teamRole
      teamId
      teamType
      inviteId
      inviteType
    }
  }
`

export const MINI_USER = gql`
  fragment MiniUser on User {
    id
    firstName
    lastName
  }
`
