// @react
import { ReactNode, useContext } from 'react'
import { useHistory } from 'react-router-dom'
// @design
import { Button, makeStyles } from '@material-ui/core'
// @common
import { SnackbarContext } from 'modules/common/context/snackbar'
import parse from 'html-react-parser'
import { SnackbarTypes } from 'modules/common/components/_UI_DUMB/Snackbars/Snackbar'
import { equivocateEnum } from 'modules/common/types'
import NotificationModel from '../../models'
// @graphql
import { useSubscription } from '@apollo/client'
import {
  AlertType,
  NotificationCode,
  OnNotificationCreatedSubscription,
  OnNotificationCreatedSubscriptionVariables,
} from 'graphql/graphqlTypes'
import { NOTIFICATION_CREATE_SUBSCRIPTION } from 'graphql/subscriptions/notification.subscription'
// @components
import DestroyDocSvgIcon from 'modules/common/components/_UI_DUMB/Icons/SvgIcons/DestroyDocSvgIcon'
import RocketShipSvgIcon from 'modules/common/components/_UI_DUMB/Icons/SvgIcons/RocketShipSvgIcon'

/*******************************************************
 * TYPES
 *******************************************************/
export interface SelfProps {
  addAction?: () => void
}

export const style = () => ({
  squishBtn: {
    marginLeft: '10px',
    padding: '3px 6px',
    fontSize: '10px',
    minHeight: 'auto',
  },
})
const useStyles = makeStyles(style)

const NoticeLink = ({
  msg,
  link,
}: {
  msg?: string | null
  link?: string | null
}) => {
  const history = useHistory()
  const classes = useStyles()

  const parsedMsg = typeof msg === 'string' ? parse(msg) : msg

  return link ? (
    <>
      <div>{parsedMsg}</div>
      <Button
        className={classes.squishBtn}
        variant="outlined"
        color="primary"
        onClick={() => history.push(link)}
      >
        view
      </Button>
    </>
  ) : (
    <>{parsedMsg}</>
  )
}

const CustomIcon = {
  DEAL_KILLED: <DestroyDocSvgIcon />,
  DEAL_LAUNCHED: <RocketShipSvgIcon />,
}

function getIcon(code: NotificationCode): ReactNode | null {
  switch (code) {
    case NotificationCode.DEAL_KILLED:
      return CustomIcon.DEAL_KILLED
    case NotificationCode.DEAL_LAUNCH_SOLO:
    case NotificationCode.DEAL_LAUNCH_GROUP:
      return CustomIcon.DEAL_LAUNCHED
    default:
      return null
  }
}

/**
 *
 * @constructor
 */
const NotificationsBus = () => {
  /*******************************************************
   * HOOKS
   *******************************************************/
  const snackbar = useContext(SnackbarContext)

  /*******************************************************
   * GRAPHQL
   *******************************************************/
  useSubscription<
    OnNotificationCreatedSubscription,
    OnNotificationCreatedSubscriptionVariables
  >(NOTIFICATION_CREATE_SUBSCRIPTION, {
    shouldResubscribe: true,
    onSubscriptionComplete: () => {
      console.log('NOTIFICATION_CREATE_SUBSCRIPTION completed')
    },
    onSubscriptionData: ({ subscriptionData }) => {
      console.log('on sub data')
      console.log('subscriptionData.data?')
      console.log(subscriptionData.data)

      if (subscriptionData.data?.notificationCreated) {
        console.log('yes!')
        const link = NotificationModel.getLink(
          subscriptionData.data?.notificationCreated,
        )
        const msg = subscriptionData?.data.notificationCreated.message

        const alertType = subscriptionData.data.notificationCreated.alertType
        const finalType = equivocateEnum<AlertType, SnackbarTypes>(
          AlertType,
          SnackbarTypes,
          alertType,
        )
        snackbar.setMessage({
          type: finalType || SnackbarTypes.INFO,
          message: <NoticeLink msg={msg} link={link} />,
          icon: getIcon(subscriptionData.data.notificationCreated.code),
          show: true,
          anchorOrigin: { vertical: 'top', horizontal: 'right' },
        })
      }
    },
  })

  /*******************************************************
   * RENDER
   *******************************************************/
  return null
}

export default NotificationsBus
