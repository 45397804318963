import styled from 'styled-components'
import colors from 'constants/colors'
import { Grid } from '@material-ui/core'
import { AccessTime } from '@material-ui/icons'

export const CardRow = styled.div`
  padding: 0px 14px 0px 20px;
  min-height: 50px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  color: ${colors.dark};
  ${(props) =>
    props.noBorder
      ? `padding-bottom: 0px;`
      : `border-bottom: 1px solid ${colors.paleGrey};
  `}

  & .MuiAvatar-circle {
    margin-left: -40px;
    margin-top: 16px;
  }
`

export const CardValue = styled.span`
  font-weight: 600;
  font-size: 14px;
  display: flex;
  align-items: center;
`

export const Stage = styled.span`
  text-transform: capitalize;
`

export const VisibilityGrid = styled(Grid)`
  && {
    min-height: 36px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
`

export const ExpiredBlock = styled.div`
  display: flex;
  align-items: center;
  padding: 6px 8px;
  border-radius: 4px;
  background-color: ${colors.pink};
`

export const ClockIcon = styled(AccessTime)`
  && {
    margin-left: 4px;
    font-size: 18px;
    color: ${colors.grapefruit};
  }
`

export const TruncatedBlock = styled.div`
  max-width: 180px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export const AvatarWrapper = styled.div`
  width: 30px;
  margin-right: 15px;
`
