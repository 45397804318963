// @react
import React, { useEffect } from 'react'
// @desing
import { Grid, DialogActions, DialogContent } from '@material-ui/core'
import * as SC from '../styledComponents/createDealTeam'
import * as ButtonsSC from 'modules/common/components/_UI_DUMB/Button/styledComponents'
// @common
import strings from 'constants/strings'
// components
import CenteredLoader from 'modules/common/components/_UI_DUMB/Loaders/CenteredLoader'
import { Scalars } from 'graphql/graphqlTypes'
import ManageDealTeamDialogContent from '../../Dialogs/DialogContent/ManageDealTeamDialogContent'

/*******************************************************
 * TYPES
 *******************************************************/
interface SelfProps {
  onSubmit: () => void
  previousStep: (unknown) => void
  saveAndClose: (...args) => void
  continueSolo: () => void
  teamId: Scalars['ID']
  dealId: Scalars['ID']
  dealLoading: boolean
  changeStepTitle: (title: string) => void
}

type PropsType = SelfProps

/**
 *
 * @param onSubmit
 * @param previousStep
 * @param saveAndClose
 * @param continueSolo
 * @param teamId
 * @param dealLoading
 * @param dealId
 * @param changeStepTitle
 * @constructor
 */
const CreateDealManageTeam = ({
  onSubmit,
  previousStep,
  saveAndClose,
  continueSolo,
  teamId,
  dealLoading,
  dealId,
  changeStepTitle,
}: PropsType) => {
  /*******************************************************
   * HOOKS
   *******************************************************/
  useEffect(() => {
    changeStepTitle('Deal Team')
  }, [])

  /*******************************************************
   * RENDER COMPONENTS
   *******************************************************/
  const DialogActionsContent = () => {
    return (
      <SC.Wrap>
        <Grid container justifyContent="center" spacing={3}>
          <Grid item xs={12} sm={6}>
            <ButtonsSC.SubmitBtn
              variant="contained"
              onClick={continueSolo}
              type="button"
              color="primary"
            >
              {strings.CONTINUE_SOLO}
            </ButtonsSC.SubmitBtn>
          </Grid>
          <Grid item xs={12} sm={6}>
            <ButtonsSC.SubmitBtn
              disabled={dealLoading}
              variant="contained"
              color="primary"
              type="submit"
            >
              {dealLoading ? <CenteredLoader size={25} /> : strings.NEXT_STEP}
            </ButtonsSC.SubmitBtn>
          </Grid>
        </Grid>
        <Grid container justifyContent="center">
          <Grid item>
            <ButtonsSC.ActionBtn
              onClick={previousStep}
              size="small"
              color="secondary"
            >
              <span className="underline">{strings.PREVIOUS_STEP}</span>
            </ButtonsSC.ActionBtn>
          </Grid>
          <Grid item>
            <ButtonsSC.ActionBtn
              onClick={(data) => saveAndClose(data)}
              size="small"
              color="secondary"
            >
              <span className="underline">{strings.SAVE_AND_CLOSE}</span>
            </ButtonsSC.ActionBtn>
          </Grid>
        </Grid>
      </SC.Wrap>
    )
  }

  /*******************************************************
   * RENDER
   *******************************************************/
  return (
    <>
      <DialogContent>
        <SC.Subtitle variant="subtitle1">My Team</SC.Subtitle>
        <ManageDealTeamDialogContent myTeamId={teamId} dealId={dealId} />
      </DialogContent>
      <DialogActions>
        <form onSubmit={onSubmit}>
          <DialogActionsContent />
        </form>
      </DialogActions>
    </>
  )
}

export default CreateDealManageTeam
