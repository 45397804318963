// @react
import { useState, useCallback, useContext } from 'react'
import ItemsDetailsComments from '../ItemsDetailsComments'
// @components
import MessagesTextarea, {
  isEmptyText,
} from 'modules/common/components/_FORM_ELEMENTS/Message/MessagesTextarea'
// @graphql
import {
  CreateCommentMutation,
  CreateCommentMutationVariables,
  DeleteCommentMutation,
  DeleteCommentMutationVariables,
  EditMutationMutation,
  EditMutationMutationVariables,
  GetItemUsersQuery,
  GetItemUsersQueryVariables,
  Item,
  ItemActions,
  Scalars,
  User,
  GetItemQueryVariables,
  GetItemQuery,
} from 'graphql/graphqlTypes'
import { useQuery, useMutation, ApolloQueryResult } from '@apollo/client'
import { UserContext } from 'modules/user/context/user'
import {
  ItemEditCommentContext,
  ItemEditCommentProps,
} from 'modules/item/contexts/item'
import {
  CREATE_COMMENT,
  DELETE_COMMENT,
  EDIT_COMMENT,
} from 'graphql/mutations/comment.mutation'
import { GET_ITEM_USERS } from 'graphql/queries/user.query'
import useErrorHandler from 'modules/common/hooks/useErrorHandler'

/*******************************************************
 * TYPES
 *******************************************************/
type PropTypes = {
  item: Item
  onUpdate: (
    variables?: Partial<GetItemQueryVariables>,
  ) => Promise<ApolloQueryResult<GetItemQuery>>
}

export type EditCommentWrapperProps = {
  id: Scalars['ID']
  body: string
  parentEntity: Scalars['ID']
}

export type EditCommentTextProps = {
  value: string | undefined
  commentId?: Scalars['ID']
}

/**
 *
 * @param item
 * @param onUpdate
 * @constructor
 */
const ItemComments = ({ item, onUpdate }: PropTypes) => {
  /*******************************************************
   * STATE
   *******************************************************/
  const [isLoading, setIsLoading] = useState(false)
  const [editCommentText, setEditCommentText] = useState<EditCommentTextProps>({
    value: undefined,
  })
  /*******************************************************
   * CONTEXT
   *******************************************************/
  const currentUser = useContext(UserContext) as { user: User }

  /*******************************************************
   * HOOKS
   *******************************************************/

  /*******************************************************
   * GRAPHQL
   *******************************************************/
  const { data: mentionsList } = useQuery<
    GetItemUsersQuery,
    GetItemUsersQueryVariables
  >(GET_ITEM_USERS, {
    // @todo note if this is not cache only or cache first, then an infinite state update is caused
    // that implies that we are fetching GQL objects of same ID, but different properties, needs to be investigated more deeply
    fetchPolicy: 'cache-first',
    variables: {
      itemId: item.id,
    },
  })

  const [createComment, { error: createCommentMutationErrors }] = useMutation<
    CreateCommentMutation,
    CreateCommentMutationVariables
  >(CREATE_COMMENT)
  useErrorHandler(createCommentMutationErrors)

  const [updateComment, { error: updateCommentMutationErrors }] = useMutation<
    EditMutationMutation,
    EditMutationMutationVariables
  >(EDIT_COMMENT)
  useErrorHandler(updateCommentMutationErrors)

  const [deleteComment, { error: deleteCommentMutationErrors }] = useMutation<
    DeleteCommentMutation,
    DeleteCommentMutationVariables
  >(DELETE_COMMENT)
  useErrorHandler(deleteCommentMutationErrors)

  /*******************************************************
   * HOOKS
   *******************************************************/
  const editComment = useCallback((id: Scalars['ID'], text: string) => {
    setEditCommentText({
      value: text,
      commentId: id,
    })
  }, [])

  /*******************************************************
   * FUNCTIONS
   *******************************************************/
  /**
   *
   * @param inputData
   */
  const createCommentWrapper = async (inputData) => {
    const commentBody = inputData.body.replace(/[\u0800-\uFFFF]/g, '')
    // this regex started failing, with negative lookahead (origibnal) it breaks safari
    // const isText = /(:>)([\w]+)(?=<\/)/.test(commentBody)
    const isText = !isEmptyText(commentBody)

    if (!isText) {
      return
    }

    const inputCommentData = {
      body: commentBody,
      item: inputData.parentEntity,
    }

    void (await createComment({
      variables: {
        input: {
          ...inputCommentData,
        },
      },
    }))
    setIsLoading(true)

    await onUpdate()
  }

  /**
   *
   * @param id
   */
  const onCommentDelete = async (id: Scalars['ID']) => {
    await deleteComment({
      variables: { id },
    })
    await onUpdate()
  }

  /**
   *
   * @param data
   */
  const editCommentWrapper = async (data: EditCommentWrapperProps) => {
    void (await updateComment({
      variables: {
        input: {
          body: data.body,
          id: data.id,
        },
      },
    }))
    await onUpdate()
  }

  const thisUserAvatar = currentUser.user.avatar?.file?.location

  const textareaProps: ItemEditCommentProps = {
    editedCommentText: editCommentText,
    editHandler: editCommentWrapper,
    userMentions: mentionsList?.userItem.data,
    cancelEditing: () => {
      setEditCommentText({
        value: undefined,
      })
    },
    submitHandler: createCommentWrapper,
    parentEntityId: item.id,
  }

  /*******************************************************
   * RENDER
   *******************************************************/
  return (
    <ItemEditCommentContext.Provider value={textareaProps}>
      <ItemsDetailsComments
        loadingMore={isLoading}
        onEditAction={editComment}
        onDeleteAction={onCommentDelete}
        comments={item.comments || []}
      />
      {item.actions?.includes(ItemActions.ITEM_COMMENT) && (
        <MessagesTextarea
          {...textareaProps}
          editedCommentText={
            editCommentText.commentId
              ? {
                  value: '',
                }
              : textareaProps.editedCommentText
          }
          userAvatar={thisUserAvatar}
          isWhiteBackground
        />
      )}
    </ItemEditCommentContext.Provider>
  )
}

export default ItemComments
