// @react
import React, { useEffect, useState } from 'react'
// @design
import { Grid } from '@material-ui/core'
import * as SC from '../styledComponents/dealTeamDialogue'
// @common
import useErrorHandler from 'modules/common/hooks/useErrorHandler'
import TeamModel from '../../../models/team'
import useAsyncEffect from 'use-async-effect'
import useDebounce from 'modules/common/hooks/useDebounce'
import { config } from 'constants/config'
// @components
import DealTeamContactsList from '../../UserRelationsManage/DealTeamContactsList'
import * as ContactItemSC from 'modules/common/components/_UI_SMART/ContactItem/styledComponents/contactItem'
import EmailFieldsList from '../../EmailFieldsList'
import ProfileMyContactsSearch from 'modules/user/components/ProfileMyContactsSearch'
// @graphql
import { useQuery } from '@apollo/client'
import {
  GET_DEAL_CONTACTS,
  GET_USER_CONTACTS,
} from 'graphql/queries/user.query'
import {
  InviteType,
  Order,
  UsersOrderByList,
  GetUserDealContactsQueryVariables,
  GetUserDealContactsQuery,
  GetInvitesQueryVariables,
  GetInvitesQuery,
  Scalars,
  GetUserContactsQuery,
  ContactStatus,
  GetUserContactsQueryVariables,
  UsersMeta,
  UserContactPayloadMeta,
  GetDealInfoQuery,
  GetDealInfoQueryVariables,
} from 'graphql/graphqlTypes'
import { GET_INVITES } from 'graphql/queries/invite.query'
import userActionsHook from '../../UserRelationsManage/userActionsHook'
import { GET_DEAL_INFO } from 'graphql/queries/deal.query'

/*******************************************************
 * TYPES
 *******************************************************/
type PropsType = {
  myTeamId: Scalars['ID']
  dealId?: Scalars['ID']
  addToContacts?: any
}
/**
 *
 * @param props
 * @constructor
 *
 * Currently only used in deal create, create team step
 */
const ManageDealTeamDialogContent = ({ myTeamId, dealId }: PropsType) => {
  /*******************************************************
   * STATE
   *******************************************************/
  const [users, setUsers] = useState<any[]>([]) // @todo  review this state
  const [meta, setMeta] = useState<
    Partial<UsersMeta> | UserContactPayloadMeta
  >()
  const [searchTerm, setSearchTerm] = useState<string>('')
  const debouncedSearchValue = useDebounce(searchTerm, config.debounce)

  /*******************************************************
   * GRAPHQL
   *******************************************************/
  /**
   * queries
   */
  const {
    data: dealData,
    loading: dealLoading,
    error: dealError,
    refetch: dealRefetch,
  } = useQuery<GetDealInfoQuery, GetDealInfoQueryVariables>(GET_DEAL_INFO, {
    variables: { id: dealId || '' },
    skip: !dealId,
  })
  useErrorHandler(dealError)
  /*******************************************************
   * GRAPHQL
   *******************************************************/

  /**
   * QUERIES
   */
  const { data, refetch, loading, fetchMore, error } = useQuery<
    GetUserDealContactsQuery,
    GetUserDealContactsQueryVariables
  >(GET_DEAL_CONTACTS, {
    // fetchPolicy: !dealData?.deal ? 'cache-only' : 'network-only',
    // fetchPolicy: 'network-only',
    variables: {
      filters: {
        search: null,
      },
      orderBy: {
        order: Order.ASC,
        value: UsersOrderByList.FIRST_NAME,
      },
      dealId: dealId || '',
    },
    skip: !dealId,
  })

  useErrorHandler(error)
  const {
    data: contacts,
    refetch: contactsRefetch,
    loading: contactsLoading,
    fetchMore: contactsFetchMore,
    error: contactsError,
  } = useQuery<GetUserContactsQuery, GetUserContactsQueryVariables>(
    GET_USER_CONTACTS,
    {
      fetchPolicy: dealData?.deal ? 'cache-only' : 'network-only',
      variables: {
        filters: { search: searchTerm },
        contactStatus: ContactStatus.ACCEPTED,
      },
    },
  )
  useErrorHandler(contactsError)

  const {
    data: invitedData,
    refetch: invitesRefetch,
    error: getInvitesQueryError,
  } = useQuery<GetInvitesQuery, GetInvitesQueryVariables>(GET_INVITES, {
    variables: {
      filters: {
        deal: dealData?.deal?.id,
        type: [InviteType.TEAM, InviteType.THIRD_PARTY],
        byEmail: true,
      },
    },
    fetchPolicy: 'network-only',
    skip: !dealData?.deal?.id,
  })
  useErrorHandler(getInvitesQueryError)

  /*******************************************************
   * LIFECYCLE HOOKS
   *******************************************************/
  // useEffect(() => {
  //   if (invitedData && data)
  //     // @todo this is messed up we're assigned Invites to a Users state and mixing that with Simplified Users
  //     setUsers([
  //       ...(invitedData.invites.data || []),
  //       ...(data.userDeal.data || []),
  //     ])
  // }, [invitedData, data])

  useEffect(() => {
    if (!dealData?.deal && contacts) {
      setUsers([...(contacts.userContacts.data || [])])
      setMeta({ ...contacts.userContacts.meta })
    }
    if (dealData?.deal && data) {
      setUsers([...(data.userDeal.data || [])])
      setMeta({ ...data.userDeal.meta })
    }
  }, [contacts, data])

  /*******************************************************
   * INVITE HOOK
   *******************************************************/
  const { handlers } = userActionsHook(refetch, myTeamId)

  /*******************************************************
   * SEARCH
   *******************************************************/
  /**
   * useCallback
   */
  useAsyncEffect(async () => {
    if (debouncedSearchValue !== undefined) {
      await refetch({ filters: { search: debouncedSearchValue } })

      void (await invitesRefetch({
        filters: {
          deal: dealData?.deal?.id,
          type: [InviteType.TEAM, InviteType.THIRD_PARTY],
          byEmail: true,
          search: debouncedSearchValue,
        },
      }))
    }
  }, [debouncedSearchValue])

  useAsyncEffect(async () => {
    if (debouncedSearchValue !== undefined) {
      await refetch({ filters: { search: debouncedSearchValue } })
    }
  }, [debouncedSearchValue])

  /*******************************************************
   * FUNCTIONS
   *******************************************************/

  const loadMoreHandler = async () => {
    if (data?.userDeal.meta && fetchMore) {
      const newPageNum = (data.userDeal.meta.page || 0) + 1

      await fetchMore({
        variables: {
          page: newPageNum,
        },
      })
    }
  }

  const refetchHandler = async () => {
    await dealRefetch()
    await invitesRefetch()
    await refetch()
  }

  /*******************************************************
   * RENDER COMPONENTS
   *******************************************************/
  const isLoadMoreNeeded = (meta?.totalPages || 0) > (meta?.page || 0) + 1

  /**
   *
   * @type {{myTeamId: string, myTeamLeadUserId?: string, theirTeamId?: string, myTeamTeamMembers?: SimplifiedUserNodeFragment[] | null, theirTeamMembers?: SimplifiedUserNodeFragment[] | null, theirTeamLeadUserId?: string} | null}
   */
  const teamMembership = TeamModel.calculateTeamMembership(dealData?.deal)

  if (dealData?.deal && !teamMembership) return <></>
  const myTeamLeadUserId = teamMembership?.myTeamLeadUserId
  const showContactsList = () => {
    return (
      !!myTeamId &&
      !loading &&
      !!(myTeamLeadUserId || !dealData?.deal) &&
      !!data &&
      !dealLoading &&
      !!dealData
    )
  }
  /*******************************************************
   * RENDER
   *******************************************************/
  return (
    <>
      <ContactItemSC.DialogContactListWrap>
        <Grid container spacing={1} alignItems="center">
          <SC.InputWrap item>
            <ProfileMyContactsSearch
              value={searchTerm}
              onClear={() => setSearchTerm('')}
              onChange={(e) => setSearchTerm(e.target.value)}
            />
          </SC.InputWrap>
        </Grid>
      </ContactItemSC.DialogContactListWrap>

      <ContactItemSC.DialogContactList data-testid="deal-team-list">
        <DealTeamContactsList
          userContactsData={users}
          teamId={myTeamId}
          loadMore={{
            isLoadMoreNeeded,
            loadMoreHandler,
          }}
          refetch={refetchHandler}
        />
      </ContactItemSC.DialogContactList>

      <EmailFieldsList<
        (userId: Scalars['ID'], isThirdParty: boolean) => Promise<void>
      >
        inviteMenu={[
          {
            handler: (email) =>
              handlers.teamInviteSendHandler(null, false, email),
            text: 'As team member',
          },
          {
            handler: (email) =>
              handlers.teamInviteSendHandler(null, true, email),
            text: 'As third party',
          },
        ]}
      />
    </>
  )
}

export default ManageDealTeamDialogContent
