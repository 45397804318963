// @react
import React, { ReactElement } from 'react'
// @common
import { QueryResult, useQuery } from '@apollo/client'
// @constants
import strings from 'constants/strings'
// @components
import ContextTableWrapper from 'modules/common/components/_UI_SMART/SharedTable/ContextTableWrapper'
import DealsSortSearch from 'modules/deal/pages/Deals/components/DealsSortSearch'
import DealsGrid from 'modules/deal/pages/Deals/components/DealsGrid'
// @graphql
import { GET_DEALS } from 'graphql/queries/deal.query'
import {
  DealsOrderByList,
  GetDealsQuery,
  GetDealsQueryVariables,
  Order,
} from 'graphql/graphqlTypes'
import config from 'constants/config'

/**
 *
 * @constructor
 * @return {any}
 */
const DealsFiltered = (): ReactElement => {
  /*******************************************************
   * GRAPHQL
   *******************************************************/
  const queryResult: QueryResult<GetDealsQuery, GetDealsQueryVariables> =
    useQuery<GetDealsQuery, GetDealsQueryVariables>(GET_DEALS, {
      fetchPolicy: 'network-only',
      variables: {
        orderBy: {
          order: Order.DESC,
          value: DealsOrderByList.CREATED_DATE,
        },
        pageSize: config.defaultPageSize,
      },
    })

  return (
    <ContextTableWrapper queryResult={queryResult}>
      <DealsSortSearch />
      <DealsGrid
        {...queryResult}
        cardPrefixes={{
          subtitle: strings.FROM,
          byLine: strings.LAST_UPDATED,
        }}
      />
    </ContextTableWrapper>
  )
}

export default DealsFiltered
