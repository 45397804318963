// @react
import React from 'react'
import { FileIcon } from '@drawbotics/file-icons'
import { mimeTypes } from 'constants/mime'
import { File } from 'graphql/graphqlTypes'
import * as SC from './styledComponents'

type PropsType = {
  file: Pick<File, 'mimetype'>
}

/**
 *
 * @param props
 * @constructor
 */
const SwitchBoardFileIcon = (props: PropsType) => {
  const { file } = props
  let key

  if (file && mimeTypes.hasOwnProperty(file.mimetype)) {
    key = mimeTypes[file.mimetype]
  } else {
    key = 'doc'
  }
  return (
    <SC.PaddingLeft>
      <FileIcon file={key} />
    </SC.PaddingLeft>
  )
}

export default SwitchBoardFileIcon
