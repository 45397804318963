import TableChartIcon from '@material-ui/icons/TableChart'
import BarChartIcon from '@material-ui/icons/BarChart'
import { useContext } from 'react'
import { IconButtonStyled } from '../styledComponents'
import { PipelineContext } from 'modules/pipeline/contexts/pipeline'
import { ChartType } from 'modules/pipeline/enum'

/**
 *
 * @constructor
 */
const ChartFormatSelect = () => {
  /*******************************************************
   * CONST
   *******************************************************/
  const values = [
    { icon: <BarChartIcon />, value: ChartType.CHART },
    { icon: <TableChartIcon />, value: ChartType.TABLE },
  ]
  /*******************************************************
   * CONETXT
   *******************************************************/
  const { state, dispatch } = useContext(PipelineContext)

  /*******************************************************
   * FUNCTIONS
   *******************************************************/
  const handleClick = (val: ChartType) => {
    dispatch({ type: 'setChartType', payload: { chartType: val } }, state)
  }

  /*******************************************************
   * RENDER
   *******************************************************/
  return (
    <div>
      {values.map((button) => {
        const isSelected = state.chartType === button.value
        return (
          <IconButtonStyled
            key={button.value}
            color={isSelected ? 'primary' : 'secondary'}
            isactive={isSelected ? String(isSelected) : undefined}
            component="span"
            onClick={() => handleClick(button.value)}
          >
            {button.icon}
          </IconButtonStyled>
        )
      })}
    </div>
  )
}

export default ChartFormatSelect
