import {
  ContactStatus,
  GetDealInfoQuery,
  SimplifiedUser,
  SimplifiedUserNodeFragment,
  TeamRole,
  User,
  UserDealFragment,
} from 'graphql/graphqlTypes'
import {
  ContactItemCTA,
  ContactItemVariant,
  MenuItem,
} from 'modules/common/components/_UI_SMART/ContactItem/type'
import { isOfType, ItemStatus, Team } from 'modules/common/types'
import ContactItem from 'modules/common/components/_UI_SMART/ContactItem/ContactItem'
import LoadMoreBtn from 'modules/common/components/_UI_SMART/LoadMoreBtn'
import React, { useContext } from 'react'
import { UserContext } from 'modules/user/context/user'
import useUserActions from './userActionsHook'
import UserModel from 'modules/user/models/user'
import { DealContext } from 'modules/deal/contexts/deal'
import TeamModel from 'modules/deal/models/team'
import { RouteNames } from 'constants/routeNames'

/*******************************************************
 * FUNCTIONS: HELPERS
 *******************************************************/
/**
 * global rules for editing team:
 *  1. user and current user must be on same team
 *  2. current user is role LEAD
 *  3. current user is role MEMBER
 * @param currentUser
 * @param {string} currentUserTeamId
 * @returns {boolean}
 */
//const canActOnList = (
//  currentUser: SimplifiedUser,
//  currentUserTeamId?: string,
//): boolean => {
//  return true
//  const isUserLead = currentUser.userDeal?.teamRole === TeamRole.LEAD
//  return currentUser.userDeal?.teamId === currentUserTeamId && !isUserLead
//}

/**
 *  item status shows whether user has a relationship or not
 * @param {SimplifiedUser} user
 * @param {boolean} isUserLead
 * @returns {ItemStatus | undefined}
 */
const getItemStatus = (
  user: SimplifiedUser,
  isUserLead: boolean,
): ItemStatus | undefined => {
  const contactStatus = user.userDeal?.status
  if (
    contactStatus === ContactStatus.PENDING ||
    contactStatus === ContactStatus.ACCEPTED
  ) {
    return ItemStatus.CONNECTED
  }
  if (user?.userDeal?.teamType === Team.THEIR_TEAM || isUserLead) {
    return ItemStatus.DISABLED
  }

  return undefined
}

/*******************************************************
 * FUNCTIONS: BUTTON AND MENU ASSEMBLY
 *******************************************************/
/**
 *
 * @param {SimplifiedUser} user
 * @param menuItems
 * @param buttonGetters
 * @returns {ContactItemCTA[]}
 */
const getButtons = (
  user: SimplifiedUser,
  menuItems,
  buttonGetters,
): ContactItemCTA[] => {
  const buttons: ContactItemCTA[] = []

  if (menuItems?.length) {
    buttons.push({
      variant: ContactItemVariant.MENU,
      items: menuItems,
    })
  }

  const addTeamMemberButton = buttonGetters.getAddTeamMemberCTA(user)
  if (addTeamMemberButton) {
    buttons.push(addTeamMemberButton)
  }

  return buttons
}
/**
 *
 * @param {SimplifiedUser} user
 * @param {User} currentUser
 * @param buttonGetters
 * @param myTeamLeadUserId
 * @param deal
 * @returns {{text: string, handler: () => void}[]}
 */
const getMenuItems = (
  user: SimplifiedUser,
  currentUser: User,
  buttonGetters,
  deal?: GetDealInfoQuery['deal'],
): (() => MenuItem | null)[] => {
  const contactStatus = user.userDeal?.status

  /**
   * determine action that can be done according to contact status type
   */
  return [
    () =>
      buttonGetters.getInviteCancelTeamMemberMenuItem(
        user,
        currentUser,
        deal,
        contactStatus,
      ),
    () =>
      buttonGetters.getMigrateTeamLeadMenuItem(
        user,
        currentUser,
        deal,
        contactStatus,
      ),
    () =>
      buttonGetters.getRemoveUserFromTeamMenuItem(
        user,
        currentUser,
        deal,
        contactStatus,
      ),
    () =>
      buttonGetters.getRemoveSelfFromTeamMenuItem(
        user,
        currentUser,
        deal,
        contactStatus,
      ),
  ]
}

/**
 *
 * @param userContact
 * @param currentUser
 * @param buttonGetters
 * @param teamContext
 * @param addToMenuItems
 * @param deal
 * @returns {ContactItemCTA[]}
 */
const buttonAssembly = (
  userContact,
  buttonGetters,
  addToMenuItems,
  currentUser,
  deal?: GetDealInfoQuery['deal'],
): ContactItemCTA[] => {
  //  if (!canActOnList(currentUser, teamContext.id)) {
  //    return []
  //  }
  const menuItemFuncs = getMenuItems(
    userContact,
    currentUser,
    buttonGetters,
    deal,
  )
  const menuItems = addToMenuItems(menuItemFuncs)
  return getButtons(userContact, menuItems, buttonGetters)
}

/**
 *
 * @param {(SimplifiedUserNodeFragment & UserDealFragment)[] | null} userContactsData
 * @param {{id?: string, teamLeadUserId: string}} teamContext
 * @param {{isLoadMoreNeeded: boolean, loadMoreHandler: () => void}} loadMore
 * @param {any} refetch
 * @returns {JSX.Element | null}
 * @constructor
 */
const DealTeamContactsList = ({
  userContactsData,
  // teamContext,
  loadMore,
  refetch,
  teamId,
}: {
  userContactsData?: (SimplifiedUserNodeFragment & UserDealFragment)[] | null
  //  teamContext: {
  //    id?: string
  //    teamLeadUserId: string
  //  }
  teamId?: string
  loadMore: {
    isLoadMoreNeeded: boolean
    loadMoreHandler: () => void
  }
  refetch
}) => {
  const { user: currentUser } = useContext(UserContext)
  const { data: dealData, refetch: dealInfoRefetch } = useContext(DealContext)

  const teamMembership = TeamModel.calculateTeamMembership(dealData?.deal)
  const { buttonGetters, addToMenuItems } = useUserActions(() => {
    refetch()
    dealInfoRefetch && dealInfoRefetch()
  }, teamMembership?.myTeamId || teamId)

  if (!currentUser || !userContactsData?.length) return null

  const contactList = userContactsData.map((userContact) => {
    if (
      !isOfType<SimplifiedUser>(userContact, '__typename', 'SimplifiedUser')
    ) {
      return null
    }

    const contactStatus = userContact?.userDeal?.status
    const isUserLead = userContact.userDeal?.teamRole === TeamRole.LEAD
    const itemStatus = getItemStatus(userContact, isUserLead)
    const buttons = buttonAssembly(
      userContact,
      buttonGetters,
      addToMenuItems,
      currentUser,
      dealData?.deal,
    )
    const userMeta = UserModel.nameFormatter(userContact)

    return (
      <ContactItem
        key={userContact?.id}
        userId={userContact?.id}
        email={userContact?.email}
        name={userMeta.name}
        text={userMeta.title}
        avatar={userContact?.avatar?.file?.location}
        contactStatus={contactStatus}
        teamType={userContact?.userDeal?.teamType}
        showLeadIcon={isUserLead}
        buttons={buttons}
        role={userContact?.userDeal?.teamRole}
        itemStatus={itemStatus}
        onUserClick={() => {
          console.log('on user click')
        }}
      />
    )
  })

  return (
    <>
      {contactList}
      <LoadMoreBtn
        isVisible={loadMore.isLoadMoreNeeded}
        isLoading={false}
        onClick={loadMore.loadMoreHandler}
      />
    </>
  )
}

export default DealTeamContactsList
