import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Button, Fab, Grid, Paper, Typography } from '@material-ui/core'
import { DealStatusLabels } from 'modules/deal/models/deal'
import MUIScheduleIcon from '@material-ui/icons/Schedule'
import colors from 'constants/colors'
import { GridVariantType } from '..'
import { flexCenter } from 'modules/common/styledComponents/Mixins'

export const CardGridItemWrap = styled(Grid)`
  display: flex;
`

export const CardTop = styled.div`
  display: flex;
`

export const CardOuterWrap = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  flex-grow: 1;
`

export const CardImage = styled.div`
  width: 90px;
  height: 90px;
  flex-shrink: 0;
  margin-right: 20px;
  border-radius: 5px;
  overflow: hidden;

  svg {
    width: 100%;
    height: 100%;
  }

  img {
    width: 100%;
    height: 100%;
    object-position: 50%;
    object-fit: cover;
  }
`

export const CardText = styled.div``

export const CardTitle = styled(Typography)`
  && {
    font-weight: 600;
    font-size: 14px;
    line-height: 1.35;
    color: ${colors.dark};
  }
`

export const CardSubtitle = styled(Typography)`
  && {
    margin-top: 5px;
    font-weight: 400;
    font-size: 12px;
    line-height: 1.33;
    color: ${colors.dark};
  }
`

export const CardByLine = styled(Typography)`
  && {
    ${flexCenter}
  }
  margin-top: 5px;
  font-size: 10px;
`

export const ScheduleIcon = styled(MUIScheduleIcon)`
  margin-right: 5px;
  font-size: 14px;
`

export const CloseIcon = styled(Fab)`
  && {
    position: absolute;
    display: inline-flex;
    top: 10px;
    right: 10px;
    width: 20px;
    height: 20px;
    min-height: auto;
    box-shadow: none;
    z-index: 10;
    background-color: transparent;
    color: ${colors.lightGreyBlue};
    cursor: pointer;
    transition: color 0.3s ease;

    &:before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      width: 200%;
      height: 200%;
      transform: translate(-50%, -50%);
      border-radius: 50%;
    }

    &:hover {
      color: ${colors.grey};
    }

    svg {
      font-size: 20px;
    }
  }

  ~ ${CardTop} ${CardTitle} {
    padding-right: 20px;
  }
`

export const CardButton = styled(Button)`
  position: relative;
  z-index: 10;
  margin-top: 20px;
`

export const CardWrap = styled(Paper)<{ type: GridVariantType }>`
  && {
    flex-grow: 1;
    position: relative;
    padding: 20px;
    border-radius: 5px;
    box-shadow: none;
    transition: background-color 0.3s ease;
  }

  ${(props) =>
    props.type &&
    props.type === 'contact' &&
    `
      display: flex;
      flex-direction: column;

      ${CardText as string} {
        text-align: center;
      }

      ${CardTop as string} {
        flex-direction: column;
        align-items: center;
        margin-bottom: 20px;
      }

      ${CardImage as string} {
        width: 80px;
        height: 80px;
        margin: 0 0 10px;
        border-radius: 50%;
      }

      ${CardButton as string} {
        display: block;
        margin: auto auto 0;
      }
  `}
`

export const CardLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;

  &:hover ~ ${CardWrap} {
    background-color: ${colors.darkenBlue};
  }
`

export const CardStatus = styled.div<{
  variant: keyof typeof DealStatusLabels
}>`
  padding: 5px;
  color: ${colors.dark};
  border-radius: 0 0 5px 5px;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  order: 2;

  ~ ${CardWrap} {
    border-radius: 5px 5px 0 0;
  }

  ${(props) =>
    props.variant === 'DECLINED' &&
    `
    background-color: ${colors.battleshipGrey};
    color: ${colors.white};
  `}

  ${(props) =>
    props.variant === 'DRAFT' &&
    `
    background-color: ${colors.veryLightBlue};
  `}

  ${(props) =>
    props.variant === 'PENDING' &&
    `
    background-color: ${colors.paleYellow};
  `}

  ${(props) =>
    props.variant === 'OPEN' &&
    `
    color: ${colors.white};
    background-color: ${colors.aquaMarine};
  `}

  ${(props) =>
    props.variant === 'DEAD' &&
    `
    color: ${colors.white};
    background-color: ${colors.black};
  `}
`

export const DealStage = styled.div`
  background-color: ${colors.veryLightBlue};
  border-radius: 5px;
  font-size: 10px;
  color: ${colors.battleshipGrey};
  padding: 3px 7px;
  display: inline-block;
  margin-top: 10px;
`
