import { useMutation } from '@apollo/client'
import strings from 'constants/strings'
import {
  DealStage,
  DealStageDirection,
  DealStatus,
  MigrateDealMutation,
  MigrateDealMutationVariables,
  Scalars,
} from 'graphql/graphqlTypes'
import { DEAL_MIGRATE } from 'graphql/mutations/deal.mutation'
import { SnackbarTypes } from 'modules/common/components/_UI_DUMB/Snackbars/Snackbar'
import { SnackbarContext } from 'modules/common/context/snackbar'
import { createContext, useContext, useEffect, useState } from 'react'
import { DealContext } from '../deal'

interface DealStepperContextValue {
  dealStage?: DealStage
  moveStage?: any //TODO: fix typing
  setDirection?: (direction) => void //TODO: fix typing
  setDealStage?: any //TODO: fix typing
}
export const DealStepperContext = createContext<DealStepperContextValue>({
  dealStage: DealStage.PRELIM,
  moveStage: () => {},
  setDirection: (direction) => {},
})
const DealStepperContextWrapper = ({ children }) => {
  /*******************************************************
   * CONTEXT
   *******************************************************/
  const queryResult = useContext(DealContext)
  const snackbar = useContext(SnackbarContext)

  /*******************************************************
   * CONST
   *******************************************************/
  const {
    data: dealData,
    loading: dealLoading,
    refetch: dealRefetch,
    dealId,
  } = queryResult
  const dealStatus = dealData?.deal.status
  /*******************************************************
   * STATE
   *******************************************************/
  const [migrateDirection, setMigrateDirection] = useState<DealStageDirection>(
    DealStageDirection.FORWARD,
  )
  const [dealStage, setDealStage] = useState<DealStage>(
    dealData?.deal.stage || DealStage.PRELIM,
  )

  useEffect(() => {
    if (dealData?.deal.stage) {
      setDealStage(dealData.deal.stage)
    }
  }, [dealData?.deal.stage])

  /**
   * mutations
   */
  const [migrateDeal] = useMutation<
    MigrateDealMutation,
    MigrateDealMutationVariables
  >(DEAL_MIGRATE)
  /*******************************************************
   * FUNCTIONS
   *******************************************************/
  /**
   * handleMigration
   * @param dealId
   * @param direction
   * @return {Promise<void>}
   */
  const handleMigration = async (
    dealId: Scalars['ID'],
    direction: DealStageDirection,
  ) => {
    const deal = await migrateDeal({
      variables: {
        id: dealId,
        direction,
      },
    })
    dealRefetch && (await dealRefetch())
  }

  const moveStage = async (direction?: DealStageDirection) => {
    if (dealStatus === DealStatus.DEAD) return
    dealId && (await handleMigration(dealId, direction || migrateDirection))
    snackbar.setMessage({
      message: strings.SUCCESS_UPDATE,
      type: SnackbarTypes.SUCCESS,
      show: true,
      anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
    })
  }

  const contextValue = {
    setMigrateDirection,
    moveStage,
    dealStage,
    setDealStage,
  }
  return (
    <DealStepperContext.Provider value={contextValue}>
      {children}
    </DealStepperContext.Provider>
  )
}

export default DealStepperContextWrapper
