import styled from 'styled-components'
import colors from 'constants/colors'
import { Typography } from '@material-ui/core'
import { device, from } from 'modules/common/styledComponents/MediaQueries'

export const Wrap = styled.div`
  width: 100%;
  padding: 2em 1.5em;
  background-color: ${colors.white};
  text-align: center;

  ${from(device.tablet)} {
    padding: 3.125em 2em;
  }

  img {
    display: inline-block;
    margin-top: 2em;
    width: 100%;
    max-width: 300px;

    ${from(device.tablet)} {
      margin-top: 3.5em;
    }
  }
`

export const ButtonWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-top: -5px;
  margin-bottom: -5px;

  .MuiButton-root {
    margin: 5px;
  }
`

export const Illustration = styled.div<{ placement: 'top' }>`
  margin-bottom: 1.7em;

  ${(props) =>
    props.placement === 'top' &&
    `
    img {
      ${from(device.tablet)} {
        margin-top: 0;
        max-width: 170px;
      }
    }
  `}
`

export const Title = styled(Typography)`
  margin-bottom: 1em;
  font-size: 20px;
  line-height: 1.34;
  color: ${colors.dark};

  ${from(device.tablet)} {
    font-size: 26px;
  }
`
