// @react
import { useMemo, useContext, ReactElement } from 'react'
// @design
import {
  HideOnDesktop,
  HideOnMobile,
} from 'modules/common/styledComponents/MediaQueries'
import * as SC from 'modules/common/styledComponents/Global'
import { Grid } from '@material-ui/core'
// @components
import Kanban, {
  CardData,
  CardMetaData,
} from 'modules/kanban/components/Kanban'
import NotFound from 'modules/common/pages/NotFound'
import CenteredLoader from 'modules/common/components/_UI_DUMB/Loaders/CenteredLoader'
// @graphql
import { GET_ITEMS } from 'graphql/queries/item.query'
import { useQuery, useMutation } from '@apollo/client'
import { ITEM_UPDATE } from 'graphql/mutations/item.mutation'
// @context
import { DealContext } from 'modules/deal/contexts/deal'
import { SnackbarContext } from 'modules/common/context/snackbar'
// @constants
import colors from 'constants/colors'
import strings from 'constants/strings'
import { SnackbarTypes } from 'modules/common/components/_UI_DUMB/Snackbars/Snackbar'
import {
  DealStage,
  ItemsOrderByList,
  ItemStatus,
  Order,
  DealStatus,
  GetItemsQueryVariables,
  GetItemsQuery,
  ItemUpdateMutationVariables,
  ItemUpdateMutation,
  Item,
  UpdateItemInput,
} from 'graphql/graphqlTypes'
// @models
import DealModel from 'modules/deal/models/deal'
import { isOfType } from 'modules/common/types'
import Lane = ReactTrello.Lane
import BoardData = ReactTrello.BoardData

/*******************************************************
 * TYPES
 *******************************************************/

type SortedByStage = { [key in DealStage]: Item[] }

/**
 *
 * @constructor
 * @return {any}
 */
const KanbanWrapper = (): ReactElement | null => {
  /*******************************************************
   * CONSTANTS
   *******************************************************/
  const initStages = DealModel.getStageViewStages()

  /*******************************************************
   * CONTEXT
   *******************************************************/
  const dealQueryResult = useContext(DealContext)
  const snackbar = useContext(SnackbarContext)

  const dealData = dealQueryResult.data
  const deal = dealQueryResult.data?.deal

  /*******************************************************
   * GQL
   *******************************************************/
  /**
   * queries
   */
  const { data, previousData } = useQuery<
    GetItemsQuery,
    GetItemsQueryVariables
  >(GET_ITEMS, {
    variables: {
      orderBy: {
        order: Order.ASC,
        value: ItemsOrderByList.LANE_ORDER,
      },
      filters: {
        deal: deal ? [deal.id] : [],
        createdDateEnd: null,
        createdDateStart: null,
      },
      pageSize: 100,
    },
    skip: !deal,
  })

  /**
   * mutations
   */
  const [updateItemMutation] = useMutation<
    ItemUpdateMutation,
    ItemUpdateMutationVariables
  >(ITEM_UPDATE)

  /*******************************************************
   * FUNCTIONS
   *******************************************************/
  /**
   *
   * @param sourceLaneId
   * @param targetLaneId
   * @param itemId
   * @param position
   * @return {Promise<void>}
   */
  const handleMigrateCard = async (
    sourceLaneId,
    targetLaneId,
    itemId,
    position: number,
  ) => {
    const sourceStageOrder = DealModel.getStageOrder(sourceLaneId)
    const targetStageOrder = DealModel.getStageOrder(targetLaneId)
    let targetStatus

    const currDealStageOrder = deal?.stage
      ? DealModel.getStageOrder(deal.stage)
      : 0

    if (targetStageOrder < currDealStageOrder) {
      targetStatus = ItemStatus.CLOSED
    }

    const input: Pick<UpdateItemInput, 'stage' | 'status'> = targetStatus
      ? {
          stage: targetLaneId,
          status: targetStatus,
        }
      : {
          stage: targetLaneId,
        }

    await updateItemMutation({
      variables: {
        input: {
          id: itemId,
          laneOrder: position,
          ...input,
        },
      },
    })
    if (targetStageOrder !== sourceStageOrder) {
      snackbar.setMessage({
        message: strings.SUCCESS_UPDATE,
        type: SnackbarTypes.SUCCESS,
        show: true,
        anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
      })
    }
  }

  /**
   *
   */
  const result = data?.items.data
    ? data.items.data.reduce<SortedByStage>((acc, curr) => {
        if (isOfType<Item>(curr, '__typename', 'Item')) {
          acc[curr.stage] = acc[curr.stage] || []
          acc[curr.stage].push(curr)
        }
        return acc
      }, {} as SortedByStage)
    : []

  const populatedLanes: Lane[] = initStages.map((stage) => {
    const isActive = dealData?.deal.stage === stage

    const cards: CardData[] =
      result && result[stage]
        ? result[stage]
            .map((item) => {
              return {
                id: item.id.toString(),
                title: item.name,
                description: item.description,
                label: item.status,
                dueDate: item.dueDate || '',
                draggable: true,
                index: item?.laneOrder || 0,
                permissions: item.permissions,
                style: {
                  backgroundColor:
                    item.status === ItemStatus.CLOSED ? colors.medGrey : '',
                  fontStyle: item.status === ItemStatus.CLOSED ? 'italic' : '',
                },
                metadata: {
                  sha: item.id,
                  status: item.status,
                  stage: item.stage,
                },
              }
            })
            .sort((a) => (a.metadata.status === 'OPEN' ? -1 : 1))
        : []

    cards.push({
      id: '-1',
      title: '',
      index: 10000,
      style: { display: 'none' },
    })

    return {
      id: stage,
      title: DealModel.getStageLabel(stage).toUpperCase(),
      label: stage,
      style: {
        backgroundColor: isActive ? colors.duckEggBlue : colors.white,
        border: '0px',
      },
      cards,
    }
  })

  const populatedBoardData: BoardData<CardMetaData> = useMemo(() => {
    return {
      lanes: populatedLanes,
    }
  }, [data?.items, dealData?.deal.stage])

  /*******************************************************
   * RENDER
   *******************************************************/
  if (!dealQueryResult.data && !dealQueryResult.loading) {
    return <NotFound />
  }

  if (data) {
    return (
      <>
        <HideOnDesktop>
          <SC.ContentContainer>
            <Grid container spacing={5}>
              <Grid item xs={12} sm={12} lg={6}>
                The Kanban card view mode has been optimized for larger screens
              </Grid>
            </Grid>
          </SC.ContentContainer>
        </HideOnDesktop>
        <HideOnMobile>
          {deal?.id && (
            <Kanban
              handleMigrateCard={handleMigrateCard}
              dealId={deal.id}
              data={populatedBoardData}
              firstLoad={!previousData}
              isCardDraggable={deal.status !== DealStatus.DEAD}
            />
          )}
        </HideOnMobile>
      </>
    )
  }

  return <CenteredLoader />
}

export default KanbanWrapper
