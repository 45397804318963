// @react
import React, { ReactElement, useContext } from 'react'
// @design
import * as SC from 'modules/common/components/_UI_SMART/SharedTable/styledComponents/sharedTable'
import { Grid } from '@material-ui/core'
// @components
import DatesFilter from 'modules/common/components/_UI_SMART/SharedTable/Filters/DatesFilter'
import ListFilter from 'modules/common/components/_UI_SMART/SharedTable/Filters/ListFilter'
import SearchFilter from 'modules/common/components/_UI_SMART/SharedTable/Filters/SearchFilter'
// @common
import strings from 'constants/strings'
import { TableContext } from 'modules/common/components/_UI_SMART/SharedTable/ContextTableWrapper'

const DealsSortSearch: React.FC = (): ReactElement => {
  /*******************************************************
   * HOOKS
   *******************************************************/
  const { changeCriteria, datePickerChangeHandler, data } =
    useContext(TableContext)

  /*******************************************************
   * RENDER COMPONENTS
   *******************************************************/
  /**
   * filter types
   */
  const allFilters = {
    createdDateEndFilter: data.find((el) => el?.key === 'createdDateEnd')
      ?.values[0]?.value,
    createdDateStartFilter: data.find((el) => el?.key === 'createdDateStart')
      ?.values[0]?.value,
    stageFilters: data.find((el) => el?.key === 'stage'),
    statusFilters: data.find((el) => el?.key === 'status'),
  }

  /*******************************************************
   * RENDER
   *******************************************************/
  return (
    <>
      <SC.Wrap>
        <Grid container spacing={3} alignItems="flex-end">
          <SC.Column item>
            <ListFilter
              type={'stage'}
              filters={allFilters.stageFilters}
              changeCriteria={changeCriteria}
            />
          </SC.Column>

          <SC.Column item>
            <ListFilter
              type={'status'}
              filters={allFilters.statusFilters}
              changeCriteria={changeCriteria}
            />
          </SC.Column>

          <SC.Column item>
            <DatesFilter
              changeCriteria={changeCriteria}
              createdDateEnd={allFilters.createdDateEndFilter}
              createdDateStart={allFilters.createdDateStartFilter}
              datePickerChangeHandler={datePickerChangeHandler}
            />
          </SC.Column>

          <SC.SearchColumn item>
            <SearchFilter
              placeholder={strings.TABLE_FILTER_SEARCH}
              type="name"
              changeCriteria={changeCriteria}
            />
          </SC.SearchColumn>
        </Grid>
      </SC.Wrap>
    </>
  )
}

export default DealsSortSearch
