import { useHistory } from 'react-router-dom'
import { Auth0Provider } from '@auth0/auth0-react'

const Auth0ProviderWithHistory = ({ children }) => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN as string
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID as string

  const history = useHistory()

  const onRedirectCallback = (appState) => {
    // console.log('onRedirectCallback')
    // console.log(appState?.returnTo || window.location.pathname)
    history.push(appState?.returnTo || window.location.pathname)
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={process.env.REACT_APP_AUTH0_REDIRECT_URI as string}
      onRedirectCallback={onRedirectCallback}
      audience={process.env.REACT_APP_AUTH0_AUDIENCE as string}
      responseType={'token id_token'}
      scope={'openid email profile'}
      // cacheLocation={'localstorage'}
    >
      {children}
    </Auth0Provider>
  )
}

export default Auth0ProviderWithHistory
