// @react
import React from 'react'
// @design
import * as SC from '../styledComponents/itemsDetailsVisibilityParameters'
// @common
import strings from 'constants/strings'
import CenteredLoader from 'modules/common/components/_UI_DUMB/Loaders/CenteredLoader'
import { ItemPermission } from 'graphql/graphqlTypes'

interface PropsType {
  changeHandler: (e: React.ChangeEvent) => void
  permissions?: ItemPermission | null
  loading: boolean | undefined
  canEdit: boolean
}

/**
 *
 * @param changeHandler
 * @param permissions
 * @param loading
 * @param canEdit
 * @constructor
 */
const ItemsDetailsVisibilityParameters = ({
  changeHandler,
  permissions,
  loading,
  canEdit,
}: PropsType) => {
  /*******************************************************
   * RENDER
   *******************************************************/
  return (
    <SC.InlineBlock>
      {loading ? (
        <CenteredLoader size={20} />
      ) : (
        <SC.RadioGroup
          onChange={changeHandler}
          value={permissions}
          aria-label="visibility"
          name="visibility"
        >
          <SC.Label
            value="MY_TEAM"
            control={<SC.StyledRadio disabled={!canEdit} color="primary" />}
            label={strings.MY_TEAM}
          />
          <SC.Label
            value="BOTH_TEAMS"
            control={<SC.StyledRadio disabled={!canEdit} color="primary" />}
            label="Both Teams"
          />
        </SC.RadioGroup>
      )}
    </SC.InlineBlock>
  )
}

export default ItemsDetailsVisibilityParameters
