import { useMutation } from '@apollo/client'
import LockIcon from '@material-ui/icons/Lock'
import LockOpen from '@material-ui/icons/LockOpen'
import { DocumentNodeFragment } from 'graphql/graphqlTypes'
import { DOCUMENT_UPDATE } from 'graphql/mutations/document.mutation'
import { GET_DEAL_INFO } from 'graphql/queries/deal.query'
import { GET_DOCUMENTS } from 'graphql/queries/document.query'
import { GET_ITEM } from 'graphql/queries/item.query'
import { useEffect, useState } from 'react'
import { LockIconWrapper } from '../../styledComponents/dragAndDropArea'
interface Props {
  document: DocumentNodeFragment
  disabled?: boolean
}

/***
 *
 * @param props
 * @constructor
 */
const DocumentFinalize = (props: Props) => {
  /*******************************************************
   * STATE
   *******************************************************/
  const [document, setDocument] = useState<DocumentNodeFragment>(props.document)

  /*******************************************************
   * GQL
   *******************************************************/
  const [updateDocument] = useMutation(DOCUMENT_UPDATE, {
    refetchQueries: [GET_DOCUMENTS, GET_DEAL_INFO, GET_ITEM],
  })

  /*******************************************************
   * HOOKS
   *******************************************************/
  useEffect(() => {
    setDocument(props.document)
  }, [props.document])

  /*******************************************************
   * FUNCTIONS
   *******************************************************/
  const changeState = async (e): Promise<void> => {
    if (props.disabled) return
    //to prevent download doc on icon click
    e.preventDefault()
    await updateDocument({
      variables: { input: { finalized: !document.finalized, id: document.id } },
    })
  }

  /*******************************************************
   * RENDER
   *******************************************************/

  return (
    <LockIconWrapper onClick={changeState}>
      {document.finalized ? <LockIcon /> : <LockOpen />}
    </LockIconWrapper>
  )
}

export default DocumentFinalize
