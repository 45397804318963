import { createTheme, makeStyles } from '@material-ui/core'
import { colors } from 'constants/colors'
import { device, to } from 'modules/common/styledComponents/MediaQueries'

export const globalUseStyles = makeStyles({
  bold: {
    fontWeight: 900,
  },
})

function arrowGenerator(color: string) {
  return {
    '&[x-placement*="bottom"] $arrow': {
      top: 0,
      left: 0,
      marginTop: '-0.95em',
      width: '2em',
      height: '1em',
      '&::before': {
        borderWidth: '0 1em 1em 1em',
        borderColor: `transparent transparent ${color} transparent`,
      },
    },
    '&[x-placement*="top"] $arrow': {
      bottom: 0,
      left: 0,
      marginBottom: '-0.95em',
      width: '2em',
      height: '1em',
      '&::before': {
        borderWidth: '1em 1em 0 1em',
        borderColor: `${color} transparent transparent transparent`,
      },
    },
    '&[x-placement*="right"] $arrow': {
      left: 0,
      marginLeft: '-0.95em',
      height: '2em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 1em 1em 0',
        borderColor: `transparent ${color} transparent transparent`,
      },
    },
    '&[x-placement*="left"] $arrow': {
      right: 0,
      marginRight: '-0.95em',
      height: '2em',
      width: '1em',
      '&::before': {
        borderWidth: '1em 0 1em 1em',
        borderColor: `transparent transparent transparent ${color}`,
      },
    },
  }
}

export const matTheme = createTheme({
  palette: {
    primary: {
      main: colors.yellowishOrange,
      '50': colors.grapefruit,
      '100': '#0000008a',
      light: colors.white,
      contrastText: colors.white,
    },
    secondary: {
      // light: '#0066ff',
      main: colors.medGrey,
      '50': colors.grapefruit,
      '100': '#0000008a',
      light: colors.white,
      contrastText: colors.battleshipGrey,
    },
    error: {
      main: colors.grapefruit,
      light: colors.grapefruitLight,
      contrastText: colors.white,
    },
    background: {
      paper: colors.white,
      default: colors.dark,
    },
  },
  typography: {
    fontFamily: [
      'Futura',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
    ].join(','),
    h1: {
      fontSize: '50px',
      color: colors.white,
      fontWeight: 500,
    },
    h2: {
      fontSize: '25px',
      fontFamily: 'Open Sans',
      color: colors.white,
    },
    h3: {
      fontSize: '20px',
      color: colors.dark,
      fontFamily: 'Open Sans',
    },
    h4: {
      fontSize: '16px',
      color: colors.battleshipGrey,
      fontFamily: 'Open Sans',
    },
    h6: {
      fontSize: '20px',
      fontFamily: 'Open Sans',
    },
    subtitle1: {
      color: colors.dark,
      fontFamily: 'Open Sans',
      fontSize: '14px',
      fontWeight: 600,
    },
    body1: {
      color: colors.battleshipGrey,
      fontFamily: 'Open Sans',
      fontSize: '14px',
      fontWeight: 300,
    },
    body2: {
      fontSize: '16px',
      color: '#0000008a',
      fontFamily: 'Open Sans',
    },
    subtitle2: {
      color: colors.paleGrey,
      fontFamily: 'Open Sans',
      fontSize: '12px',
      fontWeight: 600,
    },
  },
  overrides: {
    MuiCheckbox: {
      root: {
        // marginLeft: '-11px',
      },
    },
    MuiFormControlLabel: {
      root: {
        // marginLeft: 0,
      },
      label: {
        color: colors.battleshipGrey,
      },
    },
    MuiTypography: {
      colorSecondary: {
        color: colors.dark,
      },
    },
    MuiButton: {
      root: {
        fontFamily: 'Open Sans',
        borderRadius: '4px',
        fontWeight: 600,
        whiteSpace: 'nowrap',
        boxShadow: 'none !important',

        color: colors.yellowishOrange,
        minHeight: '35px',
        fontSize: '14px',
        '&$disabled': {
          color: colors.lightGreyBlue,
          backgroundColor: `${colors.veryLightBlue} !important`,
          pointerEvents: 'all',
          '&:hover': {
            cursor: 'not-allowed',
          },
        },
      },
      contained: {
        color: colors.white,
        backgroundColor: colors.battleshipGrey,
      },
      containedPrimary: {
        // color: colors.white,
      },
      containedSecondary: {
        // color: colors.white,
      },
      sizeLarge: {
        fontSize: '18px',
        padding: '5px 50px',
        minHeight: '50px',
      },
    },
    MuiChip: {
      root: {
        fontFamily: 'Open Sans',
      },
    },
    MuiFab: {
      root: {
        boxShadow: 'none',
        '&$disabled': {
          pointerEvents: 'all',
          '&:hover': {
            cursor: 'not-allowed',
          },
        },
      },
    },
    MuiCard: {
      root: {
        borderRadius: '5.5px',
        backgroundColor: colors.white,
      },
    },
    MuiSelect: {
      selectMenu: {
        height: 'unset',
      },
      select: {
        '& .MuiTypography-body1': {
          color: colors.dark,
        },
        '&:focus': {
          backgroundColor: 'transparent',
        },
      },
      icon: {
        color: colors.dark,
      },
    },
    MuiInputLabel: {
      root: {
        fontFamily: 'Open Sans',
        color: colors.dark,
        '&$focused': {
          color: colors.battleshipGrey,
        },
      },
      formControl: {
        transform: 'translateY(1.7rem)',
      },
      shrink: {
        color: colors.battleshipGrey,
        transform: 'translate(0, 0.3rem) scale(0.85)',
      },
    },
    MuiInputBase: {
      root: {
        fontSize: '14px',
      },
    },
    MuiSvgIcon: {
      root: {
        '&$disabled': {
          '&:hover': {
            pointerEvents: 'all',
            cursor: 'not-allowed',
          },
        },
      },
    },
    MuiInput: {
      root: {
        color: colors.dark,
        font: 'caption',
        '&$disabled': {
          fontStyle: 'italic',
          opacity: 0.5,
          '&:hover': {
            pointerEvents: 'all',
            cursor: 'not-allowed',
          },
        },
      },
      input: {
        padding: '9px 0 10px',
        fontSize: '14px',
        fontFamily: 'Open Sans',
        lineHeight: '1.2',
        color: colors.dark,
      },
      multiline: {
        padding: 0,
      },
      underline: {
        '&:after': {
          transform: 'scaleX(1)',
          opacity: 0,
          transition: '.05s',
          borderBottom: `1px solid ${colors.yellowishOrange}`,
        },
        '&:before': {
          display: 'none',
        },
        transition: '0.2s',
        borderBottom: `1px solid ${colors.dark}`,
        '&$focused': {
          borderBottomColor: colors.yellowishOrange,
        },
      },
    },
    MuiFormLabel: {
      root: {
        fontSize: 14,
      },
    },
    MuiFormHelperText: {
      root: {
        fontSize: '11px',
        color: colors.battleshipGrey,
        fontWeight: 300,
        fontFamily: 'Open Sans',
      },
    },
    MuiTable: {
      root: {
        backgroundColor: colors.white,
      },
    },
    MuiTableSortLabel: {
      root: {
        '&:hover': {
          color: 'currentColor',
          '& $icon': {
            opacity: 1,
          },
        },
      },
      active: {
        '& $icon': {
          color: 'currentColor !important',
        },
      },
      icon: {
        marginLeft: '8px',
      },
    },
    MuiTableRow: {
      root: {
        border: `1px solid ${colors.veryLightBlue}`,
        borderBottom: 'none',
        transition: '.1s',
        cursor: 'pointer',
        '&:last-child': {
          borderBottom: `1px solid ${colors.veryLightBlue}`,
        },
        '&:hover': {
          backgroundColor: colors.paleGrey,
        },
      },
    },
    MuiTableCell: {
      root: {
        border: 'none',
        padding: '24px 30px',
        color: colors.battleshipGrey,
        fontFamily: 'Open Sans',
        fontSize: '14px',
        [to(device.tablet)]: {
          padding: '14px 20px',
        },
        [to(device.mobile)]: {
          padding: '10px 16px',
        },
      },
      head: {
        color: colors.dark,
        '& .MuiTypography-root': {
          color: 'currentColor',
          fontWeight: 600,
          fontSize: '1em',
        },
      },
    },
    MuiDialogTitle: {
      root: {
        textAlign: 'center',
        position: 'sticky',
        padding: '20px',
        marginBottom: '20px',
        background: colors.white,
        zIndex: 100,
        borderBottom: `1px solid ${colors.paleGrey}`,
        top: 0,
        [to(device.tablet)]: {
          padding: '20px 16px',
        },
        [to(device.mobile)]: {
          padding: '24px 16px',
        },
      },
    },
    MuiDialogContent: {
      root: {
        padding: '0 40px',
        overflowY: 'initial',
        [to(device.tablet)]: {
          padding: '0 24px',
        },
        [to(device.mobile)]: {
          padding: '0 16px',
        },
      },
    },
    MuiDialogActions: {
      root: {
        alignItems: 'center',
        justifyContent: 'center',
        padding: '20px 42px 20px',
        position: 'sticky',
        bottom: 0,
        width: '100%',
        background: '#fff',
        borderTop: `1px solid ${colors.paleGrey}`,
        [to(device.tablet)]: {
          padding: '16px 24px 24px',
        },
        [to(device.mobile)]: {
          padding: '10px 16px 16px',
        },
      },
    },
    MuiFormControl: {
      root: {
        display: 'flex',
      },
    },
    MuiStepper: {
      root: {
        [to(device.tablet)]: {
          padding: '24px 0',
        },
        [to(device.mobileL)]: {
          padding: '24px 0 10px',
        },
      },
    },
    MuiStep: {
      horizontal: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    MuiStepLabel: {
      root: {
        color: colors.battleshipGrey,
      },
      label: {
        '&:hover': {
          color: colors.aquaMarine,
        },
        '&$active': {
          color: colors.battleshipGrey,
        },
        '&$completed': {
          color: colors.battleshipGrey,
        },
        [to(device.mobileL)]: {
          display: 'none',
        },
      },
      iconContainer: {
        marginTop: -14,
        position: 'relative',
        zIndex: 1,
      },
    },
    MuiTooltip: {
      popper: arrowGenerator(colors.grapefruit),
      tooltip: {
        fontFamily: 'Open Sans',
        fontSize: '0.75em',
        color: colors.grapefruit,
        backgroundColor: colors.veryLightPink,
        borderColor: colors.grapefruit,
        borderStyle: 'solid',
        borderWidth: '1px',
        textAlign: 'center',
      },
    },
    MuiStepIcon: {
      root: {
        padding: 14,
        boxSizing: 'content-box',
        transition: 'background-color 0.3s',
        borderRadius: '50%',
        color: colors.medGrey,
        '&$active': {
          color: colors.aquaMarine,
        },
        '&$completed': {
          color: colors.aquaMarine,
        },
      },
      text: {
        display: 'none',
      },
      active: {
        backgroundColor: colors.aquaMarineLight,
      },
      completed: {
        backgroundColor: colors.aquaMarine,
        padding: 0,
        margin: 14,
        '&& path': {
          display: 'none',
        },
      },
    },
    MuiStepConnector: {
      alternativeLabel: {
        left: 'calc(-50% + 12px)',
        right: 'calc(50% + 12px)',
      },
      completed: {
        '&& $line': {
          borderColor: colors.aquaMarine,
        },
      },
      active: {
        '&& $line': {
          borderColor: colors.aquaMarine,
        },
      },
    },
    MuiDialog: {
      scrollPaper: {
        alignItems: 'flex-start',
      },
      paper: {
        [to(device.tablet)]: {
          margin: 24,
        },
        [to(device.mobile)]: {
          margin: 16,
        },
      },
      paperScrollPaper: {
        [to(device.tablet)]: {
          maxHeight: 'calc(100% - 48px)',
        },
        [to(device.mobile)]: {
          maxHeight: 'calc(100% - 32px)',
        },
      },
    },
    MuiTabs: {
      root: {
        backgroundColor: colors.white,
      },
      indicator: {
        height: '3px',
      },
      scroller: {
        padding: '7px 0',
      },
    },
    MuiTab: {
      wrapper: {
        'flex-direction': 'row-reverse',
        padding: '0 10px',
      },
      labelIcon: {
        minHeight: 58,
      },
      root: {
        textTransform: 'none',
        fontFamily: 'Open Sans',
        fontWeight: 600,
        '&$selected': {
          '&& $label': {
            fontWeight: 600,
          },
        },
        minHeight: 58,
        '&$disabled': {
          pointerEvents: 'all',
          '&:hover': {
            cursor: 'not-allowed',
          },
        },
      },
      textColorPrimary: {
        color: colors.dark,
      },
    },
    MuiRadio: {
      root: {
        padding: 6,
      },
    },
    MuiMenu: {
      paper: {
        marginTop: 0,
      },
    },
    MuiMenuItem: {
      root: {
        color: colors.dark,
        '&:hover': {
          backgroundColor: colors.darkenBlue,
        },
      },
    },
    MuiListItemText: {
      root: {
        marginTop: 0,
        marginBottom: 0,
      },
      primary: {
        color: colors.dark,
      },
    },
    MuiSnackbar: {
      root: {},
    },
    MuiSnackbarContent: {
      root: {
        fontFamily: 'Open Sans',
        color: 'white',
      },
    },
    MuiLinearProgress: {
      // @todo this is not working
      root: {
        height: '10px',
        marginTop: '10px',
        marginBottom: '10px',
        '&:before': {
          content: '',
          position: 'absolute',
          width: '100%',
          height: '100%',
          borderRadius: '5px',
          background:
            'linear-gradient(45deg,transparent 25%,rgba(238, 238, 238, 0.5) 25%,rgba(238, 238, 238, 0.5) 30%,transparent 30%,transparent 35%,rgba(238, 238, 238, 0.5) 35%,rgba(238, 238, 238, 0.5) 70%,transparent 70%)',
          animation: 'shift 2s linear infinite',
          backgroundSize: '60px 100%',
          boxShadow:
            'inset 0 0px 1px rgba(0, 0, 0, 0.2), inset 0 -2px 1px rgba(0, 0, 0, 0.2)',
        },
      },
      bar: {},
      barColorPrimary: {},
    },
  },
})
