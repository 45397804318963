// @react
import React from 'react'

const PropertySingle: React.FunctionComponent = () => (
  <svg
    className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium MuiBox-root"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 37 37"
    fill="none"
  >
    <rect width="37" height="37" rx="5" fill="#3BA2D9" />
    <path
      d="M23.5 17.5v-8h-10v18h4v-4h2v4h4v-10Zm-6 4h-2v-2h2v2Zm0-4h-2v-2h2v2Zm0-4h-2v-2h2v2Zm4 8h-2v-2h2v2Zm0-4h-2v-2h2v2Zm0-4h-2v-2h2v2Z"
      fill="#fff"
    />
  </svg>
)

export default PropertySingle
