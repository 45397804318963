import { Button, DialogActions, DialogContent } from '@material-ui/core'
import styled from 'styled-components'
import * as SC from 'modules/user/styledComponents/profile'
import { device, from } from 'modules/common/styledComponents/MediaQueries'
import LinearProgress from '@material-ui/core/LinearProgress'
import colors from 'constants/colors'

export const style = () => ({
  paper: {
    width: 730,
    borderRadius: 5,
    boxShadow: '0 0 3px 2px rgba(0, 0, 0, 0.15)',
  },
})

export const Actions = styled(DialogActions)`
  && {
    margin-top: 1em;
    flex-direction: column;
    justify-content: center;
    border-top: none;
  }
`

export const StyledDialogContent = styled(DialogContent)`
  && {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
  }
`

export const HiddenInput = styled.input`
  display: none;
`

export const StyledAvatar = styled(SC.StyledAvatar)`
  && {
    margin-right: 0;
    background-color: ${colors.veryLightBlue};
    border: 1px dashed ${colors.lightGreyBlue}
    width: 200px;
    height: 200px;

    ${from(device.mobileL)} {
      margin-right: 0;
    }
  }
`

export const CenteredFlex = styled.div`
  width: 326px;
  display: flex;
  justify-content: space-between;
`

export const LinearProgressWrapper = styled(LinearProgress)`
  width: 60%;
  margin: 10px 0;
`

/*******************************************************
 * BUTTONS
 *******************************************************/
export const SelectButtonGroup = styled.div`
  && {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
  && button {
    margin-top: 10px;
  }
`
export const CloseButton = styled(Button)`
  && {
    margin-top: 20px;
    margin-left: 0px !important;
    margin-bottom: 10px;
    text-decoration: underline;
    text-transform: none;
  }
`
export const SelectButton = styled(Button)`
  && {
    text-transform: none;
  }
`
export const RemoveButton = styled(Button)`
  && {
    text-transform: none;
    background-color: ${colors.lightGreyBlue};
  }
`

export const UpdateButton = styled(Button)`
  && {
    width: 160px;
  }
`

export const ImportSocialsAvatar = styled(Button)`
  && {
    text-decoration: underline;
    margin-bottom: 4px;
    font-size: 14px;
    font-family: Open Sans;
    font-weight: 600;

    &: hover {
      background: none;
    }
  }
`
