import { Avatar } from '@material-ui/core'
import styled from 'styled-components'
// @common
// @constant
import { colors } from 'constants/colors'
import { ContentContainer } from 'modules/common/styledComponents/Global'

export const Container = styled(ContentContainer)`

   .ql-mention-list-container {
      z-index: 10;
     border-radius: 4px;
     background: ${colors.white};
    box-shadow: -2px 2px 4px -2px ${colors.lightGreyBlue}, 2px 2px 4px -2px ${
  colors.lightGreyBlue
}, 0 -2px 4px -2px ${colors.lightGreyBlue};
   }
  .ql-mention-list {
    padding: 0px;
    overflow: initial;
    margin-top: 0.8em;
    background: ${colors.white};
    display: block;
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    align-content: space-between;
    flex-direction: column;
  }
  .ql-mention-list-item {
    &:hover {
      color: ${colors.white} !important
    }
    & .name{
      display: inline-block;
      margin-left: 10px;
      font-size: 14px
    }

    &:hover {
      background-color: ${colors.yellowishOrange} !important
    }

    .item {
        display: flex;
        flex-direction: row;
        align-items: center;
        &:link {
          text-decoration: none;
        }
        img {
          width: 30px;
          height: 30px;
          object-fit: cover;
          border-radius: 50%;
        }
    }
    padding: 5px 9px;
  }

.ql-mention-list-item:hover {
    background-color: #e5e9ed;
    cursor: pointer;
}
  span.mention {
  color: ${colors.yellowishOrange}
  font-weight: bold
  }

  && {
    border-top: 1px solid ${colors.veryLightBlue};
    ${(props) =>
      `
          background-color:  ${
            props.isWhiteBackground ? colors.white : colors.paleGrey
          };
      `}
    display: flex;
    align-items: flex-start;
  }
`

export const Wrap = styled.div`
  position: relative;
  flex: 1;
  border: 1px solid ${colors.lightGreyBlue};
  ${(props) =>
    props.isFocused &&
    `
    border: 1px solid ${colors.yellowishOrange};
`}

  background: ${colors.white};
  border-radius: 4px;
`

export const Actions = styled.div`
  bottom: 1px;
  left: 1px;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: calc(100% - 8px);
  margin-left: 4px;
  padding: 15px 11px;
  margin-bottom: 3px;
  border-radius: 4px;
`

export const Textarea = styled.div`
  font-size: 14px;
  word-break: break-word;
  color: ${colors.dark};

  && {
    border: 0;
  }

  .ql-editor {
    border: none;
    min-height: 30px;
    max-height: 250px;
    padding-top: 10px;

    &::-webkit-scrollbar {
      width: 0;
      background: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background: transparent;
    }
  }
`

export const StyledAvatar = styled(Avatar)`
  && {
    width: 50px;
    height: 50px;
    margin-right: 21px;
  }
`
