// language=GraphQL
import { gql } from '@apollo/client'
import { SIMPLIFIED_USER } from 'graphql/fragments/user.fragment'

export const USER_INVITED_TO_CONTACTS = gql`
  subscription onUserInvitedToContacts {
    userInvitedToContacts {
      ...SimplifiedUserNode
    }
  }
  ${SIMPLIFIED_USER}
`

export const USER_ACCEPTED_INVITATION_TO_CONTACTS = gql`
  subscription onUserAcceptedInvitationToContacts {
    userAcceptedInvitationToContacts {
      ...SimplifiedUserNode
    }
  }
  ${SIMPLIFIED_USER}
`

export const USER_DECLINED_INVITATION_TO_CONTACTS = gql`
  subscription onUserDeclinedInvitationToContacts {
    userDeclinedInvitationToContacts {
      id
    }
  }
`

export const USER_CANCELED_INVITATION_TO_CONTACTS = gql`
  subscription onUserCanceledInvitationToContacts {
    userCanceledInvitationToContacts {
      id
    }
  }
`

export const USER_REMOVED_FROM_CONTACTS = gql`
  subscription onUserRemovedFromContacts {
    userRemovedFromContacts {
      id
    }
  }
`
