// @design
import { Grid, useMediaQuery, useTheme } from '@material-ui/core'
import strings from 'constants/strings'
import { Container, Title, Section } from './styledComponents'
// @components
import CurrencySelector from './CurrencySelector'
import ShowBySelector from './ShowBySelector'
import ChartFormatSelect from './ChartFormatSelect'

/**
 *
 * @constructor
 */
const GlobalControls = () => {
  /*******************************************************
   * HOOKS
   *******************************************************/
  const theme = useTheme()
  const isUpMd = useMediaQuery(theme.breakpoints.up('md'))

  /*******************************************************
   * RENDER
   *******************************************************/
  return (
    <Container mt={4}>
      <Grid
        container
        justifyContent="space-between"
        className="control-grid-wrap"
      >
        <Section md={3} sm={12} item withrightborder={String(isUpMd)}>
          <Title>{strings.PL_DISPLAYED_CURRENCY}</Title>
          <div>
            <CurrencySelector />
          </div>
        </Section>

        <Section md={5} sm={12} item withrightborder={String(isUpMd)}>
          <Title>{strings.PL_SHOW_BY}</Title>
          <div>
            <ShowBySelector />
          </div>
        </Section>
        <Section md={4} sm={12} item className="control-chart-format">
          <Title>{strings.PL_CHART_FORMAT}</Title>
          <div>
            <ChartFormatSelect />
          </div>
        </Section>
      </Grid>
    </Container>
  )
}

export default GlobalControls
