// @react
import { useContext, useState } from 'react'
// @components
import ManageThirdParty from 'modules/item/components/ManageThirdParty'
import { Team } from 'modules/common/types'
import { ContactsWrapper } from './styledComponents'
import * as ButtonsSC from 'modules/common/components/_UI_DUMB/Button/styledComponents'

// @graphql
import { Item, GetItemQuery, User } from 'graphql/graphqlTypes'
import { ApolloQueryResult } from '@apollo/client'
import { UserContext } from 'modules/user/context/user'
import { DealContext } from 'modules/deal/contexts/deal'
import DealTeamThirdPartyContactsList from 'modules/deal/components/UserRelationsManage/DealTeamThirdPartyContactsList'

/*******************************************************
 * CONSTANTS
 *******************************************************/

/*******************************************************
 * TYPES
 *******************************************************/
type PropTypes = {
  item: Item
  isAllowedToEdit: boolean
  onUpdate: () => Promise<ApolloQueryResult<GetItemQuery>>
}

/**
 *
 * @param props
 * @constructor
 */
const ThirdParty = ({ item, isAllowedToEdit, onUpdate }: PropTypes) => {
  /*******************************************************
   * CONTEXT
   *******************************************************/
  const currentUser = useContext(UserContext) as { user: User }
  const queryResult = useContext(DealContext)
  const { dealId } = queryResult

  /*******************************************************
   * STATE
   *******************************************************/
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)

  /*******************************************************
   * FUNCTIONS
   *******************************************************/

  const isCurrentUserInOwnerTeam = item.team.teamMembership?.some(
    (tm) => tm.userId === currentUser?.user.id,
  )

  /**
   *
   * @param user
   */
  const getRelativeTeamItemOwnership = (user) => {
    const isUserInOwnerTeam = item.team.teamMembership?.some(
      (tm) => tm.userId === user.id,
    )
    return isCurrentUserInOwnerTeam === isUserInOwnerTeam
      ? Team.MY_TEAM
      : Team.THEIR_TEAM
  }

  /*******************************************************
   * RENDER
   *******************************************************/
  if (!item || !dealId) {
    return null
  }

  const thirdPartyListButton = (
    <ButtonsSC.ButtonOrange
      variant="contained"
      onClick={() => setIsDialogOpen(true)}
      fullWidth={false}
      size="small"
      color="primary"
      disabled={!isAllowedToEdit}
    >
      Manage third parties
    </ButtonsSC.ButtonOrange>
  )

  return (
    <>
      <ContactsWrapper>
        <DealTeamThirdPartyContactsList
          userContactsData={item.users}
          item={item}
          refetch={onUpdate}
          disableActions={true}
        />
      </ContactsWrapper>
      <div style={{ padding: 15 }}>{thirdPartyListButton}</div>
      <ManageThirdParty
        isDialogOpen={isDialogOpen}
        setIsDialogOpen={setIsDialogOpen}
        isAllowedToEdit={isAllowedToEdit}
        selectedContacts={item.users}
        dealId={dealId}
        item={item}
      />
    </>
  )
}

export default ThirdParty
