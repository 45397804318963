// @react
import React from 'react'
import * as SC from './styledComponents/dueDateTag'
import { parseISO, format, isAfter } from 'date-fns'
import config from 'constants/config'
import '@material-ui/core/'

interface Props {
  date: string | null | undefined
  closed: boolean
}

const DueDateTag = (props: Props) => {
  const { date, closed } = props
  if (!date) return null
  const parsedDate = parseISO(date)
  const isOverdue = isAfter(new Date(), parsedDate)

  return date ? (
    <SC.Wrapper>
      <SC.StyledTag isOverdue={isOverdue} {...props}>
        {format(parsedDate, config.dateMonthFormat)}{' '}
        {isOverdue && !closed && <SC.StyledAccessTime />}
      </SC.StyledTag>
    </SC.Wrapper>
  ) : null
}

export default DueDateTag
