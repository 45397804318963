// @react
import React from 'react'
import { FC, useState } from 'react'
import * as SC from './styledComponents'
import { InputLabel, Theme, useTheme } from '@material-ui/core'
import { MenuProps } from '@material-ui/core/Menu'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
/*******************************************************
 * TYPES
 *******************************************************/
type Value = string | number

interface PropsType {
  value?: Value | Value[]
  label: string
  renderValue?: () => string | React.ReactNode
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void
  onOpen?: (evt) => void
  onClose?: (evt) => void
  multiple?: boolean
  className?: string
  MenuProps?: MenuProps
}

/**
 *
 * @param props
 * @constructor
 */
const FreeSelect: FC<PropsType> = ({
  label,
  multiple,
  renderValue,
  value,
  onChange,
  className,
  children,
  onOpen,
  onClose,
}) => {
  const theme: Theme = useTheme()
  /*******************************************************
   * STATE
   *******************************************************/
  const [isOpen, setIsOpen] = useState<boolean>(false)

  /*******************************************************
   * FUNCTIONS
   *******************************************************/
  const toggle = (open: boolean) => {
    return (evt) => {
      setIsOpen(open)
      open ? onOpen && onOpen(evt) : onClose && onClose(evt)
    }
  }

  /*******************************************************
   * RENDER
   *******************************************************/
  return (
    <SC.StyledFormControl className={className}>
      {label && <InputLabel>{label}</InputLabel>}
      <SC.StyledSelect
        MenuProps={{
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'left',
          },
          PaperProps: {
            style: {
              minWidth: 200,
            },
          },
        }}
        multiple={multiple}
        renderValue={renderValue}
        open={isOpen}
        value={value}
        onChange={onChange}
        onOpen={toggle(true)}
        onClose={toggle(false)}
        IconComponent={KeyboardArrowDownIcon}
        input={<SC.StyledInput theme={theme} />}
      >
        {children}
      </SC.StyledSelect>
    </SC.StyledFormControl>
  )
}

export default FreeSelect
