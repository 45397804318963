import { colors } from 'constants/colors'
import styled from 'styled-components'
import { ButtonOrange } from '../../Button/styledComponents'

/*******************************************************
 * Cards
 *******************************************************/
export const Card = styled.div`
  background-color: ${colors.white};
  border-radius: 4px;
`

export const CardHeader = styled.div`
  padding: 20px;
  border-bottom: 1px solid ${colors.paleGrey};
  font-size: 16px;
  font-weight: 600;
  color: ${colors.dark};
`

export const CardBody = styled.div`
  padding: 20px;
  font-size: ${(props) => props.bodyTextSize || '16px'};
  color: ${colors.dark};

  ${(props) =>
    props.noPadding &&
    `padding: 0px;
  `}
`

/*******************************************************
 * OTHER
 *******************************************************/
export const OrangeButtonHoverAuto = styled(ButtonOrange)`
  && {
    &:hover {
      cursor: auto !important;
    }
  }
`

export const Footer = styled.div`
  padding: 20px;
`
