// language=GraphQL
import { gql } from '@apollo/client'
import {
  CONTACT_ITEM,
  BASE_SIMPLIFIED_USER,
  SIMPLIFIED_USER,
  USER_ITEM,
  USER_DEAL,
} from '../fragments/user.fragment'

export const GET_USER_CONTACTS = gql`
  query GetUserContacts(
    $filters: UserContactsFilterInput
    $contactStatus: ContactStatus!
    $page: Int
  ) {
    userContacts(
      filters: $filters
      contactStatus: $contactStatus
      page: $page
    ) {
      ...ContactItem
    }
  }
  ${CONTACT_ITEM}
`

export const GET_USERS = gql`
  query GetUsers($filters: UsersFilterInput, $page: Int) {
    users(filters: $filters, page: $page) {
      data {
        ...SimplifiedUserNode
      }
      meta {
        page
        totalPages
        pageSize
      }
    }
  }
  ${SIMPLIFIED_USER}
`

export const GET_USER_INFO = gql`
  query GetUserInfo {
    user {
      ...UserNode
      notificationsEmailPref
      notificationsPriorityPref
    }
  }
  ${USER_ITEM}
`

export const GET_USER_INFO_BY_ID = gql`
  query GetUserInfoById($id: ID) {
    user(id: $id) {
      id
      avatar {
        id
        file {
          id
          location
          versionId
        }
      }
      city
      company
      country
      email
      firstName
      lastName
      state
      title
      username
      provider
    }
  }
`

export const GET_DEAL_CONTACTS = gql`
  query GetUserDealContacts(
    $filters: UsersFilterInput
    $orderBy: UsersOrderByInput
    $dealId: ID!
    $page: Int
  ) {
    userDeal(
      filters: $filters
      orderBy: $orderBy
      dealId: $dealId
      page: $page
    ) {
      data {
        ...SimplifiedUserNode
        ...UserDeal
      }
      meta {
        count
        page
        totalPages
        pageSize
      }
    }
  }
  ${SIMPLIFIED_USER}
  ${USER_DEAL}
`

export const GET_THIRDPARTY_USERS = gql`
  query GetThirdpartyUsers(
    $filters: UsersFilterInput
    $dealId: ID!
    $orderBy: UsersOrderByInput
    $page: Int
  ) {
    thirdPartyUsers(
      filters: $filters
      dealId: $dealId
      orderBy: $orderBy
      page: $page
    ) {
      meta {
        count
        totalPages
        page
      }
      data {
        ...BaseSimplifiedUserNode
        userDeal {
          status
          teamRole
          teamId
          teamType
          inviteId
          inviteType
        }
      }
    }
  }
  ${BASE_SIMPLIFIED_USER}
`

export const GET_ITEM_USERS = gql`
  query GetItemUsers($itemId: ID!) {
    userItem(itemId: $itemId) {
      meta {
        count
      }
      data {
        ...BaseSimplifiedUserNode
      }
    }
  }
  ${BASE_SIMPLIFIED_USER}
`

export const GET_NETWORK_SUMMARY = gql`
  query GetNetworkSummary {
    userContactsPending: userContacts(contactStatus: PENDING) {
      meta {
        count
      }
    }
    userContactsInvited: userContacts(contactStatus: INVITED) {
      meta {
        count
      }
    }
    userContactsAccepted: userContacts(contactStatus: ACCEPTED) {
      meta {
        count
      }
    }
  }
`
