import { Menu, MenuItem } from '@material-ui/core'
import { Currency } from 'graphql/graphqlTypes'
import { PipelineContext } from 'modules/pipeline/contexts/pipeline'
import { useContext, useState } from 'react'
import { CurrencyButton } from './styledComponents'

/**
 *
 * @constructor
 */
const CurrencySelector = () => {
  /*******************************************************
   * STATE
   *******************************************************/
  const [anchorEl, setAnchorEl] = useState(null)

  const { state, dispatch } = useContext(PipelineContext)

  /*******************************************************
   * FUNCTIONS
   *******************************************************/
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const onSelect = (currency) => {
    dispatch({ type: 'setCurrency', payload: { currency } }, state)
    handleClose()
  }

  /*******************************************************
   * RENDER
   *******************************************************/
  return (
    <>
      <CurrencyButton onClick={handleClick} variant="contained" color="primary">
        {state.currency}
      </CurrencyButton>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
        keepMounted
      >
        {Object.keys(Currency).map((key) => (
          <MenuItem key={key} onClick={() => onSelect(Currency[key])}>
            {key}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default CurrencySelector
