// @react
import { useContext, useEffect } from 'react'
import { useRouteMatch } from 'react-router'
// @common
import strings from 'constants/strings'
// @components
import ItemsSearch from 'modules/item/components/ItemsSearch'
import * as SC from 'modules/item/pages/ItemsList/styledComponents'
import * as SCGLobal from 'modules/common/styledComponents/Global'
import Table from 'modules/common/components/_UI_SMART/SharedTable/TableWrapper/TableWrapper'
import AttachFileIcon from '@material-ui/icons/AttachFile'
import NotFound from 'modules/common/pages/NotFound'
import { DealContext } from 'modules/deal/contexts/deal'
import {
  TableCell,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core'
import GlobalHelperModel from 'modules/common/models/globalHelper'
import DueDateTag from 'modules/item/components/common/DueDateTag'
import StatusTag from 'modules/item/components/common/StatusTag'
import SmallTableIcon from 'modules/common/components/_UI_DUMB/Icons/SmallTableIcon'
import {
  StarBorderIcon,
  StarIcon,
} from 'modules/item/components/StarredButton/styledComponents'
import CenteredLoader from '../../_UI_DUMB/Loaders/CenteredLoader'
import { ErrorText } from 'modules/deal/components/EmailFieldsList/styledComponents/emailFieldList'
import { SnackbarTypes } from '../../_UI_DUMB/Snackbars/Snackbar'
// @graphql
import { useQuery } from '@apollo/client/react/hooks/useQuery'
import { GET_ITEMS } from 'graphql/queries/item.query'
import {
  DealsFilterInput,
  DealStage,
  GetItemsQuery,
  GetItemsQueryVariables,
  Item,
  ItemsFilterInput,
  ItemsOrderByList,
  Order,
  Scalars,
} from 'graphql/graphqlTypes'
import { SnackbarContext } from '../../../context/snackbar'
import Deal from 'modules/deal/pages/DealSummary'
import { ApolloQueryResult } from '@apollo/client'
import ContextTableWrapper from '../SharedTable/ContextTableWrapper'

/*******************************************************
 * Types
 *******************************************************/
interface Props {
  dealId?: Scalars['ID']
  showFilters?: boolean
  handleClick?: (...args) => void
  dealStage?: DealStage
}

/**
 *
 * @param props
 * @constructor
 */
const ItemsList = ({ dealId, handleClick, showFilters, dealStage }: Props) => {
  /*******************************************************
   * CONST
   *******************************************************/
  const showDealFilter = !dealId

  /*******************************************************
   * HOOKS
   *******************************************************/
  const queryResult = useContext(DealContext)
  const snackbar = useContext(SnackbarContext)
  const match = useRouteMatch()
  const theme = useTheme()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(1440))

  /*******************************************************
   * GRAPHQL
   *******************************************************/
  const newQueryResult = useQuery<GetItemsQuery, GetItemsQueryVariables>(
    GET_ITEMS,
    {
      variables: {
        orderBy: {
          order: Order.DESC,
          value: ItemsOrderByList.NAME,
        },
        filters: {
          deal: dealId ? [dealId] : null,
          ...(dealStage ? { stage: [dealStage] } : {}),
        },
      },
      fetchPolicy: 'cache-first',
    },
  )
  const { data, error, fetchMore, loading, refetch, variables } = newQueryResult

  /**
   *
   * @param variables
   */
  const dealRefetch = (variables: {
    filters?: DealsFilterInput | ItemsFilterInput
  }): Promise<ApolloQueryResult<GetItemsQuery>> => {
    const filters = (variables.filters || { deal: Deal }) as ItemsFilterInput

    filters.deal = filters.deal ? filters.deal.map((it) => it) : null

    return refetch({ ...variables, filters })
  }

  /*******************************************************
   * HOOKS
   *******************************************************/
  useEffect(() => {
    if (error && error[0]?.message) {
      snackbar.setMessage({
        type: SnackbarTypes.ERROR,
        message: error[0]?.message,
        show: true,
      })
    }
  }, [error])

  /*******************************************************
   * RENDER
   *******************************************************/
  if (dealId && !queryResult.data && !queryResult.loading) {
    return <NotFound />
  }

  /**
   * tableData components
   */
  const tableData = () => {
    if (error) {
      return <></>
    }

    if (loading && !data) {
      return <CenteredLoader />
    }
    const itemsData = data?.items.data
    if (itemsData) {
      const items = itemsData as Item[]

      if (!items || (items && items.length === 0)) {
        return <ErrorText errorText={strings.NO_ITEMS} />
      }
      return items.map((el: Item) => (
        <TableRow
          key={el.id}
          component="div"
          onClick={() =>
            handleClick && handleClick(Number(el.id), Number(el.deal.id))
          }
        >
          {gridColumns.map((columns) => columns.cell(el))}
        </TableRow>
      ))
    }
    return []
  }

  const gridColumns = [
    {
      header: {
        component: <SmallTableIcon icon={<StarBorderIcon />} />,
        value: ItemsOrderByList.STARRED,
      },
      cell: (el) => (
        <TableCell key="starred" component="div">
          {el.starred ? (
            <SmallTableIcon
              testId="icon-star"
              icon={<StarIcon color="primary" />}
            />
          ) : null}
        </TableCell>
      ),
    },
    {
      header: { name: 'Name', value: ItemsOrderByList.NAME },
      cell: (el) => (
        <TableCell key="name" component="div">
          <Typography variant="subtitle1" className="underline">
            {el.name}
          </Typography>
        </TableCell>
      ),
    },
    {
      header: { name: 'Deal', value: ItemsOrderByList.DEAL },
      cell: (el) => (
        <TableCell key="dealName" component="div">
          {GlobalHelperModel.normalizeWords(el.deal.name)}
        </TableCell>
      ),
      hide:
        match.path === '/deals/details/:id/items' ||
        match.path === '/deals/details/:id/stage-view',
    },
    {
      header: { name: 'Stage', value: ItemsOrderByList.STAGE },
      cell: (el) => (
        <TableCell key="stage" component="div">
          {GlobalHelperModel.normalizeWords(el.stage)}
        </TableCell>
      ),
    },
    {
      header: { name: 'Status', value: ItemsOrderByList.STATUS },
      cell: (el) => (
        <TableCell key="status" component="div">
          <StatusTag status={GlobalHelperModel.normalizeWords(el.status)} />
        </TableCell>
      ),
    },
    {
      header: { name: 'Due Date', value: ItemsOrderByList.DUE_DATE },
      cell: (el) => (
        <TableCell key="dueDate" component="div">
          <DueDateTag date={el.dueDate} closed={el.status === 'CLOSED'} />
        </TableCell>
      ),
    },
    {
      header: { name: '' },
      hide: isSmallScreen,
      cell: (el) => (
        <TableCell key="documents" component="div">
          {el?.documents?.length ? (
            <SmallTableIcon
              testId="icon-attachment"
              icon={<AttachFileIcon />}
            />
          ) : null}
        </TableCell>
      ),
    },
  ].filter((column) => !column.hide)

  const headerRow = gridColumns.map((column) => column.header)

  return (
    <SCGLobal.ContentContainer>
      <ContextTableWrapper
        queryResult={newQueryResult}
        refetch={refetch}
        refetchOverride={dealRefetch}
        headerRow={headerRow}
      >
        {showFilters || showFilters === undefined ? (
          <SCGLobal.PushContainer>
            <ItemsSearch showDealFilter={showDealFilter} />
          </SCGLobal.PushContainer>
        ) : null}
        <SC.ItemTableWrapper data-testid="items-table">
          <Table
            bodyRow={tableData()}
            className="table-shared m-0"
            dataMeta={data?.items.meta}
            fetchMore={fetchMore}
            headerRow={headerRow}
            loading={loading}
            queryName="items"
            refetch={refetch}
            variables={variables}
          />
        </SC.ItemTableWrapper>
      </ContextTableWrapper>
    </SCGLobal.ContentContainer>
  )
}

export default ItemsList
