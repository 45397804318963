import { gql } from '@apollo/client'

export const GET_UNREAD_COUNT = gql`
  {
    unreadCount @client {
      amount
    }
  }
`

export const CHANGE_UNREAD_NOTIFS_COUNT = gql`
  mutation ChangeUnreadNotifsCount($amount: Int, $id: String) {
    setUnreadCount(amount: $amount, id: $id) @client {
      amount
    }
  }
`

const resolvers = {
  Mutation: {
    setUnreadCount: (_root, { amount, id }, { cache, getCacheKey }) => {
      const cacheId = getCacheKey({ __typename: 'UnreadCount', id })
      const previous = cache.readQuery({ query: GET_UNREAD_COUNT })

      if (typeof amount === 'number' && previous) {
        const unreadCount = { amount, __typename: cacheId }

        let data = {}

        if (previous.unreadCount.some((item) => item.__typename === cacheId)) {
          data = {
            unreadCount: previous.unreadCount.map((item) => {
              if (item.__typename === cacheId) {
                item.amount = amount
              }

              return item
            }),
          }
        } else {
          data = {
            unreadCount: [...previous.unreadCount, unreadCount],
          }
        }

        cache.writeQuery({ query: GET_UNREAD_COUNT, data })
      }

      return null
    },
  },
  Query: {
    getUnreadCount: (_root, { id }, { cache, getCacheKey }) => {
      const cacheId = getCacheKey({ __typename: 'UnreadCount', id })

      const queryData = cache.readQuery({ query: GET_UNREAD_COUNT })

      const [filteredData] = queryData.unreadCount.filter(
        (item) => item.__typename === cacheId,
      )

      if (filteredData) {
        return filteredData
      }

      return null
    },
  },
}
const Resolvers = {
  resolvers,
}
export default Resolvers
