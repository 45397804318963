// @react
import React, { ReactElement } from 'react'
// @design
// @components
import ChipBtn from 'modules/common/components/_UI_DUMB/ChipBtn'
import strings from 'constants/strings'
import { RouteNames } from 'constants/routeNames'
import { ChipFiltersWrapper } from 'modules/common/components/_UI_DUMB/ChipBtn/styledComponents'

/*******************************************************
 * TYPES
 *******************************************************/
type PropsType = {
  activeIndex: number
  dealId: string
}

/**
 *
 * @returns {React.ReactElement}
 * @constructor
 */
const ItemsTab: React.FC<PropsType> = ({ dealId }: PropsType): ReactElement => {
  /*******************************************************
   * RENDER
   *******************************************************/
  return (
    <>
      <ChipFiltersWrapper>
        <ChipBtn
          labelText={strings.LIST}
          url={`/${RouteNames.DEALS}/${RouteNames.DETAILS}/${dealId}/${RouteNames.ITEMS}`}
        />

        <ChipBtn
          labelText={strings.STAGE_VIEW}
          url={`/${RouteNames.DEALS}/${RouteNames.DETAILS}/${dealId}/${RouteNames.STAGE_VIEW}`}
        />

        <ChipBtn
          labelText={strings.CARDS}
          url={`/${RouteNames.DEALS}/${RouteNames.DETAILS}/${dealId}/${RouteNames.CARD_VIEW}`}
        />
      </ChipFiltersWrapper>
    </>
  )
}

export default ItemsTab
