// @graphql
import {
  ContactStatus,
  InviteType,
  Scalars,
  SimplifiedUser,
  SimplifiedUserNodeFragment,
  TeamRole,
  UserDealFragment,
} from 'graphql/graphqlTypes'
// @components
import { ContactItemCTA } from 'modules/common/components/_UI_SMART/ContactItem/type'
import ContactItem from 'modules/common/components/_UI_SMART/ContactItem/ContactItem'
// @common
import { isOfType, ItemStatus } from 'modules/common/types'
import LoadMoreBtn from 'modules/common/components/_UI_SMART/LoadMoreBtn'
import useUserActions from './userActionsHook'
import { useContext } from 'react'
import { DealContext } from 'modules/deal/contexts/deal'
import UserModel from '../../../user/models/user'

/*******************************************************
 * BUTTON AND MENU ASSEMBLY
 *******************************************************/

const getButtons = (
  user: SimplifiedUser,
  dealId,
  buttonGetters,
): ContactItemCTA[] => {
  const buttons: ContactItemCTA[] = []

  const cancelInviteButton = buttonGetters.getCancelDealInviteCTA(user, () => {
    console.log('calling back')
  })
  if (cancelInviteButton) {
    buttons.push(cancelInviteButton)
  }

  const inviteToDealButton = buttonGetters.getInviteToDealCTA(
    dealId,
    user,
    undefined, // @todo...
  )
  if (inviteToDealButton) {
    buttons.push(inviteToDealButton)
  }

  return buttons
}

/**
 *  item status shows whether user has a relationship or not
 * @param {SimplifiedUser} user
 * @returns {ItemStatus | undefined}
 */
const getItemStatus = (user: SimplifiedUser): ItemStatus | undefined => {
  const inviteId = user?.userDeal?.inviteId
  const acceptedDealStatus = user?.userDeal?.status
  if (
    user?.userDeal?.inviteType === InviteType.DEAL &&
    acceptedDealStatus === ContactStatus.PENDING &&
    !!inviteId
  ) {
    return ItemStatus.DISABLED
  }

  if (
    acceptedDealStatus === ContactStatus.ACCEPTED ||
    acceptedDealStatus === ContactStatus.PENDING
  ) {
    return ItemStatus.DISABLED
  }
  return
}

/**
 *
 * @param {(SimplifiedUserNodeFragment & UserDealFragment)[] | null} userContactsData
 * @param {{id?: string, teamLeadUserId: string}} teamContext
 * @param {{isLoadMoreNeeded: boolean, loadMoreHandler: () => void}} loadMore
 * @param {any} refetch
 * @returns {JSX.Element | null}
 * @constructor
 */
/**
 *
 */
const DealSendContactsList = ({
  userContactsData,
  loadMore,
  refetch,
  deal,
}: {
  userContactsData?: (SimplifiedUserNodeFragment & UserDealFragment)[] | null
  loadMore: {
    isLoadMoreNeeded: boolean
    loadMoreHandler: () => void
  }
  refetch
  deal?: Scalars['ID']
}) => {
  const { buttonGetters } = useUserActions(refetch)
  const dealId = useContext(DealContext)?.dealId || deal

  const contactList = userContactsData?.map((userContact) => {
    if (
      !isOfType<SimplifiedUser>(userContact, '__typename', 'SimplifiedUser')
    ) {
      return null
    }
    const buttons: ContactItemCTA[] = getButtons(
      userContact,
      dealId,
      buttonGetters,
    )

    const acceptedDealStatus = userContact?.userDeal?.status
    const isLead = userContact?.userDeal?.teamRole === TeamRole.LEAD
    const itemStatus = getItemStatus(userContact)
    const userMeta = UserModel.nameFormatter(userContact)

    return (
      <ContactItem
        key={userContact?.id}
        userId={userContact?.id}
        name={userMeta.name}
        email={userContact?.email}
        text={userMeta.title}
        avatar={userContact?.avatar?.file?.location}
        contactStatus={acceptedDealStatus}
        showLeadIcon={isLead}
        itemStatus={itemStatus}
        buttons={buttons}
        onUserClick={() => {
          console.log('onclick')
        }}
        role={userContact?.userDeal?.teamRole}
      />
    )
  })

  return (
    <>
      {contactList}
      <LoadMoreBtn
        isVisible={loadMore.isLoadMoreNeeded}
        isLoading={false}
        onClick={loadMore.loadMoreHandler}
      />
    </>
  )
}

export default DealSendContactsList
