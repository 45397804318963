// @react
import React from 'react'
import { FC, useState } from 'react'
// @common
import BROWSER_HISTORY from 'utils/history'
// @design
import * as SC from '../../styledComponents/suggest'
// @components
import Select from 'react-select'
import Selected from './Selected'
import { RouteNames } from 'constants/routeNames'

export interface SuggestType {
  value?: number
  label?: string
  position?: string
  avatar?: string
}

interface InjectProps {
  options: SuggestType[]
}

type PropsType = InjectProps

/**
 *
 * @param options
 * @constructor
 */
const Suggester: FC<PropsType> = ({ options }) => {
  // @todo this doesn't make any sense
  const [selected] = useState<SuggestType[]>([])

  /*******************************************************
   * FUNCTIONS
   *******************************************************/
  const onChange = (selected: [SuggestType]) => {
    BROWSER_HISTORY.push({
      pathname: `/${RouteNames.MESSAGES}/${String(selected[0].value)}`,
    })
  }

  /*******************************************************
   * RENDER
   *******************************************************/
  return (
    <SC.Wrap>
      <Select
        TextFieldProps={{
          label: 'Label',
          InputLabelProps: {
            shrink: true,
          },
        }}
        options={options}
        value={selected}
        onChange={onChange}
        placeholder="Start typing a name..."
        classNamePrefix="suggest"
        isMulti
      />
      <Selected selected={selected} />
    </SC.Wrap>
  )
}

export default Suggester
