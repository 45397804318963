// @react
import { ReactElement, useContext, useState } from 'react'
//@design
import { makeStyles, StepConnector, StepIcon, Theme } from '@material-ui/core'
import * as SC from '../styledComponents/dealStageViewSteppers'
import classnames from 'classnames'
// @common
import DealModel from 'modules/deal/models/deal'
// @graphql
import {
  DealStage,
  GetItemsWithUserQuery,
  GetItemsWithUserQueryVariables,
} from 'graphql/graphqlTypes'
import useFilters from 'modules/common/components/_UI_SMART/SharedTable/hook/filters'
import { QueryResult } from '@apollo/client'
import { DealContext } from 'modules/deal/contexts/deal'

/*******************************************************
 * CONST
 *******************************************************/
const style = (theme: Theme) => ({
  connectorLine: {
    transition: theme.transitions.create('border-color'),
    borderWidth: '2px',
  },
})
const useStyles = makeStyles(style)

/*******************************************************
 * TYPES
 *******************************************************/
type PropsType = {
  setDealStage: (value: DealStage) => void
  handleChangeVisibility: () => void
  dealStage: DealStage
  queryResult: QueryResult<
    GetItemsWithUserQuery,
    GetItemsWithUserQueryVariables
  >
}

/**
 *
 * @param props
 * @returns {null}
 * @constructor
 */
const DealStageViewSteppers = ({
  // changeCriteria,
  handleChangeVisibility,
  dealStage,
  setDealStage,
  queryResult,
}: PropsType): ReactElement | null => {
  /*******************************************************
   * CONST
   *******************************************************/
  const { variables } = queryResult
  const { changeCriteria } = useFilters({
    queryResult,
  })
  const { data } = useContext(DealContext)

  const classes = useStyles()
  const dealStageOrder = DealModel.getStageOrder(
    data?.deal.stage || DealStage.PRELIM,
  )
  const focusedStageOrder = DealModel.getStageOrder(
    data?.deal.stage || DealStage.PRELIM,
  )
  const stages = DealModel.getStagesArrayByOrder()
  const [pointer, setPointerStage] = useState(dealStage)
  /**
   *
   * @param key
   */
  const completeStep = (key: DealStage) => {
    if (key === DealStage.PRELIM) {
      handleChangeVisibility()
    } else {
      setDealStage(key)
      setPointerStage(key)
      changeCriteria([
        {
          key: 'stage',
          values: [key],
        },
      ])
    }
  }

  /*******************************************************
   * RENDER ELEMENTS
   *******************************************************/
  const firstIcon = () => (
    <SC.IconWrap active>
      <SC.Icon />
    </SC.IconWrap>
  )

  const connector = (
    <StepConnector
      classes={{
        line: classes.connectorLine,
      }}
    />
  )

  /*******************************************************
   * RENDER
   *******************************************************/
  return dealStage ? (
    <SC.StyledStepper
      activeStep={DealModel.getStageOrder(dealStage)}
      connector={connector}
      alternativeLabel
    >
      {stages.map((stage) => {
        const loopStageOrder = DealModel.getStageOrder(stage)
        const completedStep = loopStageOrder <= dealStageOrder
        const focusedStep = loopStageOrder === focusedStageOrder
        const activeStep = stage === pointer
        const isFirstStep = stage === DealStage.PRELIM
        const activeClasses = classnames({
          completedStep: loopStageOrder <= dealStageOrder,
          inactiveStep: loopStageOrder > dealStageOrder,
          currentStep: loopStageOrder === focusedStageOrder,
        })

        return (
          <SC.StyledStep
            key={DealModel.getStageOrder(stage)}
            completed={completedStep && !focusedStep}
            active={focusedStep}
            className={activeClasses}
          >
            <SC.StyledStepLabel
              data-first-step={isFirstStep}
              StepIconComponent={StepIcon}
              onClick={() => !isFirstStep && completeStep(stage)}
              {...(isFirstStep && {
                icon: firstIcon(),
              })}
            >
              {activeStep && <SC.DownIcon />}
              {DealModel.getStageLabel(stage).toUpperCase()}
            </SC.StyledStepLabel>
          </SC.StyledStep>
        )
      })}
    </SC.StyledStepper>
  ) : null
}

export default DealStageViewSteppers
