// @react
import React from 'react'
import { useEffect, useState } from 'react'
// @common
import SearchBar from 'modules/common/components/_UI_SMART/SearchBar'

import useDebounce from 'modules/common/hooks/useDebounce'
import { config } from 'constants/config'
/*******************************************************
 * TYPES
 *******************************************************/
interface PropsType {
  type: string
  changeCriteria: (
    filters: {
      key: string
      values: string
    }[],
  ) => void
  initialValue?: string
  placeholder: string
}

/**
 *
 * @param props
 * @constructor
 */
const SearchFilter: React.FunctionComponent<PropsType> = ({
  changeCriteria,
  type,
  placeholder,
  initialValue,
}: PropsType) => {
  /*******************************************************
   * STATE
   *******************************************************/
  const [searchValue, setSearchValue] = useState<string | undefined>(
    initialValue || '',
  )

  /*******************************************************
   * HOOKS
   *******************************************************/
  const debouncedSearchValue = useDebounce(searchValue, config.debounce)

  /*******************************************************
   * EFFECT HOOKS
   *******************************************************/
  useEffect(() => {
    if (debouncedSearchValue !== undefined) {
      changeCriteria([{ key: type, values: debouncedSearchValue }])
    }
  }, [debouncedSearchValue])

  /*******************************************************
   * RENDER
   *******************************************************/
  return (
    <SearchBar
      value={searchValue}
      placeholder={placeholder}
      onClear={() => setSearchValue('')}
      onChange={(e) => setSearchValue(e.target.value)}
    />
  )
}

export default SearchFilter
