import { gql } from '@apollo/client'
import { MESSAGE_ITEM, MESSAGE_META } from '../fragments/message.fragment'

export const GET_MESSAGES_BY_USER = gql`
  query GetMessagesByUser(
    $filters: MessagesFilterInput
    $orderBy: MessagesOrderByInput
    $page: Int
    $pageSize: Int
  ) {
    messages(
      orderBy: $orderBy
      filters: $filters
      page: $page
      pageSize: $pageSize
    ) {
      data {
        ...MessageItem
      }
      meta {
        ...MessageMeta
      }
    }
  }
  ${MESSAGE_META}
  ${MESSAGE_ITEM}
`

export const GET_THREADS_BY_USER = gql`
  query GetThreadsByUser {
    messageThreads {
      ...MessageItem
    }
  }
  ${MESSAGE_ITEM}
`
