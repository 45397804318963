// @react
import { useState } from 'react'
import { ReactElement } from 'react'
// @design
import * as SC from '../../styledComponents/createMessage'
// @common
import strings from 'constants/strings'
// @components
import MessagesTextarea from 'modules/common/components/_FORM_ELEMENTS/Message/MessagesTextarea'
import Suggest from '../../components/Suggest'
import { MessagesContainer } from 'modules/common/styledComponents/Message'
// @graphql
import { GET_USER_CONTACTS } from 'graphql/queries/user.query'
import { useQuery } from '@apollo/client'
import {
  ContactStatus,
  GetUserContactsQuery,
  GetUserContactsQueryVariables,
} from 'graphql/graphqlTypes'
import ProfileAddContact from 'modules/user/components/ProfileAddContact'

/**
 *
 * @constructor
 */
const CreateMessage = (): ReactElement => {
  /*******************************************************
   * GRAPHQL
   *******************************************************/
  /**
   * queries
   */
  const { data } = useQuery<
    GetUserContactsQuery,
    GetUserContactsQueryVariables
  >(GET_USER_CONTACTS, {
    variables: {
      filters: { search: null },
      contactStatus: ContactStatus.ACCEPTED,
    },
  })
  const [isOpened, setIsOpened] = useState(false)

  const formattedData = data?.userContacts.data?.map((el) => {
    return {
      value: el?.id ? parseInt(el.id) : undefined,
      label: `${el?.firstName || ''} ${el?.lastName || ''}`,
      position: `${el?.title || ''}, ${el?.company || ''}`,
      avatar: (el?.avatar && el.avatar.file?.location) || '',
    }
  })

  const addContacts = () => setIsOpened(true)

  const renderTitle = () => {
    if (data?.userContacts.meta?.count) {
      return (
        <>
          <SC.Title>{strings.NEW_MESSAGE}</SC.Title>
          {formattedData?.length && <Suggest options={formattedData} />}
        </>
      )
    }

    return (
      <SC.Title>
        You don’t have any contacts yet.
        <SC.StyledButton color="primary" onClick={addContacts}>
          Add a new contact
        </SC.StyledButton>
        to begin messaging!
      </SC.Title>
    )
  }
  /*******************************************************
   * RENDER
   *******************************************************/
  return (
    <MessagesContainer>
      <SC.Wrap>{renderTitle()}</SC.Wrap>
      <ProfileAddContact
        open={isOpened}
        handleClose={() => setIsOpened(false)}
      />
      {data?.userContacts.meta?.count && (
        <MessagesTextarea
          editedCommentText={{ value: undefined, commentId: undefined }}
        />
      )}
    </MessagesContainer>
  )
}

export default CreateMessage
