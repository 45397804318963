import React from 'react'
import * as SC from './styledComponents'
import { DialogContent } from '@material-ui/core'

type PropsType = {
  open: boolean
  title: string
  onClose: () => void
  children: React.ReactNode
  action: React.ReactNode
}

const FilterDialog = ({
  title,
  children,
  action,
  open,
  onClose,
}: PropsType) => (
  <>
    <SC.ChildrenWrap>{children}</SC.ChildrenWrap>
    <SC.Dialog open={open} onClose={onClose} title={title}>
      <DialogContent>
        {children}
        {action}
      </DialogContent>
    </SC.Dialog>
  </>
)

export default FilterDialog
