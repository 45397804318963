import { useState } from 'react'
import {
  Button,
  CircularProgress,
  IconButton,
  makeStyles,
  Typography,
} from '@material-ui/core'
import * as SC from './styledComponents/contactItem'
import AddCircle from '@material-ui/icons/AddCircle'
// @common
import SwitchboardTooltip from 'modules/common/components/_UI_DUMB/SwitchboardTooltip/SwitchboardTooltip'
import { RouteNames } from 'constants/routeNames'
import BROWSER_HISTORY from 'utils/history'
import Dropdown from 'modules/common/components/_UI_DUMB/Dropdown'
import ContactAvatar, {
  ContactAvatarRole,
  ContactAvatarRoleType,
} from './components/ContactAvatar'
import ContactTag from './components/ContactTag'
import { ItemStatus, Team } from 'modules/common/types'
import { ContactStatus, TeamRole } from 'graphql/graphqlTypes'
import { ContactItemComponentProps, ContactItemVariant } from './type'
import { MoreHoriz } from '@material-ui/icons'

/*******************************************************
 * CONSTANTS
 *******************************************************/
const contactStatusLabels = {
  'THEIR TEAM': 'THEIR TEAM',
  'PENDING INVITE': 'PENDING',
  PENDING: 'PENDING',
  INVITED: 'USER INVITED YOU',
}
const teamTypeLabels = {
  THEIR_TEAM: 'THEIR TEAM',
  MY_TEAM: 'MY TEAM',
}
const useStyles = makeStyles(SC.style)
/*******************************************************
 * TYPES
 *******************************************************/

type PropsType = ContactItemComponentProps

/**
 * @param injectClasses
 * @param text
 * @param avatar
 * @param name
 * @param userId
 * @param teamType
 * @param itemStatus
 * @param contactStatus
 * @param buttons
 * @param onUserClick
 * @param role
 * @param hideStatus
 * @param hideTeamType
 * @param isAdded
 * @param email
 */
const ContactItem = ({
  text,
  avatar,
  name,
  userId,
  teamType,
  itemStatus, // currently used to show user relationship (is contact not not icon), need a dedicated is user a user contact field
  contactStatus, // shows invite and deal related status, pending, accepted etc
  buttons,
  onUserClick,
  role,
  hideStatus,
  hideTeamType,
  isAdded,
  email,
}: PropsType) => {
  /*******************************************************
   * STATE
   *******************************************************/
  const [anchorEl, setAnchorEl] = useState<(EventTarget & HTMLElement) | null>()
  const [loadingButtons, setLoadingButtons] = useState({})

  /*******************************************************
   * HOOKS
   *******************************************************/
  const classes = useStyles()

  /*******************************************************
   * FUNCTIONS
   *******************************************************/
  const handleClose = () => {
    setAnchorEl(null)
  }

  /**
   *
   * @param index
   * @param handler
   */
  const handlerLoading = async (index, handler) => {
    setLoadingButtons((prev) => {
      return { ...prev, [index]: true }
    })
    handler && (await handler())
    setLoadingButtons((prev) => {
      return { ...prev, [index]: false }
    })
  }

  /**
   *
   */
  const getRole = (): ContactAvatarRoleType | undefined => {
    if (!role) return
    if (teamType === Team.THEIR_TEAM && role === TeamRole.LEAD) {
      // @todo 01-22 fix this type
      return ContactAvatarRole.THEIR_TEAM_LEAD as unknown as ContactAvatarRoleType
    }
    // @todo 01-22 fix this type
    return role as unknown as ContactAvatarRoleType
  }

  /**
   *
   */
  const onUserClickHandler = () => {
    if (onUserClick) {
      onUserClick()
    } else {
      BROWSER_HISTORY.push(`/${RouteNames.USER}/${String(userId)}`)
    }
  }
  const getContactStatusLabel = (contactStatus) => {
    let status = contactStatus
    if (!contactStatus && Number(userId) < 0) {
      status = ContactStatus.PENDING
    }
    return contactStatusLabels[status] || ''
  }
  const hasStatus = !!contactStatus || Number(userId) < 0
  const teamTypeLabel = teamType ? teamTypeLabels[teamType] : ''

  /**
   * component: roleNode
   */
  const RoleNode = () => {
    if (typeof text === 'string') {
      return (
        <SC.ContactTextWrap>
          <SC.ContactText variant="body1">{text}</SC.ContactText>
        </SC.ContactTextWrap>
      )
    }
    const preparedTest = (text?.length && text.join(', ')) || ''

    return (
      <SC.ContactTextWrap>
        <SC.ContactText variant="body1">{preparedTest}</SC.ContactText>
      </SC.ContactTextWrap>
    )
  }

  // @todo review
  const displayName = () => {
    if (typeof name === 'string') return name
    if (name.length) {
      return name.join(' ')
    }
    return email || ''
  }

  /**
   * component: ctas
   */
  let ctas
  if (buttons?.length) {
    ctas = buttons.map((item, index) => {
      switch (item.variant) {
        case ContactItemVariant.REMOVE:
          const removeHandler = () => handlerLoading(index, item.handler)
          return (
            <SwitchboardTooltip
              key={`${index}${String(item.text)}`}
              title={item.tooltipText}
              theme={'warning'}
            >
              {loadingButtons[index] ? (
                <CircularProgress size={20} />
              ) : (
                <IconButton
                  data-testid={'contact-user-item-removeuser-cta'}
                  color="secondary"
                  component="span"
                  disabled={loadingButtons[index]}
                  onClick={removeHandler}
                >
                  <AddCircle className={classes.cancelIcon} />
                </IconButton>
              )}
            </SwitchboardTooltip>
          )
        case ContactItemVariant.MENU:
          const menuItems = item.items?.map((it) => {
            const menuHandler = () => handlerLoading(index, it.handler)
            return {
              clickAction: menuHandler,
              Component: <>{it.text}</>,
            }
          })
          return (
            <div key={index}>
              <SC.Link
                href=""
                data-testid="contact-user-item-more-button"
                onClick={(event) => {
                  event.preventDefault()
                  setAnchorEl(event.currentTarget)
                }}
              >
                {loadingButtons[index] ? (
                  <CircularProgress size={20} />
                ) : (
                  item.menuTrigger || (
                    <SC.AddIconButton>
                      <MoreHoriz />
                    </SC.AddIconButton>
                  )
                )}
              </SC.Link>
              <Dropdown
                title={`${String(name)}${String(userId)}${String(email)}`}
                testId={'contact-user-item-more-menu'}
                handleCloseOutside={handleClose}
                openEventTarget={anchorEl}
                menuItems={menuItems}
                variant="yellow"
              />
            </div>
          )
        case ContactItemVariant.ACTION:
          const addHandler = () => handlerLoading(index, item.handler)
          return (
            <SwitchboardTooltip key={index} title={item.tooltipText}>
              <Button
                className={classes.inviteBtn}
                variant="contained"
                color="primary"
                disabled={loadingButtons[index]}
                onClick={addHandler}
              >
                {loadingButtons[index] ? (
                  <CircularProgress size={20} />
                ) : (
                  item.text
                )}
              </Button>
            </SwitchboardTooltip>
          )
        default:
          return null
      }
    })
  }

  const shouldShowConnected = (): boolean => {
    return itemStatus === ItemStatus.CONNECTED || !!isAdded
  }

  /*******************************************************
   * RENDER
   *******************************************************/
  return (
    <div
      style={{ position: 'relative' }}
      data-testid={'contact-user-item'}
      data-test-contact-item-user={userId}
    >
      <SC.StyledContactRequestItem
        disabled={itemStatus === ItemStatus.DISABLED}
        // itemStatus={itemStatus}
        // isAdded={isAdded}
      >
        <SC.Wrap onClick={onUserClickHandler}>
          <ContactAvatar type={getRole()} avatar={avatar} />
          <div>
            <SC.FlexCenter>
              <Typography
                data-testid={'contact-user-item-displayname'}
                className={classes.name}
                variant="subtitle1"
              >
                {displayName()}
              </Typography>
              {(hideStatus ?? true) && hasStatus && (
                <SC.TagWrapDesktop>
                  {!hideTeamType && <ContactTag type={teamTypeLabel} />}
                  <ContactTag type={getContactStatusLabel(contactStatus)} />
                </SC.TagWrapDesktop>
              )}
              {shouldShowConnected() && <SC.StyledPeopleIcon />}
            </SC.FlexCenter>
            <RoleNode />
          </div>
          {(hideStatus ?? true) && hasStatus && (
            <SC.TagWrapMobile>
              {!hideTeamType && <ContactTag type={teamTypeLabel} />}
              <ContactTag type={getContactStatusLabel(contactStatus)} />
            </SC.TagWrapMobile>
          )}
        </SC.Wrap>
        <SC.ContactRequestItemActions active={!!anchorEl}>
          {ctas}
        </SC.ContactRequestItemActions>
      </SC.StyledContactRequestItem>
    </div>
  )
}

export default ContactItem
