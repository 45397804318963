// @react
import React from 'react'
import DocumentTitle from 'react-document-title'
import strings from 'constants/strings'
import parse from 'html-react-parser'
import * as ButtonsSC from 'modules/common/components/_UI_DUMB/Button/styledComponents'
import * as SC from 'modules/common/styledComponents/NetworkError'

const NotFound = () => (
  <DocumentTitle title="Page Not Found | Switchboard">
    <SC.Wrap>
      <SC.Title>{parse(strings.NOT_FOUND_MESSAGE)}</SC.Title>
      <div>
        <SC.StyledLink to={`/`}>
          <ButtonsSC.ButtonOrange variant="contained" color="primary">
            {strings.BACK_TO_HOME}
          </ButtonsSC.ButtonOrange>
        </SC.StyledLink>
      </div>
    </SC.Wrap>
  </DocumentTitle>
)

export default NotFound
