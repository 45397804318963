// @react
import { useState, useContext } from 'react'
import parse, { domToReact, Element } from 'html-react-parser'
import clsx from 'clsx'
// @common
import strings from 'constants/strings'
import { Scalars } from 'graphql/graphqlTypes'
import { ItemEditCommentContext } from 'modules/item/contexts/item'
// @design
import { NotificationItem as StyledNotificationItem } from 'modules/common/styledComponents/Dashboard'
import { Oval } from 'modules/notification/styledComponents/notifications'
import * as SC from 'modules/notification/styledComponents/recentNotificationsItem'

/*******************************************************
 * TYPES
 *******************************************************/
type PropsType = {
  id?: Scalars['ID']
  avatar?: string
  disableHover?: boolean | null
  fromTime: string
  name?: string
  seen?: boolean | null
  text: string
  active?: boolean | null
  newClasses?: Partial<{
    text: string
    wrap: string
  }>
  onClickAction?(id: Scalars['ID'], text: string): void
  onEditClick?(id: Scalars['ID'], text: string): void
  onDeleteClick?(id: Scalars['ID']): void
  disabled?: boolean
}

/**
 *
 * @param newClasses
 * @param disableHover
 * @param active
 * @param avatar
 * @param name
 * @param fromTime
 * @param id
 * @param onClickAction
 * @param text
 * @param charsToShow
 * @param disabled
 * @param onEditClick
 * @param onDeleteClick
 * @param seen
 * @constructor
 */
const RecentNotificationsItem = ({
  newClasses,
  disableHover,
  active,
  avatar,
  name,
  fromTime,
  id,
  text,
  disabled,
  onClickAction,
  onEditClick,
  onDeleteClick,
  seen,
}: PropsType) => {
  /*******************************************************
   * PROPS
   *******************************************************/
  const hasNoMenu = disabled || !(onDeleteClick && onEditClick)
  const showOval = !seen && hasNoMenu

  const charsToShow = 157
  /**
   * isCutText was previously passed as a prop, not sure of current use
   */
  const isCutText = false

  /*******************************************************
   * STATE
   *******************************************************/
  const [anchorEl, setAnchorEl] = useState<Element | null>(null)

  /*******************************************************
   * CONTEXT
   *******************************************************/
  const editCommentContext = useContext(ItemEditCommentContext)

  /*******************************************************
   * FUNCTIONS
   *******************************************************/

  /**
   *
   */
  const handleClose = () => {
    setAnchorEl(null)
  }

  /**
   *
   * @param event
   */
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget)
  }

  /*******************************************************
   * RENDER ELEMENTS
   *******************************************************/
  const actionsMenu = hasNoMenu ? null : (
    <SC.Menu>
      <SC.MenuItem
        onClick={() => {
          onEditClick && id && onEditClick(id, text)
          handleClose()
        }}
      >
        {strings.EDIT}
      </SC.MenuItem>
      <SC.MenuItem
        onClick={() => {
          onDeleteClick && id && onDeleteClick(id)
          handleClose()
        }}
      >
        {strings.DELETE}
      </SC.MenuItem>
    </SC.Menu>
  )

  const textToParse = isCutText ? `${text.substr(0, charsToShow)}...` : text
  // TODO: we need to strip out contentEditable
  // inserted automatically by react-quill mentions, as per
  // https://stackoverflow.com/a/65565567
  const parsedText = parse(textToParse, {
    replace: (domNode) => {
      if (
        domNode instanceof Element &&
        domNode.attribs.contenteditable === 'false'
      ) {
        return <span>{domToReact(domNode.children)}</span>
      }
      return domNode
    },
  })

  /*******************************************************
   * RENDER
   *******************************************************/
  return (
    <StyledNotificationItem
      data-testid={showOval ? 'notification-unread' : 'notification-read'}
      className={clsx(
        disabled && 'disabled',
        disableHover && 'disable-hover',
        active && 'active',
        newClasses && String(newClasses.wrap),
      )}
    >
      <SC.Wrap
        onClick={() => {
          onClickAction && id && onClickAction(id, text)
        }}
      >
        {showOval ? <Oval /> : null}
        <SC.StyledAvatar
          src={avatar || 'https://www.w3schools.com/howto/img_avatar.png'}
        />
        <SC.RightColumn>
          <SC.FlexCenter>
            <SC.Name variant="subtitle1">{name || 'USER NOT FOUND'}</SC.Name>
            <SC.FromTime variant="body1">{fromTime}</SC.FromTime>
          </SC.FlexCenter>
          {editCommentContext &&
          editCommentContext.editedCommentText.commentId === id ? (
            <SC.EditMessageTextarea
              testid={`edit-comment-${id as string}`}
              noAvatar
              {...editCommentContext}
            />
          ) : (
            <>
              <SC.Text
                component="div"
                display="block"
                variant="body1"
                classes={{
                  ...(newClasses
                    ? {
                        root: newClasses.text || '',
                      }
                    : {}),
                }}
              >
                {parsedText}
              </SC.Text>
              {actionsMenu}
            </>
          )}
        </SC.RightColumn>
      </SC.Wrap>
    </StyledNotificationItem>
  )
}

export default RecentNotificationsItem
