import styled from 'styled-components'
import colors from 'constants/colors'
import { Avatar, Typography } from '@material-ui/core'
import { flexCenter } from 'modules/common/styledComponents/Mixins'

export const Wrap = styled.div`
  margin-bottom: 1em;
  padding: 0.3em 0;
`

export const SendTo = styled.div`
  && {
    ${flexCenter}
  }
  margin-top: 0.5em;
  padding: 1em 1.8em;
  border: 1px solid ${colors.veryLightBlue};
  background-color: ${colors.paleGrey};
`

export const StyledAvatar = styled(Avatar)`
  && {
    width: 60px;
    height: 60px;
    margin-right: 20px;
  }
`

export const Name = styled(Typography)`
  && {
    color: ${colors.dark};
    font-weight: 600;
  }
`

export const Position = styled(Typography)`
  && {
    font-size: 14px;
  }
`
