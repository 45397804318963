// @react
import { useEffect } from 'react'
import * as ContactItemSC from 'modules/common/components/_UI_SMART/ContactItem/styledComponents/contactItem'
// @graphql
import { GET_USER_CONTACTS } from 'graphql/queries/user.query'
import {
  ContactStatus,
  GetUserContactsQuery,
  GetUserContactsQueryVariables,
  OnUserCanceledInvitationToContactsSubscription,
  OnUserCanceledInvitationToContactsSubscriptionVariables,
  OnUserInvitedToContactsSubscription,
  OnUserInvitedToContactsSubscriptionVariables,
} from 'graphql/graphqlTypes'
import {
  USER_CANCELED_INVITATION_TO_CONTACTS,
  USER_INVITED_TO_CONTACTS,
} from 'graphql/subscriptions/user.subscription'
import { useQuery } from '@apollo/client'
// @components
import ProfileNoContacts from 'modules/user/components/ProfileNoContacts'
import ConditionalLoader from 'modules/common/components/_UI_DUMB/Loaders/ConditionalLoader'
import UserContactsList from 'modules/deal/components/UserRelationsManage/UserContactsList'

/**
 *    WHEN would this state need to be updated?
 *
 *      1 when this user deletes invite (local)
 *      2 when this user crated invite through add-contact modal dialogue (local)
 *      3 when contact deletes this user's invite, UI removes invite from list (sub)
 *      4 when contact accepts this user's invite, UI removes invite from list (sub)
 *
 * @constructor
 */
const ProfilePendingContacts = () => {
  /*******************************************************
   * GRAPHQL
   *******************************************************/
  const { data, loading, refetch, subscribeToMore } = useQuery<
    GetUserContactsQuery,
    GetUserContactsQueryVariables
  >(GET_USER_CONTACTS, {
    variables: { contactStatus: ContactStatus.PENDING },
    fetchPolicy: 'network-only',
  })

  /*******************************************************
   * SUBSCRIPTIONS
   *******************************************************/
  useEffect(() => {
    return subscribeToMore<
      OnUserInvitedToContactsSubscription,
      OnUserInvitedToContactsSubscriptionVariables
    >({
      document: USER_INVITED_TO_CONTACTS,
      updateQuery: (prev, { subscriptionData }) => {
        const newFeedItem = subscriptionData.data.userInvitedToContacts
        const count = prev.userContacts.meta?.count || 0
        const prevData = prev.userContacts.data ?? []

        return {
          ...prev,
          userContacts: {
            ...prev.userContacts,
            data: [...prevData, newFeedItem],
            meta: {
              ...prev.userContacts.meta,
              count: count + 1,
            },
          },
        }
      },
    })
  }, [subscribeToMore])

  useEffect(() => {
    return subscribeToMore<
      OnUserCanceledInvitationToContactsSubscription,
      OnUserCanceledInvitationToContactsSubscriptionVariables
    >({
      document: USER_CANCELED_INVITATION_TO_CONTACTS,
      updateQuery: (prev, { subscriptionData }) => {
        const canceledContact =
          subscriptionData.data.userCanceledInvitationToContacts
        return {
          ...prev,
          userContacts: {
            ...prev.userContacts,
            data: prev.userContacts.data?.filter(
              (contact) => contact?.id === canceledContact.id,
            ),
            meta: {
              ...prev.userContacts.meta,

              count: (prev.userContacts.meta?.count || 0) - 1,
            },
          },
        }
      },
    })
  }, [])

  /*******************************************************
   * RENDER
   *******************************************************/

  return (
    <ConditionalLoader condition={!loading}>
      <ContactItemSC.ContactListWrap data-testid="profile-pending-invites">
        <ContactItemSC.ContactWrap>
          <UserContactsList
            userContactsData={data?.userContacts.data}
            refetch={refetch}
            notFound={<ProfileNoContacts variant="empty-pending" />}
          />
        </ContactItemSC.ContactWrap>
      </ContactItemSC.ContactListWrap>
    </ConditionalLoader>
  )
}

export default ProfilePendingContacts
