import styled from 'styled-components'
import colors from 'constants/colors'

export const StyledTag = styled.div`
  ${(props) =>
    props.status === 'Open' &&
    `background: ${colors.aquaMarine}; color: ${colors.white}`};
  ${(props) =>
    props.status === 'Closed' &&
    `background: ${colors.aquaMarineLight}; color: ${colors.statusOnline}`};
  font-size: 14px;
  padding: 5px 11px;
  border-radius: 4px;
`

export const Wrapper = styled.div`
  display: flex;
`
