export const config = {
  debounce: 400,
  enableHeartBeat: false,
  defaultAvatar: 'https://www.w3schools.com/howto/img_avatar.png',
  maxFileSize: 100 * 1024 * 1024, // n x MB
  timeFormat: 'h:mm aaa',
  dateFormat: 'dd-MM-yyyy',
  dateSlashedFormat: 'dd/MM/yyyy',
  dateFullMonthFormat: 'MMMM d, yyyy',
  dateMonthFormat: 'MMM d, yyyy',
  isoDateFormat: 'yyyy-MM-dd',
  emptinessSymbol: '-',
  defaultPageSize: 12,
  pollInterval: process.env.REACT_APP_POLL_INTERVAL
    ? Number(process.env.REACT_APP_POLL_INTERVAL)
    : 10 * 1000 /* sec * ms */,
}

export default config
