import { IconButton, Typography, Grid } from '@material-ui/core'
import colors from 'constants/colors'
import styled from 'styled-components'
import { ContentContainer } from 'modules/common/styledComponents/Global'

export const Container = styled(ContentContainer)`
  && {
    flex: 1;
    overflow: auto;
    background-color: white;
    margin-top: 30px;
    padding: 0px
  }

  && .Mui-selected {
    background-color: ${colors.yellowishOrange}
    color: white
  }
  && .Mui-selected:hover {
    background-color: #a87507;
  }

  && .MuiButtonBase-root {
    border-color: #e5e9ed;
    margin-right: 10px;
  }

`

export const IconButtonStyled = styled(IconButton)`
&&.MuiButtonBase-root {
  ${(props) =>
    props.isactive
      ? `
      background-color: ${colors.yellowishOrange};
      color: ${colors.white};`
      : `background-color: ${colors.white};
      border: 1px solid ${colors.veryLightBlue};
      color: ${colors.grey};`}
  }
}
`

export const Title = styled(Typography)`
  && {
    padding-bottom: 20px;
    font-size: 16px;
    font-weight: 600;
    color: ${colors.dark};
  }
`

export const Section = styled(Grid)`
  && {
    padding: 30px;
    ${(props) =>
      props.withrightborder === 'true' &&
      `border-right: solid 1px ${colors.veryLightBlue};`}
  }
`
