// @react
import React, { useCallback, useEffect, useState, createContext } from 'react'
// @common
import BROWSER_HISTORY from 'utils/history'
import UserModel from 'modules/user/models/user'
// @components
import PageLoader from 'modules/common/components/_UI_DUMB/Loaders/PageLoader'
// @constants
import config from 'constants/config'
// @graphql
import { GET_USER_INFO } from 'graphql/queries/user.query'
import { UPDATE_USER_ACTIVITY } from 'graphql/mutations/user.mutation'
import { useQuery, useMutation } from '@apollo/client'
import {
  GetUserInfoQuery,
  GetUserInfoQueryVariables,
  User,
  UserActivityUpdateMutationVariables,
} from 'graphql/graphqlTypes'
import NotAuthorized from 'modules/common/pages/NotAuthorize'
import { HttpErrorMsg } from 'graphql/intGraphqlTypes'
import NetworkError from 'modules/common/pages/NetworkError'
/*******************************************************
 * CONSTANTS
 *******************************************************/
export enum UserInformationFilled {
  UNCHECKED,
  DATA_PROVIDED,
  DATA_REQUIRED,
}
/*******************************************************
 * TYPES
 *******************************************************/

interface UserContextValue {
  user?: User
  userMandatoryFieldsFilled?: {
    [p in Partial<keyof User>]?: string
  }
}

export const UserContext = createContext<UserContextValue>({})

/**
 *
 * @param {React.ComponentType} Component
 * @returns {() => JSX.Element}
 * @constructor
 */
const UserInfoRequiredWrapper = (Component: React.ComponentType) => {
  return function UserInfoRequired() {
    /*******************************************************
     * STATE
     *******************************************************/
    const [userStatus, setUserStatus] = useState<UserInformationFilled>(
      UserInformationFilled.UNCHECKED,
    )
    /*******************************************************
     * CONTEXT
     *******************************************************/
    const [contextValue, setContextValue] = useState<UserContextValue>({})

    /*******************************************************
     * GRAPHQL
     *******************************************************/
    /**
     * queries
     */
    const {
      loading,
      data: userData,
      error,
    } = useQuery<GetUserInfoQuery, GetUserInfoQueryVariables>(GET_USER_INFO, {
      nextFetchPolicy: 'cache-only',
    })

    const mandatoryFields = UserModel.getMandatoryFieldsStatus(
      // @todo 01-22 fix this type
      userData?.user as User,
    )
    const notAllFieldsProvided = !UserModel.checkIsAllMandatoryFieldsProvided(
      userData?.user || {},
    )

    /**
     * mutations
     */
    const [updateLastSeenMutation] = useMutation<
      UserActivityUpdateMutationVariables,
      UserActivityUpdateMutationVariables
    >(UPDATE_USER_ACTIVITY)

    /*******************************************************
     * HOOKS
     *******************************************************/

    /**
     *
     * @type {() => void}
     */
    const updateLastSeen = useCallback(() => {
      // Use the apollo client to run a mutation to update the last_seen value
      void updateLastSeenMutation({
        variables: {
          userActivityInput: {
            lastSeen: new Date().toISOString(),
          },
        },
      })
    }, [updateLastSeenMutation])

    /*******************************************************
     * EFFECT HOOKS
     *******************************************************/
    /**
     * @todo this is causing all kinds of global re-renders
     */
    useEffect(() => {
      let intervalHandler
      if (config.enableHeartBeat) {
        intervalHandler = setInterval(() => updateLastSeen(), 10000)
        return () => {
          // Clean up
          clearInterval(intervalHandler)
        }
      }
      return
    }, [updateLastSeen])

    /**
     *
     */
    useEffect(() => {
      if (
        userData?.user ||
        userData?.user === null // user has just signed up
      ) {
        if (userData.user) {
          setContextValue({
            // @todo 01-22 fix this type
            user: userData.user as User,
            userMandatoryFieldsFilled: mandatoryFields,
          })

          setUserStatus(
            notAllFieldsProvided
              ? UserInformationFilled.DATA_REQUIRED
              : UserInformationFilled.DATA_PROVIDED,
          )
        }
      }
    }, [userData])

    /**
     *
     */
    useEffect(() => {
      if (
        userStatus === UserInformationFilled.DATA_REQUIRED &&
        BROWSER_HISTORY.location.pathname !== '/profile'
      ) {
        BROWSER_HISTORY.push('/profile')
      }
    }, [userStatus, BROWSER_HISTORY.location.pathname])

    /*******************************************************
     * RENDER
     *******************************************************/
    if (error) {
      if (error.message === HttpErrorMsg.USER_NOT_APPROVED)
        return <NotAuthorized />
      return <NetworkError />
    }

    if (loading || !contextValue.user?.id) {
      return <PageLoader />
    }

    return (
      <UserContext.Provider value={contextValue}>
        <Component />
      </UserContext.Provider>
    )
  }
}

export default UserInfoRequiredWrapper
