// @react
import React from 'react'
import { Title, LaneHeader } from 'react-trello/dist/styles/Base'
import { InlineInput, LaneMenu } from 'react-trello'

/*******************************************************
 * TYPES
 *******************************************************/
type PropsType = {
  t: (title: string) => string
  updateTitle: () => void
  editLaneTitle: boolean
  canAddLanes: boolean
  laneDraggable: boolean
  label: string
  title: string
  onDelete: () => void
  onDoubleClick: () => void
  titleStyle: { [key: string]: string }
}

/**
 *
 * @param updateTitle
 * @param canAddLanes
 * @param onDelete
 * @param onDoubleClick
 * @param editLaneTitle
 * @param title
 * @param titleStyle
 * @param t
 * @param laneDraggable
 * @constructor
 */
const LaneHeaderComponent = ({
  updateTitle,
  canAddLanes = false,
  onDelete,
  onDoubleClick,
  editLaneTitle = false,
  title,
  titleStyle,
  t,
  laneDraggable,
}: PropsType) => {
  return (
    <LaneHeader onDoubleClick={onDoubleClick} editLaneTitle={editLaneTitle}>
      <Title draggable={laneDraggable} style={titleStyle}>
        {editLaneTitle ? (
          <InlineInput
            value={title}
            border
            placeholder={t('placeholder.title')}
            resize="vertical"
            onSave={updateTitle}
          />
        ) : (
          title
        )}
      </Title>
      {canAddLanes && <LaneMenu t={t} onDelete={onDelete} />}
    </LaneHeader>
  )
}

export default LaneHeaderComponent
