import { RouteType, RouteNames } from 'constants/routeNames'
import React from 'react'

const DashboardLazy = React.lazy(() => import('./pages/Dashboard'))
const routes: RouteType[] = [
  {
    component: DashboardLazy,
    path: `/${RouteNames.HOME}`,
    exact: true,
    type: 'PRIVATE',
    layout: 'MAIN',
  },
]
const Routes = {
  routes,
}

export default Routes
