import Button from 'modules/common/components/_UI_DUMB/Button'
import { PipelineContext } from 'modules/pipeline/contexts/pipeline'
import { TotalsSumBy } from 'modules/pipeline/enum'
import { useContext } from 'react'

/**
 *
 * @constructor
 */
const ShowBySelector = () => {
  /*******************************************************
   * CONST
   *******************************************************/
  const values = [
    { label: 'number of deals', value: TotalsSumBy.COUNT },
    { label: 'NAV', value: TotalsSumBy.NAV },
    { label: 'GAV', value: TotalsSumBy.GAV },
  ]

  /*******************************************************
   * CONTEXT
   *******************************************************/
  const { state, dispatch } = useContext(PipelineContext)

  /*******************************************************
   * FUNCTIONS
   *******************************************************/
  const handleClick = (val: TotalsSumBy) => {
    dispatch({ type: 'setSumBy', payload: { sumBy: val } }, state)
  }

  /*******************************************************
   * RENDER
   *******************************************************/
  return (
    <div>
      {values.map((button) => {
        const isSelected = state.sumBy === button.value
        return (
          <Button
            key={button.value}
            variant={isSelected ? 'contained' : 'outlined'}
            color={isSelected ? 'primary' : 'secondary'}
            onClick={() => handleClick(button.value)}
          >
            {button.label}
          </Button>
        )
      })}
    </div>
  )
}

export default ShowBySelector
