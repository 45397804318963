import styled from 'styled-components'
import SwitchboardDialog from 'modules/common/components/_UI_DUMB/ModalDialogs/SwitchboardDialog'
import { device, from, to } from 'modules/common/styledComponents/MediaQueries'

export const ChildrenWrap = styled.div`
  ${to(device.tabletXL)} {
    display: none;
  }
`

export const Dialog = styled(SwitchboardDialog)`
  ${from(device.tabletXL)} {
    display: none;
  }

  .MuiDialogTitle-root {
    margin-bottom: 0;

    .MuiTypography-root {
      font-size: 26px;
      font-weight: 400;
      text-align: left;

      span {
        padding-left: 0;
        text-transform: none;
      }

      span + span {
        top: 0;
      }
    }
  }

  .MuiDialogContent-root {
    padding-left: 20px;
    padding-right: 20px;
  }

  .control-chart-format {
    ${to(device.tabletXL)} {
      display: none;
    }
  }

  .control-grid-wrap {
    ${to(device.tabletXL)} {
      flex-direction: column;
    }

    .MuiGrid-item {
      padding-left: 0;
      padding-right: 0;

      ${to(device.materialXS)} {
        padding-top: 20px;
        padding-bottom: 20px;
      }
    }
  }

  ${to(device.tabletXL)} {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    margin: 0;

    .MuiDialogActions-root {
      padding-left: 0;
      padding-right: 0;
    }
  }
`
