// @common
import strings from 'constants/strings'
// @UI
import { Grid } from '@material-ui/core'
import Section from 'modules/common/components/_UI_DUMB/DashboardSection'
import DealCard from 'modules/dashboard/components/DealCard'
// @components
import ErrorText from 'modules/common/components/_UI_DUMB/ErrorText/ErrorText'
import LoadMoreBtn from 'modules/common/components/_UI_SMART/LoadMoreBtn'
// @graphql
import {
  GetDealsInvitesQuery,
  GetDealsInvitesQueryVariables,
} from 'graphql/graphqlTypes'
// @models
import DealModel from 'modules/deal/models/deal'
import { ApolloError } from '@apollo/client'
import { ApolloFetchMoreType } from 'modules/common/types'
import { ApolloQueryResult } from '@apollo/client/core/types'
import SectionEmpty from 'modules/common/components/_UI_DUMB/Section/SectionEmpty'
import { RouteNames } from 'constants/routeNames'
import CreateDeal from 'modules/deal/components/CreateDealWizard'
import React, { useState } from 'react'
import ConditionalLoader from 'modules/common/components/_UI_DUMB/Loaders/ConditionalLoader'
import { LoadingBlocks } from 'modules/common/components/_UI_DUMB/Loaders/Skeletons'

/*******************************************************
 * TYPES
 *******************************************************/
interface Props {
  data: GetDealsInvitesQuery | undefined
  loading: boolean
  error?: ApolloError | undefined
  refetch: (
    variables?: Partial<GetDealsInvitesQueryVariables>,
  ) => Promise<ApolloQueryResult<GetDealsInvitesQuery>>
  fetchMore: ApolloFetchMoreType<
    GetDealsInvitesQuery,
    GetDealsInvitesQueryVariables
  >
  variables: GetDealsInvitesQueryVariables | undefined
  cardPrefixes: {
    subtitle: string
    byLine: string
    user?: 'user' | 'createdBy'
  }
}

/**
 *
 * @param props
 * @constructor
 */
const DealsGrid = ({ data, error, loading, fetchMore }: Props) => {
  /*******************************************************
   * STATE
   *******************************************************/
  const [showDealModal, setShowDealModal] = useState<boolean>(false)
  const toggleDealModal = () => setShowDealModal(!showDealModal)

  const dataMeta = data?.dealsInvites.meta

  const isLoadMoreNeeded = dataMeta?.totalPages
    ? (dataMeta.page || 0) + 1 !== dataMeta.totalPages
    : false

  const loadMoreHandler = () => {
    if (dataMeta) {
      const newPageNum = (dataMeta.page || 0) + 1

      void fetchMore({
        variables: {
          page: newPageNum,
        },
      })
    }
  }

  /*******************************************************
   * RENDER ELEMENTS
   *******************************************************/
  const DealCards = () => {
    if (data?.dealsInvites.data?.length) {
      const dealCards = data.dealsInvites.data.map((invite, key: number) => {
        if (!invite.id || !invite.deal) {
          return null
        }

        // figure out what the main deal image is
        const dealImage = invite.deal.imageDocs?.length
          ? invite.deal.imageDocs[0]?.file?.location
          : undefined

        return (
          <DealCard
            key={invite.id}
            testid={`deal-invites_${invite.id}`}
            title={invite.deal.name}
            image={dealImage}
            invite={invite}
            href={DealModel.dealStatusRouter(
              invite.deal.status,
              invite.deal.id,
            )}
          />
        )
      })
      return <>{dealCards}</>
    }

    return (
      <Grid item xs={12}>
        <SectionEmpty
          title={strings.DASHBOARD_NO_DEALS_INVITES}
          cta={[
            {
              label: strings.DASHBOARD_NO_DEALS_EXPLORE,
              href: `/${RouteNames.DEALS}`,
            },
            {
              type: 'primary',
              label: strings.DASHBOARD_NO_DEALS_CREATE,
              onClick: toggleDealModal,
            },
          ]}
          modal={
            <CreateDeal open={showDealModal} handleClose={toggleDealModal} />
          }
        />
      </Grid>
    )
  }

  /*******************************************************
   * RENDER
   *******************************************************/
  if (error) {
    return <ErrorText errorText={`${strings.GENERIC_ERROR}:`} />
  }

  return (
    <Section margin="md">
      <ConditionalLoader
        condition={!loading}
        loadingComponent={<LoadingBlocks size={'tall'} count={12} />}
      >
        {data && (
          <>
            <Grid container spacing={3}>
              <DealCards />
            </Grid>
            <LoadMoreBtn
              isVisible={isLoadMoreNeeded}
              isLoading={loading}
              onClick={loadMoreHandler}
            />
          </>
        )}
      </ConditionalLoader>
    </Section>
  )
}

export default DealsGrid
