// language=GraphQL
import { gql } from '@apollo/client'
import { NOTIFICATION_ITEM } from '../fragments/notification.fragment'

export const NOTIFICATION_CREATE_SUBSCRIPTION = gql`
  subscription onNotificationCreated {
    notificationCreated {
      ...NotificationItem
    }
  }
  ${NOTIFICATION_ITEM}
`
