// @react
import { ReactElement, useEffect, useState, useContext } from 'react'
import { useLocation, useRouteMatch } from 'react-router'
import { useHistory } from 'react-router-dom'
// @design
import * as SC from './styledComponents/dealSummary'
import { Grid } from '@material-ui/core'
import classNames from 'classnames'
// @constants
import { ImageExtensions } from 'constants/mime'
// @icons
import HospitalityIcon from 'assets/img/industry-icons/hospitality.svg'
import IndustrialIcon from 'assets/img/industry-icons/industrial.svg'
import LandIcon from 'assets/img/industry-icons/land.svg'
import OfficeIcon from 'assets/img/industry-icons/office.svg'
import OtherIcon from 'assets/img/industry-icons/other.svg'
import ResidentialIcon from 'assets/img/industry-icons/residential.svg'
import RetailIcon from 'assets/img/industry-icons/retail.svg'
import PortfolioIcon from 'assets/img/property-type-icons/portfolio.svg'
import SingleIcon from 'assets/img/property-type-icons/single.svg'
// @common
import BlockingCenteredLoader from 'modules/common/components/_UI_DUMB/Loaders/BlockingCenteredLoader'
import BROWSER_HISTORY from 'utils/history'
import CenteredLoader from 'modules/common/components/_UI_DUMB/Loaders/CenteredLoader'
import DragAndDropArea from 'modules/common/components/_UI_DUMB/DragAndDropArea/DragAndDropArea'
import strings from 'constants/strings'
import GlobalHelperModel from 'modules/common/models/globalHelper'
import { SnackbarContext } from 'modules/common/context/snackbar'
// @components
import EditSummaryDialog from 'modules/deal/components/Dialogs/EditSummaryDialog'
import Card from 'modules/common/components/_UI_DUMB/Card'
import Spacer from 'modules/common/components/_UI_DUMB/Spacer'
import { SnackbarTypes } from 'modules/common/components/_UI_DUMB/Snackbars/Snackbar'
import { ContentContainer } from 'modules/common/styledComponents/Global'
import EditingChecker from 'modules/common/components/_FORM_ELEMENTS/EditingChecker'
import { getAdditionalInformationRows } from './additionalInforamtionRows'
// @graphql
import {
  DEAL_ADD_DOCUMENTS,
  DEAL_UPDATE,
} from 'graphql/mutations/deal.mutation'
import {
  DealDocumentInput,
  DealStatus,
  UpdateDealInput,
  DocumentType,
  UpdateDealMutation,
  UpdateDealMutationVariables,
  AddDealDocumentsMutation,
  AddDealDocumentsMutationVariables,
  DealActions,
  Document,
} from 'graphql/graphqlTypes'
import { useMutation } from '@apollo/client'
import { CHANGE_UNREAD_NOTIFS_COUNT } from 'graphql/localCache/localCacheActions'
import { Countries, Provinces } from 'country-and-province'

import { RouteNames } from 'constants/routeNames'
import { DealContext } from 'modules/deal/contexts/deal'

type TParams = { id: string }

const dealIcons = {
  // industry
  Hospitality: {
    icon: HospitalityIcon,
    label: 'Hospitality',
  },
  Industrial: {
    icon: IndustrialIcon,
    label: 'Industrial',
  },
  Land: {
    icon: LandIcon,
    label: 'Land',
  },
  Office: {
    icon: OfficeIcon,
    label: 'Office',
  },
  Other: {
    icon: OtherIcon,
    label: 'Other',
  },
  Residential: {
    icon: ResidentialIcon,
    label: 'Residential',
  },
  Retail: {
    icon: RetailIcon,
    label: 'Retail',
  },

  // property type
  PORTFOLIO: {
    icon: PortfolioIcon,
    label: 'Portfolio',
  },
  SINGLE: {
    icon: SingleIcon,
    label: 'Single',
  },
}

/**
 *
 * @constructor
 */
const DealSummary = (): ReactElement | null => {
  const match = useRouteMatch<TParams>()
  const location = useLocation()
  const { id } = match.params

  /*******************************************************
   * HOOKS
   *******************************************************/
  const [mainImage, setMainImage] = useState<string>('')
  const history = useHistory()
  const snackbar = useContext(SnackbarContext)
  const { data, refetch, loading, error } = useContext(DealContext)

  /*******************************************************
   * GRAPHQL
   *******************************************************/
  /**
   * mutations
   */
  const [updateDealMutation, { loading: isUpdatingDeal }] = useMutation<
    UpdateDealMutation,
    UpdateDealMutationVariables
  >(DEAL_UPDATE)

  const [addDealDocumentsMutation] = useMutation<
    AddDealDocumentsMutation,
    AddDealDocumentsMutationVariables
  >(DEAL_ADD_DOCUMENTS)

  /**
   * effects
   */

  useEffect(() => {
    if (!error) return
    if (error.message === 'Permission denied') {
      const link = `/${RouteNames.DEALS}`
      history.push(link)
    }
  }, [error])

  useEffect(() => {
    if (data) {
      if (data.deal.imageDocs && data.deal.imageDocs.length) {
        setMainImage(data.deal.imageDocs[0]?.file?.location || '')
      }
    }
  }, [data, id])

  if (loading && !!data) {
    return (
      <SC.StyledDealContainer>
        <CenteredLoader size={80} />
      </SC.StyledDealContainer>
    )
  }

  /**
   *
   * @param deal
   */
  const updateDeal = async (deal: UpdateDealInput) => {
    await updateDealMutation({
      variables: {
        deal: {
          ...deal,
          fields: deal.fields?.map((field) => ({
            ...field,
            id: field.id,
          })),
          id,
        },
      },
    })
    BROWSER_HISTORY.push({
      pathname: location.pathname,
      search: '',
    })
  }

  /**
   *
   * @param documents
   * @param dealDocumentType
   */
  const updateDealFilesHandler = async (
    documents: Document[],
    dealDocumentType: DocumentType,
  ) => {
    const documentIds = documents.map((document) => {
      return document.id
    })

    const dealDocumentInput: DealDocumentInput[] = documentIds.map((item) => {
      return {
        id: item,
        type: dealDocumentType,
      }
    })
    if (data) {
      await addDealDocumentsMutation({
        variables: {
          id: id,
          documents: dealDocumentInput,
        },
      })
      refetch && (await refetch())
      snackbar.setMessage({
        type: SnackbarTypes.SUCCESS,
        message: strings.SUCCESS_UPDATE,
        show: true,
        anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
      })
    }
  }

  /**
   *
   * @param location
   */
  const updateMainImage = (location) => {
    setMainImage(location)
  }

  /**
   *
   */
  const shouldShowLoader = loading || isUpdatingDeal
  const dealInactive = data?.deal.status === DealStatus.DEAD
  const canUpload = data?.deal.actions?.includes(DealActions.DEAL_CRUD_DOCS)

  if (!data?.deal) return null

  const deal = data.deal
  const dealIndustry = deal.industry && dealIcons[deal.industry]
  const dealPropertyType = deal.propertyType && dealIcons[deal.propertyType]
  const hasDealCrudDockPermission = deal.actions?.includes(
    DealActions.DEAL_CRUD_DOCS,
  )
  const additionalInformationRows = getAdditionalInformationRows(deal)

  const hasDragAndDropContainer =
    hasDealCrudDockPermission ||
    !!deal.imageDocs?.length ||
    deal.pitchBook ||
    deal.confi ||
    !!deal.otherDocs?.length

  return (
    <ContentContainer data-testid={'deal-single-summary-page'}>
      <SC.TopWrap
        container
        justifyContent="space-between"
        alignItems="flex-start"
      >
        <Grid item xs={12} sm={6} lg={8}>
          <SC.Title variant="h1">{deal.name}</SC.Title>
        </Grid>
      </SC.TopWrap>

      <SC.IconWrapper>
        {dealPropertyType && (
          <SC.DealIconBlock data-testid="deal-summary-property-type">
            <SC.DealIcon
              alt={dealPropertyType.label}
              src={dealPropertyType.icon}
            />
            <SC.DealLabel>{dealPropertyType.label}</SC.DealLabel>
          </SC.DealIconBlock>
        )}
        {dealIndustry && (
          <SC.DealIconBlock data-testid="deal-summary-industry-type">
            <SC.DealIcon alt={dealIndustry.label} src={dealIndustry.icon} />
            <SC.DealLabel>{dealIndustry.label}</SC.DealLabel>
          </SC.DealIconBlock>
        )}
      </SC.IconWrapper>

      <Grid
        container
        spacing={5}
        className={classNames({
          inactive: dealInactive,
        })}
      >
        <SC.ImagesSection
          data-testid="deal-single-summary-deal-files"
          noimages={String(!deal.imageDocs?.length)}
          isempty={String(
            !hasDragAndDropContainer &&
              !(deal.imageDocs && !!deal.imageDocs.length),
          )}
          item
          xs={12}
          sm={12}
          md={6}
          lg={5}
        >
          {deal.imageDocs && !!deal.imageDocs.length && (
            <SC.PreviewImage data-testid="deal-single-summary-deal-images-preview">
              <SC.PreviewImageMain>
                <img alt="main" src={mainImage} />
              </SC.PreviewImageMain>
              <SC.PreviewImageSmallWrap>
                {deal.imageDocs.map(({ id, file }) => (
                  <div
                    key={id}
                    onClick={() => updateMainImage(String(file?.location))}
                    style={{
                      backgroundImage: `url(${String(file?.location)})`,
                    }}
                  />
                ))}
              </SC.PreviewImageSmallWrap>
            </SC.PreviewImage>
          )}
          {hasDragAndDropContainer ? (
            <SC.DragAndDropSection>
              {(hasDealCrudDockPermission || !!deal.imageDocs?.length) && (
                <>
                  <SC.SummarySubtitle>Deal Images</SC.SummarySubtitle>
                  <DragAndDropArea
                    testId={'deal-single-summary-deal-images'}
                    documents={deal.imageDocs as Document[]}
                    removeFileCallback={() => refetch && refetch()}
                    canDeleteDocuments={hasDealCrudDockPermission}
                    fileUpdateCallback={(res) => {
                      void updateDealFilesHandler(res, DocumentType.DEAL_IMAGE)
                    }}
                    canUpload={canUpload}
                    isMultiple={true}
                    accept={ImageExtensions}
                  />
                </>
              )}

              {(hasDealCrudDockPermission || deal.confi) && (
                <>
                  <SC.SummarySubtitle>
                    Confidentiality Agreement
                  </SC.SummarySubtitle>

                  <DragAndDropArea
                    testId={'deal-single-summary-confi'}
                    documents={
                      // @todo 01-22 incorrect typing here, no casting to unkown
                      deal.confi && ([deal.confi] as unknown as Document[])
                    }
                    removeFileCallback={() => refetch && refetch()}
                    canDeleteDocuments={hasDealCrudDockPermission}
                    fileUpdateCallback={async (res) => {
                      void (await updateDealFilesHandler(
                        res,
                        DocumentType.CONFI,
                      ))
                    }}
                    canUpload={canUpload}
                    hideUploader={!!deal.confi}
                    isMultiple={false}
                  />
                </>
              )}
              {(hasDealCrudDockPermission || deal.pitchBook) && (
                <>
                  <SC.SummarySubtitle>Pitchbook</SC.SummarySubtitle>
                  <DragAndDropArea
                    testId={'deal-single-summary-pitchbook'}
                    documents={
                      deal.pitchBook &&
                      // @todo 01-22 incorrect typing here  no casting to unkown
                      ([deal.pitchBook] as unknown as Document[])
                    }
                    removeFileCallback={() => refetch && refetch()}
                    canDeleteDocuments={hasDealCrudDockPermission}
                    fileUpdateCallback={(res) => {
                      void updateDealFilesHandler(res, DocumentType.PITCHBOOK)
                    }}
                    canUpload={canUpload}
                    hideUploader={!!deal.pitchBook}
                    isMultiple={false}
                  />
                </>
              )}
              {(hasDealCrudDockPermission || !!deal.otherDocs?.length) && (
                <>
                  <SC.SummarySubtitle>Deal Docs</SC.SummarySubtitle>
                  <DragAndDropArea
                    testId={'deal-single-summary-deal-docs'}
                    // @todo 01-22 incorrect typing here  no casting to unkown
                    documents={deal.otherDocs as unknown as Document[]}
                    removeFileCallback={() => refetch && refetch()}
                    canDeleteDocuments={hasDealCrudDockPermission}
                    fileUpdateCallback={(res) => {
                      void updateDealFilesHandler(res, DocumentType.DEAL_DOC)
                    }}
                    canUpload={canUpload}
                    isMultiple={true}
                  />
                </>
              )}
            </SC.DragAndDropSection>
          ) : null}
        </SC.ImagesSection>
        <Grid
          item
          xs={12}
          sm={12}
          md={6}
          lg={7}
          data-testid="deal-single-summary-deal-info"
        >
          <Card bodyProps={{ bodyTextSize: '14px' }} title="Address">
            <div>{GlobalHelperModel.checkOnEmptiness(deal.address)}</div>
            <div>
              {GlobalHelperModel.checkOnEmptiness(
                GlobalHelperModel.generateStringFromItems([
                  deal.city,
                  deal.state && Provinces.byCode(deal.state).name,
                  deal.country && Countries.byCode(deal.country).name,
                ]),
              )}
            </div>
            <div>{GlobalHelperModel.checkOnEmptiness(deal.zip)}</div>
          </Card>
          <Spacer height={30} />
          <Card noPadding title="Additional Information">
            {additionalInformationRows.map(({ prop, name, mapFunc }) => {
              // @todo this is clearly unsafe, value is of unknown type
              const value = mapFunc ? mapFunc(deal[prop]) : deal[prop]
              const checkedValue = GlobalHelperModel.checkOnEmptiness(value)

              return (
                <SC.CardRow key={`${prop}`}>
                  <div>{name}:</div>
                  <SC.CardValue>
                    {GlobalHelperModel.safeTotoLowerCase(checkedValue)}
                  </SC.CardValue>
                </SC.CardRow>
              )
            })}
            {deal.fields?.map(({ id, value, name }) => {
              return (
                <SC.CardRow key={`${String(id)}${name}`}>
                  <div>{name}:</div>
                  <SC.CardValue>
                    {GlobalHelperModel.checkOnEmptiness(value)}
                  </SC.CardValue>
                </SC.CardRow>
              )
            })}
          </Card>
          <Spacer height={30} />
          <Card bodyProps={{ bodyTextSize: '14px' }} title="Deal notes">
            <div
              dangerouslySetInnerHTML={{
                __html: GlobalHelperModel.checkOnEmptiness(deal.description),
              }}
            />
          </Card>
        </Grid>
      </Grid>
      <EditingChecker title={strings.EDIT_SUMMARY}>
        <EditSummaryDialog
          id={id}
          deal={deal}
          ctaLabel={strings.SAVE_AND_CLOSE}
          prevValues={{ ...deal }}
          onSubmit={updateDeal}
        />
      </EditingChecker>
      {shouldShowLoader && <BlockingCenteredLoader />}
    </ContentContainer>
  )
}

export default DealSummary
