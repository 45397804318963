import { RouteType, RouteNames } from 'constants/routeNames'
import PageLoader from 'modules/common/components/_UI_SMART/PageLoader'

const routes: RouteType[] = [
  {
    path: `/${RouteNames.CALLBACK}`,
    component: PageLoader,
    exact: false,
    type: 'PUBLIC',
  },
]

const Routes = {
  routes,
}
export default Routes
