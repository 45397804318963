// @design
import * as SC from './styledComponents'
// @graphql
import { useMutation } from '@apollo/client'
import {
  Item,
  ItemActions,
  DealStage,
  ItemStatus,
  ItemUpdateMutation,
  ItemUpdateMutationVariables,
  GetItemQueryVariables,
  GetItemQuery,
} from 'graphql/graphqlTypes'
import { GET_ITEM } from 'graphql/queries/item.query'
import { ITEM_UPDATE } from 'graphql/mutations/item.mutation'
// @common
import { parseISO, format, isPast, addDays } from 'date-fns'
import config from 'constants/config'
import GlobalHelperModel from 'modules/common/models/globalHelper'
// @components
import Card from 'modules/common/components/_UI_DUMB/Card'
import ContactAvatar from 'modules/common/components/_UI_SMART/ContactItem/components/ContactAvatar'
import SwitchboardTooltip from 'modules/common/components/_UI_DUMB/SwitchboardTooltip/SwitchboardTooltip'
import ItemsDetailsVisibilityParameters from '../ItemsDetailsVisibilityParameters'
import useErrorHandler from 'modules/common/hooks/useErrorHandler'

/*******************************************************
 * CONSTANTS
 *******************************************************/
const mapDealStages = {
  [DealStage.DUE_DILIGENCE]: 'Due Diligence',
}
/*******************************************************
 * TYPES
 *******************************************************/
interface PropTypes {
  item: Item
  onEdit: () => void
  testId?: string
}

/**
 *
 * @param item
 * @param onEdit
 * @param testId
 * @constructor
 */
const ItemDetails = ({ item, onEdit, testId }: PropTypes) => {
  /*******************************************************
   * GRAPHQL
   *******************************************************/
  const [updateItemMutation, { loading, error }] = useMutation<
    ItemUpdateMutation,
    ItemUpdateMutationVariables
  >(ITEM_UPDATE)
  useErrorHandler(error)
  /**
   *
   * @param event
   */
  // @todo 12-21 review
  const togglePermissions = async (event) => {
    const teamPermissionValue = event.target.value
    void (await updateItemMutation({
      variables: {
        input: {
          id: item.id,
          permissions: teamPermissionValue,
        },
      },
      update(cache, { data: updateItemMutationData }) {
        const data = cache.readQuery<GetItemQuery, GetItemQueryVariables>({
          query: GET_ITEM,
          variables: {
            id: item.id,
          },
        })

        if (!data?.item) return
        const newData = {
          ...data,
          item: {
            ...data.item,
            permissions: updateItemMutationData?.itemUpdate.permissions,
          },
        }
        cache.writeQuery<GetItemQuery, GetItemQueryVariables>({
          query: GET_ITEM,
          data: newData,
        })
      },
    }))
  }

  /*******************************************************
   * FUNCTIONS
   *******************************************************/
  const renderDueDate = () => {
    if (item.dueDate) {
      const dueDate = format(parseISO(item.dueDate), config.dateMonthFormat)
      return isPast(addDays(parseISO(item.dueDate), 1)) &&
        item.status !== ItemStatus.CLOSED ? (
        <SC.ExpiredBlock>
          <div>{dueDate}</div>
          <SC.ClockIcon />
        </SC.ExpiredBlock>
      ) : (
        dueDate
      )
    }
    return '-'
  }

  /*******************************************************
   * RENDER ELEMENTS
   *******************************************************/
  const createdBy =
    item.createdBy &&
    `${String(item.createdBy.firstName)} ${String(item.createdBy.lastName)}`

  const itemStage = (
    mapDealStages[item.stage] || item.stage
  ).toLocaleLowerCase()

  /*******************************************************
   * RENDER
   *******************************************************/
  return (
    <Card
      title="Details"
      noPadding
      testId={testId}
      footerBtn={{
        onClick: onEdit,
        title: 'Edit Details',
        disabled: !item.actions?.some((action) => {
          return (
            action &&
            [
              ItemActions.ITEM_EDIT_FIELDS_MYTEAM,
              ItemActions.ITEM_EDIT_FIELDS_OWN,
              ItemActions.ITEM_READ_BY_ATTACHED_USERS,
              ItemActions.ITEM_EDIT_DUE_DATE,
              ItemActions.ITEM_EDIT_DUE_DATE_OWN,
            ].includes(action)
          )
        }),
      }}
    >
      <SC.CardRow key="stage">
        <div>Stage:</div>
        <SC.CardValue>
          <SC.Stage>{itemStage}</SC.Stage>
        </SC.CardValue>
      </SC.CardRow>
      <SC.CardRow key="visibility">
        <SC.VisibilityGrid
          container
          alignItems="center"
          justifyContent="space-between"
        >
          <div>Visibility:</div>
          <SC.CardValue>
            <ItemsDetailsVisibilityParameters
              permissions={item.permissions}
              changeHandler={togglePermissions}
              loading={loading}
              canEdit={
                !!item.actions?.some((action) => {
                  if (!action) return false
                  return [
                    ItemActions.ITEM_EDIT_PERMISSIONS_OWN,
                    ItemActions.ITEM_EDIT_PERMISSIONS_MY_TEAM,
                  ].includes(action)
                })
              }
            />
          </SC.CardValue>
        </SC.VisibilityGrid>
      </SC.CardRow>
      <SC.CardRow key="owner">
        <SC.VisibilityGrid
          container
          alignItems="center"
          justifyContent="space-between"
        >
          <div>Owner:</div>

          <SC.CardValue>
            <SwitchboardTooltip
              title={GlobalHelperModel.checkOnEmptiness(createdBy)}
            >
              <SC.AvatarWrapper>
                <ContactAvatar
                  avatar={item.createdBy.avatar?.file?.location}
                  options={{ avatar: { width: 30, height: 30 } }}
                />
              </SC.AvatarWrapper>
            </SwitchboardTooltip>
            <SC.TruncatedBlock>
              {GlobalHelperModel.checkOnEmptiness(createdBy)}
            </SC.TruncatedBlock>
          </SC.CardValue>
        </SC.VisibilityGrid>
      </SC.CardRow>
      <SC.CardRow key="dueDate">
        <div>Due date:</div>
        <SC.CardValue>{renderDueDate()}</SC.CardValue>
      </SC.CardRow>
      <SC.CardRow key="createdAt">
        <div>Created:</div>
        <SC.CardValue>
          {format(parseISO(item.createdDate), config.dateMonthFormat)}
        </SC.CardValue>
      </SC.CardRow>
      <SC.CardRow key="updatedAt" noBorder>
        <div>Updated:</div>
        <SC.CardValue>
          {item.modifiedDate
            ? format(parseISO(item.modifiedDate), config.dateMonthFormat)
            : '-'}
        </SC.CardValue>
      </SC.CardRow>
    </Card>
  )
}

export default ItemDetails
