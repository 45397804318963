// @react
import React from 'react'
import { Grid } from '@material-ui/core'
import FormInput from 'modules/common/components/_FORM_ELEMENTS/HookForm/form-inputs/FormInput'
import FormSelect from 'modules/common/components/_FORM_ELEMENTS/HookForm/form-inputs/FormSelect'
import strings from 'constants/strings'
import {
  debtTypes,
  interestTypes,
  landAreaUnits,
} from 'modules/deal/common/common'
import { InterestType, LandAreaUnit } from 'graphql/graphqlTypes'

const Other = ({ control, formState }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={4}>
        <FormInput
          name="yearOfConstruction"
          label={strings.YEAR_OF_CONSTRUCTION}
          control={control}
          formState={formState}
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={8}>
            <FormInput
              name="landAreaValue"
              label={strings.LAND_AREA}
              control={control}
              formState={formState}
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <FormSelect
              name="landAreaUnit"
              control={control}
              formState={formState}
              defaultValue={
                landAreaUnits.find(({ value }) => value === LandAreaUnit.ACRES)
                  ?.value
              }
              label={' '}
              options={landAreaUnits}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormSelect
          name="interest"
          control={control}
          formState={formState}
          defaultValue={
            interestTypes.find(({ value }) => value === InterestType.FEE_SIMPLE)
              ?.value
          }
          label={strings.INTEREST}
          options={interestTypes}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormSelect
          name="debt"
          control={control}
          defaultValue={debtTypes[0]}
          formState={formState}
          label={strings.DEBT}
          options={debtTypes}
        />
      </Grid>
    </Grid>
  )
}

export default Other
