// @react
import {
  useCallback,
  useReducer,
  useState,
  FormEvent,
  useContext,
  useEffect,
} from 'react'
// @design
import { Step, StepLabel, Stepper } from '@material-ui/core'
import * as SC from './styledComponents/createDealTeam'
// @common
import { RouteNames } from 'constants/routeNames'
// @components
import SwitchboardDialog from 'modules/common/components/_UI_DUMB/ModalDialogs/SwitchboardDialog'
import CreateDealSummary from './Dialogs/CreateDealSummary'
import CreateDealDocuments from './Dialogs/CreateDealDocuments'
import CreateDealManageTeam from './Dialogs/CreateDealManageTeam'
import CreateDealSendDeal from './Dialogs/CreateDealSendDeal'
// @graphql
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import { FormValues } from './Dialogs/CreateDealSummary'
import {
  ADD_DEAL,
  DEAL_LAUNCH_SOLO,
  DEAL_UPDATE,
} from 'graphql/mutations/deal.mutation'
import strings from 'constants/strings'
import {
  AddDealMutation,
  AddDealMutationVariables,
  ContinueSoloMutation,
  ContinueSoloMutationVariables,
  Scalars,
  UpdateDealMutation,
  UpdateDealMutationVariables,
  CreateDealInput,
  GetDealInfoQuery,
  GetDealInfoQueryVariables,
} from 'graphql/graphqlTypes'
import { DealDocs } from '../../common/types'
import { GET_DEALS, GET_DEAL_INFO } from 'graphql/queries/deal.query'
import { useHistory } from 'react-router-dom'
import useErrorHandler from 'modules/common/hooks/useErrorHandler'

/*******************************************************
 * CONSTANTS
 *******************************************************/
const documentStateInitialState: DealDocs = {
  confi: [],
  imageDocs: [],
  otherDocs: [],
  pitchBook: [],
}

/*******************************************************
 * TYPES
 *******************************************************/
interface InjectProps {
  open: boolean
  handleClose: () => void
}

/**
 *
 */
type PropsType = InjectProps

/**
 *
 * @param props
 * @constructor
 */
const CreateDealWizard = ({ handleClose, open }: PropsType) => {
  const initFormState = {}
  const steps = ['Deal Summary', 'Documents', 'Team', 'Send Deal']

  /*******************************************************
   * STATE
   *******************************************************/
  const [activeStep, changeStep] = useState<number>(0)
  const [dealId, changeDealId] = useState<Scalars['ID'] | null>(null)
  const [teamId, changeTeamId] = useState<Scalars['ID'] | null>(null)
  const [stepTitle, changeStepTitle] = useState<string>('Create Deal')
  const [documentsState, setDocumentsState] = useState<DealDocs>(
    documentStateInitialState,
  )

  /*******************************************************
   * CONTEXT
   *******************************************************/

  /*******************************************************
   * HOOKS
   *******************************************************/
  const history = useHistory()

  const reducer = (
    formState,
    // @todo what is the payload here ?
    /* eslint-disable */
    action: { type: string; payload: any | null },
  ) => {
    switch (action.type) {
      case 'setSummary':
        return {
          ...formState,
          summary: {
            ...action.payload,
          },
        }
      case 'reset':
        return {}
    }
  }
  const [formState, dispatchFromState] = useReducer(reducer, initFormState)

  /*******************************************************
   * GRAPHQL
   *******************************************************/
  /**
   * queries
   */
  const { data, error } = useQuery<
    GetDealInfoQuery,
    GetDealInfoQueryVariables
  >(GET_DEAL_INFO, {
    variables: { id: dealId || '' },
    skip: !dealId
  })
  useErrorHandler(error)

  /**
   * mutations
   */
  const [addDeal, { loading, error: addDealMutationError }] = useMutation<
    AddDealMutation,
    AddDealMutationVariables
  >(ADD_DEAL, { refetchQueries: [GET_DEALS] })

  const [updateDeal, { error: updateDealMutationError }] = useMutation<
    UpdateDealMutation,
    UpdateDealMutationVariables
  >(DEAL_UPDATE)

  const [dealContinueSolo, { error: dealLaunchMutationError }] = useMutation<
    ContinueSoloMutation,
    ContinueSoloMutationVariables
  >(DEAL_LAUNCH_SOLO, { refetchQueries: [GET_DEALS] })

//  const [getDeal, dealQuery] = useLazyQuery<
//const [getDeal, dealQuery] = useQuery<
//    GetDealInfoQuery,
//    GetDealInfoQueryVariables
//  >(GET_DEAL_INFO, {
//   variables: { id: dealId || '' },
//  })
  /*******************************************************
   * GRAPHQL
   *******************************************************/


//  useEffect(()=> {
//    if(dealId) {
//      getDeal()
//    }
//  }, [dealId])


//  useEffect(()=> {
//    if(dealQuery.data) {
//      setDealContext(dealQuery)
//    }
//  }, [dealQuery.data])

  /*******************************************************
   * FUNCTIONS
   *******************************************************/
  const setSummary = (formValues: FormValues) => {
    dispatchFromState({ type: 'setSummary', payload: formValues })
  }

  const reset = () => {
    dispatchFromState({ type: 'reset', payload: null })
  }

  /**
   *
   */
  const close = useCallback(() => {
    reset()
    changeStep(0)
    handleClose()
    changeDealId(null)
    changeTeamId(null)
    setDocumentsState(documentStateInitialState)
  }, [handleClose])

  /**
   *
   */
  const handleNext = useCallback(() => {
    changeStep(activeStep + 1)
  }, [activeStep])

  /**
   *
   */
  const handlePrev = useCallback(
    (evt: FormEvent) => {
      evt.preventDefault()

      if (activeStep > 0) {
        changeStep(activeStep - 1)
      }
    },
    [activeStep],
  )

  /**
   *
   */
  const create = async () => {
    await close()
  }

  /**
   *
   */
  const continueSolo = useCallback(async () => {
    close()

    if (!dealId) return

    void (await dealContinueSolo({
      variables: { id: dealId },
    }))

    if (!dealLaunchMutationError) {
      handleClose()
      history.push(
        `/${RouteNames.DEALS}/${RouteNames.DETAILS}/${String(dealId)}/${
          RouteNames.ITEMS
        }`,
      )
    }
  }, [dealContinueSolo, dealId, handleClose, history, dealLaunchMutationError])

  /**
   *
   */
  const saveAndClose = () => {
    close()
    history.push(
      `/${RouteNames.DEALS}/${RouteNames.DETAILS}/${String(dealId)}/${
        RouteNames.SUMMARY
      }`,
    )
  }

  /**
   *
   * @param inputData
   * @param formsData
   */
  const createDeal = async (
    inputData,
    formsData: { initialValues: FormValues },
  ) => {
    setSummary(inputData)

    const touchedData: CreateDealInput = Object.keys(inputData).reduce(
      (acc, item) => {
        if (formsData.initialValues[item] !== inputData[item]) {
          acc[item] = inputData[item]
        }
        return acc
      },
      { industry: inputData.industry, name: inputData.name },
    )

    if (dealId) {
      await updateDeal({
        variables: {
          deal: {
            ...inputData,
            id: dealId,
          },
        },
      })
    } else {
      const { data } = await addDeal({
        variables: {
          DealInput: {
            ...touchedData,
          },
        },
      })

      if (data?.dealCreate) {
        if (data.dealCreate.id) {
          changeDealId(data.dealCreate.id)
        }

        if (data.dealCreate.originatingTeam.id) {
          changeTeamId(data.dealCreate.originatingTeam.id)
        }
      }
    }

    if (!updateDealMutationError && !addDealMutationError) {
      handleNext()
    }
  }

  /*******************************************************
   * RENDER
   *******************************************************/
  return (
    <SwitchboardDialog
      testId="create-deal-modal"
      open={open}
      onClose={close}
      title={stepTitle}
    >
      <SC.StepperWrap>
        {activeStep !== steps.length && (
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        )}
      </SC.StepperWrap>
      {activeStep === 0 && (
        <CreateDealSummary
          ctaLabel={strings.NEXT_STEP}
          prevValues={formState.summary}
          loading={loading}
          onSubmit={createDeal}
          changeStepTitle={changeStepTitle}
        />
      )}
      {activeStep === 1 && dealId && (
        <CreateDealDocuments
          dealId={dealId}
          documentsState={documentsState}
          setDocumentsState={setDocumentsState}
          previousStep={handlePrev}
          changeStepTitle={changeStepTitle}
          onSubmit={handleNext}
        />
      )}
      {activeStep === 2 && teamId && dealId && (
        <CreateDealManageTeam
          teamId={teamId}
          dealId={dealId}
          previousStep={handlePrev}
          onSubmit={handleNext}
          saveAndClose={saveAndClose}
          continueSolo={continueSolo}
          changeStepTitle={changeStepTitle}
          dealLoading={loading}
        />
      )}
      {activeStep === 3 && dealId && (
        <CreateDealSendDeal
          dealId={dealId}
          previousStep={handlePrev}
          create={create}
          changeStepTitle={changeStepTitle}
          saveAndClose={saveAndClose}
          continueSolo={continueSolo}
        />
      )}
    </SwitchboardDialog>
  )
}

export default CreateDealWizard
