// language=GraphQL
import { gql } from '@apollo/client'
import { METRIC_FRAGMENT } from '../fragments/metric.fragment'

export const METRIC_UPDATE = gql`
  mutation UpdateMetric($input: UpdateMetricInput!) {
    metricUpdate(input: $input) {
      ...MetricNode
    }
  }
  ${METRIC_FRAGMENT}
`
