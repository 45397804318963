// @react
import { FC } from 'react'
// @design
import * as SC from '../../styledComponents/selected'
// @components
import { SuggestType } from '.'

interface InjectProps {
  selected: SuggestType[]
}

type PropsType = InjectProps

const Selected: FC<PropsType> = ({ selected }) => {
  const sendToPeople = () => {
    return selected.map((person, idx) => {
      return (
        <SC.SendTo key={idx}>
          <SC.StyledAvatar src={person.avatar} />
          <div>
            <SC.Name>{person.label}</SC.Name>
            <SC.Position>{person.position}</SC.Position>
          </div>
        </SC.SendTo>
      )
    })
  }
  return <SC.Wrap>{sendToPeople()}</SC.Wrap>
}

export default Selected
