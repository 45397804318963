import styled from 'styled-components'
import { device, from } from 'modules/common/styledComponents/MediaQueries'

export const TableWrapper = styled.div.attrs({
  className: 'deal-table-container',
})``

export const MobileStatusText = styled.span`
  ${from(device.tablet)} {
    display: none;
  }
`
