// language=GraphQL
import { gql } from '@apollo/client'

export const INVITE_DECLINE = gql`
  mutation InviteDecline($id: ID!) {
    inviteDecline(id: $id) {
      message
      status
    }
  }
`

export const INVITE_CANCEL = gql`
  mutation InviteCancel($id: ID!) {
    inviteCancel(id: $id) {
      message
      status
    }
  }
`

export const INVITE_ACCEPT = gql`
  mutation InviteAccept($id: ID!, $dealId: ID) {
    inviteAccept(id: $id, dealId: $dealId) {
      message
      status
      deal {
        id
        status
        stage
      }
    }
  }
`

export const DELETE_CONTACT = gql`
  mutation DeleteContact($id: ID!) {
    userContactRemove(id: $id) {
      message
      status
    }
  }
`

export const INVITE_TO_CONTACTS = gql`
  mutation InviteToContacts($input: InviteUserContactsInput!) {
    inviteToContacts(input: $input) {
      message
    }
  }
`

export const INVITE_TO_TEAM = gql`
  mutation InviteToTeam($input: InviteUserTeamInput!) {
    inviteToTeam(input: $input) {
      message
      status
    }
  }
`

export const INVITE_TO_DEAL = gql`
  mutation InviteToDeal($invite: InviteUserDealInput!) {
    inviteToDeal(input: $invite) {
      message
      status
    }
  }
`
