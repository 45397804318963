import styled from 'styled-components'
import colors from 'constants/colors'
import AddIcon from '@material-ui/icons/Add'
import RemoveIcon from '@material-ui/icons/Remove'
import { Typography } from '@material-ui/core'

export const StyledTypographyTitle = styled(Typography)`
  font-weight: 600;
  color: ${colors.dark};
  margin-top: 40px;
`
export const StyledFab = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  background-color: ${colors.yellowishOrange};
  width: 20px;
  height: 20px;
  border-radius: 10px;
  cursor: pointer;
`

export const StyledAddIcon = styled(AddIcon)`
  && {
    width: 20px;
    height: 20px;
  }
`

export const StyledRemoveIcon = styled(RemoveIcon)`
  && {
    width: 20px;
    height: 20px;
  }
`
export const StyledAddButton = styled.div`
  display: flex;
  align-items: end;
  justify-content: flex-end;
  padding: 15px 0px;
`

export const ErrorText = styled.div`
  color: ${colors.grapefruit};
  size: 12px;
  width: 115px !important;
`
