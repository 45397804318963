// @react
import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

const StyledLink = styled(Link)`
  text-decoration: none;
`

const StyledLinkExt = styled.a`
  text-decoration: none;
`

const DownloadFileLink = (props) => {
  if (props.link) {
    return <StyledLinkExt download href={props.link} {...props} />
  }

  return (
    <StyledLink
      download
      target="_blank"
      to={`${process.env.PUBLIC_URL}/mockFiles/newDoc.doc`}
      {...props}
    />
  )
}

export default DownloadFileLink
