import { NavLink } from 'react-router-dom'
import { Badge, ListItemIcon, ListItemText } from '@material-ui/core'
import styled, { css } from 'styled-components'
import colors from 'constants/colors'
import { device, from, to } from 'modules/common/styledComponents/MediaQueries'
import { linkMixin } from 'modules/common/styledComponents/Mixins'

export const SidebarContainer = styled.div`
  border-right: 1px solid ${colors.battleshipGrey};
  overflow: auto;
  flex-shrink: 0;
  background-color: ${colors.dark};
  transition: width 0.225s cubic-bezier(0.4, 0, 0.6, 1) 0s;

  ${from(device.ipadL)} {
    width: 200px;
  }
`

export const SubSidebarContainer = styled(SidebarContainer)`
  padding-bottom: 1em;
`

export const subSidebarTitle = css`
  padding: 20px 20px 60px;

  ${from(device.ipadL)} {
    padding-top: 22px;
    font-size: 20px;
  }
`

export const SidebarLinksWrap = styled.div`
  margin: 16px 10px 0 10px;
  overflow: auto;

  ${from(device.ipadL)} {
    margin: 30px 10px 0 10px;
  }
`

export const subSidebarLink = css`
  ${linkMixin};
  display: flex;
  margin-left: 10px;
  margin-right: 10px;
  padding: 10px 15px;
  font-size: 14px;
  border-radius: 5px;

  &:hover {
    color: ${colors.white};
    background-color: rgba(255, 255, 255, 0.05);
  }

  &.active {
    background-color: rgba(255, 255, 255, 0.2);
  }
`

export const LogoWrap = styled(NavLink)`
  text-decoration: none;
  transition: none;
`

export const Logo = styled.div`
  margin-bottom: 0.5em;
  transition: transform 0.3s ease;

  ${from(device.ipadL)} {
    margin-left: 15px;
    margin-bottom: 0;
  }

  svg {
    display: inline-block;
    vertical-align: middle;
    width: 43px;
  }
`

export const SidebarHeader = styled.div`
  position: relative;
  margin: 20px 16px 0;

  ${from(device.ipadL)} {
    margin-left: 10px;
    margin-right: 10px;
  }
`

export const SidebarLink = styled(NavLink)`
  position: relative;
  width: 100%;
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: ${colors.white};
  transition: 0.05s ease;
  border-radius: 5px;
  font-size: 14px;

  &:hover {
    background-color: rgba(255, 255, 255, 0.05);
  }

  &.active {
    background-color: rgba(255, 255, 255, 0.2);
  }

  &,
  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
  }
`

export const SidebarLinkIcon = styled.div`
  display: inline-flex;
  align-items: center;
  margin-right: 12px;

  svg {
    vertical-align: middle;
    font-size: 20px;
  }
`

export const StyledBadge = styled(Badge).attrs({
  classes: { badge: 'badge' },
})`
  .badge {
    color: ${colors.white};
    font-size: 9px;

    ${to(device.ipadL)} {
      top: -5px;
      font-size: 8px;
    }
  }
`

export const DesktopSidebarWrap = styled.div`
  ${to(device.ipadL)} {
    display: none;
  }

  > div {
    height: 100%;
  }
`

export const MobileSidebarWrap = styled.div`
  ${from(device.ipadL)} {
    display: none;
  }

  > div {
    height: 100%;
  }
`

export const DesktopMenu = styled(SidebarContainer)<{ collapsed?: boolean }>`
  ${to(device.ipadL)} {
    display: none;
  }

  ${(props) =>
    props.collapsed &&
    `
    ${from(device.ipadL)} {
      width: 70px;

      ${Logo} {
        transform: translateX(-13px);
      }
    }
  `}
`

export const MobileMenuToggle = styled.button`
  position: absolute;
  z-index: 1;
  left: 0;
  top: 0;
  width: 4em;
  height: 4em;
  margin: 0.5em auto 1em 1em;
  padding: 0;
  border: 0;
  box-shadow: none;
  outline: none;
  background-color: transparent;

  ${from(device.ipadL)} {
    display: none;
  }
`

export const MobileMenu = styled.div`
  ${from(device.ipadL)} {
    display: none;
  }
`

export const MobileMenuInner = styled(SidebarContainer)`
  width: 240px;
  height: 100%;
  border-right: 0;
`

export const MobileMenuNav = styled.nav`
  padding-bottom: 1em;
`

export const StyledListItemIcon = styled(ListItemIcon)`
  color: currentColor;
`

export const StyledListItemText = styled(ListItemText).attrs({
  classes: { primary: 'label' },
})`
  .label {
    color: currentColor;
    font-weight: 400;
  }
`
