import * as SC from '../../styledComponents/createItem'
import StarIcon from '@material-ui/icons/Star'
import StarBorderIcon from '@material-ui/icons/StarBorder'
import { Typography } from '@material-ui/core'

type PropTypes = {
  value: boolean
  onChange: (value: boolean) => void
  disabled?: boolean
  label: string
}
/**
 *
 * @constructor
 * @return {any}
 * @param props
 */
export const StarredButton = ({
  disabled,
  value,
  onChange,
  label,
}: PropTypes) => {
  return (
    <SC.CheckboxWrapper disabled={disabled}>
      <SC.StyledCheckbox
        id="starred"
        checked={value}
        checkedIcon={<StarIcon color="primary" />}
        icon={<StarBorderIcon />}
        onChange={() => onChange(!value)}
        value={value}
        disabled={disabled}
      />
      <SC.StyledLabel htmlFor="starred" disabled={disabled}>
        <Typography variant="body1" color="secondary">
          {label}
        </Typography>
      </SC.StyledLabel>
    </SC.CheckboxWrapper>
  )
}

export default StarredButton
