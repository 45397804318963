// @react
import {
  ReactElement,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react'
// @common
import Board from 'react-trello'
import BROWSER_HISTORY from 'utils/history'
import colors from 'constants/colors'
import { RouteNames } from 'constants/routeNames'
// @components
import LaneHeaderComponent from 'modules/kanban/components/LaneHeader'
import Card from 'modules/kanban/components/Card'
import { DealStage, ItemStatus, Scalars } from 'graphql/graphqlTypes'
import LaneFooter from '../LaneFooter'
import { ItemContext } from 'modules/item/contexts/item'
import BoardData = ReactTrello.BoardData
import { PropsType as CardPropsType } from 'modules/kanban/components/Card/index'
import DraggableCard = ReactTrello.DraggableCard
import { DealStepperContext } from 'modules/deal/contexts/dealStepper'

/*******************************************************
 * TYPES
 *******************************************************/
export interface CardData extends DraggableCard {
  id: Scalars['ID']
  title: string
  label?: string
  style?: { [key: string]: string }
  draggable?: boolean
  description?: string
  dueDate?: string
  index?: number
  metadata?: {
    sha: string
    status?: ItemStatus
    stage?: DealStage
  }
}

export type Lane = {
  id: string
  title?: string
  label?: string
  style?: { [key: string]: string }
  cards?: CardData[]
}

// CardMetaData labeled by React-trello types
export type CardMetaData = {
  lanes: Lane[]
}

type PropsType = {
  data: BoardData<CardMetaData>
  dealId: Scalars['ID']
  firstLoad: boolean
  isCardDraggable: boolean
  handleMigrateCard: (
    sourceLaneId: DealStage,
    targetLaneId: DealStage,
    itemId,
    position: number,
  ) => void
}

/**
 *
 * @param props
 * @constructor
 */
const Kanban = ({
  data,
  handleMigrateCard,
  dealId,
  firstLoad,
  isCardDraggable,
}: PropsType): ReactElement => {
  /*******************************************************
   * STATE
   *******************************************************/
  const [selectedLaneStage, setSelectedLaneStage] = useState<DealStage | null>(
    null,
  )

  const { dealStage, setDealStage, moveStage } = useContext(DealStepperContext)
  console.log('2--------------', dealStage)
  useEffect(() => {
    console.log('-------------->>>>>>>>>>>>>>>>>>>>>>>', dealStage)
    if (dealStage) {
      changeStage(dealStage)
    }
  }, [dealStage])

  /*******************************************************
   * FUNCTIONS
   *******************************************************/
  /**
   *
   * @param cardId
   */
  const onCardClick = (cardId) => {
    BROWSER_HISTORY.push(
      `/${RouteNames.DEALS}/${RouteNames.DETAILS}/${String(dealId)}/${
        RouteNames.ITEMS
      }/${RouteNames.DETAILS}/${String(cardId)}`,
    )
  }

  const changeStage = (stage) => {
    console.log('>>>>>>>>>>>>>>>>>>>>>', stage)
    const stageEnum: DealStage = DealStage[stage]
    setSelectedLaneStage(stageEnum)
  }

  /**
   *
   * @param cardId
   * @param sourceLaneId
   * @param targetLaneId
   * @param position
   * @param cardDetails
   */
  const handleDragEnd = async (
    cardId,
    sourceLaneId,
    targetLaneId,
    position,
    cardDetails,
  ): Promise<void> => {
    await handleMigrateCard(
      sourceLaneId,
      targetLaneId,
      cardDetails.id,
      position,
    )
  }

  /**
   * renderCard is passed to the react-trello board
   *
   */
  const renderCard = useCallback(
    (props: Omit<CardPropsType, 'firstLoad'>) => {
      return <Card firstLoad={firstLoad} {...props} />
    },
    [firstLoad],
  )

  const components = {
    LaneHeader: LaneHeaderComponent,
    Card: renderCard,
    LaneFooter: LaneFooter,
  }

  /*******************************************************
   * RENDER
   *******************************************************/
  return (
    <>
      <ItemContext.Provider value={{ stage: selectedLaneStage }}>
        <Board
          collapsibleLanes
          data={data}
          hideCardDeleteIcon
          cardDraggable={isCardDraggable}
          onCardClick={onCardClick}
          onLaneClick={changeStage}
          handleDragEnd={handleDragEnd}
          style={{
            backgroundColor: colors.paleGrey,
            fontFamily: 'Open Sans',
            height: '100%',
            paddingBottom: '50px',
          }}
          components={components}
        />
      </ItemContext.Provider>
    </>
  )
}

export default Kanban
