// language=GraphQL
import { gql } from '@apollo/client'
import { DOCUMENT_FRAGMENT } from '../fragments/document.fragment'
import { FILTERS_FRAGMENT } from '../fragments/filters.fragment'

export const GET_DOCUMENTS = gql`
  query GetDocuments(
    $filters: DocumentsFilterInput
    $orderBy: DocumentsOrderByInput
    $page: Int
  ) {
    documents(orderBy: $orderBy, filters: $filters, page: $page) {
      meta {
        page
        totalPages
        pageSize
        allFilters {
          ...FiltersNode
        }
      }
      data {
        ...DocumentNode
        deal {
          id
          name
          actions
        }
      }
    }
  }
  ${FILTERS_FRAGMENT}
  ${DOCUMENT_FRAGMENT}
`
