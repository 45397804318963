// @react
import React, { useEffect } from 'react'
import { Route, Switch } from 'react-router'
import { Router } from 'react-router-dom'
// @design
import { matTheme } from './matTheme'
import { MuiThemeProvider } from '@material-ui/core'
// @common
import history from 'utils/history'
import modules from 'modules'
import { RouteType } from 'constants/routeNames'
// @components
import DocumentTitle from 'react-document-title'
import PublicRoute from 'auth/PublicRoute'
import CenteredLoader from 'modules/common/components/_UI_DUMB/Loaders/CenteredLoader'
// @apollo
import Auth0ProviderWithHistory from '../auth/auth0ProviderWithHistory'
import AuthedApollo from '../auth/AuthedApollo'
import SnackbarProvider from 'modules/common/context/SnackbarProvider'
import { ErrorBoundary } from 'react-error-boundary'
import NotificationsBus from '../modules/notification/components/NotifictionsBus'

const noPrivateRoutes: RouteType[] = modules.routes.filter(
  ({ type }) => type !== 'PRIVATE',
)

/*******************************************************
 * Create Lazy Imports
 *******************************************************/
const AuthedAppLazy = React.lazy(() => import('App/AuthedApp'))
const NotFoundLazy = React.lazy(
  () => import('../modules/common/pages/NotFound'),
)
function ErrorFallback({ error, resetErrorBoundary }) {
  console.log('ErrorFallback')
  return (
    <div role="alert">
      <p>Something went wrong:</p>
      <pre>{error.message}</pre>
      <button onClick={resetErrorBoundary}>Try again</button>
    </div>
  )
}

/**
 *
 * @constructor
 */
const AppComponent = () => {
  useEffect(() => {
    const { pathname } = window.location
    if (pathname !== '/callback') {
      window.sessionStorage.setItem('requiredUrl', pathname)
    }
  }, [])

  return (
    <React.Suspense fallback={<CenteredLoader />}>
      <Router history={history}>
        <DocumentTitle title="Switchboard">
          <Auth0ProviderWithHistory>
            <MuiThemeProvider theme={matTheme}>
              <SnackbarProvider>
                <ErrorBoundary
                  FallbackComponent={ErrorFallback}
                  onReset={() => {
                    console.log('resetting')
                    // reset the state of your app so the error doesn't happen again
                  }}
                >
                  <AuthedApollo>
                    <NotificationsBus />
                    <Switch>
                      {noPrivateRoutes.map(({ type, ...restProps }, index) => {
                        return <PublicRoute key={index} {...restProps} />
                      })}
                      <Route
                        path={'/'}
                        render={() => {
                          return <AuthedAppLazy />
                        }}
                      />
                      <Route component={NotFoundLazy} />
                    </Switch>
                  </AuthedApollo>
                </ErrorBoundary>
              </SnackbarProvider>
            </MuiThemeProvider>
          </Auth0ProviderWithHistory>
        </DocumentTitle>
      </Router>
    </React.Suspense>
  )
}

export default AppComponent
