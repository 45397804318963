// @react
import { ReactElement, useContext } from 'react'
// @design
import { Grid } from '@material-ui/core'
import * as SC from 'modules/common/components/_UI_SMART/SharedTable/styledComponents/sharedTable'
// @components
import ListFilter from 'modules/common/components/_UI_SMART/SharedTable/Filters/ListFilter'
import DatesFilter from 'modules/common/components/_UI_SMART/SharedTable/Filters/DatesFilter'
import { TableContext } from 'modules/common/components/_UI_SMART/SharedTable/ContextTableWrapper'

/*******************************************************
 * TYPE
 *******************************************************/
type PropsType = {
  showDealFilter: boolean
}

/**
 *
 * @param changeCriteria
 * @param showDealFilter
 * @constructor
 */
const ItemsSearch: React.FunctionComponent<PropsType> = ({
  showDealFilter,
}: PropsType): ReactElement => {
  /*******************************************************
   * HOOKS
   *******************************************************/
  const {
    changeCriteria,
    datePickerChangeHandler,
    data: metaData,
  } = useContext(TableContext)

  /*******************************************************
   * PROPS
   *******************************************************/
  const dealFilters = metaData.find((el) => el?.key === 'deal')
  const stageFilters = metaData.find((el) => el?.key === 'stage')
  const statusFilters = metaData.find((el) => el?.key === 'status')
  const createdDateStartFilter = metaData.find(
    (el) => el?.key === 'createdDateStart',
  )?.values[0]?.value

  const createdDateEndFilter = metaData.find(
    (el) => el?.key === 'createdDateEnd',
  )?.values[0]?.value

  /*******************************************************
   * RENDER
   *******************************************************/
  return (
    <SC.Wrap>
      <Grid container spacing={1} alignItems="flex-end">
        {showDealFilter && (
          <SC.Column item>
            <ListFilter
              type={'deal'}
              changeCriteria={changeCriteria}
              filters={dealFilters}
            />
          </SC.Column>
        )}
        <SC.Column item>
          <ListFilter
            type={'stage'}
            changeCriteria={changeCriteria}
            filters={stageFilters}
          />
        </SC.Column>
        <SC.Column item>
          <ListFilter
            type={'status'}
            changeCriteria={changeCriteria}
            filters={statusFilters}
          />
        </SC.Column>
        <SC.Column item>
          <DatesFilter
            createdDateStart={createdDateStartFilter}
            createdDateEnd={createdDateEndFilter}
            changeCriteria={changeCriteria}
            datePickerChangeHandler={datePickerChangeHandler}
          />
        </SC.Column>
      </Grid>
    </SC.Wrap>
  )
}

export default ItemsSearch
