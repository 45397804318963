import GlobalHelperModel from 'modules/common/models/globalHelper'
import { SimplifiedUser, User } from 'graphql/graphqlTypes'

export default class UserModel {
  /**
   *
   */
  private static mandatoryFields = [
    'firstName',
    'lastName',
    'company',
    'title',
    'email',
    'state',
    'city',
    'country',
  ]

  private static socialProviders = {
    linkedin: 'LinkedIn',
    'google-oauth2': 'Google',
    facebook: 'Facebook',
  }

  /**
   *
   * @param user
   */
  public static getMandatoryFieldsStatus(user: User) {
    return this.mandatoryFields.reduce((acc, currentKey) => {
      if (!user || !user[currentKey] || !user[currentKey].length) {
        acc[currentKey] = `${
          GlobalHelperModel.normalizeWords(currentKey) as string
        } is required`
      }
      return acc
    }, {})
  }

  /**
   *
   * @param user
   */
  public static checkIsAllMandatoryFieldsProvided<T>(user: T): boolean {
    return this.mandatoryFields.every((key) => user[key])
  }

  public static nameFormatter = (
    user: SimplifiedUser,
  ): {
    name: string
    title: string
  } => {
    if (!user?.firstName && !user?.firstName) {
      return {
        name: user.email,
        title: 'We have sent an invite to this email address!',
      }
    }

    return {
      name: GlobalHelperModel.cleanStrArray([
        user?.firstName,
        user?.lastName,
      ]).join(' '),
      title: GlobalHelperModel.cleanStrArray(
        [user?.title, user?.company].filter((el) => el),
      ).join(', '),
    }
  }

  public static getSocialName(user): string | undefined {
    return UserModel.socialProviders[user?.provider]
  }
}
