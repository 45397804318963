import styled from 'styled-components'
import SBSearchBar from 'modules/common/components/_UI_SMART/SearchBar'

export const TopSection = styled.div`
  margin-bottom: 50px;
`

export const SearchBar = styled(SBSearchBar)`
  margin-bottom: 12px;
`
