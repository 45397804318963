// @react
import React from 'react'
import { Grid } from '@material-ui/core'
import strings from 'constants/strings'
import {
  debtTypes,
  interestTypes,
  siteAreaUnits,
} from 'modules/deal/common/common'
import { InterestType, SiteAreaUnit } from 'graphql/graphqlTypes'
import FormInput from 'modules/common/components/_FORM_ELEMENTS/HookForm/form-inputs/FormInput'
import FormSelect from 'modules/common/components/_FORM_ELEMENTS/HookForm/form-inputs/FormSelect'

const Hospitality = ({ control, formState }) => {
  const subsectors = [
    'Full service',
    'Limited service',
    'Resort',
    'Conference center',
  ]

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={4}>
        <FormInput
          name="yearOfConstruction"
          label={strings.YEAR_OF_CONSTRUCTION}
          control={control}
          formState={formState}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormSelect
          name="subsector"
          control={control}
          formState={formState}
          label={`${strings.SUBSECTOR}`}
          options={subsectors}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormInput
          name="brand"
          label={strings.BRAND}
          control={control}
          formState={formState}
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <FormInput
          name="hotelManagement"
          label={strings.HOTEL_MANAGEMENT}
          control={control}
          formState={formState}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormInput
          name="noKeys"
          label={strings.NUMBER_OF_ROOMS}
          control={control}
          formState={formState}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormInput
          name="meeting"
          label={strings.NUMBER_EVENT_SPACES}
          control={control}
          formState={formState}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormSelect
          name="interest"
          control={control}
          formState={formState}
          defaultValue={
            interestTypes.find(({ value }) => value === InterestType.FEE_SIMPLE)
              ?.value
          }
          label={strings.INTEREST}
          options={interestTypes}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormSelect
          name="debt"
          control={control}
          defaultValue={debtTypes[0]}
          formState={formState}
          label={strings.DEBT}
          options={debtTypes}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={8}>
            <FormInput
              name="siteAreaValue"
              label={`${strings.SITE_AREA}`}
              control={control}
              formState={formState}
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <FormSelect
              name="siteAreaUnit"
              control={control}
              defaultValue={
                siteAreaUnits.find(({ value }) => value === SiteAreaUnit.ACRES)
                  ?.value
              }
              formState={formState}
              label={' '}
              options={siteAreaUnits}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Hospitality
