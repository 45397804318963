// @design
import {
  FormControl,
  FormHelperText,
  InputLabelProps,
  InputProps,
  makeStyles,
  TextField,
} from '@material-ui/core'

import { FC, useEffect } from 'react'
import { Control, Controller, FieldValues, FormState } from 'react-hook-form'
import colors from 'constants/colors'

/*******************************************************
 * CONST
 *******************************************************/
export const style = () => ({
  wrap: {
    '& .Mui-disabled': {
      fontStyle: 'normal',
      color: `${colors.battleshipGrey}`,
    },
    '& .MuiInput-root.Mui-disabled': {
      opacity: 1,
      borderBottom: `1px solid ${colors.battleshipGrey}`,
      cursor: 'default',
    },
  },
})
const useStyles = makeStyles(style)

/*******************************************************
 * TYPES
 *******************************************************/
export type PropsType = {
  id?: string
  name: string
  label: string
  formState: FormState<FieldValues>
  className?: string
  inputProps?: Partial<InputProps>
  disabled?: boolean
  withoutLabel?: boolean
  InputLabelProps?: { shrink?: boolean }
  control: Control<any> & { formName?: string }
  hidden?: boolean
  wrap?: string
  multiline?: boolean
  rows?: number
  rowsMax?: number
  onChange?: (e) => void
  type?: React.InputHTMLAttributes<unknown>['type']
  value?: unknown
  inputLabelProps?: Partial<InputLabelProps>
  defaultValue?: unknown
}

/**
 *
 * @param classes
 * @param label
 * @param name
 * @param multiline
 * @param rows
 * @param rowsMax
 * @param onChange
 * @param inputProps
 * @param disabled
 * @param withoutLabel
 * @constructor
 */
const FormInput: FC<PropsType> = ({
  formState,
  control,
  name,
  label,
  hidden,
  className,
  wrap,
  multiline,
  rows,
  rowsMax,
  onChange,
  withoutLabel,
  type,
  disabled,
  inputProps,
  inputLabelProps,
  id,
  defaultValue,
}) => {
  /*******************************************************
   * CONST
   *******************************************************/
  const { errors, isSubmitting, isSubmitSuccessful } = formState
  const submitFailed = !isSubmitSuccessful
  const isHidden = hidden ? 'd-none' : ''
  const error = errors[name]
  const isError = (isSubmitting && !!error) || (submitFailed && !!error)
  const errorText = error?.message
  const helperText = submitFailed && typeof error === 'string' ? error : null
  let dataTestId

  /*******************************************************
   * HOOKS
   *******************************************************/
  const classes = useStyles()
  useEffect(() => {
    if (control.formName) {
      dataTestId = `${control.formName}-${name}`
      return
    }
    dataTestId = name || label.toLocaleLowerCase()
  }, [])

  /*******************************************************
   * RENDER
   *******************************************************/
  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue || ''}
      render={(values) => (
        <FormControl
          className={[className, classes[isHidden], wrap, classes.wrap].join(
            ' ',
          )}
          error={isError}
        >
          <TextField
            data-testid={dataTestId}
            id={name || label.toLocaleLowerCase()}
            variant={'standard'}
            style={{ width: '100%' }}
            multiline={multiline}
            rows={rows}
            rowsMax={rowsMax}
            label={withoutLabel ? '' : label}
            disabled={disabled || false}
            error={isError}
            type={type || 'text'}
            InputProps={inputProps}
            InputLabelProps={inputLabelProps}
            defaultValue={defaultValue}
            {...values}
            name={name || label.toLocaleLowerCase()}
            onChange={(e) => {
              if (onChange) {
                onChange(e)
              } else {
                values.onChange(e)
              }
            }}
          />
          {helperText && !errorText && (
            <FormHelperText>{helperText}</FormHelperText>
          )}
          {errorText && isError && <FormHelperText>{errorText}</FormHelperText>}
        </FormControl>
      )}
    />
  )
}

export default FormInput
