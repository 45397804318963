// @react
import React, { useState, useEffect } from 'react'
// @common
import {
  ContactStatus,
  GetNetworkSummaryQuery,
  GetNetworkSummaryQueryVariables,
  GetUserContactsQuery,
  GetUserContactsQueryVariables,
  OnUserRemovedFromContactsSubscription,
  OnUserRemovedFromContactsSubscriptionVariables,
} from 'graphql/graphqlTypes'
import useDebounce from 'modules/common/hooks/useDebounce'
import { config } from 'constants/config'
// @design
import * as ContactItemSC from 'modules/common/components/_UI_SMART/ContactItem/styledComponents/contactItem'
// @components
import LoadMoreBtn from 'modules/common/components/_UI_SMART/LoadMoreBtn'
import ProfileNoContacts from 'modules/user/components/ProfileNoContacts'
import ProfileMyContactsSearch from 'modules/user/components/ProfileMyContactsSearch'
import ConditionalLoader from 'modules/common/components/_UI_DUMB/Loaders/ConditionalLoader'
import UserContactsList from '../../../deal/components/UserRelationsManage/UserContactsList'
import { useQuery, useSubscription } from '@apollo/client'
import {
  GET_NETWORK_SUMMARY,
  GET_USER_CONTACTS,
} from 'graphql/queries/user.query'
import {
  USER_ACCEPTED_INVITATION_TO_CONTACTS,
  USER_REMOVED_FROM_CONTACTS,
} from '../../../../graphql/subscriptions/user.subscription'
import ApolloModel from '../../../common/models/apollo'
import { DataActionType } from '../../../common/types'

/*******************************************************
 * TYPES
 *******************************************************/

/**
 *
 *
 *     WHEN would this state need to be updated?
 *
 *      1. when user deletes contact (local)
 *      2. when contact deletes this user as contact (sub)
 *      3. when contact accepts this user contact invite (sub)
 *
 *
 * @constructor
 */
const ProfileMyContacts = () => {
  /*******************************************************
   * STATE
   *******************************************************/
  const [searchTerm, setSearchTerm] = useState<string>('')
  /*******************************************************
   * HOOKS
   *******************************************************/
  const debouncedSearchValue = useDebounce(searchTerm, config.debounce)
  /**
   * queries
   */
  const { data, loading, fetchMore, refetch } = useQuery<
    GetUserContactsQuery,
    GetUserContactsQueryVariables
  >(GET_USER_CONTACTS, {
    variables: {
      contactStatus: ContactStatus.ACCEPTED,
      filters: { search: searchTerm },
    },
    fetchPolicy: 'network-only',
    nextFetchPolicy: 'cache-first',
  })

  const { refetch: networkSummaryRefetch } = useQuery<
    GetNetworkSummaryQuery,
    GetNetworkSummaryQueryVariables
  >(GET_NETWORK_SUMMARY)

  useSubscription(USER_ACCEPTED_INVITATION_TO_CONTACTS, {
    onSubscriptionData: () => refetch(),
  })

  useSubscription(USER_REMOVED_FROM_CONTACTS, {
    onSubscriptionData: () => refetch(),
  })

  useEffect(() => {
    if (debouncedSearchValue !== undefined) {
      setSearchTerm(debouncedSearchValue)
    }
  }, [debouncedSearchValue])

  /*******************************************************
   * EFFECT HOOKS
   *******************************************************/

  const isLoadMoreNeeded =
    (data?.userContacts.meta?.page || 0) + 1 !==
    (data?.userContacts.meta?.totalPages || 1)

  /*******************************************************
   * FUNCTIONS
   *******************************************************/

  const refetchHandler = async () => {
    await refetch()
    await networkSummaryRefetch()
  }

  /**
   * @todo
   * NOTE: THIS STYLE OF FETCHMORE WITH UPDATE QUERY IS DEPRECATED IN APOLLO 3, WE NEED TO UPDATE IT
   */
  const loadMoreHandler = async () => {
    if (data?.userContacts.meta && fetchMore) {
      const newPageNum = (data?.userContacts.meta.page || 0) + 1

      await fetchMore({
        variables: {
          page: newPageNum,
        },
      })
    }
  }

  /*******************************************************
   * COMPONENTS
   *******************************************************/

  const profileNoContacts = (
    <ProfileNoContacts
      variant="empty-contacts"
      search={
        debouncedSearchValue.length > 0
          ? {
              term: debouncedSearchValue,
              clear: () => setSearchTerm(''),
            }
          : undefined
      }
    />
  )

  /*******************************************************
   * RENDER
   *******************************************************/
  return (
    <>
      <ProfileMyContactsSearch
        value={searchTerm}
        onClear={() => setSearchTerm('')}
        onChange={(e) => setSearchTerm(e.target.value)}
      />
      <ConditionalLoader condition={!loading}>
        <ContactItemSC.ContactListWrap data-testid="profile-my-contacts">
          <ContactItemSC.ContactWrap>
            <UserContactsList
              userContactsData={data?.userContacts.data}
              loadMore={{
                isLoadMoreNeeded: isLoadMoreNeeded,
                loadMoreHandler,
              }}
              refetch={refetchHandler}
              notFound={profileNoContacts}
            />
          </ContactItemSC.ContactWrap>
        </ContactItemSC.ContactListWrap>
      </ConditionalLoader>
    </>
  )
}

export default ProfileMyContacts
