// @react
import { DealIndustry } from 'graphql/graphqlTypes'
import constants from './constants'
import * as SC from './styledComponents/statusTag'
import GlobalHelperModel from 'modules/common/models/globalHelper'

interface Props {
  industry?: DealIndustry
}

/**
 *
 * @param props
 * @constructor
 */
const IndustryTag = ({ industry }: Props) => {
  if (!industry) return null

  /*******************************************************
   * RENDER
   *******************************************************/
  return (
    <SC.Wrapper>
      <SC.StyledTag color={constants[industry]}>
        {GlobalHelperModel.capitalize(industry)}
      </SC.StyledTag>
    </SC.Wrapper>
  )
}

export default IndustryTag
