// @react
import { useCallback, useState, useEffect } from 'react'
// @design
import { DialogActions, DialogContent, Grid } from '@material-ui/core'
import * as SC from 'modules/deal/components/CreateDealWizard/styledComponents/createDealSummary'
import * as ButtonsSC from 'modules/common/components/_UI_DUMB/Button/styledComponents'
// @common
import { yupResolver } from '@hookform/resolvers/yup'
import { useForm } from 'react-hook-form'
import validationSchema from 'modules/deal/utils/validation'
// @components
import CenteredLoader from 'modules/common/components/_UI_DUMB/Loaders/CenteredLoader'
import { Tenancy, PropertyType, RentableAreaUnit } from 'graphql/graphqlTypes'
import DealSummaryForm from 'modules/deal/components/DealSummaryForm'
import { propertyTypeFields } from 'modules/deal/common/common'

/******************************************************
 * TYPE
 *******************************************************/
export type FormValues = {
  name: string
  industry: string
  description: string
  address?: string
  propertyType: PropertyType
  country?: string
  state?: string
  city?: string
  zip?: string
  yearOfConstruction?: number | null
  rentableAreaValue?: number | null
  rentableAreaUnit?: RentableAreaUnit | null
  numberOfProperties?: number | null
  tenancy?: Tenancy | null
  fields?: { id?: string; name?: string; value?: string }[]
}

interface SelfProps {
  ctaLabel: string
  onSubmit: (data: FormValues, { initialValues: FormValues }) => Promise<void>
  loading: boolean
  prevValues?: FormValues
  changeStepTitle: (title: string) => void
}

type PropsType = SelfProps

/**
 *
 * @param loading
 * @param ctaLabel
 * @param onSubmit
 * @param prevValues
 * @param changeStepTitle
 * @constructor
 */
const CreateDealSummary = ({
  loading,
  ctaLabel,
  onSubmit,
  prevValues,
  changeStepTitle,
}: PropsType) => {
  /*******************************************************
   * PROPS
   *******************************************************/

  const initialFormValues: FormValues = {
    name: '',
    industry: '',
    description: '',
    address: undefined,
    propertyType: PropertyType.SINGLE,
    country: '',
    state: '',
    city: '',
    zip: undefined,
    yearOfConstruction: undefined,
    rentableAreaValue: undefined,
    rentableAreaUnit: undefined,
    numberOfProperties: undefined,
    tenancy: undefined,
    ...prevValues,
  }

  /*******************************************************
   * STATE
   *******************************************************/
  const [optionalOpen, changeOpenState] = useState<boolean>(false)
  const [dealData, setDealData] = useState<FormValues>(initialFormValues)

  const toggleOptionalView = useCallback(
    () => changeOpenState(!optionalOpen),
    [optionalOpen],
  )

  /*******************************************************
   * HOOKS
   *******************************************************/
  useEffect(() => {
    changeStepTitle('Create Deal')
  }, [])

  const changeDealData = useCallback(
    (value, key, callback?) => {
      callback && callback()
      setDealData({
        ...dealData,
        [key]: value,
      })
    },
    [dealData],
  )

  /*******************************************************
   * REACT HOOK FORM
   *******************************************************/
  // eslint-disable-next-line @typescript-eslint/unbound-method
  const { handleSubmit, watch, control, formState, setValue } = useForm({
    defaultValues: initialFormValues,
    resolver: yupResolver(validationSchema),
  })

  /*******************************************************
   * FUNCTIONS
   *******************************************************/
  const resetFields = (fieldKey) => {
    const resetData = propertyTypeFields[fieldKey].reduce((acc, item) => {
      acc[item] = initialFormValues[item]
      return acc
    }, {})
    setDealData({
      ...dealData,
      ...resetData,
    })
  }

  /*******************************************************
   * RENDER
   *******************************************************/
  return (
    <form
      onSubmit={handleSubmit((data) => {
        const summaryFormData = {
          ...data,
          fields: data.fields?.map(({ id, ...field }) => field),
        }
        return onSubmit(summaryFormData, { initialValues: initialFormValues })
      })}
    >
      <DialogContent style={{ paddingBottom: '50px' }}>
        <DealSummaryForm
          {...{
            control,
            formState,
            changeDealData,
            resetFields,
            toggleOptionalView,
            optionalOpen,
            watch,
            isEdit: false,
            setValue,
          }}
        />
      </DialogContent>
      <DialogActions>
        <SC.Wrap>
          <Grid container justifyContent="center" spacing={3}>
            <Grid item xs={12} sm={6}>
              <ButtonsSC.SubmitBtn
                disabled={loading}
                variant="contained"
                color="primary"
                type="submit"
              >
                {loading ? <CenteredLoader size={25} /> : ctaLabel}
              </ButtonsSC.SubmitBtn>
            </Grid>
          </Grid>
        </SC.Wrap>
      </DialogActions>
    </form>
  )
}

export default CreateDealSummary
