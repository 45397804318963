/*******************************************************
 * NOTE:
 * This component is instead meant to be used "inline",
 * usually as a part of <InlineForm> or when you need a
 * standalone/separate component with more control over
 * various events, presentation and overall behavior.
 * <InlineForm> internally also relies on react-hook-form
 * and generates Controller wrappers for the "inline"
 * inputs (like this one) that were passed in.
 *
 * This component has the "regular" counterpart (./index)
 * which is instead used as a part of react-hook-form.
 * The main differences between "regular" and "inline" is
 * that the "regular" one comes wrapped with RHF's Controller
 * whereas "inline" one gets wrapped by <InlineForm> when
 * it clones the children.
 *
 * Ideally, everything this "inline" component does should
 * also be handled by the "regular" counterpart with potentially
 * some sort of "inline" (boolean) prop passed in. However,
 * that would also make the final component code messy since
 * the markup differences are big and essentially require either
 * a fully modular component, where we can somehow only use bits
 * we're interested in, or keeping the duplicate as we do now since
 * the differences in markup and behavior are so big that it's
 * justified.
 *******************************************************/

// @react
import React, { useEffect, useState, useRef } from 'react'
// @design
import {
  FormControl,
  FormHelperText,
  InputAdornment,
  makeStyles,
  TextField,
} from '@material-ui/core'
import colors from 'constants/colors'
// @common
import DayPickerInput from 'react-day-picker/DayPickerInput'
import dateFnsFormat from 'date-fns/format'
import dateFnsParse from 'date-fns/parse'
import { isToday } from 'date-fns'
import { DateUtils } from 'react-day-picker'
import config from 'constants/config'
import calendarIcon from 'assets/img/customIcons/calendar.svg'
import FreeDatePickerArrow from 'modules/common/components/_FORM_ELEMENTS/FreeDatePicker/components/FreeDatePickerArrow'

/*******************************************************
 * CONST
 *******************************************************/
export const style = () => ({
  wrap: {
    width: '100%',
    marginBottom: '10px',
  },
  icon: {
    width: 15,
    height: 15,
  },
  rightAlign: {
    '& .DayPickerInput-Overlay': {
      right: 0,
      left: 'auto',
    },
  },
  fullWidth: {
    '& .DayPickerInput': {
      width: '100%',
    },
  },
})

const todaySelected = {
  backgroundColor: colors.yellowishOrange,
  color: colors.white,
}

const todayNotSelected = {
  color: colors.dark,
}
const FORMAT = 'MMMM do, yyyy'
const useStyles = makeStyles(style)

/*******************************************************
 * TYPES
 *******************************************************/
export type PropsType = {
  label: string
  className?: string
  disabled?: boolean
  withoutLabel?: boolean
  isRightAlign?: boolean
  isFullWidth?: boolean
  name: string
  value?: string
  isSubmitting?: boolean
  isSubmitSuccessful?: boolean
  error?: string
  hidden?: boolean
  helperText?: string
  errorText?: string
  onChange?: (arg?: string) => void
}

/**
 *
 * @param label
 * @param name
 * @param onChange
 * @param isSubmitting
 * @param isSubmitSuccessful
 * @param disabled
 * @param value
 * @param error
 * @param withoutLabel
 * @param isRightAlign
 * @param isFullWidth
 * @param hidden
 * @param helperText
 * @param errorText
 * @param className
 * @constructor
 */
const FormDatepicker: React.FunctionComponent<PropsType> = ({
  label,
  name,
  onChange,
  isSubmitting,
  isSubmitSuccessful,
  disabled,
  value,
  error,
  withoutLabel,
  isRightAlign,
  isFullWidth,
  hidden,
  helperText,
  errorText,
  className,
}) => {
  /*******************************************************
   * STATE
   *******************************************************/
  const [showState, setShowState] = useState(false)
  const [currentValue, setCurrentValue] = useState<Date | undefined>(new Date())

  /*******************************************************
   * CONST
   *******************************************************/
  const submitFailed = !isSubmitSuccessful
  const isError = (isSubmitting && !!error) || (submitFailed && !!error)
  const isHidden = hidden ? 'd-none' : ''
  const isTodayValue = isToday(currentValue || new Date())

  const modifiersStyles = {
    selected: {
      backgroundColor: colors.yellowishOrange,
      color: colors.white,
    },
    today: isTodayValue ? todaySelected : todayNotSelected,
  }

  /*******************************************************
   * HOOKS
   *******************************************************/
  const ref = useRef<any>(null)
  const classes = useStyles()

  useEffect(() => {
    setCurrentValue(value ? parseDate(value, config.isoDateFormat) : new Date())
  }, [value])

  const eventHandler = () => ref.current?.hideAfterDayClick()

  useEffect(() => {
    if (ref.current?.state?.showOverlay) {
      window.addEventListener('click', eventHandler)
    }

    return () => {
      window.removeEventListener('click', eventHandler)
    }
  }, [ref.current?.state?.showOverlay])

  /*******************************************************
   * FUNCTIONS
   *******************************************************/
  /**
   *
   * @param str
   * @param format
   */
  const parseDate = (str, format) => {
    const parsed = dateFnsParse(str, format, new Date())
    if (DateUtils.isDate(parsed)) {
      return parsed
    }
    return undefined
  }

  /**
   *
   * @param date
   * @param format
   */
  const formatDate = (date, format) => {
    return dateFnsFormat(date, format)
  }

  /*******************************************************
   * RENDER
   *******************************************************/
  return (
    <FormControl
      className={[
        className,
        isHidden,
        classes.wrap,
        isRightAlign && classes.rightAlign,
        isFullWidth && classes.fullWidth,
      ].join(' ')}
      error={isError}
    >
      <div
        onClick={(e) => e.stopPropagation()}
        className="DayPickerComponent"
        data-testid="datepicker"
      >
        <DayPickerInput
          dayPickerProps={{
            modifiersStyles,
            showOutsideDays: true,
            navbarElement: <FreeDatePickerArrow isSingle />,
            fixedWeeks: true,
          }}
          onDayPickerShow={() => setShowState(true)}
          onDayPickerHide={() => setShowState(false)}
          hideOnDayClick
          keepFocus={true}
          onClick={() => ref.current?.handleInputFocus()}
          ref={ref}
          formatDate={formatDate}
          parseDate={parseDate}
          placeholder={currentValue && `${dateFnsFormat(currentValue, FORMAT)}`}
          format={FORMAT}
          value={
            value && currentValue
              ? `${dateFnsFormat(currentValue, FORMAT)}`
              : undefined
          }
          onDayChange={(e) => {
            onChange &&
              onChange(e ? formatDate(e, config.isoDateFormat) : undefined)
          }}
          //@todo: fix warning
          component={(props) => {
            return (
              <TextField
                {...props}
                name={name || label.toLowerCase()}
                focused={showState}
                disabled={disabled}
                label={withoutLabel ? undefined : label}
                onClick={() => ref.current?.hideAfterDayClick()}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <img alt="" className={classes.icon} src={calendarIcon} />
                    </InputAdornment>
                  ),
                }}
              />
            )
          }}
        />
      </div>
      {helperText && !errorText && (
        <FormHelperText>{helperText}</FormHelperText>
      )}
      {errorText && isError && <FormHelperText>{errorText}</FormHelperText>}
    </FormControl>
  )
}

export default FormDatepicker
