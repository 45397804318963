import colors from 'constants/colors'
import { device, to } from 'modules/common/styledComponents/MediaQueries'
import { Typography } from '@material-ui/core'
import styled from 'styled-components'

export const Header = styled.div`
  display: flex;
  border: 1px solid ${colors.veryLightBlue};
  justify-content: space-between;
  align-items: center;
  padding: 20px 25px;
`

export const Text = styled.div`
  display: flex;
  align-items: center;

  ${to(device.mobileL)} {
    order: 2;
  }
`

export const Actions = styled.div`
  display: flex;
  align-items: center;
`

export const Action = styled.span`
  margin: 0;
  color: ${(props) =>
    props.disabled ? colors.lightGreyBlue : colors.yellowishOrange};
  font-size: 12px;
  font-weight: bold;
  user-select: none;
  cursor: pointer;
  transition: color 0.3s ease;

  &:hover {
    color: ${colors.dusk};
  }
`

export const Separator = styled.span`
  display: inline-block;
  margin: 0 10px;
  color: ${colors.battleshipGrey};
`

export const Title = styled(Typography)`
  && {
    margin-right: 17px;
    font-size: 1em;
    font-weight: 600;
    color: ${colors.dark};

    ${to(device.mobileL)} {
      display: none;
    }
  }
`

export const Wrap = styled.div`
  & .notification-text {
    font-weight: 600;
    color: ${colors.dark};
    font-size: 12px;
  }
  background-color: ${colors.paleGrey};
  ${(props) =>
    props.seen &&
    `background-color: ${colors.white};
        & .notification-text {
            font-weight: normal;
        }
    `};
`

export const CenteredError = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
`
export const Oval = styled.span`
  width: 10px;
  height: 10px;
  margin: 20px 10px 20px -20px;
  border-radius: 50%;
  background-color: ${colors.yellowishOrange};
`
