import { gql } from '@apollo/client'

export const MESSAGE_ITEM = gql`
  fragment MessageItem on Message {
    id
    body
    createdDate
    modifiedDate
    read
    createdBy {
      id
      firstName
      lastName
      avatar {
        id
        file {
          id
          location
          versionId
        }
      }
    }
    user {
      id
      firstName
      lastName
      avatar {
        id
        file {
          id
          location
          versionId
        }
      }
    }
  }
`

export const MESSAGE_META = gql`
  fragment MessageMeta on MessagesMeta {
    count
    unreadCount
    filters {
      user
    }
    orderBy {
      value
    }
    page
    pageSize
    totalPages
  }
`
