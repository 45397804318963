// @react
import React from 'react'
import { Grid } from '@material-ui/core'
import strings from 'constants/strings'
import FormSelect from 'modules/common/components/_FORM_ELEMENTS/HookForm/form-inputs/FormSelect'
import FormInput from 'modules/common/components/_FORM_ELEMENTS/HookForm/form-inputs/FormInput'
import {
  debtTypes,
  interestTypes,
  zoningTypes,
  landAreaUnits,
} from 'modules/deal/common/common'
import { Entitlements, InterestType, LandAreaUnit } from 'graphql/graphqlTypes'
import GlobalHelperModel from 'modules/common/models/globalHelper'

const Land = ({ control, formState }) => {
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={4}>
        <Grid container spacing={2}>
          <Grid item xs={6} sm={8}>
            <FormInput
              name="landAreaValue"
              label={strings.LAND_AREA}
              control={control}
              formState={formState}
            />
          </Grid>
          <Grid item xs={6} sm={4}>
            <FormSelect
              name="landAreaUnit"
              control={control}
              formState={formState}
              defaultValue={
                landAreaUnits.find(({ value }) => value === LandAreaUnit.ACRES)
                  ?.value
              }
              label={' '}
              options={landAreaUnits}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormSelect
          name="zoning"
          control={control}
          formState={formState}
          label={strings.ZONING}
          options={zoningTypes}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormSelect
          name="entitlements"
          control={control}
          formState={formState}
          label={strings.ENTITLEMENTS}
          options={Object.values(Entitlements).map((entitlement) => ({
            value: entitlement,
            label: GlobalHelperModel.normalizeWords(entitlement) || '',
          }))}
        />
      </Grid>

      <Grid item xs={12} sm={4}>
        <FormSelect
          name="interest"
          defaultValue={
            interestTypes.find(({ value }) => value === InterestType.FEE_SIMPLE)
              ?.value
          }
          control={control}
          formState={formState}
          label={strings.INTEREST}
          options={interestTypes}
        />
      </Grid>
      <Grid item xs={12} sm={4}>
        <FormSelect
          name="debt"
          control={control}
          formState={formState}
          defaultValue={debtTypes[0]}
          label={strings.DEBT}
          options={debtTypes}
        />
      </Grid>
    </Grid>
  )
}

export default Land
