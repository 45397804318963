// @react
import { useEffect, useMemo, useState } from 'react'
import { useLocation } from 'react-router'
import { useContext } from 'react'
// @design
import { InputAdornment } from '@material-ui/core'
import * as SC from '../styledComponents'
// @components
import NotFound from 'modules/common/pages/NotFound'
import CenteredLoader from 'modules/common/components/_UI_DUMB/Loaders/CenteredLoader'
import NumberFormat from 'modules/common/components/_FORM_ELEMENTS/NumberFormat'
// @hook-form
import InlineForm from 'modules/common/components/_FORM_ELEMENTS/HookForm/inline/inline-form'
import FormInputInline from 'modules/common/components/_FORM_ELEMENTS/HookForm/form-inputs/FormInput/inline'
import FormSelectInline from 'modules/common/components/_FORM_ELEMENTS/HookForm/form-inputs/FormSelect/inline'
import validation from '../validation'
import { yupResolver } from '@hookform/resolvers/yup'
import { InlineDoubleStyled } from 'modules/common/components/_FORM_ELEMENTS/HookForm/inline/inline-form/styledComponents/inlineForm'
// @common
import strings from 'constants/strings'
import { DealContext } from 'modules/deal/contexts/deal'
import { pick } from 'utils/utils'
// @graphql
import { METRIC_UPDATE } from 'graphql/mutations/metric.mutation'
import { GET_METRIC } from 'graphql/queries/metric.query'
import { useMutation, useQuery } from '@apollo/client'
import {
  Currency,
  DealActions,
  GetMetricQuery,
  GetMetricQueryVariables,
  Metric,
  Period,
  UpdateMetricMutation,
  UpdateMetricMutationVariables,
} from 'graphql/graphqlTypes'

/**
 *
 * @constructor
 */
const Metrics = () => {
  /***************
   * CONSTANTS
   ***************/
  const metricFields = [
    'ltv',
    'grossUnleveredIrr',
    'grossLeveredIrr',
    'netLeveredIrr',
    'multipleOnInvestedCapital',
    'grossAsset',
    'netAsset',
    'currency',
    'capRate',
    'number',
    'periodType',
  ]

  /*******
   * HOOKS
   *******/
  const location = useLocation()
  const dealId = location.pathname.split('/')[3]
  const queryResult = useContext(DealContext)

  /*******************************************************
   * GRAPHQL
   *******************************************************/
  const { data, error, loading } = useQuery<
    GetMetricQuery,
    GetMetricQueryVariables
  >(GET_METRIC, {
    variables: { dealId },
    fetchPolicy: 'network-only',
  })
  const [metricsUpdate] = useMutation<
    UpdateMetricMutation,
    UpdateMetricMutationVariables
  >(METRIC_UPDATE)

  const [values, setValues] = useState<Partial<Metric> | undefined>()
  useEffect(() => {
    if (values === undefined && data?.metric) {
      setValues(pick(data?.metric, metricFields))
    }
  }, [data?.metric])

  /*******************************************************
   * FUNCTIONS
   *******************************************************/
  const submitHandler = async (formData) => {
    const newData = { ...formData }
    setValues(formData)
    await metricsUpdate({
      variables: { input: { ...newData, id: Number(data?.metric.id) } },
    })
  }
  /*******************************************************
   * RENDER ELEMENTS
   *******************************************************/
  const disabledEdit =
    (queryResult.data?.deal.actions?.indexOf(DealActions.DEAL_METRIC_EDIT) ||
      0) < 0

  const renderMetrics = () => (
    <SC.Wrapper data-testid="transaction-targets">
      <InlineForm
        disabled={disabledEdit}
        editButtonText={strings.MT_EDIT_BUTTON}
        title={strings.MT_TITLE}
        onSubmit={submitHandler}
        defaultValues={values}
        resolver={yupResolver(validation)}
      >
        <FormInputInline
          label={strings.MT_LTV}
          name={'ltv'}
          inputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
            inputComponent: NumberFormat,
          }}
        />
        <FormInputInline
          label={strings.MT_GROSS_UNLEVERED_IRR}
          name={'grossUnleveredIrr'}
          inputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
            inputComponent: NumberFormat,
          }}
        />
        <FormInputInline
          label={strings.MT_GROSS_LEVERED_IRR}
          name={'grossLeveredIrr'}
          inputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
            inputComponent: NumberFormat,
          }}
        />
        <FormInputInline
          label={strings.MT_NET_LEVERED_IRR}
          name={'netLeveredIrr'}
          inputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
            inputComponent: NumberFormat,
          }}
        />
        <FormInputInline
          label={strings.MT_MULTIPLE_ON_INVESTED_CAPITAL}
          name={'multipleOnInvestedCapital'}
          inputProps={{
            inputComponent: NumberFormat,
          }}
        />
        <FormInputInline
          label={strings.MT_GROSS_ASSET}
          name={'grossAsset'}
          inputProps={{
            endAdornment: <InputAdornment position="end">$</InputAdornment>,
            inputComponent: NumberFormat,
          }}
        />
        <FormInputInline
          label={strings.MT_NET_ASSET}
          name={'netAsset'}
          inputProps={{
            endAdornment: <InputAdornment position="end">$</InputAdornment>,
            inputComponent: NumberFormat,
          }}
        />
        <FormSelectInline
          data-testid={'deal-datesmetrics-currency'}
          label={strings.MT_CURRENCY}
          name={'currency'}
          options={Object.values(Currency)}
        />
        <FormInputInline
          label={strings.MT_CAP_RATE}
          name={'capRate'}
          inputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
            inputComponent: NumberFormat,
          }}
        />
        <InlineDoubleStyled container={true} label={strings.MT_HOLDING_PERIOD}>
          <FormInputInline
            name={'number'}
            inputProps={{ inputComponent: NumberFormat }}
          />
          <FormSelectInline
            data-testid={'deal-datesmetrics-periodType'}
            name={'periodType'}
            options={Object.values(Period)}
          />
        </InlineDoubleStyled>
      </InlineForm>
    </SC.Wrapper>
  )

  const metrics = useMemo(renderMetrics, [pick(data?.metric, metricFields)])

  /*******************************************************
   * RENDER
   *******************************************************/
  if (loading) return <CenteredLoader />

  if (!data?.metric && error) {
    return <NotFound />
  }
  return <>{metrics}</>
}

export default Metrics
