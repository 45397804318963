import { ChangeEvent } from 'react'
import strings from 'constants/strings'
import * as SC from 'modules/user/styledComponents/profileContacts'

type PropsType = {
  value: string
  onClear: () => void
  onChange: (e: ChangeEvent<HTMLInputElement>) => void
}

const ProfileMyContactsSearch = ({ value, onClear, onChange }: PropsType) => (
  <SC.SearchBar
    value={value}
    placeholder={strings.CONTACT_SEARCH}
    onClear={onClear}
    onChange={onChange}
  />
)

export default ProfileMyContactsSearch
