// @react
import { parseISO, isAfter } from 'date-fns'
import {
  MovableCardWrapper,
  CardRightContent,
  CardTitle,
  Detail,
} from 'react-trello/dist/styles/Base'
import GlobalHelperModel from 'modules/common/models/globalHelper'
import { ItemStatus, ItemPermission } from 'graphql/graphqlTypes'
import colors from 'constants/colors'
import { Slide } from '@material-ui/core'
import { FiberManualRecord } from '@material-ui/icons'
import { AccessTimeStyled, PeopleIcon } from './styledComponents'

/*******************************************************
 * TYPES
 *******************************************************/
type SelfType = {
  id: string
  title: string
  label?: string
  permissions: ItemPermission
  showDeleteButton?: boolean
  onDelete?: () => void
  onClick?: () => void
  style?: { [key: string]: string }
  className?: string
  description?: string
  tags?: string[]
}

type MetaType = {
  dueDate: string // date string
  cardDraggable: boolean
  firstLoad: boolean
}

export type PropsType = SelfType & MetaType

/**
 *
 * @param props
 * @constructor
 */
const Card = ({
  id,
  title = 'no title',
  label,
  permissions,
  style,
  onClick,
  className,
  dueDate,
  cardDraggable,
  firstLoad,
}: PropsType) => {
  /*******************************************************
   * CONSTANTS
   *******************************************************/
  const parsedDate = parseISO(dueDate)
  const isOverdue = parsedDate && isAfter(new Date(), parsedDate)

  const customStyle = { ...style, background: colors.paleGrey, border: '0px' }

  /*******************************************************
   * RENDER COMPONENT
   *******************************************************/
  const renderContent = () => (
    <MovableCardWrapper
      data-id={id}
      onClick={onClick}
      style={customStyle}
      className={className}
    >
      <>
        <Detail draggable={cardDraggable} style={{ display: 'flex' }}>
          <CardTitle style={{ fontSize: '12px', fontStyle: 'initial' }}>
            {GlobalHelperModel.truncateText(title, 25)}
          </CardTitle>
          <CardRightContent style={{ fontSize: '14px' }}>
            {permissions === ItemPermission.BOTH_TEAMS && <PeopleIcon />}
            {isOverdue && label !== ItemStatus.CLOSED && <AccessTimeStyled />}
            <FiberManualRecord
              fontSize={'inherit'}
              htmlColor={
                label === ItemStatus.CLOSED
                  ? colors.lightGreyBlue
                  : colors.green
              }
            />
          </CardRightContent>
        </Detail>
      </>
    </MovableCardWrapper>
  )

  /*******************************************************
   * RENDER
   *******************************************************/
  if (!firstLoad) return renderContent()

  return (
    <Slide direction="up" in>
      {renderContent()}
    </Slide>
  )
}

export default Card
