// @react
import React from 'react'
import { useRef, useEffect } from 'react'
// @design
import { Typography } from '@material-ui/core'
import * as SC from '../styledComponents/itemsDetailsComments'
// @common
import { parseISO, formatDistanceToNowStrict } from 'date-fns'
import { CommentActions, Scalars, Comment } from 'graphql/graphqlTypes'

type PropsType = {
  comments: (Comment | null)[]
  loadingMore: boolean
  onEditAction?: (id: Scalars['ID'], text: string) => void
  onDeleteAction?: (id: Scalars['ID']) => void
}

/**
 *
 * @param comments
 * @param loadingMore
 * @param onDeleteAction
 * @param onEditAction
 * @constructor
 */
const ItemsDetailsComments = ({
  comments,
  loadingMore,
  onDeleteAction,
  onEditAction,
}: PropsType) => {
  /*******************************************************
   * PROPS
   *******************************************************/
  const lastItem = comments.length && [...comments].pop()

  /*******************************************************
   * HOOKS
   *******************************************************/
  const lastMessageRef = useRef<HTMLDivElement>(null)

  /*******************************************************
   * USEEFFECT HOOKS
   *******************************************************/
  useEffect(() => {
    if (comments && Array.isArray(comments)) {
      loadingMore &&
        lastMessageRef.current?.scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        })
    }
  }, [comments, lastMessageRef])

  /*******************************************************
   * RENDER
   *******************************************************/
  if (!comments) {
    return null
  }

  return (
    <SC.Wrap data-testid="item-details-comments">
      <Typography variant="subtitle2" color="primary" />
      {comments.map((comment) => {
        if (!comment) {
          return <></>
        }
        const { id, createdDate, createdBy, body, actions } = comment

        const isAllowedEditing = actions.some((action) =>
          [CommentActions.EDIT_OWN, CommentActions.EDIT_MY_TEAM].includes(
            action,
          ),
        )
        const isAllowedDeleting = actions.some((action) =>
          [CommentActions.DELETE_OWN, CommentActions.DELETE_MY_TEAM].includes(
            action,
          ),
        )

        if (!lastItem) {
          return <></>
        }

        return (
          <div key={id} ref={(id === lastItem.id && lastMessageRef) || null}>
            <SC.Item>
              <SC.StyledRecentNotificationsItem
                id={id}
                avatar={createdBy.avatar?.file?.location}
                disableHover
                fromTime={formatDistanceToNowStrict(parseISO(createdDate), {
                  addSuffix: true,
                })}
                name={`${String(createdBy.firstName)} ${String(
                  createdBy.lastName,
                )}`}
                seen={true}
                text={body}
                onEditClick={isAllowedEditing && onEditAction}
                onDeleteClick={isAllowedDeleting && onDeleteAction}
                disabled={!isAllowedEditing && !isAllowedDeleting}
              />
            </SC.Item>
          </div>
        )
      })}
    </SC.Wrap>
  )
}

export default ItemsDetailsComments
