// @react
import {
  FormControl,
  FormHelperText,
  InputLabelProps,
  InputProps,
  withStyles,
  WithStyles,
} from '@material-ui/core'
import colors from 'constants/colors'
import { FC, useState } from 'react'
import { Control, Controller, FieldValues, FormState } from 'react-hook-form'
import ReactQuill from 'react-quill'
import * as SC from 'modules/common/components/_FORM_ELEMENTS/Message/styledComponents/messagesTextarea'
import QuillToolbar from './components/QuillToolbar'
import { Wrap, Label } from './styledComponents'

export const style = () => ({
  wrap: {
    width: '100%',
    marginBottom: '10px',
  },
  select: {
    '&& p': {
      fontSize: '14px',
      color: `${colors.dark}`,
    },
  },
})

type TextInputProps = {
  name: string
  label: string
  formState: FormState<FieldValues>
  className?: string
  inputProps?: Partial<InputProps>
  disabled?: boolean
  withoutLabel?: boolean
  control: Control<any>
  hidden?: boolean
  value?: unknown
  inputLabelProps?: Partial<InputLabelProps>
}

export type PropsType = TextInputProps & WithStyles<typeof style>

/**
 *
 * @param classes
 * @param input
 * @param label
 * @param name
 * @param multiline
 * @param rows
 * @param rowsMax
 * @param onChange
 * @param inputProps
 * @param isSubmitting
 * @param isSubmitSuccessful
 * @param disabled
 * @param value
 * @param error
 * @param withoutLabel
 * @param custom
 * @constructor
 */
const FormQuillNew: FC<TextInputProps> = ({
  formState,
  control,
  name,
  label,
  disabled,
}) => {
  const { errors, isSubmitting, isSubmitSuccessful } = formState
  const submitFailed = !isSubmitSuccessful
  const error = errors[name]
  const isError = (isSubmitting && !!error) || (submitFailed && !!error)
  const errorText = error?.message
  const helperText = submitFailed && typeof error === 'string' ? error : null
  const [showToolbar, setShowToolbar] = useState(false)
  const modules = {
    toolbar: {
      container: '#toolbar',
    },
  }
  /*******************************************************
   * RENDER
   *******************************************************/
  return (
    <Controller
      name={name}
      control={control}
      render={(values) => (
        <FormControl error={isError}>
          <Wrap
            showToolbar={showToolbar}
            readOnly={disabled}
            value={values.value}
          >
            <SC.Textarea id="target">
              <Label $isDisabled={disabled}>{label}</Label>
              <ReactQuill
                readOnly={disabled}
                modules={modules}
                value={values.value}
                onChange={values.onChange}
                onFocus={() => setShowToolbar(true)}
                onBlur={() => setShowToolbar(false)}
              />
              <SC.Actions id="toolbar-wrapper">
                <QuillToolbar />
              </SC.Actions>
            </SC.Textarea>
          </Wrap>
          {helperText && !errorText && (
            <FormHelperText>{helperText}</FormHelperText>
          )}
          {errorText && isError && <FormHelperText>{errorText}</FormHelperText>}
        </FormControl>
      )}
    />
  )
}

export default withStyles(style)(FormQuillNew)
