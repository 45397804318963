// language=GraphQL
import { gql } from '@apollo/client'
import { COMMENT } from '../fragments/comment.fragment'

export const CREATE_COMMENT = gql`
  mutation CreateComment($input: CreateCommentInput!) {
    commentCreate(input: $input) {
      id
    }
  }
`

export const EDIT_COMMENT = gql`
  mutation EditMutation($input: UpdateCommentInput!) {
    commentUpdate(input: $input) {
      ...CommentNode
    }
  }
  ${COMMENT}
`

export const DELETE_COMMENT = gql`
  mutation DeleteComment($id: ID!) {
    commentDelete(id: $id) {
      message
      status
    }
  }
`
