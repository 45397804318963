import * as Yup from 'yup'

const emptyToNumber = (_, value) =>
  value === '' || value === null || value === undefined ? null : Number(value)

export default Yup.object().shape({
  ltv: Yup.number()
    .typeError('Must be a number')
    .max(100, 'Should not be more then 100')
    .min(0, 'Should not be less then 0')
    .transform(emptyToNumber)
    .nullable(true),
  grossUnleveredIrr: Yup.number()
    .typeError('Must be a number')
    .max(100, 'Should not be more then 100')
    .min(0, 'Should not be less then 0')
    .transform(emptyToNumber)
    .nullable(true),
  grossLeveredIrr: Yup.number()
    .typeError('Must be a number')
    .max(100, 'Should not be more then 100')
    .min(0, 'Should not be less then 0')
    .transform(emptyToNumber)
    .nullable(true),
  netLeveredIrr: Yup.number()
    .typeError('Must be a number')
    .max(100, 'Should not be more then 100')
    .min(0, 'Should not be less then 0')
    .transform(emptyToNumber)
    .nullable(true),
  grossAsset: Yup.number()
    .typeError('Must be a number')
    .transform(emptyToNumber)
    .nullable(true),
  multipleOnInvestedCapital: Yup.number()
    .typeError('Must be a number')
    .transform(emptyToNumber)
    .nullable(true),
  netAsset: Yup.number()
    .typeError('Must be a number')
    .transform(emptyToNumber)
    .nullable(true),
  capRate: Yup.number()
    .typeError('Must be a number')
    .max(100, 'Should not be more then 100')
    .min(0, 'Should not be less then 0')
    .transform(emptyToNumber)
    .nullable(true),
  number: Yup.number()
    .typeError('Must be a number')
    .transform(emptyToNumber)
    .nullable(true),
})
