import styled from 'styled-components'
import { Fab, Typography } from '@material-ui/core'
import { device, from, to } from 'modules/common/styledComponents/MediaQueries'

export const Header = styled.header`
  position: relative;
  padding-right: 5em;
  margin-bottom: 3.25em;
`

export const Title = styled(Typography)`
  && {
    font-size: 26px;
    font-weight: 400;
  }
`

export const FilterTitle = styled(Typography)`
  ${to(device.tabletXL)} {
    display: none;
  }
`

export const FilterToggle = styled(Fab)`
  position: absolute;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  background-color: transparent;

  ${from(device.tabletXL)} {
    display: none;
  }
`

export const DealsTableWrapper = styled.div`
  ${to(device.tablet)} {
    .MuiTableCell-root:nth-child(2),
    .MuiTableCell-root:nth-child(3),
    .MuiTableCell-root:nth-child(4),
    .MuiTableCell-root:last-child,
    .MuiTableCell-head {
      display: none;
    }
  }
`

export const ChartWrapper = styled.div`
  ${to(device.tabletXL)} {
    display: none;
  }
`

export const MobileTags = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 15px;

  ${from(device.tablet)} {
    display: none;
  }
`
