import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Typography } from '@material-ui/core'
import colors from 'constants/colors'

export const Section = styled.section`
  margin-top: 60px;
  margin-bottom: 60px;

  ${(props) =>
    props.margin &&
    props.margin === 'sm' &&
    `
    margin-top: 20px;
    margin-bottom: 20px;
  `}

  ${(props) =>
    props.margin &&
    props.margin === 'md' &&
    `
    margin-top: 40px;
    margin-bottom: 40px;
  `}

  &:first-child {
    margin-top: 20px;
  }
`

export const SectionHeader = styled.header`
  display: flex;
  margin-bottom: 20px;
  align-items: center;
  justify-content: space-between;
  color: ${colors.dark};
`

export const SectionHeaderLeft = styled.div`
  display: flex;
  align-items: center;
`

export const SectionHeaderTitle = styled(Typography)`
  && {
    color: currentColor;
    font-size: 20px;
    font-weight: 600;
  }
`

export const SectionHeaderLabel = styled(Typography)`
  && {
    margin-left: 20px;
    padding: 3px 7px;
    border-radius: 5px;
    color: ${colors.white};
    background-color: ${colors.grapefruit};
    font-weight: 400;
    font-size: 10px;
    text-transform: lowercase;
  }
`

export const SectionHeaderSeeAll = styled(Link)`
  color: currentColor;
  font-weight: 600;
  font-size: 14px;
  text-decoration: underline;

  &:hover {
    text-decoration: none;
  }
`
