import { ApolloQueryResult } from '@apollo/client'
import {
  DealsFilterInput,
  DocumentsFilterInput,
  Filters,
  FiltersNodeFragment,
  GetDealTotalsQuery,
  GetDocumentsQuery,
  GetItemsQuery,
  ItemsFilterInput,
} from 'graphql/graphqlTypes'
import { createContext } from 'react'
import { QueryResultUnion } from '../hook/filters'
import useFiltersSort, { Filter } from '../hook/filterSort'

interface TableContextValue {
  data: (FiltersNodeFragment | undefined | null)[]
  changeCriteria: (filters: Partial<Filters> | (Filter | undefined)[]) => void
  datePickerChangeHandler: (range: { from: Date; to: Date }) => void
  SortableHeader: any
  queryResult?: QueryResultUnion
}

type PropsType = {
  refetchOverride?: (variables: {
    filters?: DealsFilterInput | ItemsFilterInput | DocumentsFilterInput
  }) => Promise<
    ApolloQueryResult<GetItemsQuery | GetDealTotalsQuery | GetDocumentsQuery>
  > | void
  queryResult: QueryResultUnion | any

  headerRow?: any
  refetch?: any
  children?: any
}

export const TableContext = createContext<TableContextValue>({
  SortableHeader: () => <></>,
  changeCriteria: () => {},
  datePickerChangeHandler: () => {},
  data: [],
})

const ContextTableWrapper = ({
  headerRow,
  refetch,
  children,
  queryResult,
  refetchOverride,
}: PropsType) => {
  // const { SortableHeader } = useSort({ refetch, headerRow })
  const { data, changeCriteria, datePickerChangeHandler, SortableHeader } =
    useFiltersSort({ headerRow, queryResult, refetchOverride })
  //@ts-ignore
  const value = {
    SortableHeader,
    queryResult,
    changeCriteria,
    datePickerChangeHandler,
    data,
  } as TableContextValue

  return <TableContext.Provider value={value}>{children}</TableContext.Provider>
}

export default ContextTableWrapper
